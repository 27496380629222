import {Program} from "../model/Program";
import {API, api} from "../../lib/Api";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";
import {useQuery} from "@tanstack/react-query";
import {UseQueryResult} from "@tanstack/react-query/src/types";
import {clientAtom} from "../../Atoms";
import {requireGroup} from "../../lib/util";
import Groups from "../../model/Groups";

const useListProgramService = (): UseQueryResult<Program[]> => {
    const client = useAtomValue(clientAtom)
    const userInformation = useAtomValue(userInformationAtom)

    return useQuery({
        queryFn: () => api<Program[], undefined>(API.listProgram(), userInformation, client.id)
                .then(response => response.data.sort((a: Program, b: Program) => (a.school?.name + a.name).localeCompare(b.school?.name + b.name))),
        queryKey: ["listProgram"],
        staleTime: 86400000,
        enabled: !requireGroup(userInformation.userGroups, Groups.student)
    })
}

export default useListProgramService;