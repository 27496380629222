import React, {useEffect, useState} from 'react';
import './Student.css';

import {useAppContext} from "../lib/context"
import {Page, PageBody, PageHeader} from "../components/Page";
import {LoginStateCard} from "../components/LoginStateCard";
import {Box, Button, FormControl, Grid, TextField, Typography} from "@mui/material";
import {Group, GroupAdd} from "@mui/icons-material";
import {formDataAsMap} from "../lib/Forms";
import {API, api, apiError} from "../lib/Api";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import SpinnerCard from "../components/SpinnerCard";
import {useNavigate, useParams} from "react-router-dom";
import {Program} from "../program/model/Program";
import {Client, ClientSettingsOps} from "../client/model/Client";
import {makeStyles} from "tss-react/mui";
import useUserReadyService from "../user/lib/UserReadyService";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../Atoms";
import {clientAtom, loginStateAtom} from "../Atoms";

const useStyles = makeStyles()((theme) => ({
    formControl: {
        width: "100%"
    },
    textPara: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}))

interface StudentBulkAddResult {
    email: string
    result: boolean
    status: string
}

const StudentBulkAdd = () => {
    const {isDebug, setModal} = useAppContext();

    const loginState = useAtomValue(loginStateAtom)
    const client = useAtomValue(clientAtom) as Client
    const userInformation = useAtomValue(userInformationAtom)

    const params = useParams()

    const localClientId = client.id

    const [state, setState] = useState<string>()
    const [result, setResult] = useState<StudentBulkAddResult[]>()
    const [submitted, setSubmitted] = useState<boolean>(false)
    const [program, setProgram] = useState<Program>()

    const {useLoginStateCard, component} = LoginStateCard(loginState, client)

    isDebug && console.log("LSC", useLoginStateCard)

    const {classes} = useStyles()

    const navigate = useNavigate()
    const userReady = useUserReadyService()

    const programId = params.programId ? parseInt(params.programId) : 0

    useEffect(() => {
        if (userReady && userInformation && localClientId) {
            api<Program, undefined>(API.getProgram(programId), userInformation, localClientId).then(e => {
                setProgram(e.data)
            }).catch(err => apiError(err, setModal))
        }
    }, [userInformation, localClientId, params, setModal, programId, userReady])

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        const list = (formDataAsMap<any>(e.currentTarget).studentEmails as string).split("\n").map(x => x.trim()).filter(x => x.length >0)

        isDebug && console.log("Creating users", list)

        setSubmitted(true)

        api<StudentBulkAddResult[], string[]>(API.addStudentBulk(programId), userInformation, localClientId, list)
            .then(result => {
                setSubmitted(false)
                setResult(result.data)
            })
            .catch(e => {
                apiError(e, setModal)
                setSubmitted(false)
            })

    }

    const addComponent = (
        <>
            {program &&
                <Box className={classes.textPara}>
                <Typography>
                    Creating students for {program?.name}
                </Typography>
                </Box>
            }
            <Typography>
                To create new student accounts, add a new student email address with one address per line.
                Students will receive Welcome emails with login information.
            </Typography>
            <form onSubmit={handleSubmit}>
                <FormControl className={classes.formControl}>
                    <TextField variant="filled" type="text"
                               aria-label="Students email addresses"
                               placeholder=""
                               multiline
                               rows={20}
                               value={state}
                               onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => setState(e.target.value)}
                               name="studentEmails"
                               label="New Student Email Addresses"
                    />
                </FormControl>
                <FormControl className={classes.textPara}>
                    <Box display="flex">
                        <Box m={1}>
                            <Button variant="outlined" color="primary" type="submit" startIcon={<GroupAdd/>}>Create Students</Button>
                        </Box>
                        <Box m={1}>
                            <Button variant="outlined" color="primary" onClick={e => navigate("/student/" + programId)} startIcon={<Group/>}>Back to Student List</Button>
                        </Box>
                    </Box>
                </FormControl>
            </form>
        </>
    )

    const resultComponent = (
        <>
            <Box>
                {result && result.map(x =>
                    <Grid container wrap="nowrap" spacing={2} alignItems="center">
                        <Grid item>{x.result ? (<CheckCircleIcon className="go"/>) : (<CancelIcon className="stop"/>)}</Grid>
                        <Grid item xs={6}>{x.email}</Grid>
                        <Grid item xs={6}>{x.status}</Grid>
                    </Grid>
                )}
            </Box>
            <Box>
                <Button variant="outlined" onClick={e => navigate("/student/" + programId)}>OK</Button>
            </Box>
        </>
    )

    if (userReady && (!isDebug && ClientSettingsOps.matchDatePassed(client?.settings))) {
        return <Page navName="Students" footerPosition="fixed" variant="half">
            <PageHeader title="Bulk Add Student"/>
            <PageBody>
                <p>The match for this year has been published.  New students can be added once the next match cycle begin!</p>
                <p>See you next year!</p>
            </PageBody>
        </Page>
    }

    return useLoginStateCard ? component : (
        <Page navName="Students" footerPosition="fixed" variant="half">
            <PageHeader title="Bulk Add Student"/>
            <PageBody>
                {submitted ? <SpinnerCard message="Processing"/> :
                    result ? resultComponent : addComponent}
            </PageBody>
        </Page>
    )
}

export default StudentBulkAdd
