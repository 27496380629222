import React, {ChangeEvent, useState} from 'react'
import {QuestionnaireElementProps} from "../../model/QuestionnaireList";
import TextField from '@mui/material/TextField'
import {Theme} from "@mui/material/styles";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
        formControl: {
            width: "100%",
            minWidth: "200px",
        },
    }));

export const ElementHelpText = (props:QuestionnaireElementProps) => {
    const [helpText, setHelpText] = useState(props.row.question?.helpText)

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const x = e.target.value
        setHelpText(x)

        props.setElement && props.setElement(elem => {
            return {
                ...elem,
                question: elem.question && {
                    ...elem.question,
                    helpText: x
                }
            }
        })
    }

    const {classes} = useStyles()

    return (
        <TextField className={classes.formControl}
            id="elementHelpText"
            variant="filled"
            label="Help Text"
            defaultValue={helpText}
            name="helpText"
            size="small"
            onChange={onChangeHandler}
        />
    )
}
