import React, {FC, useState} from "react"
import {Offer} from "../../product/model/Product";
import {Grid, FormControl, FormHelperText, TextField, Box} from "@mui/material";
import {useStyles} from "../styles/OrderStyles";

export interface OfferItemTextProps {
    offer: Offer
    readonly: boolean
    setOfferQuantity: (id: string, quantity: number) => void
}


export const OfferItemText: FC<OfferItemTextProps> = ({offer, readonly, setOfferQuantity}) => {
    const [quantity, setQuantity] = useState<number>(offer.fixedQuantity || 1)
    const [error, setError] = useState<string | undefined>(undefined)

    const {classes} = useStyles()

    const quantityHandler = (e: string) => {
        try {
            const quantity = parseInt(e)
            setQuantity(quantity)
            setOfferQuantity(offer.id, quantity)
        } catch (e) {
            setError("Quantity must be a valid number")
        }
    }


    return offer.fixedQuantity !== 1 ? <>
            <FormControl error={!!error} variant="outlined" className={classes.grower}>
                    <Grid container spacing={1}>
                    <Grid item xs={3} md={1}>
                        <Box className={classes.labelBox}>
                            ${offer.price / 100}
                        </Box>
                    </Grid>
                    <Grid item xs={1} md={1}>
                        {(!!offer.fixedQuantity || readonly) ? <Box className={classes.quantityBox}>
                                {offer.fixedQuantity}
                                <input type="hidden" name={`quantity-${offer.id}`} value={offer.fixedQuantity}/>
                            </Box> :
                            <TextField required={true}
                                       value={quantity}
                                       onChange={e => quantityHandler(e.currentTarget.value as string)}
                                       error={!!error}
                                       helperText={error}
                                       variant="outlined"
                                       name={`quantity-${offer.id}`}
                            />
                        }
                    </Grid>
                    <Grid item xs={1} md={1} alignContent='center'>
                        <Box className={classes.timesBox}>x</Box>
                    </Grid>
                    <Grid item xs={4} md={8}>
                        <Box className={classes.nameBox}>{offer.name}</Box>
                    </Grid>
                    <Grid item xs={3} md={1}>
                        <Box className={classes.labelBox}>${quantity * offer.price / 100}</Box>
                    </Grid>
                    <Grid item xs={12}>
                        <FormHelperText>{error}</FormHelperText>
                    </Grid>
                </Grid>
            </FormControl>
        </> : <>
        <FormControl error={!!error} variant="outlined" className={classes.grower}>
            <input type="hidden" name={`quantity-${offer.id}`} value={offer.fixedQuantity}/>
            <Grid container spacing={0}>
                <Grid item xs={8} md={10}>
                    <Box className={classes.nameBox}>{offer.name}</Box>
                </Grid>
                <Grid item xs={4} md={2} display="flex" justifyContent="flex-end">
                    <Box className={classes.labelBox}>${quantity * offer.price / 100}</Box>
                </Grid>
                <Grid item xs={12}>
                    <FormHelperText>{error}</FormHelperText>
                </Grid>
            </Grid>
        </FormControl>
    </>

}
