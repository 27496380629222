
import React from "react"
import CancelIcon from "@mui/icons-material/Cancel";
import {Button, Hidden} from "@mui/material";
import {InternshipSiteTrackId} from "../../internship-site/model/Organization";
import {api, API, apiError} from "../../lib/Api";
import {useAppContext} from "../../lib/context";
import {questionModal} from "../../components/modal/InfoModal";
import {Client, ClientSettingsOps} from "../../client/model/Client";
import {userInformationAtom} from "../../Atoms";
import {useAtomValue} from "jotai";
import {useQueryClient} from "@tanstack/react-query";
import {clientAtom, refreshUser} from "../../Atoms";
import {DialogType, ModalAction} from "../../components/modal/ModalProps";

export interface WithdrawTrackButtonProps {
    siteTrackId: InternshipSiteTrackId
}

export const WithdrawTrackButton = (props: WithdrawTrackButtonProps) => {
    const {setModal} = useAppContext()

    const client = useAtomValue(clientAtom) as Client
    const userInformation = useAtomValue(userInformationAtom)

    const localClientId = client?.id || 0

    const queryClient = useQueryClient()

    const clickHandler = (e: React.MouseEvent<HTMLElement>) => {
        setModal && setModal(questionModal(props.siteTrackId, "Confirm Unrank", "Are you sure you wish to remove this track from your rank order list?", DialogType.Confirm, unrankAction))
    }

    const unrankAction = (action: ModalAction, siteTrackId: InternshipSiteTrackId) => {
        if (action === ModalAction.Confirm) {
            api<any, undefined>(API.withdrawSiteTrackToStudent(userInformation?.guid || "", props.siteTrackId), userInformation, localClientId)
                .then(e => {
                    refreshUser(queryClient)
                })
                .catch(e => {
                    apiError(e, setModal)
                })
        }
    }

    return ClientSettingsOps.rankListLocked(client.settings) ? <></> : (
        <Button variant="contained" className="ml-1" color="secondary" onClick={clickHandler} startIcon={<CancelIcon/>}>Unrank<Hidden lgDown> Track</Hidden></Button>
    );
}