import React, {useState} from "react";
import {StudentUserInformation} from "../../login/model/UserInformation";
import {Client} from "../../client/model/Client";
import {Box, Button, Collapse, List, ListItem, ListItemIcon, ListItemText} from "@mui/material";
import {ExpandMoreOutlined} from "@mui/icons-material";
import SchoolIcon from "@mui/icons-material/School";
import {StudentExpandoElement} from "./StudentExpandoElement";
import {InternshipSite} from "../../internship-site/model/Organization";

export const ExpandoSiteList: React.FC<{ row: StudentUserInformation, client: Client, siteList: InternshipSite[] }> = ({row, client, siteList}) => {

    const [open, setOpen] = useState<boolean>(false)

    return (
        <>
            <Button onClick={e => setOpen(!open)} startIcon={
                <ExpandMoreOutlined/>}>
                {row.student?.sites?.length} Site{row.student?.sites?.length !== 1 && "s"} Selected</Button>
            <Box style={{overflowX: "visible", "width": 50}}>
                <Collapse in={open} timeout="auto" style={{"width": 800}} unmountOnExit>
                    <List >
                        {row.student?.sites?.map(siteRef => {
                            const site = siteList.find(x => x.id === siteRef.id)
                                return site ? <ListItem key={site.id} className="items-start">
                                    <ListItemIcon className="items-start pt-1"><SchoolIcon/></ListItemIcon>
                                    <ListItemText>
                                        <StudentExpandoElement site={site} row={row} showSubmittedOnly={false}/>
                                    </ListItemText>
                                </ListItem> :<></>
                            })
                        }
                    </List>
                </Collapse>
            </Box>
        </>
    )
}