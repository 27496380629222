import React, {Dispatch, SetStateAction, useState} from "react"
import {useAppContext} from "../../lib/context";
import {
    Box, Button, Hidden, IconButton,
    List,
    Tooltip,
    Typography
} from "@mui/material";
import {Item} from "../../components/draggableList/DnDContainer";
import {InternshipSite, InternshipSiteTrack} from "../../internship-site/model/Organization";
import {TrackInformation} from "../../questionnaire/component/search/component/DirectoryEntry";
import Collapse from "@mui/material/Collapse";
import {Close, DeleteRounded, ExpandLessOutlined, ExpandMoreOutlined} from "@mui/icons-material";
import SpinnerCard, {SpinnerSmall} from "../../components/SpinnerCard";
import {useStudentOrganizationService} from "../lib/StudentOrganizationService";
import {API, api, apiError} from "../../lib/Api";
import {questionModal} from "../../components/modal/InfoModal";
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import {useNavigate} from "react-router-dom";
import {userInformationAtom} from "../../Atoms";
import {useAtomValue} from "jotai";
import {useQueryClient} from "@tanstack/react-query";
import {StudentUserInformation, UserOps} from "../../login/model/UserInformation";
import {clientAtom, refreshUser} from "../../Atoms";
import {DialogType, ModalAction} from "../../components/modal/ModalProps";
import {Client, ClientSettingsOps} from "../../client/model/Client";
import {DirectoryEntryInfoButton} from "./DirectoryEntryInfoButton";
import {useTranslation} from "react-i18next";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {StudentOps} from "../model/Student";

interface SiteRowProps {
    userInformation: StudentUserInformation
    site: InternshipSite
    sites: InternshipSite[]
    locked: boolean
}

export const SiteName: React.FC<{
    locked: boolean,
    open: boolean,
    setOpen: Dispatch<SetStateAction<boolean>>,
    site: InternshipSite
}> = ({locked, open, setOpen, site}) => {
    const {t} = useTranslation()

    return (
        <>
            <Box flexGrow={1}>
                {site.name}
                <Hidden mdUp>
                    <Tooltip title="Select Tracks">
                        <IconButton
                            onClick={e => setOpen(!open)}
                            style={{marginLeft: 5, padding: 5}}
                            size="large">
                            {open ? <ExpandLessOutlined/> : <ExpandMoreOutlined/>}
                        </IconButton>
                    </Tooltip>
                </Hidden>
            </Box>
            <Box>
                <Hidden mdDown>
                    <Button endIcon={open ? <ExpandLessOutlined/> : <ExpandMoreOutlined/>} onClick={e => setOpen(!open)}
                            style={{marginRight: 10}}>{t("applications.openTrackView")}</Button>
                </Hidden>
            </Box>
        </>
    )
}

export const SiteRemoveButton: React.FC<{ site: InternshipSite, locked: boolean }> = ({site, locked}) => {
    const {setModal} = useAppContext()

    const [isRemoving, setIsRemoving] = useState<boolean>(false)

    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client

    const localClientId = client?.id || 0

    const {t} = useTranslation()
    const queryClient = useQueryClient()

    const withdrawFromSite = () => {
        setModal && setModal(questionModal(null, "Confirm", "Are you sure you wish to withdraw from this site? If you have ranked any tracks, they will be removed from your rank list.",
            DialogType.Confirm, (action: ModalAction) => {
                if (action === ModalAction.Confirm) {
                    setIsRemoving(true)
                    api(API.withdrawSiteToStudent(userInformation?.guid || "", site.id || 0), userInformation, localClientId)
                        .then(e => {
                            refreshUser(queryClient).then(() => setIsRemoving(false))
                        })
                        .catch(e => {
                            apiError(e, setModal)
                            setIsRemoving(false)
                        })
                } else {
                    setIsRemoving(false)
                }
            }))
    }

    return locked ? <></> :
        <Box>
            {isRemoving ? <Box p={1}><SpinnerSmall/></Box> : <>
                <Hidden mdDown>
                    {locked ||
                        <Button startIcon={<Close/>} className="stop" onClick={e => withdrawFromSite()}>{t("buttons.remove")}</Button>
                    }
                </Hidden>
                <Hidden mdUp>
                    <Tooltip title="Remove Site">
                        <IconButton onClick={e => withdrawFromSite()} size="large"><DeleteRounded
                            className="stop"/></IconButton>
                    </Tooltip>
                </Hidden>
            </>}
        </Box>
}

export const ManageApplicationButton: React.FC<{ userInformation: StudentUserInformation, site: InternshipSite, track?: InternshipSiteTrack }> = ({userInformation, site, track}) => {
    const client = useAtomValue(clientAtom) as Client
    const {t} = useTranslation()

    const navigate = useNavigate()

    const submitted = StudentOps.isApplicationSubmitted(
        userInformation.student?.studentApplicationPackets.applications.find(x => x.siteId === site.id && x.trackId === track?.id)
    )

    const editable = ClientSettingsOps.isInSubmissionWindow(client.settings) &&
        StudentOps.studentApplicationsSubmittedCount(userInformation) <= client.settings.clientCycleSettings.applicationSuiteSettings.packetsPerSubmission

    const manageApplication = (site: InternshipSite, track?: InternshipSiteTrack) => {
        if (track) {
            navigate(`/site/track/${site.organizationId}/${site.id}/${track.id}/application`)
        }
        else {
            navigate(`/site/${site.organizationId}/${site.id}/application`)
        }
    }

    if (submitted) {
        return <Box>
            <Hidden lgDown>
                <Tooltip title="Manage Site Application">
                    <Button id={`packet-${site.id}-${track?.id}}`} startIcon={submitted ? <CheckCircleIcon/> : <NoteAltIcon/>} disabled={true} variant="contained">
                        {t("applications.buttons.submittedPacket")}</Button>
                </Tooltip>
            </Hidden>
            <Hidden lgUp>
                <Tooltip title="Manage Site Application">
                    <Button id={`packet-${site.id}-${track?.id}}`} startIcon={<NoteAltIcon/>} disabled={true} variant="contained" size="large">Packet</Button>
                </Tooltip>
            </Hidden>
        </Box>
    }
    else {
        return editable ?
            <Box>
                <Hidden lgDown>
                    <Tooltip title="Manage Site Application">
                        <Button id={`packet-${site.id}-${track?.id}}`}
                                startIcon={submitted ? <CheckCircleIcon/> : <NoteAltIcon/>} disabled={submitted}
                                variant="contained" onClick={e => manageApplication(site, track)}>
                            {submitted ? t("applications.buttons.submittedPacket") : t("applications.buttons.applicationPacket")}</Button>
                    </Tooltip>
                </Hidden>
                <Hidden lgUp>
                    <Tooltip title="Manage Site Application">
                        <Button id={`packet-${site.id}-${track?.id}}`} startIcon={<NoteAltIcon/>} disabled={submitted}
                                variant="contained" onClick={e => manageApplication(site, track)}
                                size="large">Packet</Button>
                    </Tooltip>
                </Hidden>
            </Box>
            : <></>
    }
}

export const applyToWhere = (client: Client) => {
    if (client.settings.clientCycleSettings.applicationSuiteSettings.useApplicationSuite) {
        return client.settings.clientCycleSettings.applicationSuiteSettings.applyTo === "Track" ? {
            applyToSite: false,
            applyToTrack: true
        } : {applyToSite: true, applyToTrack: false}
    } else {
        return {applyToSite: false, applyToTrack: false}
    }
}

const SiteRow = (props: SiteRowProps) => {
    const {isDebug} = useAppContext()

    const client = useAtomValue(clientAtom) as Client

    const editable = ClientSettingsOps.isInSubmissionWindow(client.settings)

    //const localClientId = client?.id || 0

    const [open, setOpen] = useState<boolean>(
        !ClientSettingsOps.isInSubmissionWindow(client.settings)
    )

    const tracks = props.sites.find(e => e.id === props.site.id)?.siteTracks

    isDebug && console.log(props.site.name, tracks)

    const {applyToSite} = applyToWhere(client)

    return <Box p={1} m={1} className="bg-white">
        <Box alignItems="center" flexWrap="nowrap" display="flex" >
            {client.settings.directory === "enabled" &&
                <DirectoryEntryInfoButton site={props.site}/>
            }

            <SiteName open={open} setOpen={setOpen} site={props.site} locked={props.locked}/>
            {editable && <>
                <SiteRemoveButton site={props.site} locked={props.locked}/>
                {applyToSite && <ManageApplicationButton userInformation={props.userInformation} site={props.site}/>}
            </>}
        </Box>
        <Box>
                <Collapse in={open} timeout="auto" unmountOnExit style={{width: "100%"}}>
                    <Box margin={1} pt={1}>
                        {tracks && tracks.length > 0 ? tracks
                            .filter(x => UserOps.isTrackVisible(props.userInformation, x.id!))
                            .map(track =>
                            <TrackInformation key={track.id} site={props.site} siteTrack={track} showManageSiteButton={false}/>
                        ) : <Typography>No Tracks Available</Typography>}
                    </Box>
                </Collapse>
        </Box>
        </Box>
}

export const AppliedSiteList: React.FC<{ locked: boolean, userInformation: StudentUserInformation }> =
    ({locked, userInformation }) => {
    const {isDebug} = useAppContext()

    const client = useAtomValue(clientAtom) as Client

    const localClientId = client?.id || 0

    const orgService = useStudentOrganizationService({clientId: localClientId})

    if (orgService.status === "success") {
        const sites = orgService.data.map(x => x.internshipSite)

        const itemComponent = (x: InternshipSite, i: number) => {
            isDebug && console.log("itemComponent for ", i, x)

            return (<SiteRow userInformation={userInformation} key={x.id} site={x} sites={sites} locked={locked}/>)
        }

        isDebug && console.log("Setting items on Application List")
        const items = (userInformation.student ? userInformation.student.sites.sort((a, b) => a.name.localeCompare(b.name))
            .map((x, i) => ({
                id: x.id && x.id.toString(),
                data: x,
                component: itemComponent(x, i + 1)
            })) as Item<InternshipSite>[] : [])

        return <List>{items && items.map((x, i) => x.component)}</List>
    } else {
        return <SpinnerCard message="Loading"/>
    }
}