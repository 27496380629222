import {Box, Button, Typography} from "@mui/material";
import {QuestionnaireActionComponent} from "./QuestionnaireActionComponent";
import {useModalService} from "../../../components/modal/ModalService";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {api, API} from "../../../lib/Api";
import {useAtomValue} from "jotai";
import {Client} from "../../../client/model/Client";
import {clientAtom} from "../../../Atoms";
import {refreshUser} from "../../../login/lib/UserSessionsService";

export const QuestionnaireSubmitted: QuestionnaireActionComponent = ({student, setInAction}) => {

    const client = useAtomValue(clientAtom) as Client
    const queryClient = useQueryClient()

    const modal = useModalService()

    const withdrawMutation = useMutation({
        mutationFn: (guid: string) => api(API.withdrawStudentQuestionnaire(guid), student, client.id)
    })

    const withdrawQuestionnaire = () => {
        modal.confirmChange(student.guid, {
                onConfirm: () => {
                    setInAction(true)
                    withdrawMutation.mutateAsync(student.guid).then(() => {
                        refreshUser(queryClient).then(() => setInAction(false))
                    })
                }
            }
        )
    }

    return <Box>
        <Typography variant="inherit"><i>Questionnaire Has been submitted - feedback will appear here</i></Typography>
        <Button onClick={() => withdrawQuestionnaire()} variant="contained" color="secondary">Withdraw Questionnaire Submission</Button>
    </Box>
}
