import React, {FC} from 'react'

interface DollarDecimalProps {
    value: number
}

export const DollarDecimal: FC<DollarDecimalProps> = ({value}) => (
    <>
        ${(value / 100.0).toLocaleString("en-US", { maximumFractionDigits: 2, minimumFractionDigits: 2 })}
    </>
)

