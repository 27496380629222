import React, {Dispatch, SetStateAction, useEffect, useState} from "react"
import {Box, Button, TextField} from "@mui/material";
import {EmailControlState, EmailTemplate} from "../model/Email";
import {useAppContext} from "../../lib/context";
import {api, API, apiError} from "../../lib/Api";
import {makeStyles} from "tss-react/mui";
import {EmailPayload} from "./EmailCompose";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";
import {clientAtom} from "../../Atoms";

export interface EmailPreviewProps {
    emailTemplate: EmailTemplate | null
    setMode: Dispatch<SetStateAction<EmailControlState>>
}

export interface EmailSentData {
    messageId: string
    from: string
    to: string
}

export interface EmailResult {
    status: "success" | "failure"
    value: EmailSentData
}

export const useStyles = makeStyles()((theme) => ({
    formControl: {
        marginBottom: theme.spacing(1),
        minWidth: "100%",
        maxWidth: "100%",
        position: "relative"
    }
}));

export const useEmailPreview = (props: EmailPreviewProps) => {
    const {isDebug, setModal} = useAppContext()
    const client = useAtomValue(clientAtom)
    const userInformation = useAtomValue(userInformationAtom)

    const [result, setResult] = useState<EmailResult[]>()

    const localClientId = client.id || 0

    const {classes} = useStyles()

    const bulkSend = () => {
        if (props.emailTemplate) {
            props.setMode("sending")
            api<EmailResult[], EmailPayload>(API.bulkSend(props.emailTemplate.groupName), userInformation, localClientId, {
                body: props.emailTemplate.body,
                subject: props.emailTemplate.subject,
                groupName: props.emailTemplate.groupName
            })
                .then((response) => {
                    props.setMode("sent")
                    isDebug && console.log("Sent response", response)
                    setResult(response.data)
                })
                .catch(e => apiError("Failed to send", setModal))
        }
    }

    useEffect(() => {
        if (props.emailTemplate) {
            isDebug && console.log("Email Template", props.emailTemplate.body)

            const parent = document.getElementById("iFrameBox")

            const iframe = document.createElement('iframe');
            const html = props.emailTemplate.body
            parent?.appendChild(iframe);
            iframe.width = "100%"
            iframe.height = "400"
            iframe.contentWindow?.document.open();
            iframe.contentWindow?.document.write(html);
            iframe.contentWindow?.document.close();
        }
    }, [props, isDebug])

   return {emailResult: result, previewComponent: (
       <Box>
           <Box>
               <TextField label="Subject" value={props.emailTemplate?.subject}
                          contentEditable={false} variant="outlined" className={classes.formControl}
                          />
           </Box>
           <Box id="iFrameBox" flexGrow={1}>
           </Box>
           <Box>
               <Button onClick={e => props.setMode("edit") } color="primary">Continue Editing</Button>
               <Button onClick={e => bulkSend()} color="primary">Send Email</Button>
           </Box>
       </Box>
   )}
}