import React, {useState} from 'react';

import {useAppContext} from "../lib/context"
import {PageBlurb} from "../components/PageBlurb";
import {Box, Button, Typography} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {Cancel} from "@mui/icons-material";
import {API, api} from "../lib/Api";
import {messageModal, questionModal} from "../components/modal/InfoModal";
import {useNavigate} from "react-router-dom";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../Atoms";
import {useQueryClient} from "@tanstack/react-query";
import {clientAtom, refreshUser} from "../Atoms";
import {DialogType, ModalAction} from "../components/modal/ModalProps";
import {Client} from "../client/model/Client";
import SpinnerCard from "../components/SpinnerCard";
import {useTranslation} from "react-i18next";
import {EmptyObject, LoggedInComponent, LoggedInComponentWrapper} from "../components/LoggedInPage";

const AttestationWrapper: LoggedInComponentWrapper = (context) => {
    const navigate = useNavigate()

    if (context.state === "ready") {
        if (context.userInformation?.student?.attestationTimestamp) {
            navigate("/home")
            return <></>
        }
        else {
            return <Attestation {...context}/>
        }
    }
    else {
        return <SpinnerCard message="Loading..."/>
    }
}

const Attestation: LoggedInComponent<EmptyObject> = (props) => {
    const {setModal} = useAppContext();

    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client

    const [saving, setSaving] = useState<boolean>(false)

    const {t} = useTranslation()
    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const attest = () => {
        setSaving(true)
        api(API.attestStudent(), userInformation, client.id).then(e => {
            setModal && setModal({
                ...messageModal("Attestation", "Attestation recorded!"),
                onClose: () => {
                    refreshUser(queryClient).then(x => {
                        setSaving(false)
                        navigate("/home")
                    })
                }
            })
        })
    }

    const decline = () => {
        setSaving(true)
        setModal && setModal(questionModal(null, "Are you sure?", "If you decline to attest, you will be " +
            "unable to participate in the match. Click 'Confirm' to opt-out.", DialogType.Confirm, (action, x) => {
            if (action === ModalAction.Confirm) {
                setSaving(false)
                navigate("/signout")
            }
            else {
                setSaving(false)
            }
        }))
    }

    return <><Box m={2}>
                    <Typography variant="h6">
                        Students: Please read the following before proceeding to payment.
                    </Typography>
                </Box>
                <PageBlurb name="attestation" roleSpecific={true}/>
                {saving ? <SpinnerCard message={t("spinner.updating")} horizontal={true}/> :
                    <Box display="flex">
                        <Button variant="contained" className="go" onClick={e => attest()} startIcon={<CheckCircleIcon/>}>
                            I Accept
                        </Button>
                        <Button variant="contained" className="stop" onClick={e => decline()} startIcon={<Cancel/>}>
                            I Decline
                        </Button>
                    </Box>
                }
                </>
}


export default AttestationWrapper