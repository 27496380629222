import React from "react";
import {Box} from "@mui/material";
import {makeStyles} from "tss-react/mui";


export const useStyles = makeStyles()((theme) => ({
    datumContainer: {
        borderBottom: "1px solid rgba(0,0,0,0.2)",
        padding: "0.5rem",
        margin: "0.5rem 0.2rem 0.5rem 0.2rem",
    },
    title: {
        color: "rgba(0,0,0,0.4)",
        fontSize: "90%",
        marginLeft: "-5px",
        padding: "0.2rem 0"
    },
    data: {
        color: "rgba(0,0,0,1)",
        padding: "0.2rem",
    }
}));

export const DatumDisplay: React.FC<{title: string, children: React.ReactNode}> = ({title, children}) => {
    const {classes} = useStyles();

    return <Box className={classes.datumContainer}>
        <Box className={classes.title}>{title}</Box>
        <Box className={classes.data} overflow="hidden" textOverflow="ellipsis">{children}</Box>
    </Box>
}