import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Organization, OrganizationId, trackTypes} from "../model/Organization";

import {useAppContext} from "../../lib/context";
import SpinnerCard from "../../components/SpinnerCard";

import tracksFromOrg, {TrackListRowData} from "../lib/TracksForOrganization";
import {TableColumn} from "../../components/customTable/CustomTable";
import {head, requireOneOfGroup} from "../../lib/util";
import Groups from "../../model/Groups";
import {OrganizationSelect} from "./InternshipSiteSelect";
import {isTrackEditable, TrackListTable} from "./TrackListTable";
import {API, api, apiError, getApiUrl} from "../../lib/Api";
import {PageBlurb} from "../../components/PageBlurb";
import {Client, ClientSettings, ClientSettingsOps} from "../../client/model/Client";
import {InternshipSiteUserGrid} from "./InternshipSiteListRow";
import internshipSiteFromOrg from "../lib/InternshipSiteFromOrganization";
import {RichElement} from "../../components/customTable/RichElement";
import {Box, IconButton, Tooltip} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {RelationshipOperation} from "../../program/model/ProgramSiteRelationship";
import {DownloadsControl} from "../../components/DownloadsControl";
import {PageBody, PageHeader} from "../../components/Page";
import {LowPriority} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {TrackListTypeSelect} from "./TrackListTypeSelect";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";
import {clientAtom} from "../../Atoms";
import {UserInformation, UserOps} from "../../login/model/UserInformation";

export const buildTrackColumns: (clientSettings: ClientSettings, userGroups: string[]) => Array<TableColumn<TrackListRowData>> = (settings, userGroups) => (
        (requireOneOfGroup(userGroups, [Groups.globalAdmin, Groups.clientAdmin]) ? [{
            title: "Site Name",
            field: "internshipSiteName",
            editable: "never"
        }] as TableColumn<TrackListRowData>[] : []).concat([
        {
            title:" Track ID",
            field: "track.continuousTrackId",
            editable: (requireOneOfGroup(userGroups, [Groups.globalAdmin, Groups.clientAdmin]) ? 'onUpdate' : "never")
        },
        {
            title: "Track Name",
            field: "track.name",
            required: true
        },
        {
            title: "Track Description",
            field: "track.description"
        }
        ] as TableColumn<TrackListRowData>[]).concat((settings.useTrackType === "yes" ? [
        {
            title: "Track Type",
            field: "track.siteTrackType",
            render: row => <>{trackTypes[row.track.siteTrackType]}</>,
            editComponent: props => <TrackListTypeSelect onChange={props.onChange} defaultValue={props.value}/>,
            required: true
        }] : []) as TableColumn<TrackListRowData>[])
            .concat(ClientSettingsOps.matchDatePassed(settings) ?
                [ {
                    title: `Pre ${settings.clearingHouseLabel} phase openings`,
                    field: "track.preClearingHouseOpenings",
                    editable: "never"
                   },
                    {
                        title: `${settings.clearingHouseLabel} Opted In`,
                        field: "track.clearingHouseOptIn",
                        editable: "never",
                        render: row => <RichElement value={row.track.clearingHouseOptIn}/>
                    }
            ] : [] as TableColumn<TrackListRowData>[])
            .concat([
        {
            title: "Openings",
            field: "track.openings",
            type: "numeric",
            width: 100,
            required: true,
            validator: v => {
                return (parseInt(v)) >= 0 ? undefined : "Openings must be a positive number"
            }
        },
        {
            title: "Ranked Students",
            type: "numeric",
            width: 80,
            editable: "never",
            field: "track.rankedStudents",
            render: rowData => (<>
                {requireOneOfGroup(userGroups, [Groups.globalAdmin, Groups.siteAdmin]) && (
                <Tooltip title="Rank Students">
                    <IconButton
                        href={`/siteStudentRank/${rowData.organizationId}/${rowData.track.internshipSiteId}/${rowData.track.id}`}
                        size="large"><LowPriority/></IconButton>
                </Tooltip>)}
                {rowData.track.rankedStudents.length}
                </>)
        },
        {
            title: "Matches",
            field: "track.totalMatched",
            width: 80,
            type: "numeric",
            editable: "never"
        }, {
            title: "Programs",
            field: "track.programs.length",
            width: 80,
                type: "numeric",
                editable: "never",
                render: (row) => {
                return (
                    <React.Fragment>
                        <Tooltip title="Edit Program Relationships">
                            <IconButton href={`/trackPrograms/${row.track.id}`} size="large">
                                <EditIcon/>
                            </IconButton>
                        </Tooltip>
                        {row.track.programs
                            .filter(x => [RelationshipOperation.TrackApproved, RelationshipOperation.ProgramApproved].includes(x.operationId))
                            .length}
                    </React.Fragment>
                );
                }
        },
        {
            title: "Last Modified",
            field: "track.modifiedTimestamp",
            editable: "never",
            render: row => <RichElement value={row.track.modifiedTimestamp}/>
        }
    ]).concat((requireOneOfGroup(userGroups, [Groups.globalAdmin, Groups.clientAdmin]) ? [
        {
            title: "Admins",
            editable: "never",
            render: (row) => (<InternshipSiteUserGrid users={row.admins || []}/>)
        }] : []) as TableColumn<TrackListRowData>[])
)

interface TrackListParams {
    orgId?: string | undefined
}

const TrackList: React.FC<TrackListParams> = (props) => {
    const {isDebug, setModal} = useAppContext();

    const client = useAtomValue(clientAtom) as Client
    const userInformation = useAtomValue(userInformationAtom)

    const localClientId = client.id || 0

    const [filteredOrganization, setFilteredOrganization] = useState<Organization>();
    const [loaded, setLoaded] = useState<boolean>(false)
    const [refiltering, setRefiltering] = useState<boolean>(false)

    const [organizations, setOrganizations] = useState<Organization[]>([]);

    const seeAll = useMemo(() => requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin, Groups.directoryAdmin, Groups.clientAdmin]), [userInformation])

    const navigate = useNavigate()

    const setFilteredOrganizationId = useCallback((id: OrganizationId | undefined) => {
        setRefiltering(true)
        navigate("/track/" + id)
    }, [setRefiltering, navigate])

    const internshipSite = filteredOrganization && internshipSiteFromOrg(filteredOrganization)
    console.log("Editable", isTrackEditable(userInformation, client.settings), (internshipSite?.organizationId||0) > 0)

    useEffect(() => {
        console.log("Effecting trackList", localClientId, userInformation)

        const setFiltered = (orgs: Organization[], id: OrganizationId) => {
            const filtered = orgs.find(x => x.id === id)
            console.log("Filtering to", filtered)
            setFilteredOrganization(filtered)
            setRefiltering(false)
        }

        if (localClientId && userInformation?.jwtToken) {
            api(API.listOrganizations("tracks"), userInformation, localClientId)
                .then(response => {
                    const orgs = (response.data as Organization[]).filter(x => UserOps.canAdminSite(userInformation, x.internshipSite.id || 0))

                    if (requireOneOfGroup(userInformation.userGroups, [Groups.globalAdmin, Groups.directoryAdmin, Groups.clientAdmin])) {
                        api(API.listUserByGroup(Groups.siteAdmin), userInformation, localClientId).then(siteAdminsResponse => {
                            const newOrgs = [
                                ...(orgs.map(org => ({
                                    ...org,
                                    internshipSite: {
                                        ...org.internshipSite,
                                        admins: [...(siteAdminsResponse.data as UserInformation[]).filter(sa => sa.sites?.includes(org.internshipSite.id || 0))]
                                    }
                                })))
                            ]

                            isDebug && console.log("Orgs", newOrgs)

                            if (props.orgId) {
                                setFiltered(newOrgs, parseInt(props.orgId))
                            }
                            setOrganizations(newOrgs)
                            setLoaded(true)
                        })
                    } else {
                        if (props.orgId) {
                            setFiltered(orgs, parseInt(props.orgId))
                        }
                        else {
                            setFilteredOrganizationId(head(orgs)?.id)
                        }
                        setOrganizations(orgs)
                        setLoaded(true)
                    }

                })
                .catch((x) => apiError(x, setModal));
        }
    }, [localClientId, setModal, seeAll, userInformation, isDebug, props, setFilteredOrganizationId]);

    const tracks = useMemo(() => organizations.filter(x => {
                    if (filteredOrganization) return x.id === filteredOrganization.id
                    else return true
                })
                .flatMap(x => tracksFromOrg(x))
        , [organizations, filteredOrganization])

    isDebug && console.log("Tracks", tracks, organizations, filteredOrganization, head(organizations))

    return loaded ? (
        <>
        <PageHeader title="Manage Tracks">
        </PageHeader>
        <PageBody>
                <Box display="flex" flexWrap="nowrap" p={0} m={0}>
                    <Box flexGrow={1} p={1}>
                {organizations.length > 0 ?
                    <OrganizationSelect organizations={organizations}
                                       selectedOrganizationId={filteredOrganization?.id}
                                       setFilteredOrganizationId={setFilteredOrganizationId}/>
                    : <h3 style={{paddingBottom: "0.75rem"}}>{filteredOrganization?.internshipSite.name}</h3>
                }
                    </Box>
                    {requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin, Groups.clientAdmin, Groups.directoryAdmin]) &&
                    <Box p={1}>
                        <DownloadsControl
                            downloadUrl={getApiUrl() + API.listOrganizations("tracks", "csv").location + "&clientId=" + localClientId}
                            style={{"marginTop": "17px"}}/>
                    </Box>
                    }
                </Box>
                {seeAll && (
                    <div className="warning-box">Warning: this is LIVE track data, do not edit unless you really mean
                        to!</div>)}

                <PageBlurb name="tracks_page" roleSpecific={true}/>

            {refiltering ? <SpinnerCard/> :
                (filteredOrganization ?
                        (<TrackListTable key={filteredOrganization.id} tracks={tracks}
                                         internshipSite={internshipSiteFromOrg(filteredOrganization)}/>)
                        : (requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin, Groups.clientAdmin, Groups.directoryAdmin]) ? (
                                <TrackListTable key="all-org" tracks={tracks}/>
                            ) : "Select a Track"
                        )
                )
            }
        </PageBody>
        </>) : <SpinnerCard/>
}

export default TrackList;