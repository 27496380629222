import {UseQueryResult} from "@tanstack/react-query/src/types";
import {QuestionnaireId, QuestionnaireTableElement} from "./model/Questionnaire";
import {useQuery} from "@tanstack/react-query";
import {API, api} from "../lib/Api";
import {Client} from "../client/model/Client";
import {UserInformation} from "../login/model/UserInformation";
import {Questionnaire} from "../model/Questionnaire";


export const useQuestionnaireService = (questionnaireId: QuestionnaireId, userInformation: UserInformation, client: Client): UseQueryResult<Questionnaire> => {
    return useQuery({
        queryFn: () => api<Questionnaire, undefined>(API.fetchQuestionnaire(questionnaireId), userInformation, client.id)
            .then(x => x.data)
            .then(e => ({
                id: e.id,
                name: e.name,
                elements: e.elements.map(x => ({
                    ...x,
                    parsedParameters: x.parameters ? JSON.parse(x.parameters) : undefined,
                    tableQuestions: []
                } as QuestionnaireTableElement))
            })),
        queryKey: ["questionnaire", questionnaireId],
        staleTime: 86400000
    })
}