import {QuestionnaireElement} from "../model/Questionnaire";
import {Questionnaire} from "../../model/Questionnaire";
import {QuestionAnswer, QuestionnaireSubmissionElement} from "../model/QuestionnaireSubmissionElement";
import {evalInterpolate, interpolate} from "../../application/lib/Interpolation";
import {isDefined} from "../../lib/util";

export const makeQuestionnaireElements = (interpolationSpace: Record<string, any>, questionnaire: Questionnaire, submissionElements: QuestionnaireSubmissionElement[]): QuestionnaireElement[] => {
    //console.log("submissionElements", submissionElements)
    return (questionnaire.elements as QuestionnaireElement[]).map(qe => {
        //appConfig.isDebug && console.log("Making from element", qe)

        const answerData = submissionElements.find(e => qe.question && e.answer?.questionId === qe.question.id)?.answer

        return {
            ...qe,
            answer: qe.question && (answerData && isDefined(answerData.answer) ? answerData :
                    {
                        id: 0,
                        questionId: qe.question.id,
                        label: qe.question.label,
                        answer: qe.question.defaultAnswer || ""
                    } as QuestionAnswer)
        } as QuestionnaireElement
    })
        .map(x => {
        return {
            ...x,
            answer: x.answer ? {
                ...x.answer,
                answer: evalInterpolate(interpolationSpace, interpolate(interpolationSpace, x.answer.answer || ""))
            } : undefined
        }
    })
}