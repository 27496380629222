import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import '../Login.css';
import {Spinner} from "../components/SpinnerCard";
import {Box, Button, FormGroup, Grid, TextField, Typography} from "@mui/material";
import clsx from 'clsx'
import {ClientSelect} from "./ClientSelect";
import {makeStyles} from "tss-react/mui";
import {useAtom} from "jotai";
import {useAppContext} from "../lib/context";
import {flashingErrorMessageAtom, loginStateAtom} from "../Atoms";
import {LoginState} from "./lib/UserSessionsService";
import NewPassword from "./NewPassword";
import {useSignInService} from "./lib/SignInService";
import {useAtomValue} from "jotai/index";
import {useTranslation} from "react-i18next";

export const useStyles = makeStyles()((theme) => ({
    Login: {
        height: "100%",
        display: "flex",
        alignContent: "center",
        fontFamily: "'Numans', sans-serif !important",
        position: "relative"
    },
    container: {
        height: "100%",
        display: "flex",
        alignContent: "center",
        fontFamily: "'Numans', sans-serif",
    },
    formControl: {
        margin: theme.spacing(2),
        flexGrow: 1,
        display: "block"
    },
    forgotPasswordContainer: {
        height: "100%",
        alignContent: "center"
    },
    forgotPasswordCard: {
        height: "370px",
        marginTop: "auto",
        marginBottom: "auto",
        width: "400px",
        color: "white",
        backgroundColor: "rgba(0, 0, 0, 0.5) !important"
    },
    ForgotPasswordA: {
        color: "lightgrey",
        textDecoration: "underline"
    },
    card: {
        height: "370px",
        width: "400px",
        margin: "auto",
        color: "white",
        backgroundColor: "rgba(0, 0, 0, 0.5) !important",
        position: "relative",
        padding: theme.spacing(1)
    },
    cardHeaderBox: {
        "marginBottom": "40px"
    },
    cardHeader: {
        color: "white",
        margin: theme.spacing(2),
        fontFamily: "'Numans', sans-serif !important",
    },
    cardFooter: {
        color: "white",
        margin: theme.spacing(2),
        fontFamily: "'Numans', sans-serif !important",
        position: "absolute",
        bottom: "10px",
        left: "10px"
    },
    loginLink: {
        color: "lightgrey",
        textDecoration: "underline"
    },
    prependIcon: {
        width: "50px",
        backgroundColor: "#FFC312",
        color: "black",
        border: "0 !important",
        borderTopLeftRadius: "0.25em",
        borderBottomLeftRadius: "0.25em",
        paddingLeft: "5px"
    },
    inputContainer: {
        backgroundColor: "#ffffff",
        borderTopRightRadius: "0.25em",
        borderBottomRightRadius: "0.25em",
        padding: "1px 10px 1px 10px",
        fontFamily: "'Numans', sans-serif !important",
    },
    inputField: {
        backgroundColor: "#ffffff",
        borderBottom: "none",
        fontFamily: "'Numans',sans-serif",
        width: "100%",
        div: {
            backgroundColor: "#ffffff",
            borderBottom: "none"
        },
        "div::before": {
            backgroundColor: "#ffffff",
            borderBottom: "none",
            content: "none"
        },
        input: {
            padding: "5px 12px 4px 12px"
        }

    },
    submitBtn: {
        textTransform: "none",
        fontFamily: "'Numans',sans-serif",
        color: "black",
        backgroundColor: "#FFC312",
        "&:hover": {
            color: "black",
            backgroundColor: "white"
        }
    },
    loginBtn: {
        width: "100px",
    },
    resetBtn: {
        width: "200px"
    },
    links: {
        color: "white"
    },
    linksA: {
        color: "white",
        marginLeft: "4px",
        "&:hover": {
            color: "white"
        },
        "&:visited": {
            color: "white"
        }
    }
}));


const Login: React.FC = () => {
    const {isDebug} = useAppContext()

    isDebug && console.log("On Login Page")

    const [flashingErrorMessage, setFlashingErrorMessage] = useAtom(flashingErrorMessageAtom)

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState(false)
    const loginState = useAtomValue(loginStateAtom)
    const [submitted, setSubmitted] = useState(loginState === LoginState.Authenticating)

    const {t} = useTranslation()
    const navigate = useNavigate()

    const {classes} = useStyles()

    const signInService = useSignInService()

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        isDebug && console.log("handlingSubmit")
        setFlashingErrorMessage && setFlashingErrorMessage("")

        if (email.trim() === "" || password.trim() === "") {
            setSubmitted(false)
            setFlashingErrorMessage && setFlashingErrorMessage("Please enter an email and password")
        } else {
            setSubmitted(true)
            // This should be trimmed on set, but, in case of multiple paths or we forget somewhere, I'm just gonna retrim here also - single point of access and all that.
            signInService.signIn(email.trim(), password.trim()).then(e => {
                isDebug && console.log("user session service signIn ", e)

                if (e.status === LoginState.NewPasswordRequired) {
                    setFlashingErrorMessage("Please set a new password")
                    setSubmitted(false)
                    setNewPassword(true)
                }
                if (e.status === LoginState.AuthenticationFailed) {
                    setFlashingErrorMessage(e.error?.toString() || "Error")
                    setSubmitted(false)
                }
                if (e.status === LoginState.AuthenticationSuccess || e.status === LoginState.UserReady || e.status === LoginState.Authenticating) {
                    navigate("/home")
                }
            }).catch(err => {
                setFlashingErrorMessage("A system error occurred, please try again")
                setSubmitted(false)
                console.log("ERROR", err)
            })
        }
    }

    return newPassword ? <NewPassword email={email}/> : (
        <Box className={clsx(classes.Login, classes.container)}>
            <Box className={classes.card}>
                    <Box className={classes.cardHeaderBox}>
                        <Typography variant="h5" className={classes.cardHeader}>{t("login.pageTitle")}</Typography>
                    </Box>
                    <Box>
                        {submitted ?
                            (<div style={{"width": "100%", "marginTop": "50px"}}>
                                <div style={{"margin": "0 auto", "width": "32px", "color": "#ffffff"}}>
                                    <Spinner/>
                                </div>
                            </div>)
                            :
                            <form onSubmit={handleSubmit}>
                                {flashingErrorMessage ? (
                                    <div className={classes.formControl}>
                            <span id="formError" className="text-danger">
                               {flashingErrorMessage}
                            </span>
                                    </div>
                                ) : ""}
                                <FormGroup className={classes.formControl}>
                                    <Grid container alignItems="flex-end" wrap="nowrap">
                                        <Grid item className={classes.prependIcon}>
                                            <Box p={1}><i className="fas fa-user"></i></Box>
                                        </Grid>
                                        <Grid item className={classes.inputContainer} xs={12}>
                                            <TextField className={classes.inputField} id="login-email" placeholder={t("general.emailAddress")}
                                                       variant="filled"
                                                       size="small"
                                                       name="email" value={email} onChange={e => setEmail(e.target.value.toLowerCase().trim())}/>
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                                <FormGroup className={classes.formControl}>
                                    <Grid container alignItems="flex-end" wrap="nowrap">
                                        <Grid item className={classes.prependIcon}>
                                            <Box p={1}><i className="fas fa-key"></i></Box>
                                        </Grid>
                                        <Grid item className={classes.inputContainer} xs={12}>
                                            <TextField id="login-password" name="password" placeholder={t("general.password")} className={classes.inputField}
                                                       type="password"
                                                       variant="filled"
                                                       size="small"
                                                       value={password} onChange={e => setPassword(e.target.value.trim())}/>
                                        </Grid>
                                    </Grid>
                                </FormGroup>
                                <Box className={classes.formControl}>
                                    <Grid container alignItems="flex-end" justifyContent="flex-end">
                                        {isDebug && false &&
                                        <Grid item><ClientSelect className={classes.inputField}/></Grid>
                                        }
                                        <Grid item><Button type="submit" value="Login" id="login-button" className={clsx(classes.submitBtn, classes.loginBtn)}>{t("login.login")}</Button></Grid>
                                    </Grid>
                                </Box>
                            </form>
                        }
                    </Box>
                    <Box className={classes.cardFooter}>
                        <Grid container alignItems="flex-end">
                            {submitted ? "" :
                                <a href="/forgot-password" className={classes.linksA}>{t("login.forgotPassword")}</a>
                            }
                        </Grid>
                    </Box>
            </Box>
        </Box>
    );
}

export default Login;
