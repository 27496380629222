import React, {useCallback, useMemo, useState} from "react"
import {useAppContext} from "../../lib/context";
import Container from "../../components/draggableList";
import {DndProvider} from "react-dnd";
import {Box, Button, FormControlLabel, Switch, Typography} from "@mui/material";
import {isTouchDevice, Item} from "../../components/draggableList/DnDContainer";
import {API, api} from "../../lib/Api";
import SaveIcon from '@mui/icons-material/Save';
import {BackHand, Cancel} from "@mui/icons-material";
import {green, red} from "@mui/material/colors";
import {
    InternshipSiteId,
    InternshipSiteTrack, InternshipSiteTrackId,
    Organization,
    OrganizationId,
    SiteTrackStudentCycleRank
} from "../model/Organization";
import {EligibleStudent} from "../lib/EligibleStudentService";
import {SiteTrackSelect, siteTracksFromOrganization} from "./SiteTrackSelect";
import {headOption, requireGroup} from "../../lib/util";
import Groups from "../../model/Groups";
//import useTrackRankAttestation from "../../internship-site/component/TrackRankAttestation";
import {useNavigate, useParams} from "react-router-dom";
import {HTML5Backend} from "react-dnd-html5-backend";
import {TouchBackend} from "react-dnd-touch-backend";
import {PageBlurb} from "../../components/PageBlurb";
import {Client, Clients, ClientSettingsOps} from "../../client/model/Client";
import SpinnerCard from "../../components/SpinnerCard";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";
import {clientAtom, clientCycleAtom} from "../../Atoms";
import {UserInformation} from "../../login/model/UserInformation";
import {StudentAutoCompleteSelect} from "./StudentAutoCompleteSelect";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {Optional} from "../../lib/Optional";
import {useModalService} from "../../components/modal/ModalService";
import {LoggedInComponentWrapper, WrappedLoggedInContextProps} from "../../components/LoggedInPage";
import useEligibleStudentServiceForTrack from "../lib/EligibleStudentServiceForTrack";
import {ItemComponent} from "./DndItemComponent";
import {StudentSelect} from "./StudentSelect";

interface StudentRankListProps {
    organizationId: OrganizationId
    siteId: InternshipSiteId
    siteTrackId: InternshipSiteTrackId | undefined
}


export const StudentRankListWrapper: LoggedInComponentWrapper = (props: WrappedLoggedInContextProps) => {
    const {isDebug} = useAppContext()

    const params = useParams()

    const orgId = Optional.apply(params.organizationId).map(x => parseInt(x)).getOrElse(0)
    const siteId = Optional.apply(params.siteId).map(x => parseInt(x)).getOrElse(0)
    const trackId = Optional.apply(params.siteTrackId).map(x => parseInt(x)).getOrElse(0)

    isDebug && console.log("Updating student rank list wrapper to trackId", trackId)

    const context = useMemo(() => ({
        organizationId: orgId,
        siteId: siteId,
        siteTrackId: trackId,
        ...props
    }), [orgId, siteId, trackId, props])

    return context.state === "ready" ? <StudentRankList {...context}/> : <SpinnerCard message="Loading..."/>
}

export const StudentRankList = (props: StudentRankListProps) => {
    const {isDebug} = useAppContext()

    const userInformation: UserInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client

    isDebug && console.log("Student Rank List", props.siteTrackId)

    const locked = ClientSettingsOps.rankListLocked(client.settings) && !requireGroup(userInformation?.userGroups, Groups.globalAdmin)

    const organizationQuery = useQuery({
        queryFn: () => api<Organization, undefined>(API.getOrganization(props.organizationId), userInformation, client.id)
            .then<Organization>(e => e.data),
        queryKey: ["organization", props.organizationId],
        staleTime: 86400000
    })

    // We need this for something?
    //const attestation = useTrackRankAttestation(props.organizationId, props.siteId)

    const buildSiteTrack = useCallback(() => {
        if (organizationQuery.isSuccess) {
            return organizationQuery.data.internshipSite.siteTracks.length === 0 ? undefined :
                organizationQuery.data.internshipSite.siteTracks.find(e => e.id === props.siteTrackId) || organizationQuery.data.internshipSite.siteTracks[0]
        }
        else {
            return undefined
        }
    }, [props.siteTrackId, organizationQuery])

    if (organizationQuery.isSuccess && organizationQuery.data.internshipSite.siteTracks.length === 0) {
        return <Box>This Organization has no Tracks</Box>
    }
    else {
        return (organizationQuery.isSuccess) ? <Box style={{marginBottom: 20}}>
            <StudentRankListDisplay client={client}
                                    userInformation={userInformation}
                                    organization={organizationQuery.data}
                                    siteTrack={buildSiteTrack()!}
                                    locked={locked}/>
        </Box> : <SpinnerCard message="Loading..."/>
    }
}


const StudentRankListDisplay: React.FC<{
   client: Client,
    userInformation: UserInformation,
    organization: Organization,
    siteTrack: InternshipSiteTrack,
    locked: boolean
}> = ({client, userInformation, organization,
    siteTrack, locked}) => {

    const {pageIncrement, isDebug} = useAppContext()

    console.log("Organization", organization)
    console.log("Site Track", siteTrack)

    const [clearingHouse, setClearingHouse] = useState<boolean>(siteTrack.clearingHouseOptIn)

    const navigate = useNavigate()
    const modal = useModalService()

    const siteListStudentRanksQuery = useQuery({
        queryFn: () => api<SiteTrackStudentCycleRank[], any>(API.listSiteTrackStudentRank(organization.id!, organization.internshipSite.id!, siteTrack.id!), userInformation, client.id)
            .then<SiteTrackStudentCycleRank[]>(resp => resp.data),
        queryKey: ["siteTrackStudentRank", organization.id!, organization.internshipSite.id!, siteTrack.id!],
        staleTime: 86400000
    })

    const clearingHouseMutation = useMutation({
        mutationFn: (value: boolean) => api(API.updateTrack(organization.id!, organization.internshipSite.id!, siteTrack.id!), userInformation, client.id, {
                ...siteTrack,
                clearingHouseOptIn: value
            })
    })

    const eligibleStudentsQuery = useEligibleStudentServiceForTrack({
        organizationId: organization.id!,
        siteId: organization.internshipSite.id!,
        trackId: siteTrack.id!
    })

    useQuery({
        queryFn: () => new Promise((resolve, reject) => {
            if (eligibleStudentsQuery.isSuccess && siteListStudentRanksQuery.isSuccess) {
                const invalidStudents = siteListStudentRanksQuery.data.filter(e => !eligibleStudentsQuery.data.map(x => x.student.email).includes(e.student))
                if (invalidStudents.length > 0) {
                    modal.errorMessage("Invalid Students", "The following students are no longer available: " + invalidStudents.map(e => e.student).join(", ") + "\nPlease adjust the rank list accordingly.")
                }
                resolve(invalidStudents)
            }
            else {
                reject("Not ready")
            }
        }),
        queryKey: ["invalidStudents", organization.id!, organization.internshipSite.id!, siteTrack.id!],
        staleTime: 86400000,
        enabled: siteListStudentRanksQuery.isSuccess && eligibleStudentsQuery.isSuccess
    })

    const updateClearingHouse = (value: boolean) => {
        clearingHouseMutation.mutateAsync(value).then(e => {
            setClearingHouse(value)
        })
    }

    const updateSiteTrackId = (siteTrackId: InternshipSiteTrackId) => {
        isDebug && console.log("Navigating to site track id", siteTrackId)
        navigate(`/siteStudentRank/${organization.id!}/${organization.internshipSite.id!}/${siteTrackId}`)
    }


    return (eligibleStudentsQuery.isSuccess && siteListStudentRanksQuery.isSuccess) ? <>
        {!locked &&
            <Box>
                <PageBlurb name="rank_list" roleSpecific={false}/>
            </Box>
        }
        {locked &&
            <Box m={1}>
                <Typography style={{color: red["500"]}}>
                    Your Rank List has been recorded for the Match and cannot be edited. You should have
                    received a copy of your final list in an automated email from welcome@practicumfit.com with
                    the subject "Your Final Rank Order List."
                </Typography>
            </Box>
        }

        <Box>
            <SiteTrackSelect siteTracks={siteTracksFromOrganization(organization)} siteTrackId={siteTrack.id!}
                             setSiteTrackId={updateSiteTrackId}/>
        </Box>

        {client.id !== Clients.bapic &&
            <>
                <Box justifyItems="flex-end">
                    <FormControlLabel labelPlacement="start" style={{margin: "10px 0 5px 0"}}
                                      control={<Switch checked={clearingHouse} color="primary"
                                                       onChange={e => updateClearingHouse(!clearingHouse)}
                                                       name="clearingHouse"/>}
                                      label={`Opt Into the ${client?.settings.clearingHouseLabel} Phase.`}
                    />
                </Box>
                <Box>
                    <Typography variant="caption">
                        If you opt in above, remaining openings will stay listed for this track after the
                        match.
                        (If you do not opt in, this track's openings will be reset to 0 after the match.).
                    </Typography>
                </Box>
            </>
        }

        <StudentRankingListComponent client={client}
                                     userInformation={userInformation}
                                     organization={organization}
                                     siteTrack={siteTrack}
                                     initialItems={siteListStudentRanksQuery.data || []}
                                     eligibleStudents={eligibleStudentsQuery.data || []}
                                     key={`srlc-${siteTrack.id!}-${pageIncrement}`}
                                     locked={locked}/>

    </> : <SpinnerCard message="Loading eligible students..."/>
}

const StudentRankingListComponent: React.FC<{
    client: Client,
    userInformation: UserInformation,
    organization: Organization,
    siteTrack: InternshipSiteTrack,
    initialItems: SiteTrackStudentCycleRank[],
    eligibleStudents: EligibleStudent[],
    locked: boolean
}> = ({client, userInformation, organization, eligibleStudents,
          siteTrack, initialItems, locked}) => {
    const {isDebug, setPageIncrement} = useAppContext()
    const modal = useModalService()

    const [isSaving, setIsSaving] = useState<boolean>(false)

    const blocked = (!requireGroup(userInformation?.userGroups, Groups.siteAdmin) && !isDebug)

    console.log("eligibleStudents", eligibleStudents)
    console.log("initialItems", initialItems)

    const deleteClickHandler = (x: SiteTrackStudentCycleRank) => {
        modal.confirmDelete(x, {
            onDelete: (d) => {
                setItems(items => [...items.filter(x => x.data.student !== d.student)])
                setHasChange(true)
            }
        }, "Are you sure you wish to remove student " + x.student + "?")
    }

    const makeItem = (x: SiteTrackStudentCycleRank, i: number): Item<SiteTrackStudentCycleRank> => {
        const e = headOption(eligibleStudents.filter(e => e.student.email === x.student))

        console.log("rank list element", e)
        return e.fold(() => ({
            component: <ItemComponent x={x} i={i} userInformation={userInformation} student={{email: x.student, student: undefined}} deleteClickHandler={deleteClickHandler}/>,
            data: x,
            id: x.student,
            newItem: false
            }), e =>
        ({
            component: <ItemComponent x={x} i={i} userInformation={userInformation} student={e!} deleteClickHandler={deleteClickHandler}/>,
            data: x,
            id: x.student,
            newItem: false
        })
        )
    }

    const [items, setItems] = useState<Item<SiteTrackStudentCycleRank>[]>(initialItems.map((e, i) => makeItem(e, i + 1)))
    const [hasChange, setHasChange] = useState<boolean>(false)

    const clientCycle = useAtomValue(clientCycleAtom)
    const queryClient = useQueryClient()

    isDebug && console.log("Has change", hasChange)
    isDebug && console.log("Items", items)

    const saveRankingMutation = useMutation({
        mutationFn: (items: SiteTrackStudentCycleRank[]) =>
            api(API.updateSiteTrackStudentRank(organization.id!, organization.internshipSite.id!, siteTrack.id!), userInformation, client.id,
                items.map(x => ({
                    studentEmail: x.student,
                    siteId: x.internshipSite.id,
                    trackId: siteTrack.id,
                    position: x.position
                })))
    })

    const saveRanking = () => {
        setIsSaving(true)
        saveRankingMutation.mutateAsync(items.map(x => x.data)).then(e => {
            queryClient.refetchQueries({
                queryKey: ["siteTrackStudentRank", organization.id!, organization.internshipSite.id!, siteTrack.id!]
            }).then(e => {
                modal.message("Ranking Saved", "Your ranking has been saved.")
                setHasChange(false)
                setIsSaving(false)
                // Resorting to page increment as a re-render flag. Don't love it.
                setPageIncrement && setPageIncrement(new Date())
            })
        })
    }

    const addStudent = (student: EligibleStudent) => {
        isDebug && console.log("Adding student", student)
        const x: SiteTrackStudentCycleRank = {
            siteTrack: siteTrack,
            internshipSite: organization!.internshipSite,
            student: student.student.email,
            clientCycle: clientCycle,
            position: items.length + 1
        }

        setItems(items => [...items, makeItem(x, items.length + 1)])
        setHasChange(true)
    }

    const reset = () => {
        setItems(initialItems.map((e, i) => makeItem(e, i + 1)))
    }

    const eligibleStudentsNotAlreadyRanked = eligibleStudents.filter(e => !items.map(x => x.data.student).includes(e.student.email))

    if (eligibleStudentsNotAlreadyRanked.length === 0 && items.length === 0) {
        return <Box className="m-3 rounded-2xl backdrop-brightness-105 flex">
            <Box className="stop m-2">
                <BackHand/>
            </Box>
            <Box className="my-2">
                <Typography variant="h6" className="stop"> There are no eligible students available to rank.</Typography>
            </Box>
        </Box>
    }

    return isSaving ? <SpinnerCard message="Saving..."/> : <>
    {(((requireGroup(userInformation?.userGroups, Groups.siteAdmin) || isDebug) && !locked) || (requireGroup(userInformation?.userGroups, Groups.globalAdmin) && locked)) && <>
        <Box>
            <Typography>
                Search for students by name or email. Click "Add Student" to add them to the Rank List
                below. Drag and drop to reorder, then make sure to save your ranking.
            </Typography>
        </Box>
        <Box display="flex">
            {client.settings.clientCycleSettings.applicationSuiteSettings.useApplicationSuite ?
                <StudentSelect students={eligibleStudentsNotAlreadyRanked} addStudent={addStudent}/> :
                <StudentAutoCompleteSelect studentsOptions={eligibleStudentsNotAlreadyRanked} addStudent={addStudent}/>
            }
        </Box>
        <Box style={{paddingBottom: 10, marginTop: 20}} display="flex">
            <SaveRankingButton hasChange={hasChange} saveRanking={saveRanking}/>
            <CancelRankingButton hasChange={hasChange} resetItems={reset}/>
        </Box>
    </>
    }

    <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
        {items && items.length > 0 &&
            <Container items={items} setItems={
                updated=> {
                    setItems(items => updated.map((e, i) => makeItem(e.data, i+1)))
                    setHasChange(true)
                }
            } readonly={locked || blocked}/>
        }
    </DndProvider>

    {((requireGroup(userInformation?.userGroups, Groups.siteAdmin) || isDebug) && !locked) &&
    <Box style={{paddingBottom: 10}} display="flex">
        <SaveRankingButton hasChange={hasChange} saveRanking={saveRanking}/>
        <CancelRankingButton hasChange={hasChange} resetItems={reset}/>
    </Box>
    }
    </>
}

const SaveRankingButton: React.FC<{hasChange: boolean, saveRanking: () => void}> = (
    {hasChange, saveRanking}) => (
    <Button variant={hasChange ? "contained" : "outlined"}
            style={hasChange ? {backgroundColor: green[300]} : {}}
            onClick={e => saveRanking()} startIcon={<SaveIcon/>}>Save Ranking</Button>
)

interface CancelRankingButtonProps {
    hasChange: boolean
    resetItems: () => void
}

const CancelRankingButton: React.FC<CancelRankingButtonProps> = ({hasChange, resetItems}) => {
    return hasChange ? <Button startIcon={<Cancel/>} variant="contained" onClick={e => resetItems()}
                               style={{marginLeft: 10}}>Cancel</Button> : <></>
}

