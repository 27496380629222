import React, {useCallback} from 'react'
import {useParams} from "react-router-dom";
import QuestionnaireDisplay from "./QuestionnaireDisplay";
import QuestionnaireList, {RowAction} from "./QuestionnaireList";
import {API, api, apiError} from "../lib/Api"
import {useAppContext} from "../lib/context";
import {
    defaultQuestionnaireElement,
    Question,
    QuestionnaireElement,
    QuestionnaireElementId, QuestionnaireId,
} from "./model/Questionnaire";
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import TabPanel from "../components/TabPanel";
import SpinnerCard from "../components/SpinnerCard";
import {QuestionAnswer} from "./model/QuestionnaireSubmissionElement";
import {LoginStateCard} from "../components/LoginStateCard";
import {useDebounce} from "../lib/useDebounce"
import {Page, PageBody, PageHeader} from "../components/Page";
import {Questionnaire} from "../model/Questionnaire";
import {userInformationAtom} from "../Atoms";
import {useAtomValue} from "jotai";
import {useQuestionnaireService} from "./QuestionnaireService";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {clientAtom, loginStateAtom} from "../Atoms";
import {Client} from "../client/model/Client";

export type QuestionnaireParams = {
    id: string
}

export interface QuestionnaireInputProps {
    question: Question
    answer?: QuestionAnswer
    key?: string | number
    onChange?: (value: string) => void
    editable: boolean
    requiredBlocking?: boolean
}

export interface QuestionnaireMultiInputProps {
    elements: QuestionnaireElement[]
    key?: string | number
    onChange?: (value: string, questionId: number) => void
}

const QuestionnaireEdit = () => {

    const params = useParams<QuestionnaireParams>()

    const {isDebug, setModal} = useAppContext();

    const loginState = useAtomValue(loginStateAtom)
    const client = useAtomValue(clientAtom) as Client
    const userInformation = useAtomValue(userInformationAtom)

    const localClientId: number = client.id

    const [tabValue, setTabValue] = React.useState(0);

    const questionnaireId = (params.id && parseInt(params.id)) || 0

    const queryClient = useQueryClient()

    const questionnaireQuery = useQuestionnaireService(questionnaireId, userInformation, client)

    const questionnaireMutation = useMutation({
        mutationFn: (questionnaire: Questionnaire) => api(API.updateQuestionnaire(questionnaire.id), userInformation, localClientId, questionnaire.elements)
                .catch(error => apiError(error, setModal))
    })

    const debouncedSave = useDebounce<Questionnaire | undefined>(useCallback(q => {
        isDebug && console.log("Calling debounce")

        q && questionnaireMutation.mutateAsync(q)
    }, [isDebug, questionnaireMutation]), 1000)

    const indexOf = (id: number) => (questionnaireQuery.isSuccess && questionnaireQuery.data.elements.findIndex(x => x.id === id)) || 0

    const questionnaireChanged = (data: QuestionnaireElement[]) => {
        const updated: Questionnaire | undefined = questionnaireQuery.isSuccess ? {
            ...questionnaireQuery.data,
            elements: [
                ...(data.sort((a, b) => a.position - b.position)
                    .map((x, i) => ({...x, position: i + 1})))
            ]
        } : undefined
        queryClient.setQueryData(["questionnaire", questionnaireId], updated)
        debouncedSave(updated)
    }

    const moveToTop = (id: QuestionnaireElementId) => {
        isDebug && console.log("Asked to move to top", id)
        if (questionnaireQuery.isSuccess) {
            const index = indexOf(id)
            const thisOne = questionnaireQuery.data.elements[index]

            const updated = [thisOne].concat(questionnaireQuery.data.elements.slice(0, index)).concat(questionnaireQuery.data.elements.slice(index + 1))

            questionnaireChanged(updated)
        }
    }

    const moveUp = (id: QuestionnaireElementId) => {
        //setUpdating([id])
        isDebug && console.log("Asked to move up", id)
        if (questionnaireQuery.isSuccess) {
            const index = indexOf(id)

            isDebug && console.log("index is ", index)

            if (index > 0) {
                const thisOne = questionnaireQuery.data.elements[index];
                const before = questionnaireQuery.data.elements[index - 1];

                questionnaireQuery.data.elements[index - 1] = thisOne;
                questionnaireQuery.data.elements[index] = before;
                const updated = ([] as QuestionnaireElement[])
                    .concat(questionnaireQuery.data.elements.slice(0, index - 1))
                    .concat([{
                        ...thisOne, position: before.position
                    }, {
                        ...before, position: thisOne.position
                    }])
                    .concat(questionnaireQuery.data.elements.slice(index + 1))

                questionnaireChanged(updated)
            }

        }
    }

    const moveDown = (id: QuestionnaireElementId) => {
        if (questionnaireQuery.isSuccess) {
            const index = questionnaireQuery.data.elements.findIndex(x => x.id === id)

            if (index < (questionnaireQuery.data.elements.length - 1)) {
                const thisOne = questionnaireQuery.data.elements[index];
                const after = questionnaireQuery.data.elements[index + 1];

                questionnaireQuery.data.elements[index + 1] = thisOne;
                questionnaireQuery.data.elements[index] = after;
                const updated = ([] as QuestionnaireElement[])
                    .concat(questionnaireQuery.data.elements.slice(0, index))
                    .concat([{
                        ...after, position: thisOne.position
                    }, {
                        ...thisOne, position: after.position
                    }])
                    .concat(questionnaireQuery.data.elements.slice(index + 2))

                questionnaireChanged(updated)
            }

        }
    }

    const moveToBottom = (id: QuestionnaireElementId) => {
        if (questionnaireQuery.isSuccess) {
            const index = indexOf(id)
            const thisOne = questionnaireQuery.data.elements[index]

            const newAry = [] as QuestionnaireElement[]

            const updated = newAry.concat(questionnaireQuery.data.elements.slice(0, index))
                .concat(questionnaireQuery.data.elements.slice(index + 1)).concat([thisOne])

            questionnaireChanged(updated)
        }
    }

    const rowAction = (rowAction: RowAction, oldElement: QuestionnaireElement | null, newElement: QuestionnaireElement | null) => {
        console.log("Questionnaire Edit: rowAction", rowAction, oldElement, newElement)
        if (questionnaireQuery.isSuccess) {
            switch (rowAction) {
                case RowAction.CREATE:
                    return addRow(questionnaireId, oldElement?.id || 0)
                case RowAction.UPDATE:
                    return newElement &&
                    questionnaireChanged([...(questionnaireQuery.data.elements.filter(x => x.id !== (oldElement?.id || 0))), newElement])
                case RowAction.DELETE:
                    console.log("Deleting ", oldElement)
                    return oldElement &&
                    questionnaireChanged([...(questionnaireQuery.data.elements.filter(x => x.id !== (oldElement?.id || 0)))])
            }
        }
    }

    const addRow = (questionnaireId: QuestionnaireId, id: QuestionnaireElementId) => {
        isDebug && console.log("Asked to add question to ", questionnaireId, questionnaireQuery.data)
        if (questionnaireQuery.isSuccess) {
            const index = indexOf(id)
            if (index === -1) {
                questionnaireChanged([{
                    ...defaultQuestionnaireElement(localClientId, questionnaireId),
                    position: 1,
                    isNew: true
                }])
            } else {
                const thisOne = questionnaireQuery.data.elements[index]

                const newElement: QuestionnaireElement = {
                    ...defaultQuestionnaireElement(localClientId, questionnaireId),
                    position: thisOne.position + 1,
                    isNew: true
                }

                const updated = ([] as QuestionnaireElement[])
                    .concat(questionnaireQuery.data.elements.slice(0, index + 1))
                    .concat([newElement])
                    .concat(questionnaireQuery.data.elements.slice(index + 1).map(x => {
                        return {
                            ...x, position: x.position + 1
                        }
                    }))

                questionnaireChanged(updated)
            }
        }
    }

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    const a11yProps = (index: any) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const {useLoginStateCard, component} = LoginStateCard(loginState, client)

    isDebug && questionnaireQuery.isSuccess && console.log("Questionnaire", questionnaireQuery.data)

    return useLoginStateCard ? component : (
        <Page navName="Questionnaires" supportsClientChange={false}>
            <PageHeader title={questionnaireQuery.isSuccess ? (questionnaireQuery.data?.name || "Questionnaire") : ""}/>
            <PageBody>
                {questionnaireQuery.isSuccess ? (
                    <React.Fragment>
                        <AppBar position="static" style={{backgroundColor: "#ffffff", color: "#404040"}}>
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example">
                                <Tab label="Edit" {...a11yProps(0)} aria-label="Edit Questionnaire"/>
                                <Tab label="Preview" {...a11yProps(1)} aria-label="Preview Questionnaire"/>
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <QuestionnaireList questionnaireId={questionnaireId} questionnaire={questionnaireQuery.data.elements}
                                               moveUp={moveUp} moveDown={moveDown}
                                               moveToBottom={moveToBottom} moveToTop={moveToTop}
                                               rowAction={rowAction}
                            />
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            <QuestionnaireDisplay questionnaireElements={questionnaireQuery.data.elements} questionnaireId={questionnaireId}/>
                        </TabPanel>
                    </React.Fragment>
                ) : <SpinnerCard message="Loading Questionnaire"/>}
            </PageBody>
        </Page>
    )
}

export default QuestionnaireEdit