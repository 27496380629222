import {ZodNullable, ZodObject, ZodOptional, ZodRawShape, ZodRecord, ZodTypeAny} from "zod";
import {EditTimestamp} from "../../client/component/EditTimestamp";
import {isBooleanField, isDateTimeFieldName} from "../../lib/Api";
import {EditCheckbox} from "../../client/component/EditCheckbox";
import {Box, Typography} from "@mui/material";
import {EditText} from "../../client/component/EditText";
import React from "react";

export const RenderKey: React.FC<{data: any, property: string | number, prefix?: string, validator: ZodObject<ZodRawShape> | ZodRecord | undefined, classes: Record<string, string>}> =
    ({data, property, prefix, validator, classes}) => {
    if (data) {
        //console.log("Rendering Settings:", data, property, validator, typeof(data[property]))

        const value = data[property]
        const name = (prefix || "") + (prefix ? "." : "") + property

        if (value instanceof Date) {
            return <EditTimestamp name={name} value={value} classes={classes}/>
        }

        if (typeof property === "string" && (isDateTimeFieldName(property) && typeof(value) === "string")) {
            return <EditTimestamp name={name} value={new Date(value)} classes={classes}/>
        }

        if (typeof property === "string" && isBooleanField(property) && typeof(value)==="string" && (value === "yes" || value === "no")) {
            return <EditCheckbox name={name} value={value} classes={classes}/>
        }

        const getZodShape = (validator: ZodObject<ZodRawShape> | ZodRecord) => {
            if (validator._def.typeName === "ZodRecord") {
                return (validator as ZodRecord)._def.valueType
            }
            else {
                const zodShape: ZodTypeAny = (typeof property === "string") ? (validator as ZodObject<ZodRawShape>).shape[property] : validator
                // I hope it's always nested this way around... nope....
                if (zodShape._def.typeName === "ZodOptional" || zodShape._def.typeName === "ZodNullable") {
                    if ((zodShape as ZodOptional<ZodTypeAny>)._def.innerType._def.typeName === "ZodNullable") {
                        return ((zodShape as ZodOptional<ZodTypeAny>)._def.innerType as ZodNullable<ZodTypeAny>)._def.innerType
                    }
                    return (zodShape as ZodOptional<ZodTypeAny>)._def.innerType
                } else {
                    return zodShape
                }
            }
        }

        if (validator) {
            const zodShape = getZodShape(validator)

            //console.log("With validator typeName", zodShape._def.typeName, zodShape)

            switch (zodShape._def.typeName) {
                case "ZodObject":
                case "ZodRecord":
                    return (<Box m={2}>
                        <Typography variant="h5">{property}</Typography>
                        {Object.keys(value).map(k =>
                            <RenderKey data={value} classes={classes} property={k} prefix={name} validator={zodShape as (ZodObject<ZodRawShape> | ZodRecord)}/>)
                        }
                    </Box>)
                case "ZodNumber":
                    return (<EditText name={name + "-numeric"} label={name} value={String(value)} classes={classes}/>)
                case "ZodBoolean":
                    return (<EditCheckbox name={name + "-bool"} label={name} value={value ? "yes" : "no"} classes={classes}/>)
                case "ZodDate":
                    return <>ZodDate<EditTimestamp name={name} value={new Date(value)} classes={classes}/></>
                case "ZodArray":
                    const elem = value as Array<any>
                    return (<>{elem.map((e, i) =>
                            <RenderKey data={elem} property={i} prefix={name} validator={zodShape._def.type} classes={classes}/>
                        )}</>)
                case "ZodAny":
                    return (<Box m={2}>
                    <Typography variant="h5">{property}</Typography>
                {Object.keys(value).map(k => <RenderKey classes={classes} data={value} property={k} prefix={name} validator={undefined}/>)}
                    </Box>)
                case "ZodString" :
                default:
                    return (<EditText name={name} value={String(value)} classes={classes}/>)
            }
        }
        else {
            if (value === null || value === "undefined") {
                return (<EditText name={name} value={""} classes={classes}/>)
            }
            switch (typeof value) {
                case "object":
                    return (<Box m={2}>
                        <Typography variant="h5">{property}</Typography>
                        {Object.keys(value).map(k => <RenderKey classes={classes} data={value} property={k} prefix={name} validator={validator}/>)}
                    </Box>)
                case "number":
                    return (<EditText name={name + "-numeric"} label={name} value={String(value)} classes={classes}/>)
                case "boolean":
                    return (<EditCheckbox name={name + "-bool"} label={name} value={value ? "yes" : "no"} classes={classes}/>)
                case "string" :
                default:
                    return (<EditText name={name} value={String(value)} classes={classes}/>)
            }
        }
    }
    else return <></>
}
