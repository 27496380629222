import React, {useCallback, useState} from 'react'
import {useAppContext} from "../../lib/context";
import {API, api, apiError} from "../../lib/Api";
import {useNavigate} from "react-router-dom";
import {
    QuestionAnswerSave, QuestionnaireDataAggregate
} from "../../questionnaire/model/QuestionnaireSubmissionElement";
import {QuestionnaireId} from "../../questionnaire/model/Questionnaire";
import SpinnerCard from "../../components/SpinnerCard";
import QuestionnaireDisplay from "../../questionnaire/QuestionnaireDisplay";
import {Box} from "@mui/material";
import {
    LoggedInComponentWrapper,
    StudentLoggedInComponent,
    WrappedLoggedInContextProps
} from "../../components/LoggedInPage";
import {StudentUserInformation} from "../../login/model/UserInformation";
import {useFetchStudentQuestionnaireData} from "../lib/StudentQuestionnaireService";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {UseQueryResult} from "@tanstack/react-query/src/types";
import {useModalService} from "../../components/modal/ModalService";
import {refreshUser} from "../../login/lib/UserSessionsService";


const StudentQuestionnaireSubmission: StudentLoggedInComponent<{questionnaireId: QuestionnaireId}> = (props) => {
    const {userInformation, client, questionnaireId} = props
    const {isDebug, setModal} = useAppContext();

    const queryClient = useQueryClient()

    const modalService = useModalService()

    const [saving, setSaving] = useState<boolean>(false)

    const navigate = useNavigate()

    const onChange = useCallback((qas: QuestionAnswerSave[]) => {
        isDebug && console.log("onChange", qas)
    }, [isDebug]);

    const questionnaireSubmissionMutation = useMutation({
        mutationFn: (data: {qas: QuestionAnswerSave[], draft: boolean}) =>
            api(API.submitStudentQuestionnaire(userInformation.guid, questionnaireId, data.draft), userInformation, client.id, data.qas),
    })

    const handleSubmit = (qas: QuestionAnswerSave[], draft: boolean) => {
        setSaving(true)
        questionnaireSubmissionMutation.mutateAsync({qas: qas, draft: draft})
            .then(x => {
                queryClient.invalidateQueries(["questionnaire", questionnaireId])
                return refreshUser(queryClient).then(e => x)
            })
            .then(x => {
                isDebug && console.log("Saved")
                modalService.message( "Questionnaire Submitted", "Your Answers have been submitted.", {onClose: () => {
                            setSaving(false)
                            navigate("/studentApplication")
                        }}
                )
            })
            .catch(err => {
                apiError(err, setModal)
            })
    }

    const questionnaireDataQuery: UseQueryResult<QuestionnaireDataAggregate, undefined> =
        useFetchStudentQuestionnaireData(userInformation, userInformation.guid, client.id, questionnaireId)

    if (questionnaireDataQuery.isError) {
        return <Box>API Error</Box>
    }

    console.log("State", questionnaireDataQuery.data, saving)

    return <Box m={3}>
        {(!saving &&  !questionnaireDataQuery.isLoading) ? (
                    <QuestionnaireDisplay questionnaireElements={questionnaireDataQuery.data.elements}
                                          handleSubmit={handleSubmit} onChange={onChange}
                                          questionnaireId={questionnaireId}
                                          key={questionnaireId}/>
        ) : <SpinnerCard/>}
    </Box>
}
const StudentQuestionnaireSubmissionWrapper: LoggedInComponentWrapper = (context: WrappedLoggedInContextProps) => {
    if (context.state === "ready") {
        if (context.userInformation.student && context.client.settings.clientCycleSettings.applicationSuiteSettings.materialSlotsDefinition.studentQuestionnaireId) {
            const studentUser = context.userInformation as StudentUserInformation
            const props = {
                ...context,
                userInformation: studentUser,
                questionnaireId: context.client.settings.clientCycleSettings.applicationSuiteSettings.materialSlotsDefinition.studentQuestionnaireId
            }
            return <StudentQuestionnaireSubmission {...props}/>
        }
        else {
            return <Box>Error - Not a student user</Box>
        }
    }
    else {
        return <SpinnerCard message="Loading Context..."/>
    }
}

export default StudentQuestionnaireSubmissionWrapper