import {Box, IconButton, Tooltip} from "@mui/material";
import {StudentOps} from "../model/Student";
import {DownloadsControl} from "../../components/DownloadsControl";
import {API, getApiUrl} from "../../lib/Api";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import AssignmentTurnedInIcon from "@mui/icons-material/AssignmentTurnedIn";
import React from "react";
import {StudentUserInformation} from "../../login/model/UserInformation";
import {InternshipSite} from "../../internship-site/model/Organization";
import {clientAtom} from "../../Atoms";
import {Client} from "../../client/model/Client";
import {useAtomValue} from "jotai";

export const StudentExpandoElement: React.FC<{
    site: InternshipSite,
    row: StudentUserInformation,
    showSubmittedOnly: boolean
}> = ({site, row, showSubmittedOnly}) => {
    console.log("SiteExpendo", site)
    const client = useAtomValue(clientAtom) as Client

    if (row.email === "sseeman@ego.thechicagoschool.edu") {
        console.log("StudentExpandoElement student", row)
    }

    return <Box display="flex" flexWrap="nowrap" >
        <Box flexGrow={1} className="flex-col">
            <Box>
            {site.name}
            </Box>
            {client.settings.clientCycleSettings.applicationSuiteSettings.useApplicationSuite && site.siteTracks.map(track => {
                const submitted = StudentOps.isApplicationSubmitted(StudentOps.userApplicationsForSiteTrack(row, site.id || 0, track?.id))
                return <Box className="flex-row flex ml-4 width-full bg-slate-50 m-1 p-1 pl-3 items-center">
                    <Box className="hidden">
                        <pre>
                        Site ID: {site.id}
                        Track ID: {track?.id}
                        Email: {row.email}
                        Student: {JSON.stringify(row.student.studentApplicationPackets, null, 2)}
                        {JSON.stringify(StudentOps.userApplicationsForSiteTrack(row, site.id || 0, track?.id))}
                        </pre>
                    </Box>
                    <Box flexGrow={1}>{track.name}</Box>
                {client.settings.clientCycleSettings.applicationSuiteSettings.useApplicationSuite &&
                    <Box>
                        {submitted ? "Submitted" : "Pending"}
                    </Box>
                }
                <Box ml={2}>
                    {(submitted || !showSubmittedOnly) && <DownloadsControl label="" disposition="inline"
                                                                            icon={<PictureAsPdfIcon/>}
                                                                            variant="text"
                                                                            downloadUrl={getApiUrl() + API.downloadPacketPDF(row.guid, site.id || 0, track?.id, "inline").location}/>
                    }
                </Box>
                {submitted && false &&
                    <Box>
                        <Tooltip title="Application Approval">
                            <IconButton href={`/program/approval/${row.guid}/${site.id}/0`} target="_blank">
                                <AssignmentTurnedInIcon/>
                            </IconButton>
                        </Tooltip>
                    </Box>
                }
                </Box>
            })}
        </Box>
    </Box>
}