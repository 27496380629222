import {InternshipSiteTrack, Organization} from "../model/Organization";
import {useQuery} from "@tanstack/react-query";
import {API, api} from "../../lib/Api";
import {listOrganizationQueryKey, ListOrganizationServiceProps} from "./OrganizationService";
import {Client} from "../../client/model/Client";
import {UserInformation, UserOps} from "../../login/model/UserInformation";
import {UseQueryResult} from "@tanstack/react-query/src/types";


export const useOrganizationsService = (props: ListOrganizationServiceProps & {userInformation: UserInformation, client: Client}): UseQueryResult<Organization[]> =>
    useQuery({
        queryFn: () => api<Organization[], undefined>(API.listOrganizations(props.viewName), props.userInformation, props.client.id)
                .then(response => response.data),
        queryKey: listOrganizationQueryKey(props.client.id, props.viewName),
        staleTime: 86400000
    })

export const asFilteredOrganizationList = (userInformation: UserInformation, p: Promise<Organization[]>) =>
    p.then(orgs => orgs.filter(e => UserOps.canAdminSite(userInformation, e.internshipSite.id!)))

export const asFilteredSiteTrackList = (userInformation: UserInformation, p: Promise<Organization[]>) =>
    asFilteredOrganizationList(userInformation, p)
        .then<InternshipSiteTrack[]>(x => x.flatMap((e: Organization) => e.internshipSite.siteTracks))
