import React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Page, PageBody, PageHeader} from "./Page";

export const AuthenticationFailed = () => (
    <Page navName="">
        <PageHeader title="Authentication Failed"/>
        <PageBody>
                <span className="modalIcon" style={{"color": "80000"}}><FontAwesomeIcon icon={"exclamation-triangle"}/></span>
                You are not authenticated or not authorized to view this page.  Please visit <a href="/login">the login page</a> to try again.
        </PageBody>
    </Page>
)