import {ProgramId} from "../../program/model/Program";
import {InternshipSiteId, InternshipSiteTrackId} from "../../internship-site/model/Organization";
import {ClientId} from "../../client/model/Client";
import Student, {StudentId, StudentOps} from "../../student/model/Student";
import User from "../../user/model/User";
import {None, Optional} from "../../lib/Optional";
import {requireOneOfGroup} from "../../lib/util";
import Groups from "../../model/Groups";

export interface UserInformationCognitoAttributes {
    "email": string
    "given_name": string | undefined
    "family_name": string | undefined
}

export abstract class UserInformationShape implements User {
    guid: string = ""
    email: string = ""
    assumedEmail?: string | undefined
    firstName?: string
    lastName?: string
    phoneNumber?: string
    clientId?: number | undefined
    cycleId?: number | undefined
    password?: string
    newPassword?: string
    userGroups?: string[]
    programs?: ProgramId[]
    sites?: InternshipSiteId[]
    studentId?: StudentId
    jwtToken?: string
    isPending: boolean
    createdTimestamp: Date
    lastActivityTimestamp: Date | undefined

    constructor(props: {email: string, guid?: string | undefined, assumedEmail?: string | undefined, password?: string, newPassword?: string,
        firstName?: string, lastName?: string, phoneNumber?: string, clientId?: ClientId, userGroups?: string[],
        jwtToken?: string, isPending?: boolean, createdTimestamp?: Date}) {
        this.email = props.email
        this.guid = props.guid || ""
        this.assumedEmail = props.assumedEmail
        this.firstName = props.firstName
        this.lastName = props.lastName
        this.phoneNumber = props.phoneNumber
        this.clientId = props.clientId
        this.userGroups = props.userGroups
        this.newPassword = props.newPassword
        this.jwtToken = props.jwtToken
        this.isPending = props.isPending || false
        this.createdTimestamp = props.createdTimestamp || new Date()
    }

    toAttrs = (): UserInformationCognitoAttributes => ({
            "email": this.email,
            "given_name": this.firstName,
            "family_name": this.lastName
    })

    primaryGroup(): string {
        return this.userGroups ? this.userGroups[0] : 'none'
    }

    static userInformation = (email: string, password: string) => {
        return new AdminUserInformation({email: email, password: password})
    }
}

export class StudentUserInformation extends UserInformationShape {
    student: Student

    constructor(props: {student: Student, email: string, guid?: string | undefined, assumedEmail?: string | undefined, password?: string, newPassword?: string, firstName?: string, lastName?: string, phoneNumber?: string, clientId?: ClientId, userGroups?: string[], jwtToken?: string}) {
        super(props)
        this.student = props.student
    }
}

export class AdminUserInformation extends UserInformationShape {
    student?: undefined
}

export type UserInformation  = StudentUserInformation | AdminUserInformation

export class UserOps {
    static asStudentUser = (userInformation: UserInformation): Optional<StudentUserInformation> => {
        if (userInformation?.student) {
            return Optional.apply<StudentUserInformation>(userInformation as StudentUserInformation)
        }
        else return new None<StudentUserInformation>()
    }

    static canAdminSite = (userInformation: UserInformation, siteId: InternshipSiteId) =>
        requireOneOfGroup(userInformation.userGroups, [Groups.clientAdmin, Groups.globalAdmin, Groups.directoryAdmin]) ||
            (userInformation.sites || []).includes(siteId)

    static isTrackVisible  = (userInformation: UserInformation, trackId: InternshipSiteTrackId) =>
        requireOneOfGroup(userInformation?.userGroups, [Groups.siteAdmin, Groups.programAdmin, Groups.clientAdmin, Groups.globalAdmin, Groups.directoryAdmin]) ||
        UserOps.asStudentUser(userInformation).map(s => StudentOps.isTrackVisible(s, trackId)).getOrElse(false)
}