import React, {useState} from "react"
import {useAppContext} from "../../lib/context";
import Container from "../../components/draggableList";
import {DndProvider} from "react-dnd";
import {HTML5Backend} from 'react-dnd-html5-backend'
import {Box, Button, Typography} from "@mui/material";
import {isTouchDevice, Item} from "../../components/draggableList/DnDContainer";
import {StudentTrack} from "../model/Student";
import {API, api} from "../../lib/Api";
import SaveIcon from '@mui/icons-material/Save';
import {Cancel} from "@mui/icons-material";
import {green, red} from "@mui/material/colors";
import {TouchBackend} from "react-dnd-touch-backend";
import {Client, ClientSettingsOps} from "../../client/model/Client";
import {useAtomValue} from "jotai";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {clientAtom, refreshUser} from "../../Atoms";
import {useModalService} from "../../components/modal/ModalService";
import {requireGroup} from "../../lib/util";
import {StudentUserInformation} from "../../login/model/UserInformation";
import Groups from "../../model/Groups";
import {EmptyObject, StudentLoggedInComponent} from "../../components/LoggedInPage";
import SpinnerCard from "../../components/SpinnerCard";
import {StudentDnDItemComponent} from "../../internship-site/component/StudentDnDItemComponent";


export const TrackRankList: StudentLoggedInComponent<EmptyObject> =
    ({userInformation}) => {
    const {isDebug, pageIncrement} = useAppContext()

    const client = useAtomValue(clientAtom) as Client

    isDebug && console.log("Track Rank List")

    const locked = ClientSettingsOps.rankListLocked(client.settings)

    return (
        <>
                {!locked && <>
                    <Box m={1}>
                        <Typography>
                            After your interviews, rank selected tracks in order on this page to indicate your preference
                            for the match.
                            You may change your rank order at anytime until the ranking window closes.
                        </Typography>
                    </Box>
                    <Box m={1}>
                    <Typography>
                    Be advised that practicum sites may continue to change their track offerings at any time between now and the match deadline.
                    If you complete this step early, we strongly recommend that you log in again just before your Rank Order List deadline
                    to double-check that your selections and rankings are still valid.
                    </Typography>
                    </Box>
                </>
                }
                {locked ||
                <Box m={1}>
                    <Typography>
                        You can re-order your preferences by clicking and dragging each track to the order you want.
                        Once you have the order your desire,
                        you can click the "Save Ranking" button.
                    </Typography>
                </Box>
                }
                {locked &&
                <Box m={1}>
                    <Typography style={{color: red["500"]}}>
                        Your Rank List has been recorded for the Match and cannot be edited. A copy of your final list will be sent to you in an email
                        from {client.settings.fromEmail} with the subject "Your Final Rank Order List."
                    </Typography>
                </Box>
                }
                {!locked &&
                    <TrackRankListComponent initialItems={userInformation.student.tracks} userInformation={userInformation} locked={locked}
                    key={`srlc-${pageIncrement}`}/>

                }
        </>
    )
}

const SaveRankingButton: React.FC<{hasChange: boolean, items: Item<StudentTrack>[], saveRanking: (items: Item<StudentTrack>[]) => void}> =({hasChange, saveRanking, items}) => (
        <Button variant={hasChange ? "contained" : "outlined"}
                style={hasChange ? {backgroundColor: green[300]} : {}}
                onClick={e => saveRanking(items)} startIcon={<SaveIcon/>}>Save Ranking</Button>
)

interface CancelRankingButtonProps {
    hasChange: boolean
    resetItems: () => void
}

const CancelRankingButton: React.FC<CancelRankingButtonProps> = ({hasChange, resetItems}) => {
    return hasChange ? <Button startIcon={<Cancel/>} variant="contained" onClick={e => resetItems()} style={{marginLeft:10}}>Cancel</Button> : <></>
}

const TrackRankListComponent: React.FC<{
    initialItems: StudentTrack[],
    userInformation: StudentUserInformation,
    locked: boolean
}> = ({initialItems, userInformation, locked}) => {


    const {isDebug, setPageIncrement} = useAppContext()

    const client = useAtomValue(clientAtom) as Client
    const modal = useModalService()

    const blocked = false && (!requireGroup(userInformation?.userGroups, Groups.student) && !isDebug)

    const deleteClickHandler = (x: StudentTrack) => {
        modal.confirmDelete(x, {
            onDelete: (d) => {
                setItems(items => [...items.filter(x => x.data.siteTrackId !== d.siteTrackId)])
                setHasChange(true)
            }
        }, "Are you sure you wish to remove track" + x.site.name + " - " + x.track.name+ "?")
    }

    const makeItem = (x: StudentTrack, i: number): Item<StudentTrack> => {
        return {
            component: <StudentDnDItemComponent x={x} i={i} userInformation={userInformation} deleteClickHandler={deleteClickHandler}/>,
            data: x,
            id: x.siteTrackId.toString(),
            newItem: false
        }
    }

    const [items, setItems] = useState<Item<StudentTrack>[]>(initialItems.map((e, i) => makeItem(e, i+1)))
    const [isSaving, setIsSaving] = useState<boolean>(false)
    const [hasChange, setHasChange] = useState<boolean>(false)

    const queryClient = useQueryClient()


    const saveRankingMutation = useMutation({
        mutationFn: (items: StudentTrack[]) =>
            api(API.saveStudentTrackRanking(userInformation.guid), userInformation, client.id, items).then(e => {
                refreshUser(queryClient).then(x => {
                    setHasChange(false)
                    modal.message("Saved", "Track Ranking Preference Saved")
                })
            })
    })

    const saveRanking = () => {
        setIsSaving(true)
        saveRankingMutation.mutateAsync(items.map(x => x.data)).then(e => {
            refreshUser(queryClient).then(e => {
                setPageIncrement && setPageIncrement(new Date())
                modal.message("Ranking Saved", "Your ranking has been saved.", {onClose: () => {
                        setHasChange(false)
                        setIsSaving(false)
                        // Resorting to page increment as a re-render flag. Don't love it.
                    }})
            })
        })
    }

    const reset = () => {
        setItems(initialItems.map((e, i) => makeItem(e, i + 1)))
    }

    return isSaving ? <SpinnerCard message="Saving"/> : <>
        <Box style={{paddingBottom: 10}} display="flex">
            <SaveRankingButton hasChange={hasChange} items={items} saveRanking={saveRanking}/>
            <CancelRankingButton hasChange={hasChange} resetItems={reset}/>
        </Box>
        <DndProvider backend={isTouchDevice() ? TouchBackend : HTML5Backend}>
            {items && items.length > 0 &&
                <Container items={items} setItems={
                    updated=> {
                    setItems(items => updated.map((e, i) => makeItem(e.data, i+1)))
                    setHasChange(true)
                }
                } readonly={locked || blocked}/>
            }
        </DndProvider>
        {!locked &&
        <Box style={{paddingBottom: 10}} display="flex">
            <SaveRankingButton hasChange={hasChange} items={items} saveRanking={saveRanking}/>
            <CancelRankingButton hasChange={hasChange} resetItems={reset}/>
        </Box>
        }
    </>
}