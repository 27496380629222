import React from 'react';
import '../student/Student.css';
import ProgramList from "./component/ProgramList";
import {useParams} from "react-router-dom";

import {
    EmptyObject,
    LoggedInComponent,
    LoggedInComponentWrapper, LoggedInContextProps,
    WrappedLoggedInContextProps
} from "../components/LoggedInPage";
import SpinnerCard from "../components/SpinnerCard";

type ProgramParams = {
    id: string,
}

const ProgramsPageWrapper: LoggedInComponentWrapper = (context: WrappedLoggedInContextProps) =>
    context.state === "ready" ? <Programs {...context}/> : <SpinnerCard message="Loading..."/>

const Programs: LoggedInComponent<EmptyObject> = (context: LoggedInContextProps) => {
    const params = useParams<ProgramParams>()

    return <ProgramList id={(params.id && parseInt(params.id)) || 0} />
}

export default ProgramsPageWrapper
