import React, {PropsWithChildren} from "react"
import Nav from "../nav/Nav";
import {Box, Grid, Paper, Typography} from "@mui/material";
import {PageFooter} from "./Footer";
import {Theme} from "@mui/material/styles";
import {makeStyles} from "tss-react/mui";
import {useAtomValue} from "jotai";
import {pageHeadingAtom} from "../Atoms";
import {NavName} from "../nav/NavigationElements";

export type FooterPosition = "fixed" | "static"

export interface PageProps {
    navName: NavName
    bottomNavName?: string
    supportsClientChange?: boolean
    variant?: "full" | "half"
    footerPosition?: FooterPosition
    scrollingBody?: boolean
}

export const containerStyle = (footerPosition: string) => {
    const defaultStyle = {"paddingBottom": "40px"}

    if (footerPosition === "fixed") {
        return {...defaultStyle, "height": "100%"}
    } else return defaultStyle

}

export const useStyle = makeStyles()((theme: Theme) => ({
    pageContent: {
        height: "100%",
        paddingTop: "84px",
        paddingBottom: "84px",
        [theme.breakpoints.up("sm")]: {
            marginLeft: "20px;",
            marginRight: "20px;",
        },
        [theme.breakpoints.down('md')]: {
            margin: "0px;",
            marginRight: "0px;",
        }
    },
    pageContentHalf: {
        paddingTop: "84px",
        height: "100%",
        margin: "20px auto;",
        paddingBottom: "84px",
    },
    pagePaperScrolling: {
        height: "100%",
        overflowY: "scroll"
    },
    containerScrolling: {
        height: "100%"
    },
    innerContainerScrolling: {
        height: "calc(100% - 121px)"
    },
    pagePaper: {
        backgroundColor: "rgba(235,235,235,0.97)"
    }
}))

export const Page = (props: PropsWithChildren<PageProps>) => {
    const {classes} = useStyle()

    console.log("Scrolling", props.scrollingBody)
    const pagePaper = props.scrollingBody ? classes.pagePaperScrolling : classes.pagePaper

    return (
        <>
            <Nav activeName={props.navName} supportsClientChange={props.supportsClientChange || false}/>
            <Grid container className={props.scrollingBody ? classes.containerScrolling : ""}>
            {props.variant === "half" ?
                <Grid md={6} xs={12}  item className={classes.pageContentHalf}>
                    <Paper className={pagePaper}>
                        {props.children}
                    </Paper>
                </Grid>
                :
                <Grid lg={12} xs={12} item className={classes.pageContent}>
                    <Paper className={pagePaper}>
                        {props.children}
                    </Paper>
                </Grid>
            }
            </Grid>
            <PageFooter footerPosition={props.footerPosition}/>
        </>
    )
}

export interface PageHeaderProps {
    title: string
    subTitle?: string
    flexGrow?: number
}

export const PageHeader = (props: PropsWithChildren<PageHeaderProps>) => {
    const pageTitle = useAtomValue(pageHeadingAtom)

    const flexGrow = props.flexGrow === undefined ? 1 : props.flexGrow // because 0 is falsy

    return <>
    <Box display="flex" flexWrap="nowrap" p={1} className="subHeader" alignItems="center" id="pageHeaderContainer">
        <Box p={1} flexGrow={flexGrow} id="pageHeaderTitle">
            <Typography variant="h4" data-testid="pageHeading">{pageTitle || props.title}</Typography>
        </Box>
        {props.children}
    </Box>
        {props.subTitle &&
            <Box display="flex" flexWrap="nowrap" p={1} className="subHeader" alignItems="center" id="pageSubheading">
                <Box p={1} flexGrow={1}>
                    <Typography variant="h6" data-testid="pageSubHeading">{props.subTitle}</Typography>
                </Box>
            </Box>
        }
    </>
}

export interface PageBodyProps {
    scrollingBody?: boolean
}

export const PageBody = (props: PropsWithChildren<PageBodyProps>) => {
    const {classes} = useStyle()

    return <Box className={`pl-1 pr-2 pb-3 pt-1 md:pl-3 md:pt-2 md:pr-3 ${props.scrollingBody ? classes.innerContainerScrolling : ""}`}>{props.children}</Box>
}
