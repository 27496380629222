import {useAppContext} from "../../../../lib/context";
import React, {Dispatch, SetStateAction, useRef, useState} from "react";
import {formDataAsMapFromRef} from "../../../../lib/Forms";
import {messageModal} from "../../../../components/modal/InfoModal";
import {IconButton} from "@mui/material";
import {AddCircle} from "@mui/icons-material";
import {KeySelect, SearchInputData, SearchKey, SearchKeyForm} from "../SearchComponent";
import {SearchInputProps} from "../criteria/SearchInput";
import {KeyValue} from "./KeyValue";


interface AddSelectProps {
    searchKeys: SearchKey[]
    createKey: (e: SearchInputProps) => void
    setSearchInputData: Dispatch<SetStateAction<SearchInputData>>
}

export const AddSelect = (props: AddSelectProps) => {
    const {setModal} = useAppContext()

    const [key, setKey] = useState<SearchKey>()

    const formRef = useRef<HTMLFormElement>(null)

    const handleAdd = (e: React.MouseEvent) => {
        if (key) {
            const data = (formDataAsMapFromRef(formRef) as SearchKeyForm)
            console.log("Handle Add", key, data.value)
            props.createKey({searchKey: key, value: data.value, setSearchInputData: props.setSearchInputData})
            setKey(undefined)
        } else {
            setModal && setModal(messageModal("Pick a Value", "You must select a search key."))
        }
    }

    return (
        <form ref={formRef}>
                    <KeySelect keys={props.searchKeys} setKey={setKey}/>
                    <KeyValue searchKey={key}/>
                    <IconButton
                        onClick={handleAdd}
                        style={{paddingTop: "20px", paddingLeft: "5px"}}
                        size="large">
                        <AddCircle/>
                    </IconButton>
        </form>
    );
}