import {useAppContext} from "../lib/context";
import React, {useEffect, useState} from "react";
import MaterialTable from "@material-table/core";
import {InternshipSite, InternshipSiteTrack} from "../internship-site/model/Organization";
import {TableColumn} from "../components/customTable/CustomTable";
import {api, API, apiError, getApiUrl} from "../lib/Api";
import {Client, ClientSettings, ClientSettingsOps} from "../client/model/Client";
import {Box, List, ListItemText, Typography} from "@mui/material";
import {requireGroup, requireOneOfGroup} from "../lib/util";
import Groups from "../model/Groups";
import {UserInformation} from "../login/model/UserInformation";
import {DownloadsControl} from "../components/DownloadsControl";
import useUserReadyService from "../user/lib/UserReadyService";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../Atoms";
import {clientAtom} from "../Atoms";
import {
    EmptyObject, LoggedInComponent,
    LoggedInComponentWrapper,
    LoggedInContextProps,
    WrappedLoggedInContextProps
} from "../components/LoggedInPage";
import SpinnerCard from "../components/SpinnerCard";

interface TrackMatchResult {
    track: InternshipSiteTrack
    site: InternshipSite
    students: UserInformation[]
}

export const showAdminMatches = (userInformation?: UserInformation, settings?: ClientSettings) =>
    requireGroup(userInformation?.userGroups, Groups.globalAdmin)
    || (requireGroup(userInformation?.userGroups, Groups.clientAdmin) && settings?.clientAdminCanPreviewMatch === "yes")
    || (ClientSettingsOps.matchDatePassed(settings) && requireOneOfGroup(userInformation?.userGroups, [Groups.siteAdmin, Groups.clientAdmin, Groups.programAdmin]))


const MatchResultsWrapper: LoggedInComponentWrapper = (context: WrappedLoggedInContextProps) => {
    return context.state === "ready" ? <MatchResults {...context}/> :
        <SpinnerCard message={`Home Loading...`}/>
}

const MatchResults: LoggedInComponent<EmptyObject> = (props) => {
    const {setModal} = useAppContext()
    const client = useAtomValue(clientAtom) as Client
    const userInformation = useAtomValue(userInformationAtom)

    const localClientId = client.id

    const [matchResults, setMatchResults] = useState<TrackMatchResult[]>([])

    const userReady = useUserReadyService()

    useEffect(() => {
        if (userReady && client.settings.cycle.id) {
            api<TrackMatchResult[], undefined>(API.fetchMatches(client.settings.cycle.id), userInformation, localClientId).then(res => {
                setMatchResults((res.data).sort((a, b) => (a.site.name.localeCompare(b.site.name))))
            }).catch(err => apiError(err, setModal))
        }
    }, [userReady, userInformation, localClientId, setModal, client])

    const columns = [
        {title: 'Site', field: 'site.name'},
        {title: 'Track', field: 'track.name'},
        {title: 'Students', field: '', render: row => (<List dense>{row.students
                .map(x => x.firstName +" " + x.lastName + " <" + x.email + ">")
                .map(x => <ListItemText primary={x}/>)
                }</List>), customFilterAndSearch: (term, rowData) => rowData.students.map(x => x.firstName +" " + x.lastName + " <" + x.email + ">").map(x => x.toLowerCase()).filter(x => x.includes(term.toLowerCase())).length > 0
        }
    ] as TableColumn<TrackMatchResult>[]

    return showAdminMatches(userInformation, client?.settings) ?
                    <MaterialTable
                        title="Matched Tracks and Students"
                        columns={columns}
                        data={matchResults}
                    /> :
                    <Typography>PracticumFit has not yet posted the results of the {client?.name} electronic match for the 2021-2022 academic
                        year</Typography>
}

export const matchResultsTitle = (props: LoggedInContextProps)  => ({titleString: "Match Results", titleChildren: (showAdminMatches(props.userInformation, props.client.settings) && false) ? <Box>
    <DownloadsControl downloadUrl={getApiUrl() + API.resultsCSV().location + "?clientId=" + props.client.id}/>
</Box> : <></>})


export default MatchResultsWrapper