import {LoggedInComponent} from "../../components/LoggedInPage";
import MaterialTable from "@material-table/core";
import User from "../../user/model/User";
import React from "react";
import {TableColumn} from "../../components/customTable/CustomTable";
import {Client} from "../../client/model/Client";
import {makeStyles} from "tss-react/mui";
import {StudentMaterialsElement} from "./StudentMaterialsElement";
import {StudentUserInformation} from "../../login/model/UserInformation";
import {ExpandoSiteList} from "./ExpandoStudentSiteList";
import {Button, Tooltip} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import {useNavigate} from "react-router-dom";
import AssignmentIcon from '@mui/icons-material/Assignment';
import {StudentOps} from "../model/Student";
import {QuestionnaireSubmissionActionTypes} from "../../questionnaire/model/Questionnaire";
import {useQuery} from "@tanstack/react-query";
import {API, api} from "../../lib/Api";
import {
    InternshipSite,
    Organization, OrganizationViewNames,
} from "../../internship-site/model/Organization";

export const useStyles = makeStyles()((theme) => ({
    materialChip: {
        borderRadius: "20px",
        backgroundColor: "#f0f0f0",
        margin: "3px 3px",
        fontSize: "0.8rem",
        button: {
            padding: "4px",
        },
        a: {
            padding: "4px",
            color: "rgba(0,0,0,0.54)"
        }
    },
    materialChipsGroup: {
        padding: "2px"
    },
    inlineForm: {
        input: {
            margin: "2px"
        }
    }
}));
export interface StudentApplicationListProps {
    data: StudentUserInformation[]
}

export const userMaterialsComplete = (client: Client, user: User) => {
    if (user.student) {
        return user.student.materials?.materials.length === client.settings.clientCycleSettings.applicationSuiteSettings.materialSlotsDefinition.slotDefinitions.length
    }
    else {
        return undefined
    }
}

export const StudentApplicationList: LoggedInComponent<StudentApplicationListProps> = (props) => {
    const {userInformation, client, data} = props

    const navigate = useNavigate()

    const reviewQuestionnaire = (row: StudentUserInformation) => {
        navigate("/materials/questionnaireReview/" + row.guid)
    }

    const sites = useQuery({
        queryFn: () => {
            return api<Organization[], undefined>(API.listOrganizations(OrganizationViewNames.sites), userInformation, client.id)
                .then(response => response.data)
                .then<InternshipSite[]>(data => data.map(x => x.internshipSite))
        },
        queryKey: ["siteList", client.id],
        staleTime: 86400000
    })

    const QuestionnaireButton: React.FC<{row: StudentUserInformation}> = ({row}) => {
        const lastActionType = StudentOps.lastQuestionnaireAction(row).map(x => x.actionType).getOrElse(QuestionnaireSubmissionActionTypes.none)

        switch (lastActionType) {
            case QuestionnaireSubmissionActionTypes.submitted:
                //return <Button startIcon={<AssignmentIcon/>} variant="contained" className="go" color="primary" onClick={() => adminQuestionnaire(row.guid)}>Approve</Button>
                return <Tooltip title="Review and Approve CUA."><Button variant="outlined" className={`${row.student.materials?.questionnaireComplete ? "go" : ""}`}
                               startIcon={<AssignmentIcon/>} onClick={() => reviewQuestionnaire(row)}>Approve</Button>
                </Tooltip>
            case QuestionnaireSubmissionActionTypes.approved:
                return <Tooltip title="CUA is Approved, click to review."><Button variant="outlined" startIcon={row.student.materials?.questionnaireComplete ? <CheckIcon/> : <QuestionMarkIcon/>}
                        onClick={() => reviewQuestionnaire(row)} className="done">CUA Approved!</Button>
                </Tooltip>
            default:
                return <Tooltip title={`${row.student.materials?.questionnaireComplete ? "Student's CUA is complete, click to review." : "Student's CUA is incomplete, click to review."}`}>
                    <Button variant="outlined"
                            startIcon={row.student.materials?.questionnaireComplete ? <CheckIcon/> : <QuestionMarkIcon/>}
                            onClick={() => reviewQuestionnaire(row)}>View CUA</Button>
                </Tooltip>
        }
    }

    const columns = ([
        {title: 'Sites', width: 200, field: 'sites.length', render: row => (<ExpandoSiteList siteList={ sites.isSuccess ? sites.data : []} row={row} client={client}/>)},
        {title: 'Tracks', field: 'tracks.length', render: row => row.student?.tracks.length + " Track" + (row.student?.tracks.length === 1 ? "" : "s") +" Ranked", cellStyle: {"verticalAlign": "top", "paddingTop": "30px"}, width: 130, },
        {title: 'Application Form', field: 'student.materials.questionnaireComplete', render: row => <QuestionnaireButton row={row}/>,  cellStyle: {"verticalAlign": "top", "paddingTop": "30px"},
            customSort: (a, b) => a.student.materials?.questionnaireComplete > b.student.materials?.questionnaireComplete
        },
        {title: 'Uploaded Materials', field :'', render: row => <StudentMaterialsElement userInformation={userInformation} user={row} client={client}/>, cellStyle: {"verticalAlign": "top", "paddingTop": "30px"}, customFilterAndSearch: (term, rowData) => {
            return ((rowData.student.materials?.lettersOfReference || []).filter(x =>
                x.referentName.includes(term) || x.referentEmail.includes(term) || x.referentPhone.includes(term)
            )).length > 0
        }
        }
    ] as TableColumn<StudentUserInformation>[])
        .concat([
            {title: 'Name', width: 200, field: '', customSort: (a, b) => ((a.lastName || "") + (b.firstName || "")).localeCompare((b.lastName || "") + (b.firstName || "")), render: row => <>{row.lastName}{row.lastName ? "," : ""} {row.firstName}</>, cellStyle: {"verticalAlign": "top", "paddingTop": "30px"}},
            {title: 'Email', width: 200, field: 'email', cellStyle: {"verticalAlign": "top", "paddingTop": "30px"}},
        ])

    return <MaterialTable title="List of Students" columns={columns} data={data} actions={[
            {
                icon: 'edit',
                tooltip: 'edit User',
                onClick: (event: any, rowData: StudentUserInformation | StudentUserInformation[]) => alert("You saved " + rowData)
            }
            ]}
        components={{
            Action: (props) => (
                <div style={{width: "125px"}} className="optionsCell">
                </div>
            )
        }}
        options={{
            actionsColumnIndex: -1,
                tableLayout: 'auto',
                paging: true,
                pageSize: 20,
                headerStyle: {position: 'sticky', top: 0},
        }}
    />
}