import {Dispatch, SetStateAction} from "react";
import {questionModal} from "./InfoModal";
import {DialogType, ModalProps} from "./ModalProps";

export const withConfirmModal = <T>(setModal: Dispatch<SetStateAction<ModalProps>>,
                                 data: T,
                                 confirmMessage: string) => (f: (data: T) => void) =>
    setModal && setModal(questionModal(data, "Confirm", confirmMessage, DialogType.Confirm, (action: string, insideData: T) => {
        if (action === "confirm") {
            f(insideData)
        }
    }))