import React, {useEffect, useState} from 'react';
import '../../Login.css';
import {API, api, apiError} from '../../lib/Api'
import {useAppContext} from "../../lib/context";
import {useNavigate} from "react-router-dom";
import {historyPushModal} from "../../components/modal/InfoModal";
import {formDataAsMap} from "../../lib/Forms";
import {Theme} from "@mui/material/styles";
import {FormControl, TextField} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {Program} from "../model/Program";
import {userInformationAtom} from "../../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom} from "../../Atoms";

interface ProgramFormState {
    id?: number
    name: string
    description: string
    schoolId: number
}

interface ProgramFormErrors {
    id?: string
    name?: string
    description?: string
    schoolId?: number
}

interface ProgramFormProps {
    id?: string
}

const initialState = {
    id: 0,
    name: "",
    description: "",
    schoolId: 0
}

const useStyles = makeStyles()((theme: Theme) => ({
        formControl: {
            minWidth: 120,
            width: "100%",
            marginTop: theme.spacing(1)
        },
    })
);

const ProgramForm = (props: ProgramFormProps) => {

    const navigate = useNavigate();
    const {setModal, isDebug} = useAppContext();

    const client = useAtomValue(clientAtom)
    const userInformation = useAtomValue(userInformationAtom)

    const localClientId: number = client.id
    const localId = props.id ? parseInt(props.id) : 0;

    //const {register, handleSubmit, formState: {errors}, setValue } = useForm<ProgramFormState>();

    const [program, setProgram] = useState<ProgramFormState | null>(initialState)
    const [errors, setErrors] = useState<ProgramFormErrors>({})

    useEffect(() => {
        if (localId > 0) {
            api<Program, undefined>(API.getProgram(localId), userInformation, localClientId)
                .then(e => setProgram(e.data))
                .catch(error => console.log(error));
        }
    }, [localId, localClientId, userInformation])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        setErrors({})

        const data = formDataAsMap<ProgramFormState>(event.target as HTMLFormElement)

        const localData = {...data, id: localId, clientId: localClientId, school: undefined};

        const p = (localId === 0 ?
            api(API.addProgram(), userInformation, localClientId, localData) :
            api(API.updateProgram(localId, data.schoolId), userInformation, localClientId, localData));

        p.then(r => {
            isDebug && console.log(localData);
            setProgram({...data});
            setModal && setModal(historyPushModal("Info", "Program Saved Successfully", navigate, "/program"))
        })
            .catch(x => {
                console.log("Failed to make call to add program, got ", x)
                apiError(x, setModal)
            })
    }

    const {classes} = useStyles()

    return (
        <div className="card">
            <div className="card-header">
                Program Info for {program?.name}
            </div>
            <div className="card-body">
                <form onSubmit={handleSubmit} id="programForm">
                    <FormControl className={classes.formControl}>
                        <TextField className={classes.formControl}
                                   label="Name"
                                   name="name"
                                   id="programName"
                                   placeholder="Enter Program Name"
                                   defaultValue={program?.name}
                                   helperText="Program's Name"
                                   required={true}
                                   variant="filled"
                                   error={!!errors.name}
                                   />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField className={classes.formControl}
                                   label="Description"
                                   name="description"
                                   id="programDescription"
                                   placeholder="Enter Program Description"
                                   defaultValue={program?.description}
                                   helperText="Program's Description"
                                   required={false}
                                   variant="filled"
                                   error={!!errors.description}
                        />
                    </FormControl>
                    <input type="hidden" defaultValue={program?.schoolId}/>
                    <input type="submit" value="Submit"/>
                </form>
            </div>
        </div>
    )
}

export default ProgramForm;
