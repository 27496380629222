import React, {useEffect, useState} from "react"
import {InternshipSiteId, Organization} from "../model/Organization";
import MaterialTable from "@material-table/core";
import {TableColumn} from "../../components/customTable/CustomTable";
import {QuestionnaireAnswersView} from "../../questionnaire/component/submission/QuestionnaireAnswersView";
import {API, api, apiError} from "../../lib/Api";
import {useAppContext} from "../../lib/context";
import {QuestionnaireElement, QuestionnaireId} from "../../questionnaire/model/Questionnaire";
import {componentModal} from "../../components/modal/InfoModal";
import {Questionnaire} from "../../model/Questionnaire";
import useUserReadyService from "../../user/lib/UserReadyService";
import {userInformationAtom} from "../../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom} from "../../Atoms";

interface OrganizationListViewProps {
    organizations: Organization[]
}

interface QuestionnaireViewProps {
    siteId: InternshipSiteId
}

const QuestionnaireView = (props: QuestionnaireViewProps) => {
    const {setModal} = useAppContext()

    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom)

    const localClientId = client?.id || 0

    const [questionnaire, setQuestionnaire] = useState<QuestionnaireElement[]>([]);
    const [questionnaireId, setQuestionnaireId] = useState<QuestionnaireId>();

    const userReady = useUserReadyService()

    useEffect(() => {
        if (userReady) {
            console.log("localClientID", localClientId)
            api<Questionnaire[], undefined>(API.listQuestionnaires(), userInformation, localClientId).then(qs => {
                setQuestionnaireId(qs.data[0].id)
                api<QuestionnaireElement[], undefined>(API.fetchQuestionnaire(qs.data[0].id), userInformation, localClientId).then(v => {
                    setQuestionnaire(v.data)
                }).catch((x) => apiError(x, setModal))
            }).catch((x) => apiError(x, setModal))
        }
    }, [userInformation, localClientId, setModal, userReady])

    console.log("questionnaireId",questionnaireId)

    return questionnaire && questionnaireId ?
        (<QuestionnaireAnswersView questionnaire={questionnaire} questionnaireId={questionnaireId}
                                   siteId={props.siteId}/>)
            : <React.Fragment/>
}

export const InternshipSiteListView = (props: OrganizationListViewProps) => {
    const {setModal} = useAppContext()

    const columns: Array<TableColumn<Organization>> = ([
        {
            title: "Name",
            field: "name"
        }, {
            title: "Description",
            field: "description"
        }
    ])


    return (
        <MaterialTable columns={columns} data={props.organizations}
                       options={{paging: false}}
                       actions={[{
                           icon: 'list-alt',
                           tooltip: 'View Directory',
                           onClick: (event, rowData: any) => {
                               setModal && setModal(componentModal("Directory", (<QuestionnaireView siteId={rowData.internshipSite.id}/>)))
                           }
                       }]}
        />
    )
}