import {clientAtom} from "../../../../Atoms";
import {useAtomValue} from "jotai";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {API, api} from "../../../../lib/Api";
import {refreshLetterOfReferenceQuery} from "./LetterOfReferenceService";


export const useDeclineLetterOfReferenceService = () => {
    const client = useAtomValue(clientAtom)
    const queryClient = useQueryClient()
    const refresh = refreshLetterOfReferenceQuery(queryClient)

    return useMutation({
        mutationFn: (props: {lorUUID: string, guid: string}) => api(API.declineLetterOfReference(props.guid, props.lorUUID), undefined, client.id)
            .then(res => refresh(props.lorUUID))
    })
}