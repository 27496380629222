import {
    CognitoUserPool,
    CognitoUser,
    AuthenticationDetails, CognitoUserSession, CognitoUserAttribute
} from 'amazon-cognito-identity-js';

import awsconfig from '../conf/aws-exports';
import {appConfig} from "../conf/conf";
import {AdminUserInformation, UserInformation} from "../login/model/UserInformation";


export enum CognitoResult {
    COGNITO_INITIALIZING,
    COGNITO_NO_USER,
    COGNITO_AUTH_FAILED,
    COGNITO_FAILURE,
    COGNITO_NEW_PASSWORD,
    COGNITO_SUCCESS,
}

export interface CognitoInitializing {
    status: CognitoResult.COGNITO_INITIALIZING
}

export interface CognitoSuccess {
    status: CognitoResult.COGNITO_SUCCESS,
    session: SessionResponse,
    user: CognitoUser
}

export interface CognitoNewPassword {
    status: CognitoResult.COGNITO_NEW_PASSWORD
}

export interface CognitoAuthFailed {
    status: CognitoResult.COGNITO_AUTH_FAILED
    error: string
}

export interface CognitoFailure {
    status: CognitoResult.COGNITO_FAILURE
    error: string
}

export interface CognitoNoUser {
    status: CognitoResult.COGNITO_NO_USER
}

export type CognitoServiceStatus =
    CognitoInitializing
    | CognitoSuccess
    | CognitoNewPassword
    | CognitoAuthFailed
    | CognitoFailure | CognitoNoUser
interface SessionResponse {
    session: CognitoUserSession
    attributes: any
}

const poolData = {
    UserPoolId: awsconfig.aws_user_pools_id, // Your user pool id here
    ClientId: awsconfig.aws_user_pools_web_client_id // Your client id here
};

export const userPool = new CognitoUserPool(poolData);

export const signInUser = (username: string, password: string) => {
    const authenticationData = {
        Username: username,
        Password: password,
    };
    const authenticationDetails = new AuthenticationDetails(
        authenticationData
    );
    const userData = {
        Username: username,
        Pool: userPool,
    };
    const cognitoUser = new CognitoUser(userData);
    return new Promise<CognitoServiceStatus>((resolve, reject) => {
        cognitoUser.authenticateUser(authenticationDetails, {
            onSuccess: function (result) {
                cognitoUser.getUserAttributes((err, attributes) => {
                    if (attributes)
                        resolve({status: CognitoResult.COGNITO_SUCCESS, session: {session: result, attributes}, user: cognitoUser})
                    else
                        reject({status: CognitoResult.COGNITO_FAILURE, error: err?.message || "No attributes found"})
                })
            },
            newPasswordRequired: () => {
                resolve({status: CognitoResult.COGNITO_NEW_PASSWORD})
            },
            onFailure: (err) => {
                // TODO do we want this, or should we go for for a resolve() here.
                resolve({status: CognitoResult.COGNITO_AUTH_FAILED, error: err.message})
            }
        });
    })
}

const completeSessionWithAttributes = (cognitoUser: CognitoUser, session: CognitoUserSession) => new Promise<CognitoServiceStatus>((resolve, reject) => {
    cognitoUser.getUserAttributes((err, attributes) => {
        if (err) {
            console.log("Attributes not found")
            reject({status: CognitoResult.COGNITO_FAILURE, error: err.message})
        } else {
            appConfig.isDebug && console.log("Session AOK")
            resolve({
                status: CognitoResult.COGNITO_SUCCESS, session: {
                    session: session,
                    attributes: attributes || []
                },
                user: cognitoUser,
            })
        }
    })
})

export const retrieveUserSession = () => new Promise<CognitoServiceStatus>((resolve, reject) => {
    appConfig.isDebug && console.log("getting current user")
    // TODO should we wrap this in a starting promise, and them() it with exception handling?
    var cognitoUser = userPool.getCurrentUser();
    if (cognitoUser !== null) {
        appConfig.isDebug && console.log("Getting session")
        cognitoUser.getSession((err: any, session: any) => {
            if (err) {
                appConfig.isDebug && console.log("Error getting session")
                reject({status: CognitoResult.COGNITO_FAILURE, error: err.message});
            } else {
                appConfig.isDebug && console.log("Completing session")
                cognitoUser ? completeSessionWithAttributes(cognitoUser, session)
                        .then(e => resolve(e))
                        .catch(e => reject(e))
                    : reject({status: CognitoResult.COGNITO_FAILURE, error: "No user found"})
            }
        });
    } else {
        appConfig.isDebug && console.log("No User")
        resolve({status: CognitoResult.COGNITO_NO_USER})
    }
})

export const signOut = () => {
    const cognitoUser = userPool.getCurrentUser()

    cognitoUser?.signOut()
}

export const completeNewPassword = (username: string, userAttrs: any, oldPassword: string, newPassword: string) => {
    appConfig.isDebug && console.log("completing new password")
    const userData = {
        Username: username,
        Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);
    return new Promise<CognitoServiceStatus>((resolve, reject) => {
        cognitoUser.authenticateUser(new AuthenticationDetails({
            Username: username,
            Password: oldPassword
        }), {
            onSuccess: function (result) {
                appConfig.isDebug && console.log("Re-authenticating success")
                completeSessionWithAttributes(cognitoUser, result)
                    .then(resolve)
                    .catch(reject)
            },
            onFailure: function (err) {
                appConfig.isDebug && console.log("Cognito new password failed", err)
                reject(err);
            },
            newPasswordRequired: () => {
                appConfig.isDebug && console.log("userAttrs", userAttrs)
                cognitoUser.completeNewPasswordChallenge(newPassword, {}, {
                    onSuccess: (session) => {
                        completeSessionWithAttributes(cognitoUser, session)
                            .then(resolve)
                            .catch(reject)
                    },
                    onFailure: (err) => {
                        appConfig.isDebug && console.log("New Password failure", err)
                        reject(err);
                    }
                })
            }
        })
    })
}

export const changePassword = (oldPassword: string, newPassword: string) => {
    appConfig.isDebug && console.log("changing password")
    const cognitoUser = userPool.getCurrentUser();

    return new Promise<CognitoUserSession>((resolve, reject) => {
        cognitoUser?.getSession((error: any, session: null | CognitoUserSession) => {
            cognitoUser?.changePassword(oldPassword, newPassword, (err, result) => {
                appConfig.isDebug && console.log(result);
                if (err) {
                    reject(err);
                }
                if (result === "SUCCESS") {
                    // For some reason, the new circle CI build, rejects this expecting 2 argument, even though I can't find
                    // a damn thing anywhere, and the type it's asking for isn't anywhere in the package dependency. Fuck.
                    (cognitoUser as any).getSession((err: any, session: CognitoUserSession) => {
                        if (err) reject(err)
                        else resolve(session)
                    })
                }
            })
    })})
}

export const refreshToken = () => {
    const cognitoUser = userPool.getCurrentUser();

    return new Promise<CognitoServiceStatus>((resolve, reject) => {
        if (cognitoUser !== null) {
            // Completely stupid cast but apparently yarn on CircleCI is unable to resolve the type - I have no idea
            (cognitoUser as any).getSession((err: any, session: CognitoUserSession) => {
                if (err) reject(err)
                else {
                    cognitoUser?.refreshSession(session.getRefreshToken(), (err, newSession) => {
                        if (err) {
                            reject({status: CognitoResult.COGNITO_FAILURE, error: err.message})
                        } else {
                            appConfig.isDebug && console.log("Refreshed Session successfully", newSession)
                            completeSessionWithAttributes(cognitoUser, newSession).then(resolve).catch(reject)
                        }
                    })
                }
            })
        }
        else reject("Session not found")
    })
}

export const niceifyCognitoError = (err: any) => {
    console.log("Niceifying ", err.message)
    switch(err.message) {
        case "Incorrect username or password.":
            return "Your old password was entered incorrectly, enter your old password correctly to proceed."
        default:
            return err.message
    }
}

export const userPropsFromAttributes = (attributes: CognitoUserAttribute[]) => {
    const mapping: { [p: string]: { f: ((attr: CognitoUserAttribute) => any), name: string } } = {
        "given_name": {f: (a: CognitoUserAttribute) => a.getValue(), name: "given_name"},
        "family_name": {f: (a: CognitoUserAttribute) => a.getValue(), name: "family_name"},
        "email": {f: (a: CognitoUserAttribute) => a.getValue(), name: "email"},
        "custom:clientId": {f: (a: CognitoUserAttribute) => parseInt(a.getValue()), name: "clientId"}
    } as any

    return attributes.reduce((a, x) => mapping[x.getName()] ? {
        ...a,
        [mapping[x.getName()].name]: mapping[x.getName()].f(x)
    } : a, {}) as any
}
export const buildUserInformationFromCognito: (session: CognitoUserSession, attributes: CognitoUserAttribute[]) => UserInformation = (session, attributes) => {
    const jwtToken = session.getIdToken().getJwtToken()
    const groups = session.getIdToken().decodePayload()["cognito:groups"]

    const attrs = userPropsFromAttributes(attributes)

    if (!jwtToken) {
        console.log("Error - cognito session has no jwt token", session)
        throw new Error("Got a blank jwt token from cognito session - this should be impossible")
    }

    return new AdminUserInformation({...attrs, jwtToken: jwtToken, userGroups: groups})
}
