import React from "react"
import {
    ProgramTrackRelationship,
    RelationshipOperation as Op
} from "../model/ProgramSiteRelationship";
import {Button} from "@mui/material";

export enum AssociationAction {
    Request = 1,
    Approve,
    Reject,
    Cancel,
    Revoke
}

interface AssociationOptionsProps {
    relationship?: ProgramTrackRelationship
    buttonHandler: (action: AssociationAction) => void
}

interface AssociationButtonProps {
    buttonHandler: (action: AssociationAction) => void
    action: AssociationAction
    text: string
    className?: string
}

const AssociationButton = (props: AssociationButtonProps) => {
    return (<Button className={props.className} onClick={e => {props.buttonHandler(props.action)}}>{props.text}</Button> )
}

const NoAssociationOptions = (props: AssociationOptionsProps) => (
    <AssociationButton className="go" buttonHandler={props.buttonHandler} text="Send Request" action={AssociationAction.Request}/>
)

const AssociatedOptions = (props: AssociationOptionsProps) => (
    <AssociationButton className="stop" buttonHandler={props.buttonHandler} text="Revoke Access" action={AssociationAction.Cancel}/>
)

const TrackRequestedOptions = (props: AssociationOptionsProps) => (<React.Fragment>
    <AssociationButton className="go" buttonHandler={props.buttonHandler} text="Approve Request" action={AssociationAction.Approve}/>
    <AssociationButton className="stop" buttonHandler={props.buttonHandler} text="Reject Request" action={AssociationAction.Reject}/>
</React.Fragment>)

const ProgramRequestedOptions = (props: AssociationOptionsProps) => (<React.Fragment>
    <AssociationButton className="stop" buttonHandler={props.buttonHandler} text="Withdraw Request" action={AssociationAction.Cancel}/>
</React.Fragment>)

export const AssociationOptions = (props: AssociationOptionsProps) => {
        switch (props.relationship?.operationId) {
            case Op.ProgramApproved:
            case Op.TrackApproved:
                return <AssociatedOptions {...props}/>
            case Op.ProgramCancelled:
            case Op.TrackCancelled:
            case Op.ProgramRejected:
            case Op.TrackRejected:
                return <NoAssociationOptions {...props} />
            case Op.TrackRequested:
                return <TrackRequestedOptions {...props}/>
            case Op.ProgramRequested:
                return <ProgramRequestedOptions {...props}/>
            default:
                return <NoAssociationOptions {...props}/>
        }
}