import React, {ChangeEvent, useState} from 'react'
import {QuestionnaireElementProps} from "../../model/QuestionnaireList";
import TextField from '@mui/material/TextField'
import {Theme} from "@mui/material/styles";
import {Checkbox, FormControl, FormControlLabel} from "@mui/material";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
    formControl: {
        width: "100%",
        minWidth: "200px",
    },
}));

export const ElementLabel = (props: QuestionnaireElementProps) => {
    const [label, setLabel] = useState(props.row.question?.label)
    const [checked, setChecked] = useState<boolean>(false)
    const [changed, setChanged] = useState<boolean>(false)

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const x = e.target.value
        setLabel(x)

        props.setElement && props.setElement(elem => {
            return {
                ...elem,
                question: elem.question && {
                    ...elem.question,
                    label: x
                }
            }
        })

        setChanged(true);
    }

    const handleCheckedChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const x = event.target.checked

        setChecked(x);
    };

    const {classes} = useStyles()

    return (
        <React.Fragment>
            <FormControl className={classes.formControl}>
                <TextField className={classes.formControl}
                           id="elementLabel"
                           variant="filled"
                           label="Question"
                           aria-label="Question Label"
                           value={label}
                           name="label"
                           size="small"
                           onChange={onChangeHandler}
                />
            </FormControl>
            {changed && props.row.id > 0 && (
                <FormControlLabel control={
                    <Checkbox
                        name="isMinorEdit"
                        checked={checked}
                        onChange={handleCheckedChange}
                        value="on"
                        inputProps={{'aria-label': 'Is this a minor edit'}}
                    />} label="This is a minor edit, not a new question."/>
            )}
        </React.Fragment>
    )
}
