import React, {FormEvent, useEffect, useState} from 'react';
import '../../Login.css';
import {api, API, apiError} from '../../lib/Api'
import {useAppContext} from "../../lib/context";
import {Address} from "../../components/model/Address";
import {errorMessageModal, messageModal} from "../../components/modal/InfoModal";
import {ProgramSelect} from "../../program/component/ProgramSelect";
import useListProgramService from "../../program/lib/ProgramService";
import {Program, ProgramId} from "../../program/model/Program";
import {Box, Button, FormControl, TextField} from "@mui/material";
import {formDataAsMap} from "../../lib/Forms";
import {Theme} from "@mui/material/styles";
import Groups from "../../model/Groups";
import {requireGroup} from "../../lib/util";
import {useNavigate} from "react-router-dom";
import {PageBody, PageHeader} from "../../components/Page";
import SpinnerCard from "../../components/SpinnerCard";
import {makeStyles} from "tss-react/mui";
import User from "../../user/model/User";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";
import {clientAtom} from "../../Atoms";

interface StudentFormState {
    id?: string
    clientId: number
    firstName: string
    lastName: string
    email: string
    program: {
        id: number
    }
    address: Address
}

interface StudentFormErrors {
    id?: string
    clientId?: string
    firstName?: string
    lastName?: string
    email?: string
    programId?: string
    address?: {
        address1?: string
        address2?: string
        city?: string
        region?: string
        postalCode?: string
    }
}

interface StudentFormProps {
    id?: string
    programId?: string
}

const useStyles = makeStyles()((theme: Theme) => ({
        formControl: {
            minWidth: 120,
            width: "100%",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        hFlowBox: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1)
        }
    }))

const StudentForm = (props: StudentFormProps) => {

    const {setModal, isDebug} = useAppContext();

    const client = useAtomValue(clientAtom)
        const userInformation = useAtomValue(userInformationAtom)

    const localClientId: number = client.id
    const localId = props.id || "";

    const [programId, setProgramId] = useState<ProgramId>(props.programId ? parseInt(props.programId) : 0)
    const [errors, setErrors] = useState<StudentFormErrors>({});
    const [loaded, setLoaded] = useState<boolean>(false)

    const [student, setStudent] = useState<StudentFormState | null>({
        id: localId,
        clientId: localClientId,
        firstName: "",
        lastName: "",
        email: "",
        program: {
            id: 0
        },
        address: {id: 0, address1: "", address2: "", city: "", region: "", postalCode: ""}
    });

    const navigate = useNavigate()

    const programService = useListProgramService();

    const programs: Array<Program> = programService.isSuccess ? programService.data: [] as Array<Program>

    const validate = (data: StudentFormState) => {
        if (false) {
            setErrors({})
        }
    }

    useEffect(() => {
        if (localId !== "") api<User & {address: Address, program: {id: number}}, undefined>(API.getStudent(localId), userInformation, localClientId)
            .then(resp => {
                setStudent({
                    ...resp.data,
                    firstName: resp.data.firstName || '',
                    lastName: resp.data.lastName || '',
                    clientId: resp.data.clientId || 0
                })
                setLoaded(true)
            })
            .catch(error => console.log(error));
        else
            setLoaded(true)
    }, [props.id, userInformation, localClientId, localId])

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        // TODO had to add a new coercion here, not sure why...
        const data = formDataAsMap(event.target as HTMLFormElement) as StudentFormState
        validate(data)

        const localData = {...data, clientId: localClientId, id: localId, programId: programId};
        const p = (localId !== "") ?
            api(API.addStudent(), userInformation, localClientId, localData) :
            api(API.updateStudentInfo(localId), userInformation, localClientId, localData);

        p.then(r => {
            isDebug && console.log(localData, r);
            setStudent({...data});
            setModal && setModal({
                ...messageModal("Info", "Student Saved Successfully"),
                onClose: e => navigate("/student")
            })
        }).catch(x => {
            x.json().then((apiData: any) => {
                if (apiData.message === "User already exists") {
                    setModal && setModal(errorMessageModal("Error", "A user already exists on the system with the email address " + localData.email))
                } else {
                    console.log("Failed to make call to add Student, got error JSON", data)
                    apiError(x, setModal)
                }
            }).catch((y: any) => {
                console.log("Failed to make call to add Student, got ", y)
                apiError(y, setModal)
            })
        })

    }

    const {classes} = useStyles()

    const editable = localId !== "" || requireGroup(userInformation?.userGroups, Groups.student)

    console.log("Read only", editable)

    return (
        <>
            <PageHeader title="Student Info"/>
            <PageBody>
                {loaded ? <>
                    <form onSubmit={e => handleSubmit(e)}>
                        <FormControl className={classes.formControl}>
                            <TextField type="email" id="studentInputEmail"
                                       variant="outlined"
                                       aria-label="Email Address"
                                       label="Email Address"
                                       placeholder="Enter email"
                                       helperText="Student's Email Address, use the email at their institute of learning."
                                       defaultValue={student?.email}
                                       name="email"
                                       required={requireGroup(userInformation?.userGroups, Groups.student)}
                                       disabled={!editable}
                            />
                            <div className="formErrors">{errors.email}</div>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField type="text" id="studentFirstName"
                                       variant="outlined"
                                       aria-label="Student's first name"
                                       label="First Name"
                                       defaultValue={student?.firstName}
                                       placeholder="Enter First Name"
                                       helperText="Student's First Name"
                                       name="firstName"
                                       required={true}
                                       disabled={!editable}
                            />
                            <div className="formErrors">{errors.firstName}</div>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField type="text" id="studentLastName"
                                       variant="outlined"
                                       aria-label="Student's last name"
                                       label="Last Name"
                                       helperText="Student's Last Name"
                                       defaultValue={student?.lastName}
                                       name="lastName"
                                       required={requireGroup(userInformation?.userGroups, Groups.student)}
                                       placeholder="Enter Last Name"
                                       disabled={!editable}
                            />
                        </FormControl>
                        <ProgramSelect programs={programs} programId={student?.program?.id || (props.programId ? parseInt(props.programId) : 0)}
                                       setProgramId={setProgramId} readonly={!editable}/>
                        {userInformation?.primaryGroup() === Groups.student &&
                        <React.Fragment>
                            <FormControl className={classes.formControl}>
                                <TextField type="text" id="studentAddress1"
                                           variant="outlined"
                                           aria-label="Student's address"
                                           label="Student's Address"
                                           placeholder="Street Address"
                                           helperText="Student's Street Address"
                                           defaultValue={student?.address.address1}
                                           name="address.address1"
                                />
                                <div className="formErrors">{errors.address?.address1}</div>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField type="text" id="studentAddress2"
                                           variant="outlined"
                                           aria-label="Student's Address, second line, typically unit, apartment, or suite."
                                           label="Student's Suite / Apartment"
                                           placeholder="Suite / Apt"
                                           defaultValue={student?.address.address2}
                                           name="address.address2"
                                           helperText="Student's unit, apartment, or suite number"
                                />
                                <div className="formErrors">{errors.address?.address2}</div>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField type="text" id="studentCity"
                                           variant="outlined"
                                           aria-label="Student's city"
                                           label="Student's City"
                                           placeholder="City"
                                           defaultValue={student?.address.city}
                                           name="address.city"
                                           helperText="Student's city"
                                />
                                <div className="formErrors">{errors.address?.city}</div>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField type="text" id="studentState"
                                           variant="outlined"
                                           label="Student's State"
                                           aria-label="Student's state or region"
                                           placeholder="State"
                                           defaultValue={student?.address.region}
                                           name="address.region"
                                           helperText="Student's State or Region"
                                />
                                <div className="formErrors">{errors.address?.region}</div>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField type="text" id="studentPostalCode"
                                           variant="outlined"
                                           label="Student's Zip"
                                           aria-label="Student's Zipcode or Postal Code"
                                           placeholder="Zipcode"
                                           defaultValue={student?.address.postalCode}
                                           name="address.postalCode"
                                           helperText="Student's Zipcode or Postal Code"
                                />
                                <div className="formErrors">{errors.address?.postalCode}</div>
                            </FormControl>
                        </React.Fragment>
                        }
                        {(requireGroup(userInformation?.userGroups, Groups.student) || localId !== "") ?
                            <Box display="flex" flexWrap="wrap">
                                <Box className={classes.hFlowBox}>
                                    <Button type="submit" variant="outlined">Submit Form</Button>
                                </Box>
                                <Box className={classes.hFlowBox}>
                                    <Button href="/student" variant="outlined">Cancel</Button>
                                </Box>
                            </Box>
                            :
                            <Box className={classes.hFlowBox}>
                                <Button href="/student" variant="outlined">Cancel</Button>
                            </Box>
                        }
                    </form>
                </> : <SpinnerCard message="Loading"/>
                }
            </PageBody>
        </>
    )
}

export default StudentForm;
