import { useState } from "react";
import {Organization, OrganizationViewName} from "../model/Organization";
import {Service} from "../../lib/Service";
import {API, api} from "../../lib/Api";
import {Client, ClientId} from "../../client/model/Client";
import {userInformationAtom} from "../../Atoms";
import {useAtomValue} from "jotai";
import {QueryClient, useQuery} from "@tanstack/react-query";
import {clientAtom} from "../../Atoms";

export interface ListOrganizationServiceProps {
    increment?: Date
    clientId?: ClientId
    viewName?: OrganizationViewName
}

export const listOrganizationQueryKey =  (clientId: ClientId, viewName?: OrganizationViewName) =>
    ["listOrganizations", clientId, viewName]

/**
 * Deprecated - expose reactQuery directly, or use ListOrganizationService
 * @param props
 */
const useListOrganizationService = (props: ListOrganizationServiceProps) => {
    const client = useAtomValue(clientAtom) as Client
    const userInformation = useAtomValue(userInformationAtom)

    const listClientId = client.id

    const [result, setResult] = useState<Service<Array<Organization>>>({
        status: 'loading'
    })

    useQuery({
        queryFn: () => {
            setResult({status: 'loading'})
            return api<Organization[], undefined>(API.listOrganizations(props.viewName), userInformation, listClientId)
                .then(response => {
                    setResult({status: 'loaded', payload: response.data})
                    return response.data
                })
        },
        queryKey: listOrganizationQueryKey(listClientId, props.viewName),
        staleTime: 86400000
    })

    return result;
}

export const invalidateOrganizationList = (queryClient: QueryClient, clientId: ClientId, viewName: string) => {
    console.log("Invalidating listOrganizations", clientId, viewName)
    return new Promise<void>(async (resolve, reject) => {
        // Wait 4 seconds for the back end to render
        await new Promise(resolve => setTimeout(resolve, 5000));
        resolve(queryClient.invalidateQueries(["listOrganizations", clientId, viewName]))
    })
}

export default useListOrganizationService;
