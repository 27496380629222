import {Organization} from "../../internship-site/model/Organization";
import {API, api} from "../../lib/Api";
import {Client, ClientId} from "../../client/model/Client";
import {userInformationAtom} from "../../Atoms";
import {useAtomValue} from "jotai";
import {useQuery} from "@tanstack/react-query";
import {UseQueryResult} from "@tanstack/react-query/src/types";
import {clientAtom} from "../../Atoms";

interface ListOrganizationServiceProps {
    clientId?: ClientId
}

export const useStudentOrganizationService = (props: ListOrganizationServiceProps): UseQueryResult<Organization[]> => {
    const client = useAtomValue(clientAtom) as Client
    const userInformation = useAtomValue(userInformationAtom)

    const listClientId = props.clientId || client.id

    return useQuery({
        queryFn: () => api<Organization[], undefined>(API.studentListOrganizations(), userInformation, listClientId)
                .then(response => response.data),
        queryKey: ["studentListOrganizations", listClientId],
        staleTime: 86400000
    })
}
