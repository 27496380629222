import React, {useEffect, useState} from 'react';
import '../../Login.css';
import {API, api, apiError} from '../../lib/Api'
import {useAppContext} from "../../lib/context";
import {useNavigate} from 'react-router-dom';
import {
    emptyInternshipSiteTrack,
    InternshipSiteId,
    InternshipSiteTrack, SiteTrackTypeType
} from "../model/Organization";
import {FormControl, TextField} from "@mui/material";
import {Theme} from "@mui/material/styles";
import {formDataAsMap} from "../../lib/Forms";
import {TrackListTypeSelect} from "./TrackListTypeSelect";
import {makeStyles} from "tss-react/mui";
import {userInformationAtom} from "../../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom} from "../../Atoms";
import {DialogType} from "../../components/modal/ModalProps";

interface TrackFormState {
    id?: number
    internshipSiteId: InternshipSiteId
    name: string
    description: string
    openings: number
    siteTrackType: SiteTrackTypeType
    clearingHouseOptIn: boolean
    continuousTrackId: string
}

interface TrackFormErrors {
    id?: string
    internshipSiteId?: string
    name?: string
    description?: string
    openings?: string
    siteTrackType?: string
    clearingHouseOptIn?: string
    continuousTrackId?: string
}

interface TrackFormProps {
    organizationId: string
    siteId: string
    id?: string
}

const useStyles = makeStyles()((theme: Theme) => ({
        formControl: {
            minWidth: 120,
            width: "100%",
            marginTop: theme.spacing(1)
        },
    })
);

const TrackForm = (props: TrackFormProps) => {
    const navigate = useNavigate();
    const {setModal, isDebug} = useAppContext();

    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom)

    const localClientId: number = client?.id ? client.id : 0;
    const localId = props.id ? parseInt(props.id) : 0;
    const organizationId = parseInt(props.organizationId)
    const siteId = parseInt(props.siteId)

    const [errors, setErrors] = useState<TrackFormErrors>({})

    const [track, setTrack] = React.useState<TrackFormState | null>({
        ...emptyInternshipSiteTrack,
        internshipSiteId: siteId
    });

    const validateOpenings = (x?: number) => {
        if (x === undefined) return "Openings must be defined"
        if (x && x < 0) return "Openings may not be a negative number"

        return undefined
    }

    const validate = (data: TrackFormState) => {
        setErrors({
            openings: validateOpenings(data.openings)
        })
    }

    useEffect(() => {
        if (localId && localId > 0) {
            api<any, number>(API.getTrack(organizationId, siteId, localId), userInformation, localClientId)
                .then(e => {
                    isDebug && console.log("Fetch org",e);
                    setTrack(e.data.track)
                })
                .catch(error => console.log(error));
        }
    }, [localId, userInformation, organizationId, siteId, localClientId, isDebug])

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const data = formDataAsMap<TrackFormState>(event.target as HTMLFormElement)
        validate(data)

        const localData: InternshipSiteTrack = {...data, programs: [], id: localId, internshipSiteId: siteId,
            modifiedTimestamp: new Date(), clearingHouseOptIn: track?.clearingHouseOptIn || false, rankedStudents: [], studentsApplied: []};
        const p = (localId === 0 ?
            api<any, InternshipSiteTrack>(API.addTrack(organizationId, siteId), userInformation, localClientId, localData) :
            api<any, InternshipSiteTrack>(API.updateTrack(organizationId, siteId, localId), userInformation, localClientId, localData))

        p.then(r => {
            isDebug && console.log(localData);
            setTrack({...data});
            setModal && setModal({
                title: "Info",
                message: "Track Saved Successfully",
                isOpen: true,
                onClose: () => {
                    navigate("/site")
                },
                dialogType: DialogType.Message
            })
        })
          .catch(x => {
              isDebug && console.log("Failed to make call to add track, got ", x)
              apiError(x, setModal)
          })

    }

    const {classes} = useStyles()

    return (
        <div className="card">
            <div className="card-header">
                Track Info
            </div>
            <div className="card-body">

                <form onSubmit={handleSubmit} id="trackForm">
                    <FormControl className={classes.formControl}>
                        <TextField className={classes.formControl} id="name"
                                   label="Track Name"
                                   placeholder="Track Name"
                                   defaultValue={track?.name}
                                   helperText="Track's Name"
                                   variant="filled"
                                   name="name"
                                   required={true}
                                   error={!!errors.name}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField className={classes.formControl} id="description"
                                   label="Track Description"
                                   placeholder="Description"
                                   defaultValue={track?.description}
                                   helperText="Track's Description"
                                   variant="filled"
                                   name="description"
                                   required={false}
                                   error={!!errors.description}
                                   />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                       <TextField className={classes.formControl} id="openings"
                                  label="Track Openings"
                                  placeholder="10"
                                  defaultValue={track?.openings}
                                  helperText="Track Openings"
                                  variant="filled"
                                  name="openings"
                                  required={true}
                                  error={!!errors.openings}
                                  />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TrackListTypeSelect defaultValue={track?.siteTrackType}/>
                    </FormControl>
                    <FormControl>
                        <TextField className={classes.formControl} id="continuousId"
                                   placeholder=""
                                   defaultValue={track?.continuousTrackId}
                                   helperText="Permanent Track ID"
                                   variant="filled"
                                   name="continuousId"
                                   required={true}
                                   error={!!errors.continuousTrackId}
                                   />
                    </FormControl>
                    <input type="submit" value="Submit"/>
                </form>
            </div>
        </div>
    )
}

export default TrackForm;
