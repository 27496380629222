import React from "react";
import {Box, CircularProgress, Typography} from "@mui/material";
import {useAtomValue} from "jotai";
import {slowQueryAtom} from "../Atoms";

interface SpinnerProps {
    message?: string
    small?: boolean
    horizontal?: boolean
    p?: number
    m?: number
}

const SpinnerCard: React.FC<SpinnerProps> = (props) => {

    /*
    const navigate = useNavigate()

    useMemo(() => {
        setTimeout(() => {
            console.log("Navigating to global error due to timeout")
            navigate("/globalError")
        }, 30000)
    },[navigate])

     */

    const slowMessage= useAtomValue(slowQueryAtom)

    const pad = props.p === undefined ? 4 : props.p
    const marg = props.m === undefined ? 4 : props.m

    if (props.horizontal) {
        return (
            <>
                <Box className={`float-left p-${pad} m-${marg} rounded-2xl bg-white`}>
                    {props.message &&
                        <Box display="flex" justifyContent="center">
                            <Typography>{slowMessage || props.message}</Typography>
                        </Box>
                    }
                </Box>
                <Box p={1} sx={{float: "left"}}>
                    <Spinner/>
                </Box>
            </>
        )
    } else {
        return (
                <Box className={`p-4 m-4 rounded-2xl bg-white`}>
                {props.message &&
                    <Box display="flex" justifyContent="center">
                        <Typography>{props.message}</Typography>
                    </Box>
                }
                    {slowMessage && <Box display="flex" justifyContent="center">{slowMessage}</Box>}
                <Box display="flex" justifyContent="center" p={1}>
                    <Spinner/>
                </Box>
            </Box>
        );
    }
}

export const SpinnerSmall: React.FC = () => (
    <CircularProgress/>
)

export const Spinner: React.FC = () => (
    <CircularProgress/>
)

export default SpinnerCard;
