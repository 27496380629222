import React, {FC} from "react"
import ProgramSelector from "../../components/ProgramSelector";
import Groups from "../../model/Groups";
import {SiteSelect} from "../../internship-site/component/SiteSelect";
import {InternshipSite} from "../../internship-site/model/Organization";
import {Program} from "../../program/model/Program";
import {UserInformation} from "../../login/model/UserInformation";
import {ClientId} from "../../client/model/Client";
import {ProductType} from "../../product/model/Product";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";
import {clientAtom} from "../../Atoms";

interface OrderActorSelectorProps {
    sites: InternshipSite[]
    programs: Program[]
    productType?: ProductType
}

export const offerActors = (userInformation: UserInformation, programs: Program[], sites: InternshipSite[], clientId: ClientId): string[] => {
    if (userInformation?.primaryGroup() === Groups.programAdmin) {
        return programs.map(x => x.id?.toString() || "").concat(Array.from(new Set(programs.map(x => x.school?.id?.toString() || ""))))
    }
    else if (userInformation?.primaryGroup() === Groups.siteAdmin) {
        return sites.map(x => x.id?.toString() || "")
    }
    else if (userInformation?.primaryGroup() === Groups.clientAdmin) {
        return [clientId.toString()]
    } else {
        return [userInformation.guid]
    }
}

export const OrderActorSelector: FC<OrderActorSelectorProps> = (props) => {
    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom)

    const localClientId = client?.id || 0

    if (userInformation?.primaryGroup() === Groups.programAdmin) {
        return <ProgramSelector name="orderActorId" programs={props.programs} id={"programIdActorSelector"}/>
    }
    else if (userInformation?.primaryGroup() === Groups.siteAdmin) {
        return <SiteSelect name="orderActorId" sites={props.sites} setSiteId={e => e}/>
    }
    else if (userInformation?.primaryGroup() === Groups.clientAdmin) {
        return <input name="orderActorId" value={localClientId} type="hidden"/>
    } else if (userInformation?.primaryGroup() === Groups.student) {
        return <input name="orderActorId" value={userInformation?.guid} type="hidden"/>
    }
    else {
        return <></>
    }
}