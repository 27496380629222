import {QueryClient} from "@tanstack/react-query";
import {queryClient, refreshUser} from "../../Atoms";

export interface UserRefreshProps {
    queryClient: QueryClient
    updateFlag?: (flag: boolean) => void
    onError?: (e: any) => void
}

export const withUserRefresh = <T,>(props: UserRefreshProps, f: () => Promise<T>): Promise<T> => {
    props.updateFlag && props.updateFlag(true)
    return f().then(x =>
        refreshUser(queryClient)
            .then(() => props.updateFlag && props.updateFlag(false))
            .then(() => x)
            .catch(e => {
                props.onError && props.onError(e)
                props.updateFlag && props.updateFlag(false)
                if (!props.onError) {
                    console.log("An error occurred refreshing user", e)
                }
                return x
            })
    )
}