import {useCallback, useRef} from "react";
import {useAppContext} from "./context";

export function useDebounce<T>(fn: ((t: T) => void), timeMillis: number): (t: T) => void {
    const {isDebug} = useAppContext()

    const timeoutRef = useRef<number|null>(null)

    return useCallback((t: T) => {
        isDebug && console.log("Current timeout", timeoutRef.current)
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current)
        }
        timeoutRef.current = window.setTimeout(() => {
            timeoutRef.current = null
            fn(t)
        }, timeMillis)
    }, [isDebug, fn, timeMillis])
}

