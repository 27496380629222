import {ClientSettings, ClientSettingsOps} from "../client/model/Client";
import {UserInformation} from "../login/model/UserInformation";
import Groups from "../model/Groups";
import {isEmpty} from "../components/customTable/util";
import {requireOneOfGroup} from "../lib/util";
import {useTranslation} from "react-i18next";

export const adminNavNames = [
    "audit"
]

export const navNames = [
    "home",
    "personalProfile",
    "howItWorks",
    "schools",
    "programOverview",
    "programRelationships",
    "applicationMaterials",
    "selectSitesAndTracks",
    "viewRankList",
    "rankTracks",
    "tracks",
    "users",
    "applicants",
    "results",
    "matchResult",
    "siteProfile",
    "directorySearch",
    "sites",
    "trackPrograms",
    "students",
    "login",
    ...adminNavNames
] as const


export type NavName = typeof navNames[number]

export interface NavigationElement {
    to: string
    label: NavName
    requiredRoles: string[]
}

export const useNavigationElements = (settings: ClientSettings, userInformation?: UserInformation) => {
    const {t} = useTranslation()

    const NavNames: Record<NavName, string>  = {
        "home": t("navigation.home"),
        "personalProfile": t("navigation.personalProfile"),
        "howItWorks": t("navigation.howItWorks"),
        "schools": t("navigation.schools"),
        "programOverview": t("navigation.programOverview"),
        "programRelationships": t("navigation.programRelationships"),
        "applicationMaterials": t("navigation.applicationMaterials"),
        "selectSitesAndTracks": t("navigation.selectSitesAndTracks"),
        "viewRankList": t("navigation.viewRankList"),
        "rankTracks": t("navigation.rankTracks"),
        "tracks": t("navigation.tracks"),
        "users": t("navigation.users"),
        "applicants": t("navigation.applicants"),
        "results": t("navigation.results"),
        "matchResult": t("navigation.matchResult"),
        "siteProfile": t("navigation.siteProfile"),
        "directorySearch": t("navigation.directorySearch"),
        "sites": t("navigation.sites"),
        "trackPrograms": t("navigation.trackPrograms"),
        "students": t("navigation.students"),
        "login": t("navigation.login")
    }

    const userGroups = userInformation?.userGroups || []
    return ([
        {
            to: "/userProfile",
            label: NavNames.personalProfile,
            requiredRoles: [Groups.student]
        },
        {
            to: "/howItWorks",
            label: NavNames.howItWorks,
            requiredRoles: [Groups.student]
        },
        {
            to: "/school",
            label: NavNames.schools,
            requiredRoles: [Groups.globalAdmin, Groups.clientAdmin],
        }] as NavigationElement[]).concat(settings.directory === "enabled" ? [{
            to: "/programOverview",
            label: NavNames.programOverview,
            requiredRoles: [Groups.programAdmin, Groups.clientAdmin, Groups.globalAdmin]
        }] as NavigationElement[] : [] ).concat([{
            to: "/program",
            label: NavNames.programRelationships,
            requiredRoles: [Groups.programAdmin],
        }] as NavigationElement[])
        .concat((settings.clientCycleSettings.applicationSuiteSettings.useApplicationSuite && userInformation?.student?.matchedTrackId === undefined) ? [{
            to: "/studentApplication",
            label: NavNames.applicationMaterials,
            requiredRoles: [Groups.student]
        }] : [])
        .concat(isEmpty(userGroups) ? [{
                to: "/howItWorks",
                label: NavNames.howItWorks,
                requiredRoles: []
            }] as NavigationElement[] : []
        )
        .concat([{
            to: "/Student",
            label: NavNames.students,
            requiredRoles: [Groups.globalAdmin, Groups.clientAdmin, Groups.programAdmin]
        }] as NavigationElement[])
        .concat(requireOneOfGroup(userGroups, [Groups.globalAdmin, Groups.clientAdmin]) ? [
            {
                to: "/site",
                label: NavNames.sites,
                requiredRoles: [Groups.globalAdmin, Groups.programAdmin, Groups.clientAdmin, Groups.siteAdmin], // TODO put siteAdmins back in once the read only view supports tracks also
            }
        ] as NavigationElement[] : [])
        .concat(settings.directory === "enabled" ? [{
            to: "/siteProfile",
            label: NavNames.siteProfile,
            requiredRoles: [Groups.siteAdmin, Groups.globalAdmin]
        },
            {
                to: "/directory",
                label: NavNames.directorySearch,
                requiredRoles: []
            }] as NavigationElement[]: [])
        .concat(settings.cycle.matchType !== "manual" && ((ClientSettingsOps.matchPublished(settings) || ClientSettingsOps.rankListLocked(settings)) ) ? [] : [{
            to: "/applications",
            label: NavNames.selectSitesAndTracks,
            requiredRoles: [Groups.student]
        }] as NavigationElement[])
        .concat(ClientSettingsOps.matchPublished(settings) ? []: [
            {
                to: "/trackRanks",
                label: ClientSettingsOps.rankListLocked(settings) ? NavNames.viewRankList : NavNames.rankTracks,
                requiredRoles: [Groups.student]
            }] as NavigationElement[])
        .concat([{
            to: "/track",
            label: NavNames.tracks,
            requiredRoles: [Groups.globalAdmin, Groups.siteAdmin, Groups.clientAdmin, Groups.directoryAdmin],
        }] as NavigationElement[]).concat([
            {
                to: "/trackPrograms",
                label: NavNames.trackPrograms,
                requiredRoles: [Groups.siteAdmin],
            }
        ] as NavigationElement[]).concat([
            {
                to: "/user",
                label: NavNames.users,
                requiredRoles: [Groups.globalAdmin, Groups.clientAdmin],
            }]).concat(settings.clientCycleSettings.applicationSuiteSettings.useApplicationSuite ? [
            {
                to: "/applicants",
                label: NavNames.applicants,
                requiredRoles: [Groups.siteAdmin, Groups.clientAdmin, Groups.globalAdmin]
            }
            ] :[])
        .concat(ClientSettingsOps.matchPublished(settings) ? [{
                to: "/results",
                label: NavNames.results,
                requiredRoles: [Groups.globalAdmin, Groups.clientAdmin, Groups.siteAdmin],
            },
            {
                to: "/studentResult",
                label: NavNames.matchResult,
                requiredRoles: [Groups.student]
            }
        ] :[] as NavigationElement[])
}
