import {Box} from "@mui/material";

export const FloatingButtonsContainer: React.FC = (props) => {
    return <Box className="fixed bottom-4 right-8 z-50" sx={{
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        boxShadow: "0 0 20px 10px rgba(0,0,0,0.3)",
    }}>
        {props.children}
    </Box>

}