import React, {useEffect, useState} from "react";
import {QuestionnaireInputProps} from "../QuestionnaireEdit";
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Tooltip} from "@mui/material";
import {answerFor, useStyles} from "../QuestionnaireList";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {isPresent} from "../../components/customTable/util";

const QuestionnaireSelect = (props: QuestionnaireInputProps) => {
    const [select, setSelect] = useState<string>(answerFor(props) || '')

    useEffect(() => {
        const answer = answerFor(props)
        answer && setSelect(answer)
    }, [props])

    const handleChange = (event: SelectChangeEvent<string>) => {
        const value = event.target.value as string
        setSelect(value)
        props.onChange && props.onChange(value)
    };

    const {classes} = useStyles();

    return (
        <FormControl className={classes.formControl}>
            {props.question.isRequired && !isPresent(props.answer, "answer") &&
            <Tooltip title="This is a required question that has no answer">
                <ErrorOutlineIcon className="stop" style={{position: "absolute", left: "-30px", top: "14px"}}/>
            </Tooltip>
            }
            <InputLabel id={`input-${props.question.id}`}>{props.question.label}</InputLabel>
            <Select labelId={`input-${props.question.id}`} name={`question-${props.question.id}`}
                    aria-label={props.question.helpText}
                    value={select}
                    required={props.requiredBlocking && props.question.isRequired}
                    readOnly={!props.editable}
                    variant="filled"
                    onChange={handleChange}>
                <MenuItem key={""} value={""}>-- None --</MenuItem>
                {props.question.options.map( opt => (
                    <MenuItem key={opt} value={opt}>{opt}</MenuItem>
                ))}
            </Select>
            <small id={`question-${props.question.id}-help`} className="form-text text-muted">{props.question.isRequired &&
            <span>This is a required field.</span>} {props.question.helpText}</small>
        </FormControl>
    )
}

export default QuestionnaireSelect