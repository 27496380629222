import React from "react"
import {Elements} from "@stripe/react-stripe-js";
import {loadStripe} from '@stripe/stripe-js';
import CheckoutForm from "./CheckoutForm";
import {appConfig} from "../conf/conf";
import {useParams} from "react-router-dom";
import {Box} from "@mui/material";
import {PageBlurb} from "../components/PageBlurb";
import {
    EmptyObject,
    LoggedInComponent, LoggedInComponentWrapper
} from "../components/LoggedInPage";

const stripePromise = loadStripe(appConfig.stripe.publishable)

type PaymentParams = {
    orderId?: string
}

const PaymentWrapper: LoggedInComponentWrapper = (context) => {
    return context.state === "ready" ? <Payment {...context}/> : <></>
}

const Payment: LoggedInComponent<EmptyObject> = (props) => {
    const p = useParams<PaymentParams>()

    return <>
        <PageBlurb name="payment" roleSpecific={true}/>
        <Box sx={{margin: "0 auto"}}>
            {p.orderId ?
                <Elements stripe={stripePromise}>
                    <CheckoutForm orderId={p.orderId}/>
                </Elements>
                :
                <Box>Order Id was Missing</Box>
            }
        </Box>
    </>
}

export default PaymentWrapper