import {UseQueryResult} from "@tanstack/react-query/src/types";
import {Order} from "./model/Order";
import {useQuery} from "@tanstack/react-query";
import {API, api} from "../lib/Api";
import {UserInformation} from "../login/model/UserInformation";
import {Client} from "../client/model/Client";

export const listOrderQueryKey = (clientId: number) => ["clientOrders", clientId]

export const useListOrderQuery = (props: {userInformation: UserInformation, client: Client}): UseQueryResult<Order[]> => {
    return useQuery({
        queryFn: () => api<Order[], undefined>(API.listOrders(), props.userInformation, props.client.id).then(x => x.data),
        queryKey: listOrderQueryKey(props.client.id),
        staleTime: 86400000
    })
}
