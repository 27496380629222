import React, {useState} from "react"
import {QuestionnaireElementProps} from "../../model/QuestionnaireList";
import {Checkbox} from "@mui/material";

export const ElementForStudent = (props: QuestionnaireElementProps) => {

    const [checked, setChecked] = useState<boolean>(props.row.studentVisible)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const x = event.target.checked

        setChecked(x);
        props.setElement && props.setElement(elem => {
            return {
                ...elem,
                studentVisible: x
            }
        })
    };

    return (
        <Checkbox
            name="studentVisible"
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'is student visible' }}
        />
    )
}