import React from "react";

import {Program} from "../program/model/Program";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
        maxWidth: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

interface ProgramSelectorProps {
    programs: Program[]
    id: string
    name?: string
    setProgramId?: (x: number) => void
}

const ProgramSelector: React.FC<ProgramSelectorProps> = (props) => {

    const {classes} = useStyles();

    return (
        <FormControl variant="filled" className={classes.formControl}>
            <InputLabel id="user-programs-label">Programs</InputLabel>
            <Select name={props.name}
                    id={props.id}
                    className="form-control"
                    defaultValue={"0"}
                    onChange={e => props.setProgramId && props.setProgramId(parseInt(e.target.value))}>
                <MenuItem key={0} value={"0"}>-- Select Program --</MenuItem>
                {props.programs.map(e => (
                    <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default ProgramSelector