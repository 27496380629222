import {useContext, createContext, SetStateAction, Dispatch} from "react";

import {ModalProps} from "../components/modal/ModalProps";

export type ContextProps = {
    isDebug: boolean,
    setDebug: Dispatch<SetStateAction<boolean>>
    modal: ModalProps,
    setModal: Dispatch<SetStateAction<ModalProps>>,
    signinIncrement: Date,
    setSigninIncrement: Dispatch<SetStateAction<Date>>,
    pageIncrement: Date,
    setPageIncrement: Dispatch<SetStateAction<Date>>,
    shouldBlockNavigation: boolean,
    setShouldBlockNavigation: Dispatch<SetStateAction<boolean>>,
}

export const AppContext = createContext<Partial<ContextProps>>({
    isDebug: true,
    signinIncrement: new Date()
});

export function useAppContext() {
    return useContext(AppContext);
}
