import MaterialTable from "@material-table/core";
import React, {useState} from "react";
import {TableColumn} from "../../components/customTable/CustomTable";
import {StudentUserInformation} from "../../login/model/UserInformation";
import {useAtomValue} from "jotai";
import {Client} from "../../client/model/Client";
import {clientAtom} from "../../Atoms";
import {PacketElementChip} from "../../student/components/PacketElementChip";
import {InternshipSiteId, InternshipSiteTrack, InternshipSiteTrackId} from "../../internship-site/model/Organization";
import {Optional} from "../../lib/Optional";
import {Box, Button} from "@mui/material";
import {useTranslation} from "react-i18next";
import {API, getApiUrl} from "../../lib/Api";
import GetAppIcon from "@mui/icons-material/GetApp";
import {StudentOps} from "../../student/model/Student";
import Collapse from "@mui/material/Collapse";
import {ExpandLess, ExpandMore} from "@mui/icons-material";
import {ApplicantsMatchOptions} from "./ApplicantsMatchOptions";
import {useAppContext} from "../../lib/context";

const ApplicantsInfo: React.FC<{siteId: InternshipSiteId, trackId: InternshipSiteTrackId, student: StudentUserInformation}> = ({siteId, trackId, student}) => {
    const client = useAtomValue(clientAtom) as Client

    const [showDocuments, setShowDocuments] = useState<boolean>(false)

    const {t} = useTranslation()

    const e = Optional.apply(student.student.studentApplicationPackets.applications.find(x => x.siteId === siteId && x.trackId === trackId))

    const submitted = e.map(x => StudentOps.isApplicationSubmitted(x)).getOrElse(false)
    const archived = e.map(x => StudentOps.isApplicationArchived(x)).getOrElse(false)

    return <>

        <Box className="flex flex-col 2xl:flex-row xl:flex-col ">
            <Box className="mr-1">
                <Button startIcon={<GetAppIcon/>} variant="contained" color="primary" href={getApiUrl() + API.downloadPacketPDF(student.guid,siteId, trackId, "inline").location} target="_blank" download>Download Packet PDF</Button>
            </Box>
            <Box className="ml-1 2xl:mt-0">
                <Button disabled={!(submitted || archived)} startIcon={showDocuments ? <ExpandLess/> : <ExpandMore/> } variant="contained" color="primary" onClick={e => setShowDocuments(!showDocuments)}>
                    {(submitted || archived) ? (showDocuments ? t("applicants.buttons.hideDocumentList") : t("applicants.buttons.showDocumentList")) : t("applicants.buttons.pending")}
                </Button>
            </Box>
        </Box>
        <Collapse in={showDocuments} className="relative">
            <Box className="relative border-1 rounded-xl p-4 border-solid border-slate-300 ml-8" id="1234" sx={{width:350}}>
            {e.filter(x => (StudentOps.isApplicationSubmitted(x) || StudentOps.isApplicationArchived(x))).map(app =>
                <>{client.settings.clientCycleSettings.applicationSuiteSettings.packetSlotsDefinition.slotDefinitions
                    .filter(x => StudentOps.userApplicationsForSiteTrack(student, siteId, trackId)?.packetRefs.map(x => x.packetSlotId).includes(x.id))
                    .map(x => PacketElementChip(siteId, trackId, client, student, x))}
                </>
            ).getOrElse(<Box sx={{backgroundColor: "#eeeeee"}} p={2}>Pending Submission...</Box>)
            }
            </Box>
        </Collapse>
    </>
}

export const ApplicantsTable: React.FC<{siteId: InternshipSiteId, track: InternshipSiteTrack, data: StudentUserInformation[]}> = ({siteId, track, data}) => {
    const {t} = useTranslation()

    const {isDebug} = useAppContext()

    isDebug && console.log("Applications length", data.length)

    const columns: Array<TableColumn<StudentUserInformation>> = (
        [{title: t("applicants.table.match"),field: '', render: (row: any) => <ApplicantsMatchOptions siteId={siteId} track={track} student={row}/>},
            {title: t("applicants.table.email"), field: 'student.email', customSort: (a, b) => ((a.email || "") + (b.email|| "")).localeCompare((b.lastName || "") + (b.firstName || "")), render: row => <>{row.email}</>, cellStyle: {"verticalAlign": "top", "paddingTop": "30px"}},
            {title: t("applicants.table.name"), field: '', customSort: (a, b) => ((a.lastName || "") + (b.firstName || "")).localeCompare((b.lastName || "") + (b.firstName || "")), render: row => <>{row.lastName}{row.lastName ? "," : ""} {row.firstName}</>, cellStyle: {"verticalAlign": "top", "paddingTop": "30px"}},
            {title: t("applicants.table.schoolName"), field: 'student.school.name', cellStyle: {"verticalAlign": "top", "paddingTop": "30px"}},
            {title: t("applicants.table.documents"), field: '', render: (row: StudentUserInformation) => <ApplicantsInfo siteId={siteId} trackId={track.id!} student={row}/>}
        ])

    return <MaterialTable
        title="List of Students"
        columns={columns}
        data={data}
    />
}