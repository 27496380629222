import React from 'react';

import {ProfileForm} from "./component/ProfileForm";
import {EmptyObject, LoggedInComponent, LoggedInComponentWrapper} from "../components/LoggedInPage";
import SpinnerCard from "../components/SpinnerCard";

export const ProfileWrapper: LoggedInComponentWrapper = (context) => {
    if (context.state === "ready") {
        return <Profile {...context}/>
    } else {
        return <SpinnerCard message="Loading..."/>
    }
}

const Profile: LoggedInComponent<EmptyObject> = () => {
    return <ProfileForm/>
}

export default ProfileWrapper