import React, {FC, useState} from 'react'
import {API, api, apiError} from "../lib/Api";
import {requireOneOfGroup} from "../lib/util";
import Groups from "../model/Groups";
import {AuthenticationFailed} from "../components/AuthenticationFailed";
import {useAppContext} from "../lib/context";
import {Offer} from "./model/Product";
import {Page, PageBody, PageHeader} from "../components/Page";
import MaterialTable from "@material-table/core";
import SpinnerCard from "../components/SpinnerCard";
import {TableColumn} from "../components/customTable/CustomTable";
import {useParams} from "react-router-dom";
import {DollarDecimal} from "./lib/DollarDecimal";
import {RichElement} from "../components/customTable/RichElement";
import {confirmDeleteModal, messageModal} from "../components/modal/InfoModal";
import {EditTimestamp} from "../client/component/EditTimestamp";
import useUserReadyService from "../user/lib/UserReadyService";
import {makeStyles} from "tss-react/mui";
import {userInformationAtom} from "../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom} from "../Atoms";
import {useQuery} from "@tanstack/react-query";
import {UseQueryResult} from "@tanstack/react-query/src/types";

interface OfferListProps {

}

const useStyles = makeStyles()((theme) => ({
    formControl: {
        minWidth: "100%",
        maxWidth: "100%",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    }
}));

export const OfferList: FC<OfferListProps> = (props: OfferListProps) => {
    const {isDebug, setModal} = useAppContext()

    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom)

    const params = useParams()

    const [offers, setOffers] = useState<Offer[]>();

    const userReady = useUserReadyService()

    const classes = {useStyles}

    const offersQuery: UseQueryResult<Offer[]> = useQuery({
        queryFn: () => {
            if (params.productId) {
                return api<Offer[], undefined>(API.listOffersForProduct(params.productId), userInformation, client.id).then(res => {
                    isDebug && console.log("Offer List", res.data)
                    return res.data
                })
            }
            else {
                return api<Offer[], undefined>(API.listOffers(), userInformation, client.id).then(res => {
                    isDebug && console.log("Offer List", res.data)
                    return res.data
                })
            }
        }
    })

    const addOffer = (row: Offer) => api(API.addOffer(), userInformation, client.id, row).then(res => {
        setModal && setModal(messageModal("Offer Added", "Added a new offer: " + row.name))
        return row
    })

    const deleteOffer = (oldData: Offer) => new Promise<any>((resolve, reject) =>  {
            return setModal && setModal(confirmDeleteModal<Offer>(oldData, (action: string, offer: Offer) => {
                return action === "delete" && api(API.deleteOffer(oldData.id), userInformation, client.id)
                    .then(result => {
                        isDebug && console.log("Deleted offer")
                        setModal && setModal(messageModal("Offer", "Offer Removed"))
                        offers && setOffers(offers.filter(x => x.id !== oldData.id))
                        resolve(oldData)
                    }).catch(error => {
                        apiError(error, setModal)
                        reject(oldData)
                    })
            }, "Are you sure you wish to delete this offer?"))
        })

    const fixOfferPayload = (newData: any): Offer => {
        return {...newData,
            price: typeof newData.price === "string" ? parseFloat(newData.price) : newData.price,
            fixedQuantity: typeof newData.fixedQuantity === "string" ? parseInt(newData.fixedQuantity) : newData.fixedQuantity,
            validFrom: new Date(newData.validFrom),
            validTo: new Date(newData.validTo),
            createdTimestamp: new Date(newData.createdTimestamp)
        } as Offer
    }

    const updateOffer = (newData: Offer, oldData?: Offer) => new Promise<Offer>((resolve, reject) => {
        isDebug && console.log("Updated Offer is",newData)
        api(API.updateOffer(newData.id), userInformation, client.id, fixOfferPayload(newData)).then(result => {
            setModal && setModal(messageModal("Offer", "Offer Updated"))
            offers && setOffers([...offers.filter(x => x.id !== newData.id), fixOfferPayload(newData)])
            resolve(newData)
        }).catch(error => {
            apiError(error, setModal)
            reject(oldData)
        })
    })

    if (userReady && !requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin, Groups.clientAdmin])) {
        isDebug && console.log("Returning auth failed")
        return (<AuthenticationFailed/>)
    }

    const columns: Array<TableColumn<Offer>> = [{
        title: "ID", field: "id", editable: "never"
    }, {
        title: "Product", field: "product.name", editable: "never"
    }, {
        title: "Product Type", field: "product.productType", editable: "never"
    }, {
        title: "Name", field: "name"
    }, {
        title: "price", field: "price", render: row => <DollarDecimal value={row.price}/>
    }, {
        title: "fixedQuantity", field: "fixedQuantity"
    }, {
        title: "Valid From", field: "validFrom", render: row => <RichElement value={row.validFrom}/>,
        editComponent: props => <EditTimestamp name={`validFrom_${props.rowData.id}`} value={props.rowData.validFrom} onChange={e => props.onChange(e)} classes={classes} label="Valid From"/>
    }, {
        title: "Valid To", field: "validTo", render: row => <RichElement value={row.validTo}/>,
        editComponent: props => <EditTimestamp name={`validTo_${props.rowData.id}`} value={props.rowData.validTo} onChange={e => props.onChange(e)} classes={classes} label="Valid To"/>
    }, {
        title: "Created At", field: "createdTimestamp", render: row => <RichElement value={row.createdTimestamp}/>, editable: "never"
    }]

    return <Page navName="OfferList">
            <PageHeader title="Offer List">Offer List</PageHeader>
            <PageBody>
                {offersQuery.isSuccess ?
                <MaterialTable columns={columns} data={offersQuery.data} options={{pageSize: 10}}
                editable={{
                    onRowDelete: deleteOffer,
                    onRowUpdate: updateOffer,
                    onRowAdd: addOffer
                }}/>
                    : <SpinnerCard message="Loading offers..."/>}
            </PageBody>
        </Page>
}