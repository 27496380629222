import {useAppContext} from "../../lib/context";
import {DialogActionProps, DialogType, ModalAction, ModalProps} from "./ModalProps";
import {ConfirmDialogActions, DeleteDialogActions} from "./InfoModal";
import React, {ReactElement} from "react";
import {SvgIconComponent} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import QuestionIcon from "@mui/icons-material/Help";
import AnnouncementIcon from '@mui/icons-material/Announcement';
import { useTranslation } from 'react-i18next';


export interface ModalActionsPropsWithData<T> {
    onConfirm?: (data: T) => void,
    onCancel?: (data: T) => void
    onDelete?: (data: T) => void
    onClose?: (data: T) => void
}

export type ModalActionsHandlers<T> = ModalActionsPropsWithData<T>

export interface MessageModalActionHandlers {
    onClose: () => void
}

export interface ConfirmModalActionHandlers<T> extends ModalActionsPropsWithData<T> {
    onConfirm: (data: T) => void,
    onCancel?: (data: T) => void
}

export interface ConfirmDeleteModalActionHandlers<T> extends ModalActionsPropsWithData<T> {
    onDelete: (data: T) => void
}

export const useModalService = () => {
    const {setModal} = useAppContext()

    const { t} = useTranslation();

    const questionModal = <T,>(e: T, title: string, message: string, dialogType: DialogType, callback: (action: ModalAction, e: T) => void,
                               actions: (props: DialogActionProps) => ReactElement = props => <ConfirmDialogActions handleClose={props.handleClose}/>,
                               iconComponent: SvgIconComponent = CloseIcon, iconColor: string = "#888888") => {
        return {
            isOpen: true,
            title: title,
            message: message,
            icon: iconComponent,
            onClose: (action: ModalAction) => callback(action, e),
            dialogType: dialogType,
            iconColor: iconColor,
            dialogActionComponents: actions
        } as ModalProps
    }

    const componentQuestionModal = <T,>(e: T, title: string, component: ReactElement, dialogType: DialogType, callback: (action: ModalAction, e: T) => void,
             actions: (props: DialogActionProps) => ReactElement = props => <ConfirmDialogActions handleClose={props.handleClose}/>,
             iconComponent: SvgIconComponent = CloseIcon, iconColor: string = "#888888") => {
        return {
            ...questionModal(e, title, "", dialogType, callback, actions, iconComponent, iconColor),
            component
        }
    }

    const callbackHandler = <T,>(action: ModalAction, modalActionProps: ModalActionsHandlers<T>, data: T) => {
        switch (action) {
            case ModalAction.Confirm:
                modalActionProps.onConfirm && modalActionProps.onConfirm(data)
                break
            case ModalAction.Close:
                modalActionProps.onClose && modalActionProps.onClose(data)
                break
            case ModalAction.Delete:
                modalActionProps.onDelete && modalActionProps.onDelete(data)
                break
            case ModalAction.Cancel:
                modalActionProps.onCancel && modalActionProps.onCancel(data)
                break
        }
    }

    const confirmChangeModal = <T,>(data: T, handlers: ConfirmModalActionHandlers<T>, message?: string, icon: SvgIconComponent = QuestionIcon, iconColor = "#888888") => {
        setModal && setModal(
            questionModal(data, "Confirm", message || "Are you sure you want to update this item?", DialogType.DeleteConfirm,
                (action: ModalAction) => callbackHandler(action, handlers, data),
            (props: DialogActionProps) => (<ConfirmDialogActions handleClose={props.handleClose}/>), icon, iconColor)
        )
    }

    const componentConfirmChangeModal = <T,>(data: T, title: string, handlers: ConfirmModalActionHandlers<T>, component: ReactElement, icon: SvgIconComponent = QuestionIcon, iconColor = "#888888") => {
        setModal && setModal(
            componentQuestionModal(data, title, component, DialogType.DeleteConfirm,
                (action: ModalAction) => callbackHandler(action, handlers, data),
                (props: DialogActionProps) => (<ConfirmDialogActions handleClose={props.handleClose}/>), icon, iconColor)
        )
    }

    const confirmDeleteModal = <T,>(data: T, handlers: ConfirmDeleteModalActionHandlers<T>, message?: string) => {
        setModal && setModal(
            questionModal(data, t("modal.general.confirmDeleteTitle"), message || t("modal.general.confirmDeleteMessage"), DialogType.DeleteConfirm,
            (action: ModalAction) => callbackHandler(action, handlers, data),
            (props: DialogActionProps) => <DeleteDialogActions handleClose={props.handleClose}/>)
        )
    }


    const messageModal = (title: string, message: string, handlers: MessageModalActionHandlers = {onClose: () => {}}) => setModal && setModal({
        isOpen: true,
        title: title,
        message: message,
        icon: AnnouncementIcon,
        dialogType: DialogType.Message,
        onClose: () => handlers.onClose(),
    })

    const i18nMessageModal = (titleI18nKey: string, i18nKey: string, handlers: MessageModalActionHandlers = {onClose: () => {}}) => setModal && setModal({
        isOpen: true,
        title: t(titleI18nKey),
        message: t(i18nKey),
        icon: AnnouncementIcon,
        dialogType: DialogType.Message,
        onClose: () => handlers.onClose(),
    })

    const errorMessageModal = (title: string, message: string, handlers: MessageModalActionHandlers = {onClose: () => {}}, iconColor?: string) => setModal && setModal({
        isOpen: true,
        title: title,
        message: message,
        dialogType: DialogType.Message,
        icon: WarningAmberIcon,
        iconColor: iconColor || "#a00000"
    } as ModalProps)

    const componentModal = (title: string, component: ReactElement) => ({
        isOpen: true,
        title: title,
        message: "",
        component: component,
        dialogType: DialogType.Message
    } as ModalProps);

    return {
        componentConfirmChange: componentConfirmChangeModal,
        confirmChange: confirmChangeModal,
        confirmDelete: confirmDeleteModal,
        question: questionModal,
        message: messageModal,
        i18nMessage: i18nMessageModal,
        errorMessage: errorMessageModal,
        component: componentModal
    }
}