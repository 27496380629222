import {Offer, ProductType} from "../../product/model/Product";
import {ClientCycleId, ClientId} from "../../client/model/Client";
import Groups from "../../model/Groups";

export type OrderId = string
export type PaymentId = string

interface Payment {
    id: PaymentId
    clientId: ClientId
    orderId: OrderId
    userEmail: string
    amount: number
    createdTimestamp: Date
    paymentIntent?: string
    paymentComments?: string
}

export interface Order {
    id: OrderId
    clientId: ClientId
    clientCycleId: ClientCycleId
    orderEntries: Array<OrderEntry>
    orderActorId: string
    userEmail: string
    totalPrice?: number
    productType: ProductType
    createdTimestamp: Date
    payments?: Payment[]
}

export interface OrderEntry {
    quantity: number
    unitPrice: number
    offer: Offer
}

export const offerTypeGroupMapping: {[k: string]: ProductType} = {
    [Groups.student]: "StudentProduct",
    [Groups.siteAdmin]: "OrganizationProduct",
    [Groups.clientAdmin]: "ClientProduct",
    [Groups.programAdmin]: "ProgramProduct",
}
