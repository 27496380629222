import {useQuery, useQueryClient} from "@tanstack/react-query";
import {API, api} from "../lib/Api";
import {useNavigate} from "react-router-dom";
import {Client} from "../client/model/Client";
import {clientAtom} from "../Atoms";
import {useAtomValue} from "jotai";
import {EmptyObject, StudentLoggedInComponent, StudentLoggedInComponentWrapper} from "../components/LoggedInPage";
import {refreshUser} from "../login/lib/UserSessionsService";
import {useModalService} from "../components/modal/ModalService";
import SpinnerCard from "../components/SpinnerCard";

const FinalizeQuestionnaireSubmissionWrapper: StudentLoggedInComponentWrapper = (context) => {
    if (context.state === "ready") {
        return <FinalizeQuestionnaireSubmission {...context}/>
    }
    else {
        return <SpinnerCard message="Finalizing Questionnaire Submission..."/>
    }

}

const FinalizeQuestionnaireSubmission: StudentLoggedInComponent<EmptyObject> = (props) => {

    const {userInformation} = props

    //const setErrorMessage = useSetAtom(flashingErrorMessageAtom)

    const client = useAtomValue(clientAtom) as Client

    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const modal = useModalService()

    //console.log("Finalizing render with count", counter)

    useQuery({
        queryFn: () => api(API.finalizeStudentQuestionnaire(userInformation.guid), props.userInformation, client.id)
            .then(x => x.data)
            .then(x => {
                refreshUser(queryClient).then(() => x)
            })
            .then(() => {
                modal.message("Questionnaire Submitted", "Your questionnaire has been submitted to your program manager for review. You will be notified when it has been reviewed.",
                    {onClose: () => navigate("/studentApplication")}
                )
            })
        ,
        queryKey: ["finalizeStudentQuestionnaire", userInformation.guid],
        enabled: props.userInformation.student.paid && !props.userInformation.student.materials.questionnaireSubmitted,
        staleTime: 86400000
    })

    /* TODO need to put SOME of this back in - checking for payment success is important here
    useEffect(() => {
        console.log("FQS Effecting", counter)
        if (counter === 0) {
            // If they aren't yet marked paid, return to payment
            if (!props.userInformation.student.paid) {
                isDebug && console.log("Student has not yet paid, redirecting to order page")
                setCounter(counter => counter + 1)
                setErrorMessage("You must finalize your payment before your questionnaire submission can be processed.")
                navigate("/order")
            } else if (!props.userInformation.student.materials.questionnaireSubmitted) {
                isDebug && console.log("Student has paid, but is not yet marked submitted")
                setCounter(counter => counter + 1)
                isDebug && console.log("User is NOT marked paid or NOT marked questionnaire complete, updating...")
                finalizeMutation.mutateAsync(props.userInformation.guid)
                    .then(x => setCounter(counter => counter + 1))
                    .then(x => refreshUser(queryClient))

            } else {
                setCounter(counter => counter + 1)
                isDebug && console.log("Student is marked paid, and submitted, going back to application page.", counter)
                navigate("/studentApplication")
            }
        }
        else {
            isDebug && console.log("Counter is not 0, not doing anything")
        }
    }, [props.userInformation, queryClient, setErrorMessage, finalizeMutation, setCounter, counter, isDebug, modal, navigate])

     */

    return <SpinnerCard message="Finalizing..."/>
}

export default FinalizeQuestionnaireSubmissionWrapper