import {Box, IconButton, Tooltip} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import React from "react";
import {useNavigate} from "react-router-dom";
import {InternshipSite} from "../../internship-site/model/Organization";

export const DirectoryEntryInfoButton: React.FC<{site: InternshipSite}> = (props) => {
    const navigate = useNavigate()

    return <Box>
        <Tooltip title="View Directory Entry">
            <IconButton
                onClick={e => navigate(`/directory/entry/${props.site.organizationId}/${props.site.directoryQuestionnaireId}/${props.site.id}`)}
                size="large"><InfoIcon/></IconButton>
        </Tooltip>
    </Box>
}