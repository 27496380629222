import React from "react"
import {Order} from "./model/Order";
import {Grid} from "@mui/material";
import {RichElement} from "../components/customTable/RichElement";

export interface OrderDetailProps {
    order: Order
}

export const OrderDetail: React.FC<OrderDetailProps> = ({order}) => (
    <Grid container spacing={2} m={2}>
        <Grid item xs={2}>ID</Grid>
        <Grid item xs={2}>Payment Date/Time</Grid>
        <Grid item xs={1}>Amount</Grid>
        <Grid item xs={2}>Email</Grid>
        <Grid item xs={2}>Payment Intent</Grid>
        <Grid item xs={2}>Payment Comments</Grid>
        {order.payments && order.payments.map(entry => (
            <>
                <Grid item xs={2}>{entry.id}</Grid>
                <Grid item xs={2}><RichElement value={entry.createdTimestamp}/></Grid>
                <Grid item xs={1}>{entry.amount}</Grid>
                <Grid item xs={2}>{entry.userEmail}</Grid>
                <Grid item xs={2}>{entry.paymentIntent}</Grid>
                <Grid item xs={3}>{entry.paymentComments}</Grid>
            </>
        ))}
    </Grid>
)