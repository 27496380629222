import {API, api} from "../../lib/Api";
import {ClientCycle, ClientId} from "../model/Client";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";
import {useQuery} from "@tanstack/react-query";
import {UseQueryResult} from "@tanstack/react-query/src/types";
import {UserInformation} from "../../login/model/UserInformation";
import {clientAtom} from "../../Atoms";

export const listClientCyclesKey = (clientId: ClientId) => ["listClientCycles", clientId]

const useListClientCyclesService = (): UseQueryResult<ClientCycle[]> => {

    const userInformation: UserInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom)

    return useQuery({
        queryFn: () => api<ClientCycle[], undefined>(API.listClientCycles(), userInformation, client.id)
            .then(response => response.data),
        queryKey: [listClientCyclesKey(client.id)]
    })
}

export default useListClientCyclesService;
