
import React, {useState} from 'react'

import {MenuItem, Select, SelectChangeEvent} from "@mui/material"
import {trackTypes} from "../model/Organization";


interface TrackListTypeSelectProps {
    onChange?: (value: any) => void
    defaultValue?: number
}


export const TrackListTypeSelect: React.FC<TrackListTypeSelectProps> = (props: TrackListTypeSelectProps) => {
    const [state, setState] = useState<number|undefined>(props.defaultValue)

    const handleChange = (event: SelectChangeEvent<number|undefined>) => {
        const value = event.target.value as string
        setState(parseInt(value))
        props.onChange && props.onChange(parseInt(value))
    };

    return (
        <Select
            name="siteTrackType"
            id="trackTypeSelect"
            value={state}
            onChange={handleChange}
        >
            {Object.entries(trackTypes).map(x => (
                <MenuItem value={x[0]}>{x[1]}</MenuItem>
            ))}
        </Select>
    )
}