import React, {useState} from "react"
import {useAppContext} from "../../lib/context";
import {
    Box,
    IconButton,
    Tooltip, Typography,
} from "@mui/material";
import {Item} from "../../components/draggableList/DnDContainer";
import {API, api} from "../../lib/Api";
import {InternshipSite} from "../../internship-site/model/Organization";
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import {useNavigate} from "react-router-dom";
import SpinnerCard, {SpinnerSmall} from "../../components/SpinnerCard";
import {useStudentOrganizationService} from "../lib/StudentOrganizationService";
import {Lock} from "@mui/icons-material";
import {Client, ClientSettingsOps} from "../../client/model/Client";
import {useAtomValue} from "jotai";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {clientAtom} from "../../Atoms";
import {useApiWrapper} from "../../lib/APIWrapper";
import {useModalService} from "../../components/modal/ModalService";
import {StudentUserInformation, UserOps} from "../../login/model/UserInformation";
import {DirectoryEntryInfoButton} from "./DirectoryEntryInfoButton";
import Collapse from "@mui/material/Collapse";
import {TrackInformation} from "../../questionnaire/component/search/component/DirectoryEntry";
import {applyToWhere, ManageApplicationButton, SiteName} from "./AppliedSiteList";
import {StudentOps} from "../model/Student";
import {withUserRefresh} from "../../login/lib/UserRefresh";
import {useTranslation} from "react-i18next";

interface SiteRowProps {
    site: InternshipSite
    sites: InternshipSite[]
    userInformation: StudentUserInformation,
    client: Client
}

const SiteAddButton: React.FC<{
    locked: boolean, maxed: boolean, client: Client, userInformation: StudentUserInformation, site: InternshipSite
}> = ({locked, maxed, client, userInformation, site}) => {
    const {isDebug} = useAppContext()
    const [siteMutate, setSiteMutate] = React.useState<Record<number, boolean>>({})

    const modal = useModalService()
    const navigate = useNavigate()
    const queryClient = useQueryClient()
    const {t} = useTranslation()

    const addSiteToStudentMutation = useMutation({
        mutationFn: (siteId: number) => api(API.addSiteToStudent(userInformation?.guid || "", siteId), userInformation, client.id)
    })

    const handleApplyToSite = (internshipSiteId: number | undefined) => {
        isDebug && console.log("Firing apply", userInformation, internshipSiteId)
        if (userInformation?.guid && internshipSiteId) {

            if (!StudentOps.hasPaid(userInformation)) {
                modal.errorMessage("Payment Due", "You are not currently eligible to apply as payment has not been finalized, you will be redirected to a payment page.",
                    {onClose: () => navigate("/order")})
            } else {
                if (!maxed) {
                    withUserRefresh({
                            queryClient,
                            updateFlag: (b) => setSiteMutate(e => ({...e, [internshipSiteId]: b}))
                        },
                        () => addSiteToStudentMutation.mutateAsync(internshipSiteId))
                        .then(() => modal.message("Applied", "You have added this site to your site list"))
                } else {
                    modal.message(t("applications.modals.maxedMessageTitle"),
                        t("application.modals.maxedMessage", {count: userInformation?.student?.applicationLimit}))
                }
            }
        }
    }

    if (siteMutate[site.id || 0]) {
        return <SpinnerSmall/>
    } else {
        return (locked || maxed) ? <IconButton edge="end" aria-label="apply" size="large"><Lock/></IconButton>
            : <Tooltip title={t("applications.tooltips.addToSiteList")}>
                <IconButton edge="end" aria-label={t("applications.tooltips.addToSiteList")} size="large"
                            onClick={e => handleApplyToSite(site.id)}>
                    <NoteAddIcon/>
                </IconButton>
            </Tooltip>
    }
}

const SiteRow = (props: SiteRowProps) => {
    const client = useAtomValue(clientAtom) as Client
    const userInformation = props.userInformation

    const locked: boolean = ClientSettingsOps.siteListLocked(userInformation)
    const maxed = ((userInformation?.student?.applicationLimit || 10)) === (userInformation?.student?.sites?.length || 0)

    const {applyToSite} = applyToWhere(client)

    const [open, setOpen] = useState<boolean>(false)

    const tracks = props.sites.find(e => e.id === props.site.id)?.siteTracks

    return <Box p={1} m={1} className="bg-white">
        <Box alignItems="center" flexWrap="nowrap" display="flex">
            {client.settings.directory === "enabled" &&
                <DirectoryEntryInfoButton site={props.site}/>
            }

            <SiteName open={open} setOpen={setOpen} site={props.site} locked={locked}/>
            <SiteAddButton locked={locked} maxed={maxed} client={client} userInformation={userInformation}
                           site={props.site}/>
            {applyToSite && <ManageApplicationButton userInformation={userInformation} site={props.site}/>}
        </Box>
        <Box>
            <Collapse in={open} timeout="auto" unmountOnExit style={{width: "100%"}}>
                <Box margin={1} pt={1}>
                    {tracks && tracks.length > 0 ? tracks
                        .filter(t => UserOps.isTrackVisible(userInformation, t.id!))
                        .map(track =>
                            <TrackInformation key={track.id} site={props.site} siteTrack={track}
                                              showManageSiteButton={false}/>
                        ) : <Typography>No Tracks Available</Typography>}
                </Box>
            </Collapse>
        </Box>
    </Box>
}

export const NotAppliedSiteList: React.FC<{ userInformation: StudentUserInformation }> = (props) => {
    const {isDebug} = useAppContext()
    const userInformation = props.userInformation
    const client = useAtomValue(clientAtom) as Client

    const orgService = useApiWrapper(useStudentOrganizationService({clientId: client.id}), "Student Organization Service")

    if (orgService.isSuccess) {
        const sites = orgService.data.map(x => x.internshipSite)

        const itemComponent = (x: InternshipSite, i: number) => {
            return (<SiteRow key={x.id} site={x} sites={sites} client={client} userInformation={userInformation}/>)
        }

        isDebug && console.log("Setting items on Application List")

        const items = userInformation?.student ? sites
            .filter(x => !userInformation?.student?.sites.map(y => y.id).includes(x.id))
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((x, i) => ({
                id: x.id && x.id.toString(),
                data: x,
                component: itemComponent(x, i + 1)
            })) as Item<InternshipSite>[] : []


        return <>
            {(items && items.length > 0) ? items.map((x, i) => x.component) : <Typography>No Sites</Typography>}
        </>
    } else {
        return <SpinnerCard message="Loading"/>
    }

}