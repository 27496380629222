import React, {useEffect, useState} from 'react';
import '../../Login.css';
import {api, API, apiError} from '../../lib/Api'
import {useAppContext} from "../../lib/context";
import {useNavigate} from 'react-router-dom';
import {
    emptyInternshipSite, InternshipSite, Organization, OrganizationId,
} from "../model/Organization";
import {Address} from "../../components/model/Address";
import InternshipTracksTable from "./InternshipTracksTable";
import {Box, Button, FormControl} from "@mui/material";
import TextField from "@mui/material/TextField";
import {Theme} from "@mui/material/styles";
import {formDataAsMap} from "../../lib/Forms";
import SpinnerCard from "../../components/SpinnerCard";
import internshipSiteFromOrg from "../lib/InternshipSiteFromOrganization";
import {isPresent} from "../../components/customTable/util";
import {PageBody, PageHeader} from "../../components/Page";
import {makeStyles} from "tss-react/mui";
import useUserReadyService from "../../user/lib/UserReadyService";
import {userInformationAtom} from "../../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom} from "../../Atoms";
import {DialogType} from "../../components/modal/ModalProps";
import {EditCheckbox} from "../../client/component/EditCheckbox";
import {Client} from "../../client/model/Client";

interface InternshipSiteFormState extends InternshipSite {
    id?: number
    clientId: number
    name: string
    description: string
    address?: Address
    organizationId: OrganizationId
    continuousId: string
}

interface InternshipSiteFormErrors {
    name?: string
    description?: string
    comments?: string
    address?: {
        address1?: string
        address2?: string
        city?: string
        region?: string
        postalCode?: string
    }
    continuousId?: string
}

interface InternshipSiteFormProps {
    orgId?: string
    siteId?: string
}

const useStyles = makeStyles()((theme: Theme) => ({
        formControl: {
            minWidth: 120,
            width: "100%",
            marginTop: theme.spacing(1)
        },
    })
);


const InternshipSiteForm = (props: InternshipSiteFormProps) => {
    const {modal, setModal, isDebug} = useAppContext();

    const client = useAtomValue(clientAtom) as Client
    const userInformation = useAtomValue(userInformationAtom)

    const localClientId: number = client.id
    const localId = props.orgId ? parseInt(props.orgId) : 0;

    isDebug && console.log(client?.settings)

    const isNewSite = localId === 0

    const navigate = useNavigate();

    const [errors, setErrors] = useState<InternshipSiteFormErrors>({});

    //const { register, handleSubmit, errors } = useForm<OrganizationFormState>();

    const [internshipSite, setInternshipSite] = useState<InternshipSiteFormState | null>(emptyInternshipSite);

    const userReady = useUserReadyService()

    useEffect(() => {
        if (userReady && localId && localId > 0) {
           api<Organization, number>(API.getOrganization(localId), userInformation, localClientId, localId)
                .then(e => {
                    isDebug && console.log("Fetch org", e);
                    setInternshipSite(internshipSiteFromOrg(e.data))
                })
                .catch(error => console.log(error));
        }
    }, [props.orgId, localClientId, localId, userInformation, userReady, isDebug])

    const validate = (data: InternshipSiteFormState) => {
        const errs = {
        ...(isPresent(data, "name") ? {} : {name: "This field is required"})
        }
        setErrors(state => ({...state, ...errs}))
        return errs
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        const data = formDataAsMap<InternshipSiteFormState>(event.target as HTMLFormElement)

        const errs = validate(data)

        isDebug && console.log("Submitted", data)

        if (Object.entries(errs).length > 0) return;

        const localData = {...data, id: localId, clientId: localClientId, sites: [],
        address: {
            ...data.address,
            id: internshipSite?.address?.id || 0
        }};
        console.log("Sending site update ", localData)
        const p = (localId === 0 ?
            api(API.addOrganization(), userInformation, localClientId, localData) :
            api(API.updateOrganization(localData.id), userInformation, localClientId, localData));

        p.then(r => {
            isDebug && console.log(localData);
            setInternshipSite({...internshipSite, ...data});
            isDebug && console.log(setModal);
            setModal && setModal({
                title: "Info",
                message: "Training Site Saved Successfully",
                isOpen: true,
                onClose: () => {
                    navigate("/site")
                },
                dialogType: DialogType.Message
            })
            isDebug && console.log(modal)
        })
          .catch(x => {
              isDebug && console.log("Failed to make call to add organization, got ", x)
              apiError(x, setModal)
          })
    }

    const {classes} = useStyles()

    return (
        <>
            <PageHeader title="Training Site Info"/>
            <PageBody>
                {(isNewSite || isPresent(internshipSite, "name")) ?
                <form onSubmit={handleSubmit} id="organizationForm">
                    {isNewSite ||
                    <FormControl className={classes.formControl}>
                        <TextField
                            className={classes.formControl}
                            id="continuousId"
                            label="Continuous ID"
                            defaultValue={internshipSite?.continuousId}
                            helperText={`Internship site's year over year identifier. ${errors?.continuousId ? errors?.continuousId : ""}`}
                            variant="filled"
                            name="continuousId"
                            required={true}
                            error={!!errors.continuousId}
                        />
                    </FormControl>
                    }
                    <FormControl className={classes.formControl}>
                        <TextField
                            className={classes.formControl}
                            id="organizationName"
                            label="Name"
                            defaultValue={internshipSite?.name}
                            helperText={`Site's Name. ${errors?.name ? errors.name : ""}`}
                            variant="filled"
                            name="name"
                            error={!!errors.name}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <InternshipTracksTable row={internshipSite || emptyInternshipSite}/>
                    </FormControl>
                    {client?.settings?.showDuesPaidFlag && (
                        <FormControl className={classes.formControl}>
                            <EditCheckbox label="Dues Paid" name="internshipSite.duesPaid" value={internshipSite?.duesPaid || false}/>
                        </FormControl>
                    )}
                    {client?.settings?.showAttestationFiledFlag && (
                        <FormControl className={classes.formControl}>
                            <EditCheckbox label="Attestation Filed" name="internshipSite.attestationFiled" value={internshipSite?.attestationFiled || false}/>
                        </FormControl>
                    )}
                    <FormControl className={classes.formControl}>
                        <TextField
                            id="organizationDescription"
                            label="Description"
                            defaultValue={internshipSite?.description}
                            helperText={`Site's Description. ${errors?.description ? errors.description : ""}`}
                            variant="filled"
                            name="description"
                            error={!!errors.description}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            id="organizationAddress1"
                            label="Address"
                            defaultValue={internshipSite?.address?.address1}
                            helperText={`Organization's main address. ${errors?.address?.address1 ? errors.address.address1 : ""}`}
                            variant="filled"
                            name="address.address1"
                            error={!!errors.address?.address1}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            id="organizationAddress2"
                            label="Suite / Apt"
                            defaultValue={internshipSite?.address?.address2}
                            helperText={`Site suite or unit number${errors?.address?.address2 ? errors.address.address2 : ""}`}
                            variant="filled"
                            name="address.address2"
                            error={!!errors.address?.address2}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            id="organizationCity"
                            label="City"
                            defaultValue={internshipSite?.address?.city}
                            helperText={`Site's city ${errors?.address?.city ? errors.address.city : ""}`}
                            variant="filled"
                            name="address.city"
                            error={!!errors.address?.city}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            className={classes.formControl}
                            id="organizationRegion"
                            label="State"
                            defaultValue={internshipSite?.address?.region}
                            helperText={`Site's state ${errors?.address?.region ? errors.address.region : ""}`}
                            variant="filled"
                            name="address.region"
                            error={!!errors.address?.region}
                        />
                    </FormControl>
                    <FormControl className={classes.formControl}>
                        <TextField
                            className={classes.formControl}
                            id="organizationPostalCode"
                            label="Zipcode"
                            defaultValue={internshipSite?.address?.postalCode}
                            helperText={`Site's zipcode ${errors?.address?.postalCode ? errors.address.postalCode : ""}`}
                            variant="filled"
                            name="address.postalCode"
                            error={!!errors.address?.postalCode}
                        />
                    </FormControl>
                    <Box component="span" m={1}>
                        <Button type="submit" value="Submit" color="primary">Submit</Button>
                    </Box>
                    <Box component="span" m={1}>
                        <Button value="Cancel" color="primary" onClick={() => navigate("/site")}>Cancel</Button>
                    </Box>
                    <input name="address.id" value={internshipSite?.address?.id} type="hidden"/>
                </form>
                    :
                    <SpinnerCard message="Loading"/>
                }
            </PageBody>
        </>
    )
}

export default InternshipSiteForm;
