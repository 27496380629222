import {AdminUserInformation, StudentUserInformation, UserInformation,} from "../model/UserInformation";
import {API, api} from "../../lib/Api";
import {QueryClient, useQuery} from "@tanstack/react-query";
import {
    lastUserUpdateAtom,
    loginStateAtom,
    userInformationAtom,
    userInformationQueryKey
} from "../../Atoms";
import {useSetAtom} from "jotai";
import {useAppContext} from "../../lib/context";
import {UseQueryResult} from "@tanstack/react-query/src/types";
import {buildUserInformationFromCognito, CognitoResult, retrieveUserSession} from "../../components/CognitoAuth";

export const userInformationSentinel = new AdminUserInformation({guid: "", email: ""})

export enum LoginState {
    "InitialState",
    "Authenticating",
    "Unauthenticated",
    "AuthenticationFailed",
    "NewPasswordRequired",
    "AuthenticationSuccess",
    "UserReady",
    "BadSystemState",
}

export const userInformationFromProps = (props: any) => {
    const x =
        props.student ? new StudentUserInformation(props) : new AdminUserInformation(props)
    x.clientId = props.clientId
    x.password = props.password
    x.newPassword = props.newPassword
    x.userGroups = props.userGroups
    if (props.programs) x.programs = props.programs
    if (props.sites) x.sites = props.sites
    if (props.jwtToken) x.jwtToken = props.jwtToken

    return x
}

export const enhanceUserInformation = (userInformation: UserInformation, data: any) => {
    return userInformationFromProps({
        ...userInformation,
        ...data,
        userGroups: data.groups,
        jwtToken: userInformation.jwtToken
    })
}

export const refreshUser = (queryClient: QueryClient) => {
    console.log("Refreshing user object in 1 second")
    // TODO Curiously though - because this doesn't "unload" the current user immediately - pages can behave pretty weirdly
    // We can do this - but it's gonna take some work to smooth out the consequences
   //queryClient.setQueriesData(userInformationQueryKey(), userInformationSentinel)

    return new Promise<void>((resolve) => setTimeout(() => resolve(), 1000))
        .then(x => {
            queryClient.invalidateQueries({
                // I think we only have to do cognito session, which will then propagate to user session
                predicate: query => ["userSession"].includes((query.queryKey[0] as any).toString())
            })
        }).then(x => new Promise<void>((resolve) => {
            const lastUpdateCheckPoint = new Date().getTime()
                const interval =  setInterval(() => {
                    console.log("Waiting for user query", queryClient.getQueryState(userInformationQueryKey())?.dataUpdatedAt)
                    if (
                        ((queryClient.getQueryState(userInformationQueryKey())?.dataUpdatedAt || 0) > lastUpdateCheckPoint) ||
                        (new Date().getTime() - 15000) > lastUpdateCheckPoint
                    )
                    {
                        clearInterval(interval)
                        resolve()
                    }
                }, 1000)
            })
        )
}

export type signInResponse = {
    status: LoginState
    error?: string
}

export interface SessionPending {
    status: LoginState.InitialState
}

export interface SessionAvailable {
    status: LoginState.UserReady
    userInformation: UserInformation
}

export interface NoSession {
    status: LoginState.Unauthenticated
}

export type UserSessionServiceResponse = SessionPending | SessionAvailable | NoSession

export const useUserSessionService = () => {
    const {isDebug} = useAppContext()
    const setUserInformation = useSetAtom(userInformationAtom)

    const setLoginState = useSetAtom(loginStateAtom)
    const setLastUserUpdate = useSetAtom(lastUserUpdateAtom)

    const buildUserSessionQuery: UseQueryResult<UserSessionServiceResponse> = useQuery({
        queryFn: () => retrieveUserSession().then(response => {
                isDebug && console.log("Cognito response", response)
                switch (response.status) {
                    case CognitoResult.COGNITO_SUCCESS:
                        const userInfo = buildUserInformationFromCognito(response.session.session, response.session.attributes)
                        return api(API.currentUser(), userInfo, 0)
                            .then(response => enhanceUserInformation(userInfo, response.data))
                            .then(userInformation => {
                                console.log("Setting auth success")
                                setLoginState(LoginState.UserReady)
                                setUserInformation(userInformation)
                                setLastUserUpdate(new Date())
                                return {userInformation, status: LoginState.UserReady}
                            })
                    case CognitoResult.COGNITO_NO_USER:
                        setLoginState(LoginState.Unauthenticated)
                        return {userInformation: userInformationSentinel, status: LoginState.Unauthenticated}
                }
            }).catch(errResponse => {
                console.log("WEIRD Cognito failure", errResponse)
                setLoginState(LoginState.BadSystemState)
                return {userInformation: userInformationSentinel, status: LoginState.BadSystemState}
            }),
        queryKey: userInformationQueryKey(),
        staleTime: 300 * 1000, // Align this with the token refresh time
    })

    return buildUserSessionQuery
}

