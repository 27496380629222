import React, {FormEvent, useState} from "react"
import {Client, MaterialSlotDefinition} from "../../../client/model/Client";
import {LetterOfReference, LetterOfReferenceStatuses} from "../../model/LetterOfReference";
import useUserReadyService from "../../../user/lib/UserReadyService";
import {API, api} from "../../../lib/Api";
import {Box, Button, FormControl, Grid, TextField, Typography} from "@mui/material";
import {useStyles} from "../../Materials";
import SpinnerCard from "../../../components/SpinnerCard";
import {refreshUser, userInformationAtom} from "../../../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom} from "../../../Atoms";
import {UserInformation} from "../../../login/model/UserInformation";
import {useQueryClient} from "@tanstack/react-query";
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import {useModalService} from "../../../components/modal/ModalService";
import {useTranslation} from "react-i18next";

export const RequestLetterOfReference: React.FC<{ slotDefinition: MaterialSlotDefinition, previousDeclined?: LetterOfReference, editable: boolean}> =
    ({slotDefinition, previousDeclined, editable}) => {

    const userInformation: UserInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client

    const [referentEmail, setReferentEmail] = useState<string>("")
    const [referentPhone, setReferentPhone] = useState<string>("")
    const [referentName, setReferentName] = useState<string>("")
    const [emailNote, setEmailNote] = useState<string>("")
    const [creatingLetterOfReference, setCreatingLetterOfReference] = useState<boolean>(false)

    const localClientId = client.id

    const {classes} = useStyles()

    const queryClient = useQueryClient()
    const modal = useModalService()
    const {t} = useTranslation()

    const userReady = useUserReadyService()

    const createLetterOfReference = (lor: LetterOfReference) => {
        if (userReady && userInformation) {
            setCreatingLetterOfReference(true)
            api(API.createLetterOfReference(userInformation.guid), userInformation, localClientId, lor)
                .then(x => refreshUser(queryClient))
                .then(x => setCreatingLetterOfReference(false))
                .then(x => modal.i18nMessage("materials.modal.requestLetterOfReferenceTitle", "materials.modal.requestLetterOfReference"))
        }
    }

    const requestLetterOfReferenceSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        if (userInformation) {
            createLetterOfReference({
                id: "",
                slotId: slotDefinition.id,
                studentEmail: userInformation.email,
                documentId: undefined,
                referentEmail: referentEmail,
                referentName: referentName,
                referentPhone: referentPhone,
                referentTitle: undefined,
                emailNote: emailNote,
                state: LetterOfReferenceStatuses.requested,
                createdTimestamp: new Date(),
            })
        }
    }

    return creatingLetterOfReference ? <SpinnerCard message="Creating..."/> : <Box>
        {previousDeclined && <Box mt={1} className="stop">
            <Typography variant="inherit"><b>The previous request was declined by {previousDeclined.referentName} ({previousDeclined.referentEmail}), you may request a reference from an alternative individual.</b></Typography>
        </Box>}
        <Box mt={1}>
            <Typography variant="inherit">Enter the details for the individual who you would like to request a letter of reference from below, and click the "Create letter of Reference Request" button</Typography>
        </Box>
        <form onSubmit={(e) => requestLetterOfReferenceSubmit(e)}>
            <Grid container spacing={1} mt={1} columnSpacing={2}>
                <Grid item xs={12} md={4}>
                    <FormControl className={classes.formControlGridded}>
                        <TextField id={`letterOfReference-${slotDefinition.id}-referentEmail`}
                                   type="email"
                                   variant="outlined"
                                   aria-label="Referent Email"
                                   label="Referent Email"
                                   name={`letterOfReference-${slotDefinition.id}-referentEmail`}
                                   value={referentEmail}
                                   required={true}
                                   onChange={e => setReferentEmail(e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl className={classes.formControlGridded}>
                        <TextField id={`letterOfReference-${slotDefinition.id}-referentName`}
                                   type="text"
                                   variant="outlined"
                                   aria-label="Referent Name"
                                   label="Referent Name"
                                   name={`letterOfReference=${slotDefinition.id}-referentName`}
                                   value={referentName}
                                   required={true}
                                   onChange={e => setReferentName(e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={4}>
                    <FormControl className={classes.formControlGridded}>
                        <TextField id={`letterOfReference-${slotDefinition.id}-referentPhone`}
                                   type="text"
                                   variant="outlined"
                                   aria-label="Referent Phone"
                                   label="Referent Phone"
                                   name={`letterOfReference=${slotDefinition.id}-referentPhone`}
                                   value={referentPhone}
                                   required={true}
                                   onChange={e => setReferentPhone(e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12}>
                    <FormControl className={classes.formControl}>
                        <TextField id={`letterOfReference-${slotDefinition.id}-emailNote`}
                                   type="text"
                                   multiline={true}
                                   variant="outlined"
                                   aria-label="Email Note"
                                   label="Email Note"
                                   name={`letterOfReference=${slotDefinition.id}-emailNote`}
                                   value={emailNote}
                                   onChange={e => setEmailNote(e.target.value)}
                        />
                    </FormControl>
                </Grid>
                <Grid item xs={12} md={12}>
                    <Button variant="outlined" color="primary" type="submit" startIcon={<HistoryEduIcon/>}>{t("materials.buttons.requestLetterOfReference")}</Button>
                </Grid>
            </Grid>
        </form>
    </Box>
}
