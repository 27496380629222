import React, {useEffect, useState} from "react"
import {API, api, apiError} from "../../../lib/Api";
import {InternshipSiteId} from "../../../internship-site/model/Organization";
import {useAppContext} from "../../../lib/context";
import {List, ListItem, ListItemText, ListItemIcon} from "@mui/material";
import {School} from "../../../school/model/School";
import SchoolIcon from '@mui/icons-material/School';
import SpinnerCard from "../../../components/SpinnerCard";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../../Atoms";
import {clientAtom} from "../../../Atoms";

interface SchoolListItemProps {
    school: School
}

export const SchoolListItem = (props: SchoolListItemProps) => {
    return (
    <ListItem>
        <ListItemIcon>
            <SchoolIcon />
        </ListItemIcon>
        <ListItemText primary={props.school.name} />
    </ListItem>
    )
}

interface SiteRelationshipsProps {
    siteId: InternshipSiteId
}

export const SiteRelationships = (props: SiteRelationshipsProps) => {
    const {setModal} = useAppContext()

    const client = useAtomValue(clientAtom)
    const userInformation = useAtomValue(userInformationAtom)

    const localClientId = client?.id || 0

    const siteId = props.siteId

    const [loaded, setLoaded] = useState<boolean>(false)
    const [associations, setAssociations] = useState<School[]>([])

    useEffect(() => {
        if (localClientId) {
            api<School[], undefined>(API.siteSchoolRelationships(siteId), userInformation, localClientId)
                .then(response => {
                    setAssociations(response.data)
                    setLoaded(true)
                })
                .catch(error => apiError(error, setModal))
        }
    }, [localClientId, userInformation, siteId, setModal])

    return loaded ? (
            <List dense={false}>
                {associations.map(x =>
                    <SchoolListItem school={x}/>
                )}
            </List>
    ) : (<SpinnerCard message="Loading Schools..."/>)
}