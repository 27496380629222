import React, {FC, useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {useAppContext} from "../lib/context";
import {Page, PageBody, PageHeader} from "../components/Page";
import {Box, Button, SelectChangeEvent, Typography} from "@mui/material";
import {OrderActorSelector} from "./components/OrderActorSelector";
import {OfferSelectList} from "./components/OfferSelectList";
import {DollarDecimal} from "../product/lib/DollarDecimal";
import {ProductTypeSelector} from "./components/ProductTypeSelector";
import {SiteSelect} from "../internship-site/component/SiteSelect";
import {InternshipSite} from "../internship-site/model/Organization";
import useListOrganizationService from "../internship-site/lib/OrganizationService";
import {Offer, ProductType, productTypes} from "../product/model/Product"
import ProgramSelector from "../components/ProgramSelector";
import {formDataAsMap, formDataAsMapFromRef} from "../lib/Forms";
import {offerTypeGroupMapping, Order} from "./model/Order";
import {makeOrderEntries} from "./CreateOrder";
import {messageModal} from "../components/modal/InfoModal";
import {API, api} from "../lib/Api";
import {useNavigate} from "react-router-dom";
import {LoginStateCard} from "../components/LoginStateCard";
import SchoolSelector from "../components/SchoolSelector";
import useListProgramService from "../program/lib/ProgramService";
import {Program} from "../program/model/Program";
import useEligibleOfferService from "./lib/EligibleOfferService";
import {userInformationAtom} from "../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom, loginStateAtom} from "../Atoms";
import {useStyles} from "./styles/OrderStyles";
import {Client} from "../client/model/Client";

export interface AdminCreateOrderProps {

}


export const AdminCreateOrder: FC<AdminCreateOrderProps> = (props) => {
    const {isDebug, setModal} = useAppContext()
    const loginState = useAtomValue(loginStateAtom)
    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client

    const [productType, setProductType] = useState<ProductType>()
    const [orderActorId, setOrderActorId] = useState<string>()
    const [actorComponent, setActorComponent] = useState<React.ReactNode>()
    const [filteredOffers, setFilteredOffers] = useState<Offer[]>()
    const [order, setOrder] = useState<Order>()
    const [sites, setSites] = useState<InternshipSite[]>([])

    const localClientId = client?.id || 0

    const {classes} = useStyles()

    const organizationsService = useListOrganizationService({});

    const navigate = useNavigate()

    const formRef = useRef<HTMLFormElement>(null)

    const productTypeChangeHandler = (e: SelectChangeEvent<string>) => {
        setProductType(productTypes.find(x => x === e.target.value))
    }

    const programQuery = useListProgramService();

    const programs: Array<Program> = useMemo(() =>
            programQuery.isSuccess ? programQuery.data: [] as Array<Program>
        , [programQuery])

    const orderActorIdChangeHandler = useCallback((actorId: string) => {
        setOrderActorId(actorId)
    }, [setOrderActorId])

    const offerService = useEligibleOfferService()

    useEffect(() =>
            setSites(organizationsService.status === "loaded" ? organizationsService.payload.map(e => e.internshipSite).sort((a, b) => a.name.localeCompare(b.name)) : [])
        , [organizationsService])

    useEffect(() => {
        if (sites) {
            const productTypeToActorComponent: { [key in ProductType]: React.ReactNode } = {
                "OrganizationProduct": <SiteSelect sites={sites} setSiteId={e => orderActorIdChangeHandler(e.toString())} name="orderActorId"/>,
                "ProgramProduct": <ProgramSelector id="orderActorId" name="orderActorId" programs={programs} setProgramId={e => orderActorIdChangeHandler(e.toString())}/>,
                "StudentProduct": <></>,
                "ClientProduct": <input type="hidden" name="orderActorId" value={localClientId}/>,
                "SchoolProduct": <SchoolSelector id="orderActorId" name="orderActorId" setSchoolId={e => orderActorIdChangeHandler(e.toString())}></SchoolSelector>,
            }

            if (productType && offerService.status === 'loaded') {
                setActorComponent(productTypeToActorComponent[productType])
                setFilteredOffers(offerService.payload.filter(x => x.product.productType === productType))
            }
        }
    }, [productType, offerService, localClientId, sites, orderActorIdChangeHandler, programs])

    const setOfferQuantity = useCallback((offerId: string, quantity: number) => {
        const formData = formDataAsMapFromRef(formRef)
        const entries = offerService.status === "loaded" ? makeOrderEntries(offerService.payload, formData) : []
        const totalPrice = entries.map(x => x.unitPrice * x.quantity).reduce((a, b) => a + b, 0)

        isDebug && console.log("Form Entries", entries)

        if (userInformation) {
            setOrder({
                id: "",
                clientId: localClientId,
                clientCycleId: client?.settings.cycle.id || 0,
                orderEntries: entries,
                orderActorId: orderActorId || "",
                userEmail: userInformation?.email || "",
                totalPrice: totalPrice,
                createdTimestamp: new Date(),
                productType: entries.length ? entries[0].offer.product.productType : offerTypeGroupMapping[userInformation?.primaryGroup()]
            })
        }
    }, [isDebug, localClientId, offerService, userInformation, orderActorId, client])

    const makeOrder = (e: React.FormEvent) => {
        e.preventDefault()
        isDebug && console.log("Making order")
        if (offerService.status === "loaded" && userInformation) {

            const formData = formDataAsMap<{ [p: string]: any }>(e.target as HTMLFormElement)

            if (order?.orderEntries.length === 0) {
                setModal && setModal(messageModal("Order is Empty", "You have no items selected for your order. " +
                    "If you would like to purchase an item, please click the check box on the left side of the line item."))
            } else {
                isDebug && console.log("Making order entries")
                const entries = makeOrderEntries(offerService.payload, formData)
                isDebug && console.log("Making order", entries)
                const orderPayload: Order = {
                    id: "",
                    clientId: localClientId,
                    clientCycleId: client?.settings.cycle.id || 0,
                    orderEntries: entries,
                    orderActorId: formData.orderActorId,
                    userEmail: userInformation ? userInformation.email : "",
                    createdTimestamp: new Date(),
                    productType: entries.length ? entries[0].offer.product.productType : offerTypeGroupMapping[userInformation?.primaryGroup()]
                }

                // Make order on the backend here
                api<Order, Order>(API.addOrder(), userInformation, localClientId, orderPayload).then(result => {
                    // redirect to payment page with orderId
                    setModal && setModal(messageModal("Order Created", `A ${orderPayload.productType} order was created for ${orderPayload.orderActorId}`))
                    navigate(`/clientOrders`)
                })
            }
        }
    }

    const {useLoginStateCard, component} = LoginStateCard(loginState, client)
    if (useLoginStateCard) return component;

    return (
        <Page navName="adminCreateOrder" variant="half" footerPosition="fixed">
            <PageHeader title="Create Order">
            </PageHeader>
            <PageBody>
                <form onSubmit={makeOrder} ref={formRef}>
                    <ProductTypeSelector onChangeHandler={productTypeChangeHandler}/>
                    {actorComponent && actorComponent}
                    {(filteredOffers && filteredOffers.length > 0) && sites && <>
                        <Box width="100%">
                            <OrderActorSelector sites={sites} programs={programs}/>
                            <OfferSelectList setOfferQuantity={setOfferQuantity} offers={filteredOffers}/>
                        </Box>
                        <Typography variant="h5">Order Total: <DollarDecimal value={order?.totalPrice ? order?.totalPrice : 0}/></Typography>
                        <Button type="submit" variant="outlined" color="primary" className={classes.orderConfirmButton}>Confirm Order</Button>
                    </>
                    }
                </form>
            </PageBody>
        </Page>
    )
}
