import React, {useState} from 'react'
import {FormControl, InputLabel, Select, MenuItem, Theme, SelectChangeEvent} from "@mui/material";
import {bareElementTypes, elementTypeFromRow} from "../display/ElementType";
import {QuestionnaireElementProps} from "../../model/QuestionnaireList";
import {defaultQuestion, QuestionnaireElement} from "../../model/Questionnaire";
import {makeStyles} from "tss-react/mui";
import {useAtomValue} from "jotai";
import {clientAtom} from "../../../Atoms";

const useStyles = makeStyles()((theme: Theme) => ({
        formControl: {
            margin: theme.spacing(0),
            minWidth: 120,
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }));

export const ElementType = (props: QuestionnaireElementProps) => {
    const client = useAtomValue(clientAtom)
    const localClientId = client?.id || 0

    const [elementType, setElementType] = useState<string>(elementTypeFromRow(props.row));

    const {classes} = useStyles()

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const key = event.target.value as string
        setElementType(key)
        const bareType = bareElementTypes.find(x => x.name === key)

        const localQuestion = (elem: QuestionnaireElement) => {
            if (elem.elementType.id !== 1) {
                return undefined
            }
            else {
                if (bareType?.questionTypeId) {
                    if (elem.question) {
                        console.log("Settings type to ", elem.question.type.questionType)
                        return {
                            ...elem.question,
                            options: elem.question.type.questionType.includes("Select") ? elem.question.options : [],
                            type: {
                                id: bareType.questionTypeId || 0,
                                questionType: bareType.name,
                                questionTypeDescription: ""
                            }
                        }
                    }
                    else {
                        return defaultQuestion(localClientId, elem.questionnaireId)
                    }
                }
            }
        }

        props.setElement && props.setElement(elem => {
            return {
                ...elem,
                elementType: {
                    id: bareType?.elementTypeId || 0,
                    type: (bareType?.elementTypeName || "Question"),
                    description: ""
                },
                question: localQuestion(elem)
            } as QuestionnaireElement
        })
    }

    return (
        <FormControl variant="filled" className={classes.formControl} aria-label="Change Type">
            <InputLabel id={`elementType-label-${props.row.id}`}></InputLabel>
            <Select
                labelId={`elementType-label-${props.row.id}`}
                id={`elementType-${props.row.id}`}
                defaultValue={elementType}
                onChange={handleChange}
                label="Element Type"
                name="elementType"
            >
                {bareElementTypes.map( x => (
                    <MenuItem key={x.name} value={x.name}>{x.name}</MenuItem>
                    ))}
            </Select>
        </FormControl>
    )
}