import {useAppContext} from "../lib/context";
import React, {useState} from "react";
import {Box, Button} from "@mui/material";
import {api, API, apiError} from "../lib/Api";
import {messageModal, questionModal} from "../components/modal/InfoModal";
import EmailIcon from "@mui/icons-material/Email";
import Diversity2Icon from '@mui/icons-material/Diversity2';
import {CloudSync} from "@mui/icons-material";
import SpinnerCard from "../components/SpinnerCard";
import {
    EmptyObject,
    LoggedInComponent,
    LoggedInComponentWrapper,
    LoggedInContextProps,
    WrappedLoggedInContextProps
} from "../components/LoggedInPage";
import {DialogType} from "../components/modal/ModalProps";
import {clientAtom} from "../Atoms";
import {useAtomValue} from "jotai";
import {Client} from "../client/model/Client";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

export const AdminPageWrapper: LoggedInComponentWrapper = (context: WrappedLoggedInContextProps) => {
    if (context.state === "ready") {
        const props: LoggedInContextProps = context
        return <AdminPage {...props}/>
    }
    else {
        return <SpinnerCard message="Loading..."/>
    }
}

export const AdminPage: LoggedInComponent<EmptyObject> = (context: LoggedInContextProps) => {
    const {userInformation } = context
    const {isDebug, setModal} = useAppContext();
    const client = useAtomValue(clientAtom) as Client

    const [inProgress, setInProgress] = useState(false)

    const unsetAdmins = (e: React.MouseEvent<HTMLButtonElement>) => {
        setModal && setModal(questionModal(undefined, "Confirm", "Are you sure you want to remove ALL admins from the system?", DialogType.Confirm, (action: string, row: undefined) => {
            if (action === "confirm") {
                setInProgress(true)
                api<any, undefined>(API.unsetAdmins(), userInformation, client.id).then(() => {
                        setModal && setModal(messageModal("Global Admin", `unset all admin associations - hope you meant to do that`))
                        setInProgress(false)
                    }
                ).catch(err => apiError(err, setModal))
            }
        }))
    }

    const reconcileGroupsAndUsers = (e: React.MouseEvent<HTMLButtonElement>) => {
        setModal && setModal(questionModal(undefined, "Confirm", "Are you sure you want to re-associate all admins' group associations?",
            DialogType.Confirm,
            (action: string, row: undefined) => {
                setInProgress(true)
                if (action === "confirm") {
                    api<any, undefined>(API.reconcileGroupsAndUsers(), userInformation, client.id).then(() => {
                        setModal && setModal(messageModal("Global Admin", `Reset all group and user associations.`))
                        setInProgress(false)
                    }).catch(err => apiError(err, setModal))
                }
            }))
    }

    const republishOrgs = (e: React.MouseEvent<HTMLButtonElement>) => {
        setModal && setModal(questionModal(undefined, "Confirm", "Are you sure you want to re-publish all organizations to S3?",
            DialogType.Confirm,
            (action: string, row: undefined) => {
                isDebug && console.log("Setting in progress true")
                setInProgress(true)
                if (action === "confirm") {
                    api<any, undefined>(API.republishOrgs(), userInformation, client.id).then(() => {
                        setModal && setModal(messageModal("Global Admin", `Org republishing completed`))
                        setInProgress(false)
                    }).catch(err => apiError(err, setModal))
                }
            }))
    }

    const fixStudents = (e: React.MouseEvent<HTMLButtonElement>) => {
        setModal && setModal(questionModal(undefined, "Confirm", "Are you sure you want to filter student records to valid cycles?",
            DialogType.Confirm,
            (action: string, row: undefined) => {
                isDebug && console.log("Setting in progress true")
                setInProgress(true)
                if (action === "confirm") {
                    api<any, undefined>(API.fixStudents(), userInformation, client.id).then(() => {
                        setModal && setModal(messageModal("Global Admin", `Students update completed`))
                        setInProgress(false)
                    }).catch(err => apiError(err, setModal))
                }
            }))
    }


    return <Box m={2}>
        {inProgress ? <SpinnerCard message="Operation in progress..."/> : <>
            <Box>
                <Button startIcon={<EmailIcon/>} onClick={unsetAdmins}>Unset all admin associations</Button>
            </Box>
            <Box>
                <Button startIcon={<Diversity2Icon/>} onClick={reconcileGroupsAndUsers}>Re-associate groups for admins</Button>
            </Box>
            <Box>
                <Button startIcon={<CloudSync/>} onClick={republishOrgs}>Republish all organizations (global - does all clients)</Button>
            </Box>
            <Box>
                <Button startIcon={<ManageAccountsIcon/>} onClick={fixStudents}>Run Fix Students</Button>
            </Box>
        </>
        }
    </Box>

}

export default AdminPageWrapper

