import React, {FC} from "react"
import {OfferListElement} from "./OfferListElement";
import {Box, Grid, List, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText} from "@mui/material";
import {Offer} from "../../product/model/Product";
import {useStyles} from "../styles/OrderStyles";

interface OfferSelectListProps {
    setOfferQuantity: (id: string, quantity: number) => void
    offers: Offer[]
}

export const OfferSelectList: FC<OfferSelectListProps> = ({setOfferQuantity, offers}) => {

    const {classes} = useStyles()

    const isStudentOffer = (offers: Offer[]) =>
        offers.length === 1 && offers[0].fixedQuantity === 1 && offers[0].product.productType === "StudentProduct"

    return isStudentOffer(offers) ? <List className={classes.grower}>
            <ListItem className={classes.grower}>
                <ListItemIcon>
                </ListItemIcon>
                <ListItemText id={"0"} primary={
                    <Grid container spacing={{xs: 0, md: 1}}>
                        <Grid item xs={8} md={10} className={classes.orderHeading}>Product</Grid>
                        <Grid item xs={4} md={2} className={classes.orderHeading} >
                            <Box display="flex" justifyContent="flex-end">
                            Price
                            </Box>
                        </Grid>
                    </Grid>
                } className={classes.grower}/>
                <ListItemSecondaryAction>
                </ListItemSecondaryAction>
            </ListItem>
            {offers.map(offer => <OfferListElement setOfferQuantity={setOfferQuantity} readonly={false} offer={offer}/>)}
        </List> :
        <List className={classes.grower}>
                <ListItem className={classes.grower}>
                    <ListItemIcon>
                    </ListItemIcon>
                    <ListItemText id={"0"} primary={
                        <Grid container spacing={{xs: 0, md: 1}}>
                            <Grid item xs={3} md={2} className={classes.orderHeading}>Price</Grid>
                            <Grid item xs={1} md={2} className={classes.orderHeading}>Quantity</Grid>
                            <Grid item xs={1} md={1}></Grid>
                            <Grid item xs={4} md={6} className={classes.orderHeading}>Product</Grid>
                            <Grid item xs={3} md={1} className={classes.orderHeading}>Total</Grid>
                        </Grid>
                    } className={classes.grower}/>
                    <ListItemSecondaryAction>
                    </ListItemSecondaryAction>
                </ListItem>
                {offers.map(offer => <OfferListElement setOfferQuantity={setOfferQuantity} readonly={false} offer={offer}/>)}
            </List>
}