import {rowPredicate, SearchFilter} from "./CustomTable";
import {DataFilter, rowFilterPredicate} from "./Fitlering";
import {SortingConfig, useSortableData} from "./Sorting";

export function useDataView<T extends Object>(data: Array<T>, config: SortingConfig<T> | null = null , searchFilter?: SearchFilter, dataFilter?: DataFilter<T>) {
    return useSortableData(
        data
            .filter(row => rowPredicate(row, searchFilter))
            .filter(row => rowFilterPredicate(row, dataFilter)),
        config)
}
