import {useEffect, useState} from "react";
import User from "../model/User";
import {Service} from "../../lib/Service";
import {api, API} from "../../lib/Api";
import {userInformationAtom} from "../../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom} from "../../Atoms";

const useListUserService = (loadIndex: Date, groupFilter?: string) => {
    const client = useAtomValue(clientAtom)
    const userInformation = useAtomValue(userInformationAtom)

    const [result, setResult] = useState<Service<Array<User>>>({
        status: 'loading'
    });

    useEffect(() => {
        if (client?.id) {
            api<User[], undefined>(groupFilter ? API.listUserByGroup(groupFilter) : API.listUser(),
                userInformation, client.id)
                .then(response => setResult({status: 'loaded', payload: response.data}))
                .catch(error => setResult({status: 'error', error}));
        }
    }, [client, loadIndex, userInformation, groupFilter]);

    return result;
}

export default useListUserService;
