import React, {Dispatch, SetStateAction, useCallback, useEffect, useState} from "react";
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {InputSearchProps, SearchInputData, useStyles} from "../SearchComponent";

export const SelectSearch = (props: InputSearchProps) => {
    const [state, setState] = useState<string>(props.value || "");

    const selectSearch: string | undefined = props.value
    const parentCallBack: Dispatch<SetStateAction<SearchInputData>> =  props.setSearchInputData

    const {classes} = useStyles()

    const updateSearchInputData = useCallback((prev: SearchInputData, newValue: string) => {
        return {
            search: [...(prev.search.filter(x => x.key !== props.searchKey.name)), {
                key: props.searchKey.name,
                value: newValue
            }],
            query: prev.query,
            openingsOnly: prev.openingsOnly
        }}, [props.searchKey.name])

    useEffect(() => {
        if (selectSearch) {
            console.log("Effecting SelectSearch", selectSearch)
            parentCallBack(prev => selectSearch ? updateSearchInputData(prev, selectSearch) : prev)
            setState(selectSearch)
        }
    }, [selectSearch, parentCallBack, updateSearchInputData])

    const changeHandler = (e: SelectChangeEvent<unknown>) => {
        const newValue = e.target.value as string
        setState(newValue)
        props.setSearchInputData(prev => updateSearchInputData(prev, newValue))
    }

    return (
        <React.Fragment key={props.searchKey.name}>
            <input type="hidden" name="key" value={props.searchKey.name}/>
            <FormControl variant="filled" className={classes.formControl} size="small">
                <InputLabel id={`searchKey-${props.searchKey.name}`}>{props.searchKey.name}</InputLabel>
                <Select name="value"
                        variant="filled"
                        aria-label={`Search for ${props.searchKey.name} that contains`}
                        value={state}
                        onChange={changeHandler}>
                    {props.searchKey.question.options ? props.searchKey.question.options.map(e => (
                        <MenuItem key={e} value={e}>{e}</MenuItem>
                    )) : <MenuItem key="none" value={undefined} disabled={true}>[No Options Available]</MenuItem>}
                </Select>
            </FormControl>
        </React.Fragment>
    )
}