import {LetterOfReference} from "./LetterOfReference";
import {MaterialDocument} from "./Document";
import {QuestionnaireSubmissionId} from "../../questionnaire/model/QuestionnaireSubmissionElement";
import {SlotGroup, SlotId} from "../../client/model/Client";
import {InternshipSiteId, InternshipSiteTrackId} from "../../internship-site/model/Organization";
import {QuestionnaireSubmissionAction} from "../../questionnaire/model/Questionnaire";

export interface StudentMaterialsSlot {
    slot_element: MaterialDocument | LetterOfReference
}

export interface StudentMaterialsDocument {
    id: string
    documentType: SlotGroup
    document: MaterialDocument
    slotId: SlotId
    createdTimestamp: Date
}

export interface MaterialElement {
    position: number
    id: string
    documentType: SlotGroup
    materialSlotId: string
    createdTimestamp: Date
    storageRef: string
    document?: StudentMaterialsDocument
}

export interface StudentMaterials {
    materials: Array<MaterialElement>
    studentQuestionnaireSubmissionId: QuestionnaireSubmissionId
    lettersOfReference: Array<LetterOfReference>
    documents: Array<StudentMaterialsDocument>
    questionnaireComplete: boolean
    questionnaireSubmitted: boolean
    actions: QuestionnaireSubmissionAction[]
}

export interface PacketRef {
    id: string | undefined
    packetSlotId: SlotId
    documentId: string | undefined
    createdTimestamp: Date,
    storageRef: string | undefined
    siteId: number
    trackId: number | undefined | null
    submitted: boolean
}

export class PacketRefOps {
    static emptyPacketRef = (packetSlotId: string, siteId: InternshipSiteId, trackId: InternshipSiteTrackId | undefined): PacketRef => {return {
        id: "",
        packetSlotId: packetSlotId,
        createdTimestamp: new Date(),
        storageRef: undefined,
        documentId: undefined,
        siteId: siteId,
        trackId: trackId,
        submitted: false
    }}
}

export type StudentApplicationStatus = "Created" | "Submitted" | "Withdrawn" | "Archived"

export interface StudentApplication {
    packetRefs: PacketRef[]
    siteId: InternshipSiteId
    trackId?: InternshipSiteTrackId
    applicationStatus: StudentApplicationStatus
    adminApprovalQuestionnaireSubmissionId?: QuestionnaireSubmissionId
}

export interface StudentPacketApplications {
    applications: Array<StudentApplication>
    documents: Array<StudentMaterialsDocument>
}

export const emptyStudentMaterials: StudentMaterials = {
    materials: [],
    studentQuestionnaireSubmissionId: 0,
    lettersOfReference: [],
    documents: [],
    questionnaireComplete: false,
    questionnaireSubmitted: false,
    actions: []
}