import React from "react";

//const dateFormat = require("dateformat")
import dateFormat from "dateformat";

export const dateTimeFormat = "mmmm dS, yyyy, hh:MM TT"
export const dayFormat = "mm/dd/yyyy"

export const DateFormatted: React.FC<{date: Date, inDateFormat?: string}> = ({date, inDateFormat}) =>
    <>{dateFormat(date, inDateFormat || dateTimeFormat)}</>


export const yearFormatted = (date: Date) => dateFormat(date, "yyyy")

export const dayFormatted = (date: Date) => dateFormat(date, dayFormat)

export const dateTimeFormatted = (date: Date) => dateFormat(date, dateTimeFormat)
