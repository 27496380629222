import React, {useState} from "react"
import {ProgramSelect} from "../../program/component/ProgramSelect";
import {useAppContext} from "../../lib/context";
import {api, API} from "../../lib/Api";
import {confirmChangeModal, messageModal} from "../../components/modal/InfoModal";
import {IconButton} from "@mui/material";
import {ManageAccounts} from "@mui/icons-material";
import {Program} from "../../program/model/Program";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";
import {StudentUserInformation} from "../../login/model/UserInformation";
import {clientAtom} from "../../Atoms";
import {ModalAction} from "../../components/modal/ModalProps";

export const StudentListSchoolProgramComponent: React.FC<{user: StudentUserInformation, programs: Program[] | undefined}> = ({user, programs}) => {
    const {setModal, setPageIncrement} = useAppContext()

    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom)

    const localClientId = client?.id || 0

    type ComponentStateType = "view" | "edit" | "validating"

    const [componentState, setComponentState] = useState<ComponentStateType>("view")

    const doClearStudentTrackRanks: () => Promise<any> = () =>
        api<any, undefined>(API.clearStudentTrackRanks(user.guid), userInformation, localClientId)

    const doUpdateStudentProgram = (program: Program) =>
        api(API.updateStudentInfo(user.guid), userInformation, localClientId, {...user, students: {...user.student, programId: program.id}}).then(r => {
            setModal && setModal(messageModal("User Update", `Program for ${user.email} has been changed `))
            setComponentState("view")
            setPageIncrement && setPageIncrement(new Date())
        })

    const doProgramUpdate = (program: Program) => {
        setModal && setModal(confirmChangeModal("Student Update", (action) => {
            if (action === ModalAction.Confirm) {
                return doUpdateStudentProgram(program)
            }
            else {
                setComponentState("view")
            }
        }, `Change program for ${user.email} to ${program?.name}?`))
    }

    const requestStudentProgramUpdate = (programId: number) => {
        if (programs) {
            setComponentState("validating")
            const program = programs.find(p => p.id === programId)

            program && api(API.validateStudentTransfer(user.guid, programId), userInformation, localClientId).then(r => {
                if (r.data === "Valid") {
                    doProgramUpdate(program)
                } else {
                    setModal && setModal(confirmChangeModal("Clear Student Tracks", (action) => {
                            if (action === ModalAction.Confirm) {
                                doClearStudentTrackRanks().then(r => doUpdateStudentProgram(program))
                            }
                            setComponentState("view")
                        },
                        "This student has selected some tracks which are not approved for their new program. To transfer them cleanly, their track selections will be cleared. Please advise the student to re-create their track list following the transfer. Are you sure you want to do this?"
                    ))
                }
            })
        }
    }

    if (programs) {
        switch (componentState) {
            case "validating":
                return <>Validating...</>
            case "edit":
                return <ProgramSelect programId={user.student?.programId || 0} programs={(programs) || []} setProgramId={requestStudentProgramUpdate}/>
            default:
                return <>{user.student?.school?.name} - {user.student?.program?.name} <IconButton onClick={e => setComponentState("edit")}><ManageAccounts/></IconButton></>

        }
    }
    else return <>Loading...</>
}