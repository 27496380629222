import {useAppContext} from "../../lib/context";
import {useEffect, useState} from "react";
import {Service} from "../../lib/Service";
import {API, api} from "../../lib/Api";
import {Offer} from "../../product/model/Product";
import useUserReadyService from "../../user/lib/UserReadyService";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";
import {clientAtom} from "../../Atoms";

const useEligibleOfferService = (email?: string) => {
    const {isDebug} = useAppContext()
    const client = useAtomValue(clientAtom)
    const userInformation = useAtomValue(userInformationAtom)

    const clientId = client.id

    const [result, setResult] = useState<Service<Array<Offer>>>({
        status: 'loading'
    });

    const userReady = useUserReadyService()

    useEffect(() => {
        if (userReady && clientId) {
            isDebug && console.log("Loading eligible offers...");
            api<Offer[], undefined>(email ? API.listEligibleOffersForUser(email) : API.listEligibleOffers(), userInformation, clientId)
                .then(response => setResult({status: 'loaded', payload: response.data}))
                .catch(error => setResult({status: 'error', error}));
        }}, [userReady, userInformation, isDebug, clientId, email]);

    return result;
}

export default useEligibleOfferService
