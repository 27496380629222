import {useAppContext} from "../lib/context";
import {LoginStateCard} from "../components/LoginStateCard";
import {Page, PageBody} from "../components/Page";
import React from "react";
import {Box, Button, List, ListItem, ListItemIcon, ListItemText, Typography} from "@mui/material";
import {api, API, APIDefinition, apiError, getApiUrl} from "../lib/Api";
import {messageModal, questionModal} from "../components/modal/InfoModal";
import {DownloadsControl} from "../components/DownloadsControl";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import EmailIcon from "@mui/icons-material/Email";
import {ChangeHistory, CloudDownload, CloudUpload, Loop} from "@mui/icons-material";
import {requireOneOfGroup} from "../lib/util";
import Groups from "../model/Groups";
import {AuthenticationFailed} from "../components/AuthenticationFailed";
import useUserReadyService from "../user/lib/UserReadyService";
import {userInformationAtom} from "../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom, loginStateAtom} from "../Atoms";
import {DialogType, ModalAction} from "../components/modal/ModalProps";

interface EmailSendResult {
    email: string
    success: boolean
    errorMessage?: string
}

interface EmailResultListProps {
    emails: EmailSendResult[]
}

export const EmailResultList = (p: EmailResultListProps) => (
    <Box style={{maxHeight: 600, overflowY: "scroll"}}>
        <Typography variant="h6">Emails</Typography>
        <List>{p.emails.map((e: any) => (
            <ListItem dense>
                <ListItemIcon>{e.success ?
                    <CheckCircleOutlineIcon className="go"/> :
                    <HighlightOffIcon className="stop"/>
                }</ListItemIcon>
                <ListItemText primary={e.email} secondary={e.errorMessage || ""}/>
            </ListItem>
        ))}
        </List>
    </Box>
)

export const StageMatch = () => {
    const {setModal, isDebug} = useAppContext();
    const loginState = useAtomValue(loginStateAtom)
    const client = useAtomValue(clientAtom)
    const userInformation = useAtomValue(userInformationAtom)

    const localClientId = client?.id

    const userReady = useUserReadyService()

    const {useLoginStateCard, component} = LoginStateCard(loginState, client)

    if (userReady && !requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin])) {
        isDebug && console.log("Returning auth failed")
        return (<AuthenticationFailed/>)
    }

    isDebug && console.log("LSC", useLoginStateCard)

    const stageMatch = (e: React.MouseEvent<HTMLButtonElement>) => {
        api(API.runExports(), userInformation, localClientId).then(data =>
            setModal && setModal(messageModal("Exports run", "Exports Run"))
        ).catch(err => apiError(err, setModal))
    }

    const initiateMatch = (e: React.MouseEvent<HTMLButtonElement>) => {
        api(API.initiateMatch(), userInformation, localClientId).then(data =>
            setModal && setModal(messageModal("Match Run", "Match Run"))
        ).catch(err => apiError(err, setModal))
    }

    const loadResults = (e: React.MouseEvent<HTMLButtonElement>) => {
        api(API.loadResults(), userInformation, localClientId).then(data =>
            setModal && setModal(messageModal("Load Results", "Results Loaded"))
        ).catch(err => apiError(err, setModal))
    }

    const sendEmails = (endpoint: APIDefinition, name: string) => {
        setModal && setModal(
            questionModal(api, "Send Emails", "Do you want to send emails for " + name + "?", DialogType.Confirm,
                (action, e) => {
                    if (action === ModalAction.Confirm) {
                        api<{emails: EmailSendResult[]}, undefined>(endpoint, userInformation, localClientId).then(resp => {
                                isDebug && console.log("Email result", resp.data)
                                setModal && setModal({
                                    ...messageModal(name, "Emails Triggered"),
                                    component: <EmailResultList emails={resp.data.emails}/>
                                })
                            }
                        ).catch(err => apiError(err, setModal))
                    }
        }))
    }

    const sendROLStudentEmails = (e: React.MouseEvent<HTMLButtonElement>) => {
        sendEmails(API.sendROLStudentEmails(), "Student Rank Order Lists")
    }

    const sendROLSiteAdminEmails = (e: React.MouseEvent<HTMLButtonElement>) => {
        sendEmails(API.sendROLSiteAdminEmails(), "Site Admin Rank Order Lists")
    }

    const sendSiteAdminMatchEmails = (e: React.MouseEvent<HTMLButtonElement>) => {
        sendEmails(API.sendSiteAdminMatchEmails(), "Site Admin Matches")
    }

    const sendProgramAdminMatchEmails = (e: React.MouseEvent<HTMLButtonElement>) => {
        sendEmails(API.sendProgramAdminMatchEmails(), "Program Admin Matches")
    }

    const sendStudentMatchEmails = (e: React.MouseEvent<HTMLButtonElement>) => {
        sendEmails(API.sendStudentMatchEmails(), "Student Matches")
    }

    const updateClearingHouse = (e: React.MouseEvent<HTMLButtonElement>) => {
        api(API.updateClearingHouse(),userInformation, localClientId).then(() =>
            setModal && setModal(messageModal("Clearing House", `Updated openings for the ${client?.settings.clearingHouseLabel}`))
        ).catch(err => apiError(err, setModal))
    }

    return useLoginStateCard ? component : (
        <Page navName="Match" footerPosition="fixed">
            <PageBody>
                <Box m={2}>
                    <Typography variant="h5">Pre Match Actions</Typography>
                    <Box>
                        <Button startIcon={<EmailIcon/>} onClick={sendROLStudentEmails}>Send Student ROL Emails</Button>
                        <Button startIcon={<EmailIcon/>} onClick={sendROLSiteAdminEmails}>Send Site Admin ROL Emails</Button>
                    </Box>
                </Box>
                <Box m={2}>
                    <Typography variant="h5">Process Match</Typography>
                    <Button startIcon={<CloudUpload/>} onClick={stageMatch}>Stage Match</Button>
                    <Button startIcon={<Loop/>} onClick={initiateMatch}>Initiate Match</Button>
                    <Button startIcon={<CloudDownload/>} onClick={loadResults}>Load Results & Generate CSVs</Button>
                </Box>
                <Box m={2}>
                    <Typography variant="h5">Post Match Emails</Typography>
                    <Button startIcon={<EmailIcon/>} onClick={sendSiteAdminMatchEmails}>Send Site Admin Match Emails</Button>
                    <Button startIcon={<EmailIcon/>} onClick={sendProgramAdminMatchEmails}>Send Program Admin Match Emails</Button>
                    <Button startIcon={<EmailIcon/>} onClick={sendStudentMatchEmails}>Send Student Match Emails</Button>
                </Box>
                <Box m={2}>
                    <Typography variant="h5">Post Match Actions</Typography>
                    <Button startIcon={<ChangeHistory/>} onClick={updateClearingHouse}>Update Clearing House Openings</Button>
                </Box>
                <Box m={2}>
                    <DownloadsControl downloadUrl={`${getApiUrl()}export/file?clientId=${localClientId}&filename=Final_Pairing.csv`} label="Final Pairing.csv"/>
                    <DownloadsControl downloadUrl={`${getApiUrl()}export/file?clientId=${localClientId}&filename=clearing_house.csv`} label="clearing_house.csv"/>
                    <DownloadsControl downloadUrl={`${getApiUrl()}export/file?clientId=${localClientId}&filename=program_stats.csv`} label="program_stats.csv"/>
                    <DownloadsControl downloadUrl={`${getApiUrl()}export/file?clientId=${localClientId}&filename=student_match_positions.csv`} label="student_match_positions.csv"/>
                    <DownloadsControl downloadUrl={`${getApiUrl()}export/file?clientId=${localClientId}&filename=student_ranks.csv`} label="student_ranks.csv"/>
                    <DownloadsControl downloadUrl={`${getApiUrl()}export/file?clientId=${localClientId}&filename=student_results.csv`} label="student_results.csv"/>
                    <DownloadsControl downloadUrl={`${getApiUrl()}export/file?clientId=${localClientId}&filename=track_openings.csv`} label="track_openings.csv"/>
                    <DownloadsControl downloadUrl={`${getApiUrl()}export/file?clientId=${localClientId}&filename=track_ranks.csv`} label="track_ranks.csv"/>
                    <DownloadsControl downloadUrl={`${getApiUrl()}export/file?clientId=${localClientId}&filename=track_stats.csv`} label="track_stats.csv"/>
                </Box>
            </PageBody>
        </Page>
    )
}