import React, {useEffect, useState} from 'react';
import '../../Login.css';
import {apiError, api, API} from '../../lib/Api'
import {useAppContext} from "../../lib/context";
import { useNavigate } from 'react-router-dom';
import {Program} from "../../program/model/Program";
import {Address} from "../../components/model/Address";
import ProgramsTable from "./ProgramsTable";
import {School} from "../model/School";
import {historyPushModal} from "../../components/modal/InfoModal";
import {Box, Button, Checkbox, FormControlLabel, FormGroup, Grid} from "@mui/material";
import SpinnerCard from "../../components/SpinnerCard";
import TextField from "@mui/material/TextField";
import {formDataAsMap} from "../../lib/Forms";
import {isPresent} from "../../components/customTable/util";
import {PageBody, PageHeader} from "../../components/Page";
import {requireOneOfGroup} from "../../lib/util";
import Groups from "../../model/Groups";
import {makeStyles} from "tss-react/mui";
import {Theme} from "@mui/material/styles";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";
import {clientAtom} from "../../Atoms";

interface SchoolFormState {
    id?: number
    name: string
    address?: Address
    programs?: Program[]
    clientId: number
    schoolPaid: boolean
}

interface SchoolFormErrors {
    name?: string
    address?: {
        address1?: string
        address2?: string
        city?: string
        region?: string
        postalCode?: string
    },
    programs?: string
    paid?: boolean
}

interface SchoolFormProps {
    id?: string
}

const useStyles = makeStyles()((theme: Theme) => ({
        formControl: {
            minWidth: 120,
            width: "100%",
            marginTop: theme.spacing(1)
        }
    })
);


const SchoolForm = (props: SchoolFormProps) => {
    const navigate = useNavigate();
    const {modal, setModal, isDebug} = useAppContext();

    const client = useAtomValue(clientAtom)
    const userInformation = useAtomValue(userInformationAtom)

    const localClientId: number = client.id
    const localId = props.id ? parseInt(props.id) : 0;
    const emptySchool: School = {
        name: "",
        programs: [],
        clientId: localClientId,
        schoolPaid: false,
        address: {
            address1: undefined,
            address2: undefined,
            city: undefined,
            region: undefined,
            postalCode: undefined
        }
    }

    const [school, setSchool] = useState<SchoolFormState>(emptySchool);
    const [programs, setPrograms] = useState<Program[]>([])
    const [errors, setErrors] = useState<SchoolFormErrors>({});
    const [schoolPaid, setSchoolPaid] = useState<boolean>(false);

    useEffect(() => {
        if (localClientId > 0) api<School, undefined>(API.getSchool(localId), userInformation, localClientId)
            .then(e => {
                setSchool(e.data)
                setSchoolPaid(e.data.schoolPaid)
                isDebug && console.log(e);
            })
            .catch(error => console.log(error));
    }, [userInformation, localClientId, localId, isDebug])

    const validate = (data: SchoolFormState) => {
        const errs = {
            ...(isPresent(data, "name") ? {} : {name: "This field is required"})
        }
        setErrors(state => ({...state, ...errs}))
        return errs
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const data = formDataAsMap<SchoolFormState>(event.target as HTMLFormElement)
        const errs = validate(data)

        if (Object.entries(errs).length > 0) return;

        const localData = {...data, paid: schoolPaid, id: localId, clientId: localClientId, programs: programs}
        console.log("Saving school", localData)
        const p = localId === 0 ?
            api(API.addSchool(), userInformation, localClientId, localData)
            : api(API.updateSchool(localData.id), userInformation, localClientId, localData)

        p.then(r => {
            isDebug && console.log("Return is", r)
            isDebug && console.log(localData);
            setSchool({...data});
            isDebug && console.log(setModal);
            setModal && setModal(historyPushModal("Info", "School Saved Successfully", navigate,"/school"))
            console.log(modal)
        })
          .catch(x => {
              console.log("Failed to make call to add school, got ", x)
              apiError(x, setModal)
          })

    }

    const {classes} = useStyles()

    return (
        <>
        <PageHeader title="School Info"/>
        <PageBody>
                {(localId === 0 || isPresent(school, "name")) ?
                <form onSubmit={handleSubmit} data-testid="school-form">
                    <FormGroup className={classes.formControl}>
                        <TextField
                            id="schoolName"
                            label="Name"
                            defaultValue={school?.name}
                            helperText={`School's Name. ${errors?.name ? errors.name : ""}`}
                            variant="filled"
                            name="name"
                            required={true}
                            error={!!errors.name}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formControl}>
                        <ProgramsTable row={school} setPrograms={setPrograms}/>
                    </FormGroup>
                    <FormGroup className={classes.formControl}>
                        <TextField
                            id="schoolAddress1"
                            label="Address"
                            defaultValue={school?.address?.address1}
                            helperText={`School's Address. ${errors?.address?.address1 ? errors.address?.address1: ""}`}
                            variant="filled"
                            name="address.address1"
                            error={!!errors.address?.address1}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formControl}>
                        <TextField
                            id="schoolAddress2"
                            label="Suite / Unit"
                            defaultValue={school?.address?.address2}
                            helperText={`School's Suite / Unit. ${errors?.address?.address2 ? errors.address?.address2: ""}`}
                            variant="filled"
                            name="address.address2"
                            error={!!errors.address?.address2}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formControl}>
                        <TextField
                            id="schoolCity"
                            label="City"
                            defaultValue={school?.address?.city}
                            helperText={`School's City. ${errors?.address?.city ? errors.address?.city : ""}`}
                            variant="filled"
                            name="address.city"
                            error={!!errors.address?.city}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formControl}>
                        <TextField
                            id="schoolRegion"
                            label="State"
                            defaultValue={school?.address?.region}
                            helperText={`School's City. ${errors?.address?.region ? errors.address?.region : ""}`}
                            variant="filled"
                            name="address.region"
                            error={!!errors.address?.region}
                        />
                    </FormGroup>
                    <FormGroup className={classes.formControl}>
                        <TextField
                            id="schoolPostalCode"
                            label="Zipcode"
                            defaultValue={school?.address?.postalCode}
                            helperText={`School's Zipcode. ${errors?.address?.postalCode ? errors.address?.postalCode: ""}`}
                            variant="filled"
                            name="address.postalCode"
                            error={!!errors.address?.postalCode}
                        />
                    </FormGroup>
                    {requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin, Groups.clientAdmin]) ?
                    <FormGroup className={classes.formControl}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={schoolPaid}
                                    onChange={e => setSchoolPaid(!schoolPaid)}
                                    name="paid"
                                    value="checked"
                                    color="primary"
                                />
                            }
                            label="School has paid for it's students directly"
                        />
                    </FormGroup>
                        : <input type="hidden" name="paid" value={schoolPaid ? "checked" : ""}/>
                    }
                    <Grid>
                        <Box component="span" mr={1}>
                            <Button type="submit" value="Submit">Submit</Button>
                        </Box>
                        <Box component="span" mr={1}>
                            <Button type="submit" value="Cancel" onClick={() => navigate("/school")}>Cancel</Button>
                        </Box>
                    </Grid>
                </form>
                    : <SpinnerCard message="Loading"/>}
        </PageBody>
            </>
    )
}

export default SchoolForm;
