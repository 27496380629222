import {Order} from "../model/Order";
import {sum} from "../../lib/util";

export type PaymentState = "Unpaid" | "Refunded" | "Paid" | "Partially Paid" | "Unknown"

export const paymentState = (order: Order): PaymentState | undefined => {
    if (order.payments) {
        const paymentTotal = sum(order.payments.map(x => x.amount))

        if (order.payments.length === 0) {
            return "Unpaid"
        } else if (paymentTotal === 0) {
            return "Refunded"
        } else if (paymentTotal > 0) {
            if (paymentTotal === order.totalPrice) {
                return "Paid"
            }
            else {
                return "Partially Paid"
            }
        }
    } else {
        return "Unpaid"
    }
}