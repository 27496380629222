import {
    QuestionnaireElement,
    QuestionnaireElementTypes,
    QuestionnaireTableElement
} from "../model/Questionnaire";
import {Either} from "../../lib/Either";
import {takeWhile} from "../../lib/arrayUtil";
import {appConfig} from "../../conf/conf";


export const isTableQuestion = (qe: QuestionnaireElement) => {
    return qe.question?.label.indexOf("[Table]") === 0
}

const validateTableElementsMatchColumnCount = (tableDef: QuestionnaireTableElement, tableElements: QuestionnaireElement[]) => {
    if (tableElements.length % (tableDef.parsedParameters.columnCount || 0) !== 0) {
        throw new Error(`Table column count of ${tableDef.parsedParameters?.columnCount} does not match element count of ${tableElements.length}`)
    }
}

/**
 * Assumes the input array starts with a table heading element
 */
export const takeQuestionnaireTableEntries = (l: QuestionnaireElement[]): [a: QuestionnaireTableElement, b: QuestionnaireElement[]] => {
    const taken = takeWhile(l.slice(1), isTableQuestion)

    console.log("Table Def", l[0])
    const tableDef = l[0] as QuestionnaireTableElement

    appConfig.isDebug && console.log("tableDef", tableDef)

    validateTableElementsMatchColumnCount(tableDef, taken)

    return [{
        ...tableDef,
        tableQuestions: taken
    }, l.slice(1 + taken.length)]
}

export const chompQuestionnaireEntries = (elems: QuestionnaireElement[]): Either<QuestionnaireElement, QuestionnaireTableElement>[] => {
    let ar = [...elems]
    const acum: Either<QuestionnaireElement, QuestionnaireTableElement>[] = []

    while (ar.length > 0) {
        if (ar[0].elementType.id === QuestionnaireElementTypes.TableHeading.id) {
            const [r, remaining] = takeQuestionnaireTableEntries(ar)
            acum.push(Either.right(r))
            ar = remaining
        }
        else {
            acum.push(Either.left(ar[0]))
            ar = ar.slice(1)
        }
    }

    return acum
}
