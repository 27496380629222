import React, {FC, useEffect, useRef, useState} from 'react'
import {useAppContext} from "../lib/context";
import {requireOneOfGroup} from "../lib/util";
import Groups from "../model/Groups";
import {AuthenticationFailed} from "../components/AuthenticationFailed";
import {Page, PageBody, PageHeader} from "../components/Page";
import {Button, FormControl, TextField} from "@mui/material";
import {Product, ProductId} from "./model/Product";
import {useNavigate, useParams} from "react-router-dom";
import {api, API} from "../lib/Api";
import SpinnerCard from "../components/SpinnerCard";
import {DateTimePicker} from "@mui/x-date-pickers";
import dayjs, {Dayjs} from "dayjs";
import {makeStyles} from "tss-react/mui";
import Box from "@mui/material/Box";
import {formDataAsMapFromRef} from "../lib/Forms";
import {historyPushModal} from "../components/modal/InfoModal";
import useUserReadyService from "../user/lib/UserReadyService";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../Atoms";
import {UserInformation} from "../login/model/UserInformation";
import {clientAtom} from "../Atoms";
import CancelIcon from "@mui/icons-material/Cancel";
import {Client} from "../client/model/Client";

export interface OfferFormParams {
    productId: ProductId
}

export interface OfferFormProps {

}

export const OfferForm: FC<OfferFormProps> = (props) => {
    const {isDebug, setModal} = useAppContext()

    const userInformation: UserInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client

    const params = useParams()

    const [product, setProduct] = useState<Product>();
    const [validFrom, setValidFrom] = useState<Dayjs>(dayjs(new Date()));
    const [validTo, setValidTo] = useState<Dayjs>(dayjs(new Date()));

    const localClientId = client.id

    const formRef = useRef<HTMLFormElement>(null)

    const navigate = useNavigate()
    const userReady = useUserReadyService()

    useEffect(() => {
        if (params.productId) {
            api<Product, undefined>(API.fetchProduct(params.productId), userInformation, localClientId).then(res => {
                setProduct(res.data)
            })
        }
    }, [params.productId, userInformation, localClientId])

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (formRef.current && product) {
            const formData = formDataAsMapFromRef(formRef)
            api(API.addOffer(), userInformation, localClientId, {
                ...formData,
                clientCycleId: client.settings.cycle.id,
                validFrom: validFrom.toISOString(),
                validTo: validTo.toISOString(),
                clientId: parseFloat(formData.clientId),
                price: parseFloat(formData.price) * 100,
                fixedQuantity: (formData.fixedQuantity ? parseInt(formData.fixedQuantity): null),
                product: product
            }).then(res => {
                if (res.status === "success") {
                    setModal && setModal(historyPushModal("Created", "New Offer Created", navigate, `/offers/${product.id}`))
                }
            })
        }
    }

    const handleValidFromChange = (e: Dayjs | null) => {
        if (e) {
            setValidFrom(e)
        }
    }

    const handleValidToChange = (e: Dayjs | null) => {
        if (e) {
            setValidTo(e)
        }
    }

    const useStyles = makeStyles()((theme) => ({
        formControl: {
            margin: theme.spacing(1),
        }
    }));

    const {classes} = useStyles();

    if (userReady && !requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin, Groups.clientAdmin])) {
        isDebug && console.log("Returning auth failed")
        return (<AuthenticationFailed/>)
    }

    return (
        <Page navName="OfferForm">
            <PageHeader title="Create New Offer"/>
            <PageBody>
                {product ?
                    <form onSubmit={handleSubmit} ref={formRef}>
                        <input type="hidden" name="productId" value={product.id}/>
                        <input type="hidden" name="clientId" value={localClientId}/>
                        <FormControl fullWidth className={classes.formControl}>
                        <TextField variant="filled" type="text"
                                   placeholder="Name"
                                   defaultValue={product?.name}
                                   aria-readonly={true}
                                   name="name"
                                   label="Name"/>
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl}>
                            <TextField variant="filled" type="text"
                                       placeholder="Description"
                                       aria-readonly={true}
                                       name="description"
                                       label="Description"/>
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl}>
                        <TextField variant="filled" type="text"
                                   placeholder="price"
                                   name="price"
                                   label="Price"/>
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl}>
                        <TextField variant="filled" type="number"
                                   placeholder="Fixed quantity?"
                                   name="fixedQuantity"
                                   label="Fixed Quantity"/>
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl}>
                            <DateTimePicker
                                            label="Valid From"
                                            onChange={handleValidFromChange}
                                            slotProps={{
                                                textField: {
                                                    name: "validFrom",
                                                    id: "validFrom"
                                                }
                                            }}
                                            value={validFrom}/>
                        </FormControl>
                        <FormControl fullWidth className={classes.formControl}>
                        <DateTimePicker
                                    label="Valid To"
                                    onChange={handleValidToChange}
                                    slotProps={{
                                        textField: {
                                            name: "validTo",
                                            id: "validTo"
                                        }
                        }}
                                    value={validTo}/>
                        </FormControl>
                        <Box component="span" m={1}>
                            <Button variant="outlined" type="submit" value="Submit">Submit</Button>
                        </Box>
                        <Box component="span" m={1}>
                            <Button value="Cancel" onClick={() => navigate("/products")} startIcon={<CancelIcon/>}>Cancel</Button>
                        </Box>
                    </form>
                    : <SpinnerCard message="Loading Product..."/>
                }
            </PageBody>
        </Page>
    )
}