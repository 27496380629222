import React, {useCallback, useEffect, useState} from "react";
import {FormControl, TextField} from "@mui/material";
import {InputSearchProps, SearchInputData, useStyles} from "../SearchComponent";
import {useAppContext} from "../../../../lib/context";

export const TextInputSearch = (props: InputSearchProps) => {
    const {isDebug} = useAppContext()

    const [state, setState] = useState<string>(props.value || "");

    const {classes} = useStyles()

    const updateSearchInputData = useCallback((prev: SearchInputData, newValue: string) => ({
        search: [...(prev.search.filter(x => x.key !== props.searchKey.name)), {
            key: props.searchKey.name,
            value: newValue
        }],
        query: prev.query,
        openingsOnly: prev.openingsOnly
    }), [props.searchKey.name])

    useEffect(() => {
        isDebug && console.log("Effecting ", props.value)
        props.setSearchInputData(prev => props.value ? updateSearchInputData(prev, props.value) : prev)
    }, [props, updateSearchInputData, isDebug])


    const changeHandler = (e: React.ChangeEvent<{value: unknown}>) => {
        const newValue = e.target.value as string
        setState(newValue)
        props.setSearchInputData(prev => updateSearchInputData(prev, newValue))
    }

    return (
        <FormControl className={classes.formControl}>
            <input type="hidden" name="key" value={props.searchKey.name}/>
            <TextField variant="filled" type="text" id={`search-${props.searchKey.name}`}
                       aria-label={`Search for ${props.searchKey.name} that contains`}
                       placeholder="Search String"
                       value={state}
                       onChange={changeHandler}
                       name="value"
                       label={props.searchKey.name}
            />
        </FormControl>
    )
}