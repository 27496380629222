import React, {useEffect, useMemo, useState} from 'react'
import {Program, ProgramId} from "../../program/model/Program";

import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material"
import {requireOneOfGroup} from "../../lib/util";
import Groups from "../../model/Groups";
import {makeStyles} from "tss-react/mui";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";

interface ProgramSelectProps {
    programs: Array<Program>
    programId: ProgramId
    setProgramId: (programId: ProgramId) => void
    setLoading?: (loading: boolean) => void
    readonly?: boolean
}

const useStyles = makeStyles()((theme) => ({
    formControl: {
        minWidth: "100%",
        maxWidth: "100%",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const ProgramSelect = (props: ProgramSelectProps) => {
    const userInformation = useAtomValue(userInformationAtom)

    const [select, setSelect] = useState<ProgramId>(0)

    useEffect(() => {
        setSelect(props.programId)
    }, [props.programId])

    const {classes} = useStyles();

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        setSelect(event.target.value as ProgramId);
        props.setLoading && props.setLoading(true)
        props.setProgramId(event.target.value as ProgramId)
    };

    const sortedPrograms = useMemo(() =>props.programs.sort((x, y) => (x.school?.name + x.name).localeCompare(y.school?.name + x.name)), [props.programs])

    return (
        <FormControl variant="filled" className={classes.formControl}>
            <InputLabel id="user-programs-label">Programs</InputLabel>
            <Select name="programId"
                    value={select}
                    disabled={props.readonly!==undefined && props.readonly}
                    onChange={handleChange}>
                {requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin, Groups.clientAdmin]) &&
                    <MenuItem key={0} value={0}>-- All Programs --</MenuItem>
                }
                {sortedPrograms.map( e => (
                    <MenuItem key={e.id} value={e.id}>{e.school?.name} - {e.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}