import React, {useState} from "react"
import {Box, Grid} from "@mui/material";
import {Page, PageBody, PageHeader} from "../components/Page";
import {useEmailCompose} from "./components/EmailCompose";
import {useEmailPreview} from "./components/EmailPreview";
import SpinnerCard from "../components/SpinnerCard";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {EmailControlState} from "./model/Email";

export interface BulkEmailProps {
}

export const BulkEmail= (props: BulkEmailProps) => {
    const [mode, setMode] = useState<EmailControlState>("edit")

    const {preview, element} = useEmailCompose({setMode: setMode})
    const {emailResult, previewComponent} = useEmailPreview({emailTemplate: preview, setMode: setMode})

    const resultComponent = <Box>
        <Box p={1}>
            <b>Email Sent</b>
        </Box>

        <Grid container>
            <Grid item xs={12} sm={3}>Status</Grid>
            <Grid item xs={12} sm={3}>Message ID</Grid>
            <Grid item xs={12} sm={3}>From</Grid>
            <Grid item xs={12} sm={3}>To</Grid>
            {emailResult && emailResult.map(x => x.status === "success" ? (
            <React.Fragment>
                <Grid item xs={12} sm={3}>
                    <CheckCircleOutlineIcon className="go"/>
                    {x.status}
                </Grid>
                <Grid item xs={12} sm={3}>{x.value.messageId}</Grid>
                <Grid item xs={12} sm={3}>{x.value.from}</Grid>
                <Grid item xs={12} sm={3}>{x.value.to}</Grid>
            </React.Fragment>
        ) : (
            <React.Fragment>
                <Grid item xs={12} sm={3}>
                    <HighlightOffIcon className="stop"/>
                    {x.status}
                </Grid>
                <Grid item xs={12} sm={3}>{x.value.messageId}</Grid>
                <Grid item xs={12} sm={3}>{x.value.from}</Grid>
                <Grid item xs={12} sm={3}>{x.value.to}</Grid>
            </React.Fragment>
        ))}
        </Grid>
    </Box>

    const sendingComponent = <SpinnerCard message="Sending Email - Please Wait"/>

    const component = (mode: string) => {
        switch (mode) {
            case "edit":
                return element
            case "preview":
                return previewComponent
            case "sent":
                return resultComponent
            case "sending":
                return sendingComponent
        }
    }

    return (
        <Page navName="bulkEmail" variant={mode === "sent" ? "full" : "half"} footerPosition="fixed">
            <PageHeader title="Bulk Email Send"/>
            <PageBody>
                {component(mode)}
            </PageBody>
        </Page>
    )
}