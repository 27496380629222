import React from 'react'
import {QuestionnaireElement, QuestionnaireId} from "../../model/Questionnaire";
import {InternshipSiteId} from "../../../internship-site/model/Organization";
import {QuestionnaireAnswers} from "./QuestionnaireAnswers";
import {userInformationAtom} from "../../../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom} from "../../../Atoms";
import {Client} from "../../../client/model/Client";
import {QuestionnaireAggregate, useQuestionnaireAnswersService} from "../../QuestionnaireAnswersService";
import {UseQueryResult} from "@tanstack/react-query/src/types";

export interface QuestionnaireAnswersProps {
    questionnaireId: QuestionnaireId
    questionnaire: QuestionnaireElement[]
    siteId: InternshipSiteId
}

export const QuestionnaireAnswersView = (props: QuestionnaireAnswersProps) => {

    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client

    const qasQuery: UseQueryResult<QuestionnaireAggregate> = useQuestionnaireAnswersService(userInformation, client.id, props.questionnaireId, props.siteId, false)

    return qasQuery.isSuccess ? (<QuestionnaireAnswers answers={qasQuery.data.elements}/>) : <React.Fragment/>
}