import React, {useEffect, useState} from 'react'
import {Page, PageBody, PageHeader} from "../components/Page";
import {Client, ClientSettingsOps} from "../client/model/Client";
import {Box, Grid, Paper, Typography} from "@mui/material";
import useListOrganizationService from "../internship-site/lib/OrganizationService";
import tracksFromOrg, {TrackListRowData} from "../internship-site/lib/TracksForOrganization";
import SpinnerCard from "../components/SpinnerCard";
import {LoginStateCard} from "../components/LoginStateCard";
import {yearFormatted} from "../lib/DateFormats";
import useUserReadyService from "../user/lib/UserReadyService";
import {userInformationAtom} from "../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom, loginStateAtom} from "../Atoms";

const StudentResultDisplay = () => {
    const client = useAtomValue(clientAtom) as Client
    const userInformation  = useAtomValue(userInformationAtom)

    const [track,  setTrack] = useState<TrackListRowData>()

    const orgService = useListOrganizationService({})

    const userReady = useUserReadyService()

    useEffect(() => {
        if (userReady && orgService.status === "loaded" && userInformation?.student) {
            setTrack(
                orgService.payload.flatMap(x => tracksFromOrg(x))
                    .find(e => e.track.id === userInformation?.student?.matchedTrackId)
            )
        }
    }, [userReady, orgService, userInformation])

    return orgService.status === "loaded" ?
        <Box style={{height: 500}}>
            <Box m={2}>
                <Typography variant="h5">
                {client?.settings?.siteName} has posted the results of the {client?.settings?.clientName} electronic match for {yearFormatted(new Date())}.
                </Typography>
            </Box>
            <Box m={2}>
                <Typography variant="h6">
                Your match results are listed below:
                </Typography>
            </Box>
            {userInformation?.student?.matchedTrackId ? (<>
            <Box>
                <Typography variant="h5">Congratulations - You have a match!</Typography>
            </Box>
            <Grid container spacing={2}>
                <Grid xs={4} item>&nbsp;</Grid>
                <Grid xs={4} item>
                <Paper elevation={3}><Box p={2}>
                    <Typography><i>Internship Site & Track:</i></Typography>
                    <Typography style={{fontSize: 32}}>{track?.internshipSiteName}</Typography>
                    <Typography style={{fontSize: 26}}>{track?.track.name}</Typography>
                </Box></Paper>
                </Grid>
                <Grid xs={4} item>&nbsp;</Grid>
            </Grid>
            </>) :
                <Box>
                    <Box m={2}>
                    <Typography>We regret to inform you that you did not match to a position. </Typography>
                    </Box>
                    <Box m={2}>
                        <Typography>Training sites with open positions will be participating
                            in {client?.settings.clientName}'s {client?.settings.clearingHouseLabel} process.
                            A representative from your doctoral program should provide you with instructions for
                            how to notify them if you wish to apply to {client?.settings.clearingHouseLabel} sites.</Typography>
                    </Box>
                </Box>
            }
        </Box>
    : <SpinnerCard/>
}

export const StudentResult = () => {
    const client = useAtomValue(clientAtom) as Client
    const loginState = useAtomValue(loginStateAtom)

    const { useLoginStateCard, component } = LoginStateCard(loginState, client)

        return useLoginStateCard ? component : (
            <Page navName="Results" footerPosition="fixed">
                <PageHeader title="Results"/>
                <PageBody>
                    {client.settings.cycle.cycleEnd &&  <>
                        {ClientSettingsOps.matchDatePassed(client.settings) ?
                            <StudentResultDisplay/> :
                            <Typography>
                                PracticumFit has not yet posted the results of the {client?.name}
                                electronic match for
                                the {client?.settings.cycle.cycleEnd.getFullYear() - 1}-{client?.settings.cycle.cycleEnd.getFullYear()} academic
                                year
                            </Typography>
                        }
                    </>}
                </PageBody>
            </Page>
        )
}