import React, {useState} from 'react';
import {signOut as cognitoSignOut} from "../components/CognitoAuth";
import {useNavigate} from 'react-router-dom';

import {clearCookies, requireOneOfGroup} from "../lib/util";
import Groups from "../model/Groups";
import {UserInformation} from "../login/model/UserInformation";
import {AppBar, Box, Hidden, IconButton, Link, Toolbar} from "@mui/material";
import {Theme} from "@mui/material/styles";
import MenuIcon from '@mui/icons-material/Menu';
import {AdminMenu} from "./component/AdminMenu";
import {NavDrawer} from "./component/NavDrawer";
import {makeStyles} from "tss-react/mui";
import {userInformationAtom} from "../Atoms";
import {useAtomValue, useSetAtom} from "jotai";
import {clientAtom, flashingErrorMessageAtom, loginStateAtom} from "../Atoms";
import {useNavigationElements, NavName} from "./NavigationElements";
import {LoginState} from "../login/lib/UserSessionsService";
import {Client} from "../client/model/Client";
import {useTranslation} from "react-i18next";

interface NavProps {
    activeName: NavName
    supportsClientChange?: boolean
}

const useStyles = makeStyles()((theme: Theme) => ({
        root: {
            flexGrow: 1,
            backgroundColor: "none",
            "&.MuiPaper-root":{
                backgroundColor:"#0d47a1",
                color: "#fff"
            }
        },
        menuButton: {
            marginRight: theme.spacing(2),
        },
        toolbar: {
            overflowX: 'auto',
            backgroundColor: "rgba(0,0,0,0.4);",
        },
        toolbarSecondary: {
            justifyContent: 'space-between',
            overflowX: 'auto',
        },
        brandLink: {
            color: "#ffffff",
            '&:visited': {
                color: '#ffffff',
            },
            fontSize: "1.2rem",
            fontWeight: "bold",
            fontFamily: "'Helvetica Neue', Helvetica, Arial, sans-serif"
        },
        toolbarLink: {
            cursor: "pointer",
            color: "#ffffff",
            '&:visited': {
                color: '#ffffff',
            },
            '&:hover': {
                color: '#ffffff',
                textDecoration: 'underline'
            },
            padding: "0 10px;"
        },
        activeToolbarLink: {
            cursor: "pointer",
            color: "#999999",
            '&:visited': {
                color: '#ffffff',
            },
        },
        leftNav: {
            flexGrow: 1,
        },
        rightNav: {
        },
    }));

const InternfitNavbar: React.FC<NavProps> = ({activeName, supportsClientChange}) => {
    const client = useAtomValue(clientAtom) as Client
    const userInformation: UserInformation= useAtomValue(userInformationAtom)

    const setLoginState = useSetAtom(loginStateAtom)
    const setFlashingErrorMessage = useSetAtom(flashingErrorMessageAtom)

    const localClientId = client.id

    const {t} = useTranslation()
    const navigate = useNavigate()
    const navigationElements = useNavigationElements(client.settings, userInformation)

    const signOut = (e: React.MouseEvent) => {
        e.preventDefault();
        cognitoSignOut()
        localStorage.clear()
        clearCookies()
        //setUserInformation && setUserInformation(new AdminUserInformation({email:""}))
        setLoginState && setLoginState(LoginState.Unauthenticated)
        setFlashingErrorMessage && setFlashingErrorMessage(t("login.signedOutMessage"))

        navigate("/login")
    }

    // TODO read clientSettings dynamically for questionnaire text link on Nav (need to add clientSettings API call)

    const {classes} = useStyles();
    const [adminAnchorEl, setAdminAnchorEl] = useState<null | HTMLElement>(null);
    const [adminOpen, setAdminOpen] = useState<boolean>(false)
    const [drawerOpen, setDrawerOpen] = useState<boolean>(false)

    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAdminAnchorEl(event.currentTarget);
        setDrawerOpen(true);
        setAdminOpen(false)
    };

    const handleClose = () => {
        setAdminOpen(false)
        setAdminAnchorEl(null)
        setDrawerOpen(false)
    };

    const handleAdminMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAdminAnchorEl(event.currentTarget)
        setAdminOpen(true)
    }

    const renderDesktopToolbarLinks = () => ( <>
        {navigationElements
                .filter(e => e.requiredRoles.length === 0 || requireOneOfGroup(userInformation?.userGroups, e.requiredRoles))
                .map(e => (
                    <Link key={e.label} href={e.to} className={activeName === e.label ? classes.activeToolbarLink : classes.toolbarLink}>{e.label}</Link>
                ))}

        {requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin, Groups.clientAdmin]) &&
            <>
            <Link className={classes.toolbarLink} onClick={handleAdminMenuOpen}>Admin <i className="fa fa-angle-down"/></Link>
            </>
        }
            </>
    )

    return localClientId > 0 ? (
        <React.Fragment>
            <AppBar position="absolute" className={classes.root}>
                <Toolbar className={classes.toolbar}>
                    <IconButton
                        edge="start"
                        className={classes.menuButton}
                        color="inherit"
                        aria-label="open drawer"
                        onClick={handleMenu}
                        size="large">
                        <MenuIcon />
                    </IconButton>
                    <Link href="/home" style={{width: "140px"}} className={classes.brandLink}>{client?.settings.siteName}</Link>

                    <div className={classes.leftNav}>
                        <Hidden mdDown>
                            {renderDesktopToolbarLinks()}
                        </Hidden>
                    </div>

                    <div className={classes.rightNav}>
                        <Link className={classes.toolbarLink} onClick={signOut}>Sign Out</Link>
                    </div>

                    <Box className={classes.rightNav} ml={3} sx={{fontWeight: "bold", fontSize: "larger"}}>{client.name}</Box>

                </Toolbar>
            </AppBar>
            <AdminMenu anchorEl={adminAnchorEl} open={adminOpen} id="admin-menu" closeMenuHandler={handleClose}/>
            <NavDrawer toggleDrawerHandler={handleClose} open={drawerOpen} activeName={""}/>
        </React.Fragment>
    ): (<React.Fragment/>);
}

export default InternfitNavbar;


/*
<Nav className="justify-content-end">

    <span>{}</span>
    {requireGroup(userInformation?.userGroups, Groups.globalAdmin) ? (
            <NavDropdown title={client?.name} id="client-nav-dropdown">
                {clients && clients.map(client => (
                    <NavDropdown.Item key={client.id}
                                      onClick={onClickHandler(client)}>{client.name}</NavDropdown.Item>
                ))
                }
            </NavDropdown> ) :
        <Navbar.Text className="mr-auto">
            {client?.name}
        </Navbar.Text>
    }
    <Nav className="mr-auto">
        <Nav.Link key="SignOut" href={""} onClick={signOut}>Sign Out</Nav.Link>
    </Nav>
</Nav>


 */