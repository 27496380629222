import React from 'react';
import './User.css';
import UserForm from "./components/UserForm";
import UserList from "./components/UserList";
import {useParams} from "react-router-dom";

import {useAppContext} from "../lib/context"
import {requireOneOfGroup} from "../lib/util";
import {LoginStateCard} from "../components/LoginStateCard";
import {AuthenticationFailed} from "../components/AuthenticationFailed";
import Groups from "../model/Groups";
import {Page} from "../components/Page";
import useUserReadyService from "./lib/UserReadyService";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../Atoms";
import {clientAtom, loginStateAtom} from "../Atoms";

type StudentsParams = {
    id: string
}

const Students = () => {
    const params = useParams<StudentsParams>()
    const { isDebug } = useAppContext();

    const loginState = useAtomValue(loginStateAtom)
    const client = useAtomValue(clientAtom)
    const userInformation = useAtomValue(userInformationAtom)

    const userGroups = userInformation?.userGroups
    isDebug && console.log("Client: ", client, userInformation)

    const {useLoginStateCard, component} = LoginStateCard(loginState, client)

    const userReady = useUserReadyService()

    if (userReady && !requireOneOfGroup(userGroups, [Groups.globalAdmin, Groups.clientAdmin])) {
        isDebug && console.log("Returning auth failed")
        return (<AuthenticationFailed/>)
    }

    return useLoginStateCard ? component : (
        <Page navName="Users">
            {params.id ? (
                <div className="d-flex justify-content-center h-100">
                    <UserForm email={params.id}/>
                </div>
            ) : (<UserList/>)}
        </Page>
    )
}

export default Students
