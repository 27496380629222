import React from "react"
import {useAppContext} from "../lib/context";
import {userInformationAtom} from "../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom} from "../Atoms";
import {Client} from "../client/model/Client";
import {useTranslation} from "react-i18next";

type PageBlurbProps = {
    name: string
    roleSpecific?: boolean
}

export const PageBlurb = (props: PageBlurbProps) => {
    const {isDebug} = useAppContext()
    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client

    //isDebug && console.log("Seeking blurb", props.name, props.roleSpecific, client.settings.blurbs, userInformation.primaryGroup())
    const {t} = useTranslation()

    if (userInformation && client) {
        const key = (props.roleSpecific ? userInformation?.primaryGroup() + "." : "") + (props.name.split("/").join("."))

        isDebug && console.log("Blurb key", key)
        //isDebug && console.log("Blurb", client.settings.blurbs[key])

        //const date = (d: Date) => dateFormat(date, "mmmm dS, yyyy")

        const blurb = {"__html": t("blurbs."+key, client.settings)}

        //const blurb = {"__html": client.settings.blurbs[key] || ""}

        try {
            if (blurb) {
                const content = blurb //eval("`" + blurb + "`")

                return (
                    <div className="blurb" dangerouslySetInnerHTML={content}/>
                )
            } else return (<React.Fragment/>)
        } catch (e: any) {
            console.log(e)
            return <div className="blurb">{e.getMessage}</div>
        }
    }
    else {
        return <></>
    }
}