import {useAtomValue} from "jotai";
import {clientAtom, loginStateAtom} from "../../Atoms";
import {LoginState} from "../../login/lib/UserSessionsService";
import {Client} from "../../client/model/Client";

const useUserReadyService = () => {
    const loginState = useAtomValue(loginStateAtom)
    const client = useAtomValue(clientAtom) as Client

    // TODO somehow the way the SPA works, loginState is UserReady on a navigate() and yet the use session also isn't present. It's so weird.
    return loginState === LoginState.UserReady && !!client.settings.clientCycleSettings;
}

export default useUserReadyService