import {StudentUserInformation, UserInformation} from "../login/model/UserInformation";
import {StudentOps} from "./model/Student";
import {Box, Typography} from "@mui/material";
import React from "react";
import {useTranslation} from "react-i18next";
import {QuestionnaireSubmissionActionTypes} from "../questionnaire/model/Questionnaire";

export const QuestionnaireMessage: React.FC<{userInformation: UserInformation, i18nKey: string}> = ({userInformation, i18nKey}) => {
    const {t} = useTranslation()

    return <Box>
        <Typography className="stop">{t(i18nKey)}</Typography>
    </Box>
}

export const QuestionnaireNotCompleteMessage: React.FC<{userInformation: StudentUserInformation}> = ({ userInformation }) =>
    <QuestionnaireMessage userInformation={userInformation} i18nKey="applications.unsubmittedQuestionnaire"/>


export const QuestionnaireNotApprovedMessage: React.FC<{userInformation: StudentUserInformation}> = ({ userInformation }) =>
    <QuestionnaireMessage userInformation={userInformation} i18nKey="applications.incompleteApproval"/>

export const QuestionnaireRejectedMessage: React.FC<{userInformation: StudentUserInformation}> = ({ userInformation }) =>
    <QuestionnaireMessage userInformation={userInformation} i18nKey="applications.rejectedQuestionnaire"/>


export const QuestionnaireStatusMessage: React.FC<{userInformation: StudentUserInformation}> = ({ userInformation }) => {
    return StudentOps.lastQuestionnaireAction(userInformation).map(status => {
        console.log("Previous status is ", status)
        switch (status.actionType) {
            case QuestionnaireSubmissionActionTypes.submitted:
                return <QuestionnaireNotApprovedMessage userInformation={userInformation}/>
            case QuestionnaireSubmissionActionTypes.rejected:
                return <QuestionnaireRejectedMessage userInformation={userInformation}/>
            case QuestionnaireSubmissionActionTypes.approved:
                return <></>
            default:
                return <QuestionnaireNotCompleteMessage userInformation={userInformation}/>
        }
    }).getOrElse(<QuestionnaireNotCompleteMessage userInformation={userInformation}/>)

}