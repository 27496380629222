import React, {ChangeEvent, useState} from "react"
import {QuestionnaireElementProps} from "../../model/QuestionnaireList";
import {FormControl} from "@mui/material";
import TextField from "@mui/material/TextField";
import {Theme} from "@mui/material/styles";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
        formControl: {
            minWidth: 120,
            width: "100%"
        },
    }));

const ElementDefaultAnswer = (props:QuestionnaireElementProps) => {
    const [defaultAnswer, setDefaultAnswer] = useState(props.row.question?.defaultAnswer)

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const x = e.target.value
        setDefaultAnswer(x)

        props.setElement && props.setElement(elem => {
            return {
                ...elem,
                question: elem.question && {
                    ...elem.question,
                    defaultAnswer: x
                }
            }
        })
    }

    const {classes} = useStyles()

    return (
        <React.Fragment>
            <FormControl className={classes.formControl}>
                <TextField className={classes.formControl}
                           id="elementLabel"
                           variant="filled"
                           label="Question"
                           aria-label="Question Label"
                           value={defaultAnswer}
                           name="defaultAnswer"
                           size="small"
                           onChange={onChangeHandler}
                />
            </FormControl>
        </React.Fragment>
    )
}

export default ElementDefaultAnswer