import React, {CSSProperties} from "react"
import {Checkbox, ListItemText, MenuItem} from "@mui/material";
import {ellipsized} from "../lib/util";

interface MultiSelectItemProps {
    key: string
    name: string
    value: string
    style: CSSProperties
    checked: boolean
}

export const multiSelectItem = (props: MultiSelectItemProps) => (
    <MenuItem key={props.name} value={props.name} style={props.style}>
        <Checkbox checked={props.checked} />
        <ListItemText primary={ellipsized(props.name, 40)} />
    </MenuItem>
)