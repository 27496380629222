import React, {useEffect} from 'react';
import './App.scss';
import Home from "./Home";
import Login from "./login/Login";
import Students from "./student/Student";
import Users from "./user/User";
import Programs from "./program/Program";
import Schools from "./school/School";
import InternshipSites from "./internship-site/InternshipSites";
import Tracks from "./internship-site/Tracks";
import QuestionnaireEdit from "./questionnaire/QuestionnaireEdit";
import QuestionnaireSubmission from "./questionnaire/QuestionnaireSubmission";
import TrackProgramRelationships from "./internship-site/TrackProgramRelationships";
import ForgotPassword from "./login/ForgotPassword";
import {SiteProfile} from "./questionnaire/SiteProfile";
import {DirectorySearch} from "./questionnaire/DirectorySearch";
import {DirectoryEntry} from "./questionnaire/component/search/component/DirectoryEntry";
import ProgramOverview from "./program/ProgramOverview";
import Terms from "./Terms";
import Privacy from "./Privacy";
import {BulkEmail} from "./email/BulkEmail";
import Payment from "./order/Payment";
import TrackRank from "./student/TrackRank";
import Sites from "./student/Sites";
import StudentBulkAdd from "./student/StudentBulkAdd";
import StudentQuestionnaireSubmission from "./student/components/StudentQuestionnaireSubmission";
import {HowItWorks} from "./HowItWorks";
import {useAppContext} from "./lib/context";
import {isBlank, requireGroup} from "./lib/util";
import Groups, {allGroups} from "./model/Groups";
import Profile from "./profile/Profile";
import Attestation from "./profile/Attestation";
import Withdrawn from "./Withdrawn";
import ProgramForm from "./program/ProgramForm";
import StudentForm from "./student/StudentForm";
import {StageMatch} from "./match/StageMatch";
import ClientSettingsEdit from "./client/ClientSettingsEdit";
import {StudentResult} from "./student/StudentResult";
import MatchResults, {matchResultsTitle} from "./match/MatchResults";
import {ClientCycles} from "./client/ClientCycles";
import AdminPage from "./admin/AdminPage";
import {SignOut} from "./login/SignOut";
import {CreateOrder} from "./order/CreateOrder";
import {OrderList} from "./order/OrderList";
import {AdminCreateOrder} from "./order/AdminCreateOrder";
import Materials from "./application/Materials";
import Packet from "./application/packet/Packet";
import {LoggedInContextProps, LoggedInPage, StudentLoggedInPage} from "./components/LoggedInPage";
import {CapicEnd} from "./CapicEnd";
import {ProvideLetterOfReference} from "./application/lib/lettersOfReference/ProvideLetterOfReference";
import {clientsAtom, pageHeadingAtom, userInformationAtom} from "./Atoms";
import {useAtomValue, useSetAtom} from "jotai";
import ProgramAdminApprovalQuestionnaireSubmission from "./program/ProgramAdminApprovalQuestionnaireSubmission";
import {clientAtom, flashingErrorMessageAtom} from "./Atoms";
import {StudentUserInformation, UserInformation} from "./login/model/UserInformation";
import {ErrorFallback} from "./components/ErrorFallback";
import {useNavigate, useLocation, Route, Routes} from "react-router-dom";
import {LoginState, useUserSessionService} from "./login/lib/UserSessionsService";
import {appConfig} from "./conf/conf";
import {useQuery} from "@tanstack/react-query";
import {API, api} from "./lib/Api";
import {Client} from "./client/model/Client";
import SpinnerCard from "./components/SpinnerCard";
import {useApiWrapper} from "./lib/APIWrapper";
import {ProductList} from "./product/ProductList";
import Audit from "./audit/Audit";
import {OfferList} from "./product/OfferList";
import {OfferForm} from "./product/OfferForm";
import QuestionnaireReview from "./application/lib/QuestionnaireReview";
import {QuestionnaireReviewButtons} from "./application/lib/QuestionnaireReviewButtons";
import StudentApplicants, {StudentApplicantsNoParamsWrapper} from "./application/StudentApplicants";
import FinalizeQuestionnaireSubmission from "./application/FinalizeQuestionnaireSubmission";
import {useTranslation} from "react-i18next";
import {StudentOps} from "./student/model/Student";
import {StudentRankListWrapper} from "./internship-site/component/StudentRankList";

export const excludedPaths = ["/login", "/forgot-password", "/withdrawn", "/howItWorks", "/capicEnd", "/provideLetterOfReference", "/signout", "/globalError"]

const HardRoute: React.FC<{ href: string }> = ({href}) => {
    useEffect(() => {
        window.location.assign(href)
    }, [href])

    return (<></>)
}

const Routing = () => {
    const {isDebug, setDebug,} = useAppContext()

    isDebug && console.log("Routing...")

    const userInformation: UserInformation = useAtomValue(userInformationAtom)
    const setFlashingErrorMessage = useSetAtom(flashingErrorMessageAtom)
    const setPageHeading = useSetAtom(pageHeadingAtom)
    setPageHeading("")

    const setClients = useSetAtom(clientsAtom)
    const client = useAtomValue(clientAtom) as Client

    const navigate = useNavigate()
    const location = useLocation()

    //const {t} = useTranslation()

    const inExcludedPath = !!excludedPaths.find(x => location.pathname.indexOf(x) >= 0)
    isDebug && console.log("location exclude", location, inExcludedPath)

    const buildUserSessionQuery = useUserSessionService()
    useApiWrapper(buildUserSessionQuery, "User Session Service")

    useEffect(() => {
        const x = localStorage.getItem("isDebug")
        if (x !== null && x === "true") {
            setDebug && setDebug(true)
        } else {
            localStorage.setItem("isDebug", appConfig.isDebug ? "true" : "false")
        }
    }, [setDebug])

    useQuery({
        queryFn: () => api<Client[], undefined>(API.listClient(), userInformation, 0).then(response => {
            isDebug && console.log("Got clients", response.data)
            setClients(response.data as Client[])
            return response.data
        }).catch(err => {
            isDebug && console.log("Failed to list clients", err)
        }),
        queryKey: ["listClients"],
        staleTime: 86400000,
        enabled: !!(userInformation?.jwtToken && requireGroup(userInformation.userGroups, Groups.globalAdmin))
    })

    isDebug && console.log(location.pathname, buildUserSessionQuery.status, buildUserSessionQuery.data?.status)

    // Set global debug flag always true if you're a global admin
    requireGroup(userInformation.userGroups, Groups.globalAdmin) && localStorage.setItem("isDebug", "true")

    if (buildUserSessionQuery.isSuccess && !excludedPaths.find(x => location.pathname.indexOf(x) >= 0)) {
        isDebug && console.log("Student", userInformation?.student?.attestationTimestamp)
        isDebug && console.log("Routing: Client Settings", client.settings)

        const isPaywalledPage = location.pathname.startsWith("/applications") || location.pathname.startsWith("/trackRanks")
        const inPayment = location.pathname.startsWith("/payment") || location.pathname.startsWith("/order")

        if (requireGroup(userInformation?.userGroups, Groups.student) && client?.settings.cycle.id) {
            isDebug && console.log("Firing redirect checks", userInformation, client.settings)
            if (userInformation?.student?.clientCycleId !== client?.settings.cycle.id) {
                console.log("Warning - cycle is different", userInformation?.student?.clientCycleId, client?.settings.cycle.id)
                if (location.pathname !== "/login") {
                    setFlashingErrorMessage && setFlashingErrorMessage("Thank you for participating in a previous match, please await a new invite for this year's match before logging in.")
                    navigate(`/login`)
                }
            } else if (!userInformation?.firstName || isBlank(userInformation?.firstName)) {
                if (location.pathname !== "/userProfile") {
                    navigate(`/userProfile`)
                }
            } else if (!userInformation?.student?.attestationTimestamp) {
                if (location.pathname !== "/attestation") {
                    navigate("/attestation")
                }
            } else if (!(userInformation?.student?.paid || userInformation?.student?.school?.schoolPaid) && client.settings.paywallLocation === "signup"
                && !inPayment) {
                isDebug && console.log("Student is flagged unpaid", userInformation?.student?.paid, userInformation?.student?.school?.schoolPaid)
                navigate("/order")
            } else if (client.settings.paywallLocation === "application_submit" && !inPayment && isPaywalledPage) {
                if (requireGroup(userInformation?.userGroups, Groups.student) && !userInformation.student.paid &&
                    userInformation.student.materials.questionnaireSubmitted) {
                    navigate("/order")
                }
            } else if (!StudentOps.hasPaid(userInformation as StudentUserInformation) && StudentOps.studentApplicationsSubmittedCount(userInformation as StudentUserInformation) > 0) {
                // This case shouldn't exist, but, we have to handle it as we didn't gate the button correctly originally. It should now be gated, but this catches those who fell through the cracks.
                setFlashingErrorMessage("You have submitted your application(s) but have not paid your application fee. Please pay your application fee to complete your application(s).")
                navigate("/order")
            }
        }

    } else {
        isDebug && console.log("User not ready yet")
        isDebug && excludedPaths.find(x => location.pathname.indexOf(x) >= 0) &&
        isDebug && console.log("In an excluded path", location.pathname)
    }

    if (buildUserSessionQuery.isSuccess) {
        if (inExcludedPath) {
            return <RoutingRoutes/>
        }
        if (buildUserSessionQuery.data.status === LoginState.UserReady) {
            return <RoutingRoutes/>
        }
        if (buildUserSessionQuery.data.status === LoginState.Unauthenticated) {
            return <Login/>
        }
    }

    if (buildUserSessionQuery.isLoading) {
        return <SpinnerCard message="Authenticating"/>
    }

    if (buildUserSessionQuery.isError) {
        isDebug && console.log("Build use Session Failed", buildUserSessionQuery.failureReason)
        return <ErrorFallback/>
    }

    console.log("Falling back as DEFAULT!!!", buildUserSessionQuery.status)
    return <ErrorFallback/>

    // This is NOT in a useEffect block because we don't want reloads to happen after the page loads. Theses properties will
    // cause a rerender through context updates
}

const RoutingRoutes = () => {
    const {t} = useTranslation()

    const homeTitle = (e: LoggedInContextProps | undefined) => ({titleString: e?.client?.settings?.siteName ? t("home.welcome", {siteName: e?.client.settings.siteName}) : ""})

    return (
        <Routes>
            <Route path="/" element={<LoggedInPage Component={Home} title={homeTitle} navName="Home" requiredGroups={[]}/>}/>
            <Route path="globalError" element={<ErrorFallback/>}/>
            <Route path="home"
                   element={<LoggedInPage Component={Home} title={homeTitle} navName="Home" requiredGroups={[]}/>}/>
            <Route path="howItWorks" element={<HowItWorks/>}/>
            <Route path="about" element={<HowItWorks/>}/>
            <Route path="withdrawn" element={<StudentLoggedInPage Component={Withdrawn} title={() => ({titleString: "Withdrawn"})}
                                                                  navName="Withdrawn"
                                                                  requiredGroups={[Groups.student]}/>}/>,
            <Route path="terms" element={<Terms/>}/>
            <Route path="privacy" element={<Privacy/>}/>
            <Route path="capicEnd" element={<CapicEnd/>}/>
            <Route path="login" element={<Login/>}/>
            <Route path="forgot-password" element={<ForgotPassword/>}/>
            <Route path="signout" element={<SignOut/>}/>
            <Route path="logout" element={<SignOut/>}/>

            <Route path="student"
                   element={<LoggedInPage Component={Students} title={() => ({titleString: "Students"})} navName="Students"
                                          requiredGroups={[Groups.globalAdmin, Groups.clientAdmin, Groups.programAdmin]}/>}/>
            <Route path="student/:programId"
                   element={<LoggedInPage Component={Students} title={() => ({titleString: "Students"})} navName="Students"
                                          requiredGroups={[Groups.globalAdmin, Groups.clientAdmin, Groups.programAdmin]}/>}/>
            <Route path="studentForm/:id" element={<StudentForm/>}/>
            <Route path="students/bulk/:programId" element={<StudentBulkAdd/>}/>

            <Route path="studentResult" element={<StudentResult/>}/>
            <Route path="studentApplication"
                   element={<LoggedInPage Component={Materials} title={() => ({titleString: "Application Materials"})}
                                          navName="Application" requiredGroups={[Groups.student]}/>}/>
            <Route path="studentQuestionnaire" element={<LoggedInPage Component={StudentQuestionnaireSubmission}
                                                                      title={() => ({titleString: "Student Questionnaire"})}
                                                                      navName="StudentQuestionnaire"
                                                                      requiredGroups={[Groups.student]}/>}/>

            <Route path="provideLetterOfReference/:guid/:lorUUID" element={<ProvideLetterOfReference/>}/>

            <Route path="trackRanks" element={<StudentLoggedInPage Component={TrackRank} title={() => ({titleString: "Rank Tracks"})} variant="half"
                navName="TrackRanks" requiredGroups={[Groups.student]}/>}/>
            <Route path="applications"
                   element={<StudentLoggedInPage Component={Sites} title={() => ({titleString: t("applications.pageTitle")})} navName="Sites"
                                          requiredGroups={[Groups.student]}/>}/>
            <Route path="finalizeStudentQuestionnaire" element={<StudentLoggedInPage Component={FinalizeQuestionnaireSubmission} title={() => ({titleString: ""})} navName="None" requiredGroups={[Groups.student]}/>}/>
            <Route path="materials/questionnaireReview/:guid" element={<LoggedInPage Component={QuestionnaireReview}
                                                                                     title={() => ({titleString: "", titleChildren: <QuestionnaireReviewButtons/>})} navName={"application"}
                                                                                     scrollingBody={true}
                                                                                       requiredGroups={[Groups.programAdmin, Groups.clientAdmin, Groups.globalAdmin]}/>}/>
            <Route path="materials" element={<LoggedInPage Component={Materials} title={() => ({titleString: "Materials"})}
                                                           navName="Materials"
                                                           requiredGroups={[Groups.student]}/>}/>
            <Route path="program" element={<LoggedInPage Component={Programs} title={() => ({titleString: "Program Relationships"})}
                                                         navName="Programs"
                                                         requiredGroups={[Groups.siteAdmin, Groups.clientAdmin, Groups.globalAdmin]}/>}/>

            <Route path="program/:id" element={<LoggedInPage Component={Programs} title={() => ({titleString:"Program Relationships"})}
                                                             navName="Programs"
                                                             requiredGroups={[Groups.siteAdmin, Groups.clientAdmin, Groups.globalAdmin]}/>}/>
            <Route path="program/approval/:guid"
                   element={<LoggedInPage Component={ProgramAdminApprovalQuestionnaireSubmission}
                                          title={() => ({titleString: "Approve Student Application"})}
                                          requiredGroups={[Groups.globalAdmin, Groups.clientAdmin, Groups.programAdmin]}
                                          navName="Students"/>}/>

            <Route path="programForm/:id" element={<ProgramForm/>}/>
            <Route path="programOverview" element={<ProgramOverview/>}/>
            <Route path="school" element={<Schools/>}/>
            <Route path="school/:id" element={<Schools/>}/>
            <Route path="site" element={<InternshipSites/>}/>
            <Route path="site/:orgId/:siteId" element={<InternshipSites/>}/>
            <Route path="site/:orgId/:siteId/application" element={<StudentLoggedInPage Component={Packet} title={() => ({titleString: "Application Packet"})} navName="Packet" requiredGroups={[Groups.student]}/>}/>
            <Route path="site/track/:orgId/:siteId/:trackId/application" element={<StudentLoggedInPage Component={Packet} title={() => ({titleString: t("applications.packet.pageTitle")})} navName="Application" requiredGroups={[Groups.student]}/>}/>
            <Route path="siteStudentRank/:organizationId/:siteId" element={<LoggedInPage variant="half" Component={StudentRankListWrapper} title={() => ({titleString: "Track Rankings"})} navName="Sites" requiredGroups={[Groups.siteAdmin, Groups.clientAdmin, Groups.globalAdmin]}/>}/>
            <Route path="siteStudentRank/:organizationId/:siteId/:siteTrackId" element={<LoggedInPage variant="half" Component={StudentRankListWrapper} title={() => ({titleString: "Track Rankings"})} navName="Sites" requiredGroups={[Groups.siteAdmin, Groups.clientAdmin, Groups.globalAdmin]}/>}/>
            <Route path="trackPrograms" element={<TrackProgramRelationships/>}/>
            <Route path="trackPrograms/:siteTrackId" element={<TrackProgramRelationships/>}/>
            <Route path="applicants/:organizationId/:siteId/:trackId" element={<LoggedInPage Component={StudentApplicants} title={() => ({titleString: "Applicants"})} navName="Applicants" requiredGroups={[Groups.siteAdmin, Groups.clientAdmin, Groups.globalAdmin]}/>}/>
            <Route path="applicants" element={<LoggedInPage Component={StudentApplicantsNoParamsWrapper} title={() => ({titleString: "Applicants"})} navName="Applicants" requiredGroups={[Groups.siteAdmin, Groups.clientAdmin, Groups.globalAdmin]}/>}/>

            <Route path="siteProfile" element={<SiteProfile/>}/>
            <Route path="userProfile" element={<LoggedInPage
                Component={Profile}
                title={() => ({titleString: "User Profile"})}
                requiredGroups={allGroups}
                navName="User Profile"/>}/>

            <Route path="user" element={<Users/>}/>
            <Route path="/user/:id" element={<Users/>}/>

            <Route path="track" element={<Tracks/>}/>
            <Route path="track/:id" element={<Tracks/>}/>
            <Route path="track/:organizationId/:siteId/:id" element={<Tracks/>}/>

            <Route path="questionnaire/:id" element={<QuestionnaireEdit/>}/>
            <Route path="questionnaire/submission/:orgId/:id/:siteId" element={<LoggedInPage Component={QuestionnaireSubmission} title={() => ({titleString: "Questionnaire Submission"})} navName="Questionnaire Submission" requiredGroups={[Groups.globalAdmin, Groups.clientAdmin, Groups.siteAdmin]}/>}/>,

            <Route path="directory/entry/:orgId/:id/:siteId" element={<DirectoryEntry/>}/>
            <Route path="directory" element={<DirectorySearch/>}/>
            <Route path="results" element={<LoggedInPage Component={MatchResults} title={matchResultsTitle} navName="Results" requiredGroups={allGroups}/>}/>
            <Route path="bulkEmail" element={<BulkEmail/>}/>
            <Route path="audit" element={<Audit/>}/>
            <Route path="products" element={<ProductList/>}/>
            <Route path="offers" element={<OfferList/>}/>
            <Route path="offers/:productId" element={<OfferList/>}/>
            <Route path="offer/:productId" element={<OfferForm/>}/>

            <Route path="payment/:orderId" element={<LoggedInPage
                variant="half" Component={Payment}
                title={(ctx) => {
                    const blurbKey = ctx.userInformation?.primaryGroup() + "/payment_page_title"
                    return {titleString: ctx.client?.settings?.blurbs[blurbKey] || "Payment"}
                }}
                footerPosition="fixed"
                navName="Payment"
                requiredGroups={allGroups}/>}/>

            <Route path="order" element={<CreateOrder/>}/>
            <Route path="adminOrder" element={<AdminCreateOrder/>}/>
            <Route path="attestation" element={<LoggedInPage
                footerPosition="fixed"
                variant="half"
                Component={Attestation}
                title={() => ({titleString: "Attestation"})}
                navName="Attestation"
                requiredGroups={[Groups.student]}
            />}/>
            <Route path="clientOrders" element={<OrderList/>}/>
            <Route path="stageMatch" element={<StageMatch/>}/>
            <Route path="clientSettings" element={<LoggedInPage Component={ClientSettingsEdit} navName="ClientSettings" title={() => ({titleString: "Client Settings"})} requiredGroups={[Groups.globalAdmin]}/>}/>
            <Route path="clientCycles" element={<ClientCycles/>}/>
            <Route path="globalAdmin" element={<LoggedInPage Component={AdminPage} title={e => ({titleString: "Global Admin Functions"})} navName="Admin" requiredGroups={[Groups.globalAdmin]}/>}/>
            <Route path="help" element={<HardRoute href="https://practicumfit.zendesk.com"/>}/>
        </Routes>
    )
}

export default Routing