import React, {useEffect, useMemo, useState} from 'react'

import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material"
import {makeStyles} from "tss-react/mui";

export interface SelectItem {
    value: string
    label: string
}

interface ItemSelectProps {
    items: Array<SelectItem>
    item: SelectItem | undefined
    componentName: string
    componentId: string
    setItem: (x: SelectItem) => void
    readonly?: boolean
}

const useStyles = makeStyles()((theme) => ({
    formControl: {
        minWidth: "100%",
        maxWidth: "100%",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const ItemSelect = (props: ItemSelectProps) => {
    const [selectItem, setSelectItem] = useState<SelectItem>({value: "", label: ""})

    console.log("Items", props.items)

    useEffect(() => {
        props.item && setSelectItem(props.item)
    }, [props.item])

    const {classes} = useStyles();

    const handleChange = (event: SelectChangeEvent<SelectItem>) => {
        const item = props.items.find(x => x.value === event.target.value as string)
        console.log("Item picked", item)
        if (item) {
            setSelectItem(item)
            props.setItem(item)
        }
    };

    const sortedSelectedItems = useMemo(() => props.items.sort((x, y) => x.label.localeCompare(y.label)), [props.items])

    return (
        <FormControl variant="filled" className={classes.formControl}>
            <InputLabel id="{props.componentId}-label">{props.componentName}</InputLabel>
            <Select name={props.componentId}
                    value={selectItem}
                    disabled={props.readonly !== undefined && props.readonly}
                    onChange={handleChange}>
                <MenuItem key={0} value={0}>-- All --</MenuItem>
                {sortedSelectedItems.map(e => (
                    <MenuItem key={e.value} value={e.value}>{e.label}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}