import React from "react"

import {MaterialSlotDefinition} from "../../../client/model/Client";
import {Box, Button, Stack} from "@mui/material";
import {LetterOfReference, LetterOfReferenceStatuses} from "../../model/LetterOfReference";
import {API, api, apiError} from "../../../lib/Api";
import {useAppContext} from "../../../lib/context";
import {StudentLoggedInComponent} from "../../../components/LoggedInPage";
import {messageModal} from "../../../components/modal/InfoModal";
import {withConfirmModal} from "../../../components/modal/ModalHelpers";
import {refreshUser} from "../../../Atoms";
import {useQueryClient} from "@tanstack/react-query";
import {DatumDisplay} from "../../../components/DatumDisplay";
import BlockIcon from "@mui/icons-material/Block";
import EmailIcon from "@mui/icons-material/Email";


export const LetterOfReferenceView: StudentLoggedInComponent<{slotDefinition: MaterialSlotDefinition, letterOfReference: LetterOfReference, editable: boolean}> =
    ({userInformation, slotDefinition, letterOfReference, client}) => {

    const {setModal} = useAppContext()

    const queryClient = useQueryClient()

    const resendEmail = () => {
        if (letterOfReference.id && setModal) {
            withConfirmModal(setModal, true, "Are you sure you want to resend this request email?")(data =>
                    letterOfReference.id && api(API.resendLetterOfReferenceEmail(userInformation.guid, letterOfReference.id), userInformation, client.id).then(res => {
                        setModal(messageModal("Completed", "Letter of Reference Request Email Resent"))
                    }).catch(err => apiError(err, setModal))
            )
        }
    }

    const cancelLetterOfReference = () => {
        if (letterOfReference.id && setModal) {
            withConfirmModal(setModal, true, "Are you sure you want to cancel this request email?")(data =>
                    letterOfReference.id && api(API.cancelLetterOfReference(userInformation.guid, letterOfReference.id), userInformation, client.id).then(res => {
                        setModal(messageModal("Cancelled", "Letter of Reference Request is Cancelled"))
                    }).catch(err => apiError(err, setModal))
                    .then(x => refreshUser(queryClient))
            )
        }
    }

    return <Box m={0}>
        <DatumDisplay title="Status">{letterOfReference.state}</DatumDisplay>
        <DatumDisplay title="Email">{letterOfReference.referentEmail}</DatumDisplay>
        <DatumDisplay title="Name">{letterOfReference.referentName}</DatumDisplay>

        {(letterOfReference.state !== LetterOfReferenceStatuses.completed) &&
            <Stack direction="row" spacing={1}>
                <Button type="button" onClick={e => cancelLetterOfReference()} startIcon={<BlockIcon/>} variant="contained" color="secondary">Cancel</Button>
                <Button type="button" onClick={e => resendEmail()} startIcon={<EmailIcon/>} variant="contained">Resend Email</Button>
            </Stack>
        }
    </Box>

}
