import {EligibleStudent} from "../lib/EligibleStudentService";
import React, {useState} from "react";
import {Button, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import {green} from "@mui/material/colors";
import {useModalService} from "../../components/modal/ModalService";
import {useTranslation} from "react-i18next";


export const getOptionLabel = (option: any) => `${option.student.firstName ? option.student.firstName + " " + option.student.lastName + " - " : ""}${option.student.email}`

export const StudentSelect: React.FC<{
    students: EligibleStudent[],
    addStudent: (student: EligibleStudent) => void
}> = ({students , addStudent}) => {
    const [student, setStudent] = useState<EligibleStudent | null>(null)

    const modals = useModalService()

    const {t} = useTranslation()

    const onAdd = () => {
        console.log("Attempting to add", student)
        if (student) {
            addStudent(student)
        }
        else {
            modals.errorMessage("Error", "Please select a student to add")
        }
    }

    return <>
        <Select name='student'
                id='students-select'
                variant="filled"
                value={student?.student.email || ""}
                className="m-1"
                sx={{width: 600}}
                onChange={e => setStudent(students.find(x => x.student.email === e.target.value)!)}
        >
            {students.map(e => <MenuItem key={e.student.guid} value={e.student.email}>{getOptionLabel(e)}</MenuItem>)}
        </Select>
        <Button onClick={e => onAdd()} variant="outlined"
                style={student ? {backgroundColor: green[300], marginLeft: 5} : {marginLeft: 5}}>
            {t("studentRankList.buttons.addStudent")}
        </Button>
    </>
}