import React from "react";
import {School} from "../model/School";
import {useNavigate} from "react-router-dom";
import useListSchoolService from "../lib/SchoolService";
import SpinnerCard from "../../components/SpinnerCard";

import {Button, Box, Tooltip} from "@mui/material";
import {API, api, getApiUrl} from "../../lib/Api";
import {messageModal} from "../../components/modal/InfoModal";
import {useAppContext} from "../../lib/context";
import {DownloadsControl} from "../../components/DownloadsControl";
import {PageBody, PageHeader} from "../../components/Page";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import MaterialTable from "@material-table/core";
import ProgramsTable from "./ProgramsTable";
import {sum} from "../../lib/util";
import {TableColumn} from "../../components/customTable/CustomTable";
import {DollarDecimal} from "../../product/lib/DollarDecimal";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";
import {clientAtom} from "../../Atoms";

const SchoolList: React.FC = () => {
    const {setModal} = useAppContext()

    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom)

    const localClientId = client?.id || 0

    console.log("SchoolList")
    const schoolsService = useListSchoolService();
    const schools: Array<School> = schoolsService.status === 'loaded' ? schoolsService.payload : [] as Array<School>

    const navigate = useNavigate()

    const schoolColumns: TableColumn<School>[] = [
        {title: "Name", field: "name", comparator: (a: School, b: School) => a.name.localeCompare(b.name)},
        {title: "Payments", field: "",
            render: (row => (row.orders?.length || 0) > 0 ?  <Tooltip title={<>{row.orders?.filter(e => (e.payments?.length || 0) >  0).map(x =>
                <Box>{x.orderEntries.map(x => x.offer.name).join(", ")} : <DollarDecimal value={sum(x.payments?.map(x => x.amount)) || 0}/></Box>
            )}</>}>
                <Box>{sum((row.orders || []).map(e => e.payments ? e.payments.length : 0))}</Box>
            </Tooltip> : <Tooltip title="No Payments"><Box>0</Box></Tooltip>)
        },
        {title: "Address", field: "address", render: row => row.address?.address1},
        {title: "Invited Students", field: "", render: row => sum(row?.programs?.map(x => x.invitedStudents || 0))},
        {title: "Active Students", field: "", render: row => sum(row?.programs?.map(x => x.activeStudents || 0))},
        {title: "Withdrawn Students", field: "", render: row => sum(row?.programs?.map(x => x.withdrawnStudents || 0))}
    ];


    const confirmDelete = (row: School) => new Promise<void>((resolve, reject) => {
            row.id && api(API.deleteSchool(row.id), userInformation, localClientId).then(() => {
                schools.splice(schools.indexOf(row), 1)
                setModal && setModal(messageModal("Delete School", "School successfully deleted"))
                resolve()
            }).catch(e => reject())
        }
    )

    return (schoolsService.status !== "loaded") ? <SpinnerCard/> : (
        <>
            <PageHeader title="School List"><>
                <Box><Button color="primary" onClick={e => navigate("/school/0")} startIcon={<AddCircleIcon/>}>Create New School</Button></Box>
                <Box><DownloadsControl downloadUrl={getApiUrl() + API.listSchool("csv").location + "&clientId=" + localClientId}/></Box>
            </>
            </PageHeader>
            <PageBody>
                <MaterialTable columns={schoolColumns} data={schools}
                               detailPanel={row => <ProgramsTable row={row.rowData}/>}
                               options={{pageSize: 20}}
                               editable={{
                                   onRowDelete: confirmDelete
                               }}
                />
            </PageBody>
        </>
    )
}

export default SchoolList;




