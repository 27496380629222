import React, {useState} from 'react'

import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material"
import {InternshipSiteTrack, InternshipSiteTrackId, Organization} from "../model/Organization";
import {appConfig} from "../../conf/conf";
import {makeStyles} from "tss-react/mui";
import {Optional} from "../../lib/Optional";

interface SiteSelectProps {
    siteTracks: Array<InternshipSiteTrack>
    siteTrackId: InternshipSiteTrackId
    setSiteTrackId: (id: InternshipSiteTrackId) => void
    setSiteTrack?: (siteTrack: InternshipSiteTrack) => void
}

const useStyles = makeStyles()((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
        maxWidth: "100%"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const siteTracksFromOrganization = (o: Organization) => {
    return o.internshipSite.siteTracks
        .map(st => ({...st, siteName: o.internshipSite.name})).sort((a, b) => a.name.localeCompare(b.name)) as InternshipSiteTrack[]
}


export const SiteTrackSelect: React.FC<SiteSelectProps> = ({siteTracks, siteTrackId, setSiteTrackId, setSiteTrack}) => {
    const [select, setSelect] = useState<InternshipSiteTrackId>(siteTrackId)
    appConfig.isDebug && console.log("SiteTrackSelect", siteTrackId)

    /* This is a really bad idea as it will trigger a re-render in the parent which will then change the value of props */
    /*
    useEffect(() => {
        appConfig.isDebug && console.log("Effecting for siteTrackId", siteTrackId)
        if (siteTrackId) {
            setSelect(siteTrackId)
            setSiteTrackId(siteTrackId)
        }
    }, [siteTrackId, setSiteTrackId])

     */


    const {classes} = useStyles();

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const selectedId = event.target.value as InternshipSiteTrackId

        setSelect(selectedId)

        Optional.apply(siteTracks.find(x => x.id === selectedId))
            .forEach(x => {
                if (x.id) {
                    setSiteTrack && setSiteTrack(x)
                    setSiteTrackId(x.id)
                }
            })
    };

    return (
        <FormControl variant="filled" className={classes.formControl}>
            <InputLabel id="user-programs-label">Tracks</InputLabel>
            <Select name="siteTrackId"
                    value={select}
                    onChange={handleChange}>
                {siteTracks.map( e => (
                    <MenuItem key={e.id} value={e.id}>{e.siteName} - {e.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}