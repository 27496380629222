import React from 'react'
import {signOut as cognitoSignOut} from "../components/CognitoAuth";
import {useNavigate} from 'react-router-dom';
import {useSetAtom} from "jotai";
import {flashingErrorMessageAtom, loginStateAtom, userInformationAtom} from "../Atoms";
import {LoginState} from "./lib/UserSessionsService";
import {AdminUserInformation} from "./model/UserInformation";

export const SignOut: React.FC = () => {
    const setFlashingErrorMessage = useSetAtom(flashingErrorMessageAtom)
    const setLoginState = useSetAtom(loginStateAtom)
    const setUserInformation = useSetAtom(userInformationAtom)
    const navigate = useNavigate();

    cognitoSignOut()
    localStorage.clear()
    // TODO figure out how to clear the session on signout.
    setUserInformation(new AdminUserInformation({email: ""}))
    setLoginState(LoginState.Unauthenticated)
    setFlashingErrorMessage("You have been signed out")

    navigate("/login")

    return <></>
}