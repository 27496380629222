import React, {useState} from "react";
import {useNavigate} from "react-router-dom";

import User from "../model/User";
import {useAppContext} from "../../lib/context";
import useListUserService from "../lib/UserService";
import SpinnerCard from "../../components/SpinnerCard";
import {TableColumn} from "../../components/customTable/CustomTable";
import {API, api, getApiUrl} from "../../lib/Api";
import Groups from "../../model/Groups";
import {requireGroup} from "../../lib/util";
import MaterialTable, {MTableBodyRow} from "@material-table/core";
import {Box, Button} from "@mui/material";
import {DownloadsControl} from "../../components/DownloadsControl";
import {RichElement} from "../../components/customTable/RichElement";
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import {PageBody, PageHeader} from "../../components/Page";
import {useAtomValue, useSetAtom} from "jotai";
import {userInformationAtom} from "../../Atoms";
import {assumedUserEmailAtom, clientAtom} from "../../Atoms";
import {DialogType} from "../../components/modal/ModalProps";
import {UserInformation} from "../../login/model/UserInformation";

const UserList: React.FC = () => {
    const {isDebug, setModal} = useAppContext();

    const userInformation: UserInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom)
    const setAssumedUserEmail = useSetAtom(assumedUserEmailAtom)

    const localClientId = client?.id || 0

    console.log("Client ID is ", localClientId)

    const [render, setRender] = useState(new Date())

    const navigate = useNavigate()

    const userService = useListUserService(render)

    const userColumns: TableColumn<User>[] = [
        {title: "Email", field: "email"},
        {title: "First Name", field: "firstName"},
        {title: "Last Name", field: "lastName"},
    ].concat(
        requireGroup(userInformation?.userGroups, Groups.globalAdmin) ? [
            {
                title: "Last Activity",
                field: "lastActivityTimestamp",
                render: (row: User) => (<RichElement value={row.lastActivityTimestamp}/>)
            },
            {title: "Groups", field: "groups"}
        ] as Array<TableColumn<User>> : []
    ).concat(
        requireGroup(userInformation?.userGroups, Groups.clientAdmin) ? [
            {
                title: "Last Activity",
                field: "lastActivityTimestamp",
                render: (row: User) => (<RichElement value={row.lastActivityTimestamp}/>)
            },
            {title: "Groups", field: "groups"}
        ] as Array<TableColumn<User>> : []
    )

    const assumeUser = (email: string) => {
        if (requireGroup(userInformation?.userGroups, Groups.globalAdmin)) {
            setAssumedUserEmail && setAssumedUserEmail(email)
            localStorage.setItem("assumedUserEmail", email)
            navigate("/home")
        }
    }

    const users: Array<User> = userService && userService.status === 'loaded' ? userService.payload : [] as Array<User>
    const data = users.filter(x => !requireGroup(x.groups, Groups.directoryViewer))

    isDebug && console.log(users)

    const handleDelete = (user: User, resolve: (value?: any) => void, reject: (reason?: any) => void) => {

        user && (api(API.deleteUser(user.guid), userInformation, localClientId).then(x => {
            setModal && setModal({
                title: "Info",
                message: "User Removed Successfully",
                isOpen: true,
                onClose: () => {
                    resolve()
                },
                dialogType: DialogType.Message
            })
            setRender(new Date())
        }).catch((err) => reject(err)))
    }

    console.log("Requiring global admin", requireGroup(userInformation?.userGroups, Groups.globalAdmin),userInformation?.userGroups)

    return (userService && userService.status === "loading") ? <SpinnerCard/> : (
        <>
            <PageHeader title="User List">
                <>
                    <Box>
                        <Button startIcon={<PersonAddIcon/>} onClick={e => navigate(`/user/new`)}>Invite Administrator</Button>
                    </Box>
                    <Box>
                        <DownloadsControl
                            downloadUrl={getApiUrl() + API.listUser("csv").location + "&clientId=" + localClientId}/>
                    </Box>
                </>
            </PageHeader>
            <PageBody>
                <MaterialTable columns={userColumns} data={data} options={{paging: false}} title=""
                               components={{
                                   Row: props => <MTableBodyRow id={props.data.guid} {...props} />
                               }}
                               editable={{
                                   onRowDelete: (oldData: any) => new Promise((resolve, reject) => {
                                       handleDelete(oldData, resolve, reject)
                                   }),
                               }}
                               actions={[
                                   {
                                       icon: 'edit',
                                       tooltip: 'Edit User',
                                       onClick: (event: any, rowData: User | User[]) => {
                                           navigate("/User/" + (rowData as User).email)
                                       }
                                   }].concat(requireGroup(userInformation?.userGroups, Groups.globalAdmin) ? [{
                                    icon: 'social_distance',
                                       tooltip: 'Assume User',
                                       onClick: (event, rowData: User | User[]) => {
                                           assumeUser((rowData as User).email)
                                       }
                                   }] : [])
                               }
                />
            </PageBody>
        </>
    )
}

export default UserList