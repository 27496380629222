import {QuestionnaireElement, QuestionnaireId} from "./model/Questionnaire";
import {UserInformation} from "../login/model/UserInformation";
import {ClientId} from "../client/model/Client";
import {API, api} from "../lib/Api";
import {QuestionnaireSubmissionElement} from "./model/QuestionnaireSubmissionElement";
import {makeQuestionnaireElements} from "./lib/QuestionnaireElements";
import {useQuery} from "@tanstack/react-query";
import {InternshipSiteId} from "../internship-site/model/Organization";
import {Questionnaire} from "../model/Questionnaire";
import {UseQueryResult} from "@tanstack/react-query/src/types";
import {useAppContext} from "../lib/context";

export interface QuestionnaireAggregate {
    questionnaire: Questionnaire
    elements: QuestionnaireElement[]
}

export const useQuestionnaireAnswersService = (userInformation: UserInformation, clientId: ClientId, questionnaireId: QuestionnaireId, siteId: InternshipSiteId, fetchDraft: boolean): UseQueryResult<QuestionnaireAggregate> => {

    const {isDebug} = useAppContext()

    isDebug && console.log("In use Questionnaire Service", clientId, fetchDraft)

    const questionnaireQuery = useQuery({
        queryFn: () => api<Questionnaire, any>(API.fetchQuestionnaire(questionnaireId), userInformation, clientId)
            .then(e => {
                isDebug && console.log("Fetched questionnaire Data successfully")
                return e.data
            })
            .catch(err => {
                console.log("Questionnaire ID is incorrect - check system configuration for questionnaire ID",err)
                if (err.status === 404) {
                    return new Promise<Questionnaire>((resolve, reject) => {
                        reject("Failed - Questionnaire ID is incorrect")
                    })
                }
                else {
                    return new Promise<Questionnaire>((resolve, reject) => {
                        reject(err)
                    })
                }
            }),
        queryKey: [questionnaireId],
        staleTime: 86400000,
    })

    return useQuery({
        queryFn: () => api<QuestionnaireSubmissionElement[], undefined>(API.fetchQuestionnaireSubmission(questionnaireId, siteId, fetchDraft), userInformation, clientId)
            .then<QuestionnaireAggregate>(resp => new Promise<QuestionnaireAggregate>((resolve, reject) => {
                console.log("Fetching submission data from site", siteId)
                try {
                    if (questionnaireQuery.data)
                        resolve({
                            questionnaire: questionnaireQuery.data,
                            elements: makeQuestionnaireElements({}, questionnaireQuery.data, resp.data)
                        } as QuestionnaireAggregate)
                    else
                        reject("Passed invalid questionnaire data - this shouldn't be possible")
                }
                catch (e) {
                    isDebug && console.log("Exception", e)
                    reject("Failed to make questionnaire elements")
                }
            }))
            .catch(error => {
                console.log("API error", error)
                return new Promise<QuestionnaireAggregate>((resolve, reject) => {
                    if (error.status === 404) {
                        console.log("Got 404 for data")
                        if (questionnaireQuery.isSuccess) {
                            resolve({
                                questionnaire: questionnaireQuery.data,
                                elements: (questionnaireQuery.isSuccess ? makeQuestionnaireElements({}, questionnaireQuery.data, []) : [])
                            })
                        } else reject("Failed - this should never happen")
                    } else {
                        console.log("Failed to load questionnaire data for student ", error.status)
                        reject("Failed")
                    }
                })
            }),
        // fetchDraft is not part of this fetch key as either draft or non-draft are fulfilling the same role.
        queryKey: ["questionnaireSubmission", questionnaireId, siteId, fetchDraft],
        staleTime: 86400000,
        enabled: questionnaireQuery.isSuccess
    })
}