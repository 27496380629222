import MaterialTable from "@material-table/core";
import React, {Dispatch, SetStateAction, useCallback, useMemo} from "react";
import {Program} from "../../program/model/Program";
import {School} from "../model/School";
import {api, API, apiError} from '../../lib/Api'
import {useAppContext} from "../../lib/context";
import {TextField} from "@mui/material";
import {generatePassword, isBlank} from "../../lib/util";
import {RichElement} from "../../components/customTable/RichElement";
import {TableColumn} from "../../components/customTable/CustomTable";
import {EditTimestamp} from "../../client/component/EditTimestamp";
import {StandardValidators} from "../../lib/Forms";
import {userInformationAtom} from "../../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom} from "../../Atoms";

interface ProgramsTableProps {
    row: School
    setPrograms?: Dispatch<SetStateAction<Program[]>>
}

const ProgramsTable: React.FC<ProgramsTableProps> = (props) => {
    const {setModal, isDebug} = useAppContext();

    const client = useAtomValue(clientAtom)
    const userInformation = useAtomValue(userInformationAtom)

    const localClientId: number = client.id
    const schoolId = props.row.id || 0

    const randPass = generatePassword()

    const {row} = props;

    const nestedTableColumns = useMemo(() => [
        {title: 'Name', field: 'name'},
    ].concat(
        client?.settings.directory === "enabled" ? [
            {title: 'id', field: 'id', editable: "never"},
            {
                title: 'Directory Viewer Email',
                field: 'directoryViewer.email',
                validate: row => row.directoryViewer?.email ? StandardValidators.validateEmail(row.directoryViewer?.email) : true
            },
            {
                title: 'Directory Viewer Password', field: 'directoryViewer.directoryViewPassword',
                editComponent: (props: any) => (
                        <TextField value={props.value || "[Pending]"}
                                   onChange={e => props.onChange(e.target.value)}
                                   name="directoryViewPassword"
                                   aria-label="Password"
                                   disabled/>
                    )

            }, {title: 'Site List Lock Date', field: 'sitelistLockDate',
                render: row => row.sitelistLockDate ? <RichElement value={row.sitelistLockDate}/> : <span></span>,
                editComponent: (props: any) => (
                    <EditTimestamp name="sitelistLockDate" value={props.value} onChange={props.onChange}/>
                )
        }, {
            title: 'Program Admins', field: 'admins', editable: "never",
                render: row => row.admins?.map(x => <div>{x.email}</div>)
            }
        ] as TableColumn<Program>[]: []), [client?.settings.directory])


    console.log("Programs nested", row)

    const [state, setState] = React.useState<Program[]>(row.programs || [])

    const toSaveableProgram = useCallback((newData: Program) => ({
        ...newData,
        directoryViewer: newData.directoryViewer?.email ? {
            ...(newData.directoryViewer),
            directoryViewPassword: isBlank(newData.directoryViewer?.directoryViewPassword) ? randPass : newData.directoryViewer?.directoryViewPassword,
            programId: newData.id || 0
        } : null
    } as Program), [randPass])

    const handleAdd: (p: Program) => Promise<Program> = useCallback((newData: Program) =>
        new Promise<Program>((resolve, reject) => {
            console.log("New Program data", newData)
            if (schoolId > 0) {
                const p = api<Program, Program>(API.addProgram(), userInformation, localClientId, {
                    ...toSaveableProgram(newData), schoolId: schoolId, clientId: localClientId
                });
                p.then(result => {
                    setState((prevState) => [...prevState, result.data])
                    console.log("Added program");
                    resolve(result.data)
                }).catch(error => {
                    reject()
                    apiError(error, setModal)
                })
            } else {
                setState((prevState) => {
                    props.setPrograms && props.setPrograms([...prevState, toSaveableProgram(newData)] as Program[])
                    return [...prevState, toSaveableProgram(newData)]

                })
                resolve(newData)
            }
        }), [props, setState, userInformation, localClientId, setModal, schoolId, toSaveableProgram])

    const handleUpdate: (oldValue: Program, newValue: Program) => Promise<Program> = useMemo(() => (newData, oldData) => new Promise<Program>((resolve, reject) => {
        console.log("new data is", newData)
        if (schoolId > 0) {
            isDebug && console.log("handling update for school id", schoolId)
            if (oldData && oldData.id) {
                setState((prevState) => prevState.map(x => x === oldData ? toSaveableProgram(newData) : x))

                isDebug && console.log("Calling API to update program")
                api<Program, Program>(API.updateProgram(oldData.id, oldData.schoolId), userInformation, localClientId, toSaveableProgram(newData)).catch((x) => {
                    apiError(x, setModal)
                    reject()
                }).then((res) => {
                    isDebug && console.log("API success - resolving with", newData)
                    res ? resolve(res.data): reject()
                })
            }
        } else {
            setState((prevState) => {
                const data = [...prevState.map(x => x === oldData ? toSaveableProgram(newData) : x)]
                props.setPrograms && props.setPrograms(data)
                return data
            })
            resolve(newData)
        }
    }), [schoolId, setState, props, isDebug, localClientId, toSaveableProgram, setModal, userInformation])

    const handleDelete: (oldValue: Program) => Promise<void> = useMemo(() => (oldData: Program) => new Promise<void>((resolve, reject) => {
        if (schoolId > 0 && oldData.id) {
            api(API.deleteProgram(oldData.id), userInformation, localClientId)
                .then(result => {
                    isDebug && console.log("Deleted program")
                    setState((prevState) => prevState.filter(x => x.id !== oldData.id))
                    resolve()
                }).catch(error => {
                    apiError(error, setModal)
                    reject()
                })
        } else {
            setState((prevState) => {
                const data = prevState.filter(x => x !== oldData)
                props.setPrograms && props.setPrograms(prevState)
                return data
            })
            resolve()
        }
    }), [schoolId, isDebug, setState, userInformation, localClientId, setModal, props])

    return (
        <MaterialTable title="Manage Programs" columns={nestedTableColumns} data={state} key={row.id}
                       options={{
                           paging: false
                       }}
                       editable={{
                           onRowAdd: (newData: any) => handleAdd(newData as Program),
                           onRowUpdate: (newData: any, oldData: any) => {
                               console.log("onRowUpdate",newData)
                               return handleUpdate(newData as Program, oldData as Program)
                           },
                           onRowDelete: (oldData: any) => handleDelete(oldData as Program),
                       }}
        />
    )
}

export default ProgramsTable;