
/* This is SO dangerous - we need a way to exclude stuff. A lot of stuff */
import {appConfig} from "../../conf/conf";

export const evalInterpolate = (scopedValues: Record<string, any>, stringToInterpolate: string): string => {
    const evalPattern = /%\{([^}]+)}/g;

    const replacements = Array.from(stringToInterpolate.matchAll(evalPattern)).map(match => ({
        placeholder: match[0],
        expr: match[1]
    }))

    /* eslint-disable @typescript-eslint/no-unused-vars */
    const questionnaire = scopedValues.questionnaire
    /* eslint-disable @typescript-eslint/no-unused-vars */
    const student = scopedValues.student

    return replacements.reduce(
        (acc, { placeholder, expr}) => {
            appConfig.isDebug && console.log("Evaling", expr, questionnaire)
            /* eslint-disable no-eval */
            try {
                const r = eval(expr)
                appConfig.isDebug && console.log("Result was", r)

                return acc.replace(placeholder, r || "")
            }
            catch (e) {
                appConfig.isDebug && console.log("Eval Error", e)
                return acc.replace(placeholder, "")
            }
        },
        stringToInterpolate
    )
}

export const interpolate = (scopedValues: Record<string, any>, stringToInterpolate: string): string => {

    const pattern = /\$\{([^}]+)}/g;

    const replacements = Array.from(stringToInterpolate.matchAll(pattern)).map(match => ({
        placeholder: match[0],
        keyPath: match[1].split('.')
    }))

    //console.log("Replacements for interpole", scopedValues)

    const extractValueFromJson = (path: string[], json: Record<string, any>): string | undefined => {
        if (path.length === 0) return

        const [head, ...tail] = path

        if (tail.length === 0) {
            if (head in json) {
                return (json[head] || "").toString()
            }
            else {
                return ""
            }
        }
        else {
            if (head in json) {
                return extractValueFromJson(tail, json[head] as Record<string, any>)
            }
            else {
                return ""
            }
        }
    }

    return replacements.reduce(
        (acc, { placeholder, keyPath }) =>
            acc.replace(placeholder, extractValueFromJson(keyPath, scopedValues) || ""),
        stringToInterpolate
    )
}