import React from "react"
import {MaterialSlotDefinition} from "../../../client/model/Client";
import {StudentMaterialsDocument} from "../../model/StudentMaterials";
import {RequestLetterOfReference} from "./RequestLetterOfReference";
import {LetterOfReferenceView} from "./LetterOfReferenceView";
import {LetterOfReference} from "../../model/LetterOfReference";
import {LoggedInComponent} from "../../../components/LoggedInPage";
import {StudentUserInformation} from "../../../login/model/UserInformation";
import {I18Typography} from "../../../components/I18Typography";

export const LetterOfReferenceComponent: LoggedInComponent<{
    slotDefinition: MaterialSlotDefinition,
    userInformation: StudentUserInformation,
    editable: boolean}> = (props) => {
    const {slotDefinition, userInformation} = props

    const doc: StudentMaterialsDocument | undefined =
        userInformation && userInformation.student.materials.documents.find(x => x.slotId === slotDefinition.id)

    const letterOfReference: LetterOfReference | undefined =
        userInformation && userInformation.student.materials?.lettersOfReference.filter(x => x.slotId === slotDefinition.id)
            .find(x => x.state !== "Canceled")

    console.log("Letter of Ref doc", doc)
    console.log("Letter of Ref", letterOfReference)

    if (letterOfReference && letterOfReference.state !== "Canceled") {
        if (letterOfReference.state === "Declined") {
            return <RequestLetterOfReference slotDefinition={slotDefinition} previousDeclined={letterOfReference}
                                             editable={props.editable}/>
        } else {
            return <LetterOfReferenceView {...props} slotDefinition={slotDefinition}
                                          letterOfReference={letterOfReference}/>
        }
    } else {
        return props.editable ? <RequestLetterOfReference slotDefinition={slotDefinition} editable={props.editable}/> :
            <I18Typography msgId="materials.submissions.unusedLetter"/>
    }
}