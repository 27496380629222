import useOutstandingOfferService from "../lib/OutstandingOfferService";
import {Box, Button, darken, Theme} from "@mui/material";
import {requireGroup} from "../../lib/util";
import Groups from "../../model/Groups";
import ProductionQuantityLimitsIcon from "@mui/icons-material/ProductionQuantityLimits";
import SpinnerCard from "../../components/SpinnerCard";
import React from "react";
import {useAtomValue} from "jotai";
import {makeStyles} from "tss-react/mui";
import {red} from "@mui/material/colors";
import {userInformationAtom} from "../../Atoms";
import {useTranslation} from "react-i18next";

export const useStyles = makeStyles()((theme: Theme) =>
    ({
        largeIcon: {
            fontSize: "2.5rem"
        },
        outstandingOrder: {
            color: red.A700
        },
        errorButton: {
            backgroundColor: red.A700,
            "&:hover": {
                backgroundColor: darken(red.A400, 0.5)
            },
            "&:visited": {
                color: "#ffffff"
            }
        },

    })
)

export const OutstandingOffers = () => {
    const userInformation = useAtomValue(userInformationAtom)

    const offerService = useOutstandingOfferService()

    const {t} = useTranslation()

    const {classes} = useStyles()

        return <Box className={`${classes.outstandingOrder} mt-3`}>

        {!requireGroup(userInformation?.userGroups, Groups.student) && (offerService.status === "loaded" ? (offerService.payload.length > 0) ?
            <>
                <Button href={"order"} variant="contained" className={classes.errorButton} startIcon={<ProductionQuantityLimitsIcon/>} sx={{color: "#ffffff"}} >
                    {t("order.unpaidInvoice")}
                </Button>
            </> : <></> : <SpinnerCard/>
    )}</Box>
}