import React, {MouseEvent, useCallback, useState} from 'react'
import {useAppContext} from "../lib/context";
import {API, api} from "../lib/Api";
import {useNavigate, useParams} from "react-router-dom";
import {
    QuestionAnswerSave,
    QuestionnaireSubmissionElement
} from "./model/QuestionnaireSubmissionElement";
import SpinnerCard from "../components/SpinnerCard";
import {QuestionnaireAnswers} from "./component/submission/QuestionnaireAnswers";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import TabPanel from "../components/TabPanel";
import QuestionnaireDisplay from "./QuestionnaireDisplay";
import {messageModal, questionModal} from "../components/modal/InfoModal";
import {
    defaultOrganizationViewName,
    InternshipSite,
    InternshipSiteId,
    Organization
} from "../internship-site/model/Organization";
import {SiteSelect} from "../internship-site/component/SiteSelect";
import {requireGroup} from "../lib/util";
import Groups from "../model/Groups";
import {Box, Button} from "@mui/material";
import {useDebounce} from "../lib/useDebounce";
import {Page, PageBody, PageHeader} from "../components/Page";
import DialogActions from "@mui/material/DialogActions";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../Atoms";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {UserInformation} from "../login/model/UserInformation";
import {QuestionnaireAggregate, useQuestionnaireAnswersService} from "./QuestionnaireAnswersService";
import {clientAtom} from "../Atoms";
import {DialogActionProps, DialogType, ModalAction} from "../components/modal/ModalProps";
import {LoggedInComponentWrapper, WrappedLoggedInContextProps} from "../components/LoggedInPage";
import {Client} from "../client/model/Client";
import {refreshUser} from "../login/lib/UserSessionsService";

const YesNoDialogActions = (props: DialogActionProps) => (
    <DialogActions test-id="dialog-confirm-actions">
        <Button aria-label="close dialog" onClick={(e: MouseEvent<HTMLButtonElement>) => props.handleClose(e, ModalAction.Cancel)} color="primary" test-id="dialog-cancel-button">
            Review Later
        </Button>
        <Button onClick={(e: MouseEvent<HTMLButtonElement>) => props.handleClose(e, ModalAction.Confirm)} color="primary" autoFocus test-id="dialog-confirm-button">
            Review Now
        </Button>
    </DialogActions>
)

const QuestionnaireSubmissionWrapper: LoggedInComponentWrapper = (context: WrappedLoggedInContextProps) => {
    if (context.state === "ready") {
        return <QuestionnaireSubmission/>
    }
    else {
        return <SpinnerCard message="Loading..."/>
    }
}

const QuestionnaireSubmission = () => {
    const {setModal, isDebug} = useAppContext();

    const client = useAtomValue(clientAtom) as Client
    const userInformation: UserInformation = useAtomValue(userInformationAtom)

    const params = useParams()

    const [saving, setSaving] = useState<boolean>(false)
    const [siteId, setSiteId] = useState<InternshipSiteId>(parseInt(params.siteId || '0'))
    const [useDraft, setUseDraft] = useState<boolean>(false)
    const [draftAvailable, setDraftAvailable] = useState<boolean>(false)
    const [workingOnDraft, setWorkingOnDraft] = useState<boolean>(false)

    const [tabValue, setTabValue] = React.useState(0);

    const queryClient = useQueryClient()

    const navigate = useNavigate()

    const questionnaireId = parseInt(params.id || '0')

    const questionnaireQuery = useQuestionnaireAnswersService(userInformation, client.id, questionnaireId, siteId, useDraft || workingOnDraft)

    const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
        setTabValue(newValue);
    };

    const mutateQuestionnaireAnswers = useMutation({
        mutationFn: (props: {submitData: QuestionAnswerSave[], draft: boolean}) =>
            api(API.submitQuestionnaire(questionnaireId, siteId, props.draft), userInformation, client.id, props.submitData),
        mutationKey: ["submitQuestionnaire", questionnaireId, siteId, false],
    })

    const mutateQuestionnaireAnswersDraft = useMutation({
        mutationFn: (props: {submitData: QuestionAnswerSave[], draft: boolean}) =>
            api(API.submitQuestionnaire(questionnaireId, siteId, props.draft), userInformation, client.id, props.submitData),
        mutationKey: ["submitQuestionnaire", questionnaireId, siteId, true],
    })

    const debouncedSave = useDebounce<QuestionAnswerSave[]>(useCallback(q => {
        isDebug && console.log("Calling debounced save", mutateQuestionnaireAnswersDraft.status)
        // Why the truthy check here?
        q && mutateQuestionnaireAnswersDraft.mutate({submitData: q, draft: true})
    }, [isDebug, mutateQuestionnaireAnswersDraft]), 3000)

    const updateQuestionnaireFromDraft = useCallback((qas: QuestionAnswerSave[]) => {
        isDebug && console.log("Updating from draft", qas)
        if (questionnaireQuery.isSuccess) {
            console.log("Setting query data")
            const updatedQueryData = {
                    questionnaire: questionnaireQuery.data.questionnaire,
                    elements: questionnaireQuery.data?.elements.map(x => {
                        return {
                            ...x,
                            answer: {
                                ...x.answer,
                                answer: qas.find(e => e.questionId === x.question?.id)?.answer
                            }
                        } as QuestionnaireSubmissionElement
                    })
                }

            queryClient.setQueryData<QuestionnaireAggregate>(["questionnaireSubmission", questionnaireId, siteId, false],
                updatedQueryData)
            queryClient.setQueryData<QuestionnaireAggregate>(["questionnaireSubmission", questionnaireId, siteId, true],
                updatedQueryData)
        }
    }, [isDebug, questionnaireQuery, queryClient, questionnaireId, siteId])

    const onChange = useCallback((qas: QuestionAnswerSave[]) => {
        isDebug && console.log("onChange", qas)
        // As the form values update, so the state object gets updated, which is what this does
        // And then we can say we're working on a new draft
        updateQuestionnaireFromDraft(qas)
        setWorkingOnDraft(true)
        debouncedSave(qas);
    }, [debouncedSave, setWorkingOnDraft, isDebug, updateQuestionnaireFromDraft]);

    const handleSubmit = (qas: QuestionAnswerSave[], draft: boolean, force: boolean = false) => {
        setSaving(true)
        setWorkingOnDraft(false)
        setUseDraft(false)
        isDebug && console.log("Saving answers", mutateQuestionnaireAnswersDraft.status);
        (["success", "idle", "error"].includes(mutateQuestionnaireAnswersDraft.status) && !force) ? setTimeout(() => handleSubmit(qas, draft, true), 2000) :
            mutateQuestionnaireAnswers.mutate({submitData: qas, draft: false},
                {
                    onSettled: (data, error) => refreshUser(queryClient).then(e => {
                        isDebug && console.log("Saved", data)
                        isDebug && error && console.log("Saving error", error)
                        setModal && setModal(
                            questionModal(qas, "Questionnaire Submitted", "Your Answers have been saved. Please ensure that your track lists are up-to-date. Review tracks now?", DialogType.DeleteConfirm, (action: ModalAction, data: QuestionAnswerSave[]) => {
                                setSaving(false)
                                setTabValue(0)
                                console.log("Submitted action", action)
                                if (action === "confirm") {
                                    navigate("/track/" + siteId)
                                }
                            }, props => <YesNoDialogActions handleClose={props.handleClose}/>)
                        )
                    }),
                    onError: (e) => {
                        messageModal("Error", "There was an error saving your answers. Please try again later: " + (e as any).toString())
                    }
                })
    }

    const a11yProps = (index: any) => {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const organizationsQuery= useQuery({
            queryFn: () => api<Organization[], undefined>(API.listOrganizations(defaultOrganizationViewName), userInformation, client.id)
                    .then(response => response.data),
            queryKey: ["listOrganizations", client.id, defaultOrganizationViewName],
            staleTime: 86400000
        })

    const organizations: Array<Organization> = (organizationsQuery.isSuccess ? organizationsQuery.data: [] as Array<Organization>)
        .filter(x => {
            if (requireGroup(userInformation.userGroups, Groups.siteAdmin)) {
                return userInformation.sites?.includes(x.internshipSite?.id || 0)
            } else return true
        })

    const sites: InternshipSite[] = organizations.map(e => e.internshipSite).sort((a, b) => a.name.localeCompare(b.name))

    useQuery({
        queryFn: () => api<string, any>(API.isMostRecentADraft(questionnaireId, siteId), userInformation, client.id).then(e => {
                if (e.data === "yes") setDraftAvailable(true)
                else setDraftAvailable(false)
            return e.data
            }),
        queryKey: ["isMostRecentADraft", questionnaireId, siteId],
        staleTime: 86400000,
    })

    /*
    useEffect(() => {
        if (siteId !== paramSiteId)
            navigate(`/questionnaire/submission/${orgId}/${questionnaires[0].id}/${siteId}`)
    }, [siteId, navigate, orgId, questionnaires, paramSiteId])

     */

    const handleSiteIdChange = (siteId: InternshipSiteId) => {
        //setIsLoading(true)
        setSiteId(siteId)
        setTabValue(0)
    }

    const restoreFromDraft = () => {
        setDraftAvailable(false)
        setUseDraft(true)
        setWorkingOnDraft(true)
        // These seems silly, but, necessary?
        queryClient.invalidateQueries(["questionnaireSubmission", questionnaireId, siteId])
    }

    return questionnaireQuery.isSuccess ? (
        <Page navName="Questionnaires" supportsClientChange={false}>
            <PageHeader title="Directory Profile for" flexGrow={0}>
                <Box>
                    <SiteSelect sites={sites} siteId={siteId} setSiteId={handleSiteIdChange}/>
                </Box>
            </PageHeader>
            <PageBody>
                {(questionnaireQuery.isSuccess && !saving) ? (
                    <React.Fragment>
                        <AppBar position="static" style={{backgroundColor: "#ffffff", color: "#404040"}}>
                            <Tabs value={tabValue} onChange={handleTabChange} aria-label="simple tabs example">
                                <Tab label="View" {...a11yProps(0)} />
                                <Tab label="Edit" {...a11yProps(1)} />
                            </Tabs>
                        </AppBar>
                        <TabPanel value={tabValue} index={0}>
                            <QuestionnaireAnswers answers={questionnaireQuery.data.elements.filter(x => !!x.question) as QuestionnaireSubmissionElement[]}/>
                        </TabPanel>
                        <TabPanel value={tabValue} index={1}>
                            {(draftAvailable && !workingOnDraft) &&
                                <Button onClick={restoreFromDraft} color="secondary" variant="outlined">You have a previously saved draft, would you like to continue
                                    editing from that?</Button>
                            }

                            <QuestionnaireDisplay questionnaireElements={questionnaireQuery.data.elements}
                                                  handleSubmit={handleSubmit} onChange={onChange}
                                                  questionnaireId={questionnaireId}
                                                  key={siteId}/>
                        </TabPanel>
                    </React.Fragment>
                ) : <SpinnerCard/>}
            </PageBody>
        </Page>
    ) : <SpinnerCard message="Loading data..."/>
}

export default QuestionnaireSubmissionWrapper