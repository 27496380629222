import React, {useCallback, useEffect, useMemo, useRef, useState} from "react"
import {Page, PageBody, PageHeader} from "../components/Page";
import {useAppContext} from "../lib/context";
import {OfferSelectList} from "./components/OfferSelectList";
import {Box, Button, Typography} from "@mui/material";
import {API, api} from "../lib/Api";
import useOutstandingOfferService from "./lib/OutstandingOfferService";
import {formDataAsMap, formDataAsMapFromRef} from "../lib/Forms";
import {offerTypeGroupMapping, Order, OrderEntry} from "./model/Order";
import {Offer} from "../product/model/Product";
import {OrderActorSelector} from "./components/OrderActorSelector";
import {LoginStateCard} from "../components/LoginStateCard";
import {useNavigate} from "react-router-dom";
import {errorMessageModal, messageModal} from "../components/modal/InfoModal";
import {PageBlurb} from "../components/PageBlurb";
import {DollarDecimal} from "../product/lib/DollarDecimal";
import Groups from "../model/Groups";
import {requireGroup, requireOneOfGroup, sum} from "../lib/util";
import SpinnerCard from "../components/SpinnerCard";
import useListOrganizationService from "../internship-site/lib/OrganizationService";
import {Organization} from "../internship-site/model/Organization";
import {Program} from "../program/model/Program";
import useListProgramService from "../program/lib/ProgramService";
import {useAtomValue} from "jotai";
import {flashingErrorMessageAtom, userInformationAtom} from "../Atoms";
import {UserInformation} from "../login/model/UserInformation";
import {clientAtom, loginStateAtom} from "../Atoms";
import {useStyles} from "./styles/OrderStyles";
import {Client} from "../client/model/Client";
import {useTranslation} from "react-i18next";


export const makeOrderEntries: (offers: Offer[], formData: { [k: string]: any }) => OrderEntry[] = (offers, data) => {
    return Object.entries(data)
        .filter(x => x[0].startsWith("offer-"))
        //.filter(x => x[1] === "1")
        .map(x => x[0].slice(6))
        .map(e => {
            const offer = offers.find(x => x.id === e)
            if (offer) {
                return {
                    offer: offer,
                    quantity: parseInt(data[`quantity-${e}`]),
                    unitPrice: offer.price
                } as OrderEntry
            } else {
                throw new Error("Failed to find offer for id " + e)
            }
        })
}


export const CreateOrder: React.FC = () => {
    const {isDebug, setModal} = useAppContext()

    const userInformation: UserInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client
    const loginState = useAtomValue(loginStateAtom)
    const errorMessage = useAtomValue(flashingErrorMessageAtom)

    const localClientId = client.id
    const [order, setOrder] = useState<Order>()
    const [excludedActors, setExcludedActors] = useState<string[]>([])

    const navigate = useNavigate()
    const {t} = useTranslation()

    const {classes} = useStyles()

    const formRef = useRef<HTMLFormElement>(null)

    const organizationsService = useListOrganizationService({});

    const sites = useMemo(() => {
        const organizations: Array<Organization> = (organizationsService.status === 'loaded' ? organizationsService.payload : [] as Array<Organization>)
            .filter(x => {
                if (requireGroup(userInformation?.userGroups, Groups.siteAdmin)) {
                    return userInformation?.sites?.includes(x.internshipSite?.id || 0)
                } else return true
            })

        return organizations.map(e => e.internshipSite).sort((a, b) => a.name.localeCompare(b.name))
    }, [organizationsService, userInformation])


    const programService = useListProgramService();
    const programs: Array<Program> = useMemo(() => programService.isSuccess ? programService.data: [] as Array<Program>, [programService])

    const outstandingOfferService = useOutstandingOfferService()

    // Fetch valid offers for this user
    // Show the offers, allow the user to select the offer they wish to purchse
    // Checkout page with order details

    const setOfferQuantity = useCallback((offerId: string, quantity: number) => {
        if (outstandingOfferService.status === "loaded" && userInformation) {
            const entries = makeOrderEntries(outstandingOfferService.payload, formDataAsMapFromRef(formRef))
            const totalPrice = entries.map(x => x.unitPrice * x.quantity).reduce((a, b) => a + b, 0)

            setOrder({
                id: "",
                clientId: localClientId,
                clientCycleId: client?.settings.cycle.id || 0,
                orderEntries: entries,
                orderActorId: "",
                userEmail: userInformation?.email || "",
                totalPrice: totalPrice,
                createdTimestamp: new Date(),
                productType: entries.length ? entries[0].offer.product.productType : offerTypeGroupMapping[userInformation?.primaryGroup()]
            })
        }
    }, [localClientId, outstandingOfferService, userInformation, client])

    useEffect(() => {
        api<Order[], undefined>(API.listOrdersForUser(), userInformation, localClientId).then(res => {
            isDebug && console.log("Effecting orders")
            const completedOrders = res.data.filter(x => sum((x.payments || []).map(y => y.amount)) === x.totalPrice)
            // Filter to only completed orders
            const excluded = completedOrders.map(x => x.orderActorId)
            isDebug && console.log("Excluded", excluded)
            setExcludedActors(excluded)
        })
    }, [userInformation, localClientId, setExcludedActors, isDebug])


    const validateOfferActorId = (formData: { [k: string]: any }) => {
        const targetNames: { [k: string]: any } = {
            [Groups.siteAdmin]: "Site",
            [Groups.programAdmin]: "Program"
        }

        if (userInformation?.primaryGroup() && requireOneOfGroup(userInformation?.userGroups, [Groups.siteAdmin, Groups.programAdmin])) {
            isDebug && console.log("Validating order actor", formData.orderActorId);
            if (!formData.orderActorId || formData.orderActorId === "0") {
                (setModal && setModal(errorMessageModal("Order Failure", `You must select a ${targetNames[userInformation.primaryGroup()]} to proceed`)))
                return false
            } else {
                return true
            }
        } else {
            return true
        }
    }

    const makeOrder = (e: React.FormEvent) => {
        isDebug && console.log("Making order")
        if (outstandingOfferService.status === "loaded" && userInformation) {
            e.preventDefault()

            const formData = formDataAsMap<{ [p: string]: any }>(e.target as HTMLFormElement)
            isDebug && console.log("Form data", formData)

            if (validateOfferActorId(formData)) {
                isDebug && console.log("Validation passed")
                if (order?.orderEntries.length === 0) {
                    setModal && setModal(messageModal("Order is Empty", "You have no items selected for your order. " +
                        "If you would like to purchase an item, please click the check box on the left side of the line item."))
                } else {
                    isDebug && console.log("Making order entries")
                    const entries = makeOrderEntries(outstandingOfferService.payload, formData)
                    isDebug && console.log("Making order", entries)
                    const productType = entries.length ? entries[0].offer.product.productType : offerTypeGroupMapping[userInformation?.primaryGroup()]
                    const orderPayload: Order = {
                        id: "",
                        clientId: localClientId,
                        clientCycleId: client.settings.cycle.id || 0,
                        orderEntries: entries,
                        orderActorId: (productType === "SchoolProduct" ?
                            programs.find(x => x.id?.toString() === formData.orderActorId)?.schoolId.toString() || 0
                            : formData.orderActorId),
                        userEmail: userInformation ? userInformation.email : "",
                        createdTimestamp: new Date(),
                        productType: productType
                    }
                    console.log("Order payload", orderPayload)

                    // Make order on the backend here
                    api<Order, Order>(API.addOrder(), userInformation, localClientId, orderPayload).then(result => {
                        // redirect to payment page with orderId
                        navigate(`/payment/${result.data.id}`)
                    })
                }
            }
        }
    }

    const {useLoginStateCard, component} = LoginStateCard(loginState, client)
    if (useLoginStateCard) return component;

    const blurbKey = userInformation.primaryGroup() + "/order_page_title"
    console.log("BlurbKey is", blurbKey)
    console.log("Blurbs", client?.settings.blurbs)

    return (
        <Page navName="payment" variant={requireGroup(userInformation.userGroups, Groups.student) ? "half" : "full"} footerPosition="fixed">
            <PageHeader title={`${client.settings.blurbs[blurbKey]}`}>
            </PageHeader>
            <PageBody>
                {outstandingOfferService.status === "loaded" ?
                    (outstandingOfferService.payload.length > 0 ?
                    <>
                        <PageBlurb name="order_page" roleSpecific={true}/>
                        <Box sx={{margin: "0 auto"}}>
                        <Typography variant="inherit" className="stop">{errorMessage}</Typography>
                        <form onSubmit={makeOrder} ref={formRef}>
                            <Box>
                                <OrderActorSelector sites={sites.filter(x => x.id && !excludedActors.includes(x.id.toString()))} programs={programs.filter(x => x.id && !excludedActors.includes(x.id.toString()))}/>
                                <OfferSelectList setOfferQuantity={setOfferQuantity} offers={outstandingOfferService.payload}/>
                            </Box>
                            <Typography variant="h5">Order Total: <DollarDecimal value={order?.totalPrice ? order.totalPrice : 0}/></Typography>
                            <Button type="submit" variant="outlined" color="primary" className={classes.orderConfirmButton}>{t("order.confirmOrder")}</Button>
                        </form>
                        </Box>
                    </>
                    :
                    <Box sx={{marginTop: "20px", marginBottom: "20px", color: "green"}}>
                        <Typography variant="h5">{t("order.noProductsAvailable")}</Typography>
                    </Box>
                ) : <SpinnerCard message="Loading Offers"/>}
            </PageBody>
        </Page>
    )
}