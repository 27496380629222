import React, {CSSProperties, ReactNode} from 'react'
import {Box, Button, Tooltip} from "@mui/material";
import GetAppIcon from '@mui/icons-material/GetApp';
import {DownloadDisposition} from "../lib/Api";
import {useAppContext} from "../lib/context";

interface DownloadsControlProps {
    downloadUrl: string
    style?: CSSProperties
    label?: string
    variant?: 'text' | 'outlined' | 'contained',
    className?: string
    icon?: ReactNode
    disposition?: DownloadDisposition
}

export const DownloadsControl = (props: DownloadsControlProps) => {
    const {isDebug} = useAppContext()
    isDebug && console.log("Download Control", props.downloadUrl)
    isDebug && console.log("Label", props.label)
    return props.downloadUrl ? (
        <Box style={props.style}>
            <DownloadButton {...props}/>
        </Box>
    ) : (<React.Fragment/>)
}
export const DownloadButton = (props: DownloadsControlProps) => {

    const defaultLabel = (disposition: DownloadDisposition | undefined) => {
        switch (disposition) {
            case "inline":
                return "Preview"
            case "attachment":
            default:
                return "Download"
        }
    }

    const defaultIcon= () => <GetAppIcon/>

    return props.downloadUrl ? (
            <Tooltip title={ props.label || defaultLabel(props.disposition)}>
                <Button href={props.downloadUrl} target="_blank"
                        startIcon={props.icon || defaultIcon()}
                        className={props.className}
                        color="primary" variant={props.variant || "contained"}>
                    {props.label || defaultLabel(props.disposition)}
                </Button>
            </Tooltip>
    ) : (<React.Fragment/>)
}
