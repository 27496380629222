import React from "react"

import {Client, MaterialSlotDefinition} from "../../client/model/Client";
import {StudentMaterialsDocument} from "../model/StudentMaterials";
import {DocumentDisplay} from "./DocumentDisplay";
import {StudentUserInformation} from "../../login/model/UserInformation";
import {UploadDocumentDropzoneControl} from "./UploadDocumentDropzoneControl";
import {Box} from "@mui/material";
import {useQueryClient} from "@tanstack/react-query";
import {refreshUser} from "../../Atoms";
import {StoredDocument} from "../model/Document";
import {useAppContext} from "../../lib/context";
export const ElectiveDocument = (props: { slotDefinition: MaterialSlotDefinition, userInformation: StudentUserInformation, client: Client, editable: boolean}) => {
    const {isDebug} = useAppContext()

    const queryClient = useQueryClient()

    const doc: StudentMaterialsDocument | undefined =
        props.userInformation.student.materials.documents.find(x => x.slotId === props.slotDefinition.id)

    isDebug && console.log("Elective Doc is", doc)

    const completeUpload = (d: StoredDocument) => {
        refreshUser(queryClient)
    }

    return (
        <Box>
            {doc?.document.storageRef ? <DocumentDisplay document={doc} uploadType="material" editable={props.editable}/> :
                <UploadDocumentDropzoneControl userInformation={props.userInformation} guid={props.userInformation.guid}
                                               client={props.client} slotDefinition={props.slotDefinition} uploadType="material"
                                               completeUpload={completeUpload}
                />}
        </Box>
    )
}