export interface DataFilter<T> {
    rowFilter: (row: T) => boolean
}

export function filteredView<T>(data: Array<T>, dataFilter?: DataFilter<T>) {
    return data.filter(x => rowFilterPredicate(x, dataFilter))
}

export function rowFilterPredicate<T>(row: T, dataFilter?: DataFilter<T>): boolean {
    return dataFilter ? dataFilter.rowFilter(row) : true
}