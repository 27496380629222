import React from "react"
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import {DateFormatted} from "../../lib/DateFormats"

export interface RichElementProps {
    value: any
    dateFormat?: string
    className?: string
}

export const RichElement: React.FC<RichElementProps> = ({value, dateFormat, className}) => {
    if (value == null) {
        return (<span className={`nullValue ${className}`}>Unknown</span>)
    } else if (typeof(value) === "boolean") {
        return (value as boolean) ? (
            <CheckIcon/>
        ) : (
            <ClearIcon/>
        )
    } else if (value instanceof Date) {
        //const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'};
        //return value.toLocaleDateString('en-US', options)
        return <DateFormatted date={value} inDateFormat={dateFormat}/>
    } else if (typeof(value) === "string") {
        if (value.startsWith("https://") || value.startsWith("http://")) {
            return (<span className={className}>
                    <a href={value} target="_blank" rel="noopener noreferrer">
                        {value.split(/\n/).map(x => <React.Fragment>{x}<br/></React.Fragment>)}
                    </a>
            </span>)
        }
        else {
            return (
                <span className={className}>
                {value.split(/\n/).map(x => <React.Fragment>{x}<br/></React.Fragment>)}
            </span>
            );
        }
    }
    else {
        const str = value.toString();
        return (str)
    }
}
