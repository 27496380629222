import {useLocalStorage} from "./LocalStorage";
import {useNavigate} from "react-router-dom";


export const usePaymentCallback = (props: {callbackPath: string}) => {
    const [value, setValue] = useLocalStorage({key: "paymentCallbackPath", value: props.callbackPath})

    const navigate = useNavigate()

    const invokeCallback = () => {
        navigate(value)
    }

    return {callback: invokeCallback, setCallback: setValue}
}