import {API, api} from "../../lib/Api";
import {Questionnaire} from "../../model/Questionnaire";
import {
    QuestionnaireDataAggregate,
    QuestionnaireDataResult, QuestionnaireNoDataResult,
} from "../../questionnaire/model/QuestionnaireSubmissionElement";
import {
    QuestionnaireElement,
    QuestionnaireId
} from "../../questionnaire/model/Questionnaire";
import {UserInformation} from "../../login/model/UserInformation";
import {Client, ClientId} from "../../client/model/Client";
import {makeQuestionnaireElements} from "../../questionnaire/lib/QuestionnaireElements";
import {UseQueryResult} from "@tanstack/react-query/src/types";
import {useQuery} from "@tanstack/react-query";
import {useAppContext} from "../../lib/context";
import {useFetchStudentQuestionnaireData} from "../../student/lib/StudentQuestionnaireService";
import {clientAtom} from "../../Atoms";
import {useAtomValue} from "jotai";

/*
export const fetchProgramStudentApprovalQuestionnaire = (userInformation: UserInformation, student: UserInformation, clientId: ClientId, questionnaireId: QuestionnaireId, siteId: InternshipSiteId): Promise<QuestionnaireDataAggregate> => {
    console.log("Fetching questionnaire data")
    return api<Questionnaire, undefined>(API.fetchQuestionnaire(questionnaireId), userInformation, clientId)
        .then<QuestionnaireDataAggregate>(qdata => {
            console.log("Fetched questionnaire", qdata)
            return api<QuestionnaireSubmissionElement[], undefined>(API.fetchProgramStudentApprovalQuestionnaireSubmission(questionnaireId, student.guid, siteId), userInformation, clientId)
                .then<QuestionnaireDataAggregate>(resp => {
                    console.log("Making questionnaire data")
                    try {
                        return makeQuestionnaireElements({student: student},
                            qdata.data, resp.data as QuestionnaireSubmissionElement[])
                    }
                    catch (e) {
                        console.log("Failed to make elements", e)
                        return []
                    }
                })
                .catch(error => {
                    console.log("API error", error)
                    if (error.status === 404) {
                        console.log("Got 404 for data")
                        return new Promise<QuestionnaireDataAggregate>((resolve) => resolve(qdata.data.elements))
                    } else {
                        console.log("Failed to load questionnaire data for student ", error.status)
                        return new Promise<QuestionnaireDataAggregate>((resolve, reject) => {
                            reject("Failed")
                        })
                    }
                });
        }).catch(err => {
            console.log("Questionnaire ID is incorrect - check system configuration for questionnaire ID",err)
            if (err.status === 404) {
                return new Promise<QuestionnaireDataAggregate>((resolve, reject) => {
                    reject("Failed - Questionnaire ID is incorrect")
                })
            }
            else {
                return new Promise<QuestionnaireDataAggregate>((resolve, reject) => {
                    reject(err)
                })
            }
        })

}

 */
/*
QuestionnaireElement contains all the elements including headings,
QuestionnaireSubmissionElement contains only elements with answers
 */
export const useFetchProgramStudentApprovalQuestionnaire = (userInformation: UserInformation, guid: string, student: UserInformation | undefined, clientId: ClientId, questionnaireId: QuestionnaireId): UseQueryResult<QuestionnaireDataAggregate, any> => {
    const {isDebug} = useAppContext()
    const client = useAtomValue(clientAtom) as Client

    const studentDataQuery = useFetchStudentQuestionnaireData(userInformation, guid, client.id, client.settings.clientCycleSettings.applicationSuiteSettings.materialSlotsDefinition.studentQuestionnaireId || 0)

    const questionnaireQuery: UseQueryResult<Questionnaire, any> = useQuery({
        queryFn: () => api<Questionnaire, undefined>(API.fetchQuestionnaire(questionnaireId), userInformation, clientId)
            .then(e => e.data)
            .then(e => ({
                id: e.id,
                name: e.name,
                elements: e.elements.map(x => ({
                    ...x,
                    parsedParameters: x.parameters ? JSON.parse(x.parameters) : undefined,
                    tableQuestions: []
                }))
            }))
            .catch(err => {
                isDebug && console.log("Questionnaire ID is incorrect - check system configuration for questionnaire ID",err)
                if (err.status === 404) {
                    return new Promise<Questionnaire>((resolve, reject) => {
                        reject("Failed - Questionnaire ID is incorrect")
                    })
                }
                else {
                    return new Promise<Questionnaire>((resolve, reject) => {
                        reject(err)
                    })
                }
            }),
        queryKey: ["questionnaire", questionnaireId],
        staleTime: 86400000,
        enabled: !!student
    })

    return useQuery({
        queryFn: () => api<QuestionnaireDataResult, undefined>(API.fetchProgramStudentApprovalQuestionnaireSubmission(questionnaireId, guid), userInformation, clientId)
            .then<QuestionnaireDataAggregate>(resp => {
                isDebug && console.log("Making questionnaire data")
                try {
                    if (questionnaireQuery.data) {
                        return {
                            submission: resp.data.submission,
                            elements: makeQuestionnaireElements({student: student, questionnaire: studentDataQuery.data}, questionnaireQuery.data, resp.data.elements)
                        } as QuestionnaireDataResult
                    } else {
                        return {
                            submission: undefined,
                            elements: [] as QuestionnaireElement[]
                        } as QuestionnaireNoDataResult
                    }
                } catch (e) {
                    console.log("Failed to make elements", e)
                    return {elements: [] as QuestionnaireElement[], submission: undefined} as QuestionnaireNoDataResult
                }
            })
            .catch(error => {
                if (error.status === 404) {
                    isDebug && console.log("Got 404 for data")
                    return new Promise<QuestionnaireDataAggregate>((resolve) => resolve(
                        {
                        elements: questionnaireQuery.isSuccess ? makeQuestionnaireElements({student: student, questionnaire: studentDataQuery.data}, questionnaireQuery.data, []) :[],
                        submission: undefined
                    }))
                } else {
                    isDebug && console.log("API error", error)
                    isDebug && console.log("Failed to load questionnaire data for student ", error.status)
                    return new Promise<QuestionnaireDataAggregate>((resolve, reject) => {
                        reject("Failed")
                    })
                }
            }),
        queryKey: ["programApprovalQuestionnaire", guid],
        enabled: questionnaireQuery.isSuccess && studentDataQuery.isSuccess,
        staleTime: 86400000
    })
}
