import React from 'react';
import '../../Login.css';
import {Program} from "../model/Program";
import {Link} from "react-router-dom";

interface ProgramLinkProps {
    program: Program
}

export const ProgramLink: React.FC<ProgramLinkProps> = (props) => {
    return (
        <Link to={`/program/${props.program.id}`}>{props.program.name}</Link>
    )
}
