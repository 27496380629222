import {Box} from "@mui/material";
import {ReactNode} from "react";


export type PacketSlotEntryVariant = "plain" | "error"

export const PacketSlotEntry: React.FC<{variant?: PacketSlotEntryVariant, children: ReactNode}> = (props) => {
    const color = props.variant === "error" ? "rgba(255,0,0,0.2)" : "rgba(255,255,255,1)"

    return <Box aria-label="Packet Slot Entry" p={1} sx={{
        border: "2px solid rgba(128,128,128,0.2)",
        borderRadius: 3,
        backgroundColor: color
    }} className="xs:ml-1 xs:mr-1 md:mr-2 md:ml-2 mt-2">
        {props.children}
    </Box>
}