import React, {ReactNode, useState} from "react"
import {useAppContext} from "../../../lib/context";
import {useParams} from "react-router-dom";
import {Page, PageBody, PageHeader} from "../../../components/Page";
import {Box, Button, Typography} from "@mui/material";
import {useStyles} from "../../Materials";
import SpinnerCard from "../../../components/SpinnerCard";
import {Client, MaterialSlotDefinition} from "../../../client/model/Client";
import {LetterOfReferenceStatuses, LetterOfReferenceStatus} from "../../model/LetterOfReference";
import {Check} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {useAtomValue} from "jotai";
import {UploadDocumentDropzoneControl} from "../UploadDocumentDropzoneControl";
import {clientAtom} from "../../../Atoms";
import {MaterialDocument} from "../../model/Document";
import {useQueryClient} from "@tanstack/react-query";
import {ErrorFallback} from "../../../components/ErrorFallback";
import {LetterOfReferenceAPIResult, useLetterOfReferenceService} from "./lib/LetterOfReferenceService";
import {useDeclineLetterOfReferenceService} from "./lib/DeclineLetterOfReferenceService";

export const ProvideLetterOfReference: React.FC = () => {
    const {isDebug} = useAppContext()

    const client = useAtomValue(clientAtom) as Client

    const queryClient = useQueryClient()

    const [showForm, setShowForm] = useState<boolean>(false)

    const params = useParams()

    const {classes} = useStyles()

    const letterOfReferenceQuery  = useLetterOfReferenceService(params.guid || '', params.lorUUID || '')

    const getMaterialsSlot = (lor: LetterOfReferenceAPIResult): MaterialSlotDefinition => {
        const slot = client.settings.clientCycleSettings?.applicationSuiteSettings?.materialSlotsDefinition
            .slotDefinitions.find(x => x.id === lor.slotId) as MaterialSlotDefinition

        isDebug && console.log("Found materials slot", slot)

        return slot
    }

    const declineService = useDeclineLetterOfReferenceService()
    const decline = () => declineService.mutate({guid: params.guid || '', lorUUID: params.lorUUID || ''})

    const accept = () => {
        setShowForm(true)
    }

    const upload: (client: Client, slot: MaterialSlotDefinition, document: MaterialDocument | undefined) => React.ReactNode = (client, slot) => <><Typography variant="h5">Complete Letter of Reference Request</Typography>
        <Typography variant="inherit">You have the option to either accept the request for this letter of reference and upload a PDF document,
            or, you may decline this request.</Typography>
        <Box hidden={showForm} p={2}>
            <Box m={1}>
                <Button variant="contained" color="primary" onClick={e => accept()} startIcon={<Check/>}>I would like to provide a letter of reference.</Button>
            </Box>
            <Box m={1}>
            <Button variant="contained" color="secondary" onClick={e => decline()} startIcon={<CloseIcon/>}>I decline to provide a letter of reference.</Button>
            </Box>
        </Box>
        <Box hidden={!showForm}>
        <form>
            <p>Thank you for accepting this request to provide a letter of reference!</p>
            <p>
            Please Upload your Letter of Reference document using the form below. The letter should be provided in PDF format.
            </p>
            <UploadDocumentDropzoneControl
                userInformation={undefined}
                guid={params.guid || ''}
                client={client}
                slotDefinition={slot}
                uploadType="letterOfReference"
                completeUpload={() => queryClient.invalidateQueries(["letterOfReference", params.lorUUID])}
            />
        </form>
        </Box>
    </>

    const complete: (client: Client, slot: MaterialSlotDefinition, document: MaterialDocument | undefined) => React.ReactNode  = (client, slot, document) => <><Typography variant="h5">Letter of Reference Request Complete</Typography>
        <p>
            This letter of reference request has been completed!
        </p>
    </>

    /*
    const downloadButton =(document: MaterialDocument | undefined) =>
        <Button href={getApiUrl() + API.downloadMaterial(document?.id || '').location} variant="contained"
                target="_blank" download color="primary" startIcon={<GetAppIcon/>}>View Document</Button>

     */

    const declined: (client: Client, slot: MaterialSlotDefinition, document: MaterialDocument | undefined) => React.ReactNode  = (client, slot) => <><Typography variant="h5">
        The letter of reference request was declined.
    </Typography></>

    type LetterOfReferenceStatusMappingObject = {
        [K in LetterOfReferenceStatus]: (client: Client, slot: MaterialSlotDefinition, document: MaterialDocument | undefined) => ReactNode;
    };

    const mapping = {
        [LetterOfReferenceStatuses.requested]: upload,
        [LetterOfReferenceStatuses.completed]: complete,
        [LetterOfReferenceStatuses.declined]: declined
    } as LetterOfReferenceStatusMappingObject

    return (client?.settings.siteName && letterOfReferenceQuery.isSuccess) ? <Page navName="applicationMaterials" footerPosition="fixed">
            <PageHeader title={`Letter of Reference for ${letterOfReferenceQuery.data.studentMetadata.firstName} ${letterOfReferenceQuery.data.studentMetadata.lastName}`}>
            </PageHeader>
            <PageBody>
                <Box className={classes.materialSlotEntry}>
                    {mapping[letterOfReferenceQuery.data.state](client, getMaterialsSlot(letterOfReferenceQuery.data), letterOfReferenceQuery.data.document?.document)}
                </Box>
            </PageBody>
        </Page>
        : (letterOfReferenceQuery.isError ? <ErrorFallback/> : <SpinnerCard message="Please wait, loading..."/>)
}