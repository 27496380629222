import {StudentLoggedInComponent} from "../../../components/LoggedInPage";
import {Box, Checkbox, FormControlLabel, FormGroup, Typography} from "@mui/material";
import React, {useState} from "react";
import {StudentOps} from "../../../student/model/Student";
import {FullWidthFormControl} from "../../../components/form/FullWidthFormControl";
import {PacketSlotEntry} from "./PacketSlotEntry";
import {ClientSettingsOps, SlotGroups} from "../../../client/model/Client";
import {PacketRef, StudentApplication} from "../../model/StudentMaterials";
import {PacketState} from "../Packet";
import {LetterOfReferenceStatuses} from "../../model/LetterOfReference";
import {useTranslation} from "react-i18next";
import {RequirementFulfilled} from "../../lib/RequirementFulfilled";

export interface LettersOfReferenceSelectionProps {
    application: StudentApplication,
    updatePacket: (f: (packetState: PacketState) => PacketState) => void
}

export const LettersOfReferenceSelection: StudentLoggedInComponent<LettersOfReferenceSelectionProps> = (props) => {

    const clientSettings = props.client.settings

    const {t} = useTranslation()

    const packetSlots = ClientSettingsOps.packetSlotsByType(clientSettings, SlotGroups.letterOfReference)
    const lettersOfReference = StudentOps.lettersOfReference(props.userInformation)
    const filledSlotEntries = StudentOps.packetSlotEntriesByType(clientSettings, props.application?.packetRefs || [])(SlotGroups.letterOfReference)

    console.log("Initializing LOR with", filledSlotEntries)

    const [satisfied, setSatisfied] = useState<boolean>(filledSlotEntries.length === packetSlots.length)

    const [lorCheckedState, setLorCheckedState] = React.useState<Record<string, boolean>>(
        Object.fromEntries(
            new Map(
                filledSlotEntries.map(x => [x.documentId, true])
            )
        )
    )

    const makePacketRef = (id: string, slotId: string): PacketRef => {
        const lor =
            lettersOfReference.find(e => e.letterOfReference.documentId === id)

        console.log("Making packet ref", lor)

        if (lor) {
            return {
                id: undefined,
                packetSlotId: slotId,
                documentId: lor.letterOfReference.documentId,
                createdTimestamp: new Date(),
                storageRef: lor.document?.document.storageRef,
                siteId: props.application.siteId,
                trackId: props.application.trackId,
                submitted: false
            }
        }
        else {
            throw new Error("Can't find letter of reference ID" + id)
        }
    }

    const setCheckedState = (id: string) => {
        setLorCheckedState(state => {
            const j: boolean = state[id] || false
            const newState = {...state, [id]: !j}

            if (Object.keys(newState).filter(x => newState[x]).length === packetSlots.length) {
                setSatisfied(true)
            }
            else {
                setSatisfied(false)
            }

            props.updatePacket(packetState => {
                const packetSlots = ClientSettingsOps.packetSlotsByType(props.client.settings, SlotGroups.letterOfReference)
                const newPacketRef = [...(Object.keys(newState).filter(x => newState[x]).map((e, i) => makePacketRef(e, packetSlots[i].id)))]

                console.log("New Packet Refs for LOR", newPacketRef)

                return ({
                    ...packetState,
                    isSatisfied: (packetState.isSatisfied && satisfied),
                    application: {
                        ...packetState.application,
                        packetRefs: [
                            ...(packetState.application.packetRefs.filter(e => !(packetSlots.map(x => x.id).includes(e.packetSlotId)))),
                            ...newPacketRef
                        ]
                    }
                })
            })

            return newState
        })
    }

    const handleCheckbox = (event: React.ChangeEvent<HTMLInputElement>) => setCheckedState(event.target.name)

    return <PacketSlotEntry>
        <Box sx={{width: "100%"}} className="relative">
            <RequirementFulfilled isFulfilled={() => satisfied}/>
            <Typography variant="h5">
                {t("packet.selectLettersOfReference", {count: packetSlots.length})}
            </Typography>
        </Box>
        <Box m={1}>
            <FullWidthFormControl>
                <FormGroup>
                    {lettersOfReference && lettersOfReference
                        .map(e => ({lor: e, id: e.letterOfReference.documentId || ""}))
                        .map(({lor, id}, index) => <>
                                <FormControlLabel
                                    control={<Checkbox name={id} onChange={handleCheckbox}
                                                       disabled={(satisfied && !lorCheckedState[id]) || (lor.letterOfReference.state !== LetterOfReferenceStatuses.completed)}
                                                       checked={lorCheckedState[id]}/>}
                                    label={`${lor.letterOfReference.referentName} ${lor.letterOfReference.state === LetterOfReferenceStatuses.requested ? "[Pending]" : ""}`}/>
                            </>
                        )}
                </FormGroup>
            </FullWidthFormControl>
        </Box>
    </PacketSlotEntry>
}