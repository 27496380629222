import {Button, Fab} from "@mui/material";
import React, {RefObject} from "react";
import {ZodObject, ZodRawShape, ZodRecord} from "zod";
import {useAppContext} from "../../lib/context";
import {RenderKey} from "./RenderKey";
import {initialClientSettings} from "../../lib/initialValues";
import {formDataAsMapFromRef} from "../../lib/Forms";

const merge = require('deepmerge')

export type OpenFormProps = {
    data: Record<string, any>,
    validator: ZodObject<ZodRawShape> | ZodRecord | undefined,
    classes: Record<string, string>,
    onSubmit: (data: Record<string, any>) => void,
    formRef: RefObject<HTMLFormElement>,
    defaultValue: Partial<Record<string, any>>
}

export const OpenForm = ({data, validator, classes, onSubmit, formRef, defaultValue}: OpenFormProps) => {
    const {isDebug} = useAppContext()

    const showFormData = (e: React.FormEvent<HTMLButtonElement>) => {
        const data = merge(initialClientSettings, // This populates empty values into places the form might miss like slot definitions
            formDataAsMapFromRef(formRef))
        isDebug && console.log("Form Data is", data);
    }
    
    const handleSubmit = (e: React.FormEvent<HTMLButtonElement>) => {
        const data = merge(defaultValue, // This populates empty values into places the form might miss like slot definitions
            formDataAsMapFromRef(formRef))
        onSubmit(data)
    }

    return <form ref={formRef}>
        {(Object.keys(data) as Array<keyof typeof data>).map(key =>
            <RenderKey data={data} property={key} validator={validator} classes={classes}/>)}
        <Button color="primary" variant="outlined" type="button" onClick={e => onSubmit(e)}>Submit</Button>
        <Fab type="button" variant="extended" id="client-settings-submit" className={classes.fab}
             onClick={e => handleSubmit(e)}>
            Save Client Settings
        </Fab>
        {isDebug &&
            <Button color="primary" variant="outlined" type="button" onClick={e => {
                showFormData(e)
            }}>Show Form Data</Button>
        }
    </form>
}
