import React, {FC, useEffect, useState} from "react"
import {Offer} from "../../product/model/Product";
import {Checkbox, IconButton, ListItem, ListItemIcon, ListItemSecondaryAction, ListItemText} from "@mui/material";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import {OfferItemText} from "./OfferItemText";
import {useStyles} from "../styles/OrderStyles";

interface OfferListElementProps {
    offer: Offer
    readonly: boolean
    setOfferQuantity: (id: string, quantity: number) => void
}


export const OfferListElement: FC<OfferListElementProps> = ({offer, readonly, setOfferQuantity}) => {
    const [checked, setChecked] = useState(0)

    const handleToggle = () => {
        setChecked(checked => 1 - checked)
    }

    useEffect(() => {
        setOfferQuantity(offer.id, checked)
    }, [offer, setOfferQuantity, checked])

    const {classes} = useStyles()

    return (
        <ListItem key={offer.id} role={undefined} dense button onClick={e => handleToggle()}>
            {!readonly &&
            <ListItemIcon>
                <Checkbox
                    edge="start"
                    checked={!!checked}
                    tabIndex={-1}
                    disableRipple
                    inputProps={{'aria-label': offer.name}}
                    name={`offer-${offer.id}`}
                />
            </ListItemIcon>
            }
            <ListItemText id={offer.id} primary={<OfferItemText setOfferQuantity={setOfferQuantity} readonly={readonly} offer={offer}/>} className={classes.grower}/>
            <ListItemSecondaryAction onClick={e => handleToggle()}>
                <IconButton edge="end" aria-label="comments" size="large">
                    <LocalOfferIcon/>
                </IconButton>
            </ListItemSecondaryAction>
        </ListItem>
    );

}