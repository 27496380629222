import React from 'react'
import {AuthenticationFailed} from "./AuthenticationFailed";
import SpinnerCard from "./SpinnerCard";
import {Client} from "../client/model/Client";
import {useAppContext} from "../lib/context";
import {requireOneOfGroup} from "../lib/util";
import useUserReadyService from "../user/lib/UserReadyService";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../Atoms";
import {LoginState} from "../login/lib/UserSessionsService";

interface LoginStateCardResult {
    useLoginStateCard: boolean,
    component: JSX.Element
}

export const LoginStateCard: (loginState: LoginState | undefined, client: Client | undefined, requiredGroups?: string[]) => LoginStateCardResult = (loginState, client, requiredGroups) => {
    const {isDebug} = useAppContext()
    const userInformation = useAtomValue(userInformationAtom)

    const userReady = useUserReadyService()

    if (!client?.settings.clientCycleSettings) {
        return {useLoginStateCard: true, component: (<SpinnerCard message="Loading"/>)} as LoginStateCardResult
    }

    const checkAuthorized = () => {
        if ((loginState === LoginState.AuthenticationSuccess || loginState === LoginState.UserReady) && userReady) {
            isDebug && console.log("Authentication successful", requiredGroups, userInformation?.userGroups)
            if (requiredGroups && requiredGroups.length > 0)
                return requireOneOfGroup(userInformation?.userGroups, requiredGroups) ?
                    {useLoginStateCard: false, component: (<React.Fragment/>)} as LoginStateCardResult :
                    {useLoginStateCard: true, component: (<AuthenticationFailed/>)} as LoginStateCardResult
            else return {useLoginStateCard: false, component: (<React.Fragment/>)} as LoginStateCardResult
        }
        else {
            return {useLoginStateCard: true, component: (<AuthenticationFailed/>)} as LoginStateCardResult
        }
    }

    switch (loginState) {
        case LoginState.NewPasswordRequired:
            return {useLoginStateCard: true, component: (<SpinnerCard message="New password required, redirecting.."/>)} as LoginStateCardResult
        case LoginState.AuthenticationFailed:
            return {useLoginStateCard: true, component: (<AuthenticationFailed/>)} as LoginStateCardResult
        case LoginState.Authenticating:
            return {useLoginStateCard: true, component: (<SpinnerCard message="Authenticating"/>)} as LoginStateCardResult
        case LoginState.AuthenticationSuccess:
            return {useLoginStateCard: true, component: (<SpinnerCard message="Loading User Data"/>)} as LoginStateCardResult
        default:
            return checkAuthorized()
    }
}