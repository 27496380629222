import React, {useState} from "react";
import {Box, Button, Grid} from "@mui/material";
import {StudentMaterialsDocument} from "../model/StudentMaterials";
import {RichElement} from "../../components/customTable/RichElement";
import {api, API, apiError, getApiUrl} from "../../lib/Api";
import {Cancel} from "@mui/icons-material";
import {useAppContext} from "../../lib/context";
import GetAppIcon from '@mui/icons-material/GetApp';
import SpinnerCard from "../../components/SpinnerCard";
import {useAtomValue} from "jotai";
import {clientAtom, userInformationAtom} from "../../Atoms";
import {useQueryClient} from "@tanstack/react-query";
import {refreshUser} from "../../Atoms";
import {DatumDisplay} from "../../components/DatumDisplay";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {DownloadButton} from "../../components/DownloadsControl";
import {Client} from "../../client/model/Client";

export interface DocumentDisplayProps {
    document: StudentMaterialsDocument
    uploadType: "material" | "packet" | "letterOfReference"
    editable: boolean
}

export const DocumentDisplay: React.FC<DocumentDisplayProps> = (props) => {
    const {setModal, isDebug} = useAppContext()
    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client

    const queryClient = useQueryClient()
    const [removing, setRemoving] = useState(false)

    const removeDocument = (e: React.MouseEvent<HTMLButtonElement>) => {
        setRemoving(true)

        // LetterOfReference doesn't apply here becaues that's a different component altogether
        const apiEndpoint = props.uploadType === "material" ? API.removeDocument : API.removePacketDocument

        // TODO move to useMutation
        api(apiEndpoint(props.document.document.id), userInformation, client.id)
            .then(res =>
                refreshUser(queryClient).then(() => setRemoving(false))
            )
            .catch(err => apiError(err, setModal))
    }

    isDebug && console.log("Document", props.document.document)
    isDebug && console.log("Editable", props.editable)

    return removing ? <Grid container><Grid item xs={12}><SpinnerCard message="Removing Document"/></Grid></Grid> :
        <Box>
            <DatumDisplay title="Original File Name">
                {props.document.document.originFilename}
            </DatumDisplay>
            <DatumDisplay title="Uploaded">
                <RichElement value={props.document.createdTimestamp}/>
            </DatumDisplay>
            <Grid container spacing={1}>
                {props.editable &&
                <Grid item xs={12} md={4}>
                    <Button variant="contained" onClick={removeDocument} startIcon={<Cancel/>} color="secondary" className="w-full">Remove</Button>
                </Grid>
                }
                <Grid item xs={12} md={4}>
                    <Button href={getApiUrl() + API.downloadMaterial(userInformation.guid, props.document.document.id).location} variant="contained"
                            className="w-full"
                                    target="_blank" download color="primary" startIcon={<GetAppIcon/>}>Download</Button>
                </Grid>
                <Grid item xs={12} md={4}>
                    <DownloadButton downloadUrl={getApiUrl() + API.downloadMaterial(userInformation.guid, props.document.document.id, "inline").location + "&clientId=" + client.id}
                                    className="w-full"
                                icon={<PictureAsPdfIcon/>} disposition="inline"/>
                </Grid>
            </Grid>
        </Box>
}
