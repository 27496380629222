import React, {useEffect, useState} from 'react'
import {Organization, OrganizationId} from "../model/Organization";
import {FormControl, Select, InputLabel, SelectChangeEvent} from "@mui/material";

import {requireOneOfGroup} from "../../lib/util";
import Groups from "../../model/Groups";
import {makeStyles} from "tss-react/mui";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";

const useStyles = makeStyles()((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
        maxWidth: "100%"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

interface OrganizationSelectProps {
    organizations: Organization[]
    setFilteredOrganizationId: (orgId: OrganizationId) => void
    selectedOrganizationId: OrganizationId | undefined
}

export const OrganizationSelect = (props: OrganizationSelectProps) => {
    const userInformation = useAtomValue(userInformationAtom)

    const [selectedOrganizationId, setSelectedOrganizationId] = useState<OrganizationId>();

    useEffect(() => {
        props.selectedOrganizationId && setSelectedOrganizationId(props.selectedOrganizationId)
    }, [props.selectedOrganizationId])

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        setSelectedOrganizationId(parseInt(event.target.value as string))
        props.setFilteredOrganizationId && props.setFilteredOrganizationId(parseInt(event.target.value as string))
    }

    const {classes} = useStyles()

    return (
        <FormControl variant="filled" className={classes.formControl}>
            <InputLabel htmlFor="internship-site-select">Training Site</InputLabel>
            <Select
                native
                value={selectedOrganizationId}
                onChange={handleChange}
                inputProps={{
                    name: 'Training Site',
                    id: 'internship-site-select',
                }}>
                {requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin, Groups.directoryAdmin, Groups.clientAdmin]) &&
                    <option aria-label="None" value="">All Tracks</option>
                }
                {props.organizations.sort((a, b) => a.internshipSite.name.localeCompare(b.internshipSite.name)).filter(x => !x.internshipSite.name.toLowerCase().includes("archive")).map(x => (
                    <option aria-label={x.internshipSite.name} value={x.id} key={x.id}>{x.internshipSite.name}</option>
                ))}
            </Select>
        </FormControl>
    )
}
