import React, {useState} from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import {ColumnRepr, TableColumn} from "../../components/customTable/CustomTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ListAltIcon from '@mui/icons-material/ListAlt';
import Collapse from "@mui/material/Collapse";
import Box from "@mui/material/Box";
import {InternshipSite} from "../model/Organization";
import {Questionnaire} from "../../model/Questionnaire";
import {TrackListTable} from "./TrackListTable";
import {tracksFromInternshipSite} from "../lib/TracksForOrganization";
import {List, ListItem, ListItemIcon, ListItemText, Tooltip} from "@mui/material";
import {confirmDeleteModal, ConfirmDialogActions, questionModal} from "../../components/modal/InfoModal";
import {useAppContext} from "../../lib/context";
import {AccountCircle} from "@mui/icons-material";
import {LowPriority} from "@mui/icons-material";
import {requireOneOfGroup} from "../../lib/util";
import Groups from "../../model/Groups";
import ArchiveIcon from '@mui/icons-material/Archive';
import UnarchiveIcon from '@mui/icons-material/Unarchive';
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";
import {clientAtom} from "../../Atoms";
import {DialogType, ModalAction} from "../../components/modal/ModalProps";
import {UserInformation} from "../../login/model/UserInformation";
import {Client} from "../../client/model/Client";

interface InternshipSiteUserGridProps {
    users: UserInformation[]
}

export const InternshipSiteUserGrid = (props: InternshipSiteUserGridProps) => {
    return (
    <List dense>
        {props.users.map(u =>
            <OrganizationUserEntry user={u} key={u.email}/>
        )}
    </List>
    )
}

export interface OrganizationUserEntryProps {
    user: UserInformation
}

export const OrganizationUserEntry = (props: OrganizationUserEntryProps) => (
    <ListItem key={props.user.email} role={undefined} dense>
        <ListItemIcon style={{"minWidth":"35px"}}>
            <Tooltip title={`View User ${props.user.email}`}>
                <IconButton size="small" href={`/user/${props.user.email}`}><AccountCircle/></IconButton>
            </Tooltip>
        </ListItemIcon>
        <ListItemText primary={`${props.user.firstName || ""} ${props.user.lastName || ""} ${props.user.email}`}/>
    </ListItem>
)

interface InternshipSiteListRowProps {
    internshipSite: InternshipSite
    columns: Array<TableColumn<InternshipSite>>,
    siteAdmins: UserInformation[],
    questionnaires: Questionnaire[]
    handleDelete: (row: InternshipSite) => void
    handleRestore: (row: InternshipSite) => void
    viewName?: string
}


export const InternshipSiteListRow: React.FC<InternshipSiteListRowProps> = (props) => {
    const {setModal} = useAppContext()

    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client

    const [open, setOpen] = useState(false);

    const {internshipSite, columns} = props

    const handleDelete = (row: InternshipSite) => {
        setModal && setModal(confirmDeleteModal(row, (action: string, row: InternshipSite) => {
            return action === "delete" && props.handleDelete(row)
        }, "Are you sure you want to delete this Site? ALL Tracks will be deleted and all relationships those Tracks had with schools will also be deleted."))
    }

    const handleRestore = (row: InternshipSite) => {
        setModal && setModal(questionModal(row, "Confirm Restore Site", "Are you sure you want to add this site to the current cycle?", DialogType.Confirm, (action: string, row: InternshipSite) => {
            return action === ModalAction.Confirm && props.handleRestore(row)
        }, (props) => <ConfirmDialogActions {...props}/>))
    }

    return (
        <React.Fragment>
            <TableRow key={props.internshipSite.organizationId} style={internshipSite.clientCycleId !== client.settings.cycle.id ? {backgroundColor: "#f0f0f0", color: "#808080"} : {}}>
                <TableCell style={{width: "48px"}}>
                    <IconButton onClick={() => setOpen(!open)} size="large">
                        {open ? <KeyboardArrowUpIcon/> : <KeyboardArrowDownIcon/>}
                    </IconButton>
                </TableCell>
                {columns.map((column, index) => {
                    return (<TableCell key={`${internshipSite.id}-${index}`} className={internshipSite.clientCycleId !== client.settings.cycle.id ? "notInCycle" : "inCycle"}>
                        <ColumnRepr column={column} row={internshipSite} />
                    </TableCell>)
                })}
                <TableCell>
                    <InternshipSiteUserGrid users={props.siteAdmins.filter(x => (x.sites || []).includes(props.internshipSite.id || 0))}/>
                </TableCell>
                <TableCell className="optionsCell">
                    <div style={{whiteSpace: "nowrap"}}>
                        <Tooltip title="Edit Site">
                            <IconButton
                                href={`site/${internshipSite.organizationId}/${internshipSite.id}`}
                                aria-label="Edit Site"
                                size="large"><EditIcon/></IconButton>
                        </Tooltip>
                        {props.viewName === "allCycles" ?
                            internshipSite.clientCycleId !== client?.settings.cycle.id ?
                                    <Tooltip title="Restore Site to Current Cycle">
                                        <IconButton
                                            aria-label="Unarchive Site"
                                            onClick={() => handleRestore(internshipSite)}
                                            size="large">
                                            <UnarchiveIcon/>
                                        </IconButton>
                                    </Tooltip> :
                                    <Tooltip title="Archive Site">
                                        <IconButton
                                            aria-label="Archive Site"
                                            onClick={() => handleDelete(internshipSite)}
                                            size="large">
                                            <ArchiveIcon/>
                                        </IconButton>
                                    </Tooltip>
                            :
                                <Tooltip title="Delete Site">
                                    <IconButton
                                        aria-label="Delete Site"
                                        onClick={() => handleDelete(internshipSite)}
                                        size="large">
                                        <DeleteOutlineIcon/>
                                    </IconButton>
                                </Tooltip>
                        }
                        {props.questionnaires && props.questionnaires.length > 0 && (
                            <Tooltip title="Site Directory Profile">
                                <IconButton
                                    href={`questionnaire/submission/${props.internshipSite.id}/${props.questionnaires[0].id}/${internshipSite.id}`}
                                    size="large">
                                    <ListAltIcon/>
                                </IconButton>
                            </Tooltip>
                        )}
                        {requireOneOfGroup(userInformation?.userGroups, [Groups.siteAdmin, Groups.globalAdmin]) &&
                            <Tooltip title="Rank Students">
                                <IconButton
                                    href={`/siteStudentRank/${internshipSite.organizationId}/${internshipSite.id}`}
                                    size="large">
                                    <LowPriority/>
                                </IconButton>
                            </Tooltip>
                        }
                    </div>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={8}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box margin={1}>
                            <TrackListTable tracks={tracksFromInternshipSite(internshipSite)} internshipSite={internshipSite}/>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </React.Fragment>
    );
}