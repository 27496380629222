import {FormControl, TextField} from "@mui/material";
import React from "react";

interface EditTextProps {
    name: string
    value: string
    classes: any
    label?: string
}

export const EditText = (props: EditTextProps) => {

    return props.value.length > 40 || props.value.includes("<") ? (
        <FormControl className={props.classes.formControl}>
            <TextField variant="outlined" type="text" id={props.name}
                       defaultValue={props.value}
                       multiline
                       rows={4}
                       name={props.name}
                       label={props.label || props.name}
            />
        </FormControl>
    ) : (<FormControl className={props.classes.formControl}>
        <TextField id={props.name}
                   type="text"
                   variant="outlined"
                   aria-label={props.name}
                   label={props.label || props.name}
                   defaultValue={props.value}
                   name={props.name}
        />
    </FormControl>)
}