import React from 'react';
import {CardElement} from '@stripe/react-stripe-js';
import './CardSectionStyles.css'
import {Grid, Typography} from "@mui/material";
import {Theme} from "@mui/material/styles";
import {ReactComponent as StripeMark} from "../assets/PoweredbyStripe-blurple.svg"
import {makeStyles} from "tss-react/mui";

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};

const useStyles = makeStyles()((theme: Theme) => ({
        cardBox: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1)
        },
    }));

function CardSection() {
    const {classes} = useStyles()

    return (
        <Grid container justifyContent="flex-end" style={{paddingBottom: 10}}>
            <Grid item xs={12} className={classes.cardBox}>
                <Typography>Enter Payment Card Details</Typography>
                <CardElement options={CARD_ELEMENT_OPTIONS} />
            </Grid>
            <Grid item container xs={12} justifyContent="flex-end">
                <StripeMark style={{width: "6rem"}}/>
            </Grid>
        </Grid>
    );
};

export default CardSection;