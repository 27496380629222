import {Service} from "./Service";
import {API, api} from "./Api";
import {Questionnaire} from "../model/Questionnaire";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../Atoms";
import {useQuery} from "@tanstack/react-query";
import {ClientId} from "../client/model/Client";
import {clientAtom} from "../Atoms";
import {useAppContext} from "./context";

const questionnaireQueryStaleSeconds = 1 * 60 * 60// 1 Hr

const useListQuestionnaireService = () => {
    const {isDebug} = useAppContext()
    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom)
    const clientId = client?.id

    isDebug && console.log("Questionnaire clientId",clientId)

    const questionnairesQuery = useQuery({
        queryFn: () => api<Questionnaire[], undefined>(API.listQuestionnaires(), userInformation, clientId),
        queryKey: ["questionnaires", clientId],
        staleTime: (questionnaireQueryStaleSeconds * 1000),
        enabled: clientId > 0
        //enabled: userReady
    })

    const result = (): Service<Questionnaire[] & {clientId?: ClientId}> => { switch (questionnairesQuery.status) {
            case "loading":
                return {status: 'loading'}
            case "error":
                return {status: 'error', error: (questionnairesQuery.error as any).toString()}
            case "success":
                return {status: 'loaded', payload: questionnairesQuery.data.data}
        }
    }

    return result();
}

export default useListQuestionnaireService;