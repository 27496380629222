import React from 'react';
import '../student/Student.css';

import {useAppContext} from "../lib/context"
import {LoginStateCard} from "../components/LoginStateCard";
import TrackProgramList from "./component/TrackProgramList";
import {Page} from "../components/Page";
import {useParams} from "react-router-dom";
import {requireOneOfGroup} from "../lib/util";
import Groups from "../model/Groups";
import {AuthenticationFailed} from "../components/AuthenticationFailed";
import useUserReadyService from "../user/lib/UserReadyService";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../Atoms";
import {clientAtom, loginStateAtom} from "../Atoms";

type TrackProgramParams = {
    siteTrackId: string,
}

const TrackProgramRelationships = () => {
    const params = useParams<TrackProgramParams>()
    const { isDebug} = useAppContext();

    const loginState = useAtomValue(loginStateAtom)
    const client = useAtomValue(clientAtom)
    const userInformation = useAtomValue(userInformationAtom)

    const { useLoginStateCard, component } = LoginStateCard(loginState, client)

    const userReady = useUserReadyService()

    if (userReady && !requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin, Groups.clientAdmin, Groups.siteAdmin])) {
        isDebug && console.log("Returning auth failed")
        return (<AuthenticationFailed/>)
    }

    isDebug && console.log("TPR", params)

    const siteTrackId = params.siteTrackId ? parseInt(params.siteTrackId) : undefined

    isDebug && console.log("LSC", useLoginStateCard)

    return useLoginStateCard ? component: (
        <Page navName="Program">
            <TrackProgramList siteTrackId={siteTrackId}/>
        </Page>
    )
}

export default TrackProgramRelationships
