import { useEffect, useState } from "react";
import {School} from "../model/School";
import {Service} from "../../lib/Service";
import {API, api} from "../../lib/Api";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";
import {clientAtom} from "../../Atoms";
import {useAppContext} from "../../lib/context";

const useListSchoolService = () => {
    const {isDebug} = useAppContext()
    const client = useAtomValue(clientAtom)
    const userInformation = useAtomValue(userInformationAtom)

    const [result, setResult] = useState<Service<Array<School>>>({
        status: 'init'
    });

    // FIXME this is horrible
    useEffect(() => {
        isDebug && console.log("Effecting", client.id)
        if (client.id) {
            isDebug && console.log("Effecting schools")
            api<School[], undefined>(API.listSchool(), userInformation, client.id)
                .then(response => setResult({status: 'loaded', payload: response.data}))
                .catch(error => setResult({status: 'error', error}));
        }
    }, [isDebug, client, userInformation]);

    return result;
}

export default useListSchoolService;