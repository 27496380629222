import React from "react";
import {Page} from "./components/Page";
import {Card, CardContent} from "@mui/material";

const Privacy = () => {
    return (
        <Page navName="" bottomNavName="Terms">
            <Card>
                <CardContent>
                    <h1>Match Fit LLC Privacy Policy</h1>

                    <h2>Introduction</h2>
                    <p>Match Fit LLC. ("PracticumFit", “InternFit”, "we", "us", "our") is committed to protecting the privacy and confidentiality of information entrusted to us by our users. This Privacy Policy applies to websites (practicumfit.com, internfit.com), including all pages, sub-pages, and successor pages (collectively, the "Website"), owned and operated by Match Fit LLC.
                    We may periodically update this Privacy Policy, and reserve the right to make additions, deletions, and modifications without notice.  All such changes will be effective upon posting to the website.  The date of last update is posted at the bottom of this page, and you may refer to this date to determine whether these terms have recently been updated.  If you have questions, concerns, or suggestions about this policy, please email us at support@practicumfit.com, and we will do our best to address your concerns.</p>

                    <h2>Account Access</h2>
                    <p>Access to any and all data on our site is protected via password access.  Password management is performed by
                        our cloud provider on behalf of MatchFit LLC, and as a result we never store your password directly on our systems.
                        Your account will grant access to different features on this site, depending
                        whether you are a student or school/site/program administrator, and prevent access to your information by unauthorized users.
                        The access rules are set by your matching organization, and implemented by us. As a result Matchfit LLC never directly collects
                    or stores your credentials, and in accordance with credentialling best practices cannot provide them if requested.  If you
                    need to restore account access; use the <a href="/forgot-password">Forgot Password link</a>.</p>

                    <p>Please protect your account by keeping your password secure.  Do not ever share your password with any person,
                        including your school administrator.  If you forget your password, please reset it using the link on the login page.
                        If you lose access to the site for some reason and need help accessing your account, please contact us at support@practicumfit.com.</p>

                    <p>Should you wish to remove your information from our site, please contact support@practicumfit.com to delete your account.  Please note that any
                        account data that participates in logging, tracking, statistics or error analysis will be retained by our systems as long as it necessary
                        to do so to continue the normal operation of our site and our systems.
                        Rules for withdrawal from the match are set by your matching organization and enforced by our site implementation.
                        Generally, if we receive your request sufficiently in advance of your match, we will be able to fulfill your request.
                        If not, we may need to retain your account to maintain the integrity of the match process.</p>

                    <p>In no case will deleting your account allow you to break a match or receive a refund after the closing of the ranking window.
                        If you wish to withdraw from the match, please refer to your organization’s match policy.</p>

                    <h2>Information we collect, and how we use it</h2>
                    <h3>Information collected as a part of site function:</h3>
                    <p>We track user login data, browser type, and IP (Internet Protocol) addresses of all visitors to the site, regardless of whether you log in or not.
                        This information is used strictly for site analytics and to operate our site and systems including but not limited to error logging, trouble-shooting and testing; at no time does
                        MatchFit LLC share information with 3rd parties other than those used for running, hosting and distributing the InternFit and PracticumFit web properties.
                        </p>

                    <h3>Information input by users:</h3>
                    <p>Data input by users for the purpose of executing the match is securely using industry best practices; encryption at rest, and encryption in motion,
                        and made available in accordance with the rules set by your match organization.
                        This includes information manually typed or selected by users, as well as site and
                        school information imported or carried over from previous match years.  The purpose of this information is to allow
                        students to match to internship or practicum sites, while schools and consortia oversee and regulate the match
                        process pursuant to the match rules.</p>

                    <p>With the exception of match rankings and match results, site and school information is available to users within a client.
                        This allows students and schools to review sites, and allows users to contact sites and schools participating in the match.</p>

                    <p>With the exception of rank order lists, student information is made available to the student’s school administrators
                        (e.g. your Director of Clinical Training), to sites with whom you choose to share your data, and to your match organization’s administrators.
                        Schools will use this data to oversee your match process and may approve or restrict which sites you can apply to,
                        and is able to see which sites you have applied to and ranked.
                        Sites will use data you share with them to evaluate you for employment.</p>

                    <p>Rankings for the match are never shared with other students, unless we need to resolve a dispute; in this case we share the minimum
                        amount of data necessary to resolve the matter. For training sites, ranking data can be viewed by authorized administrators of
                        that training site or matching organization.</p>

                    <p>Match results are individually released to the site, school, and student involved in the match.  After the match, anonymized
                        statistics are generated about the match and may be publicly distributed.  We will use this information only to report on general trends
                        in the match process, monitor site function, and assist users with help inquiries.</p>

                    <h3>Payment information:</h3>
                    <p>We use an external payment processing service to process payment, we do not collect, use or store your payment information on Matchfit LLC's systems ever.
                        Matchfit keeps a record of the payment amount collected and date of the transaction to validate your payment status.</p>

                    <h3>How we store your information:</h3>
                    <p>MatchFit uses cloud based web hosting services to run the site and securely store your information.  All information is encrypted in motion via HTTPS,
                        and encrypted at rest. All datastores in our hosting infrastructure use KMS based hardware encryption to secure your information.</p>

                    <p>Collected information is stored on your computer (for example, in cookies, and your browser cache), and on our secure cloud-based servers.
                        Data is regularly backed up on the server, and may be used occasionally by Matchfit's development staff on local systems for the purposes
                        of testing and debugging.</p>

                    <h3>How we share your data with other users:</h3>
                    <p>Data is shared as agreed with the match organization you are participating through, and used internally by MatchFit for site maintenance
                        and user support, and with the hosting, networking, and help services we contract with to operate our platform, a list of which can be obtained by mailing
                        support@practicumfit.com. In no case do we share information to 3rd parties, nor do we sell user data for any purpose.</p>

                    <h3>Legal Limitations:</h3>
                    <p>Notwithstanding the above, we will comply with subpoenas from legitimate legal authorities to access current and past account information
                        stored on our sites.  In case of an investigation by a matching organization or school, or a match dispute, we will disclose limited data,
                        only to the extent necessary to resolve the matter. If further resolution is required, we will implement the decision of the matching organization.
                    </p>

                    <p>Last updated: November 20, 2020</p>
                </CardContent>
            </Card>
        </Page>
    )
}

export default Privacy
