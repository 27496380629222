import React from 'react';
import '../student/Student.css';
import SchoolForm from "./component/SchoolForm";
import SchoolList from "./component/SchoolList";
import {useParams} from "react-router-dom";

import {useAppContext} from "../lib/context"
import {LoginStateCard} from "../components/LoginStateCard";
import {Page} from "../components/Page";
import {requireOneOfGroup} from "../lib/util";
import Groups from "../model/Groups";
import {AuthenticationFailed} from "../components/AuthenticationFailed";
import useUserReadyService from "../user/lib/UserReadyService";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../Atoms";
import {clientAtom, loginStateAtom} from "../Atoms";

type SchoolParams = {
    id: string
}

const Schools = () => {
    const params = useParams<SchoolParams>()
    const {isDebug} = useAppContext();

    const loginState = useAtomValue(loginStateAtom)
    const client = useAtomValue(clientAtom)
    const userInformation = useAtomValue(userInformationAtom)

    const {useLoginStateCard, component}  = LoginStateCard(loginState, client)

    const userReady = useUserReadyService()

    isDebug && console.log("Schools")

    if (userReady && !requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin, Groups.clientAdmin, Groups.programAdmin])) {
        isDebug && console.log("Returning auth failed")
        return (<AuthenticationFailed/>)
    }

    return useLoginStateCard ? component : (
        <Page navName="School">
            {params.id ? (
                <div className="justify-content-center h-100">
                    <SchoolForm id={params.id}/>
                </div>
            ) : (<SchoolList/>)}
        </Page>
    )
}

export default Schools
