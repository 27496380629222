import MaterialTable from "@material-table/core";
import React, {useEffect, useMemo, useState} from "react";
import {api, API, apiError, apiResultParse} from '../../lib/Api'
import {useAppContext} from "../../lib/context";
import {Client, ClientCycle} from "../model/Client";
import useListClientCyclesService, {listClientCyclesKey} from "../lib/ClientCycleService";
import {RichElement} from "../../components/customTable/RichElement";
import {TableColumn} from "../../components/customTable/CustomTable";
import {
    confirmDeleteModal,
    errorMessageModal,
    messageModal,
    questionModal
} from "../../components/modal/InfoModal";
import SpinnerCard from "../../components/SpinnerCard";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";
import {DateTimePicker} from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import {DatePicker} from "@mui/x-date-pickers";
import {useQueryClient} from "@tanstack/react-query";
import {clientAtom} from "../../Atoms";
import {DialogType} from "../../components/modal/ModalProps";
import {FormControl, InputLabel, Select} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';

interface ClientCyclesTableProps {
}

const ClientCyclesTable: React.FC<ClientCyclesTableProps> = (props) => {
    const {setModal, isDebug} = useAppContext()
    const client = useAtomValue(clientAtom) as Client
    const userInformation = useAtomValue(userInformationAtom)

    const [state, setState] = useState<ClientCycle[]>([])

    const queryClient = useQueryClient()

    const clientCycles = useListClientCyclesService()

    useEffect(() => {
        if (clientCycles.isSuccess) {
            setState(clientCycles.data)
        }
    }, [clientCycles.isSuccess, clientCycles.data])

    const tableColumns: Array<TableColumn<ClientCycle>> = [
        {title: "ID", field: 'id', editable: "never"},
        {title: 'Name', 'field': 'cycleName'},
        {title: 'Match Type', 'field': 'matchType', editComponent: props => (
                <FormControl variant="filled" className="w-full">
                    <InputLabel id="match-type-label">Match Type</InputLabel>
                    <Select value={props.value} name="matchType" variant="outlined" onChange={e => props.onChange(e.target.value)}>
                        <MenuItem value="auto" key="auto">Auto</MenuItem>
                        <MenuItem value="manual" key="manual">Manual</MenuItem>
                    </Select>
                </FormControl>
            )},
        {title: 'Cycle Start', 'field': 'cycleStart', render: (row: ClientCycle) => <RichElement key={`${row.id}-cycleStart`} value={row.cycleStart}/>,
            editComponent: props => (
                <DatePicker
                    value={props.value && dayjs(props.value)}
                    onChange={e => props.onChange(e.toDate())}
                />)
        },
        {title: 'Cycle End', 'field': 'cycleEnd', render: (row: ClientCycle) => <RichElement key={`${row.id}-cycleEnd`} value={row.cycleEnd}/>,
            editComponent: props => (
                <DatePicker
                    value={props.value && dayjs(props.value)}
                    onChange={e => props.onChange(e.toDate())}
                />)
        },
        {title: 'Ranklist Lock Date', field: 'rankListLockDate', render: (row: ClientCycle) => <RichElement key={`${row.id}-rankListLockDate`} value={row.rankListLockDate}/>,
            editComponent: props => (
                <DateTimePicker
                    value={props.value && dayjs(props.value)}
                    onChange={e => props.onChange(e.toDate())}
                />)
        },
        {title: 'Match Publish Date', field: 'matchPublishDate', render: (row: ClientCycle) => <RichElement key={`${row.id}-matchPublishDate`} value={row.matchPublishDate}/>,
            editComponent: props => (
                <DateTimePicker
                    value={props.value && dayjs(props.value)}
                    onChange={e => props.onChange(e.toDate())}
                />)
        },
        {title: 'Clearing House Date', field: 'clearingHouseDate',
            render: (row: ClientCycle) => <RichElement key={`${row.id}-clearingHouseDate`} value={row.clearingHouseDate}/>,
            editComponent: props => (
                <DateTimePicker
                    value={props.value && dayjs(props.value)}
                    onChange={e => props.onChange(e.toDate())}
                />)
        }
    ]

    const handleDelete: (oldData: ClientCycle) => Promise<void> = useMemo(() => oldData =>
        new Promise<void>((resolve, reject) => {
            if (oldData.id && setModal) {
                setModal(confirmDeleteModal<ClientCycle>(oldData, (action: string, clientCycle: ClientCycle) => {
                    return action === "delete" && clientCycle.id && api<any, undefined>(API.deleteClientCycle(clientCycle.id), userInformation, client.id)
                        .then(result => {
                            isDebug && console.log("Deleted program")
                            setState(prevState => prevState.filter(x => x.id !== oldData.id))
                            queryClient.invalidateQueries(listClientCyclesKey(client.id))

                            resolve()
                        }).catch(error => {
                            apiError(error, setModal)
                            reject()
                        })
                }, "Deleting this cycle will delete ALL related cycle data including sites, students, and match data, this operation cannot be undone. Confirm?"))
            }
        }), [setModal, userInformation, isDebug, queryClient, client.id])

    const handleUpdate: (newData: ClientCycle, oldData: ClientCycle) => Promise<any> = useMemo(() => (newData, oldData) =>
        new Promise<ClientCycle>((resolve, reject) => {
            setState(prevState => [...(prevState.map(x => x.id === oldData.id ? apiResultParse(newData) : x))])

            if (oldData.id) {
                api<ClientCycle, ClientCycle>(API.updateClientCycle(oldData.id), userInformation, client.id, newData).catch((x) => {
                    apiError(x, setModal)
                    reject()
                }).then(() => {
                    queryClient.invalidateQueries(listClientCyclesKey(client.id))
                    resolve(newData)
                })
            } else {
                setModal && setModal(errorMessageModal("Bad state", "This row has no ID for some reason - this is an error"))
                reject()
            }
        }), [setModal, userInformation, client.id, setState, queryClient])

    const handleAdd: (newData: ClientCycle) => Promise<ClientCycle> = useMemo(() => (newData: ClientCycle) => new Promise<ClientCycle>((resolve, reject) => {
        console.log("New cycle data", newData)
        api<ClientCycle, ClientCycle>(API.addClientCycle(), userInformation, client.id, newData).then(result => {
            setState((prevState) => (
                [...prevState, result.data]
            ))
            queryClient.invalidateQueries(listClientCyclesKey(client.id))
            resolve(newData)
        }).catch(error => {
            reject()
            apiError(error, setModal)
        })
    }), [setState, userInformation, client.id, setModal, queryClient])

    const migrateSitesHandler = (event: any, rowData: ClientCycle | ClientCycle[]) => {
        if (typeof(rowData) === "object") {
            setModal && setModal(questionModal<any>({}, "Confirm Migrate", "Are you sure you want to migrate all sites from the previous cycle to the current cycle?", DialogType.Confirm, (action, data) => {
            if (action === "confirm") {
                api<any, undefined>(API.migrateSites(), userInformation, client.id).then(result => {
                    setModal && setModal(messageModal("Sites Migrated", "All the sites have been migrated to the new cycle"))
                }).catch(err => {
                    apiError(err, setModal)
                })

            }
            }))
        }
    }

    const migrateStudentsHandler = (event: any, rowData: ClientCycle | ClientCycle[]) => {
        if (typeof(rowData) === "object") {
            setModal && setModal(questionModal<any>({}, "Confirm Migrate", "Are you sure you want to migrate all students from the previous cycle to the current cycle?", DialogType.Confirm, (action, data) => {
                if (action === "confirm") {
                    api<any, undefined>(API.migrateStudents(), userInformation, client.id).then(result => {
                        setModal && setModal(messageModal("Students Migrated", "All the students have been migrated to the new cycle"))
                    }).catch(err => {
                        apiError(err, setModal)
                    })

                }
            }))
        }
    }

    return (clientCycles.isSuccess ?
        <MaterialTable title="Manage Cycles" columns={tableColumns} data={state}
                       actions={[
                           {icon: 'change_circle', tooltip: 'Migrate Sites', onClick: migrateSitesHandler },
                           {icon: () => <PersonAddAltIcon/>, tooltip: 'Migrate Students', onClick: migrateStudentsHandler },
                       ]}
                       options={{
                           paging: false
                       }}
                       editable={{
                           onRowAdd: (newData: any) => handleAdd(newData as ClientCycle),
                           onRowUpdate: (oldData: any, newData: any) => handleUpdate(oldData as ClientCycle, newData as ClientCycle),
                           onRowDelete: (oldData: any) => handleDelete(oldData as ClientCycle)
                       }}
        /> : <SpinnerCard message="Loading Client Cycles..."/>
    )
}

export default ClientCyclesTable;