import React, {useEffect} from 'react';
import {useAppContext} from "./lib/context";
import Groups from "./model/Groups";
import {requireGroup} from "./lib/util";
import {PageBlurb} from "./components/PageBlurb";
import {Box} from "@mui/material";
import {Client} from "./client/model/Client";
import {messageModal} from "./components/modal/InfoModal";
import SpinnerCard from "./components/SpinnerCard";
import {
    EmptyObject,
    LoggedInComponent,
    LoggedInComponentWrapper,
    WrappedLoggedInContextProps
} from "./components/LoggedInPage";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "./Atoms";
import {loginStateAtom} from "./Atoms";
import {OutstandingOffers} from "./order/components/OutstandingOffers";


const SiteAdminBlurb = () => (
    <div>
        As a Site Administrator, you can:
        <ul>
            <li>edit your Agency Profile for the SCAPTP Directory, on the Profile page</li>
            <li>create Tracks, which students will apply to and rank, on the Tracks page</li>
            <li>initiate affiliations between your Tracks and SCAPTP member School Programs on
                the Track Relationships page
            </li>
        </ul>
        A few notes:
        <ul>
            <li>If you manage more than one Training Site, or have more than one track you are
                hiring into, use the drop-downs on each page to manage Profiles, Tracks, and
                Relationships separately for each of the Sties and Tracks that you manage.
            </li>
            <li>Track Relationship requests won't be accepted by schools until School Program
                Administrators log into PracticumFit starting Nov. 9. You can initiate the
                requests now for them to see the first time they log in.
            </li>
            <li>When they log in, some Schools may choose to divide their students into multiple
                'Programs' to group cohort years, or PsyD and PhD students. Sites and schools
                may choose to affiliate a different set of Tracks to each Program at their
                school.
            </li>
            <li>Menu options to browse the SCAPTP directory, and rank students for the match,
                will appear on the site in future weeks.
            </li>
        </ul>
    </div>
)

const StudentBlurb: React.FC<{ client: Client | undefined }> = ({client}) => {
    const userInformation = useAtomValue(userInformationAtom)

    return (
        <Box>
            <p>You are a member
                of {userInformation?.student?.program?.name} at {userInformation?.student?.school?.name}.</p>
            <p>A brief overview of the PracticumFit match process for students:</p>

            {userInformation && client &&
                <PageBlurb name="home" roleSpecific={true}/>
            }

            <p>If you have any questions, please contact us at {client?.settings?.clientSupportEmail}</p>
        </Box>
    )
}

const HomePageWrapper: LoggedInComponentWrapper = (context: WrappedLoggedInContextProps) => {
    const {isDebug} = useAppContext()
    const loginState = useAtomValue(loginStateAtom)
    if (isDebug) {
        return context.state === "ready" ? <Home {...context}/> :
            <SpinnerCard message={`Home Loading... ${context.state} ${loginState}`}/>
    } else {
        return context.state === "ready" ? <Home {...context}/> :
            <SpinnerCard message={`Home Loading...`}/>
    }
}


const Home: LoggedInComponent<EmptyObject> = (context) => {
    const {userInformation, client} = context
    const {isDebug, setModal} = useAppContext()

    isDebug && console.log("Rendering Home", context)

    useEffect(() => {
            isDebug && console.log("Effecting title change")
            Array.from(document.getElementsByTagName("title")).map(x => x.innerHTML = client?.settings.clientName + " - " + client?.settings.siteName)
    }, [isDebug, client])

    useEffect(() => {
            if (userInformation.userGroups?.includes(Groups.siteAdmin)) {
                if (userInformation.sites?.length === 0) {
                    setModal && setModal(messageModal("No Sites Associated", `Sorry, you're not affiliated with any agencies, please contact your ${client.settings.clientName} administrator.`))
                }
                else {
                    isDebug && console.log("Site admin has sites", userInformation.sites)
                }
            }

            if (userInformation.userGroups?.includes(Groups.programAdmin)) {
                if (userInformation.programs?.length === 0) {
                    setModal && setModal(messageModal("No Programs Associated", `Sorry, you're not affiliated with any schools, please contact your ${client.settings.clientName} administrator.`))
                }
                else {
                    isDebug && console.log("Program admin has programs", userInformation.programs)
                }
            }
    }, [userInformation, client, setModal, isDebug])

    return <>
                <PageBlurb name="welcome_page" roleSpecific={true}/>
                {(requireGroup(userInformation?.userGroups, Groups.siteAdmin) && client.id === 3 &&
                    <SiteAdminBlurb/>
                )}
                {requireGroup(userInformation?.userGroups, Groups.student) && <StudentBlurb client={client}/>}

            {!requireGroup(userInformation?.userGroups, Groups.student) && <OutstandingOffers/>}
        </>
}

export default HomePageWrapper;
