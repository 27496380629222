import React from 'react';
import {Page, PageBody, PageHeader} from "./components/Page";
import {Box} from "@mui/material";
import {PageBlurb} from "./components/PageBlurb";
import SpinnerCard from "./components/SpinnerCard";
import {useAtomValue} from "jotai";

import {clientAtom} from "./Atoms";

export const HowItWorks: React.FC = () => {
    const client = useAtomValue(clientAtom)

    return client?.settings?.siteName ? <Page navName="Home" footerPosition="fixed">
            <PageHeader title={`${client?.settings.siteName} - How it Works`}/>
            <PageBody>
                <Box>
                    <PageBlurb name="how_it_works" roleSpecific={true}/>
                </Box>
            </PageBody>
        </Page> : <SpinnerCard message="Loading..."/>
}
