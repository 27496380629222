import React, {ReactElement} from "react";
import {SvgIconComponent} from "@mui/icons-material";

export enum DialogType {
    Message, Confirm, DeleteConfirm
}

export enum ModalAction {
    Cancel = "cancel",
    Delete = "delete",
    Close = "close",
    Confirm = "confirm"
}
export interface ModalProps {
    isOpen: boolean,
    title: string,
    message: string,
    icon?: SvgIconComponent,
    iconColor?: string,
    onClose?: (s: string) => void,
    dialogType: DialogType,
    component?: ReactElement,
    dialogActionComponents?: (props: DialogActionProps) => ReactElement
}
export interface DialogActionProps {
    handleClose: (e: React.MouseEvent<HTMLButtonElement>, action: string) => void
}
