import {useState} from "react";

export const useLocalStorage = (props: {key: string, value: string}): [string, (value: string) => void] => {

    let value = props.value
    if (localStorage.getItem(props.key)) {
        value  = localStorage.getItem(props.key) || props.value
    }
    else {
        localStorage.setItem(props.key, props.value)
    }

    console.log("Value", value)
    const [valueState, setValueState] = useState<string>(value)
    const setLocalStorage = (value: string) => {
        localStorage.setItem(props.key, value)
        setValueState(value)
    }

    return [valueState, setLocalStorage]
}