import React, {FC, useMemo} from "react"

import {Client, PacketSlotDefinition} from "../../client/model/Client";
import {useAppContext} from "../../lib/context";
import {PacketRef, StudentApplication, StudentMaterialsDocument} from "../model/StudentMaterials";
import {DocumentDisplay} from "./DocumentDisplay";
import {Box, Button, Grid, Link, Tooltip, Typography} from "@mui/material";
import {API, getApiUrl} from "../../lib/Api";
import GetAppIcon from "@mui/icons-material/GetApp";
import {InternshipSiteId, InternshipSiteTrackId} from "../../internship-site/model/Organization";
import {RequirementFulfilled} from "./RequirementFulfilled";
import {StoredDocument} from "../model/Document";
import {StudentUserInformation} from "../../login/model/UserInformation";
import {UploadDocumentDropzoneControl} from "./UploadDocumentDropzoneControl";
import {Optional} from "../../lib/Optional";

export const SelectSpecificRequiredDocument: FC<{
    client: Client,
    userInformation: StudentUserInformation,
    slotDefinition: PacketSlotDefinition,
    siteId: InternshipSiteId,
    trackId: InternshipSiteTrackId | undefined,
    editable: boolean,
    packetRef: PacketRef | undefined,
    setPacketRef: (slotId: string, packetRef: PacketRef) => void,
    applicationPacket: StudentApplication,
    saveDraft: () => void}> =
    ({client, userInformation, slotDefinition, applicationPacket, siteId, trackId, packetRef, setPacketRef, saveDraft, editable}) => {
        const {isDebug} = useAppContext()

        isDebug && console.log("User information", userInformation)

        isDebug && console.log("Application packet", applicationPacket)

        // Change this to packet
        const doc =
            Optional.apply(applicationPacket)
                .filter(e => e.siteId === siteId && e.trackId === trackId)
                .map(x => x.packetRefs)
                .flatMap(e => Optional.apply(e.find(x => x.packetSlotId === slotDefinition.id)))
                .flatMap(x => Optional.apply(userInformation.student.studentApplicationPackets.documents.find(doc => doc.document.id === x.documentId)))

        const matchingDoc: StudentMaterialsDocument | undefined = useMemo(() =>  {
            const doc = userInformation.student.materials.documents.find(x => x.slotId === slotDefinition.id)
            if (slotDefinition.source === "materials") {
                //setPacketRef(slotDefinition.id, {...packetRef, storageRef: doc?.document.storageRef, documentId: doc?.document.id})
            }
            return doc
        }, [userInformation, slotDefinition])

        isDebug && console.log("Specific doc", slotDefinition)
        isDebug && console.log("Materials", userInformation.student.materials.documents)
        isDebug && console.log("Matching doc", matchingDoc)

        const isFulfilled = () => {
            if (slotDefinition.source === "direct") {
                return doc.map(e => e.document.storageRef).isPresent()
            } else {
                return matchingDoc?.document !== undefined
            }
        }

        const completeUpload = (doc: StoredDocument) => {
            isDebug && console.log("Updating packet refs to include", doc)
            setPacketRef(slotDefinition.id, {
                id: undefined,
                storageRef: doc.storageRef,
                documentId: doc.id,
                packetSlotId: slotDefinition.id,
                createdTimestamp: new Date(),
                siteId: siteId,
                trackId: trackId,
                submitted: false
            })
        }

        return slotDefinition.source === "direct" ? (
            <Box className="relative">
                <Box sx={{float: "left", width: "calc(100%)"}}>
                <Typography variant="h5">{slotDefinition.name}</Typography>
                {doc.map(e => e.document.storageRef).isPresent() ? <DocumentDisplay document={doc.get()} uploadType="packet" editable={editable}/> :
                    <UploadDocumentDropzoneControl guid={userInformation.guid} userInformation={userInformation} client={client} slotDefinition={slotDefinition} completeUpload={completeUpload} siteId={siteId} trackId={trackId} uploadType="packet"
                    />

                }
                </Box>
                <RequirementFulfilled isFulfilled={isFulfilled}/>
                <div style={{clear: "both"}}>&nbsp;</div>
            </Box>
        ) : <Box className="relative">
            <Box sx={{float: "left", width: "calc(100%)"}} aria-label="Specific Required Document Container">
            <Typography variant="h5">{slotDefinition.name}</Typography>
            {matchingDoc?.document ?
                <Grid container p={1}>
                    <Grid item xs={12} md={4} lg={4}>
                        <Box>Name: {matchingDoc.document.name}</Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4}>
                        <Box>Filename: {matchingDoc.document.originFilename}</Box>
                    </Grid>
                    <Grid item xs={12} md={4} lg={4} display="flex" justifyContent="flex-end">
                        <Tooltip title={"Download File"}>
                            <Button href={getApiUrl() + API.downloadMaterial(userInformation.guid, matchingDoc.document.id).location} target="_blank" download color="primary"
                                    endIcon={<GetAppIcon/>}>Download</Button>
                        </Tooltip>
                    </Grid>
                </Grid>
                : <Box>No document uploaded, please visit the <Link href="/studentApplication">Application Materials Page</Link> to add additional documents.</Box>
            }
            </Box>
            <RequirementFulfilled isFulfilled={isFulfilled}/>
            <div style={{clear: "both"}}>&nbsp;</div>
        </Box>
    }