import React, {useCallback, useRef, useState} from 'react'
import {useDropzone} from 'react-dropzone'
import {Box, Button} from "@mui/material";
import BackupIcon from '@mui/icons-material/Backup';
import {api} from "../../lib/Api";
import {StoredDocument} from "../model/Document";
import {endpoint} from "./UploadDocumentControl";
import {useAppContext} from "../../lib/context";
import {Client, MaterialSlotDefinition, PacketSlotDefinition} from "../../client/model/Client";
import {UserInformation} from "../../login/model/UserInformation";
import SpinnerCard from "../../components/SpinnerCard";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {InternshipSiteId, InternshipSiteTrackId} from "../../internship-site/model/Organization";
import {refreshUser} from "../../Atoms";
import {useTranslation} from "react-i18next";
import {appConfig} from "../../conf/conf";
import {useModalService} from "../../components/modal/ModalService";

interface UploadDocumentDropzoneControlProps {
    slotDefinition: MaterialSlotDefinition | PacketSlotDefinition,
    completeUpload?: (doc: StoredDocument) => void,
    guid: string,
    userInformation: UserInformation | undefined,
    client: Client,
    siteId?: InternshipSiteId,
    trackId?: InternshipSiteTrackId,
    uploadType: string,
    triggerRefreshUser?: boolean
}

export const UploadDocumentDropzoneControl = (props: UploadDocumentDropzoneControlProps) => {
    const {userInformation, client, guid, slotDefinition, siteId, trackId, uploadType} = props

    const {isDebug} = useAppContext()

    const [isUploading, setIsUploading] = useState<boolean>()
    const [file, setFile] = useState<File>()
    const [blob, setBlob] = useState<Blob>()

    const formRef = useRef<HTMLFormElement>(null)
    const documentType = slotDefinition.slotGroup

    const {t} = useTranslation()
    const queryClient = useQueryClient()

    const modal = useModalService()

    const uploadMutate = useMutation({
        mutationFn: (formData: FormData) => api<StoredDocument, any>(endpoint(uploadType)(guid), userInformation, client.id, formData, "multipart/form-data")
                .then<StoredDocument>((response) => response.data)
            .catch((error) => {
                console.log(error)
                modal.errorMessage("An API error occurred", "An error occurred while uploading the document. Please reload the page and try again.")
            })
    })

    const doUpload = (blob: Blob | undefined, fileName: string | undefined) => {
        console.log("Replace doc clicked")
        if (formRef.current && blob) {
            const formData = new FormData(formRef.current)
            siteId && formData.append("siteId", siteId.toString())
            trackId && formData.append("trackId", trackId.toString())
            setIsUploading(true)
            const fileObject = new File([blob], fileName || "Unknown", {type: "application/pdf"})

            if (fileObject.size > appConfig.maxFileSize) {
                modal.errorMessage(t("fileUpload.modal.fileTooLargeTitle"), t("fileUpload.modal.fileTooLargeMessage", {maxFileSize: appConfig.maxFileSize}))
                setIsUploading(false)
                return
            }

            formData.append("document", fileObject)

            isDebug && console.log("Trigger Refresh", props.triggerRefreshUser)
            isDebug && console.log("Form Data", formData.entries())
            uploadMutate.mutateAsync(formData)
                .then<StoredDocument>((result) => {
                    if (result) {
                        if (props.triggerRefreshUser !== false) {
                            console.log("Refreshing user HERE")
                            return refreshUser(queryClient).then<StoredDocument>(() => {
                                console.log('Success:', result);
                                return new Promise<StoredDocument>((resolve) => resolve(result))
                            }).catch((error) => {
                                console.log("Upload document failed", error)
                                return new Promise((resolve, reject) => reject(error))
                            })
                        } else {
                            console.log("Trigger is Bypassing refresh")
                            return new Promise<StoredDocument>((resolve) => resolve(result))
                        }
                    }
                    else {
                        throw new Error("Result from mutate upload mutate was void")
                    }
                })
                .then<StoredDocument>(result => {
                    props.completeUpload && props.completeUpload(result)
                    return new Promise<StoredDocument>((resolve) => resolve(result))
                })
                .then(result => {
                    setIsUploading(false)
                })
                    .catch((error) => {
                        setIsUploading(false)
                        console.error('Error:', error);
                    });

        }
    }

    const onDrop = useCallback((acceptedFiles) => {
        // TODO This is a loop but the internal set one element only
        acceptedFiles.forEach((file: File) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                const binaryStr = reader.result

                console.log("Read data")
                if (binaryStr) {
                    console.log("Got result")
                    var blob = new Blob([binaryStr], {type: "application/pdf"});
                    setBlob(blob)
                    setFile(file)
                }
            }

            reader.readAsArrayBuffer(file)
        })

    }, [])

    const {getRootProps, getInputProps} = useDropzone({onDrop: onDrop, accept: ["application/pdf",".pdf"]})


    return (
        <Box sx={{width: "300px", height: "140px", margin: "0 auto"}} p={1} m={0}>
            {isUploading ? <Box m={0} p={0} sx={{height: "100%", margin: "auto", display: "block", textAlign: "center", color: "#000000", border: "2px dotted rgba(0, 0, 0, 0.4)", borderRadius: "4px", backgroundColor: "rgb(255,255,255)"}}>
                    <SpinnerCard m={0} message={`${t("spinner.uploading")}`}/> </Box>:
                <form encType="multipart/form-data" ref={formRef} style={{height: "100%"}}>
                    <input name="guid" value={props.guid || ''} type="hidden"/>
                    <input name="slotId" value={slotDefinition.id} type="hidden"/>
                    <input name="documentName" value="" type="hidden"/>
                    <input name="documentType" value={documentType} type="hidden"/>
                    <div {...getRootProps()} style={{height: "100%"}}>
                        <input {...getInputProps()} />
                        <Box m={0} sx={{height: "100%", display: "flex", textAlign: "center", color: "#000000", border: "2px dotted rgba(0, 0, 0, 0.4)", borderRadius: "10px", backgroundColor: "rgba(255,255,255,0.7)"}}>
                            {file ? <Box display="flex" flexWrap="wrap" sx={{
                                    margin: "auto",
                                    justifyContent: "center"
                                }}>
                                <Box sx={{"padding": "5px 5px 0 0", overflow: "hidden"}}>{file.name}</Box>
                                <Box>
                                    <CheckCircleIcon/>
                                </Box>
                                    {blob && file &&
                                        <Box >
                                            <Button style={{margin: "8px auto", display: "block"}} onClick={e => { e.stopPropagation(); doUpload(blob, file?.name)}}
                                                variant="contained">Upload Document</Button>
                                        </Box>
                                    }
                            </Box> :
                                <Box sx={{display: "block", margin: "auto", height: "50px"}}>
                                Drop files here or Click to Upload
                                <Box sx={{margin: "auto"}}><BackupIcon/></Box>
                            </Box>}
                        </Box>
                    </div>
                </form>
            }
        </Box>
    )
}