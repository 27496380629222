import {useAppContext} from "../lib/context";
import React, {useState} from "react";
import {API, api, BodyMethod} from "../lib/Api";
import {AuditHistoryEntry} from "./model/AuditHistoryEntry";
import MaterialTable from "@material-table/core";
import {TableColumn} from "../components/customTable/CustomTable";
import {Box, Card, CardContent} from "@mui/material";
import {Page} from "../components/Page";
import {RichElement} from "../components/customTable/RichElement";
import {asSelectItem, AuditOperationType} from "./model/AuditOperationType";
import {ItemSelect} from "../components/ItemSelect";
import {requireOneOfGroup} from "../lib/util";
import Groups from "../model/Groups";
import {AuthenticationFailed} from "../components/AuthenticationFailed";
import useUserReadyService from "../user/lib/UserReadyService";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../Atoms";
import {clientAtom} from "../Atoms";
import {useQuery} from "@tanstack/react-query";
import {UserInformation} from "../login/model/UserInformation";
import SpinnerCard from "../components/SpinnerCard";
import {UseQueryResult} from "@tanstack/react-query/src/types";
import {messageModal} from "../components/modal/InfoModal";

const Audit = () => {
    const {isDebug, setModal} = useAppContext();
    const client = useAtomValue(clientAtom)
    const userInformation: UserInformation = useAtomValue(userInformationAtom)

    const [operationType, setOperationType] = useState<AuditOperationType>();

    const userReady = useUserReadyService()

    const auditLogQuery = useQuery({
        queryFn: () => api<AuditHistoryEntry[], undefined>(API.auditLog(operationType?.name, 0, 1000), userInformation, client.id)
            .then(x => x.data),
        queryKey: ["auditLog", operationType?.name, client.id],
        staleTime: 86400000
    })

    const auditOperationsQuery: UseQueryResult<AuditOperationType[]> = useQuery({
        queryFn: () => api<AuditOperationType[], undefined>(API.auditOperations(), userInformation, client.id)
            .then(x => x.data),
        queryKey: ["auditOperations", client.id],
        staleTime: 86400000
    })

    if (userReady && !requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin])) {
        isDebug && console.log("Returning auth failed")
        return (<AuthenticationFailed/>)
    }

    const columns: Array<TableColumn<AuditHistoryEntry>> = ([
        {
            title: "id",
            field: "id",
            width: 50
        }, {
            title: "createdTimestamp",
            field: "createdTimestamp",
            render: row => (<RichElement value={row.createdTimestamp}/>)
        },
        {
            title: "operation",
            field: "operationLabel"
        }, {
            title: "user",
            field: "userEmail"
        }, {
            title: "Client ID",
            field: "clientId",
            width: 100
        }, {
            title: "Group",
            field: "groups"
        }, {
            title: "URL",
            field: "doUrl"
        }, {
            title: "Payload",
            field: "doPayload",
            render: row => (
                <Box component="div" textOverflow="ellipsis" style={{"maxHeight": "300px"}} overflow="hidden">
                    {row.doPayload}
                </Box>
            )
        }
    ])

    return auditOperationsQuery.isSuccess && auditLogQuery.isSuccess ? (
        <Page navName="audit">
            <Card>
                <CardContent>
                    <ItemSelect items={auditOperationsQuery.data.map(x => asSelectItem(x))}
                                item={operationType && asSelectItem(operationType)} componentName={"Audit Operation Types"} componentId={"audit-operation-types-select"}
                                setItem={e => setOperationType({name: e.value})}/>
                    <MaterialTable columns={columns} data={auditLogQuery.data} options={{pageSize: 50}} actions={
                        [
                            {
                                icon: 'replay',
                                tooltip: 'Replay Change',
                                onClick: (event, rowData) => {
                                    api({
                                        location: (rowData as AuditHistoryEntry).doUrl.substring((rowData as AuditHistoryEntry).doUrl.indexOf("api") + 4),
                                        method: (rowData as AuditHistoryEntry).doMethod as BodyMethod,
                                        validator: undefined
                                    }, userInformation, (rowData as AuditHistoryEntry).clientId).then(x => {
                                        setModal && setModal(messageModal("Info", "Replay Successful"))
                                    })
                                }
                            }
                            ]
                    }/>
                </CardContent>
            </Card>
        </Page>
    ) : <SpinnerCard message="Loading audit log..."/>
}

export default Audit
