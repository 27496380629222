import React from "react";
import {useAppContext} from "../lib/context";
import {
    QuestionId,
    QuestionnaireElement,
    QuestionnaireId,
    QuestionProps
} from "./model/Questionnaire";
import QuestionnaireTextInput from "./component/QuestionnaireTextInput";
import QuestionnaireTextArea from "./component/QuestionnaireTextArea";
import QuestionnaireSelect from "./component/QuestionnaireSelect";
import QuestionnaireMultiSelect from "./component/QuestionnaireMuliSelect";
import QuestionnaireDate from "./component/QuestionnaireDate";
import {LoginStateCard} from "../components/LoginStateCard";
import {Box, Fab, Tooltip} from "@mui/material";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {QuestionAnswerSave} from "./model/QuestionnaireSubmissionElement";
import {isPresent} from "../components/customTable/util";
import {makeStyles} from "tss-react/mui";
import {useAtomValue} from "jotai";
import {clientAtom, loginStateAtom} from "../Atoms";
import QuestionnaireTableComponent from "./component/QuestionnaireTableComponent";
import {chompQuestionnaireEntries} from "./lib/ChompElements";
import {isEditable} from "./component/lib/questionnaireUtil";
import {useTranslation} from "react-i18next";

interface QuestionnaireSummaryDisplayProps {
    questionnaire: QuestionnaireElement[] | undefined
}

export const QuestionnaireSummaryDisplay = (props: QuestionnaireSummaryDisplayProps) => {
    if (props.questionnaire) {
        const emptyAnswers = props.questionnaire?.filter(x => x.question?.isRequired === true)
            .filter(x => !isPresent(x,"answer.answer"))

        if (emptyAnswers.length > 0) {
            return (
                    <Box m={1}>
                        <Tooltip title="This are required questions that have no answers yet" style={{"marginRight": "5px", "marginTop": "-3px"}}>
                            <ErrorOutlineIcon className="stop" />
                        </Tooltip>
                        {emptyAnswers.length} required answers missing
                    </Box>
            )
        } else return <React.Fragment/>
    }
    else {
        return <React.Fragment/>
    }
}

interface QuestionnaireDisplayProps {
    questionnaireId: QuestionnaireId
    questionnaireElements: QuestionnaireElement[] | undefined
    handleSubmit?: (qas: QuestionAnswerSave[], draft: boolean) => void
    onChange?: (saveData: QuestionAnswerSave[]) => void,
    requiredBlocking?: boolean
}

const QuestionnaireDisplay = (props: QuestionnaireDisplayProps) => {
    const {isDebug} = useAppContext();

    const loginState = useAtomValue(loginStateAtom)
    const client = useAtomValue(clientAtom)

    const {questionnaireElements} = props;

    const {t} = useTranslation()

    const formRef = React.useRef<HTMLFormElement>(null)

    isDebug && console.log("Rendering Questionnaire", questionnaireElements)

    const data = chompQuestionnaireEntries(questionnaireElements || [])

    isDebug && console.log("Chomped Questionnaire", data)

    const {useLoginStateCard, component} = LoginStateCard(loginState, client)

    const useStyles = makeStyles()(theme => ({
        fab: {
            position: 'fixed',
            bottom: theme.spacing(4),
            right: theme.spacing(8),
        },
    }));

    const {classes} = useStyles()

    const extractFromValue = (name: string, entry: FormDataEntryValue) => {
        const questionId = parseInt(name.substr(name.indexOf("-") + 1))
        if (typeof (entry) === "string") {
            return {
                questionId: questionId,
                answer: entry
            } as QuestionAnswerSave
        } else return {
            questionId: questionId,
            answer: null
        }
    }

    const generateQuestionSaveData: () => QuestionAnswerSave[] = () => {
        if (formRef.current) {
            const formData = new FormData(formRef.current)
            return Array.from(formData.entries()).map((e, i) => {
                const [key, value] = e
                isDebug && console.log("Type", typeof (value))

                return (extractFromValue(key, value) as QuestionAnswerSave)
            })
        } else return []
    }

    const localFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        if (formRef.current) {
            props.handleSubmit && props.handleSubmit(generateQuestionSaveData(), false)
        }
    }

    const changeElement = (id: QuestionId) => (value: string) => {
        isDebug && console.log("Changing value for ", id, value)
        if (formRef.current && props.onChange) {
            props.onChange([
                ...generateQuestionSaveData().filter(x => x.questionId !== id), {
                        questionId: id,
                        answer: value
                    }] as QuestionAnswerSave[])
        }
    }

    const renderQuestionElement = (x: QuestionnaireElement) => {
        switch (x.elementType.type) {
            case 'Question':
                return x.question ? (<QuestionComponent key={x.id} questionnaireElement={x}
                                                        onChange={changeElement(x.question.id)} requiredBlocking={props.requiredBlocking}/>) : (
                    <React.Fragment key={x.id}>No Question</React.Fragment>)
            case 'Short Section Heading':
                return (<h2 key={x.id}>{x.content}</h2>)
            case 'HTML Content':
                return (<div key={x.id} dangerouslySetInnerHTML={{
                    __html: x.content || ""
                }}></div>)
            case 'Subheading':
                return (<h3 key={x.id}>{x.content}</h3>)
            case 'Formatting Line Break':
                return (<hr key={x.id}></hr>)
            default:
                isDebug && console.log(x)
                return (<span>{x.elementType.type}</span>)
        }
    }

    return useLoginStateCard ? component : (
        <div className="container">
            <QuestionnaireSummaryDisplay questionnaire={questionnaireElements}/>

            <form onSubmit={localFormSubmit} ref={formRef} key={`form-${props.questionnaireId}`}>
                {data.map(x =>
                    x.fold(l => renderQuestionElement(l), r =>
                        <QuestionnaireTableComponent table={r} columnHeadings={r.parsedParameters.headings}
                                                     key={r.id.toString()} columns={r.parsedParameters.columnCount}
                                                     onChange={(v, i) => changeElement(i)}/>
                    )
                )}
                <Fab type="submit" variant="extended" id="questionnaire-submit" className={classes.fab}>{t("questionnaire.buttons.saveAnswers")}</Fab>
            </form>
        </div>
    )
}

const QuestionComponent = (props: QuestionProps) => {
    const q = props.questionnaireElement.question
    const a  = props.questionnaireElement.answer

    const id = props.questionnaireElement.id

    if (q) {
        switch (q.type.questionType) {
            case 'Short Text':
            case 'Link':
                return (<QuestionnaireTextInput key={id} question={q} answer={a} onChange={props.onChange} editable={isEditable(props.questionnaireElement)} requiredBlocking={props.requiredBlocking}/>)
            case 'Paragraph':
                return (<QuestionnaireTextArea key={id} question={q} answer={a} onChange={props.onChange} editable={isEditable(props.questionnaireElement)} requiredBlocking={props.requiredBlocking}/>)
            case 'Select':
                return (<QuestionnaireSelect key={id} question={q} answer={a} onChange={props.onChange} editable={isEditable(props.questionnaireElement)} requiredBlocking={props.requiredBlocking}/>)
            case 'Multi-Select':
                return (<QuestionnaireMultiSelect key={id} question={q} answer={a} onChange={props.onChange} editable={isEditable(props.questionnaireElement)} requiredBlocking={props.requiredBlocking}/>)
            case 'Date':
                return (<QuestionnaireDate key={id} question={q} answer={a} onChange={props.onChange} editable={isEditable(props.questionnaireElement)} requiredBlocking={props.requiredBlocking}/>)
            default:
                return (<span>{q.type.questionType}</span>)
        }
    } else return (<React.Fragment>No Question Data</React.Fragment>)
}

export default QuestionnaireDisplay
