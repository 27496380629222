import React from "react";
import {StudentLoggedInComponent} from "../../../components/LoggedInPage";
import {PacketSlotEntry} from "./PacketSlotEntry";
import {SelectSpecificRequiredDocument} from "../../lib/SelectSpecificRequiredDocument";
import {ClientSettingsOps, SlotGroups} from "../../../client/model/Client";
import {StudentOps} from "../../../student/model/Student";
import {PacketRef, StudentApplication} from "../../model/StudentMaterials";
import {PacketState} from "../Packet";
import {useAppContext} from "../../../lib/context";

export interface SpecificDocumentsSelectionProps {
    application: StudentApplication
    updatePacket: (f: (packetState: PacketState) => PacketState) => void,
    saveDraft: () => void,
}

export const SpecificDocumentsSelection: StudentLoggedInComponent<SpecificDocumentsSelectionProps> = (props) => {
    const {isDebug} = useAppContext()

    const specifics = ClientSettingsOps.packetSlotsByType(props.client.settings, SlotGroups.specificRequiredDocument)
    const packetRefs = StudentOps.packetSlotEntriesByType(props.client.settings, props.application.packetRefs || [])(SlotGroups.specificRequiredDocument)
    isDebug && console.log("Displaying specifics for slots", specifics)

    const editable = ClientSettingsOps.isInSubmissionWindow(props.client.settings)

    isDebug && console.log("in submission editable", editable)

    const setPacketRef = (slotId: string, packetRef: PacketRef) => {
        isDebug && console.log("Putting packet ref for", slotId, packetRef)
        props.updatePacket(packetState =>  {
            const updated = {...packetState,
                application: {
                ...packetState.application,
                packetRefs: [...packetState.application.packetRefs.filter(x => x.packetSlotId !== slotId), packetRef]}
            }
            isDebug && console.log("Updated packet state", updated)
            return updated
        })
    }

    return <>{specifics.map(slot => {
            const refEntry = packetRefs.find(x => x.packetSlotId === slot.id)
            return <PacketSlotEntry>
                    <SelectSpecificRequiredDocument slotDefinition={slot} client={props.client} siteId={props.application.siteId}
                                                    trackId={props.application.trackId} setPacketRef={setPacketRef} saveDraft={props.saveDraft}
                                                    applicationPacket={props.application}
                                                    userInformation={props.userInformation} packetRef={refEntry} editable={editable}/>
                </PacketSlotEntry>
        }
    )}</>

}