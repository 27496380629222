import React, {useState} from 'react'
import {Program} from "../../program/model/Program";

import {Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select} from "@mui/material"
import Input from "@mui/material/Input";
import {useTheme} from "@mui/material/styles";
import User from "../model/User";
import {makeStyles} from "tss-react/mui";


interface ProgramSelectProps {
    programs: Array<Program>
    user: User | undefined | null
    setPrograms: (s: number[]) => void
    errors?: string | undefined
}

const useStyles = makeStyles()((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
        maxWidth: "100%"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

export const ProgramSelect = (props: ProgramSelectProps) => {
    const [multiSelect, setMultiSelect] = useState<number[]>(props.user?.programs ? props.user.programs : [])

    function getStyles(name: number, multiSelect: number[], theme: any) {
        return {
            fontWeight:
                multiSelect.includes(name)
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const handleChange = (value: number[]) => {
        setMultiSelect(value)
        props.setPrograms && props.setPrograms(value)
    }

    const {classes} = useStyles();
    const theme = useTheme();

    const checked: (p: Program, s: number[]) => boolean = (p, s) => {
        if (p.id  && s.includes(p.id)) return true;

        return false;
    }

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="user-programs-label">Programs</InputLabel>
            <Select
                labelId="user-programs-label"
                id="user-programs"
                name="programs"
                multiple
                value={multiSelect}
                variant="filled"
                onChange={e => handleChange(e.target.value as number[])}
                input={<Input/>}
                renderValue={(selected) => (selected as number[]).map(x => props.programs.find(y => y.id === x)).map(x => x?.name).join(', ')}
                error={props.errors!==undefined}
            >
                {props.programs.map((program) => (
                    <MenuItem key={program.id} value={program.id} style={getStyles(program.id ? program.id : 0, multiSelect, theme)}>
                        <Checkbox checked={checked(program, multiSelect)}/>
                        <ListItemText primary={`${program.school?.name} - ${program.name}`}/>
                    </MenuItem>
                ))}
            </Select>
            <small id="user-programs-help" className="form-text text-muted">Select which programs this person is administrating. {props.errors}</small>
        </FormControl>
    );
}