import {atom} from "jotai/index";
import {Client, ClientCycle, ClientSettingsOps} from "./client/model/Client";
import {initialLoginState} from "./lib/initialValues";
import {
    userInformationSentinel
} from "./login/model/Login";
import {Questionnaire} from "./model/Questionnaire";
import {QueryClient} from "@tanstack/react-query";
import {atomsWithQuery} from "jotai-tanstack-query";
import {UserInformation} from "./login/model/UserInformation";
import {API, api} from "./lib/Api";
import {appConfig} from "./conf/conf";
import {LoginState, refreshUser as loginRefreshUser} from "./login/lib/UserSessionsService";

export const currentClientQueryKey = () => ["currentClient"]

export const queryClient = new QueryClient()
export const clientsAtom = atom<Client[]>([]);
export const clientCycleAtom = atom<ClientCycle>({
    id: 0, clientId: 0, cycleStart: new Date(), cycleEnd: new Date(), cycleName: "", matchType: "auto"
});
export const flashingErrorMessageAtom = atom<string>("");
export const assumedUserEmailAtom = atom<string | null>("")
export const loginStateAtom = atom<LoginState>(initialLoginState());
export const lastUserUpdateAtom = atom<Date>(new Date(0))
export const pageHeadingAtom = atom<string>("")
export const userInformationQueryKey = () => ["userSession"]
export const userSessionStaleSeconds = 60 * 60
export const questionnairesAtom = atom<Questionnaire[]>([]);
export const userInformationAtom = atom<UserInformation>(userInformationSentinel)

export const slowQueryAtom = atom<string>("")

export const [clientAtom, clientStatusAtom] = atomsWithQuery<Client>((get) => ({
    queryFn: () => api<Client, undefined>(API.currentClient(), undefined, 0)
        .then(response => response.data)
        .then(client => {
            appConfig.isDebug && console.log("Setting client", client)
            ClientSettingsOps.loadSiteBlurbsToI18n(client.settings)
            return {...client, settings: {...client.settings, isLoaded: true}}
        }),
    queryKey: currentClientQueryKey(),
    staleTime: 86400000,
}))

export const refreshUser = (queryClient: QueryClient) => {
 return loginRefreshUser(queryClient)
}

