import MaterialTable from "@material-table/core";
import React from "react";
import {InternshipSite} from "../model/Organization";
import {tracksFromInternshipSite} from "../lib/TracksForOrganization";
import {useAppContext} from "../../lib/context";

interface InternshipSitesTableProps {
    row: InternshipSite
}

const InternshipTracksTable: React.FC<InternshipSitesTableProps> = (props) => {
    const {isDebug} = useAppContext()
    const {row} = props;

    const data = tracksFromInternshipSite(row);
    isDebug && console.log("IS Data",data);

    const nestedTableColumns = [
        {title: 'Name', field: 'track.name'}
    ]

    return (
        <MaterialTable title="Tracks" columns={nestedTableColumns}
                       data={data}
                       options={{ paging: false, search: false}}
        />
    )
}

export default InternshipTracksTable;