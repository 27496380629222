import React, {CSSProperties, ReactNode, useRef} from 'react'
import {useAppContext} from "../lib/context";
import {api, API, apiError} from "../lib/Api";
import {Order} from "./model/Order";
import {TableColumn} from "../components/customTable/CustomTable";
import {Page, PageBody, PageHeader} from "../components/Page";
import MaterialTable from "@material-table/core";
import SpinnerCard from "../components/SpinnerCard";
import {requireOneOfGroup} from "../lib/util";
import Groups from "../model/Groups";
import {AuthenticationFailed} from "../components/AuthenticationFailed";
import {RichElement} from "../components/customTable/RichElement";
import {OrderDetail} from "./OrderDetail";
import {PaymentState, paymentState} from "./components/PaymentState";
import {DollarDecimal} from "../product/lib/DollarDecimal";
import {componentModal, ConfirmDialogActions, messageModal} from "../components/modal/InfoModal";
import {ManualPayment} from "./components/ManualPayment";
import {formDataAsMap, formDataAsMapFromRef} from "../lib/Forms";
import useListSchoolService from "../school/lib/SchoolService";
import {ProductType, ProductTypes} from "../product/model/Product";
import {Box, Button, Link, Tooltip} from "@mui/material";
import {useNavigate} from "react-router-dom";
import useUserReadyService from "../user/lib/UserReadyService";
import {userInformationAtom} from "../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom, clientsAtom} from "../Atoms";
import {ModalAction} from "../components/modal/ModalProps";
import {UserInformation} from "../login/model/UserInformation";
import {useQueryClient} from "@tanstack/react-query";
import {UseQueryResult} from "@tanstack/react-query/src/types";
import {useOrganizationsService} from "../internship-site/lib/ListOrganizationService";
import {Organization} from "../internship-site/model/Organization";
import {listOrderQueryKey, useListOrderQuery} from "./ListOrderQueryservice";
import {Client} from "../client/model/Client";

export const OrderList: React.FC = () => {
    const {isDebug, setModal} = useAppContext()

    const clients = useAtomValue(clientsAtom)

    const userInformation: UserInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client

    const localClientId = client?.id || 0

    const navigate = useNavigate();
    const userReady = useUserReadyService()
    const queryClient = useQueryClient()

    const ordersQuery = useListOrderQuery({userInformation, client})

    const organizationsService: UseQueryResult<Organization[]> = useOrganizationsService({
        client: client,
        userInformation: userInformation,
    })

    const sites = organizationsService.isSuccess ? organizationsService.data.map(x => x.internshipSite): []
    const schoolsService = useListSchoolService()
    const schools = schoolsService.status === "loaded" ? schoolsService.payload : []
    const programs = schoolsService.status === "loaded" ? schoolsService.payload.flatMap(x => x.programs) : []

    const paymentFormRef = useRef<HTMLFormElement>(null)

    const getActorName = (productType: ProductType, id: string | undefined): string | undefined => {
        switch (productType) {
            case ProductTypes.organizationProduct: return sites.find(x => x.id?.toString() === id)?.name
            case ProductTypes.programProduct: return programs.find(x => x?.id?.toString() === id)?.name
            case ProductTypes.schoolProduct: return schools.find(x => x?.id?.toString() === id)?.name
            case ProductTypes.clientProduct: return clients?.find(x => x?.id?.toString() === id)?.name
            case ProductTypes.studentProduct: return "Unknown"
            default: return undefined
        }
    }

    const getActorLink = (productType: ProductType, id: string | undefined): ((children: React.ReactNode) => ReactNode) => {
        switch (productType) {
            case ProductTypes.organizationProduct:
                const site = sites.find(x => x.id?.toString() === id)
                return site ? ((children: React.ReactNode) => <Link href={`/site/${site.organizationId}/${site.id}`}>{children}</Link>) : children => <Tooltip title="Unknown"><>{children}</></Tooltip>
            case ProductTypes.programProduct:
                const program = programs.find(x => x?.id?.toString() === id)
                return program ? ((children: React.ReactNode) => <Link href={`/school`}>{children}</Link>) : children => <Tooltip title="Unknown"><>{children}</></Tooltip>
            default: return children => <Tooltip title={productType}><>{children}</></Tooltip>
        }
    }

    const columns: Array<TableColumn<Order>> = [{
        title: "ID", field: "id"
    }, {title: "Status", field: "", render: row => paymentState(row)},
        {
            title: "Product Type", field: "productType"
        }, {
            title: "Product Name", field: "productType", render: row => row.orderEntries.map(x => x.offer.product.name).join(", ")
        }, {
            title: "Order Date", field: "createdTimestamp", render: row => <RichElement value={row.createdTimestamp}/>
        }, {
            title: "User", field: "userEmail"
        }, {
            title: "Ordered For Entity", field: "orderActorId", render: row => getActorLink(row.productType, row.orderActorId)(<>
                {getActorName(row.productType, row.orderActorId)}&nbsp;
                <sup>{row.orderActorId}</sup>
                </>)
            , customFilterAndSearch: (term, row) => {
                const actorName = getActorName(row.productType, row.orderActorId)
                return actorName?.toLowerCase().includes(term.toLowerCase()) || row.orderActorId?.toLowerCase().includes(term.toLowerCase())
            }
        }, {
            title: "Total Price", field: "totalPrice", render: row => <DollarDecimal value={row.totalPrice || 0}/>
        }]

    const cssForPaymentState = (paymentState: PaymentState | undefined): CSSProperties => {
        switch (paymentState) {
            case "Paid":
                return {"backgroundColor": "#ccffcc"}
            case "Unpaid":
                return {"backgroundColor": "#ffcccc"}
            case "Partially Paid":
                return {"backgroundColor": "#ffeecc"}
            case "Refunded":
                return {"backgroundColor": "#cccccc"}
        }
        return {}
    }

    isDebug && console.log("Orders", ordersQuery.data)

    const paymentFormSubmit = (e: React.FormEvent) => {
        const formData = formDataAsMap<{ [p: string]: any }>(e.target as HTMLFormElement)
        console.log(formData)
    }

    const paymentClickHandler = (event: any, rowData: Order | Order[]) => {
        if (Array.isArray(rowData)) return

        setModal && setModal({
            ...componentModal("Payment Details", (<ManualPayment orderId={rowData.id} onSubmit={paymentFormSubmit} formRef={paymentFormRef}/>)),
            dialogActionComponents: ConfirmDialogActions,
            onClose: (action: string) => {
                if (action === ModalAction.Confirm) {
                    console.log("payment form ref", paymentFormRef)
                    const formData = formDataAsMapFromRef(paymentFormRef)
                    isDebug && console.log("Payment form data", formData)
                    api(API.manualPayment(rowData.id), userInformation, localClientId, formData)
                        .then(x =>
                                setModal && setModal({
                                    ...messageModal("Payment Added", "Payment for " + formData.amount + " added."),
                                    onClose: (x: string) => queryClient.invalidateQueries(listOrderQueryKey(client.id))
                                })
                        ).catch(err => apiError(err, setModal))
                }
            }
        })
    }

    if (userReady && !requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin, Groups.clientAdmin])) {
        isDebug && console.log("Returning auth failed")
        return (<AuthenticationFailed/>)
    }

    return ordersQuery.isSuccess ? (
        <Page navName="OrderList">
            <PageHeader title="Client Order List">
                <Box p={1} m={1}>
                    <Button onClick={e => navigate("/adminOrder")} variant="outlined">Administratively Create Order</Button>
                </Box>
            </PageHeader>
            <PageBody>
                <MaterialTable columns={columns} data={ordersQuery.data} options={
                    {
                        pageSize: 10,
                        rowStyle: (row, index, x) => cssForPaymentState(paymentState(row))
                    }}
                               actions={[{
                                   icon: 'paid',
                                   tooltip: 'Record Payment',
                                   onClick: paymentClickHandler
                               }]}
                               detailPanel={[{render: row => <OrderDetail order={row.rowData}/>}]}
                />
            </PageBody>
        </Page>
    ) : <SpinnerCard/>
}