import React, {useEffect, useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import {useAppContext} from "../../lib/context";
import useListQuestionnaireService from "../../lib/ListQuestionnaireService";
import {Questionnaire} from "../../model/Questionnaire";
import {head, requireGroup} from "../../lib/util";
import {useNavigate} from "react-router-dom";
import Groups from "../../model/Groups";
import {API, getApiUrl} from "../../lib/Api";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";
import {clientAtom} from "../../Atoms";
import {UserInformation} from "../../login/model/UserInformation";
import {Client} from "../../client/model/Client";

export interface AdminMenuProps {
    anchorEl: HTMLElement | null
    id: string
    open: boolean
    closeMenuHandler: (() => void)
}

const QuestionnaireEditLink: React.FC<{questionnaire: Questionnaire | undefined}> = ({questionnaire}) => {
    const navigate = useNavigate()

    const questionnaireOnClickHandler = (questionnaire: Questionnaire) => () => {
        if (questionnaire.id && questionnaire.id > 0) {
            navigate("/questionnaire/" + questionnaire.id)
        }
    }

    return questionnaire ? (<MenuItem key="Questionnaire" id="questionnaire" onClick={questionnaireOnClickHandler(questionnaire)}>{questionnaire.name}</MenuItem>) : <></>

}

export const AdminMenu = (props: AdminMenuProps) => {
    const {isDebug} = useAppContext()
    const client = useAtomValue(clientAtom) as Client
    const userInformation: UserInformation = useAtomValue(userInformationAtom)

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(props.anchorEl)
    const [open, setOpen] = useState<boolean>()

    const navigate = useNavigate()

    const questionnaireService = useListQuestionnaireService()
    const questionnaires: Array<Questionnaire> = questionnaireService.status === "loaded" ? questionnaireService.payload : [] as Array<Questionnaire>
    const questionnaire = questionnaireService.status === "loaded" ? head(questionnaires) : undefined
    const studentQuestionnaire = questionnaireService.status === "loaded" ? questionnaires.find(x => x.name === "Student Questionnaire") : undefined
    const approvalQuestionnaire = questionnaireService.status === "loaded" ? questionnaires.find(x => x.name === "Materials Approval Questionnaire") : undefined


    useEffect(() => {
        isDebug && console.log("Admin Menu Effecting")
        setOpen(props.open)
        setAnchorEl(props.anchorEl)
    },[props, props.open, props.anchorEl, isDebug])

    const handleMenuClose = () => {
        setAnchorEl(null)
        setOpen(false)
        props.closeMenuHandler()
    };

    isDebug && console.log("Admin Menu", open, props.open)

    return (
            <Menu anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                id={props.id}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={open || false}
                onClose={handleMenuClose}>
                    <MenuItem key="site-relationships" component="a" href="/trackPrograms">Track Relationships</MenuItem>
                    <MenuItem key="program-relationships" component="a" href="/program">Program Relationships</MenuItem>
                    <MenuItem key="bulk-email" component="a" href="/bulkEmail">Bulk Email</MenuItem>

                {questionnaireService.status === "loaded" && [
                <QuestionnaireEditLink questionnaire={questionnaire} key="questionnaire"/>,
                <QuestionnaireEditLink questionnaire={studentQuestionnaire} key="studentQuestionnaire"/>,
                <QuestionnaireEditLink questionnaire={approvalQuestionnaire} key="approvalQuestionnaire" />
                ]}

                <MenuItem component="a" href={getApiUrl() + API.pivotResult().location} target="_blank" download >
                    Download Site Profiles
                </MenuItem>

                {userInformation && requireGroup(userInformation.userGroups, Groups.globalAdmin) && [
                    <MenuItem key="products" component="a" href={"/products"}>Products</MenuItem>,
                    <MenuItem key="offers" component="a" href={"/offers"}>Offers</MenuItem>,
                    <MenuItem key="orders" component="a" href={"/clientOrders"}>Client Orders</MenuItem>,
                    <MenuItem key="adminOrder" component="a" href={"/adminOrder"}>Create Order Administratively</MenuItem>,
                    <MenuItem key="run-export" component="a" href={"/stageMatch"}>Stage Match</MenuItem>,
                    <MenuItem key="client-settings" component="a" href={"/clientSettings"}>Client Settings</MenuItem>,
                    <MenuItem key="client-cycles" onClick={() => navigate("/clientCycles")}>Client Cycles</MenuItem>,
                    <MenuItem key="audit-log" component="a" href={"/audit"}>Audit Log</MenuItem>,
                    <MenuItem key="download-application" component="a" href={getApiUrl() + API.downloadApplicationCSV().location + "?clientId=" + client.id}>Download Application</MenuItem>,
                    <MenuItem key="global-admin" component="a" href="/globalAdmin">Global Admin Functions</MenuItem>,
                ]}
            </Menu>
    )
}
