// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
import {makeStyles} from "tss-react/mui";
import {Theme} from "@mui/material";

export const useStyles = makeStyles()((theme: Theme) =>
    ({
        timesBox: {
            textAlign: 'center',
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1)
        },

        labelBox: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1),
            whiteSpace: "nowrap"
        },

        nameBox: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1)
        },

        quantityBox: {
            marginTop: theme.spacing(2),
            marginBottom: theme.spacing(1)
        },

        orderHeading: {
            fontSize: "1.2rem"
        },

        grower: {
            width: "100%",
        },

        orderConfirmButton: {
            margin: "20px auto",
            display: "block"
        },
        formControl: {
            margin: theme.spacing(1),
            minWidth: "100%",
            maxWidth: "100%",
        },
        selectEmpty: {
            marginTop: theme.spacing(2),
        },
    }));
