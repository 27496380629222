import {Checkbox, FormControlLabel} from "@mui/material";
import React, {useState} from "react";

interface EditTextProps {
    label?: string
    name: string
    value: string | boolean
    classes?: any
}

export const EditCheckbox = (props: EditTextProps) => {
    const inboundValue = typeof props.value === "string" ? props.value === "yes" : props.value

    const [checked, setChecked] = useState(inboundValue)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(!checked)
    }

    return (
        <>
        <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange}
            name={`checkbox-${props.name}`} />} label={props.label || props.name}/>
        <input type="hidden" value={checked ? "yes" : "no"} name={props.name}/>
        </>
    )
}