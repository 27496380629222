import {Box, Button, FormControl, TextField} from "@mui/material";
import {useNavigate, useParams} from "react-router-dom";
import {useStudentInformationService} from "../../student/lib/StudentInformationService";
import {useModalService} from "../../components/modal/ModalService";
import React, {useCallback, useState} from "react";
import {useStyles} from "../../questionnaire/QuestionnaireList";
import {useMutation} from "@tanstack/react-query";
import {api, API} from "../../lib/Api";
import {useAtomValue} from "jotai";
import {clientAtom, userInformationAtom} from "../../Atoms";
import {Client} from "../../client/model/Client";
import SpinnerCard from "../../components/SpinnerCard";
import {StudentOps} from "../../student/model/Student";
import {QuestionnaireSubmissionActionTypes} from "../../questionnaire/model/Questionnaire";
import {StudentUserInformation} from "../../login/model/UserInformation";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

export const QuestionnaireReviewButtons: React.FC = () => {
    const params = useParams()
    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client

    const [comments, setComments] = useState<string>("")
    const [saving, setSaving] = useState<boolean>(false)

    const modal = useModalService()

    const studentData = useStudentInformationService(params.guid)
    const navigate = useNavigate()

    const approve = () => {
        navigate("/program/approval/" + params.guid)
    }

    const {classes} = useStyles()

    const correctionMutation = useMutation({
        mutationFn: (guid: string) => api(API.rejectStudentQuestionnaire(guid), userInformation, client.id, {comments})
            .then(() => navigate("/student#2"))
            .catch((e) => {
                setSaving(false)
            })
    })

    const returnForCorrections = useCallback(() => {
        modal.componentConfirmChange(params.guid, "Email student that their application is being returned for corrections?", {onConfirm: () => {
            setSaving(true)
            correctionMutation.mutateAsync(params.guid || '').then(() => {
                setSaving(false)
                modal.message("Student Notified", "The student has been notified that their application has been returned for corrections.", {
                    onClose: () => navigate("/student#2")
                })
            })
            }},

            <Box>
                <Box className="modalIcon" style={{"color": "#ff0000", marginTop:"0", paddingTop:"1rem"}}><WarningAmberIcon/></Box>
                <Box pt={2} sx={{minWidth: 400, float: "left", width: "calc(100% - 45px)"}}>
                    <FormControl className={classes.formControl}>
                        <Box mb={2} id="modal-message-text">
                            Provide specific feedback for the student to correct:
                        </Box>
                        <TextField multiline name="comments" placeholder="Add feedback comments for this review"
                                   onChange={(e) => setComments(e.target.value)}/>
                    </FormControl>
                </Box>
            </Box>
        )
    }, [params.guid, classes.formControl, modal, navigate, correctionMutation])

    console.log("Review buttons", studentData.data)

    const isSubmitted = (student: StudentUserInformation) => (StudentOps.lastQuestionnaireAction(student).map(x => x.actionType).getOrElse(QuestionnaireSubmissionActionTypes.none) === QuestionnaireSubmissionActionTypes.submitted)

    if (studentData.isSuccess && isSubmitted(studentData.data)) {
        console.log("Submitted, rendering flow buttons")
        return saving ? <SpinnerCard message="Saving..."/> : <>
                <Button variant="contained" className="go" onClick={() => approve()}>Approve</Button>
                <Button variant="contained" color="secondary" onClick={() => returnForCorrections()}>Return for Corrections</Button>
            </>
    }
    else {
        return <></>
    }
}