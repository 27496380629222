import React, {useCallback, useState} from 'react'
import {useAppContext} from "../lib/context";
import {API, api, apiError} from "../lib/Api";
import {useNavigate, useParams} from "react-router-dom";
import {
    QuestionAnswerSave
} from "../questionnaire/model/QuestionnaireSubmissionElement";
import {QuestionnaireId} from "../questionnaire/model/Questionnaire";
import SpinnerCard from "../components/SpinnerCard";
import QuestionnaireDisplay from "../questionnaire/QuestionnaireDisplay";
import {Box} from "@mui/material";
import {
    LoggedInComponentWrapper, LoggedInContextProps,
    WrappedLoggedInContextProps
} from "../components/LoggedInPage";
import {useFetchProgramStudentApprovalQuestionnaire} from "./lib/ProgramStudentApprovalQuestionnaireService";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {LoggedInComponent} from "../components/LoggedInPage";
import {StudentUserInformation} from "../login/model/UserInformation";
import {useModalService} from "../components/modal/ModalService";
import {listStudentsKey} from "../student/lib/StudentService";

export interface ProgramAdminApprovalQuestionnaireSubmissionProps {
    questionnaireId: QuestionnaireId,
    guid: string
}

const ProgramAdminApprovalQuestionnaireSubmission: LoggedInComponent<ProgramAdminApprovalQuestionnaireSubmissionProps> = (props) => {
    const {userInformation, client, questionnaireId, guid} = props
    const {setModal, isDebug} = useAppContext();

    const modal = useModalService()

    const [saving, setSaving] = useState<boolean>(false)

    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const onChange = useCallback((qas: QuestionAnswerSave[]) => {
        isDebug && console.log("onChange", qas)
    }, [isDebug]);

    const questionnaireSubmissionMutation = useMutation({
        mutationFn: (data: {qas: QuestionAnswerSave[], draft: boolean}) =>
            api(API.submitProgramStudentApprovalQuestionnaire(guid, questionnaireId, data.draft), userInformation, client.id, data.qas)
                .then(x => queryClient.invalidateQueries(["programApprovalQuestionnaire", guid]))
                .then(x => queryClient.invalidateQueries(listStudentsKey(client.id))),
        onError: (err) => {
            setSaving(false)
        }
    })

    const handleSubmit = (qas: QuestionAnswerSave[], draft: boolean) => {
        setSaving(true)
        questionnaireSubmissionMutation.mutateAsync({qas: qas, draft: draft})
            .then(x => {
                isDebug && console.log("Saved")
                modal.message("CERF Complete", "The student's CERF is complete.", {
                    onClose: () => {
                        setSaving(false)
                        navigate("/student#2")
                    }
                })
            })
            .catch(err => {
                apiError(err, setModal)
            })
    }

    const studentQuery = useQuery({
        queryFn: () => api<StudentUserInformation, undefined>(API.getStudent(guid), userInformation, client.id),
        queryKey: ["student", guid]
    })

    const questionnaireDataQuery = useFetchProgramStudentApprovalQuestionnaire(userInformation, guid, studentQuery.data?.data, client.id, questionnaireId)

    if (questionnaireDataQuery.isError) {
        return <Box>API Error</Box>
    }

    isDebug && console.log("State", questionnaireDataQuery.data, saving)

    return <Box m={3}>
        {(!saving &&  !questionnaireDataQuery.isLoading) ? (
            <QuestionnaireDisplay questionnaireElements={questionnaireDataQuery.data.elements}
                                  handleSubmit={handleSubmit} onChange={onChange}
                                  questionnaireId={questionnaireId}
                                  key={questionnaireId}
                                  requiredBlocking={true}/>
        ) : <SpinnerCard/>}
    </Box>
}

const ProgramAdminApprovalQuestionnaireSubmissionWrapper: LoggedInComponentWrapper = (context: WrappedLoggedInContextProps) => {
    const params = useParams()
    if (context.state === "ready") {
        if (context.client.settings.clientCycleSettings.applicationSuiteSettings.materialSlotsDefinition.materialsApprovalQuestionnaireId) {
            const adminUser = context.userInformation
            // For some reason this throws the IDE for a loop, but compiles fine
            const props:  LoggedInContextProps & ProgramAdminApprovalQuestionnaireSubmissionProps = {
                ...context,
                userInformation: adminUser,
                questionnaireId: context.client.settings.clientCycleSettings.applicationSuiteSettings.materialSlotsDefinition.materialsApprovalQuestionnaireId,
                guid: params.guid || ''
            }
            return <ProgramAdminApprovalQuestionnaireSubmission {...props}/>
        }
        else {
            return <Box>Error - No Admin Questionnaire Available for this Client</Box>
        }
    }
    else {
        return <SpinnerCard message="Loading Context..."/>
    }
}

export default ProgramAdminApprovalQuestionnaireSubmissionWrapper