import {UseQueryResult} from "@tanstack/react-query/src/types";
import {QueryClient, useQuery} from "@tanstack/react-query";
import {API, api} from "../../../../lib/Api";
import {LetterOfReference} from "../../../model/LetterOfReference";
import {StudentMaterialsDocument} from "../../../model/StudentMaterials";
import {useAppContext} from "../../../../lib/context";
import {useAtomValue} from "jotai";
import {clientAtom} from "../../../../Atoms";


export type LetterOfReferenceAPIResult = LetterOfReference & { document?: StudentMaterialsDocument } & {
    studentMetadata: { firstName: string, lastName: string }
}

export const letterOfReferenceQueryKey = (lorUUID: string) => ["letterOfReference", lorUUID]

export const useLetterOfReferenceService = (guid: string, lorUUID: string): UseQueryResult<LetterOfReferenceAPIResult> => {
    const {isDebug} = useAppContext()
    const client = useAtomValue(clientAtom)

    return useQuery({
        queryFn: () => api<LetterOfReferenceAPIResult, undefined>(API.fetchStudentLetterOfReferenceDoc(guid, lorUUID), undefined, client.id)
            .then(res => {
                isDebug && console.log("student lor doc", res.data)
                return res.data
            }),
        queryKey: letterOfReferenceQueryKey(lorUUID),
        staleTime: 86400000
    })
}

export const refreshLetterOfReferenceQuery = (queryClient: QueryClient) => (lorUUID: string) => {
    return queryClient.invalidateQueries(letterOfReferenceQueryKey(lorUUID))
}