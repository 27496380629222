import {ClientCycle, ClientId} from "../../client/model/Client";
import {Address, emptyAddress} from "../../components/model/Address";
import {QuestionnaireId} from "../../questionnaire/model/Questionnaire";
import {ProgramTrackRelationship} from "../../program/model/ProgramSiteRelationship";
import {Order} from "../../order/model/Order";
import {UserInformation} from "../../login/model/UserInformation";
import User from "../../user/model/User";

export type OrganizationId = number
export type InternshipSiteTrackId = number
export type InternshipSiteId = number

export interface Organization {
    id?: OrganizationId
    clientId: ClientId
    comments: string
    address: Address
    internshipSite: InternshipSite
    name: string,
    description: string,
    modifiedTimestamp: Date,
}

export const emptyInternshipSite: InternshipSite = {
    organizationId: 0,
    clientId: 0,
    clientCycleId: 0,
    name: "",
    totalOpenings: 10,
    identityCode: "",
    description: "",
    duesPaid: false,
    attestationFiled: false,
    siteTracks: [],
    address: emptyAddress,
    questionnaireComplete: false,
    modifiedTimestamp: new Date(),
    continuousId: "0",
    rankedStudents: [],
    studentsApplied: []
}

export const emptyOrganization: Organization = {
    id: 0,
    clientId: 0,
    comments: "",
    address: emptyAddress,
    internshipSite: emptyInternshipSite,
    name: "",
    description: "",
    modifiedTimestamp: new Date(0)
}

export interface RankedStudent extends User {
    trackId: InternshipSiteTrackId
    position: number
}

export interface InternshipSite {
    id?: InternshipSiteId
    directoryQuestionnaireId?: QuestionnaireId
    organizationId: number
    clientId: number
    clientCycleId: number
    name: string
    address?: Address
    totalOpenings: number
    identityCode: string
    description: string
    duesPaid: boolean
    attestationFiled: boolean
    questionnaireComplete: boolean
    siteTracks: InternshipSiteTrack[]
    admins?: UserInformation[]
    orders?: Order[]
    modifiedTimestamp: Date
    continuousId: string
    rankedStudents: RankedStudent[]
    studentsApplied: RankedStudent[]
}

export type SiteTrackTypeType = 1 | 2 | 3 | 4

export interface InternshipSiteTrack {
    id?: InternshipSiteTrackId
    internshipSiteId: InternshipSiteId
    name: string
    description: string
    openings: number
    siteTrackType: SiteTrackTypeType
    siteName?: string
    modifiedTimestamp: Date
    continuousTrackId: string
    programs: ProgramTrackRelationship[]
    clearingHouseOptIn: boolean
    totalMatched?: number
    rankedStudents: RankedStudent[]
    studentsApplied: RankedStudent[]
}

export const emptyInternshipSiteTrack: InternshipSiteTrack = {
    name: "",
    internshipSiteId: 0,
    description: "",
    openings: 0,
    siteTrackType: 1,
    modifiedTimestamp: new Date(),
    continuousTrackId: "0",
    programs: [],
    clearingHouseOptIn: false,
    totalMatched: 0,
    rankedStudents: [],
    studentsApplied: []
}

export interface SiteTrackStudentCycleRank {
    siteTrack: InternshipSiteTrack
    internshipSite: InternshipSite
    student: string
    clientCycle: ClientCycle
    position: number
}


export const trackTypes = {
    1: 'FT',
    2: 'HT-2Yr',
    3: 'HT-1Yr',
    4: 'Undetermined/Multiple'
}

export const OrganizationViewNames = {allCycles: "allCycles", tracks: "tracks", sites: "sites"}
export const organizationViewNames = [OrganizationViewNames.allCycles, OrganizationViewNames.tracks, OrganizationViewNames.sites]
export type OrganizationViewName = typeof organizationViewNames[number]

export const defaultOrganizationViewName = OrganizationViewNames.tracks
