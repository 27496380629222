import {InternshipSiteId, InternshipSiteTrackId, OrganizationId} from "../model/Organization";
import {API, api} from "../../lib/Api";
import {Program} from "../../program/model/Program";
import {School} from "../../school/model/School";
import {userInformationAtom} from "../../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom} from "../../Atoms";
import {StudentUserInformation} from "../../login/model/UserInformation";
import {Client} from "../../client/model/Client";
import {useQuery} from "@tanstack/react-query";

export interface EligibleStudent {
    student: StudentUserInformation
    program: Program
    school: School
}

interface EligibleStudentServiceForTrackProps {
    siteId: InternshipSiteId
    organizationId: OrganizationId
    trackId: InternshipSiteTrackId
}

const useEligibleStudentServiceForTrack = (props: EligibleStudentServiceForTrackProps) => {
    const client = useAtomValue(clientAtom) as Client
    const userInformation = useAtomValue(userInformationAtom)

    console.log("Loading eligible students for ", props.trackId)

    return useQuery({
        queryFn: () => api<EligibleStudent[], undefined>(API.listEligibleStudentsForSiteTrack(props.organizationId, props.siteId, props.trackId), userInformation, client.id)
                .then<EligibleStudent[]>(response =>  response.data),
        queryKey: ["eligibleStudents", props.organizationId, props.siteId, props.trackId],
        staleTime: 86400000
    })

}

export default useEligibleStudentServiceForTrack;
