import React, {useEffect, useState} from 'react';
import '../Login.css';
import {useNavigate} from "react-router-dom"
import {useAppContext} from "../lib/context";
import {SpinnerSmall} from "../components/SpinnerCard";
import {Box, Button, FormGroup, Grid, TextField, Typography} from "@mui/material";
import {API, api, apiError} from "../lib/Api";
import {historyPushModal} from "../components/modal/InfoModal";
import {useStyles} from "./Login";
import clsx from "clsx";
import {clientAtom, userInformationAtom} from "../Atoms";
import {useAtom, useAtomValue} from "jotai";
import {flashingErrorMessageAtom} from "../Atoms";

const ForgotPassword: React.FC = () => {
    const {isDebug, setModal} = useAppContext();

    const [flashingErrorMessage, setFlashingErrorMessage] = useAtom(flashingErrorMessageAtom)
    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom)

    const [email, setEmail] = useState("");
    const [submitted, setSubmitted] = useState(false)

    const navigate = useNavigate()

    useEffect(() => {
        if (submitted && flashingErrorMessage) {
            setSubmitted(false)
        }
    }, [flashingErrorMessage, submitted, setSubmitted])

    isDebug && console.log("Login")

    const {classes} = useStyles()

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (email.trim() === "") {
            setSubmitted(false)
            setFlashingErrorMessage("Please enter a valid email address")
        }
        else {
            setSubmitted(true)
            api(API.resetUserPassword(email), userInformation, client.id).then(resp => {
                setFlashingErrorMessage && setFlashingErrorMessage("Please check your email for a temporary password")
                setModal && setModal({
                    ...historyPushModal("Password Reset", "If this account exists, a new temporary password will have been sent", navigate, "/login"),
                })
            }).catch((err) => apiError(err, setModal))
        }
    }

    return (
        <Box className={clsx(classes.Login, classes.container)}>
            <Box className={classes.card}>
                    <Box className={classes.cardHeaderBox}>
                        <Typography variant="h5" className={classes.cardHeader}>Forgot Password</Typography>
                    </Box>
                    <Box>
                        <form onSubmit={handleSubmit}>
                            {flashingErrorMessage ? (
                                    <div className={classes.formControl}>
                                        <span id="formError" className="text-danger">
                                           {flashingErrorMessage}
                                        </span>
                                    </div>
                                ) :
                                <div className={classes.formControl}>
                                    <span id="info">Enter your email to receive a temporary password</span>
                                </div>
                            }
                            <FormGroup className={classes.formControl}>
                                <Grid container alignItems="flex-end" wrap="nowrap">
                                    <Grid item className={classes.prependIcon}>
                                        <Box p={1}><i className="fas fa-user"></i></Box>
                                    </Grid>
                                    <Grid item className={classes.inputContainer} xs={12}>
                                        <TextField
                                                   placeholder="email address"
                                                   name="email"
                                                   id="email-address"
                                                   className={classes.inputField}
                                               value={email}
                                               onChange={e => setEmail(e.target.value.toLowerCase())}/>
                                    </Grid>
                                </Grid>
                            </FormGroup>

                            <FormGroup className={classes.formControl}>
                                <Grid container alignItems="flex-end" justifyContent="flex-end">
                                    <Grid item>
                                {submitted ? <Button className={classes.loginBtn}><SpinnerSmall/></Button>
                                : (
                                    <Button type="submit" className={clsx(classes.submitBtn, classes.resetBtn)} id="reset-password-button">Reset Password</Button>
                                )}
                                    </Grid>
                                </Grid>
                            </FormGroup>
                        </form>
                    </Box>
                    <div className="card-footer">
                    </div>
                </Box>
            </Box>
    );
}

export default ForgotPassword;
