import React from "react";
import MaterialTable, {Column} from "@material-table/core";
import QuestionnaireTextInput from "./QuestionnaireTextInput";
import {QuestionnaireSubmissionElement} from "../model/QuestionnaireSubmissionElement";
import {mapByCount} from "../../lib/util";
import {QuestionnaireElement, QuestionnaireTableElement} from "../model/Questionnaire";
import {useAppContext} from "../../lib/context";
import {makeStyles} from "tss-react/mui";
import {Box} from "@mui/material";
import {isEditable} from "./lib/questionnaireUtil";

interface QuestionnaireTableComponentProps {
    columns: number,
    columnHeadings: string[],
    table: QuestionnaireTableElement,
    key?: string,
    onChange?: (value: string, questionId: number) => void
}
export const useStyles = makeStyles()((theme) => ({
    questionnaireTableContainer: {
        margin: "0.4rem -34px",
        position: "relative"
    },
}));

export const questionnaireTableColumns = (columnHeadings: string[]): Column<QuestionnaireElement>[] => [{
    title: "",
    field: "title",
    sorting: false,
    width: 20,
}, ...columnHeadings.map((e, i) => ({
    title: e,
    sorting: false,
    field: "q" + i,
    width: 20,
}))]

export const filterTableRowLabel = (label: String) => label.replace(/(^\[[A-Za-z]+\])/gi, "")

const QuestionnaireTableComponent = (props: QuestionnaireTableComponentProps) => {

    const {isDebug} = useAppContext();

    const rowSize = props.columns

    const {classes} = useStyles()

    isDebug && console.log("Passed to table component", props.table, rowSize)

    const columns = questionnaireTableColumns(props.columnHeadings)

    const makeRowQuestionnaireElements: any = (elems: QuestionnaireSubmissionElement[]) =>
        Object.fromEntries(
            elems.map((e, i) =>
                [("q" + i), (<QuestionnaireTextInput question={e.question} answer={e.answer} onChange={e => console.log(e)} editable={isEditable(e)}/>)]
            )
        )

    const makeRowObject = (rowData: QuestionnaireElement[]) => {
        isDebug && console.log("Making row from", rowData)
        return ({
            title: filterTableRowLabel(rowData[0].question?.label || ''), ...makeRowQuestionnaireElements(rowData),
            id: rowData[0].id
        })
    }

    const items = mapByCount(props.table.tableQuestions, rowSize, (elems) => makeRowObject(elems))

    return <Box className={`foo ${classes.questionnaireTableContainer}`}>
        <MaterialTable data={items} columns={columns} title={props.table.content} options={{
            search: false,
            toolbar: false,
            paging: false,
        }}/>
    </Box>
}

export default QuestionnaireTableComponent