import {Optional} from "./Optional";

export function lookup(obj: any, key: string): any {
    //isDebug && console.log("Asked to lookup ", key, " in ", obj);
    return key.split('.').reduce((o, k) => o && o[k], obj);
}

export function setFromStringPath(obj: any, key: string, value: any): any {
    return setFromArrayPath(obj, key.split('.'), value)
}

export function setFromArrayPath(obj: any, keys: string[], value: any): any {
    if (obj === undefined) {
        throw new Error("setFromStringPath called on an undefined object as the target for " + keys.join(".") + " : " + value)
    }
    const [h, hx] = keys
    if (hx === undefined) {
        obj[h] = value
        return obj
    }
    else {
        const v = obj[h] || (isNaN(parseInt(hx)) ? {} : [])
        obj[h] = setFromArrayPath(v, keys.slice(1), value)
        return obj
    }
}


export function setListFromStringPath(obj: any, key: string, value: any): any {
    return setListFromArrayPath(obj, key.split('.'), value)
}

export function setListFromArrayPath(obj: any, keys: string[], value: any): any {
    const [h, hx] = keys
    if (hx === undefined) {
        if (obj[h]) {
            obj[h] = [...obj[h], value]
        }
        else {
            obj[h] = [value]
        }
        return obj
    }
    else {
        const v = obj[h] || {}
        obj[h] = setFromArrayPath(v, keys.slice(1), value)
        return obj
    }
}

export const clearCookies = () => {
    document.cookie.split(';')
        .forEach(x => {
            document.cookie = x + "=; expires="+ new Date(0).toUTCString();
        })
}

export const setCookie = (cname: string, cvalue: string, exdays: number) => {
    const expires = "expires="+ new Date(new Date().getTime() + (exdays*24*60*60*1000)).toUTCString();

    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/";
}

export const requireGroup = (userGroups: string[] | undefined, group: string) => {
    //isDebug && console.log("Requiring group", userGroups, group)
    return (userGroups && userGroups.find((x: string) => x === group) !== undefined) || false
}

export const requireOneOfGroup = (userGroups: string[] | undefined, groups: string[]) => {
    //isDebug && console.log("Requiring one of group", userGroups, groups)
    return (userGroups && userGroups.find((x: string) => groups.includes(x)) !== undefined) || false
}

export const isBlank = (str: string | undefined | null) => !str || str.trim() === ""

export const isDefined = (str: string | undefined | null) => (str !== undefined && str !== null)

export function head<T>(arr: Array<T> | undefined): T | undefined {
    if (arr) {
        return arr.length === 0 ? undefined : arr[0]
    }
    else return undefined
}

export const headOption = <T>(arr: Array<T> | undefined): Optional<T> => Optional.apply(head(arr))

export const generatePassword = () => {
    var pass = '';
    var str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' +
        'abcdefghijklmnopqrstuvwxyz0123456789@#%';
    var number = '0123456789';
    var special = '@#%';

    const randChar = () => str.charAt(Math.floor(Math.random() * str.length))

    const randSpecial = () => special.charAt(Math.floor(Math.random() * special.length))

    const randNumber = () => number.charAt(Math.floor(Math.random() * number.length))

    for (var i = 1; i <= 8; i++) {
        var char = randChar()
        pass += char
    }

    pass += randNumber()
    pass += randSpecial()

    return pass;
}

export const ellipsized = (text: string, maxLength: number) => {
    if (text.length > maxLength) {
        return text.substring(0, maxLength - 2) + "\u2026"
    }
    else return text;
}

export function notUndefined<T>(x: T | undefined): x is T {
    return x !== undefined;
}

export function sum(x: Array<number> | undefined) {
    if (!x) return 0
    else return x.reduce((a, b) => (a || 0) + (b || 0), 0)
}

export function olderDate(a: Date | undefined, b: Date | undefined): Date | undefined {
    if (a && b) return a < b ? a : b
    else if (b && !a) return b
    else if (a && !b) return a
    else return undefined
}

export const mapByCount = <T, K>(arr: T[], count: number, f: (elems: Array<T>) => K): K[] => {
    if (arr.length % count !== 0) throw new Error(`Array length ${arr.length} is not a multiple of ${count}`)

    const it = arr.entries()
    const result = []
    for (let i = it.next(); !i.done; i = it.next()) {
        let chunk = [i.value[1]]
        for (let t = 1; t < count; t++) {
            chunk.push(it.next().value[1])
        }
        result.push(f(chunk))
    }

    return result
}

export const openDocumentForPreview = (url: string) => {
    console.log("Opening",url)
    window.open(url, '_blank');
}