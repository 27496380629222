import React, {MouseEvent, ReactElement} from "react";
import {useAppContext} from "../../lib/context";
import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import useMediaQuery from "@mui/material/useMediaQuery";
import {useTheme} from "@mui/material/styles";
import {DialogActionProps, DialogType, ModalAction, ModalProps} from "./ModalProps";
import {SvgIconComponent} from "@mui/icons-material";
import HelpIcon from '@mui/icons-material/Help';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import {Box} from "@mui/material";


const defaultIcon: SvgIconComponent = HelpIcon
const defaultIconColor: string = "#008800"

export const historyPushModal = (title: string, message: string, navigate: any, path: string) => {return {
    isOpen: true,
    title: title,
    message: message,
    onClose: () => navigate(path),
    dialogType: DialogType.Message
} as ModalProps}

export function questionModal<T>(e: T, title: string, message: string, dialogType: DialogType, callback: (action: ModalAction, e: T) => void,
                                 actions: (props: DialogActionProps) => ReactElement = props => <ConfirmDialogActions handleClose={props.handleClose}/>,
                                 icon: SvgIconComponent = HelpIcon) {
    return {
        isOpen: true,
        title: title,
        message: message,
        onClose: (action: ModalAction) => callback(action, e),
        dialogType: dialogType,
        icon: icon,
        iconColor: "#8888888",
        dialogActionComponents: actions
    } as ModalProps
}

export function confirmDeleteModal<T>(e: T, callback: (action: ModalAction, e: T) => void, message?: string) {
    return questionModal(e, "Confirm Delete", message || "Are you sure you want to delete this item?", DialogType.DeleteConfirm, callback,
        (props: DialogActionProps) => <DeleteDialogActions handleClose={props.handleClose}/>)
}

export function confirmChangeModal<T>(data: T, callback: (action: ModalAction, e: T) => void, message?: string) {
    return questionModal(data, "Confirm", message || "Are you sure you want to update this item?", DialogType.DeleteConfirm, callback,
        (props: DialogActionProps) => <ConfirmDialogActions handleClose={props.handleClose}/>, HelpIcon)
}


export const messageModal = (title: string, message: string) => ({
    isOpen: true,
    title: title,
    message: message,
    dialogType: DialogType.Message,
    icon: CheckCircleOutlineIcon
} as ModalProps)

export const errorMessageModal = (title: string, message: string, iconColor?: string) => ({
    ...messageModal(title, message),
    icon: WarningAmberIcon,
    iconColor: iconColor || "#a00000"
} as ModalProps)

export const componentModal = (title: string, component: ReactElement) => ({
    isOpen: true,
    title: title,
    message: "",
    component: component,
    dialogType: DialogType.Message
} as ModalProps);


export const DeleteDialogActions = (props: DialogActionProps) => (
    <DialogActions test-id="dialog-delete-actions">
        <Button autoFocus aria-label="close dialog" onClick={(e: MouseEvent<HTMLButtonElement>) => props.handleClose(e, ModalAction.Cancel)} color="primary" test-id="dialog-cancel-button">
            Cancel
        </Button>
        <Button onClick={(e: MouseEvent<HTMLButtonElement>) => props.handleClose(e, ModalAction.Delete)} color="primary" autoFocus test-id="dialog-delete-button">
            Delete
        </Button>
    </DialogActions>
)

export const ConfirmDialogActions = (props: DialogActionProps) => (
    <DialogActions test-id="dialog-confirm-actions">
        <Button autoFocus aria-label="close dialog" onClick={(e: MouseEvent<HTMLButtonElement>) => props.handleClose(e, ModalAction.Cancel)} color="primary" test-id="dialog-cancel-button">
            Cancel
        </Button>
        <Button onClick={(e: MouseEvent<HTMLButtonElement>) => props.handleClose(e, ModalAction.Confirm)} color="primary" autoFocus test-id="dialog-confirm-button">
            Confirm
        </Button>
    </DialogActions>
)

export const DefaultDialogActions = (props: DialogActionProps) => (
    <DialogActions test-id="dialog-default-actions">
        <Button autoFocus aria-label="close dialog" onClick={(e: MouseEvent<HTMLButtonElement>) => props.handleClose(e, ModalAction.Close)} color="primary">
            Close
        </Button>
    </DialogActions>
)

const InfoModal: React.FC = () => {
    const {modal, setModal} = useAppContext()

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClose = (e: React.MouseEvent<HTMLButtonElement>, action: string) => {
        if (setModal && modal) {
            setModal({...modal, isOpen: false, icon: undefined})
            if (modal.onClose) modal.onClose(action)
        }
    }

    const LocalIcon: SvgIconComponent = modal?.icon ? modal.icon : defaultIcon

    return (
        <Dialog
            fullScreen={fullScreen}
            open={modal?.isOpen || false}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            test-id="info-modal"
        >
            <DialogTitle id="responsive-dialog-title">{modal?.title}</DialogTitle>
            <DialogContent>
                {modal?.component ? modal.component : (
                    <Box m={0} pt={1}>
                        <div className="modalIcon" style={{"color": modal?.iconColor || defaultIconColor}}><LocalIcon/></div>
                        <div style={{float: "left", width: "calc(100% - 45px)"}} id="modal-message-text">
                            {modal?.message ? modal.message : "Are you sure?"}
                        </div>
                    </Box>
                )}
            </DialogContent>
            {modal?.dialogActionComponents ? modal.dialogActionComponents({handleClose: handleClose}) : (<DefaultDialogActions handleClose={handleClose}/>) }
        </Dialog>
    )
}


export default InfoModal
