import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import dateFormat from "dateformat";

const formatDate = (value:any , format: undefined | string, lng: string | undefined) => {
    if (value instanceof Date) return dateFormat(value, format);
    return value;
}

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        debug: true,
        fallbackLng: 'en',
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
            format: (value, format, lng) => {
                if (value instanceof Date) return formatDate(value, format, lng);
                return value;
            }
        },
        resources: {
            en: {
                translation: {
                    general: {
                        "emailAddress": "email address",
                        "password": "password"
                    },
                    error: {
                        "generalDataError": "A general data error occurred.  Please contact support.",
                    },
                    modal: {
                        general: {
                            confirmDeleteTitle: "Confirm Delete",
                            confirmDeleteMessage: "Are you sure you want to delete this item?"
                        }
                    },
                    spinner: {
                        loading: "Loading...",
                        updating: "Updating...",
                        authenticating: "Authenticating...",
                        slowQuery: "Please wait for a moment longer...",
                        uploading: "Uploading...",
                    },
                    buttons: {
                        saveDraft: "Save Draft",
                        submit: "Submit",
                        remove: "Remove",
                        add: "Add"
                    },
                    navigation: {
                        home: "Home",
                        personalProfile: "Personal Profile",
                        howItWorks: "How It Works",
                        schools: "Schools",
                        programOverview: "Program Overview",
                        programRelationships: "Program Relationships",
                        applicationMaterials: "Application Materials",
                        selectSitesAndTracks: "Select Sites & Tracks",
                        viewRankList: "View Rank List",
                        rankTracks: "Rank Tracks",
                        tracks: "Tracks",
                        users: "Users",
                        applicants: "Applicants",
                        results: "Results",
                        matchResult: "Match Result",
                        siteProfile: "Profile",
                        directorySearch: "Directory Search",
                        sites: "Sites",
                        trackPrograms: "Track Relationships",
                        students: "Students",
                        login: "Login"
                    },
                    home: {
                        welcome: "Welcome to {{siteName}}!"
                    },
                    login: {
                        pageTitle: "Sign In",
                        login: "Login",
                        forgotPassword: "Forgot your password?",
                        signedOutMessage: "You have been signed out."
                    },
                    applications: {
                        pageTitle: "Sites & Tracks",
                        packet: {
                            pageTitle: "Application Packet",
                        },
                        inPreSubmissionWindow: {
                            pageBlurb: ""
                        },
                        inSubmissionWindow: {
                            pageBlurb: "To add sites to your list, click the gray plus sign on the right hand side. Then click the\n \"Select Tracks\" button and choose which tracks to rank from the list that appears.\n You can edit your track selections at any time before the ranking window closes.",
                        },
                        inRankingWindow: {
                            pageBlurb: "Submissions are closed. Rank links can be modified until {{clientCycleSettings.dateSettings.rankListLockDate, mmmm dS}}."
                        },
                        submissionCountMessage_other: "You have currently submitted to {{count}} sites, you may submit to {{remaining}} more.",
                        submissionCountMessage_one: "You have currently submitted to {{count}} site, you may submit to {{remaining}} more.",
                        trackRankCountMessage_other: "You have currently ranked {{count}} tracks, you may rank {{remaining}} more.",
                        trackRankCountMessage_one: "You have currently ranked one track, you may rank {{remaining}} more.",
                        selectedSites: "Selected Sites",
                        unselectedSites: "Unselected Sites",
                        unsubmittedQuestionnaire: "You have not yet completed your student questionnaire. You will not be able to submit applications until that has been completed.",
                        incompleteApproval: "Your student application has not yet been approved. You will not be able to submit applications until that has been completed by your program administrator.",
                        rejectedQuestionnaire: "Your student application has been rejected by the program administration. You will need to review their feedback and adjust your responses before you can submit any applications.",
                        openTrackView: "View Tracks",
                        submitApplication: "Submit Application",
                        applicationSubmitted: "Application has been Submitted",
                        tooltips: {
                            addToSiteList: "Add to Site List"
                        },
                        modals: {
                            maxedMessageTitle: "Application",
                            maxedMessage: "You have already applied to {{count}}, which is the maximum number of sites allowed.",
                            applicationSubmissionsMaxedTitle: "Too Many Submissions",
                            applicationSubmissionsMessage: "You have already submitted the maximum number of packets for this cycle.",
                            siteRankRequiredTitle: "Site Required",
                            siteRankRequiredMessage: "Please add this site to your rank list before submitting your application.",
                            submitPacketTitle: "Confirm",
                            submitPacketMessage: "Are you sure you want to submit your application? This will lock your application packet, submit it to the Training Site, and you will no longer be able to make changes to any of the materials included in this packet.",
                            paymentRequired: "Payment Required",
                            paymentRequiredMessage: "Please pay the application fee before submitting your application. You will now be redirect to the payment page.",
                            questionnaireRequired: "Required Information Missing",
                            questionnaireRequiredMessage: "You must first submit your questionnaire before submitting your application.",
                        },
                        buttons: {
                            applicationPacket: "Application Packet",
                            submittedPacket: "Packet Submitted"
                        }
                    },
                    applicants: {
                        pageTitle: "Applicants",
                        spinnerLoading: "Loading Applicants...",
                        openingsRemaining_one: "Track has {{count}} opening left available to match.",
                        openingsRemaining_other: "Track has {{count}} openings left available to match.",
                        table: {
                            match: "Match Options",
                            email: "Email",
                            name: "Name",
                            schoolName: "School Name",
                            documents: "Documents"
                        },
                        buttons: {
                            hideDocumentList: "Hide Document List",
                            showDocumentList: "Show Document List",
                            pending: "In Draft",
                            matchStudent: "Match this Student"
                        }
                    },
                    order: {
                        confirmOrder: "Confirm Order",
                        noProductsAvailable: "There are no available products to purchase at this time.  Please contact support if you believe this is in error.",
                        processingPayment: "Processing payment - please wait.",
                        unpaidInvoice: "There is an unpaid invoice for your organization. Click here to view.",
                    },
                    materials: {
                        modal: {
                            requestLetterOfReferenceTitle: "Letter Of Reference",
                            requestLetterOfReference: "Your request has been emailed to the referent."
                        },
                        buttons: {
                            requestLetterOfReference: "Email Request for Letter of Reference"
                        },
                        pageTitle: "Application Materials",
                        materialsInPacket: "Some of your Materials are included in a submitted Application Packet. These documents may no longer be changed or removed. If you do not successfully match, your materials will unlock and you will able to revise them for the {{clearingHouseLabel}} phase.",
                        noSubmissions: {
                            inRankingWindow: "You did not submit any applications during the standard submission phase. " +
                                "You may continue to edit your materials in preparation for the {{clearingHouseLabel}} phase which begins on {{clearingHouseDate, mmmm dS}}."
                        },
                        submissions: {
                            inRankingWindow_one: "You submitted one application. Application Materials are now locked and can no longer be edited.",
                            inRankingWindow_other: "You submitted {{count}} applications. Application Materials are now locked and can no longer be edited.",
                            unusedLetter: "A letter of reference was not requested for this materials slot."
                        },
                        preClearingHouseBlurb: {
                            noMatch: "Your application materials may be revised in preparation for the {{clearingHouseLabel}} phase."
                        }
                    },
                    packet: {
                        pageHeader: "Application Packet for {{siteName}}",
                        previewPDF: "Preview Packet PDF",
                        selectElectiveDocuments_one: "Please select an elective document from your materials:",
                        selectElectiveDocuments_other: "Please select up to {{count}} elective documents from your materials:",
                        selectLettersOfReference_one: "Please a letters of reference from your materials:",
                        selectLettersOfReference_other: "Please select exactly {{count}} Letters of Reference from your materials:"
                    },
                    siteDirectory: {
                        tracksAvailable: "Tracks Available",
                        schoolsParticipating: "Schools Participating with this Site",
                        buttons: {
                            addToRankList: "Add to Rank List",
                        }
                    },
                    questionnaire: {
                        buttons: {
                            saveAnswers: "Save Answers"
                        }
                    },
                    fileUpload: {
                        modal: {
                            fileTooLargeTitle: "File Too Large",
                            fileTooLargeMessage: "The file you are trying to upload is too large. Please upload a file smaller than {{maxFileSize}}KB"
                        }
                    },
                    sitesAndTracks: {
                        selectedSites: "Selected Sites",
                        selectedSitesWithAppLimit: "Selected Sites {{selectedCount}} of {{available}}",
                        unselectedSites: "Unselected Sites",
                        blurbs: {
                            locked: "Your site selections have been locked as of {{date, mmm dS, yyyy}}. You may add and remove tracks from your rank list until {{rankListLockDate, mmm dS, yyyy}}}",
                            hasSiteListLockedDate: "Your site list can be changed up until midnight on {{date, mmm dS, yyyy}}. After that time, your site list will be locked and you will not be able to make any changes. This date is set by your program administration, please contact them with any additional questions.",
                        },
                        buttons: {
                            addToSiteList: "Add to Site List"
                        }
                    },
                    studentRankList: {
                        buttons: {
                            addStudent: "Add Student"
                        }
                    },
                    manualMatch: {
                        pageTitle: "Manual Match",
                        blurb: "Use this page to manually match students to sites.",
                        buttons: {
                            match: "Match"
                        }
                    },
                    blurbs: {
                        students: {
                            payment: "",
                            welcome_page: "",
                            materials_pre_clearinghouse: ""
                        },
                        "global-admins": {
                            "tracks_page": "",
                            "programs_page": "",
                        },
                        "directory-admins": {
                            "welcome_page": "",
                            "tracks_page": ""
                        },
                    }
                }
            }
            // here we will place our translations...
        }
});