import dayjs, {Dayjs} from "dayjs";
import React, {useEffect} from 'react';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DateTimePicker} from '@mui/x-date-pickers/DateTimePicker';
import {FormControl} from "@mui/material";
import {LocalizationProvider} from "@mui/x-date-pickers";


interface EditTimestampProps {
    name: string
    label?: string
    value: Date | null
    onChange?: (e: Date | null) => void
    classes?: any
}

export const EditTimestamp = (props: EditTimestampProps) => {
    //console.log("EditTimestamp Got timestamp ", props.value)
    // The first commit of Material-UI
    const [selectedDate, setSelectedDate] = React.useState<Dayjs | null>(dayjs(props.value) || null);

    // Force a re-render to get the latest value
    useEffect(() => {
        setSelectedDate(dayjs(props.value))
    }, [props.value])

    const handleDateChange = (date: Dayjs | null) => {
        //console.log("Changing date", date, typeof date)
        if (date) {
            setSelectedDate(date);
            props.onChange && props.onChange(date.toDate())
        }
    };

    /*
    Note that there is one field that uses user-friendly date format, and one that uses the ISO format. The ISO format one gets sent to the server.
     */
    return (
        <>
            <FormControl className={props.classes?.formControl}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                        label={props.label || props.name}
                        value={selectedDate}
                        onChange={(value: Dayjs | null) => handleDateChange(value)}
                        slotProps={{
                            textField: {
                                name: `${props.name}-datepicker`,
                                id: props.name
                            }
                        }}
                    />
                    <input type="hidden" name={props.name} value={selectedDate?.isValid() ? selectedDate?.toISOString() : ''}/>
                </LocalizationProvider>
            </FormControl>
        </>
    );
}