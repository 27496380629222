import React, {useState} from 'react'

import {Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select} from "@mui/material"
import Input from "@mui/material/Input";
import {useTheme} from "@mui/material/styles";
import User from "../model/User";
import {InternshipSite} from "../../internship-site/model/Organization";
import {makeStyles} from "tss-react/mui";
import {useAppContext} from "../../lib/context";


interface SiteSelectProps {
    sites: Array<InternshipSite>
    user: User | undefined | null
    setSites: (s: number[]) => void
    errors: string | undefined
}

const useStyles = makeStyles()((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
        maxWidth: "100%",
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));


export const SiteSelect = (props: SiteSelectProps) => {
    const {isDebug} = useAppContext()

    const [multiSelect, setMultiSelect] = useState<number[]>(props.user?.sites? props.user.sites: [])

    function getStyles(name: number, multiSelect: number[], theme: any) {
        return {
            fontWeight:
                multiSelect.includes(name)
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const handleChange = (value: number[]) => {
        setMultiSelect(value)
        props.setSites && props.setSites(value)
    }

    const {classes} = useStyles();
    const theme = useTheme();

    const checked: (p: InternshipSite, s: number[]) => boolean = (p, s) => {
        if (p.id  && s.includes(p.id)) return true;

        return false;
    }

    isDebug && console.log("Site errors", props.errors)
    isDebug && console.log("sites", props.sites)

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="user-sites-label">Sites</InputLabel>
            <Select
                labelId="user-sites-label"
                id="user-sites"
                name="sites"
                multiple
                value={multiSelect}
                variant="filled"
                onChange={e => handleChange(e.target.value as number[])}
                input={<Input/>}
                error={!!props.errors}
                renderValue={(selected) => (selected as number[]).flatMap(x => props.sites.filter(y => y.id === x)).map(x => x?.name).join(', ')}
            >
                {props.sites.map((site) => (
                    <MenuItem key={site.id} value={site.id} style={getStyles(site.id ? site.id : 0, multiSelect, theme)}>
                        <Checkbox checked={checked(site, multiSelect)}/>
                        <ListItemText primary={site.name}/>
                    </MenuItem>
                ))}
            </Select>
            <small id="user-sites-help" className="form-text text-muted">Select which sites this person is administrating. {props.errors}</small>
        </FormControl>
    );
}