import {ProgramId} from "./Program";
import {InternshipSite, InternshipSiteTrack, InternshipSiteTrackId} from "../../internship-site/model/Organization";

export type RelationshipOperationId = number
export type OperationId = number

export enum RelationshipOperation {
    ProgramRequested = 1,
    TrackRequested = 2,
    ProgramApproved = 3,
    TrackApproved = 4,
    ProgramRejected = 5,
    TrackRejected = 6,
    ProgramCancelled = 7,
    TrackCancelled = 8
}

export interface ProgramTrackRelationship {
    programId: ProgramId
    siteTrackId: InternshipSiteTrackId
    relationshipId: RelationshipOperationId
    operationId: OperationId
    operationName: string
}

export interface ProgramSiteRelationship {
    site: InternshipSite,
    siteTrack: InternshipSiteTrack,
    relationship?: ProgramTrackRelationship
}
