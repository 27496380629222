import {
    Client,
    ClientCycleId,
    ClientSettings, ClientSettingsOps, PacketSlotDefinition,
    SlotGroup, SlotGroups,
    SlotId
} from '../../client/model/Client'
import {Program, ProgramId} from "../../program/model/Program";
import {Address} from "../../components/model/Address";
import {
    InternshipSite, InternshipSiteId,
    InternshipSiteTrack,
    InternshipSiteTrackId
} from "../../internship-site/model/Organization";
import {School} from "../../school/model/School";
import {
    MaterialElement,
    PacketRef, PacketRefOps,
    StudentApplication, StudentApplicationStatus,
    StudentMaterials, StudentMaterialsDocument,
    StudentPacketApplications
} from "../../application/model/StudentMaterials";
import {StudentUserInformation} from "../../login/model/UserInformation";
import {LetterOfReference, LetterOfReferenceStatuses} from "../../application/model/LetterOfReference";
import {appConfig} from "../../conf/conf";
import {Optional} from "../../lib/Optional";
import {headOption} from "../../lib/util";
import {
    QuestionnaireSubmissionAction,
    QuestionnaireSubmissionActionTypes
} from "../../questionnaire/model/Questionnaire";

export type StudentId = string

export interface StudentTrack {
    id: number
    position: number
    siteTrackId: InternshipSiteTrackId
    track: InternshipSiteTrack
    site: InternshipSite
}

interface Student {
    programId: ProgramId

    personalEmail?:string
    homePhone?:string
    workPhone?:string
    mobilePhone?:string

    sites: InternshipSite[]
    tracks: StudentTrack[]
    school?: School
    program?: Program

    matchedTrackId?: InternshipSiteTrackId

    signInCount?: number
    lastSignInTimestamp?: Date
    lastActivityTimestamp?: Date
    studentWorkflowStepId?: number
    createdTimestamp?: string
    modifiedTimestamp?: Date
    address?:Address

    clientCycleId: ClientCycleId
    paid: boolean
    applicationLimit: number
    attestationTimestamp: Date
    hasWithdrawn: boolean

    materials: StudentMaterials
    studentApplicationPackets: StudentPacketApplications
}

export default Student;

export class StudentOps {
    static userApplicationsForSiteTrack = (userInformation: StudentUserInformation, siteId: InternshipSiteId, trackId: InternshipSiteTrackId | undefined): StudentApplication | undefined => {
        console.log("Finding for trackId, siteId", trackId, siteId)
        console.log("userApplication for SiteTrack", userInformation.student.studentApplicationPackets?.applications)
        return (userInformation.student?.studentApplicationPackets?.applications || ([] as StudentApplication[]))
            .find(x => x.siteId === siteId && (!trackId || x.trackId === trackId))
    }

    static packetSlotEntriesByType = (clientSettings: ClientSettings, userPacketRefs: PacketRef[]) => (slotGroup: SlotGroup): PacketRef[] => {
        const slotIds = ClientSettingsOps.packetSlotsByType(clientSettings, slotGroup).map(x => x.id)
        return userPacketRefs.filter(x => slotIds.includes(x.packetSlotId))
    }

    static findUserPacketSlot = (userPacketRefs: Array<PacketRef>) => (packetSlotId: string): PacketRef | undefined =>
        userPacketRefs && userPacketRefs.find(x => x.packetSlotId === packetSlotId)

    static packetRefsBySiteIdTrackId = (client: Client, userInformation: StudentUserInformation, siteId: InternshipSiteId, trackId: InternshipSiteTrackId | undefined) => {
        const userApplication = StudentOps.userApplicationsForSiteTrack(userInformation, siteId, trackId)
        if (userApplication) {
            const entries: Array<[string, PacketRef]> = client.settings.clientCycleSettings.applicationSuiteSettings.packetSlotsDefinition.slotDefinitions
                .map(e => [e.id,
                    (StudentOps.findUserPacketSlot(userApplication.packetRefs)(e.id) || PacketRefOps.emptyPacketRef(e.id, siteId, trackId))])

            return Object.fromEntries(new Map<string, PacketRef>(entries))
        } else {
            // Build a new set overlaying the student materials into the relevant slots
            const entries: Array<[string, PacketRef]> = client.settings.clientCycleSettings.applicationSuiteSettings.packetSlotsDefinition.slotDefinitions
                .map(e => [e.id, StudentOps.studentMaterialToPacket(StudentOps.findUserMaterialSlot(userInformation, e.id), siteId, trackId) || PacketRefOps.emptyPacketRef(e.id, siteId, trackId)])

            return Object.fromEntries(new Map<string, PacketRef>(entries))
        }
    }

    static submissionStatusBySiteIdTrackId = (userInformation: StudentUserInformation, siteId: InternshipSiteId, trackId: InternshipSiteTrackId | undefined): Optional<StudentApplicationStatus> => {
        return Optional.apply(StudentOps.userApplicationsForSiteTrack(userInformation, siteId, trackId))
            .map(x => x.applicationStatus)
    }


    static findUserMaterialSlot = (userInformation: StudentUserInformation, slotId: SlotId): MaterialElement | undefined => {
        return (userInformation.student.materials?.materials || []).find(x => x.materialSlotId === slotId)
    }

    static studentMaterialToPacket = (material: MaterialElement | undefined, siteId: InternshipSiteId, trackId: InternshipSiteTrackId | undefined): PacketRef | undefined => {
        appConfig.isDebug && console.log("Converting student material to packet", material)
        return material && {
            id: "",
            packetSlotId: material.materialSlotId,
            createdTimestamp: new Date(),
            storageRef: material.storageRef,
            documentId: material.document?.id,
            siteId: siteId,
            trackId: trackId,
            submitted: false
        }
    }

    static letterOfReferenceBySlotId = (userInformation: StudentUserInformation, slotId: SlotId): LetterOfReference | undefined => {
        console.log("Finding LOR for", slotId, userInformation)
        return userInformation.student.materials?.lettersOfReference.find(x => x.slotId === slotId)
    }

    static lastQuestionnaireAction = (student: StudentUserInformation): Optional<QuestionnaireSubmissionAction> => {
        return headOption(student.student.materials?.actions)
    }

    static isStudentQuestionnaireComplete = (student: StudentUserInformation): boolean =>
        StudentOps.lastQuestionnaireAction(student).filter(x => x.actionType === QuestionnaireSubmissionActionTypes.approved).isPresent()

    static hasPaid = (student: StudentUserInformation) => student.student.paid || student.student.school?.schoolPaid
    static eligibleToApply = (student: StudentUserInformation) => StudentOps.hasPaid(student)

    static studentPacketDocuments = (userInformation: StudentUserInformation): StudentMaterialsDocument[] => {
        return userInformation.student.studentApplicationPackets.documents
    }

    static studentMaterialDocuments = (userInformation: StudentUserInformation) => {
        const studentValidStorageRefs = (userInformation.student.materials?.materials || []).map(x => x.storageRef)
        const materialDocuments: StudentMaterialsDocument[] = userInformation.student.materials.documents.filter(x => studentValidStorageRefs.includes(x.document.storageRef)) || []

        return {materialDocuments}
    }

    static electiveDocuments = (userInformation: StudentUserInformation): StudentMaterialsDocument[] => {
        console.log("mat docs", StudentOps.studentMaterialDocuments(userInformation).materialDocuments)
        return StudentOps.studentMaterialDocuments(userInformation).materialDocuments
            .filter(x => x.documentType === SlotGroups.electiveDocument)
    }

    static lettersOfReference = (userInformation: StudentUserInformation) => {
        return userInformation.student.materials?.lettersOfReference
            .filter(x => [LetterOfReferenceStatuses.completed, LetterOfReferenceStatuses.requested].includes(x.state))
            .map(x => ({
                letterOfReference: x,
                document: userInformation.student.materials?.documents.find(e => e.document.id === x.documentId)
            }))
    }

    static makePacketRef = (student: StudentUserInformation, documentId: string, siteId: InternshipSiteId, trackId: InternshipSiteTrackId | undefined): PacketRef => {
        const ref = Optional.apply(student.student.materials?.documents.find(e => e.document.id === documentId))
            .orElse(Optional.apply(student.student.studentApplicationPackets.documents.find(e => e.document.id === documentId)))
            .map(doc => ({
                id: undefined,
                packetSlotId: doc.slotId,
                documentId: doc.document.id,
                createdTimestamp: new Date(),
                storageRef: doc.document.storageRef,
                siteId: siteId,
                trackId: trackId,
                submitted: false
            }))

        if (ref.isEmpty()) {
            throw new Error("Failed to find document ID " + documentId)
        }

        return ref.get()
    }

    static newStudentApplication = (packetSlots: PacketSlotDefinition[], student: StudentUserInformation, siteId: InternshipSiteId, trackId: InternshipSiteTrackId | undefined): StudentApplication => {
        const makePacketSpecifics = () => StudentOps.studentPacketDocuments(student)
            .filter(x => x.documentType === SlotGroups.specificRequiredDocument)
            .map(x => StudentOps.makePacketRef(student, x.document.id, siteId, trackId))

        console.log("Packet specifics", makePacketSpecifics())

        const makeSpecifics = () => StudentOps.studentMaterialDocuments(student).materialDocuments
            .filter(x => x.documentType === SlotGroups.specificRequiredDocument)
            .map(x => StudentOps.makePacketRef(student, x.document.id, siteId, trackId))

        const directSlotIds = packetSlots.filter(x => x.source === "direct").map(e => e.id)

        const newApp: StudentApplication = {
            // Don't include directs as they are "direct" to the packet, no prefills from materials
            packetRefs: [...makePacketSpecifics(), ...makeSpecifics()].filter(e => !directSlotIds.includes(e.packetSlotId)),
            siteId: siteId,
            trackId: trackId,
            applicationStatus: "Created",
            adminApprovalQuestionnaireSubmissionId: undefined
        }

        console.log("New app is", newApp)
        return newApp
    }

    static studentApplicationsSubmittedCount = (userInformation: StudentUserInformation) => (userInformation.student?.studentApplicationPackets?.applications.filter(x => StudentOps.isApplicationSubmitted(x)).length) || 0

    static studentHasAppliedForSite = (userInformation: StudentUserInformation, siteId: InternshipSiteId) => userInformation.student.sites.map(x => x.id).includes(siteId)

    static studentHasSubmittedToTrack = (userInformation: StudentUserInformation, siteId: InternshipSiteId, trackId: InternshipSiteTrackId) =>
        StudentOps.isApplicationSubmitted(StudentOps.userApplicationsForSiteTrack(userInformation, siteId, trackId))


    static rankedCount = (client: Client, userInformation: StudentUserInformation) =>
        userInformation.student.tracks?.length || 0

    static remainingCount = (client: Client, userInformation: StudentUserInformation): number =>
        parseInt(client.settings.defaultApplicationLimit || "10") - (userInformation.student.tracks?.length || 0)

    static materialSlotDocumentInSubmission(userInformation: StudentUserInformation, slotId: SlotId): boolean {
        return StudentOps.documentInSubmission(
            userInformation,
            StudentOps.findUserMaterialSlot(userInformation, slotId)?.document?.id || ""
        )
    }

    static documentInSubmission = (userInformation: StudentUserInformation, documentId: string): boolean => {
       return userInformation.student.studentApplicationPackets.applications.filter(x => StudentOps.isApplicationSubmitted(x))
           .flatMap(x => x.packetRefs.flatMap(e => e.documentId)).includes(documentId)
    }

    static isApplicationSubmitted = (application: StudentApplication | undefined): boolean => ["Submitted", "Approved"].includes(application?.applicationStatus || "none")
    static isApplicationArchived = (application: StudentApplication | undefined): boolean => ["Archived"].includes(application?.applicationStatus || "none")

    static didNotMatch = (userInformation: StudentUserInformation) => userInformation.student.matchedTrackId === undefined

    static isTrackVisible = (userInformation: StudentUserInformation, trackId: InternshipSiteTrackId) =>
        (userInformation.student?.program?.tracks || []).map(e => e.track.id).includes(trackId)

}