import {QuestionnaireElementProps} from "../../model/QuestionnaireList";
import React from "react";

export const ElementLabel = ({row}: QuestionnaireElementProps) => {
    switch (row.elementType.type) {
        case 'Question':
            return (<React.Fragment>{row.question && row.question.label}</React.Fragment>)
        case 'Short Section Heading':
            return (<React.Fragment>{row.content}</React.Fragment>)
        case 'Subheading':
            return (<React.Fragment>{row.content}</React.Fragment>)
        case 'Formatting Line Break':
            return (<React.Fragment>&lt;&lt;Line Break&gt;&gt;</React.Fragment>)
        case 'HTML Content':
            return (<div dangerouslySetInnerHTML={{
                __html: row.content || ""
            }}></div>)
        default:
            return (<React.Fragment>"None"</React.Fragment>)
    }
}
