import React from 'react';
import '../student/Student.css';
import InternshipSiteForm from "./component/InternshipSiteForm";
import InternshipSiteList from "./component/InternshipSiteList";
import {useParams} from "react-router-dom";

import {LoginStateCard} from "../components/LoginStateCard";
import {Page} from "../components/Page";
import {useAtomValue} from "jotai";
import {clientAtom, loginStateAtom} from "../Atoms";

type InternshipSiteParams = {
    orgId: string
    siteId: string
}

const InternshipSites = () => {
    const params = useParams<InternshipSiteParams>()

    const loginState = useAtomValue(loginStateAtom)
    const client = useAtomValue(clientAtom)

    const {useLoginStateCard, component} = LoginStateCard(loginState, client)

    return useLoginStateCard ? component : (
        <Page navName="Organization">
            {params.orgId ? (
                <div className="justify-content-center h-100">
                    <InternshipSiteForm orgId={params.orgId} siteId={params.siteId}/>
                </div>
            ) : (<InternshipSiteList/>)}
        </Page>
    )
}

export default InternshipSites
