import React, {useEffect, useMemo, useState} from 'react';
import '../student/Student.css';

import {useAppContext} from "../lib/context"
import {ProgramSelect} from "./component/ProgramSelect";
import {Program, ProgramId} from "./model/Program";
import {Box, Button, Card, CardContent, FormControl, Typography} from "@mui/material";
import {API, api, apiError} from "../lib/Api";
import {ProgramSiteRelationship, RelationshipOperation as RO} from "./model/ProgramSiteRelationship";
import {PageBlurb} from "../components/PageBlurb";
import {Page} from "../components/Page";
import {messageModal} from "../components/modal/InfoModal";
import useListStudentService from "../student/lib/StudentService";
import {requireOneOfGroup} from "../lib/util";
import Groups from "../model/Groups";
import {AuthenticationFailed} from "../components/AuthenticationFailed";
import {makeStyles} from "tss-react/mui";
import {ProgramViewer} from "../user/model/User";
import {EditTimestamp} from "../client/component/EditTimestamp";
import SpinnerCard from "../components/SpinnerCard";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../Atoms";
import {useMutation, useQuery} from "@tanstack/react-query";
import {UseQueryResult} from "@tanstack/react-query/src/types";
import {UserInformation} from "../login/model/UserInformation";
import {InternshipSiteTrack} from "../internship-site/model/Organization";
import {clientAtom} from "../Atoms";
import {Client} from "../client/model/Client";

const useStyles = makeStyles()((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        display: "block"
    },
}));

interface StudentTotals {
    totalStudents: number
    paidStudents: number
    incompleteRegistrations: number
}


const ProgramOverview = () => {
    const {setModal, isDebug } = useAppContext();

    const userInformation: UserInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client

    const [programId, setProgramId] = useState<ProgramId>(0)
    const [sitelistLockDate, setSitelistLockDate] = useState<Date | null>(null)
    const [lockDateChanged, setLockDateChanged] = useState<boolean>(false)
    const [studentTotals, setStudentTotals] = useState<StudentTotals>()
    const [sitelistLockDateLoading, setSiteListLockDateLoading] = useState<boolean>(false)

    const programService: UseQueryResult<Program[]> = useQuery({
        queryFn: () => api<Program[], undefined>(API.listProgram(), userInformation, client.id)
            .then(response => response.data.sort((a: Program, b: Program) => (a.school?.name + a.name).localeCompare(b.school?.name + b.name)))
            .then(programs => {
                console.log("Get programs", programs)
                if (programs[0]?.id) {
                    isDebug && console.log("Setting programId", programs[0].id)
                    if (programId === 0) {
                        setProgramId(programs[0].id)
                    }
                    programs[0].sitelistLockDate && setSitelistLockDate(new Date(programs[0].sitelistLockDate))
                }
                else {
                    console.log("Programs Fetch Failed", programs)
                }
                return programs
            }),
        queryKey: ["listProgram", programId],
        staleTime: 86400000,
    })

    const studentService = useListStudentService()
    const programs: Array<Program> = useMemo(() => programService.isSuccess ? programService.data: [] as Array<Program>, [programService])

    const program = useMemo(() => programs.find(x => x.id === programId), [programs, programId])

    const {classes} = useStyles()

    useEffect(() => {
        if (studentService.isSuccess) {
            const students = studentService.data.filter(x => x.student?.programId === programId)
            setStudentTotals({
                totalStudents: students.length,
                paidStudents: students.filter(x => x.student?.paid || x.student?.school?.schoolPaid).length,
                incompleteRegistrations: students.filter(x => !(x.student?.attestationTimestamp)).length
            })
        }
    }, [studentService, setStudentTotals, programId])

    const eligibleTracksQuery: UseQueryResult<InternshipSiteTrack[]> = useQuery({
        queryFn: () => api<ProgramSiteRelationship[], undefined>(API.programSiteRelationships(programId), userInformation, client.id)
                .then(resp =>
                    resp.data.filter(a => [RO.ProgramApproved, RO.TrackApproved].map(x => x.valueOf()).includes(a.relationship?.operationId || 0))
                        .map(x => x.siteTrack)),
        queryKey: ["eligibleTracks", programId],
        staleTime: 86400000,
        enabled: programService.isSuccess
    })

    const programViewerQuery: UseQueryResult<ProgramViewer> = useQuery({
        queryFn: () => api<ProgramViewer, undefined>(API.programViewerAccount(programId), userInformation, client.id)
                .then(resp => resp.data)
                .catch((x) => apiError(x, setModal)),
        queryKey: ["programViewer", programId],
        enabled: programService.isSuccess
    })

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setSiteListLockDateLoading(true)
        isDebug && console.log("SiteListLock", sitelistLockDate?.toISOString)
        submitSitelistLockDate(sitelistLockDate)
    }

    const submitSiteListLockDateMutate = useMutation({
        mutationFn: (x: {programId: ProgramId, sitelistLockDate: Date | null}) =>
            api(API.programSitelistLockDate(x.programId), userInformation, client.id, {sitelistLockDate: x.sitelistLockDate?.toISOString() || null}),
        onSuccess: () => programService.refetch()
    })

    const submitSitelistLockDate = (x: Date | null) => {
        if (programId) {
            submitSiteListLockDateMutate.mutateAsync({programId, sitelistLockDate: x}).then(x => {
                    setModal && setModal({...messageModal("Site List Lock Date", "Site List Lock Date Successfully Saved"),
                        onClose: (action) => {
                            setLockDateChanged(false)
                            setSiteListLockDateLoading(false)
                        }
                    })
                })
        }
    }

    const programIdHandler = (programId: ProgramId) => {
        if (programService.isSuccess) {
            const program = programService.data.find(x => x.id === programId)
            if (program && program.id) {
                setSitelistLockDate(program.sitelistLockDate || null)
                setProgramId(program.id)
            }
        }
    }


    if (!requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin, Groups.programAdmin, Groups.clientAdmin])) {
        isDebug && console.log("Returning auth failed")
        return (<AuthenticationFailed/>)
    }

    if (isDebug) {
        console.log("programService", programService.isSuccess)
        console.log("eligibleTracks", eligibleTracksQuery.isSuccess)
        console.log("programViewer", programViewerQuery.isSuccess)
        console.log("studentService", studentService.status)
        console.log("programId", programId)
    }

    return programService.isSuccess && eligibleTracksQuery.isSuccess && programViewerQuery.isSuccess && studentService.isSuccess ? (
        <Page navName="Program Overview">
            <Card>
                <CardContent>
                    <PageBlurb name="program_overview"/>
                    <ProgramSelect programs={programs} setProgramId={programIdHandler} programId={programId}
                                   setLoading={() => {
                                   }}/>
                    <Box p={2}>
                        <Typography variant="h4">{program?.school?.name} - {program?.name}</Typography>
                    </Box>

                    <Box p={2}>
                        <div>Total Students: {studentTotals?.totalStudents}</div>
                        <div>Paid Students: {studentTotals?.paidStudents}</div>
                    </Box>

                    <Box p={2}>
                        <Typography variant="h6">Agencies</Typography>
                        <div>Tracks Approved for <u>this</u> Program: {eligibleTracksQuery.data?.length}</div>
                        <div>Total Openings: {eligibleTracksQuery.isSuccess && eligibleTracksQuery.data.map(x => x.openings).reduce((a, b) => a + b, 0)}</div>
                    </Box>

                    <Box p={2}>
                        <div>Incomplete Student Registrations: {studentTotals?.incompleteRegistrations || 0}</div>
                    </Box>

                    <Box p={2}>
                        <Typography variant="h4">Student Directory Login Details</Typography>
                        <div>Login: {programViewerQuery.data.email}</div>
                        <div>Password: {programViewerQuery.data.directoryViewPassword}</div>
                    </Box>

                    {client?.settings.useSiteListLockDate === "yes" &&
                    <Box p={2}>
                        <Typography>Program-specific Dates</Typography>
                        {sitelistLockDateLoading ? <SpinnerCard/> : <form onSubmit={handleSubmit}>
                            <Box display="flex" flexWrap="nowrap" alignItems="center">
                                <Box>
                                    <FormControl className={classes.formControl}>
                                        <EditTimestamp name="sitelistLockDate" value={sitelistLockDate}
                                                       onChange={(e: Date | null) => {
                                                           isDebug && console.log("Changing", e)
                                                           setLockDateChanged(true)
                                                           if (e) {
                                                               setSitelistLockDate(e)
                                                           } else {
                                                               setSitelistLockDate(null)
                                                           }
                                                       }}
                                                       classes={classes}
                                        />
                                    </FormControl>
                                </Box>
                                <Box>
                                    <Button variant="outlined" color="primary" onClick={e => {
                                        setSitelistLockDate(null)
                                        setLockDateChanged(true)
                                    }}>Clear Lock Date</Button>
                                </Box>
                                <Box>
                                    {lockDateChanged &&
                                    <FormControl>
                                        <Button variant="outlined" type="submit" color="primary"
                                                style={{marginLeft: 10}}>Save Site Lock Date</Button>
                                    </FormControl>
                                    }
                                </Box>
                            </Box>
                            <div>
                                <i>Note: you can optionally lock student choices for their practicum sites (not
                                    individual tracks)
                                    after the above date. Leaving it blank will allow students to adjust their site list
                                    until the ranking deadline.</i>
                            </div>
                        </form>
                        }
                    </Box>
                    }

                </CardContent>
            </Card>
        </Page>
    ) : <SpinnerCard message="Loading..."/>
}

export default ProgramOverview
