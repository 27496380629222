import React from "react"
import {API, api, apiError} from "../../../../lib/Api";
import {useAppContext} from "../../../../lib/context";
import {useNavigate, useParams} from "react-router-dom";
import {QuestionnaireAnswersView} from "../../submission/QuestionnaireAnswersView";
import {LoginStateCard} from "../../../../components/LoginStateCard";
import {
    InternshipSite,
    InternshipSiteId,
    InternshipSiteTrack, InternshipSiteTrackId,
    Organization,
    trackTypes
} from "../../../../internship-site/model/Organization";
import {Box, Button, Grid, Hidden, Paper, Theme, Tooltip, Typography} from "@mui/material";
import SpinnerCard from "../../../../components/SpinnerCard";
import {SiteRelationships} from "../../submission/SiteRelationships";
import {Page, PageBody, PageHeader} from "../../../../components/Page";
import {messageModal} from "../../../../components/modal/InfoModal";
import AssignmentIcon from '@mui/icons-material/Assignment';
import {AddCircle} from "@mui/icons-material";
import {WithdrawTrackButton} from "../../../../student/components/WithdrawTrackButton";
import {useStudentOrganizationService} from "../../../../student/lib/StudentOrganizationService";
import {requireGroup, requireOneOfGroup} from "../../../../lib/util";
import Groups from "../../../../model/Groups";
import { Client, ClientSettingsOps } from "../../../../client/model/Client";
import {UserInformation, UserOps} from "../../../../login/model/UserInformation";
import {makeStyles} from "tss-react/mui";
import {userInformationAtom} from "../../../../Atoms";
import {useAtomValue} from "jotai";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import {UseQueryResult} from "@tanstack/react-query/src/types";
import {useQuestionnaireService} from "../../../QuestionnaireService";
import {clientAtom, loginStateAtom, refreshUser} from "../../../../Atoms";
import {applyToWhere, ManageApplicationButton} from "../../../../student/components/AppliedSiteList";
import {useTranslation} from "react-i18next";
import {StudentOps} from "../../../../student/model/Student";

const useStyles = makeStyles()((theme: Theme) => ({
        root: {
            margin: theme.spacing(1),
            padding: theme.spacing(1)
        },
        paperHeading: {
            padding: theme.spacing(1)
        },
        scrollingMaxContainer: {
            maxHeight: "400px",
            overflowY: "scroll"
        }
    }))

export const siteTrackFilter = (siteTrack: InternshipSiteTrack, userInformation?: UserInformation, client?: Client) => {
    console.log("optin for ", siteTrack.clearingHouseOptIn, siteTrack.name)
    return (ClientSettingsOps.clearingHousePassed(client?.settings) && (requireOneOfGroup(userInformation?.userGroups, [Groups.directoryViewer, Groups.student]))) ? (siteTrack.clearingHouseOptIn === true) : true
}

export const TrackInformation = (props: { site: InternshipSite, siteTrack: InternshipSiteTrack, showManageSiteButton: boolean }) => {
    const {setModal, isDebug} = useAppContext()

    const client = useAtomValue(clientAtom) as Client
    const userInformation = useAtomValue(userInformationAtom)

    const localClientId = client.id

    const {t} = useTranslation()
    const queryClient = useQueryClient()

    const submittedToTrack = userInformation.student && StudentOps.studentHasSubmittedToTrack(userInformation, props.siteTrack.internshipSiteId, props.siteTrack.id!)

    console.log("Submitted to track", props.siteTrack.id, submittedToTrack)

    const programSiteListLocked = userInformation?.student && ClientSettingsOps.siteListLocked(userInformation)
    const locked = (client.settings.cycle.matchType !== "manual") && ((ClientSettingsOps.rankListLocked(client.settings) ||
        (programSiteListLocked && !userInformation?.student?.sites.map(x => x.id).includes(props.siteTrack.internshipSiteId))))


    const maxed = (userInformation?.student?.sites.length || 0) >= (userInformation?.student?.applicationLimit || 0)

    const applyToSiteTrack = (siteTrackId: InternshipSiteTrackId | undefined, siteId: InternshipSiteId | undefined) => {
        isDebug && console.log("Firing apply", userInformation, siteTrackId)
        if (userInformation?.guid && siteTrackId) {
            if (userInformation?.student?.sites.map(x => x.id).includes(siteId) || !maxed) {
                api(API.addSiteTrackToStudent(userInformation.guid, siteTrackId), userInformation, localClientId)
                    .then(x => {
                        setModal && setModal(messageModal("Applied", "You have add this track to your rank order list."))
                        refreshUser(queryClient).then(() => x)
                    }).catch(x => apiError(x, setModal))
            } else {
                // TODO Make this a common thing
                setModal && setModal(messageModal("Application", `You have already applied to ${userInformation?.student?.applicationLimit} sites, which is the maximum number of sites allowed.`))
            }
        } else {
            console.log("Missing user guid or siteTrackId", userInformation?.guid, siteTrackId)
        }
    }

    const {applyToTrack} = applyToWhere(client)

    const siteComponent = () => {

        if (userInformation && userInformation.student && props.siteTrack.id && (!ClientSettingsOps.matchDatePassed(client?.settings) || client.settings.cycle.matchType === "manual" )) {
            return userInformation.student.tracks.map(x => x.track.id).includes(props.siteTrack.id) ?
                <Box display="flex" justifyContent="end" m={1}>
                            {applyToTrack &&
                                <ManageApplicationButton userInformation={userInformation} site={props.site} track={props.siteTrack}/>
                            }
                            {props.showManageSiteButton &&
                            <Button color="primary" startIcon={<AssignmentIcon/>} href="/applications">Review Site List</Button>}
                            <WithdrawTrackButton siteTrackId={props.siteTrack.id}/>
                </Box> : (locked ||
                    <Box display="flex" justifyContent="end" m={1}>
                        {applyToTrack &&
                            <ManageApplicationButton userInformation={userInformation} site={props.site} track={props.siteTrack}/>
                        }
                        {((!client.settings.clientCycleSettings.applicationSuiteSettings.useApplicationSuite || submittedToTrack) && !locked)&&
                    <Button color="primary" startIcon={<AddCircle/>}
                            onClick={e => applyToSiteTrack(props.siteTrack.id, props.siteTrack.internshipSiteId)}
                            style={{whiteSpace: "nowrap"}}>
                        <Hidden lgUp>{t("buttons.add")}</Hidden>
                        <Hidden lgDown>{t("siteDirectory.buttons.addToRankList")}</Hidden>
                    </Button>
                        }
                    </Box>
                );
        } else return <React.Fragment/>
    }

    console.log("Student", userInformation?.student)

    return (
        <Grid container spacing={1} style={{paddingBottom: "20px"}}>
            <Grid item sm={12} xs={12} >
                <Grid container style={{backgroundColor: "rgba(0,0,0,0.05)"}}>
                    <Grid item xs={12} lg={8}>
                        <Box display="flex" flexWrap="nowrap" alignItems="center" >
                            <Box> <Typography variant="h6"
                                              style={{paddingLeft: "0.5rem"}}>{props.siteTrack.name}</Typography> </Box>
                            <Box style={{paddingLeft: 30, whiteSpace: "nowrap"}}>
                                {(!ClientSettingsOps.matchDatePassed(client?.settings) || ClientSettingsOps.clearingHousePassed(client?.settings)) ?
                                    <Typography>Openings: {props.siteTrack.openings}</Typography>
                                    : <Typography>Openings: &lt;Unavailable until {client?.settings.clearingHouseLabel} starts&gt;</Typography>
                                }
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} lg={4} justifyContent="end">
                        {userInformation?.student && siteComponent()}
                    </Grid>
                </Grid>
            </Grid>

            {
                props.siteTrack.description && props.siteTrack.description.length > 0 && <>
                    <Grid item sm={2} xs={12}><b>Description:</b></Grid>
                    <Grid item sm={10} xs={12}>{props.siteTrack.description}</Grid>
                </>}

            <Grid item xs={12} sm={2}><b>Track ID:</b></Grid>
                <Grid item xs={12} sm={10}>{props.siteTrack.continuousTrackId}</Grid>

            {client?.settings.useTrackType === "yes" && (<React.Fragment>
                <Grid item sm={2} xs={12}><b>Track Type:</b></Grid>
                <Grid item sm={10} xs={12}>{(trackTypes as any)[props.siteTrack.siteTrackType]}</Grid>
            </React.Fragment>)
            }
        </Grid>
    )
}

export const DirectoryEntry = () => {
    const {isDebug, setModal} = useAppContext()

    const loginState = useAtomValue(loginStateAtom)
    const userInformation: UserInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client

    const params = useParams()

    const orgId = parseInt(params.orgId || '0')
    const questionnaireId = parseInt(params.id || '0')
    const siteId = parseInt(params.siteId || '0')

    console.log("Directory entry for", orgId, questionnaireId, siteId)

    const {t} = useTranslation()
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const {classes} = useStyles()

    const studentOrganizationQuery = useStudentOrganizationService({clientId: client.id})

    const maxed = ((userInformation?.student?.applicationLimit || 10)) === (userInformation?.student?.sites?.length || 0)

    const {useLoginStateCard, component} = LoginStateCard(loginState, client)

    const locked = ClientSettingsOps.rankListLocked(client.settings)

    const questionnaireQuery = useQuestionnaireService(questionnaireId, userInformation, client)

    const validTrackIds = studentOrganizationQuery.status === "success" ? studentOrganizationQuery.data.flatMap(x => x.internshipSite.siteTracks
        .filter(x => siteTrackFilter(x))
        .map(x => x.id)) : []

    isDebug && console.log("Valid track ids", validTrackIds)

    const filterOrganization: (org: Organization) => Organization = (org: Organization) => {
        isDebug && console.log("Building filtered orgs")
        return {
            ...org,
            internshipSite: {
                ...org.internshipSite,
                siteTracks: org.internshipSite.siteTracks.filter(x => validTrackIds.includes(x.id))
            }
        } as Organization
    }

    const selectFilter = (): ((org: Organization) => Organization) => {
        if (requireGroup(userInformation.userGroups, Groups.student)) {
            if (orgId && client.id > 0 && studentOrganizationQuery.status === "success") {
                return filterOrganization;
            }
            else {
                return (org: Organization) => org
            }
        } else {
            return (org: Organization) => org
        }
    }

    const organizationQuery: UseQueryResult<Organization> = useQuery({
        queryFn: () => api<Organization, any>(API.getOrganization(orgId), userInformation, client.id)
            .then(x => selectFilter()(x.data as Organization))
            .catch((x) => apiError(x, setModal)),
        queryKey: ["getOrganization", orgId],
        staleTime: 86400000
    })

    const addSiteToStudentMutation = useMutation({
        mutationFn: (data: {siteId: InternshipSiteId, guid: string}) => api(API.addSiteToStudent(userInformation.guid, siteId), userInformation, client.id)
                .then(x => x.data)
                .catch(x => apiError(x, setModal)),
        onSuccess: () => {
            setModal && setModal(messageModal("Applied", "You have added this site to your site list"))
            refreshUser(queryClient)
        }
    })

    const applyToSite = (internshipSiteId: number | undefined) => {
        isDebug && console.log("Firing apply", userInformation, internshipSiteId)
        if (internshipSiteId) {
            if (!maxed) {
                addSiteToStudentMutation.mutate({siteId: internshipSiteId, guid: userInformation.guid})
            } else {
                setModal && setModal(messageModal("Application", `You have already applied to ${userInformation?.student?.applicationLimit} sites, which is the maximum number of sites allowed.`))
            }
        }
    }

    const programSiteListLocked = userInformation?.student && ClientSettingsOps.siteListLocked(userInformation)

    return useLoginStateCard ? component : organizationQuery.isSuccess ? (
        <Page navName="Directory">
            <PageHeader
                title={"Directory Profile " + (organizationQuery.data?.internshipSite ? `for ${organizationQuery?.data.internshipSite.name}` : "")}
                subTitle={"Site Id: " + organizationQuery.data?.internshipSite.continuousId}>
            {userInformation?.student && !ClientSettingsOps.matchDatePassed(client?.settings) &&
                <Box>
                    {(userInformation?.student &&
                        userInformation?.student?.sites?.map(x => x.id).includes(organizationQuery.data?.internshipSite.id || 0)) || programSiteListLocked ?
                        <Tooltip title="Manage your Application">
                            <Button startIcon={<AssignmentIcon/>} onClick={e => navigate("/applications")}>
                                Review Site List
                            </Button>
                        </Tooltip> : (locked ||
                            <Tooltip title="Add this site to your site list">
                                <Button startIcon={<AssignmentIcon/>} onClick={e => applyToSite(organizationQuery.data?.internshipSite.id)}>
                                    {t("addToSiteList")}
                                </Button>
                            </Tooltip>
                        )
                    }
                </Box>
                }
            </PageHeader>
            <PageBody>
                <Typography className={classes.paperHeading} variant="h5">{t("siteDirectory.tracksAvailable")}</Typography>
                <div className={classes.scrollingMaxContainer}>
                    {organizationQuery.data.internshipSite.siteTracks
                        .filter(x => UserOps.isTrackVisible(userInformation, x.id!))
                        .map(track => (
                        <Paper className={classes.root} key={track.id} id={`track-container-${track.id}`}>
                            <TrackInformation site={organizationQuery.data.internshipSite} siteTrack={track} showManageSiteButton={true}/>
                        </Paper>
                    ))}
                </div>
                <Paper className={classes.root}>
                    <Typography className={classes.paperHeading} variant="h5">{t("siteDirectory.schoolsParticipating")}</Typography>
                    <div className={classes.scrollingMaxContainer}>
                        <SiteRelationships siteId={siteId}/>
                    </div>
                </Paper>
                {questionnaireQuery.isSuccess ? (
                    <Paper className={classes.root}>
                        <QuestionnaireAnswersView questionnaire={questionnaireQuery.data.elements} questionnaireId={questionnaireId}
                                                  siteId={siteId}/>
                    </Paper>
                ) : <SpinnerCard message="Loading directory entry..."/>}
            </PageBody>
        </Page>
    ) : <SpinnerCard message="loading..."/>
}