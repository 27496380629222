import React, {MouseEvent, useEffect, useState} from 'react';
import './Student.css';

import {useAppContext} from "../lib/context"
import {TrackRankList} from "./components/TrackRankList";
import {questionModal} from "../components/modal/InfoModal";
import {API, api} from "../lib/Api";
import {useNavigate} from "react-router-dom";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import {Tooltip} from "@mui/material";
import {useAtomValue} from "jotai";
import {clientAtom} from "../Atoms";
import {DialogActionProps, DialogType, ModalAction} from "../components/modal/ModalProps";
import {Client} from "../client/model/Client";
import {EmptyObject, StudentLoggedInComponent, StudentLoggedInComponentWrapper} from "../components/LoggedInPage";
import {StudentUserInformation} from "../login/model/UserInformation";
import SpinnerCard from "../components/SpinnerCard";
import {useTranslation} from "react-i18next";

const TrackRank: StudentLoggedInComponent<EmptyObject> = (props) => {
    const { setModal, setPageIncrement } = useAppContext();

    const userInformation: StudentUserInformation = props.userInformation
    const client = useAtomValue(clientAtom) as Client

    const localClientId = client.id

    const [attestation, setAttestation] = useState<boolean>(
        client.settings.attestationAtRanking === "no" || !!userInformation.student.attestationTimestamp
    );

    const navigate = useNavigate()

    const studentId = userInformation?.guid || ""

    useEffect(() => {
        if (client?.settings.attestationAtRanking === "yes"  && !attestation && client?.settings.blurbs['students/attestation']) {
            setModal && setModal({
                ...questionModal(null, "Attestation", "", DialogType.Confirm, (action, value) => {
                    if (action !== ModalAction.Confirm) {
                        setModal && setModal(questionModal(null, "Withdraw", "If you refuse the attestation, you will be withdrawn from the match. Click 'Confirm' to withdraw yourself from the match, or 'Cancel' to go back.", DialogType.Confirm, (action) => {
                            if (action === ModalAction.Confirm) {
                                api(API.withdraw(studentId), userInformation, localClientId, {}).then(e => {
                                    setPageIncrement && setPageIncrement(new Date())
                                    navigate("/withdrawn")
                                })
                            } else {
                                navigate("/home")
                            }
                        },
                        (props: DialogActionProps) => (
                            <DialogActions>
                                <Button autoFocus aria-label="go back" onClick={(e: MouseEvent<HTMLButtonElement>) => props.handleClose(e, ModalAction.Cancel)} color="primary">
                                    Go Back
                                </Button>
                                <Tooltip title="This will withdraw you from the match, and you will lose site access">
                                    <Button aria-label="Withdraw from the Match" onClick={(e: MouseEvent<HTMLButtonElement>) => props.handleClose(e, ModalAction.Confirm)} color="primary" autoFocus>
                                        Withdraw
                                    </Button>
                                </Tooltip>
                            </DialogActions>
                        )
                            ))
                    } else {
                        setAttestation(true)
                    }
                }),
                component: <div
                    dangerouslySetInnerHTML={{__html: client?.settings.blurbs['students/attestation'] || 'Empty Attestation in Track Rank'}}></div>
            })
        }
    },[userInformation, localClientId, studentId, navigate, client, attestation, setModal, setPageIncrement])

    return <TrackRankList {...props}/>
}

export const TrackRankWrapper: StudentLoggedInComponentWrapper = (context) => {

    const {t} = useTranslation()

    if (context.state === "ready") {
        const props = {
            ...context,
            userInformation: context.userInformation as StudentUserInformation
        }
        return <TrackRank {...props}/>
    }
    else {
        return <SpinnerCard message={t("spinner.loading")}/>
    }
}

export default TrackRankWrapper
