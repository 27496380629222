import {AppConfig} from "./AppConfig";
import {buildVersion} from "../lib/buildVersion";

export const appConfig: AppConfig = {
    practicumfitApiUrl: "https://api.prod.practicumfit.com/api/",
    internfitApiUrl: "https://api.prod.internfit.com/api/",
    pinnedApiUrl: "https://web1.prod.practicumfit.com:9000/api/",
    isDebug: false,
    env: "prod",
    maxFileSize: 42967040,
    stripe: {
        publishable: "pk_live_jMOeSXnyDIYGluEeyXmYir3h",
        secret: "sk_live_u2YfOatZa48MmnLRLkI9s7NN"
    },
    buildVersion: buildVersion
}