import {createTheme} from "@mui/material/styles";
import {blue, red} from "@mui/material/colors";

const theme = {
    palette: {
        primary: {
            main: blue[900],
        },
        secondary: {
            main: red[900],
        },
    },

    timesBox: {
        textAlign: 'center',
        marginTop: '1.5rem',
        marginBottom: '1rem',
    },
    labelBox: {
        marginTop: '1.5rem',
        marginBottom: '1rem',
        whiteSpace: "nowrap"
    },
    nameBox: {
        marginTop: '1.5rem',
        marginBottom: '1rem',
        whiteSpace: "nowrap",
    },
    quantityBox: {
        marginTop: '1.5rem',
        marginBottom: '1rem',
    },
    orderHeading: {
        fontSize: "1.2rem"
    },
    grower: {
        width: "100%",
    },
    orderConfirmButton: {
        margin: "20px auto",
        display: "block"
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 20,
                    padding: "8px 16px",
                    boxShadow: "none"
                },
                containedPrimary: {
                    backgroundColor: "#1976D2",
                    color: "#fff",
                    "&:hover": {
                        backgroundColor: "#0d47a1",
                        color: "#fff",
                    }
                },
                "root:hover": {
                    backgroundColor: "#0d47a1",
                    color: "#fff",
                    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)"
                },
                outlinedPrimary: {
                    backgroundColor: "#1976D2",
                    color: "#fff",
                    "&:hover": {
                        backgroundColor: "#0d47a1",
                        color: "#fff",
                        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)"
                    },
                    "&.Mui-disabled": {
                        backgroundColor: "rgba(0,0,0,0.12)",
                        color: "rgba(0,0,0,0.26)"
                    }
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: 20,
                }
            }
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    backgroundColor: "#eee",
                    boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.16),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)",
                    padding: "12px 24px",
                    marginBottom: 10
                }
            }
        },

        MuiTypography: {
            styleOverrides: {
                h5: {
                    fontWeight: 600,
                    fontFamily: "Helvetica"
                },
                h4: {
                    fontWeight: 600,
                    fontFamily: "Helvetica"
                }
            }
        },

        MuiAppBar: {
            styleOverrides: {
                colorSecondary: {
                    backgroundColor: "#1976D2"
                }
            }
        },

        MuiTabs: {
            styleOverrides: {
                indicator: {
                    height: '3px', // Set the thickness of the bottom border
                    // Add other style properties if needed
                }
            }
        },

        MuiTabPanel: {
            styleOverrides: {
                root: {
                    padding: 0
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    display: "inline-block",
                    whiteSpace: "normal",
                    overflowX: "hidden",
                    textOverflow: "ellipsis"
                }
            }
        },
    }
} as const;

export type CustomTheme = {
    [Key in keyof typeof theme]: typeof theme[Key]
}
declare module '@mui/material/styles/createTheme' {
    interface Theme extends CustomTheme { }
    interface ThemeOptions extends CustomTheme { }

    export function useTheme<T = Theme>(): T;
}

export default createTheme(theme)