import {StudentUserInformation} from "../../login/model/UserInformation";
import {InternshipSiteId, InternshipSiteTrack, InternshipSiteTrackId} from "../../internship-site/model/Organization";
import {AddTask, ThumbDownAlt} from "@mui/icons-material";
import {Box, Button, Tooltip} from "@mui/material";
import React from "react";
import {useMutation} from "@tanstack/react-query";
import {api, API} from "../../lib/Api";
import {clientAtom, userInformationAtom} from "../../Atoms";
import {useAtomValue} from "jotai";
import {Client} from "../../client/model/Client";
import {useModalService} from "../../components/modal/ModalService";
import {useAppContext} from "../../lib/context";
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import {useTranslation} from "react-i18next";
import {StudentOps} from "../../student/model/Student";

export const ApplicantsMatchOptions: React.FC<{student: StudentUserInformation,
    siteId: InternshipSiteId
    track: InternshipSiteTrack}> = ({student, track, siteId}) => {

    const {isDebug} = useAppContext()
    const {t} = useTranslation()

    const userInformation = useAtomValue(userInformationAtom)
    const client  = useAtomValue(clientAtom) as Client

    const modal = useModalService()

    /*
    const proposeMutate = useMutation({
        mutationFn: (trackId: InternshipSiteTrackId) => api(API.proposeMatch(student.guid), userInformation, client.id, trackId)
    })

     */

    const matchMutate = useMutation({
        mutationFn: (trackId: InternshipSiteTrackId) => api(API.makeMatch(student.guid), userInformation, client.id, trackId)
    })

    const makeMatch = () => {
        isDebug && console.log("Making match to", track.id)
        modal.confirmChange(track.id!, {onConfirm: (e) => {
                matchMutate.mutateAsync(track.id!).then(r => {
                    modal.message("Success", "Student Matched Recorded")
                })
            }}, `Are you sure you want to match this student to ${track.name}?`
        )
    }

    if (student.student.matchedTrackId || StudentOps.submissionStatusBySiteIdTrackId(student, siteId, track.id).contains("Archived")) {
        return student.student.matchedTrackId === track.id ? <MatchedThisTrack/> : <DidNotMatchThisTrack/>
    }
    else {
            return track.openings > 0 ? <>
                <Tooltip title={`Match this student to this track ${track.openings === 0 ? "[No openings remaining]" : ""}`}>
                    <Button disabled={track.openings === 0} color="primary" variant="outlined" startIcon={<AddTask/>} onClick={e => makeMatch()}>
                        {t("applicants.buttons.matchStudent")}
                    </Button>
                </Tooltip>
            </> : <DidNotMatchThisTrack/>
    }
}

export const MatchedThisTrack: React.FC = () => {
    return <Box className="rounded-2xl flex flex-row border-2 border-lime-700 border-solid bg-lime-600 text-white font-bold p-0 pt-2 pl-2 w-auto">
        <Box className="m-1"><ThumbUpOffAltIcon /></Box>
        <Box className="m-1">Student Matched</Box>
    </Box>
}

export const DidNotMatchThisTrack: React.FC = () => {
    return <Box className="flex flex-row stop">
        <Box className="m-1"><ThumbDownAlt/></Box>
        <Box className="m-1">Student Not Matched</Box>
    </Box>
}

