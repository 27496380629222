
export abstract class Optional<T> {
    protected constructor() {
    }

    static some = <T>(t: T): Optional<T> => new Some(t)
    static none = <T>(t: T): Optional<T> => new None<T>()

    static apply = <T>(t: T | undefined): Optional<T> => t === undefined ? (new None<T>()): Optional.some(t)

    abstract map<U>(f: (x: T) => U): Optional<U>
    abstract flatMap<U>(f: (x: T) => Optional<U>): Optional<U>
    abstract forEach(f: (x: T) => void): void
    abstract getOrElse(t: T): T
    abstract orElse(t: Optional<T>): Optional<T>
    abstract get(): T
    abstract isEmpty(): boolean
    abstract isPresent(): boolean
    abstract filter(f: (x: T) => boolean): Optional<T>
    abstract fold<U>(notPresent: () => U, present: (value: T) => U): U
    abstract contains(t: T): boolean

    find = (f: (x: T) => boolean) => this.filter(f)
}

export class Some<T> extends Optional<T> {
    value: T

    constructor(value: T) {
        super()
        this.value = value
    }

    map = <U>(f: (x: T) => U): Optional<U> => new Some(f(this.value))
    flatMap = <U>(f: (x: T) => Optional<U>): Optional<U> => f(this.value)
    forEach = (f: (x: T) => void) => f(this.value)

    getOrElse = (t: T): T => this.value
    orElse = (t: Optional<T>): Optional<T> => this

    get = () => this.value
    isPresent = () => true
    isEmpty = () => !this.isPresent()

    filter = (f: (x: T) => boolean): Optional<T> => f(this.value) ? new Some(this.value) : new None<T>()
    fold = <U>(f: () => U, g: (value: T) => U) => g(this.value)
    contains = (t: T) => t === this.value
}

export class None<T> extends Optional<T> {
    // For some reason ESLint flags this - you need this otherwise you can't build a None because the abstarct
    // Super class is... abstract
    // eslint-disable-next-line
    constructor() {
        super()
    }
    map = <U>(f: (x: T) => U): Optional<U>  => new None<U>()
    flatMap = <U>(f: (x: T) => Optional<U>): Optional<U> => new None<U>()
    forEach = (f: (x: T) => void) => {}
    getOrElse = (t: T) => t
    orElse = (t: Optional<T>): Optional<T> => t
    get = () => {throw new Error("Option is None")}
    isPresent = () => false
    isEmpty = () => !this.isPresent()

    filter = (f: (x: T) => boolean): Optional<T> => new None<T>()
    fold = <U>(f: () => U, g: (value: T) => U) => f()
    contains = (t: T) => false
}