import React from "react"

import {Client, MaterialSlotDefinition} from "../../client/model/Client";
import {StudentMaterialsDocument} from "../model/StudentMaterials";
import {DocumentDisplay} from "./DocumentDisplay";
import {StudentUserInformation} from "../../login/model/UserInformation";
import {UploadDocumentDropzoneControl} from "./UploadDocumentDropzoneControl";
import {Box} from "@mui/material";
import {refreshUser} from "../../Atoms";
import {useQueryClient} from "@tanstack/react-query";

export const SpecificRequiredDocument = (props: {userInformation: StudentUserInformation, client: Client, editable: boolean, slotDefinition: MaterialSlotDefinition}) => {

    const doc: StudentMaterialsDocument | undefined =
        props.userInformation.student.materials.documents.find(x => x.slotId === props.slotDefinition.id)

    console.log("SpecificRequiredDocument", doc)

    const queryClient = useQueryClient()

    const uploadComplete = () => {
        refreshUser(queryClient)
    }

    return (
        <Box sx={{margin: "0 auto"}}>
            {doc?.document.storageRef ? <DocumentDisplay document={doc} uploadType="material" editable={props.editable}/> :
                <UploadDocumentDropzoneControl guid={props.userInformation.guid} client={props.client}
                                               completeUpload={uploadComplete}
                                               slotDefinition={props.slotDefinition} userInformation={props.userInformation} uploadType="material"/>}
        </Box>
    )
}