import React, {useEffect, useState} from "react";
import {QuestionnaireInputProps} from "../QuestionnaireEdit";
import {FormControl, TextField, Tooltip} from "@mui/material";
import {answerFor, useStyles} from "../QuestionnaireList";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {isPresent} from "../../components/customTable/util";

const QuestionnaireTextArea= (props: QuestionnaireInputProps) => {
    const [state, setState] = useState<string>(props.answer?.answer || "")
    const [isUpdated, setIsUpdated] = useState<boolean>(false)

    const {classes} = useStyles()

    useEffect(() => {
        const answer = answerFor(props)
        answer && setState(answer)
    }, [props])

    return (
        <FormControl className={classes.formControl}>
            {props.question.isRequired && !isPresent(props.answer, "answer") &&
            <Tooltip title="This is a required question that has no answer">
                <ErrorOutlineIcon className="stop" style={{position: "absolute", left: "-30px", top: "14px"}}/>
            </Tooltip>
            }
            <TextField variant="filled" type="text" id={`input-${props.question.id}`}
                       aria-describedby={`question${props.question.id}-help`}
                       placeholder=""
                       multiline
                       rows={4}
                       value={state}
                       InputProps={{
                           readOnly: !props.editable,
                       }}
                       required={props.requiredBlocking && props.question.isRequired}
                       onChange={(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
                           setIsUpdated(true)
                           return setState(e.target.value)
                       }}
                       name={`question-${props.question.id}`}
                       label={props.question.label}
                       onBlur={() => isUpdated && props.onChange && props.onChange(state)}
            />
            <small id={`question-${props.question.id}-help`} className="form-text text-muted">{props.question.isRequired &&
            <span>This is a required field.</span>} {props.question.helpText}</small>
        </FormControl>
    )
}

export default QuestionnaireTextArea