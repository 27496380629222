import React from 'react';
import {useTranslation} from "react-i18next";
import {Typography, TypographyVariant} from "@mui/material";
import {OverridableStringUnion} from "@mui/types";
import {TypographyPropsVariantOverrides} from "@mui/material/Typography/Typography";

export const I18Typography: React.FC<{msgId: string, params?: any, style?: React.CSSProperties,
    className?: string, variant?: OverridableStringUnion<TypographyVariant | 'inherit', TypographyPropsVariantOverrides>}> = ({msgId, params, className, style, variant}) => {

    const {t} = useTranslation()

    console.log("Loading i18n message for",msgId)

    return <Typography variant={variant} style={style} className={className}>{t(msgId, params)}</Typography>
}