import {API, api} from "../../lib/Api";
import {Questionnaire} from "../../model/Questionnaire";
import {
    QuestionnaireDataAggregate,
    QuestionnaireDataResult, QuestionnaireNoDataResult,
} from "../../questionnaire/model/QuestionnaireSubmissionElement";
import {
    QuestionnaireElement,
    QuestionnaireId,
    QuestionnaireTableElement
} from "../../questionnaire/model/Questionnaire";
import {UserInformation} from "../../login/model/UserInformation";
import {ClientId} from "../../client/model/Client";
import {makeQuestionnaireElements} from "../../questionnaire/lib/QuestionnaireElements";
import {useQuery} from "@tanstack/react-query";
import {UseQueryResult} from "@tanstack/react-query/src/types";

/*
QuestionnaireElement contains all the elements including headings,
QuestionnaireSubmissionElement contains only elements with answers
 */
export const useFetchStudentQuestionnaireData = (userInformation: UserInformation, guid: string, clientId: ClientId, questionnaireId: QuestionnaireId): UseQueryResult<QuestionnaireDataAggregate, any> => {
    const questionnaireQuery: UseQueryResult<Questionnaire, any> = useQuery({
        queryFn: () => api<Questionnaire, undefined>(API.fetchQuestionnaire(questionnaireId), userInformation, clientId)
            .then(e => e.data)
            .then(e => ({
                id: e.id,
                name: e.name,
                elements: e.elements.map(x => ({
                    ...x,
                    parsedParameters: x.parameters ? JSON.parse(x.parameters) : undefined,
                    tableQuestions: []
                } as QuestionnaireTableElement))
            }))
            .catch(err => {
                console.log("Questionnaire ID is incorrect - check system configuration for questionnaire ID",err)
                if (err.status === 404) {
                    return new Promise<Questionnaire>((resolve, reject) => {
                        reject("Failed - Questionnaire ID is incorrect")
                    })
                }
                else {
                    return new Promise<Questionnaire>((resolve, reject) => {
                        reject(err)
                    })
                }
            }),
        queryKey: ["questionnaire", questionnaireId],
        staleTime: 86400000
    })

    const questionnaireDataQuery: UseQueryResult<QuestionnaireDataResult, any> = useQuery({
        queryFn: () => api<QuestionnaireDataResult, undefined>(API.fetchStudentQuestionnaireSubmission(questionnaireId, guid), userInformation, clientId)
            .then<QuestionnaireDataAggregate>(resp => {
                console.log("Making questionnaire data", resp)
                try {
                    if (questionnaireQuery.data) {
                        return {
                            submission: resp.data.submission,
                            elements: makeQuestionnaireElements({student: userInformation}, questionnaireQuery.data, resp.data.elements)
                        } as QuestionnaireDataResult
                    } else {
                        return {
                            submission: undefined,
                            elements: [] as QuestionnaireElement[]
                        } as QuestionnaireNoDataResult
                    }
                } catch (e) {
                    console.log("Failed to make elements", e)
                    return {
                        elements: [] as QuestionnaireElement[],
                        submission: undefined
                    } as QuestionnaireNoDataResult
                }
            })
            .catch(error => {
                console.log("API error", error)
                if (error.status === 404) {
                    console.log("Got 404 for data")
                    return new Promise<QuestionnaireDataAggregate>((resolve) => resolve(
                        {
                            submission: undefined,
                            elements: (questionnaireQuery.isSuccess ? makeQuestionnaireElements({student: userInformation}, questionnaireQuery.data, []) : [])
                        }))
                } else {
                    console.log("Failed to load questionnaire data for student ", error.status)
                    return new Promise<QuestionnaireDataAggregate>((resolve, reject) => {
                        reject("Failed")
                    })
                }
            }),
        queryKey: ["studentQuestionnaire", userInformation.guid],
        enabled: questionnaireQuery.isSuccess,
        staleTime: 86400000
    })

    return questionnaireDataQuery
}