import {QuestionnaireElementProps} from "../../model/QuestionnaireList";
import React from "react";
import {
    QuestionnaireElement,
    QuestionnaireElementTypeId,
    QuestionTypeId
} from "../../model/Questionnaire";

interface BareElement {
    name: string
    questionTypeId?: QuestionTypeId
    elementTypeId: QuestionnaireElementTypeId
    elementTypeName: 'Question' | 'Short Section Heading' | 'HTML Fragment' | 'Table Heading' | 'Formatting Line Break' | 'Subheading'
}

export const bareElementTypes: BareElement[] = [
    {
        name: 'Short Text',
        questionTypeId: 1,
        elementTypeId: 1,
        elementTypeName: 'Question'
    },
    {
        name: 'Paragraph',
        questionTypeId: 2,
        elementTypeId: 1,
        elementTypeName: 'Question'
    },
    {
        name: 'Multi-Select',
        questionTypeId: 3,
        elementTypeId: 1,
        elementTypeName: 'Question'
    },
    {
        name: 'Select',
        questionTypeId: 4,
        elementTypeId: 1,
        elementTypeName: 'Question'
    },
    {
        name: 'Date',
        questionTypeId: 5,
        elementTypeId: 1,
        elementTypeName: 'Question'
    },
    {
        name: 'Link',
        questionTypeId: 6,
        elementTypeId: 1,
        elementTypeName: 'Question'
    },
    /*
    {
        name: 'Document',
        questionTypeId: 7,
        elementTypeId: 1,
        elementTypeName: 'Question'
    },

     */
    {
        name: 'Section Heading',
        elementTypeName: 'Short Section Heading',
        elementTypeId: 2,
    },
    {
        name: 'Subheading',
        elementTypeName: 'Subheading',
        elementTypeId: 5,
    },
    {
        name: 'HTML Fragment',
        elementTypeName: 'HTML Fragment',
        elementTypeId: 4
    },
    {
        name: 'Table Heading',
        elementTypeName: 'Table Heading',
        elementTypeId: 7
    },
    {
        name: 'Formatting Line Break',
        elementTypeName: 'Formatting Line Break',
        elementTypeId: 6
    }
]

export const elementTypeFromRow = (row: QuestionnaireElement) => {
    switch (row.elementType.type) {
        case 'Question':
            return (row.question && row.question.type.questionType) || "Unknown"
        case 'Short Section Heading':
            return "Section Heading"
        case 'HTML Content':
            return "HTML Fragment"
        case 'Subheading':
            return "Subheading"
        case "Formatting Line Break":
            return "Formatting Line Break"
        case "Table Heading":
            return "Table Heading"
        default:
            return "Unknown"
    }
}

export const ElementType = ({row}: QuestionnaireElementProps) => (
    <React.Fragment>{elementTypeFromRow(row)}</React.Fragment>
)