
export abstract class Either<T,U> {
    left: T | undefined = undefined
    right: U | undefined = undefined
    abstract fold<K>(leftFn: (t: T) => K, rightFn: (t: U) => K): K

    abstract isLeft(): boolean
    abstract isRight(): boolean

    static right = <T, K>(right: K): Either<T, K>  => new Right(right)
    static left = <T, K>(left: T): Either<T, K>  => new Left(left)
}

export class Left<T, U>  extends Either<T, U> {
    left: T

    constructor(left: T) {
        super()
        this.left = left
    }

    fold<K>(leftFn: (t: T) => K, rightFn: (t: U) => K): K {
        return leftFn(this.left)
    }

    isLeft = () => true
    isRight = () => false

}

export class Right<T, U> extends Either<T, U> {
    right: U

    constructor(right: U) {
        super()
        this.right = right
    }

    fold<K>(leftFn: (t: T) => K, rightFn: (t: U) => K): K {
        return rightFn(this.right)
    }

    isLeft = () => false
    isRight = () => true
}