import React, {useEffect, useState} from "react";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import {useAppContext} from "../../lib/context";
import {useAtomValue} from "jotai";
import {invalidateOrganizationList} from "../lib/OrganizationService";
import {useQueryClient} from "@tanstack/react-query";
import {OrganizationViewName, OrganizationViewNames} from "../model/Organization";
import {clientAtom} from "../../Atoms";

export interface SiteListFilterMenuProps {
    anchorEl: HTMLElement | null
    id: string
    closeMenuHandler: ((selection?: string) => void)
}


export const mapViewName: (x: string) => OrganizationViewName = (x: string): OrganizationViewName => {
    switch (x) {
        case "All Cycles":
            return OrganizationViewNames.allCycles
        default:
            return OrganizationViewNames.tracks
    }
}


export const SiteListFilterMenu = (props: SiteListFilterMenuProps) => {
    const {isDebug} = useAppContext()

    const queryClient = useQueryClient()

    const client = useAtomValue(clientAtom)

    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(props.anchorEl)
    const [open, setOpen] = useState<boolean>()

    useEffect(() => {
        console.log("Effecting")
        setOpen(props.anchorEl !== null)
        setAnchorEl(props.anchorEl)
    },[props, props.anchorEl])

    const handleMenuClose = () => {
        setAnchorEl(null)
        setOpen(false)
    };

    const handleMenuClick = (e: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(null)
        setOpen(false)
        props.closeMenuHandler(e.currentTarget.innerText)
        console.log("Inner text", e.currentTarget.innerText)
        invalidateOrganizationList(queryClient, client.id, mapViewName(e.currentTarget.innerText))
    };

    isDebug && console.log("Internship Site Filter Menu", open, anchorEl)

    return (
            <Menu anchorEl={anchorEl}
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                id={props.id}
                keepMounted
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={open || false}
                onClose={handleMenuClose}>
                <MenuItem onClick={handleMenuClick}>Current Cycle ({client?.settings?.cycle.cycleName || "Unknown"})</MenuItem>
                <MenuItem onClick={handleMenuClick}>All Cycles</MenuItem>
            </Menu>
    )
}
