import React from "react";

export enum SortDirection {
    ASC = 'asc', DESC = 'desc'
}

export interface SortingConfig<T> {
    comparator?: (a: T, b: T) => number
    direction?: SortDirection
}

export function useSortableData<T>(items: Array<T>, config: SortingConfig<T>|null=null) {
    //console.log("useSortableData", searchFilter)
    const [sortConfig, setSortConfig] = React.useState(config);

    const sortedItems = React.useMemo(() => {
        if (sortConfig !== null) {
            return [...items].sort((a: T, b: T) =>
                sortConfig.comparator ? sortConfig.direction === SortDirection.ASC ? sortConfig.comparator(a, b) : sortConfig.comparator(b, a) : 0
            )
        }
        else {
            return items
        }
    }, [items, sortConfig]);

    function requestSort(comparator: ((a: T, b: T) => number) | undefined) {
        if (comparator) {
            let direction: SortDirection = SortDirection.ASC
            //console.log(sortConfig, comparator, sortConfig && comparator === sortConfig.comparator)
            if (
                sortConfig &&
                sortConfig.comparator === comparator &&
                sortConfig.direction === SortDirection.ASC
            ) {
                direction = SortDirection.DESC
            }
            setSortConfig( {comparator, direction});
        }
    }

    return { items: sortedItems, requestSort, sortConfig };
}

