import React, {ChangeEvent, useState} from 'react'
import { Theme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import {FormControl, TextField} from "@mui/material";
import {QuestionnaireElementProps} from "../../model/QuestionnaireList";

const useStyles = makeStyles()((theme: Theme) => ({
        formControl: {
            minWidth: "75px",
        },
}));

export const ElementPosition = (props: QuestionnaireElementProps) => {
    const [position, setPosition] = useState<number>(props.row.position)

    const {classes} = useStyles()

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const v = parseInt(e.target.value)
        setPosition(v)

        props.setElement && props.setElement(elem => {
            return {
                ...elem,
                position: v
            }
        })
    }

    return (
        <FormControl>
        <TextField id="elementPosition" label="Pos" variant="filled" type="number"
                   name="position"
                   className={classes.formControl}
                   size="small"
                   InputLabelProps={{
                       shrink: true,
                   }}
        defaultValue={position} onChange={onChangeHandler}/>
        </FormControl>
    )
}
