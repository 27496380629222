import React, {useState} from 'react';
import './App.scss';
import {dom, library} from '@fortawesome/fontawesome-svg-core'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {faTwitter} from '@fortawesome/free-brands-svg-icons'
import {AppContext} from "./lib/context";
import InfoModal from "./components/modal/InfoModal";
import Routing from "./Routing";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {appConfig} from "./conf/conf";
import useScript from "./hooks/useScript";
import {initialModal} from "./lib/initialValues";
import {Box} from "@mui/material";
import {ThemeProvider} from '@mui/material/styles';
import theme from './lib/theme'
import {QueryClientProvider} from "@tanstack/react-query";

import {ReactQueryDevtools} from "@tanstack/react-query-devtools";
import {queryClient} from "./Atoms";
import {ModalProps} from "./components/modal/ModalProps";
import {StyledEngineProvider} from "@mui/system";

const App: React.FC = () => {
    const [isDebug, setDebug] = useState(appConfig.isDebug);

    const [modal, setModal] = useState<ModalProps>(initialModal);
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(false);
    const [pageIncrement, setPageIncrement] = useState<Date>(new Date())

    const [signinIncrement, setSigninIncrement] = useState<Date>(new Date())

    useScript("https://static.zdassets.com/ekr/snippet.js?key=1618b266-8140-4653-803d-d821ae6ca159", "ze-snippet")

    library.add(fas, faTwitter);

    dom.watch();

    const context = {
        isDebug, setDebug,
        modal, setModal,
        signinIncrement, setSigninIncrement,
        pageIncrement, setPageIncrement,
        shouldBlockNavigation, setShouldBlockNavigation,
    }

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Box className="bg" justifyContent="center">
                    <QueryClientProvider client={queryClient}>
                        <AppContext.Provider value={context}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <InfoModal/>
                                    <Routing/>
                            </LocalizationProvider>
                        </AppContext.Provider>
                        <ReactQueryDevtools />
                    </QueryClientProvider>
                </Box>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
