import {API, api} from "../../lib/Api";
import {useQuery} from "@tanstack/react-query";
import {clientAtom, userInformationAtom} from "../../Atoms";
import {Client} from "../../client/model/Client";
import {useAtomValue} from "jotai";
import {StudentUserInformation} from "../../login/model/UserInformation";

export const useStudentInformationService = (guid: string | undefined) => {
    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client

    const studentQuery = useQuery({
        queryFn: () => api<StudentUserInformation, undefined>(API.getStudent(guid || ''), userInformation, client.id)
            .then<StudentUserInformation>(x => x.data),
        queryKey: ["studentInformation", guid],
        staleTime: 86400000,
        enabled: !!guid
    })

    return studentQuery
}