import React, {useState, useEffect} from 'react';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';

import CardSection from './CardSection';
import {useAppContext} from "../lib/context";
import {API, api, apiError} from "../lib/Api";
import {errorMessageModal, messageModal} from "../components/modal/InfoModal";
import {Button, List, Typography} from "@mui/material";
import {useNavigate} from "react-router-dom";
import SpinnerCard from "../components/SpinnerCard";
import {OfferListElement} from "./components/OfferListElement";
import {makeStyles} from "tss-react/mui";
import {PreparePaymentResponse} from "./model/Payment";
import useUserReadyService from "../user/lib/UserReadyService";
import {flashingErrorMessageAtom, userInformationAtom} from "../Atoms";
import {useAtomValue} from "jotai";
import {useQuery, useQueryClient} from "@tanstack/react-query";
import {clientAtom, refreshUser} from "../Atoms";
import {Client} from "../client/model/Client";
import {usePaymentCallback} from "../lib/PaymentCallback";
import {UseQueryResult} from "@tanstack/react-query/src/types";
import {useTranslation} from "react-i18next";

interface CheckoutFormProps {
    orderId: string
}

const useStyles = makeStyles()({
    submitButton: {
        width: "100%"
    }
})

const CheckoutForm: React.FC<CheckoutFormProps> = ({orderId}) => {
    const {setModal, isDebug} = useAppContext()
    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client
    const errorMessage = useAtomValue(flashingErrorMessageAtom)

    const [processing, setProcessing] = useState(false)
    const {t} = useTranslation()

    const localClientId = client.id

    const paymentCallback = usePaymentCallback({callbackPath: "/home"})

    const stripe = useStripe();
    const elements = useElements();

    const {classes} = useStyles()

    const navigate = useNavigate()
    const userReady = useUserReadyService()
    const queryClient = useQueryClient()

    const userPaymentIntentQuery: UseQueryResult<PreparePaymentResponse> = useQuery({
        queryFn: () => api<PreparePaymentResponse, undefined>(API.preparePayment(orderId), userInformation, client.id)
            .then<PreparePaymentResponse>(result => result.data)
            .catch(err => {
                isDebug && console.log("Prepare error")
                err.json().then((e: any) => {
                    if (e.message === "Payment Already Processed") {
                        paymentCallback.callback()
                    }
                    else {
                        apiError(err, setModal)
                    }
                }).catch(() => apiError(err, setModal))
            }),
        queryKey: ["preparePayment", orderId],
    })

    useEffect(() => {
        // TODO this looks weird, why two if blocks here?
        if (userReady && userInformation && !userInformation.firstName) {
            //Sentry.captureMessage("First Name Undefined for " + userInformation.email)
            isDebug && console.log("First Name undefined");
            refreshUser(queryClient)
            /*
            setTimeout(() => setUserInformation && userEnhanceFromBackEnd(userInformation)
                .then(e => setUserInformation(e)), 2000)

             */
        }
        /*
        if (userReady && userInformation) { //&& userInformation.firstName && requireGroup(userInformation.userGroups, Groups.student)) {
            api<PreparePaymentResponse, undefined>(API.preparePayment(orderId), userInformation, localClientId).then(result => {
                setPaymentSecret(result.data.clientSecret)
                setOrder(result.data.order)
            }).catch(err => {
                isDebug && console.log("Prepare error")
                err.json().then((e: any) => {
                    if (e.message === "Payment Already Processed") {
                        paymentCallback.callback()
                    }
                    else {
                        apiError(err, setModal)
                    }
                }).catch(() => apiError(err, setModal))
            })
        }

         */
    }, [queryClient, userInformation, localClientId, navigate, setModal, userReady, isDebug, orderId])

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement)

        if (userPaymentIntentQuery.isSuccess && cardElement) {
            const billingDetails = {
                name: userInformation.firstName + " " + userInformation.lastName,
                email: userInformation.email
            }

            stripe.confirmCardPayment(userPaymentIntentQuery.data.clientSecret, {
                payment_method: {
                    card: cardElement,
                    billing_details: billingDetails
                }
            }).then(result => {
                if (result.error) {
                    setTimeout(() => setProcessing(false), 550)
                    // Show error to your customer (e.g., insufficient funds)
                    isDebug && console.log(result.error.message);

                    if (result.error.message === 'You cannot confirm this PaymentIntent because it has already succeeded after being previously confirmed.') {
                        setModal && setModal({
                            ...errorMessageModal("Payment Confirmed", "Your payment has has already been processed, please proceed to the site."),
                            onClose: (e) => {
                                paymentCallback.callback()
                            }
                        })
                    } else {
                        setModal && setModal(errorMessageModal("Payment Failed", (result.error.message || "Unknown error") +" If you're trying to use a new payment card after a failed payment, please wait 10 minutes and try again."))
                    }
                } else {
                    isDebug && console.log(result)
                    // The payment has been processed!
                    if (result.paymentIntent?.status === 'succeeded') {
                        // Consider instead pushing to an SQS or something here so that we can't lose a payment with an HTTP service outage
                        api(API.confirmPayment(orderId), userInformation, localClientId, {paymentIntent: {
                            ...result.paymentIntent,
                            billing_details: billingDetails
                        }}).then(x => {
                            refreshUser(queryClient).then(() => {
                                setModal && setModal({
                                    ...messageModal("Payment confirmed", "Your payment has been processed successfully - thank you!"),
                                    onClose: (e) => {
                                        setProcessing(false);
                                        paymentCallback.callback()
                                    }
                                })
                            })
                        }).catch(err => {
                            setProcessing(false);
                            apiError(err, setModal)
                            })

                        // A backend webhook from Stripe to our API layer catches the case where the front-end has a network glitch.
                    }
                }
            });
        }

        // This is weird here, but it must be here because the strip.confirmPayment API needs the component
        // to be mounted; if we put this in the then() clause, then it will wait until sumission is finished,
        // which defeats the purpose.
        setTimeout(() => setProcessing(true), 500);
    };

    if (client.settings && userInformation && userPaymentIntentQuery.isSuccess && userPaymentIntentQuery.data.order && userPaymentIntentQuery.data.order.totalPrice) {
        return processing ? <SpinnerCard message={t("order.processingPayment")}/> : (
            <form onSubmit={handleSubmit}>
                <Typography variant="h5">Order Details</Typography>
                <Typography variant="inherit">{errorMessage}</Typography>
                    <List>
                    {userPaymentIntentQuery.data.order.orderEntries.map(x => <OfferListElement offer={x.offer} readonly={true} setOfferQuantity={(e, i) => e}/>)}
                    </List>
                <CardSection/>

                <Button type="submit" variant="outlined" disabled={!stripe} className={classes.submitButton}>
                    Confirm Order for ${userPaymentIntentQuery.data.order.totalPrice / 100.0}
                </Button>
            </form>
        );
    } else return <SpinnerCard message="Loading Configuration"/>
}

export default CheckoutForm
