import React, {useEffect, useMemo} from 'react'
import {useAppContext} from "../lib/context";
import useListQuestionnaireService from "../lib/ListQuestionnaireService";
import {Questionnaire} from "../model/Questionnaire";
import useListOrganizationService from "../internship-site/lib/OrganizationService";
import {Organization} from "../internship-site/model/Organization";
import {requireGroup} from "../lib/util";
import {useNavigate} from "react-router-dom"
import Groups from "../model/Groups";
import {LoginStateCard} from "../components/LoginStateCard";
import {Page} from "../components/Page";
import SpinnerCard from "../components/SpinnerCard";
import {userInformationAtom} from "../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom, loginStateAtom} from "../Atoms";

export const SiteProfile = () => {
    const {isDebug} = useAppContext()

    const userInformation = useAtomValue(userInformationAtom)
    const loginState = useAtomValue(loginStateAtom)
    const client = useAtomValue(clientAtom)

    const questionnaireService = useListQuestionnaireService()
    const questionnaires: Array<Questionnaire> = useMemo(() => questionnaireService.status === "loaded" ? questionnaireService.payload : [] as Array<Questionnaire>, [questionnaireService])

    const organizationsService = useListOrganizationService({});
    const organizations: Array<Organization> = useMemo(() => (organizationsService.status === 'loaded' ? organizationsService.payload : [] as Array<Organization>)
        .filter(x => {
            if (requireGroup(userInformation?.userGroups, Groups.siteAdmin)) {
                return userInformation?.sites?.includes(x.internshipSite?.id || 0)
            } else return true
        }), [organizationsService, userInformation])

    const navigate = useNavigate()

    isDebug && console.log(organizationsService.status, questionnaireService.status, organizations, questionnaires)

    useEffect(() => {
        isDebug && console.log("Effecting", organizationsService.status, questionnaireService.status)
        if (userInformation && userInformation.sites && organizationsService.status === "loaded" && questionnaireService.status === "loaded") {
            isDebug && console.log("Everything is in")
            const orgId = organizations[0].id
            const siteId = organizations[0].internshipSite.id

            navigate(`/questionnaire/submission/${orgId}/${questionnaires[0].id}/${siteId}`)
        }
    }, [isDebug, userInformation, navigate, organizationsService.status, questionnaireService.status, organizations, questionnaires])

    const {useLoginStateCard, component} = LoginStateCard(loginState, client)

    return useLoginStateCard ? component : (
        <Page navName="Profile">
            <SpinnerCard message="Loading Profile"/>
        </Page>
    )
}