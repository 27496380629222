import React from 'react'

import {OrderId} from '../model/Order'
import {FormControl, InputLabel, MenuItem, Select, TextField, Theme} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";

const useStyles = makeStyles()((theme: Theme) => ({
    formControl: {
        minWidth: 120,
        width: "100%",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
}))

export const ManualPayment = (props: {orderId: OrderId, onSubmit: (e: React.FormEvent) => void, formRef: React.RefObject<HTMLFormElement>}) => {
    const userInformation = useAtomValue(userInformationAtom)

    const {classes} = useStyles()

    return (
        <form ref={props.formRef}>
            <FormControl className={classes.formControl}>
                <TextField name="amount"
                           variant="outlined"
                           aria-label="Payment Amount"
                           placeholder="0.0"
                           label="Payment Amount"
                />
            </FormControl>
            <FormControl className={classes.formControl}>
                <InputLabel id="paymentType-select-label">Payment Type</InputLabel>
                <Select name="paymentType" label="Payment Type">
                    <MenuItem key="Charge" value="Charge">Charge</MenuItem>
                    <MenuItem key="Refund" value="Refund">Refund</MenuItem>
                    <MenuItem key="Manual-EFT" value="Manual-EFT">Manual EFT</MenuItem>
                    <MenuItem key="Manual-Check" value="Manual-Check">Manual Check</MenuItem>
                    <MenuItem key="Waived" value="Waived">Payment Waived</MenuItem>
                </Select>
            </FormControl>
            <FormControl className={classes.formControl}>
                <TextField name="paymentComments"
                           variant="outlined"
                           aria-label="Comments"
                           placeholder="Comments"
                           multiline={true}
                           label="Comments"/>
            </FormControl>
            <input type="hidden" name="userEmail" value={userInformation?.email}/>
            <input type="hidden" name="orderId" value={props.orderId}/>
        </form>
    )
}