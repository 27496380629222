import React from 'react'
import {Page, PageBody, PageHeader} from "../components/Page";
import {LoginStateCard} from "../components/LoginStateCard";
import Groups from "../model/Groups";
import ClientCyclesTable from "./component/ClientCyclesTable";
import {useAtomValue} from "jotai";
import {clientAtom, loginStateAtom} from "../Atoms";

export const ClientCycles = () => {
    const client = useAtomValue(clientAtom)
    const loginState = useAtomValue(loginStateAtom)

    const {useLoginStateCard, component} = LoginStateCard(loginState, client, [Groups.globalAdmin])

    return useLoginStateCard ? component : (
        <Page navName="Client Cycles">
            <PageHeader title="Client Cycles"/>
            <PageBody>
                <ClientCyclesTable/>
            </PageBody>
        </Page>
    )
}