import React, {useState} from "react"
import {
    InternshipSiteId, InternshipSiteTrack,
    InternshipSiteTrackId,
    Organization,
    OrganizationId
} from "../internship-site/model/Organization";
import {clientAtom, userInformationAtom} from "../Atoms";
import {useAtomValue} from "jotai";
import {api, API} from "../lib/Api";
import {useQuery} from "@tanstack/react-query";
import {Client} from "../client/model/Client";
import SpinnerCard from "../components/SpinnerCard";
import {SiteTrackSelect} from "../internship-site/component/SiteTrackSelect";
import {StudentUserInformation} from "../login/model/UserInformation";
import {LoggedInComponent, LoggedInComponentWrapper} from "../components/LoggedInPage";
import {useParams} from "react-router-dom";
import {Optional} from "../lib/Optional";
import {ApplicantsTable} from "./admin/ApplicantsTable";
import {useTranslation} from "react-i18next";
import {headOption} from "../lib/util";
import {ErrorFallback} from "../components/ErrorFallback";
import {asFilteredOrganizationList, asFilteredSiteTrackList} from "../internship-site/lib/ListOrganizationService";
import {StudentOps} from "../student/model/Student";
import {Typography} from "@mui/material";
import Paper from "@mui/material/Paper";

const StudentApplicantsWrapper: LoggedInComponentWrapper = (context) => {
    const {t} = useTranslation()
    const params = useParams()
    const orgId = Optional.apply(params.organizationId).map(x => parseInt(x)).getOrElse(0)
    const siteId = Optional.apply(params.siteId).map(x => parseInt(x)).getOrElse(0)
    const trackId = Optional.apply(params.trackId).map(x => parseInt(x)).getOrElse(0)

    if (context.state === "ready") {
        return <StudentApplicants organizationId={orgId} siteId={siteId} trackId={trackId} client={context.client} userInformation={context.userInformation} state={context.state}/>
    }
    else {
        return <SpinnerCard message={t("applicants.spinnerLoading")}/>
    }
}

export const StudentApplicantsNoParamsWrapper: LoggedInComponentWrapper = (context) => {
    const {t} = useTranslation()

    const siteTracksQuery = useQuery({
        queryFn: () => new Promise<Organization[]>((resolve, reject) => {
            if (context.state === "ready") {
                resolve(asFilteredOrganizationList(context.userInformation,
                    api<Organization[], undefined>(API.listOrganizations(), context.userInformation, context.client.id)
                        .then(response => response.data))
                )
            } else {
                reject("Context state was not ready, which should be impossible")
            }
        }),
        queryKey: ["organizationList", context.state],
        enabled: context.state === "ready",
        staleTime: 86400000
    })

    if (context.state === "ready") {

        if (siteTracksQuery.isSuccess) {
            return headOption(siteTracksQuery.data.filter(x => x.internshipSite.siteTracks.length > 0))
                .map(e =>
                    <StudentApplicants organizationId={e.id!} siteId={e.internshipSite.id!}
                                       trackId={e.internshipSite.siteTracks.at(0)!.id!}
                                       userInformation={context.userInformation} client={context.client}
                                       state={context.state}/>
                )
                .getOrElse(<ErrorFallback message={t("error.generalDataError")}/>)
        } else {
            return <SpinnerCard message={t("applicants.spinnerLoading")}/>
        }
    }
    else {
        return <SpinnerCard message={t("applicants.spinnerLoading")}/>
    }
}

/** All the applications per site - if applications are configured per track, this goes on the track rank list page */
const StudentApplicants: LoggedInComponent<{organizationId: OrganizationId, siteId: InternshipSiteId, trackId: InternshipSiteTrackId}> = (props) => {
    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client

    const {t} = useTranslation()
    const [siteTrack, setSiteTrack] = useState<InternshipSiteTrack>()
    const [siteTrackId, setSiteTrackId] = useState<number>(props.trackId)

    console.log("SiteTrack", siteTrack)

    const siteTracksQuery = useQuery({
        queryFn: () => asFilteredSiteTrackList(userInformation, api<Organization[], undefined>(API.listOrganizations(), userInformation, client.id)
            .then(response => response.data))
            .then<InternshipSiteTrack[]>(x => {
                const e = x.find(x => x.id === siteTrackId)
                setSiteTrack(e)
                return x
            }),
        queryKey: ["siteTracks", client.id],
        staleTime: 86400000
    })

    const applicantsQuery = useQuery({
        queryFn: () => new Promise<StudentUserInformation[]>((resolve, reject) => {
            if (siteTrack) {
                resolve(api<StudentUserInformation[], any>(API.fetchTrackApplicants(siteTrack.internshipSiteId, siteTrackId), userInformation, client.id)
                    .then<StudentUserInformation[]>(x =>
                        x.data.filter(e =>
                            ["Submitted", "Archived"].includes(StudentOps.userApplicationsForSiteTrack(e, siteTrack.internshipSiteId, siteTrackId)?.applicationStatus || "")
                        )
                    ))
            } else {
                reject("No site track present")
            }
        }),
        queryKey: ["siteApplicants", siteTrackId, siteTrack],
        staleTime: 86400000,
        enabled: siteTracksQuery.isSuccess && !!siteTrack
    })

    return (siteTracksQuery.isSuccess && applicantsQuery.isSuccess && siteTrack) ? <>
        <SiteTrackSelect siteTracks={siteTracksQuery.data} siteTrackId={siteTrackId} setSiteTrackId={setSiteTrackId} setSiteTrack={setSiteTrack}/>
        {siteTrack.openings > 0 && client.settings.cycle.matchType === "manual" &&
        <Paper className="p-2 my-3">
            <Typography variant="h6">{t("applicants.openingsRemaining", {count: siteTrack.openings})}</Typography>
        </Paper>
        }
        <ApplicantsTable data={applicantsQuery.data} siteId={siteTrack.internshipSiteId} track={siteTrack}/>
    </> : <SpinnerCard message={t("applicants.spinnerLoading")}/>
}

export default StudentApplicantsWrapper