import React, {useState, useCallback, useEffect} from 'react'
import {Card} from './Card'
import update from 'immutability-helper'
import {useAppContext} from "../../lib/context";

// simple way to check whether the device support touch (it doesn't check all fallback, it supports only modern browsers)
export const isTouchDevice = () => {
    return "ontouchstart" in window
};

const style = {
    width: "100%",
}

export type Item<T> = {
    id: string
    component: JSX.Element
    data: T
    newItem?: boolean
}

export type DnDContainerProps<T> = {
    items: Item<T>[]
    setItems: (items: Item<T>[]) => void
    readonly?: boolean
}

export function DnDContainer<T>(props: DnDContainerProps<T>) {
    const {isDebug} = useAppContext()

    const [cards, setCards] = useState<Item<T>[]>([])

    useEffect(() => {
        setCards(props.items)
    }, [props.items])

    const moveCard = useCallback(
        (dragIndex: number, hoverIndex: number) => {
            if (!props.readonly) {
                const dragCard = cards[dragIndex]
                const newCards = update(cards, {
                    $splice: [
                        [dragIndex, 1],
                        [hoverIndex, 0, dragCard],
                    ],
                }).map((x, i) => ({
                    ...x,
                    component: x.component
                }))
                setCards(newCards)
                props.setItems(newCards)
                isDebug && console.log("Card moved", newCards)
            }
        },
        [cards, isDebug, props],
    )

    const renderCard = (card: Item<T>, index: number) => {
        return (
            <Card
                key={card.id}
                index={index}
                id={card.id}
                moveCard={moveCard}
            >
                {card.component}
            </Card>
        )
    }

    return ( <div style={style}>{cards.map((card, i) => renderCard(card, i))}</div> )
}
