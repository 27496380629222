import {API, api} from "../../lib/Api";
import {userInformationAtom} from "../../Atoms";
import {useAtomValue} from "jotai";
import {StudentUserInformation, UserInformation} from "../../login/model/UserInformation";
import {useQuery} from "@tanstack/react-query";
import {Client, ClientId} from "../../client/model/Client";
import {UseQueryResult} from "@tanstack/react-query/src/types";
import {clientAtom} from "../../Atoms";

export const listStudentsKey = (clientId: ClientId) => ["listStudents", clientId]

// TODO should this really be fetched based on programID? this means that all program admins can see all students, thougn, they might be filtered on the back-end?
const useListStudentService = (): UseQueryResult<StudentUserInformation[]> => {
    const userInformation: UserInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client

    return useQuery({
        queryFn: () => api<StudentUserInformation[], undefined>(API.listStudent(), userInformation, client.id)
            .then<StudentUserInformation[]>(response => response.data),
        queryKey: [listStudentsKey(client.id)],
        staleTime: 86400000
    })
}

export default useListStudentService;