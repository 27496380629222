import React, {Dispatch, SetStateAction, useState} from 'react'
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {TableSortLabel} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import {AssociationAction} from "../../program/component/AssociationOptions";
import {SortDirection} from "../../components/customTable/Sorting";
import {AssociationIcon} from "../../program/component/AssociationsList";
import {ProgramLink} from "../../program/component/ProgramLink";
import {SiteProgramRelationship} from "../model/SiteProgramRelationship";
import {InternshipSiteTrackId} from "../model/Organization";
import {ProgramId} from "../../program/model/Program";
import {useAppContext} from "../../lib/context";
import {api, API, APIDefinition, apiError} from "../../lib/Api";
import {AssociationOptions} from "./AssociationOptions";
import {Spinner} from "../../components/SpinnerCard";
import {useDataView} from "../../components/customTable/DataView";
import {messageModal, questionModal} from "../../components/modal/InfoModal";
import {userInformationAtom} from "../../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom} from "../../Atoms";
import {DialogType, ModalAction} from "../../components/modal/ModalProps";

interface AssociationsListProps {
    relationships: SiteProgramRelationship[]
    setIncrement: Dispatch<SetStateAction<Date>>
    siteTrackId: InternshipSiteTrackId
    inProgress: ProgramId[]
    setInProgress: Dispatch<SetStateAction<ProgramId[]>>
}

export type ActionAPIFunction = (programId: ProgramId, siteTrackId: InternshipSiteTrackId) => APIDefinition

const actionMapping: { [k: number]: ActionAPIFunction } = {
    [AssociationAction.Request.valueOf()]: API.requestProgramRelationship,
    [AssociationAction.Approve.valueOf()]: API.acceptProgramRelationship,
    [AssociationAction.Cancel.valueOf()]: API.cancelProgramRequest,
    [AssociationAction.Reject.valueOf()]: API.rejectProgramRelationship
}

const actionMappingMessage: { [k: number]: string } = {
    [AssociationAction.Request.valueOf()]: "Your request has been submitted. An email has been generated to notify Administrators of the affiliation request.",
    [AssociationAction.Approve.valueOf()]: "Your approval has been submitted.",
    [AssociationAction.Cancel.valueOf()]: "You have cancelled the affiliation.",
    [AssociationAction.Reject.valueOf()]: "Your reject has been submitted. An email has been generated to notify Administrators that their request for affiliation has been rejected."
}

const actionMappingConfirmMessage: { [k: number]: string } = {
    [AssociationAction.Request.valueOf()]: "Do you wish to Request this relationship?",
    [AssociationAction.Approve.valueOf()]: "Do you wish to Approve this relationship?",
    [AssociationAction.Cancel.valueOf()]: "Do you wish to Cancel this relationship?",
    [AssociationAction.Reject.valueOf()]: "Do you wish to Reject this reletionship?"
}

export const statusSortMapping = [
    0,
    5,
    6,
    7,
    8,
    3,
    4,
    1,
    2
]

export const AssociationsList = (props: AssociationsListProps) => {
    const {isDebug, setModal} = useAppContext()

    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom)

    const localClientId = client?.id

    const [orderBy, setOrderBy] = useState("Name")

    isDebug && console.log("Assoc list", props.relationships)
    const {items, requestSort, sortConfig} = useDataView(props.relationships)

    const handleUpdate = (programId: ProgramId) => {
        props.setIncrement(new Date())
    }

    const associationsColumns = [
        {
            label: "School Name",
            name: "schoolName",
            comparator: (a: SiteProgramRelationship, b: SiteProgramRelationship) => (a.program.school?.name || "").localeCompare(b.program.school?.name || "")
        },
        {
            label: "Program Name",
            name: "programName",
            comparator: (a: SiteProgramRelationship, b: SiteProgramRelationship) => a.program.name.localeCompare(b.program.name)
        }, {
            label: "Status",
            name: "status",
            comparator: (a: SiteProgramRelationship, b: SiteProgramRelationship) => {
                const opSort = (a.relationship ? statusSortMapping[a.relationship.operationId]: 0) - (b.relationship ? statusSortMapping[b.relationship.operationId]: 0)
                return opSort === 0 ? a.program.name.localeCompare(b.program.name) : opSort
            }

        }, {
            label: "Actions",
            name: "actions",
            comparator: undefined
        }
    ];


    const updateRelationship = (action: ModalAction,  x: {action: AssociationAction, programId: ProgramId, siteTrackId: InternshipSiteTrackId}) => {
        if (localClientId && action === ModalAction.Confirm) {
            setModal && setModal(messageModal(
                "Action Performed",
                actionMappingMessage[x.action.valueOf()]
            ))
            props.setInProgress(e => [...e, x.programId])
            api<any, undefined>(actionMapping[x.action.valueOf()](x.programId, x.siteTrackId), userInformation, localClientId)
                .then(() => handleUpdate(x.siteTrackId)).catch((x) => apiError(x, setModal))
        }
    }

    const buttonHandler = (programId: ProgramId, siteTrackId: InternshipSiteTrackId) => (action: AssociationAction) => {
        setModal && setModal(questionModal<{action: AssociationAction, programId: number, siteTrackId: number}>({action: action, programId: programId, siteTrackId: siteTrackId},
            "Confirm", actionMappingConfirmMessage[action.valueOf()], DialogType.Confirm, updateRelationship)
        )
    }


    /*
    const updateRelationship = (f: (b: ProgramId, c: InternshipSiteTrackId) => APIDefinition, programId: ProgramId, siteTrackId: InternshipSiteTrackId) => {
        if (localClientId) {
            props.setInProgress(e => [...e, programId])
            api(f(programId, siteTrackId), userInformation, localClientId)
                .then(() => handleUpdate(programId)).catch((e) => apiError(e, setModal))
        }
    }

    const buttonHandler = (programId: ProgramId, siteTrackId: InternshipSiteTrackId) => (action: AssociationAction) => {
        localClientId && updateRelationship(actionMapping[action.valueOf()], programId, siteTrackId)
        setModal && setModal(messageModal(
            "Action Performed",
            actionMappingMessage[action.valueOf()]
        ))
    }
     */

    return (
        <TableContainer component={Paper} style={{height: "100%"}}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        {associationsColumns.map((column, index) => {
                            return (
                                <TableCell key={index}>
                                    {column.comparator ?
                                        <TableSortLabel
                                            active={orderBy === column.name}
                                            direction={orderBy === column.name ? sortConfig?.direction : SortDirection.ASC}
                                            onClick={() => {
                                                requestSort(column.comparator);
                                                setOrderBy(column.name);
                                            }}>
                                            {column.label}
                                        </TableSortLabel>
                                        : column.label
                                    }
                                </TableCell>

                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items && items.map((association) =>
                        props.inProgress.includes(association.program.id || 0) ?
                            (<TableRow key={association.program.id}><TableCell
                                colSpan={4}><Spinner/></TableCell></TableRow>) :
                            (<TableRow key={association.program.id}>
                                <TableCell>{association.program.school?.name}</TableCell>
                                <TableCell><ProgramLink program={association.program}/></TableCell>
                                <TableCell><AssociationIcon relationship={association.relationship}/></TableCell>
                                <TableCell><AssociationOptions
                                    buttonHandler={buttonHandler(association.program.id || 0, props.siteTrackId)}
                                    relationship={association.relationship}/></TableCell>
                            </TableRow>)
                    )
                    }
                </TableBody>
            </Table>
        </TableContainer>
    )
}