import React from "react";

import SpinnerCard from "./SpinnerCard";
import {School} from "../school/model/School";
import {FormControl, InputLabel, MenuItem, Select} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import useListSchoolService from "../school/lib/SchoolService";

const useStyles = makeStyles()((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
        maxWidth: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

interface SchoolSelectorProps {
    id: string
    name?: string
    setSchoolId?: (x: number) => void
}

const SchoolSelector: React.FC<SchoolSelectorProps> = (props) => {

    const schoolService = useListSchoolService();

    const schools: Array<School> = schoolService.status === 'loaded' ? schoolService.payload : [] as Array<School>

    const {classes} = useStyles();

    return (schoolService.status === "loading") ? <SpinnerCard/> : (
        <FormControl variant="filled" className={classes.formControl}>
            <InputLabel id="user-schools-label">Schools</InputLabel>
            <Select name={props.name}
                    id={props.id}
                    className="form-control"
                    defaultValue={"0"}
                    onChange={e => props.setSchoolId && props.setSchoolId(parseInt(e.target.value))}>
                <MenuItem key={0} value={"0"}>-- Select School --</MenuItem>
                {schools.map(e => (
                    <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default SchoolSelector