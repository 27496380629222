import React, {useState} from 'react';
import {MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {ClientId} from "../client/model/Client";
import {useAtomValue} from "jotai";
import {clientsAtom} from "../Atoms";

export interface ClientSelectProps {
    className?: string
}

export const ClientSelect = (props: ClientSelectProps) =>  {

    console.log("Here in client select")

    const clients = useAtomValue(clientsAtom)

    const [clientId, setClientId] = useState<ClientId>(0)

    const handleChange = (event: SelectChangeEvent<ClientId>) => {
        setClientId(parseInt(event.target.value as string))
    };

    return (
    <Select name="value"
            variant="filled"
            aria-label={`Client Select`}
            value={clientId}
            className={props.className}
            onChange={handleChange}>
        <MenuItem key={0} value={0}>-- Select Client --</MenuItem>
        {(clients || []).map(e => (
            <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
        ))}
    </Select>
    )
}
