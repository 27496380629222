import {QuestionTypes} from "../../../model/Questionnaire";
import React, {Dispatch, SetStateAction} from "react";
import {SearchInputData, SearchKey} from "../SearchComponent";
import {TextInputSearch} from "../component/TextInputSearch";
import {SelectSearch} from "../component/SelectSearch";
import {MultiSelectSearch} from "../component/MultiSelectSearch";

export interface SearchInputProps {
    searchKey: SearchKey
    value: string
    setSearchInputData: Dispatch<SetStateAction<SearchInputData>>
}

export const SearchInput = (props: SearchInputProps) => {

    console.log("Search Input", props)

    switch (props.searchKey?.typeId) {
        case QuestionTypes.ShortText.id:
            return (<TextInputSearch {...props}/>)
        case QuestionTypes.Select.id:
            return (<SelectSearch {...props}/>)
        case QuestionTypes.MultiSelect.id:
            return (<MultiSelectSearch {...props}/>)
        default: // should only trigger on undefined - TODO empty input box maybe?
            return (<React.Fragment/>)
    }
}