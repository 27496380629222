import {useAppContext} from "../../lib/context";
import React, {useState} from "react";
import {Box, Button, Fab, Typography} from "@mui/material";
import {InternshipSiteId, InternshipSiteTrackId, Organization} from "../../internship-site/model/Organization";
import {ReadinessCheckList} from "../lib/ReadinessCheckList";
import {useNavigate, useParams} from "react-router-dom";
import {PacketRef, StudentApplication} from "../model/StudentMaterials";
import {API, api, getApiUrl} from "../../lib/Api";
import {
    EmptyObject,
    StudentLoggedInComponent, StudentLoggedInComponentWrapper,
    StudentLoggedInContextProps, StudentWrappedLoggedInContextProps
} from "../../components/LoggedInPage";
import SpinnerCard from "../../components/SpinnerCard";
import {StudentOps} from "../../student/model/Student";
import {useMutation, useQuery, useQueryClient} from "@tanstack/react-query";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import ErrorIcon from "@mui/icons-material/Error";
import {useSetAtom} from "jotai";
import {pageHeadingAtom} from "../../Atoms";
import {useModalService} from "../../components/modal/ModalService";
import {LettersOfReferenceSelection} from "./components/LettersOfReferenceSelection";
import {FloatingButtonsContainer} from "../../components/form/FloatingButtonsContainer";
import {ElectiveDocumentSelection} from "./components/ElectiveDocumentSelection";
import {useTranslation} from "react-i18next";
import {withUserRefresh} from "../../login/lib/UserRefresh";
import {SpecificDocumentsSelection} from "./components/SpecificDocumentsSelection";
import {ClientSettings, ClientSettingsOps} from "../../client/model/Client";
import {PacketSlotEntry} from "./components/PacketSlotEntry";

export interface PacketProps {
    siteId: InternshipSiteId
    trackId?: InternshipSiteTrackId
}

export const PacketWrapper: StudentLoggedInComponentWrapper = (props: StudentWrappedLoggedInContextProps) => {
    const params = useParams()

    const siteId = parseInt(params.siteId || '0')
    const trackId: number | undefined = parseInt(params.trackId || "") || undefined

    if (props.state === "ready") {
        //const context: StudentLoggedInContextProps & PacketParams = {...props, siteId, trackId, orgId}
        const context: StudentLoggedInContextProps & PacketProps = {
            state: "ready",
            userInformation: props.userInformation,
            client: props.client,
            siteId: siteId,
            trackId: trackId,
        }

        return <Packet {...context}/>
    }
    else
        return <SpinnerCard message="Loading..."/>
}

export interface PacketState {
    isSatisfied: boolean
    application: StudentApplication
}

const applicationPacketReady = (clientSettings: ClientSettings, packetRefs: PacketRef[]): boolean => {
        return clientSettings.clientCycleSettings.applicationSuiteSettings.packetSlotsDefinition.slotDefinitions
            .filter(x => x.required)
            .map(slot => {
                    if (packetRefs.find(x => x.packetSlotId === slot.id)?.documentId !== undefined) {
                        return true
                    }
                    else {
                        console.log("Nothing for slot ", slot)
                        return false
                    }
                }
            )
            .reduce((a, b) => a && b, true)
}

const Packet: StudentLoggedInComponent<PacketProps> = (props) => {
    const {userInformation, client, siteId, trackId} = props
    const {isDebug} = useAppContext()

    const setPageHeading = useSetAtom(pageHeadingAtom)
    const navigate = useNavigate()

    const queryClient = useQueryClient()
    const modal = useModalService()
    const {t} = useTranslation()

    const initialPacketState: PacketState = {application: {
                siteId: siteId,
                trackId: trackId,
                applicationStatus: "Created",
                adminApprovalQuestionnaireSubmissionId: undefined,
                ...StudentOps.userApplicationsForSiteTrack(userInformation, siteId, trackId),
                packetRefs: [
                    ...(StudentOps.userApplicationsForSiteTrack(userInformation, siteId, trackId)?.packetRefs || []),
                    ...StudentOps.newStudentApplication(
                        client.settings.clientCycleSettings.applicationSuiteSettings.packetSlotsDefinition.slotDefinitions,
                        userInformation, siteId, trackId).packetRefs
                ],
            },
        isSatisfied: applicationPacketReady(client.settings, StudentOps.userApplicationsForSiteTrack(userInformation, siteId, trackId)?.packetRefs || [])
    }

    const [packetState, setPacketState] = useState<PacketState>(initialPacketState)

    isDebug && console.log("Initial packet state is", initialPacketState)

    isDebug && console.log("Application is", packetState.application, StudentOps.newStudentApplication(
        client.settings.clientCycleSettings.applicationSuiteSettings.packetSlotsDefinition.slotDefinitions,
        userInformation, siteId, trackId))

    const [isSaving, setIsSaving] = useState<boolean>(false)

    const packetSlotsDefinition = client.settings.clientCycleSettings.applicationSuiteSettings.packetSlotsDefinition

    isDebug && console.log("packet refs", packetState.application.packetRefs)
    const isSatisfied = applicationPacketReady(client.settings, packetState.application.packetRefs) &&
        StudentOps.hasPaid(userInformation) && StudentOps.isStudentQuestionnaireComplete(userInformation)

    isDebug && console.log("Satisfied", isSatisfied)

    const saveSiteDraftMutate = useMutation({
        mutationFn: (props: {packetRefs: PacketRef[], siteId: InternshipSiteId}) =>
            api<any, any>(API.saveSitePacket(userInformation.guid, props.siteId), userInformation, client.id,
                Object.entries(props.packetRefs).map(x => x[1]))
    })

    const saveSiteTrackDraftMutate = useMutation({
        mutationFn: (props: {packetRefs: PacketRef[], siteId: InternshipSiteId, trackId: InternshipSiteTrackId}) =>
            api<any, any>(API.saveSiteTrackPacket(userInformation.guid, props.siteId, props.trackId), userInformation, client.id,
                Object.entries(props.packetRefs).map(x => x[1])
            )
    })

    const saveDraft = () => {
        if (packetState && userInformation) {
            if (trackId) {
                withUserRefresh({queryClient, updateFlag: setIsSaving }, () => saveSiteTrackDraftMutate.mutateAsync({packetRefs: packetState.application.packetRefs, siteId, trackId}))
                    .then(x => modal.message("Draft Saved", "Your draft has been saved."))
            } else {
                withUserRefresh({queryClient, updateFlag: setIsSaving}, () => saveSiteDraftMutate.mutateAsync({packetRefs: packetState.application.packetRefs, siteId})
                    .then(x => modal.message("Draft Saved", "Your draft has been saved.")))
            }
        }
    }

    const submitSitePacketMutation= useMutation({
        mutationFn: (props: {siteId: InternshipSiteId}) => api<any, any>(API.submitSitePacket(userInformation.guid, props.siteId), userInformation, client.id)
    })

    const submitTrackPacketMutation= useMutation({
        mutationFn: (props: {siteId: InternshipSiteId, trackId: InternshipSiteTrackId}) => api<any, any>(API.submitTrackPacket(userInformation.guid, props.siteId, props.trackId), userInformation, client.id)
    })

    const withdrawPacketMutation= useMutation({
        mutationFn: (siteId: InternshipSiteId) => api<any, any>(API.withdrawPacket(userInformation.guid, siteId), userInformation, client.id)
    })

    const submitPromise = () => {
        if (trackId) {
            return saveSiteTrackDraftMutate.mutateAsync({packetRefs: packetState.application.packetRefs, siteId, trackId})
                .then(() => submitTrackPacketMutation.mutateAsync({siteId, trackId}))
        }
        else {
            return submitSitePacketMutation.mutateAsync({siteId}).then(() => submitSitePacketMutation.mutateAsync({siteId}))
        }
    }


    const submitPacket = () => {
        const elligibleToSubmit = () => {
            const submittedCount = userInformation.student.studentApplicationPackets.applications.filter(x => StudentOps.isApplicationSubmitted(x)).length

            if (!StudentOps.hasPaid(userInformation)) {
                modal.errorMessage(t("applications.modals.paymentRequired"), t("applications.modals.paymentRequiredMessage"), {
                    onClose: () => navigate("/order")
                })
            }

            if (!StudentOps.isStudentQuestionnaireComplete(userInformation)) {
                // We should never reach this
                modal.errorMessage(t("applications.modals.questionnaireRequired"), t("applications.modals.questionnaireRequiredMessage"), {
                    onClose: () => navigate("/materials")
                })
            }

            if (submittedCount >= client.settings.clientCycleSettings.applicationSuiteSettings.packetsPerSubmission) {
                modal.i18nMessage("applications.modals.applicationSubmissionsMaxedTitle", "applications.modals.applicationSubmissionsMessage")
                return false
            }

            if (!StudentOps.studentHasAppliedForSite(userInformation, siteId)) {
                modal.i18nMessage("applications.modals.siteRankRequiredTitle", "applications.modals.siteRankRequiredMessage")
                return false
            }

            return true
        }

        if (elligibleToSubmit()) {
            modal.confirmChange(undefined, {
                    onConfirm: () =>
                        withUserRefresh({queryClient, updateFlag: setIsSaving}, submitPromise)
                }, t("applications.modals.submitPacketMessage"))
        }
    }

    const withdrawPacket = () => {
        withUserRefresh({queryClient, updateFlag: setIsSaving}, () => withdrawPacketMutation.mutateAsync(siteId))
    }

    isDebug && console.log("In submission window", ClientSettingsOps.isInSubmissionWindow(client.settings))
    isDebug && console.log("Packet refs", packetState.application.packetRefs)

    const siteQuery = useQuery({
        queryFn: () => api<Organization, EmptyObject>(API.getOrganizationBySiteId(siteId), userInformation, client.id)
            .then<Organization>(x => x.data),
        queryKey: ["organizationBySiteId", siteId]
    })

    if (StudentOps.isApplicationSubmitted(packetState.application)) {
        return <><Box m={4}>
            <Typography variant="h5">
                {t("applications.applicationSubmitted")}
            </Typography>
            </Box>
        {false &&
            <Box pt={2}>
                <Button variant="contained" onClick={e => withdrawPacket()}>Withdraw Packet and Modify</Button>
            </Box>
        }
        </>
    }

    if (packetSlotsDefinition) {
        if (siteQuery.isSuccess) {
            setPageHeading(t("packet.pageHeader", {siteName: siteQuery.data.name}))

            return isSaving ? <SpinnerCard message="Submitting"/> :
                <>
                    <FloatingButtonsContainer>
                        <Box m={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Fab variant="extended" id="questionnaire-submit" color="primary"
                                 onClick={e => saveDraft()}>
                                <UploadFileIcon sx={{mr: 1}}/>
                                Save Draft
                            </Fab>
                        </Box>
                        {false &&
                            <Box mr={2} >
                                <Box>
                                    <Fab
                                        href={getApiUrl() + API.downloadPacketPDF(userInformation.guid, siteId, trackId).location + "?clientId=" + client.id}
                                        color="primary" variant="extended">
                                        <PictureAsPdfIcon sx={{mr: 1}}/>
                                        {t("packet.previewPDF")}
                                    </Fab>
                                </Box>
                            </Box>
                        }
                        <Box m={1} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Fab disabled={!isSatisfied || !ClientSettingsOps.isInSubmissionWindow(client.settings)} color="secondary" id="submitApplication"
                                 variant="extended" onClick={e => submitPacket()}>
                                <UnarchiveIcon sx={{mr: 1}}/>
                                {t("applications.submitApplication")}
                            </Fab>
                        </Box>
                    </FloatingButtonsContainer>

                    {!StudentOps.isStudentQuestionnaireComplete(userInformation) &&
                        <PacketSlotEntry variant="error">
                            <Box className="flex flex-row m-1">
                                <Box className="m-1 mt-0"><ErrorIcon/></Box>
                                <Box className="m-1">Questionnaire is not yet Completed and Approved</Box>
                            </Box>
                        </PacketSlotEntry>
                    }

                    <SpecificDocumentsSelection client={client} userInformation={userInformation} state={props.state}
                                                application={packetState.application} saveDraft={saveDraft}
                                                updatePacket={setPacketState}/>

                    <ElectiveDocumentSelection client={client} userInformation={userInformation} state={props.state}
                                               application={packetState.application}
                                               updatePacket={setPacketState}/>

                    <LettersOfReferenceSelection client={client} userInformation={userInformation}
                                                 state={props.state}
                                                 application={packetState.application}
                                                 updatePacket={setPacketState}/>

                    <ReadinessCheckList user={userInformation}/>
                </>
        } else {
            return <SpinnerCard message="Loading..."/>
        }
    } else {
        return <>Invalid</>
    }
}

export default PacketWrapper