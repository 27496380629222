import React, {ChangeEvent, useState} from 'react'
import {QuestionnaireElementProps} from "../../model/QuestionnaireList";
import TextField from '@mui/material/TextField'
import {Theme} from "@mui/material/styles";
import {makeStyles} from "tss-react/mui";

const useStyles = makeStyles()((theme: Theme) => ({
        formControl: {
            minWidth: 120,
            width: "100%"
        },
    }));

export const ElementContent = (props:QuestionnaireElementProps) => {
    const [content, setContent] = useState(props.row.content)

    const {classes} = useStyles()

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const x = e.target.value

        setContent(x)

        props.setElement && props.setElement(elem => {
            return {
                ...elem,
                content: x
            }
        })
    }

    return (
        <TextField
            className={classes.formControl}
            id="elementContent"
            label="Content"
            multiline
            rows={4}
            defaultValue={content}
            variant="filled"
            name="content"
            onChange={onChangeHandler}
        />
    )
}
