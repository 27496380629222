import React from 'react';
import {LoginStateCard} from "./components/LoginStateCard";
import {useAppContext} from "./lib/context";
import {Page, PageBody, PageHeader} from "./components/Page";
import {Box, Typography} from "@mui/material";
import {useAtom, useAtomValue} from "jotai";
import {clientAtom, loginStateAtom} from "./Atoms";
import {
    EmptyObject, StudentLoggedInComponent,
    StudentLoggedInComponentWrapper
} from "./components/LoggedInPage";
import SpinnerCard from "./components/SpinnerCard";

const WithdrawnWrapper: StudentLoggedInComponentWrapper = (context) => {
    const {isDebug} = useAppContext()
    const loginState = useAtomValue(loginStateAtom)
    if (isDebug) {
        return context.state === "ready" ? <Withdrawn {...context}/> :
            <SpinnerCard message={`Home Loading... ${context.state} ${loginState}`}/>
    } else {
        return context.state === "ready" ? <Withdrawn {...context}/> :
            <SpinnerCard message={`Home Loading...`}/>
    }
}

const Withdrawn: StudentLoggedInComponent<EmptyObject> = () => {
    const {isDebug} = useAppContext()
    const [client] = useAtom(clientAtom)
    const loginState = useAtomValue(loginStateAtom)

    isDebug && console.log("Login State", loginState)

    const {useLoginStateCard, component} = LoginStateCard(loginState, client)

    return useLoginStateCard ? component : (
        <Page navName="Home" footerPosition="fixed">
            <PageHeader title={`${client.settings.siteName} - Withdrawn`}/>
            <PageBody>
                <Box>
                    <Typography >
                        You have withdrawn from the match. If you wish to participate, please contact your program administrator.
                    </Typography>
                </Box>
            </PageBody>
        </Page>
    );
}

export default WithdrawnWrapper