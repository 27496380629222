import {Box, Tooltip} from "@mui/material";
import {Lock} from "@mui/icons-material";
import React from "react";
import {PositionMarker} from "./DndItemComponent";
import {StudentUserInformation} from "../../login/model/UserInformation";
import {StudentTrack} from "../../student/model/Student";
import {Client, ClientSettingsOps} from "../../client/model/Client";
import {requireGroup} from "../../lib/util";
import Groups from "../../model/Groups";
import {clientAtom} from "../../Atoms";
import {useAtomValue} from "jotai";

export const StudentDnDItemComponent:React.FC<{
    x: StudentTrack, i: number, userInformation: StudentUserInformation, deleteClickHandler: (x: StudentTrack) => void
}> = ({x, i, userInformation, deleteClickHandler}) => {

    const client = useAtomValue(clientAtom) as Client

    const locked = ClientSettingsOps.rankListLocked(client.settings) && !requireGroup(userInformation.userGroups, Groups.globalAdmin)

    return   <Box display="flex" flexWrap="nowrap" alignItems="center">
        <PositionMarker existingPosition={x.position} newPosition={i}/>
        <Box flexGrow={1}>
            {x.site.name} - {x.track.name}
        </Box>
        {locked &&
            <Box>
                <Tooltip title="Locked"><Lock/></Tooltip>
            </Box>
        }
    </Box>
}