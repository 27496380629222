import {useStyle} from "./Page";
import {Box, Grid, Paper, Typography} from "@mui/material";
import React, {ReactNode} from "react";
import {PageFooter} from "./Footer";

export const ErrorFallback: React.FC<{message?: ReactNode}> = ({message}) => {
    const {classes} = useStyle()
        return <Grid container>
            <Grid lg={12} xs={12} item className={classes.pageContent}>
                <Paper className="pagePaper">
                    <Box display="flex" flexWrap="nowrap" p={1} className="subHeader" alignItems="center">
                        <Box p={1} flexGrow={1}>
                            <Typography variant="h4" data-testid="pageHeading">Site Error</Typography>
                        </Box>
                    </Box>
                    <Box p={2}>
                        <Typography variant="h6">An error has occurred on the site, please contact support if you are unable to resolve this.</Typography>
                        {message}
                    </Box>
                </Paper>
            </Grid>
            <PageFooter footerPosition="fixed"/>
    </Grid>
}
