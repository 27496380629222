import React, {useCallback, useEffect, useState} from "react";
import {Checkbox, FormControl, InputLabel, ListItemText, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {InputSearchProps, SearchInputData, useStyles} from "../SearchComponent";
import Input from "@mui/material/Input";
import {useTheme} from "@mui/material/styles";
import {isNonEmpty} from "../../../../components/customTable/util";

export const MultiSelectSearch = (props: InputSearchProps) => {
    const [state, setState] = useState<string[]>([]);

    function getStyles(name: string, multiSelect: string[], theme: any) {
        return {
            fontWeight:
                multiSelect.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const {classes} = useStyles()
    const theme = useTheme();

    const updateSearchInputData = useCallback((prev: SearchInputData, newValue: string[]) => ({
            search: [...(prev.search.filter(x => x.key !== props.searchKey.name)), ...((newValue).map(v => ({key: props.searchKey.name, value: v})))],
            query: prev.query,
            openingsOnly: prev.openingsOnly
    }), [props.searchKey.name])

    useEffect(() => {
        const opts = (props.value?.split(",") || []).filter(x => isNonEmpty(x))

        props.value && setState(opts)
        props.setSearchInputData(prev => props.value ? updateSearchInputData(prev, opts) : prev)
    }, [props, updateSearchInputData])

    const changeHandler = (e: SelectChangeEvent<unknown>) => {
        const newValue = e.target.value as string[]
        setState(newValue)
        props.setSearchInputData(prev => updateSearchInputData(prev, newValue))
    }

    return (
        <React.Fragment>
            <input type="hidden" name="key" value={props.searchKey.name}/>
            <FormControl variant="filled" className={classes.formControl}>
                <InputLabel id={`searchKey-${props.searchKey.name}`}>{props.searchKey.name}</InputLabel>
                <Select
                    name="value"
                    multiple
                    variant="filled"
                    value={state}
                    onChange={changeHandler}
                    input={<Input/>}
                    renderValue={(selected) => (selected as string[]).join(', ')}
                >
                    {props.searchKey.question.options ? props.searchKey.question.options.map((name) => (
                        <MenuItem key={name} value={name} style={getStyles(name, state, theme)}>
                            <Checkbox checked={state.indexOf(name) > -1}/>
                            <ListItemText primary={name}/>
                        </MenuItem>
                    )) : <MenuItem key="none" value={undefined} disabled={true}>[No Options Available]</MenuItem>}
                </Select>
            </FormControl>
        </React.Fragment>
    )
}