import React, {useState} from "react"
import {useAppContext} from "../lib/context";
import {Box, Hidden, Typography} from "@mui/material";
import {ClientSettingsOps, MaterialSlotDefinition, SlotGroup} from "../client/model/Client";
import {ElectiveDocument} from "./lib/ElectiveDocument";
import {SpecificRequiredDocument} from "./lib/SpecificRequiredDocument";
import {makeStyles} from "tss-react/mui";
import {LetterOfReferenceComponent} from "./lib/lettersOfReference/LetterOfReferenceComponent";
import {
    EmptyObject,
    LoggedInComponentWrapper, StudentLoggedInComponent,
    StudentLoggedInContextProps,
    WrappedLoggedInContextProps
} from "../components/LoggedInPage";
import SpinnerCard from "../components/SpinnerCard";
import {StudentUserInformation} from "../login/model/UserInformation";
import {API, getApiUrl} from "../lib/Api";
import {DownloadsControl} from "../components/DownloadsControl";
import {useFetchStudentQuestionnaireData} from "../student/lib/StudentQuestionnaireService";
import {UseQueryResult} from "@tanstack/react-query/src/types";
import {
    QuestionnaireDataAggregate,
} from "../questionnaire/model/QuestionnaireSubmissionElement";
import {StudentOps} from "../student/model/Student";
import {QuestionnaireSubmitted} from "./lib/questionnaireState/QuestionnaireSubmitted";
import {QuestionnaireRejected} from "./lib/questionnaireState/QuestionnaireRejected";
import {QuestionnaireApproved} from "./lib/questionnaireState/QuestionnaireApproved";
import {QuestionnaireUnsubmitted} from "./lib/questionnaireState/QuestionnaireUnsubmitted";
import {QuestionnaireActionComponent} from "./lib/questionnaireState/QuestionnaireActionComponent";
import {PageBlurb} from "../components/PageBlurb";
import {I18Typography} from "../components/I18Typography";

export type MaterialsProps = StudentLoggedInContextProps

const slotGroupsComponentMapping: { [key in SlotGroup]: (x: MaterialSlotDefinition, p: StudentLoggedInContextProps, editable: boolean) => React.ReactNode } = {
    "Letter of Reference": (slotDefinition, p, editable) => <LetterOfReferenceComponent slotDefinition={slotDefinition} client={p.client} userInformation={p.userInformation} state={p.state} editable={editable}/>,
    "Specific Required Document": (slotDefinition, p, editable) => <SpecificRequiredDocument slotDefinition={slotDefinition} client={p.client} userInformation={p.userInformation} editable={editable}/>,
    "Elective Document": (slotDefinition, p, editable) => <ElectiveDocument slotDefinition={slotDefinition} client={p.client} userInformation={p.userInformation} editable={editable}/>,
}

export const useStyles = makeStyles()((theme) => ({
    floatingButtonsBox: {
        position: 'fixed',
        bottom: theme.spacing(4),
        right: theme.spacing(8),
        backgroundColor: "rgba(0, 0, 0, 0.2)",
        boxShadow: "0 0 20px 10px rgba(0,0,0,0.3)",
        zIndex: 100
    },
    materialSlotEntry: {
        margin: "1rem auto",
        padding: theme.spacing(2),
        border: "2px solid rgba(128,128,128,0.3)",
        backgroundColor: "rgba(246,246,246,1)"
    },
    packetSlotEntry: {
        margin: theme.spacing(2),
        padding: theme.spacing(1),
        border: "2px solid rgba(128,128,128,0.2)",
        backgroundColor: "rgba(128,128,128,0.1)"
    },
    formControl: {
        minWidth: "100%",
        maxWidth: "100%",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    formControlGridded: {
        minWidth: "100%",
        maxWidth: "100%",
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1)
    },
    smallDownloadButton: {
        borderRadius: 0,
        padding: "0.2rem 0.4rem",
        backgroundColor: "rgba(0,0,0,0.1)",
        border: "1px solid rgba(0,0,0,0.2)",
        color: "#000"
    }
}));

export const Materials: StudentLoggedInComponent<EmptyObject> = (props: MaterialsProps) => {
    const {isDebug} = useAppContext()
    const {client, userInformation} = props

    const [loading, setLoading] = useState<boolean>(false)

    const { classes } = useStyles()

    const lastQAction = StudentOps.lastQuestionnaireAction(userInformation)

    const editable = ClientSettingsOps.isInSubmissionWindow(client.settings) || StudentOps.studentApplicationsSubmittedCount(userInformation) === 0

    const QActionComponent: QuestionnaireActionComponent = lastQAction.map(x => {
            switch (x.actionType) {
                case 1: // Questionnaire Submitted
                    return QuestionnaireSubmitted
                case 2: // Questionnaire Rejected
                    return QuestionnaireRejected
                case 3: // Questionnaire Approved
                    return QuestionnaireApproved
                default: // Questionnaire Unsubmitted
                    return QuestionnaireUnsubmitted
            }
        }).getOrElse(QuestionnaireUnsubmitted)


    const studentQuestionnaireQuery: UseQueryResult<QuestionnaireDataAggregate, any> = useFetchStudentQuestionnaireData(userInformation, userInformation.guid, client.id, client.settings.clientCycleSettings.applicationSuiteSettings.materialSlotsDefinition.studentQuestionnaireId || 0)

    isDebug && console.log("Material Slot Defn", client?.settings.clientCycleSettings?.applicationSuiteSettings.materialSlotsDefinition)

    const isLoaded = studentQuestionnaireQuery.isSuccess && !loading

    const hasSubmissions = StudentOps.studentApplicationsSubmittedCount(userInformation) > 0

    return isLoaded ? <Box sx={{maxWidth: "700px", margin: "0 auto"}}>
        {client.id === 1 &&
            <Box className="pb-4"><PageBlurb name="materials_pre_clearinghouse" roleSpecific={true}/></Box>
        }

        {hasSubmissions && ClientSettingsOps.isInSubmissionWindow(client.settings) &&
            <Box><I18Typography msgId="materials.materialsInPacket" params={client.settings}/></Box>
        }

        {(ClientSettingsOps.isInRankingWindow(client.settings) || ClientSettingsOps.rankListLocked(client.settings)) && (
          hasSubmissions ? <Box><I18Typography msgId="materials.submissions.inRankingWindow"
                              params={{count: StudentOps.studentApplicationsSubmittedCount(userInformation)}}/></Box>
            : <Box><I18Typography msgId="materials.noSubmissions.inRankingWindow" params={client.settings}/></Box>
        )}

        {ClientSettingsOps.isPreClearingHouse(client.settings) && StudentOps.didNotMatch(userInformation) &&
            <Box><I18Typography msgId="materials.preClearingHouseBlurb.noMatch" params={client.settings}/></Box> }

        <Box className={classes.materialSlotEntry}>
            <Box sx={{display:"flex"}}>
            <Box flexGrow={1}>
                <Typography variant="h5">Student Questionnaire</Typography>
            </Box>
                {false &&
            <Box mr={1}>
                <Hidden mdDown>
                <DownloadsControl downloadUrl={getApiUrl() + API.renderPDF(userInformation.guid).location}
                                  variant="outlined" label="Download Questionnaire as PDF" className={classes.smallDownloadButton}/>
                </Hidden>
                <Hidden mdUp>
                    <DownloadsControl downloadUrl={getApiUrl() + API.renderPDF(userInformation.guid).location}
                                      variant="outlined" label="PDF" className={classes.smallDownloadButton}/>
                </Hidden>
            </Box>
                }
            </Box>
            <Box sx={{display: "flex", margin: "1rem auto 1rem auto"}}>
                <QActionComponent action={lastQAction} student={userInformation} setInAction={setLoading}/>
            </Box>
        </Box>
        {client?.settings.clientCycleSettings?.applicationSuiteSettings?.materialSlotsDefinition.slotDefinitions.map(slot =>
            <Box className={classes.materialSlotEntry}>
                <Typography variant="h5">{slot.name}</Typography>
                {slotGroupsComponentMapping[slot.slotGroup](slot, props, !StudentOps.materialSlotDocumentInSubmission(props.userInformation, slot.id) && editable)}
            </Box>
        )}
    </Box> : <SpinnerCard message="Loading..."/>
}

const MaterialsWrapper: LoggedInComponentWrapper = (context: WrappedLoggedInContextProps) => {
    if (context.state === "ready") {
        if (context.userInformation.student) {
            const studentUser = context.userInformation as StudentUserInformation
            const props: StudentLoggedInContextProps = {...context, userInformation: studentUser}
            return <Materials {...props}/>
        }
        else {
            return <Box>Error - Not a student user</Box>
        }
    }
    else {
        return <SpinnerCard message="Loading..."/>
    }
}

export default MaterialsWrapper
