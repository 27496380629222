import React, {FC, useEffect, useState} from 'react'
import {API, api, apiError} from "../lib/Api";
import {requireOneOfGroup} from "../lib/util";
import Groups from "../model/Groups";
import {AuthenticationFailed} from "../components/AuthenticationFailed";
import {useAppContext} from "../lib/context";
import {Product} from "./model/Product";
import {Page, PageBody, PageHeader} from "../components/Page";
import MaterialTable from "@material-table/core";
import SpinnerCard from "../components/SpinnerCard";
import {TableColumn} from "../components/customTable/CustomTable";
import {confirmDeleteModal, messageModal} from "../components/modal/InfoModal";
import {useNavigate} from "react-router-dom";
import useUserReadyService from "../user/lib/UserReadyService";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../Atoms";
import {clientAtom} from "../Atoms";

interface ProductListProps {

}

export const ProductList: FC<ProductListProps> = (props: ProductListProps) => {
    const {isDebug, setModal} = useAppContext()

    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom)

    const [products, setProducts] = useState<Product[]>([]);

    const navigate = useNavigate()
    const userReady = useUserReadyService()

    useEffect(() => {
        api<Product[], undefined>(API.listProducts(), userInformation, client.id).then(res =>
            setProducts(res.data)
        )
    }, [userInformation, client.id, setProducts])

    if (userReady && !requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin, Groups.clientAdmin])) {
        isDebug && console.log("Returning auth failed")
        return (<AuthenticationFailed/>)
    }

    const addNewProduct = (row: Product) => api(API.addProduct(), userInformation, client.id, row).then(res => {
        setModal && setModal(messageModal("Product Added", "Added a new product: " + row.name))
        return row
    })

    const deleteProduct = (oldData: Product) => new Promise<void>((resolve, reject) =>  {
        return setModal && setModal(confirmDeleteModal<Product>(oldData, (action: string, product: Product) => {
            return action === "delete" && api(API.deleteProduct(oldData.id), userInformation, client.id)
                .then(result => {
                    isDebug && console.log("Deleted Product")
                    setModal && setModal(messageModal("Product", "Product Removed"))
                    setProducts(products.filter(x => x.id !== oldData.id))
                    resolve()
                }).catch(error => {
                    apiError(error, setModal)
                    reject()
                })
        }, "Are you sure you wish to delete this offer?"))
    })

    const updateProduct = (newData: Product, oldData?: Product) => new Promise<any>((resolve, reject) => {
        api(API.updateProduct(newData.id), userInformation, client.id, newData).then(result => {
            setModal && setModal(messageModal("Product", "Product Updated"))
            resolve(result.data)
        }).catch(error => {
            apiError(error, setModal)
            reject(oldData)
        })
    })

    const getProduct = (row: Product | Product[]) => Array.isArray(row) ? row[0] : row

    const showOffers = (row: Product | Product[]) => {
        if (Array.isArray(row)) {
            navigate(`/offers/${row[0].id}`)
        }
        else {
            navigate(`/offers/${row.id}`)
        }
    }

    const addOffer = (row: Product | Product[]) => {
        navigate(`/offer/${getProduct(row).id}`)
    }

    const columns: Array<TableColumn<Product>> = [{
        title: "ID", field: "id", editable: "never"
    }, {
        title: "Type", field: "productType"
    }, {
        title: "Name", field: "name"
    }, {
        title: "Description", field: "description"
    }]

    return products ? (
        <Page navName="ProductList">
            <PageHeader title="Product List">Product List</PageHeader>
            <PageBody>
                {products ?
                <MaterialTable columns={columns} data={products} options={{pageSize: 50}}
                               editable={{
                                   isEditable: row => true,
                                   isDeletable: row => true,
                                   onRowAdd: addNewProduct,
                                   onRowDelete: deleteProduct,
                                   onRowUpdate: updateProduct
                               }}
                               actions={[{
                                   icon: 'local_offer',
                                   tooltip: 'Show Offers',
                                   onClick: (event: any, rowData: Product | Product[]) => {
                                       showOffers(rowData)
                                   }
                               },{
                                   icon: 'add_shopping_cart',
                                   tooltip: 'Add New Offer',
                                   onClick: (event: any, rowData: Product | Product[]) => {
                                       addOffer(rowData)
                                   }
                               }]}
                /> : <SpinnerCard message="Loading products..."/>}
            </PageBody>
        </Page>
    ) : <SpinnerCard/>
}