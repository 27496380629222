import {UseQueryResult} from "@tanstack/react-query/src/types";
import {useCallback, useEffect} from "react";
import {slowQueryAtom} from "../Atoms";
import {useSetAtom} from "jotai";
import {useTranslation} from "react-i18next";

export const slowCallTimeout = 5000;

export const useApiWrapper = <T,>(result: UseQueryResult<T>, label: string) => {
    const setSlowQuery = useSetAtom(slowQueryAtom)

    const {t} = useTranslation()

    const setSlow = useCallback(() => {
        console.log("Query is slow", result.status, label)
        setSlowQuery(t("spinner.slowQuery"))
    }, [setSlowQuery, result.status, label, t])

    useEffect(() => {
        if (result.status === "loading") {
            setTimeout(setSlow, slowCallTimeout)
        }
        else {
            setSlowQuery("")
        }
    }, [result.status, setSlow, setSlowQuery])

    return result
}