import React, {RefObject, useRef} from 'react'
import {Theme} from "@mui/material/styles";
import {useAppContext} from "../lib/context";
import {api, API, apiError} from "../lib/Api";
import {messageModal} from "../components/modal/InfoModal";
import SpinnerCard from "../components/SpinnerCard";
import {makeStyles} from "tss-react/mui";
import {Client, ClientSettings, clientSettingsValidator} from "./model/Client";
import {currentClientQueryKey, userInformationAtom} from "../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom} from "../Atoms";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {LoggedInComponentWrapper} from "../components/LoggedInPage";
import {OpenForm} from "../components/openFormat/Form";
import {ZodError} from "zod";

const useStyles = makeStyles()((theme: Theme) => ({
        formControl: {
            minWidth: 120,
            width: "100%",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
            backgroundColor: "rgba(255,255,255,0.5)"
        },
        hFlowBox: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1)
        },
        fab: {
            position: 'fixed',
            bottom: theme.spacing(4),
            right: theme.spacing(8),
        }
    }))

const ClientSettingsWrapper: LoggedInComponentWrapper = (context) => {
    if (context.state === "ready") {
        const props = {
            ...context
        }
        return <ClientSettingsEdit {...props}/>
    }
    else {
        return <SpinnerCard message="Loading..."/>
    }
}

const ClientSettingsEdit: LoggedInComponentWrapper = (props) => {
    const {isDebug, setModal} = useAppContext()
    const client = useAtomValue(clientAtom) as Client
    const userInformation = useAtomValue(userInformationAtom)

    const clientSettings = client.settings
    const localClientId = client.id

    const queryClient = useQueryClient()

    const form: RefObject<HTMLFormElement> = useRef<HTMLFormElement>(null)

    const clientSettingsMutation = useMutation({
        mutationFn: (data: ClientSettings) => api<ClientSettings, ClientSettings>(API.updateClientSettings(localClientId), userInformation, localClientId, data)
    })

    const handleSubmit = (data: Record<string, any>) => {
        console.log("Handling submit for client settings")

        try {
            // We need to fill in a couplle things so validation works.

            const updated: ClientSettings = {
                ...data as ClientSettings,
                clientCycleSettings: {
                    ...data.clientCycleSettings,
                    applicationSuiteSettings: {
                        ...data.clientCycleSettings.applicationSuiteSettings,
                        materialSlotsDefinition: {
                            ...data.clientCycleSettings.applicationSuiteSettings.materialSlotsDefinition,
                            slotDefinitions: data.clientCycleSettings.applicationSuiteSettings.materialSlotsDefinition.slotDefinitions || [],
                        },
                        packetSlotsDefinition: {
                            ...data.clientCycleSettings.applicationSuiteSettings.packetSlotsDefinition,
                            slotDefinitions: data.clientCycleSettings.applicationSuiteSettings.packetSlotsDefinition.slotDefinitions || [],
                        },
                    }

                }
            }

            isDebug && console.log("Pre-parsed data", updated)
            const parsedData = clientSettingsValidator.parse(updated)

            clientSettingsMutation.mutateAsync(parsedData)
                .then(x => setModal && setModal({
                    ...messageModal("Success", "Client Settings Updated"),
                    onClose: () => queryClient.invalidateQueries(currentClientQueryKey())
                }))
                .catch(err => apiError(err, setModal))


            isDebug && console.log("Client Settings Update", parsedData)
        } catch (e) {
            if (e instanceof ZodError) {
                e.errors.forEach(err => {
                    console.log(`Validation failed for ${err.path.join('.')}: ${err.message}`);
                })
            }
            throw e
        }
    }

    const {classes} = useStyles()

    isDebug && console.log("Client Settings", clientSettings)

    // This is using the default client object because this renders before the client is fully loaded.
    // TODO make a isLoaded flag on client object
    return <OpenForm data={clientSettings} validator={clientSettingsValidator} classes={classes} onSubmit={handleSubmit} formRef={form} defaultValue={{}}/>
}

export default ClientSettingsWrapper