import React, {useEffect, useState} from "react";
import {QuestionnaireInputProps} from "../QuestionnaireEdit";
import {FormControl, Tooltip} from "@mui/material";
import {DatePicker} from "@mui/x-date-pickers";
import {answerFor, useStyles} from "../QuestionnaireList";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {isPresent} from "../../components/customTable/util";
import dayjs, {Dayjs} from "dayjs";

const QuestionnaireDate = (props: QuestionnaireInputProps) => {

    const {classes} = useStyles()

    const [date, setDate] = useState<Dayjs | null>(null)

    useEffect(() => {
        const answer = answerFor(props)
        answer && setDate(dayjs(new Date(answer)))
    }, [props])

    const q = props.question

    const handleDateChange = (e: Dayjs | null) => {
        if (e && e.isValid()) {
            setDate(dayjs(e.toDate()))
            props.onChange && props.onChange(e.toDate().toISOString())
        }
    }

    return q && (
        <FormControl className={classes.formControl}>
            {props.question.isRequired && !isPresent(props.answer, "answer") &&
            <Tooltip title="This is a required question that has no answer">
                <ErrorOutlineIcon className="stop" style={{position: "absolute", left: "-30px", top: "14px"}}/>
            </Tooltip>
            }
            <DatePicker label={q.label}
                        readOnly={props.editable === false}
                        onChange={handleDateChange}
                        slotProps={{
                            textField: {
                                name: `question-${q.id}`,
                                id: `question-${q.id}`,
                                required: props.requiredBlocking && props.question.isRequired
                            }
                        }}
                        value={date}/>

            <small id={`question-${q.id}-help`} className="form-text text-muted">{props.question.isRequired &&
            <span>This is a required field.</span>} {q.helpText}</small>
        </FormControl>
    )
}

export default QuestionnaireDate