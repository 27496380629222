import {StudentUserInformation, UserInformation} from "../../login/model/UserInformation";
import {Client, MaterialSlotDefinition} from "../../client/model/Client";
import {MaterialElement} from "../../application/model/StudentMaterials";
import React, {useState} from "react";
import {Box, IconButton, Tooltip} from "@mui/material";
import {API, getApiUrl} from "../../lib/Api";
import GetAppIcon from "@mui/icons-material/GetApp";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {requireOneOfGroup} from "../../lib/util";
import Groups from "../../model/Groups";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Collapse from "@mui/material/Collapse";
import {UploadDocumentDropzoneControl} from "../../application/lib/UploadDocumentDropzoneControl";
import {useStyles} from "./StudentApplicationList";
import InputIcon from '@mui/icons-material/Input';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import {
    useDeclineLetterOfReferenceService
} from "../../application/lib/lettersOfReference/lib/DeclineLetterOfReferenceService";
import {useModalService} from "../../components/modal/ModalService";
import {StudentOps} from "../model/Student";
import {Optional} from "../../lib/Optional";

export const MaterialElementChip = (userInformation: UserInformation, client: Client, user: StudentUserInformation, slot: MaterialSlotDefinition) => {
    const fulfilled: MaterialElement | undefined = (user.student?.materials?.materials || []).find(x => x.materialSlotId === slot.id)
    const {classes} = useStyles()

    const [showFileUpload, setShowFileUpload] = useState(false)
    const declineLetterOfReferenceService = useDeclineLetterOfReferenceService()

    const modal= useModalService()

    const declineLetterOfReference = (guid: string, lorUUID: string) => {
        modal.confirmChange({guid, lorUUID}, {
                onConfirm: ({guid, lorUUID}) => declineLetterOfReferenceService.mutate({guid, lorUUID})
            },
            "Are you sure you want to decline this letter of reference on behalf of the referent?")
    }

    if (slot)
        return <Box p={1} className={(!!fulfilled ? "go" : "stop") + " " + classes.materialChip} alignItems="center">
            <Box display="flex" alignItems="center">
                <Box>{slot.name}</Box>
                <Box>
                    {fulfilled?.document?.id &&
                        <Tooltip title="Download PDF">
                            <IconButton href={getApiUrl() + API.downloadMaterial(user.guid, fulfilled.document.id).location}
                                        target="_blank" download>
                                <GetAppIcon/>
                            </IconButton>
                        </Tooltip>
                    }
                </Box>
                <Box>
                    {fulfilled?.document?.id &&
                        <Tooltip title="View PDF">
                            <IconButton
                                href={getApiUrl() + API.downloadMaterial(user.guid, fulfilled.document.id, "inline").location}
                                target="_blank">
                                <PictureAsPdfIcon/>
                            </IconButton>
                        </Tooltip>
                    }
                </Box>
                {requireOneOfGroup(userInformation.userGroups, [Groups.globalAdmin, Groups.clientAdmin]) && <>
                    <Tooltip title="Replace File">
                        <Box>
                            <IconButton onClick={() => setShowFileUpload(true)}><UploadFileIcon/></IconButton>
                        </Box>
                    </Tooltip>

                    {Optional.apply(StudentOps.letterOfReferenceBySlotId(user, slot.id)).map(lor =><>
                    <Box>
                        <IconButton href={`/provideLetterOfReference/${user.guid}/${lor.id}`}><InputIcon/></IconButton>
                    </Box>
                     <Box>
                        <IconButton onClick={(e) => declineLetterOfReference(user.guid, lor.id)}><NoAccountsIcon/></IconButton>
                    </Box></>).getOrElse(<></>)
                    }
                </>
                }
            </Box>
            {Optional.apply(StudentOps.letterOfReferenceBySlotId(user, slot.id)).map(lor => <Box>
                {lor.referentEmail} - {lor.referentName} - {lor.referentPhone}
            </Box>
            ).getOrElse(<></>)}
            <Box>
                <Collapse in={showFileUpload}>
                    <Box sx={{display: 'flex', justifyContent: "flex-end", flexDirection: "column"}}>
                        <Box flexGrow={1} sx={{marginLeft: "auto"}}>
                            Replace File Upload
                        </Box>
                        <Box flexGrow={1} sx={{marginLeft: "auto"}}>
                            <UploadDocumentDropzoneControl
                                userInformation={userInformation}
                                client={client}
                                slotDefinition={slot}
                                guid={user.guid}
                                uploadType="material"
                            />
                        </Box>
                    </Box>
                </Collapse>
            </Box>
        </Box>
    else <></>
}