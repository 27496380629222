import React from "react";
import {Page} from "./components/Page";
import {Card, CardContent} from "@mui/material";

const Terms = () => {
    return (
        <Page navName="" bottomNavName="Terms">
            <Card>
                <CardContent>
                    <h1>Terms of Service</h1>

                    <h2>Acceptance</h2>
                    <p>By accessing web pages and domains within internfit.com or practicumfit.com (the “pages,”
                        “websites,” “sites”, “We”, “Us”), you acknowledge receipt of the following Terms of Service. By
                        continuing to use this website you agree to be bound by them and abide by their restrictions. If
                        you do not agree to abide by these terms, conditions, and restrictions, please do not access or
                        make use of this site. By using this site, you also agree that you are an adult (at least 18
                        years of age) and have the legal right to accept these terms.</p>

                    <p>We may periodically update these Terms of Service, and reserve the right to make additions,
                        deletions, and modifications without notice. All such changes will be effective upon posting to
                        the website. The date of last update is posted at the bottom of this page, and you may refer to
                        this date to determine whether these terms have recently been updated. If you have questions,
                        concerns, or suggestions about this policy, please email us at support@practicumfit.com, and we
                        will do our best to address your concerns.</p>

                    <h2>Use of Site</h2>
                    <p>What you can do:</p>

                    <p>Your access to this site has been provided through a Matching Organization your Agency or School
                        is associated with. By accessing this site, you agree to limit your use of this platform to
                        activities consistent with those permitted by the Organization which provided you with this
                        invitation. You agree to abide by all rules and requirements made by your Matching Organization
                        for participation as a user with your access-type (Student/Applicant, Agency/Site Administrator,
                        or School/Program Administrator).</p>

                    <p>You understand that Match Fit LLC is contracted to provide a secure technical platform for
                        Match-related services, and that We do not manage the Match and its hiring-related functions or
                        arbitrate disputes related to these activities. Fees paid to Us to access these services provide
                        access to the technical platform, and if applicable, inclusion in the algorithmic matching
                        service, and these fees do not guarantee any specific outcome from your participation. You agree
                        that any concerns or conflicts arising from your use of the service will be arbitrated by and
                        through your Matching Organization, which has the authority to suspend or terminate your access
                        at any time, for any reason.</p>

                    <p>What you cannot do:</p>
                    You agree not to use the site for any abusive, disruptive, hostile, or illegal purposes.<br/>
                    You agree not to disrupt or circumvent the function of the site, other users’ access or experience
                    on the site, or the normal functions and rules of the associated match you are participating
                    in.<br/>
                    You agree not to send excessive or unprofessional email or messages using tools on this site, nor to
                    send messages unrelated to the legitimate functions described in “What you can do.”<br/>
                    You agree not to harvest data off this site, whether manually or by the use of automated software,
                    unless for personal use related to the match you are participating in. In no case may you sell
                    information gathered on this site (also see the Copyright section below)<br/>
                    You agree not to access or attempt to access areas of the site that are not accessible to your
                    account, whether by use of malicious code, using another user’s account, or creating or using an
                    unauthorized account.<br/>
                    You agree not to obstruct access to the site, whether by damaging the site’s function or by
                    overloading it with excessive use, access, or queries (i.e. a DDoS attack), or malicious
                    software.<br/>

                    <h2>Copyright</h2>
                    <p>All content of this site, including text, graphics, data, and images, is protected by copyright
                        to the respective owners. Content input and provided by users is copyright to the user that
                        provided the content, and may be accessed and reproduced by other users for the legitimate
                        purposes of the associated match. Further details of legitimate use may be found in our Privacy
                        Policy and the policies of your Match Organization.</p>

                    <p>Otherwise, no content on this site may be copied, reproduced, republished, or redistributed in
                        any form, without the prior written approval of MatchFit, LLC; PracticumFit, or InternFit.</p>

                    <h2>Limitation of Liability</h2>
                    <p>Information Provided "As Is": THE WEB SITE AND THE WEB SITE INFORMATION IS PROVIDED "AS IS".
                        MATCH FIT LLC AND ITS AFFILIATES, AND EACH OF THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES,
                        CONSULTANTS AND AGENTS DO NOT WARRANT THE ACCURACY, ADEQUACY, TIMELINESS, OR COMPLETENESS OF THE
                        WEB SITE OR WEB SITE INFORMATION, AND DISCLAIM LIABILITY FOR ANY ERRORS OR OMISSIONS. NO
                        WARRANTY OR CONDITION OF ANY KIND (IMPLIED, EXPRESS, OR STATUTORY) IS GIVEN IN THE WEB SITE OR
                        WEB SITE INFORMATION. MATCH FIT LLC RESERVES THE RIGHT TO MODIFY OR DISCONTINUE, TEMPORARILY OR
                        PERMANENTLY, THE WEB SITE (OR ANY PART THEREOF) WITH OR WITHOUT NOTICE TO YOU.
                        No Liability for Use of Web Site: YOU AGREE TO THE EXTENT PERMITTED BY THE LAW APPLICABLE TO
                        YOU, TO DEFEND, INDEMNIFY, AND HOLD HARMLESS MATCH FIT LLC AND ITS AFFILIATES, AND EACH OF THEIR
                        RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, CONSULTANTS AND AGENTS WILL NOT BE LIABLE FOR ANY
                        DAMAGES, LOSSES OR EXPENSES (INCLUDING DIRECT, INDIRECT, SPECIAL, INCIDENTAL, CONSEQUENTIAL OR
                        PUNITIVE DAMAGES) ARISING FROM THE USE OR INABILITY TO USE THIS WEB SITE OR THE WEB SITE
                        INFORMATION, OR ANY ERROR, OMISSION, INTERRUPTION, DEFECT, DELAY, COMPUTER VIRUS, SYSTEM
                        FAILURE, LOSS OF DATA OR OTHERWISE, EVEN IF MATCH FIT LLC OR ITS REPRESENTATIVES ARE ADVISED OF
                        THE POSSIBILITY OF SUCH DAMAGES, LOSSES OR EXPENSES.
                        TO THE EXTENT APPLICABLE LAW CONFERS SOVEREIGN IMMUNITY ON YOU, NOTHING IN THE PRECEDING
                        PARAGRAPH SHALL BE CONSTRUED AS A WAIVER OF SUCH SOVEREIGN IMMUNITY.
                        CERTAIN STATE LAWS DO NOT ALLOW LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR LIMITATION
                        OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU, SOME OR ALL OF THE ABOVE DISCLAIMERS,
                        EXCLUSIONS, OR LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MIGHT HAVE ADDITIONAL RIGHTS.
                        Third-party web sites: Hyperlinks to other Internet resources or web sites are used at your own
                        risk. Match Fit LLC makes no representations whatsoever about other web sites which you may
                        access through the Sites.
                    </p>

                    <h2>Applicable Law</h2>
                    <p>Except where prohibited by the laws of your jurisdiction, this site and its use will be governed
                        by the laws of the state of California, and any applicable federal laws.</p>

                    <h2>Privacy</h2>
                    <p>Please refer to our Privacy Policy for full details of how we will collect, store, use, and
                        distribute data input by and pertaining to you. The specific details are determined by your
                        consortium and match, as well as your role (student/site/school).
                    </p>

                    <h2>How to Contact Us</h2>
                    <p>If you have questions about these terms of service, or our privacy policy, please contact us at
                        support@practicumfit.com, and we’ll do our best to answer them.
                        <p><i>Last updated: November 20, 2020</i></p>
                    </p>
                </CardContent>
            </Card>
        </Page>
    )
}

export default Terms
