import {useAppContext} from "../../lib/context";
import {useQueryClient} from "@tanstack/react-query";
import {LoginState, signInResponse} from "./UserSessionsService";
import {
    loginStateAtom,
    userInformationAtom,
    userInformationQueryKey
} from "../../Atoms";
import {useSetAtom} from "jotai";
import {CognitoResult, signInUser} from "../../components/CognitoAuth";

export const useSignInService = () => {
    const {isDebug} = useAppContext()

    const setUserInformation= useSetAtom(userInformationAtom)
    const setLoginState = useSetAtom(loginStateAtom)
    const queryClient = useQueryClient()

    /*
    const logSignInMutation = useMutation({
        mutationFn: (userInformation: UserInformation) => api(API.signInUser(userInformation.email), userInformation, client.id)
            .catch(err => {
                console.log("Failed to sign in user after refresh", err)
            })
    })

     */

    const signIn = (username: string, password: string): Promise<signInResponse> => {
        setUserInformation(userInformation => {
            userInformation.email = username
            isDebug && console.log("Updating user information with sign in email", userInformation)
            return userInformation
        })
        isDebug && console.log("User Session Service SignIn")
        return signInUser(username, password).then(async e => {
            isDebug && console.log("sign in result", e)
            switch(e.status) {
                case CognitoResult.COGNITO_SUCCESS:
                    isDebug && console.log("Sign-in success")
                    // Have to update the global jwt token reference because React doesn't do a fucking page refresh anymore.
                    setUserInformation(userInformation => {
                        userInformation.email = username
                        userInformation.jwtToken = e.session.session.getIdToken().getJwtToken()
                        isDebug && console.log("Updating user information with ", userInformation)
                        return userInformation
                    })
                    queryClient.invalidateQueries(userInformationQueryKey())
                    // TODO Fix me here, this should fetch user Info and update the user information
                    //logSignInMutation.mutate(UserInformationShape.userInformation(username, password))
                    return {
                        status: LoginState.Authenticating
                    }

                case CognitoResult.COGNITO_NEW_PASSWORD:
                    isDebug && console.log("Sign-in requires new password")
                    setLoginState(LoginState.NewPasswordRequired)
                    return {
                        status: LoginState.NewPasswordRequired
                    }
                case CognitoResult.COGNITO_AUTH_FAILED:
                    isDebug && console.log("Sign-in failed")
                    setLoginState(LoginState.AuthenticationFailed)
                    return {
                        status: LoginState.AuthenticationFailed,
                        error: e.error
                    }
                default:
                    isDebug && console.log("Sign-in error")
                    setLoginState(LoginState.BadSystemState)
                    return {
                        status: LoginState.BadSystemState,
                        error: "Sign in Error"
                    }
            }

        }).catch(err => {
            isDebug && console.log("Cognito Service auth failed", err)
            setLoginState(LoginState.BadSystemState)
            return {
                status: LoginState.BadSystemState,
                error: err.error.message || "System Error"
            }
        })
    }

    return {
        signIn: signIn
    }
}