export interface Address {
    id?: number
    address1: string | undefined
    address2: string | undefined
    city: string | undefined
    region: string | undefined
    postalCode: string | undefined
}

export const emptyAddress = {
    address1: undefined,
    address2: undefined,
    city: undefined,
    region: undefined,
    postalCode: undefined
}
