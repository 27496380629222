import React, {ReactNode, useRef, useState} from "react"
import {API, api, APIDefinition} from "../../lib/Api";
import {useAppContext} from "../../lib/context";
import {Button, FormControl, TextField} from "@mui/material";
import {makeStyles} from "tss-react/mui";
import {Client, MaterialSlotDefinition, PacketSlotDefinition} from "../../client/model/Client";
import SpinnerCard from "../../components/SpinnerCard";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {StoredDocument} from "../model/Document";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";
import {useQueryClient} from "@tanstack/react-query";
import {refreshUser} from "../../Atoms";

const useStyles = makeStyles()((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        width: "calc(100% - 10px)",
        marginRight: "10px"
    },
    fileInput: {
        fontSize: "1.4rem",
        border: 0,
        clip: "rect(0, 0, 0, 0)",
        height: "1px",
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        whiteSpace: "nowrap",
        width: "1px"
    },
    fileContainer: {
        padding: "6px 16px",
        borderRadius: "4px",
        cursor: "pointer",
        backgroundColor: theme.palette.primary.main,
        fontSize: "0.875rem",
        color: "white",
        textTransform: "uppercase", display: "inline-flex", alignItems: "center", flexDirection: "row",
        height: "2rem",
        boxShadow: "0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)"
    },
    fileLabel: {}
}));

export const endpoint = (uploadType: string): (guid: string) => APIDefinition => {
    switch (uploadType) {
        case "material":
            return API.uploadMaterial
        case "packet":
            return API.uploadPacket
        case "letterOfReference":
            return API.uploadLetterOfReference
        default:
            throw new Error("Failed to find upload API for type " + uploadType + " which likely means it's an invalid type")
    }
}


export const UploadDocumentControl: React.FC<{
    slotDefinition: MaterialSlotDefinition | PacketSlotDefinition,
    uploadType: "material" | "packet" | "letterOfReference",
    children?: ReactNode,
    completeUpload?: (doc: StoredDocument) => void,
    guid: string,
    client: Client
}> =
    ({guid , client, uploadType, slotDefinition, children, completeUpload}) => {
        const {isDebug} = useAppContext()
        const userInformation = useAtomValue(userInformationAtom)

        const localClientId = client?.id || 0

        const [isUploading, setIsUploading] = useState(false)
        const [selectedFile, setSelectedFile] = useState<File>()
        const [isFilePicked, setIsFilePicked] = useState(false)
        const [documentName, setDocumentName] = useState<string>()

        const uploadRef = useRef<HTMLInputElement>(null);
        const formRef = useRef<HTMLFormElement>(null);

        const queryClient = useQueryClient()

        isDebug && console.log("Upload type is", uploadType)

        const changeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
            if (event.target.files) {
                setSelectedFile(event.target.files[0]);
                setIsFilePicked(true);
            }
        };


        const handleClick= (event: React.MouseEvent<HTMLButtonElement>) => {
            setIsUploading(true)

            if (selectedFile && formRef.current) {
                const formData = new FormData(formRef.current)
                const uploadHTMLElem = uploadRef.current
                if (uploadHTMLElem?.files && uploadHTMLElem?.files[0]) {
                    isDebug && console.log("Found file to attach")
                    formData.append("document", uploadHTMLElem?.files[0]);
                }
                isDebug && console.log("Form Data", formData.entries())

                api<StoredDocument, any>(endpoint(uploadType)(guid), userInformation, localClientId, formData, "multipart/form-data")
                    .then((response) => response.data)
                    .then((result) => {
                        console.log('Success:', result);
                        completeUpload && completeUpload(result)
                        refreshUser(queryClient).then(() => {
                            setIsUploading(false)
                        })
                    })
                    .catch((error) => {
                        setIsUploading(false)
                        console.error('Error:', error);
                    });
            }
        }

        const {classes} = useStyles();

        return isUploading ? <SpinnerCard message="Document uploading"/> :
            <form encType="multipart/form-data" ref={formRef}>
                <input type="hidden" name="slotId" value={slotDefinition.id}/>
                <input type="hidden" name="documentType" value={slotDefinition.slotGroup}/>
                {children || <></>}
                <FormControl className={classes.formControl}>
                    <TextField name="documentName"
                               aria-label="Document Name"
                               label="Document Name"
                               placeholder="Enter a name for your document"
                               variant="outlined"
                               value={documentName}
                               onChange={(e) => setDocumentName(e.target.value)}
                               required={true}
                    />
                </FormControl>
                <input type="file" name="file" ref={uploadRef} onChange={changeHandler} className={classes.fileInput}
                       id={`file-${slotDefinition.id}`} accept="application/pdf"/>
                <label className={classes.fileLabel} htmlFor={`file-${slotDefinition.id}`}>
                    <div className={classes.fileContainer}>
                        <UploadFileIcon style={{marginRight: "8px"}}/>
                        Select File
                    </div>
                </label>
                {selectedFile && isFilePicked && (<>
                        <FormControl className={classes.formControl}>
                            <div>
                                <p>Filename: {selectedFile.name}</p>
                                <p>Filetype: {selectedFile.type}</p>
                            </div>
                        </FormControl>
                        <div>
                            <Button type="button" variant="contained" onClick={handleClick}>Upload File and Submit</Button>
                        </div>
                    </>
                )}
            </form>
    }