import React, {useCallback, useEffect, useState} from "react";
import {useAppContext} from "../../lib/context";

import {SiteTrackSelect, siteTracksFromOrganization} from "./SiteTrackSelect";
import {API, api, apiError} from "../../lib/Api";
import {AssociationsList} from "./AssociationsList";
import {InternshipSiteTrack, InternshipSiteTrackId} from "../model/Organization";
import useListOrganizationService from "../lib/OrganizationService";
import {SiteProgramRelationship} from "../model/SiteProgramRelationship";
import {useDebounce} from "../../lib/useDebounce";
import {ProgramId} from "../../program/model/Program";
import {head, requireOneOfGroup} from "../../lib/util";
import {useNavigate} from "react-router-dom";
import {Box} from "@mui/material";
import {PageBody, PageHeader} from "../../components/Page";
import Groups from "../../model/Groups";
import useUserReadyService from "../../user/lib/UserReadyService";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";
import {clientAtom} from "../../Atoms";

interface TrackProgramListProps {
    siteTrackId: InternshipSiteTrackId | undefined
}

const TrackProgramList = (props: TrackProgramListProps) => {
    const {isDebug, setModal} = useAppContext();

    const client = useAtomValue(clientAtom)
    const userInformation = useAtomValue(userInformationAtom)

    const [associations, setAssociations] = useState<SiteProgramRelationship[]>()
    const [increment, setIncrement] = useState<Date>(new Date())
    const [inProgress, setInProgress] = useState<ProgramId[]>([])

    const userReady = useUserReadyService()

    const localClientId = client?.id

    isDebug && console.log("Program List", props.siteTrackId)

    const organizationService = useListOrganizationService({})

    const siteTracksUnfiltered: Array<InternshipSiteTrack> =
        organizationService.status === 'loaded' ? organizationService.payload.flatMap(e => siteTracksFromOrganization(e)) : []

    const siteTracks = requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin, Groups.clientAdmin])  ? siteTracksUnfiltered :
        siteTracksUnfiltered.filter(x => userInformation?.sites?.includes(x.internshipSiteId))

    const siteTrackId = props.siteTrackId || head(siteTracks.map(x => x.id)) || 0

    const debouncedRelationships = useDebounce(useCallback(() => {
            if (userReady && localClientId && siteTrackId) {
                api(API.siteTrackProgramRelationships(siteTrackId), userInformation, localClientId)
                    .then(response => {
                        setAssociations((response.data as SiteProgramRelationship[]).sort((a, b) => {
                            if (a.program.school && b.program.school && a.program.school.name !== b.program.school.name) {
                                return a.program.school.name.localeCompare(b.program.school.name)
                            }
                            else {
                                return a.program.name.localeCompare(b.program.name)
                            }
                        }))
                        setInProgress([])
                    })
                    .catch(error => apiError(error, setModal))
            }
        }, [userReady, localClientId, siteTrackId, userInformation, setAssociations, setInProgress, setModal]), 1000)

    useEffect(() => {
        debouncedRelationships(null)
    }, [siteTrackId, localClientId, userInformation, increment, debouncedRelationships])

    const navigate = useNavigate()

    const setSiteTrackId = (e: number) => {
        isDebug && console.log("Setting site track ID", e, siteTrackId)
        if (siteTrackId !== e) {
            navigate(`/trackPrograms/${e}`)
        }
    }


    isDebug && console.log('TrackProgramList', siteTracks)
    // Todo: to make this logic more standarized based on how many we want to show without scroll and its diff for each table.
    return (<>
        <PageHeader title="Track Relationships">
            <>
                <Box style={{minWidth: "10rem"}} flexGrow={1} p={1}>
                    <SiteTrackSelect siteTracks={siteTracks} setSiteTrackId={setSiteTrackId} siteTrackId={siteTrackId}/>
                </Box>

                {/**
                <Box p={1} m={1}>
                    <DownloadsControl csvDownloadUrl={getApiUrl() + API.siteTrackProgramRelationships(siteTrackId, "csv").location} />
                </Box>
 **/}
            </>
        </PageHeader>
        <PageBody>
                {associations ? <AssociationsList inProgress={inProgress} setInProgress={setInProgress} siteTrackId={siteTrackId} relationships={associations} setIncrement={setIncrement}/> :
                    <span>Select a Track to see Relationships</span>}
        </PageBody>
    </>)
}

export default TrackProgramList