import React, {MouseEvent, useState} from 'react';
import './Student.css';
import {StudentList} from "./components/StudentList";
import {useNavigate, useParams} from "react-router-dom";

import {
    LoggedInComponent,
    LoggedInComponentWrapper, LoggedInContextProps,
    WrappedLoggedInContextProps
} from "../components/LoggedInPage";
import SpinnerCard from "../components/SpinnerCard";
import {Box, Button, Tooltip} from "@mui/material";
import {ProgramSelect} from "../program/component/ProgramSelect";
import {ForwardToInbox, GroupAdd} from "@mui/icons-material";
import {DownloadsControl} from "../components/DownloadsControl";
import {api, API, apiError, getApiUrl} from "../lib/Api";
import useListProgramService from "../program/lib/ProgramService";
import {Program, ProgramId} from "../program/model/Program";
import {userInformationAtom} from "../Atoms";
import {useAtomValue} from "jotai";
import {messageModal, questionModal} from "../components/modal/InfoModal";
import {useQueryClient} from "@tanstack/react-query";
import {useAppContext} from "../lib/context";
import {UserInformation} from "../login/model/UserInformation";
import DialogActions from "@mui/material/DialogActions";
import {listStudentsKey} from "./lib/StudentService";
import {clientAtom} from "../Atoms";
import {DialogActionProps, DialogType, ModalAction} from "../components/modal/ModalProps";
import {Client} from "../client/model/Client";

export type StudentPageParams = {
    programId?: string
}

const StudentPageWrapper: LoggedInComponentWrapper = (context: WrappedLoggedInContextProps) => {
    const params = useParams<StudentPageParams>()
    const programService = useListProgramService()
    if (context.state === "ready" && programService.isSuccess && programService.data[0]) {

        console.log("Program ID at StudentsPageWrapper Component is", params.programId)
        const newContext: LoggedInContextProps & StudentsProps = {
            ...context,
            programs: programService.data,
            programId: params.programId ? parseInt(params.programId) : programService.data[0].id || 0
        }
        return <Students {...newContext}/>
    }
    else {
        return <SpinnerCard message="Loading..."/>
    }
}

export interface StudentsProps {
    programs: Program[]
    programId: ProgramId
}

const Students: LoggedInComponent<StudentsProps> = (props) => {
    const {setModal} = useAppContext()

    const client = useAtomValue(clientAtom) as Client
    const userInformation: UserInformation = useAtomValue(userInformationAtom)

    console.log("Program ID at Students Component is", props.programId)

    const [loading, setLoading] = useState(false)

    const navigate = useNavigate()
    const queryClient = useQueryClient()

    const updateProgramId = (programId: ProgramId) => {
        navigate("/student/" + programId)
    }

    const resendInvitesConfirm = (programId: ProgramId) => {
        api<UserInformation[], undefined>(API.listNonEnrolled(programId), userInformation, client.id).then(e => {
            if (e.data.length === 0) {
                setModal && setModal(messageModal("Resend Invites", "There are no unenrolled students to send invites to"))
            }
            else {
                setModal && setModal(questionModal("", "Resend Invites", "Are you sure you wish to resend invites to " + e.data.length + " students? (" + e.data.map(x => x.email).join(", ") + ")",
                    DialogType.Confirm, (action: ModalAction, e: string) => {
                        if (action === ModalAction.Confirm) {
                            setLoading(true)
                            api(API.resendNonEnrolledInvites(programId), userInformation, client.id).then(e => {
                                setModal && setModal(messageModal("Invites Sent", "Invites sent"))
                                queryClient.invalidateQueries(listStudentsKey(client.id))
                                setLoading(false)
                            }).catch(err => apiError(err, setModal))
                        }
                    },
                    (props: DialogActionProps) => (
                        <DialogActions>
                            <Button autoFocus aria-label="Cancel Operation" onClick={(e: MouseEvent<HTMLButtonElement>) => props.handleClose(e, ModalAction.Cancel)}
                                    color="primary">
                                Cancel
                            </Button>
                            <Tooltip title="This will send email to all students listed">
                                <Button aria-label="Resend emails" onClick={(e: MouseEvent<HTMLButtonElement>) => props.handleClose(e, ModalAction.Confirm)} color="primary"
                                        autoFocus>
                                    Resend Emails
                                </Button>
                            </Tooltip>
                        </DialogActions>
                    )
                ))
            }
        })
    }

    return loading ? <SpinnerCard message="Loading..."/> : <>
        <Box display="flex" p={1} className="subHeader" alignItems="center">
            <Box flexGrow={1} style={{paddingLeft: 20, maxWidth: "calc(100% - 550px)"}}>
                <ProgramSelect programs={props.programs} setProgramId={updateProgramId} programId={props.programId || 0}/>
            </Box>
            <Box>
                <Tooltip title="Resend Invite Email">
                    <Button  color="primary" startIcon={<ForwardToInbox/>} onClick={e => resendInvitesConfirm(props.programId)}>
                        Resend Invite to Non-enrolled Students
                    </Button>
                </Tooltip>
            </Box>
            <Box p={1}>
                <Tooltip title="Add Students">
                    <Button href={`/students/bulk/${props.programId}`} color="primary" startIcon={<GroupAdd/>}>
                        Add Students
                    </Button>
                </Tooltip>
            </Box>
            {
                <Box p={1}>
                    <DownloadsControl
                        downloadUrl={getApiUrl() + API.listStudentSites(client.id, (props.programId && props.programId > 0) ? props.programId : undefined).location}/>
                </Box>
            }
        </Box>
        <StudentList programs={props.programs} programId={props.programId} userInformation={props.userInformation} client={props.client} state={props.state}/>
    </>
}

export default StudentPageWrapper
