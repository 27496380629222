import React from 'react';
import '../student/Student.css';
import ProgramForm from "./component/ProgramForm";
import {useParams} from "react-router-dom";

import {useAppContext} from "../lib/context"
import {LoginStateCard} from "../components/LoginStateCard";
import {Page} from "../components/Page";
import {useAtomValue} from "jotai";
import {clientAtom, loginStateAtom} from "../Atoms";

type ProgramParams = {
    id: string
}

const Programs = () => {
    const params = useParams<ProgramParams>()
    const { isDebug} = useAppContext();

    const loginState = useAtomValue(loginStateAtom)
    const client = useAtomValue(clientAtom)

    const { useLoginStateCard, component } = LoginStateCard(loginState, client)

    isDebug && console.log("LSC", useLoginStateCard)

    return useLoginStateCard ? component: (
        <Page navName="Program">
                <div className="d-flex justify-content-center h-100">
                    <ProgramForm id={params.id}  />
                </div>
        </Page>
    )
}

export default Programs
