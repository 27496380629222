import {StudentUserInformation} from "../../login/model/UserInformation";
import {Client, PacketSlotDefinition} from "../../client/model/Client";
import {PacketRef} from "../../application/model/StudentMaterials";
import React from "react";
import {Box, IconButton, Tooltip} from "@mui/material";
import {API, getApiUrl} from "../../lib/Api";
import GetAppIcon from "@mui/icons-material/GetApp";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {useStyles} from "./StudentApplicationList";
import {InternshipSiteId, InternshipSiteTrackId} from "../../internship-site/model/Organization";

export const PacketElementChip = (siteId: InternshipSiteId, trackId: InternshipSiteTrackId | undefined, client: Client, user: StudentUserInformation, slot: PacketSlotDefinition) => {

    const packetRefs: PacketRef[] = user.student.studentApplicationPackets.applications
        .filter(x => x.siteId === siteId && x.trackId === trackId)
        .flatMap(x => x.packetRefs)

    console.log("Packet refs", packetRefs)

    const fulfilled: PacketRef | undefined = packetRefs.find(x => x.packetSlotId === slot.id)

    console.log("Slot and packet", slot, fulfilled)

    const {classes} = useStyles()

    if (slot)
        return <Box p={1} className={(!!fulfilled ? "go" : "stop") + " " + classes.materialChip} alignItems="center">
            <Box display="flex" alignItems="center">
                <Box>{slot.name}</Box>
                <Box>
                    {fulfilled?.documentId &&
                        <Tooltip title="Download PDF">
                            <IconButton href={getApiUrl() + API.downloadMaterial(user.guid, fulfilled.documentId).location}
                                        target="_blank" download>
                                <GetAppIcon/>
                            </IconButton>
                        </Tooltip>
                    }
                </Box>
                <Box>
                    {fulfilled?.documentId &&
                        <Tooltip title="View PDF">
                            <IconButton
                                href={getApiUrl() + API.downloadMaterial(user.guid, fulfilled.documentId, "inline").location}
                                target="_blank">
                                <PictureAsPdfIcon/>
                            </IconButton>
                        </Tooltip>
                    }
                </Box>
            </Box>
        </Box>
    else <></>
}