import React from 'react';
import './Student.css';
import StudentForm from "./components/StudentForm";
import {useParams} from "react-router-dom";

import {useAppContext} from "../lib/context"
import {Page} from "../components/Page";
import {LoginStateCard} from "../components/LoginStateCard";
import {useAtomValue} from "jotai";
import {clientAtom, loginStateAtom} from "../Atoms";

type StudentsParams = {
    id: string
    programId?: string
}

const Students = () => {
    const params = useParams<StudentsParams>()
    const { isDebug} = useAppContext();
    const loginState = useAtomValue(loginStateAtom)
    const client = useAtomValue(clientAtom)

    const { useLoginStateCard, component } = LoginStateCard(loginState, client)

    isDebug && console.log("LSC", useLoginStateCard)

    return useLoginStateCard ? component: (
        <Page navName="Students" footerPosition="fixed">
            <div className="d-flex justify-content-center">
                <StudentForm id={params.id} programId={params.programId}/>
            </div>
        </Page>
    )
}

export default Students
