import {ClientId} from "../../client/model/Client";
import {QuestionAnswer, QuestionnaireSubmissionId} from "./QuestionnaireSubmissionElement";
import z from "zod";

export type QuestionnaireId = number
export type QuestionId = number
export type QuestionTypeId = number
export type QuestionnaireElementId = number
export type QuestionnaireElementTypeId = number

export const QuestionnaireSubmissionActionTypes = {
    none: 0,
    submitted: 1,
    rejected: 2,
    approved: 3
}

export interface QuestionnaireSubmissionAction {
    actionType: number
    comments: string | undefined
    actionTimestamp: Date
}

export interface QuestionnaireSubmissionRow {
    id: QuestionnaireSubmissionId
    clientId: ClientId
    questionnaireId: QuestionnaireId
    createdTimestamp: Date
    questionnaireComplete: boolean
    questionnaireSubmitted: boolean
}

export interface QuestionnaireElementType {
    id: QuestionnaireElementTypeId
    type: string
    description: string
}

export const QuestionnaireElementTypes: {[p: string]: QuestionnaireElementType} = {
    Question: { id: 1, type: "Question", description: "Question" },
    ShortSectionHeading: { id: 2, type: "Short Section Heading", description: "Short Heading" },
    LongSectionHeading: { id: 3, type: "Long Section Heading", description: "Long Heading"},
    HTMLFragment: { id: 4, type: "HTML Content", description: "a fragment of HTML"},
    Subheading: { id: 5, type: "Subheading", description: "A subheading"},
    Linebreak: {id: 6, type: "Linebreak", description: "Linebreak for the outputting"},
    TableHeading: {id: 7, type: "Table Heading", description: "A table of questions"}
} as const

export interface QuestionType {
    id: QuestionTypeId
    questionType: string
    questionTypeDescription: string
}

export const QuestionTypes = {
    ShortText: { id: 1, questionType: "Short Text", questionTypeDescription: "Short Test" },
    Paragraph: { id: 2, questionType: "Paragraph", questionTypeDescription: "A Paragraph" },
    MultiSelect: { id: 3, questionType: "Multi-Select", questionTypeDescription: "A select with multiple options" },
    Select: { id: 4, questionType: "Select", questionTypeDescription: "A select" },
    Date: { id: 5, questionType: "Date", questionTypeDescription: "date" },
    Link: { id: 6, questionType: "Link", questionTypeDescription: "A Link" },
    Document: { id: 7, questionType: "Document", questionTypeDescription: "An uploaded document" }
}

export interface Question {
    id: number
    clientId: ClientId
    questionnaireId: QuestionnaireId
    label: string
    helpText: string
    defaultAnswer: string | undefined
    options: string[]
    type: QuestionType
    isRequired: boolean
    isSearchable: boolean
    isExportable: boolean
}

export const defaultQuestion = (clientId: ClientId, questionnaireId: QuestionnaireId) => {
    return {
        id: 0,
        clientId: clientId,
        questionnaireId: questionnaireId,
        label: "New Question",
        helpText: "Fill in your question",
        defaultAnswer: undefined,
        options: [],
        type: {
            id: 1,
            questionType: "Short Text",
            questionTypeDescription: "Short Text Description"
        },
        isRequired: false,
        isSearchable: false,
        isExportable: false
    } as Question;
}

export const questionnaireElementTableParametersValidator = z.object({
    columnCount: z.number().int().gte(1),
    headings: z.string().trim().min(1).array()
})

export type QuestionnaireElementTableParameters = z.infer<typeof questionnaireElementTableParametersValidator>

export type QuestionnaireElementExtendedParameters = {
    extended: "yes"
    editable: boolean
}

export interface QuestionnaireElement {
    id: QuestionnaireElementId
    questionnaireId: QuestionnaireId
    position: number
    elementType: QuestionnaireElementType,
    content: string | null
    question?: Question | undefined
    answer?: QuestionAnswer
    studentVisible: boolean
    isNew?: boolean
    parameters?: string
    //parsedParameters?: QuestionnaireElementTableParameters | QuestionnaireElementExtendedParameters
    parsedParameters?: Record<string, any>
}

export interface QuestionnaireTableElement extends QuestionnaireElement {
    id: QuestionnaireElementId
    questionnaireId: QuestionnaireId
    position: number
    elementType: QuestionnaireElementType,
    content: string | null
    question?: Question | undefined
    answer?: QuestionAnswer
    studentVisible: boolean
    isNew?: boolean
    parameters?: string
    parsedParameters: QuestionnaireElementTableParameters
    tableQuestions: QuestionnaireElement[]
}

export interface QuestionnaireElementQuestion {
    id: QuestionnaireElementId
    questionnaireId: QuestionnaireId
    position: number
    elementType: QuestionnaireElementType,
    content: string
    question: Question
    answer?: QuestionAnswer
    studentVisible: boolean
}

export const defaultQuestionnaireElement = (clientId: ClientId, questionnaireId: QuestionnaireId) => {
    return {
        id: 0,
        questionnaireId: questionnaireId,
        position: 1,
        elementType: {
            id: 1,
            type: "Question",
            description: "Question Type"
        },
        studentVisible: true,
        question: defaultQuestion(clientId, questionnaireId)
    } as QuestionnaireElement;
}

export interface QuestionProps {
    questionnaireElement: QuestionnaireElement
    key?: string | number | undefined
    onChange?: (value: string) => void
    requiredBlocking?: boolean
}


export class QuestionnaireElementOps {
    static makeQuestionHTMLId = (qe: QuestionnaireElement) => {
        return "question_" + (qe.question?.label || "").replace(/ /g, '_').toLowerCase();
    }

    static makeQuestionAnswerHTMLId = (qe: QuestionnaireElement) => {
        return "answer_" + (qe.question?.label || "").replace(/ /g, '_').toLowerCase();
    }
}