import Autocomplete, {createFilterOptions} from "@mui/material/Autocomplete";
import {Button, TextField} from "@mui/material";
import {green} from "@mui/material/colors";
import React, {useState} from "react";
import {useAppContext} from "../../lib/context";
import {EligibleStudent} from "../lib/EligibleStudentService";
import {useModalService} from "../../components/modal/ModalService";

export const StudentAutoCompleteSelect: React.FC<{
    studentsOptions: EligibleStudent[],
    addStudent: (student: EligibleStudent) => void
}> = ({studentsOptions, addStudent}) => {
    const {isDebug} = useAppContext()

    const [student, setStudent] = useState<EligibleStudent | null>()
    const [autoCompleteIncrement, setAutoCompleteIncrement] = useState(new Date())

    const modals = useModalService()

    const onAdd = () => {
        console.log("Adding", student)
        if (student) {
            addStudent(student)
            setStudent(null)
            setAutoCompleteIncrement(new Date())
        }
        else {
            modals.errorMessage("Error", "Please select a student to add")
        }
    }

    const filterOptionsFunction = createFilterOptions<EligibleStudent>({
        matchFrom: 'any',
        stringify: (option: any) => option.student.firstName + " " + option.student.lastName + " - " + option.student.email
    });

    return <>
        <Autocomplete
            id="students-combo-box"
            key={autoCompleteIncrement.toString()}
            options={studentsOptions}
            filterOptions={(x: any, state: any) => state.inputValue.length > 1 ? filterOptionsFunction(x, state) : []}
            getOptionLabel={(option: any) => option.student.firstName + " " + option.student.lastName + " - " + option.student.email}
            style={{width: 600}}
            renderInput={(params: any) => <TextField {...params} label="Students" variant="outlined"/>}
            onChange={(e: any, v: any) => {
                isDebug && console.log("StudentAutoCompleteSelect", v)
                setStudent(v)
            }}
        />
        <Button onClick={e => onAdd()} variant="outlined"
                style={student ? {backgroundColor: green[300], marginLeft: 5} : {marginLeft: 5}}>
            Add Student
        </Button>
    </>
}