import {QuestionTypes} from "../../../model/Questionnaire";
import {TextInputSearch} from "../component/TextInputSearch";
import {SelectSearch} from "../component/SelectSearch";
import {MultiSelectSearch} from "../component/MultiSelectSearch";
import React from "react";
import {SearchKey} from "../SearchComponent";

export interface KeyValueProps {
    searchKey: SearchKey | undefined
}

export const KeyValue = (props: KeyValueProps) => {

    switch (props.searchKey?.typeId) {
        case QuestionTypes.ShortText.id:
            return (<TextInputSearch searchKey={props.searchKey} setSearchInputData={() => {}}/>)
        case QuestionTypes.Select.id:
            return (<SelectSearch searchKey={props.searchKey} setSearchInputData={() => {}}/>)
        case QuestionTypes.MultiSelect.id:
            return (<MultiSelectSearch searchKey={props.searchKey} setSearchInputData={() => {}}/>)
        default: // should only trigger on undefined - TODO empty input box maybe?
            return (<React.Fragment/>)
    }
}