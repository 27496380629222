import React, {useEffect, useState} from "react";
import {FormControl, InputLabel, Select, Tooltip} from "@mui/material";
import Input from "@mui/material/Input";
import {useTheme} from '@mui/material/styles';
import {QuestionnaireInputProps} from "../QuestionnaireEdit";
import {answerFor, useStyles} from "../QuestionnaireList";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {multiSelectItem} from "../../components/MultiSelectItem";
import {ellipsized} from "../../lib/util";
import {isNonEmpty, isPresent} from "../../components/customTable/util";

const QuestionnaireMultiSelect = (props: QuestionnaireInputProps) => {
    const [multiSelect, setMultiSelect] = useState<string[]>((answerFor(props) || "").split(",").map(x => x.trim()).filter(x => isNonEmpty(x)))

    useEffect(() => {
        const answer = answerFor(props)
        answer && setMultiSelect(answer.split(",").map(x => x.trim()))
    }, [props])

    function getStyles(name: string, multiSelect: string[], theme: any) {
        return {
            fontWeight:
                multiSelect.indexOf(name) === -1
                    ? theme.typography.fontWeightRegular
                    : theme.typography.fontWeightMedium,
        };
    }

    const handleChange = (value: string[]) => {
        setMultiSelect(value)
        props.onChange && props.onChange(value.join(","))
    }

    const {classes} = useStyles();
    const theme = useTheme();

    return (
        <FormControl className={classes.formControl} variant="filled">
            {props.question.isRequired && !isPresent(props.answer, "answer") &&
            <Tooltip title="This is a required question that has no answer">
                <ErrorOutlineIcon className="stop" style={{position: "absolute", left: "-30px", top: "14px"}}/>
            </Tooltip>
            }
            <InputLabel id={`input-${props.question.id}-label`}>{props.question.label}</InputLabel>
            <Select
                labelId={`input-${props.question.id}-label`}
                id={`input-${props.question.id}`}
                aria-label={props.question.helpText}
                name={`question-${props.question.id}`}
                multiple
                value={multiSelect}
                readOnly={!props.editable}
                required={props.requiredBlocking && props.question.isRequired}
                variant="filled"
                defaultValue={[props.answer?.answer]}
                onChange={e => {
                    console.log(e)
                    handleChange(e.target.value as string[])
                }}
                input={<Input style={{overflowX: "hidden"}}/>}
                renderValue={(selected) => ellipsized((selected as string[]).join(', '), 80)}
            >
                {props.question.options.map((name) =>
                    multiSelectItem({key:name,
                        value:name,
                        name:name,
                        style:getStyles(name, multiSelect, theme),
                        checked:(multiSelect.indexOf(name) > -1)})
                )}
            </Select>
            <small id={`question-${props.question.id}-help`} className="form-text text-muted">{props.question.isRequired &&
            <span>This is a required field.</span>} {props.question.helpText}</small>
        </FormControl>
    )
}

export default QuestionnaireMultiSelect