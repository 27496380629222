import {ClientId} from "../../client/model/Client";

export const ProductTypes = {
    studentProduct: "StudentProduct",
    clientProduct: "ClientProduct",
    organizationProduct: "OrganizationProduct",
    schoolProduct: "SchoolProduct",
    programProduct: "ProgramProduct"
}

export const productTypes = [ProductTypes.studentProduct, ProductTypes.clientProduct, ProductTypes.programProduct, ProductTypes.organizationProduct, ProductTypes.schoolProduct] as const;
export type ProductType = typeof productTypes[number];

export type ProductId = string
export type OfferId = string

export interface Product {
    id: ProductId
    name: string
    description: string
    productType: ProductType
}

export interface Offer {
    id: OfferId
    product: Product
    clientId: ClientId
    name: string
    price: number
    fixedQuantity?: number
    validFrom: Date
    validTo: Date
    createdTimestamp: Date
    modifiedTimestamp: Date
    validForActorId: string
}