import {StudentLoggedInComponent} from "../../../components/LoggedInPage";
import {Box, Checkbox, FormControlLabel, FormGroup, Hidden, Link, Typography} from "@mui/material";
import React, {useState} from "react";
import {StudentOps} from "../../../student/model/Student";
import {FullWidthFormControl} from "../../../components/form/FullWidthFormControl";
import {PacketSlotEntry} from "./PacketSlotEntry";
import {ClientSettingsOps, SlotGroups} from "../../../client/model/Client";
import {PacketRef, StudentApplication} from "../../model/StudentMaterials";
import {useTranslation} from "react-i18next";
import {PacketState} from "../Packet";

export interface LettersOfReferenceSelectionProps {
    application: StudentApplication,
    updatePacket: (f: (packetState: PacketState) => PacketState) => void
}

export const ElectiveDocumentSelection: StudentLoggedInComponent<LettersOfReferenceSelectionProps> = (props) => {

    const clientSettings = props.client.settings

    const {t} = useTranslation()

    const packetSlots = ClientSettingsOps.packetSlotsByType(clientSettings, SlotGroups.electiveDocument)
    const electiveDocuments = StudentOps.electiveDocuments(props.userInformation)
    const slotEntries = StudentOps.packetSlotEntriesByType(clientSettings, props.application?.packetRefs || [])(SlotGroups.electiveDocument)

    console.log("Elective Doc selection", packetSlots)

    const [satisfied, setSatisfied] = useState<boolean>()

    const [checkedState, setCheckedState] = React.useState<Record<string, boolean>>(
        Object.fromEntries(
            new Map(
                slotEntries.map(x => [x.documentId, true])
            )
        )
    )

    const makePacketRef = (id: string): PacketRef => {
        const doc= electiveDocuments.find(e => e.document.id === id)
        if (doc) {
            return {
                id: undefined,
                packetSlotId: doc.slotId,
                documentId: doc.document.id,
                createdTimestamp: new Date(),
                storageRef: doc.document.storageRef,
                siteId: props.application.siteId,
                trackId: props.application.trackId,
                submitted: false
            }
        }
        else {
            throw new Error("Can't find document ID " + id)
        }
    }

    const handleCheckedState = (id: string) => {
        setCheckedState(state => {
            const j: boolean = state[id] || false
            const newState = {...state, [id]: !j}

            if (Object.keys(newState).filter(x => newState[x]).length === packetSlots.length) {
                setSatisfied(true)
            }
            else {
                setSatisfied(false)
            }

            props.updatePacket(packetState => ({...packetState, application: {...packetState.application, packetRefs: [
                ...(packetState.application.packetRefs.filter(e => !(packetSlots.map(x => x.id).includes(e.packetSlotId)))),
                ...(Object.keys(newState).filter(x => newState[x]).map(e => makePacketRef(e)))
            ]}}))

            return newState
        })
    }

    return <PacketSlotEntry>
        <Typography variant="h5">
            {t("packet.selectElectiveDocuments", {count: packetSlots.length})}
        </Typography>
        <FullWidthFormControl>
            {electiveDocuments.length ===0 &&
                <Typography variant="inherit">
                    Add some documents on your <Link href="/studentApplication">Materials Page</Link>
                </Typography>
            }
            <FormGroup>
                {electiveDocuments && electiveDocuments.map(e =>
                    <Box m={1}>
                <Hidden mdDown>
                    <FormControlLabel sx={{overflowX:"hidden", textOverflow:"ellipsis", whiteSpace:"normal"}}
                        control={<Checkbox name={e.document.id} onChange={e => handleCheckedState(e.target.name)}
                                           disabled={satisfied && !checkedState[e.document.id]}
                                           defaultChecked={checkedState[e.document.id]}
                                           checked={checkedState[e.document.id] || false}/>}
                        label={`${e.document.originFilename}`}/>
                </Hidden>
                        <Hidden mdUp>
                            <FormControlLabel sx={{overflowX:"hidden", textOverflow:"ellipsis", whiteSpace:"normal", width:300}}
                                              control={<Checkbox name={e.document.id} onChange={e => handleCheckedState(e.target.name)}
                                                                 disabled={satisfied && !checkedState[e.document.id]}
                                                                 defaultChecked={checkedState[e.document.id]}
                                                                 checked={checkedState[e.document.id] || false}/>}
                                              label={`${e.document.originFilename}`}/>
                        </Hidden>
                    </Box>
                )}
            </FormGroup>
        </FullWidthFormControl>
    </PacketSlotEntry>

}