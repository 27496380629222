import React, {useMemo, useState} from "react";
import {InternshipSite, InternshipSiteId, Organization, OrganizationViewName} from "../model/Organization";
import {Link, useNavigate} from "react-router-dom";

import SpinnerCard, {Spinner} from "../../components/SpinnerCard";
import {TableColumn} from "../../components/customTable/CustomTable";
import {InternshipSiteUserGrid} from "./InternshipSiteListRow";
import useListQuestionnaireService from "../../lib/ListQuestionnaireService";
import {Questionnaire} from "../../model/Questionnaire";
import {Box, Button, Tooltip} from "@mui/material";
import {requireGroup, requireOneOfGroup, sum} from "../../lib/util";
import Groups from "../../model/Groups";
import {useAppContext} from "../../lib/context";
import {InternshipSiteListView} from "./InternshipSiteListView";
import {API, api, apiError, getApiUrl} from "../../lib/Api";
import {messageModal, questionModal} from "../../components/modal/InfoModal";
import internshipSiteFromOrg from "../lib/InternshipSiteFromOrganization";
import {RichElement} from "../../components/customTable/RichElement";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import PlayCircleFilledIcon from "@mui/icons-material/PlayCircleFilled";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {DownloadsControl} from "../../components/DownloadsControl";
import {PageBody, PageHeader} from "../../components/Page";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import FilterListIcon from "@mui/icons-material/FilterList";
import {mapViewName, SiteListFilterMenu} from "./SiteListFilterMenu";
import CheckIcon from "@mui/icons-material/Check";
import {DollarDecimal} from "../../product/lib/DollarDecimal";
import MaterialTable, {Action, MTableBodyRow} from "@material-table/core";
import {TrackListTable} from "./TrackListTable";
import {tracksFromInternshipSite} from "../lib/TracksForOrganization";
import {useAtomValue} from "jotai";
import {queryClient, userInformationAtom} from "../../Atoms";
import {useQuery} from "@tanstack/react-query";
import {clientAtom} from "../../Atoms";
import {DialogType} from "../../components/modal/ModalProps";
import {UserInformation} from "../../login/model/UserInformation";
import {invalidateOrganizationList} from "../lib/OrganizationService";
import {Client} from "../../client/model/Client";

const InternshipSiteList: React.FC = () => {
    const {setModal, isDebug} = useAppContext()

    const userInformation: UserInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client

    const userGroups = userInformation?.userGroups
    const localClientId = client?.id || 0

    const [filterMenuAnchor, setFilterAnchor] = useState<HTMLElement | null>(null)
    const [orgView, setOrgView] = useState<string>("Current Cycle")
    const [viewName, setViewName] = useState<OrganizationViewName>(mapViewName(orgView))

    const [siteUnderChange, setSiteUnderChange] = useState<InternshipSiteId[]>([])

    const navigate = useNavigate()

    const internshipSiteColumns: TableColumn<InternshipSite>[] = useMemo(() => {
        if (client?.id && client?.id > 0) {
            isDebug && console.log("Client is", client?.id === 1)
            return ([
                {
                    title: "ID",
                    field: "continuousId",
                    width: 20,
                    render: (row => (
                        <div style={{paddingRight: 40}}>{row.continuousId}</div>
                    )),
                    editable: "never"
                },
                {
                    title: "Profile",
                    field: "questionnaireComplete",
                    width: 20,
                    comparator: (a, b) => a.questionnaireComplete > b.questionnaireComplete,
                    render: (row => row.questionnaireComplete ?
                        <Tooltip title="Questionnaire Complete">
                            <CheckIcon className="go"/>
                        </Tooltip> :
                        <Tooltip title="Questionnaire Incomplete">
                            <ErrorOutlineIcon className="stop"/>
                        </Tooltip>)
                },
                {
                    title: "Payments",
                    field: "orders",
                    render: (row => row.orders ?  <Tooltip title={<>{row.orders?.filter(e => (e.payments?.length || 0) >  0).map(x =>
                        <Box>{x.orderEntries.map(x => x.offer.name).join(", ")} : <DollarDecimal value={sum(x.payments?.map(x => x.amount)) || 0}/></Box>
                    )}</>}>
                        <Box>{sum(row.orders.map(e => e.payments ? e.payments.length : 0))}</Box>
                    </Tooltip> : <Tooltip title="No Payments"><Box>0</Box></Tooltip>)
                    ,
                    width: 20,
                    comparator: (a, b) => (a.orders?.map(e => e.payments?.length || 0) || 0) > (b.orders?.map(e => e.payments?.length || 0) || 0),
                },
                {
                    title: "Name",
                    field: "name",
                    comparator: (a, b) => a.name.localeCompare(b.name),
                    render: (row => (
                        <div style={{position: "relative"}}>
                            {requireOneOfGroup(userGroups, [Groups.globalAdmin, Groups.clientAdmin]) && (viewName === "allCycles") &&
                                (row.clientCycleId === client?.settings.cycle.id ?
                                        <Tooltip title="In Current Cycle" className="go"><PlayCircleFilledIcon
                                            style={{position: "absolute", left: "-40px"}}/></Tooltip> :
                                        <Tooltip title="Not in Current Cycle"><RemoveCircleIcon className="pause" style={{
                                            position: "absolute",
                                            left: "-40px"
                                        }}/></Tooltip>
                                )
                            }
                            <RichElement value={row.name}/>
                        </div>
                    ))
                },
                {title: "Total Openings", field: "totalOpenings", width: 20, comparator: (a, b) => a.totalOpenings - b.totalOpenings},
                {title: "Total Tracks", field: "siteTracks.length", width: 20, comparator: (a, b) => a.siteTracks.length - b.siteTracks.length},
                {title: "Total Students", field: "studentsApplied.length", width: 20, comparator: (a, b) => a.studentsApplied.length - b.studentsApplied.length},
                {title: "Students Ranked", field: "rankedStudents.length", width: 20, comparator: (a, b) => a.rankedStudents.length - b.rankedStudents.length}
            ] as TableColumn<InternshipSite>[])
                .concat(client?.id !== 1 ? [] : [
                    {title: "Identity Code", field: "identityCode", width: 20, comparator: (a, b) => a.identityCode.localeCompare(b.identityCode)},
                ])
                .concat(client?.settings.showDuesPaidFlag === "yes" ? [
                    {title: "Dues Paid", field: "duesPaid", width: 20, comparator: (a, b) => (a.duesPaid ? 1 : 0) - (b.duesPaid ? 1 : 0)}
                ] : [])
                .concat(client?.settings.showAttestationFiledFlag === "yes" ? [
                    {title: "Attestation Filed", field: "attestationFiled", width: 20, comparator: (a, b) => (a.attestationFiled ? 1 : 0) - (b.attestationFiled ? 1 : 0)}
                ] : [])
                .concat([
                    {title: "Admins", field: "admins",
                        render: row => row.admins ? <InternshipSiteUserGrid users={row.admins}/> : <></>,
                        customFilterAndSearch: (term, rowData) => rowData.admins?.map(x => x.email).join(" ").includes(term) || false
                    }
                ])
        } else return []
    }, [client, userGroups, viewName, isDebug]);


    const questionnaireService = useListQuestionnaireService()
    const questionnaires: Array<Questionnaire> = questionnaireService.status === "loaded" ? questionnaireService.payload : [] as Array<Questionnaire>

    const organizationsQuery = useQuery({
        queryFn: () => api<Organization[], undefined>(API.listOrganizations(viewName), userInformation, client.id)
                .then(response => response.data),
        queryKey: ["listOrganizations", client.id, viewName],
        staleTime: 86400000
    })

    isDebug && console.log("Org Query Status", organizationsQuery.status)

    const organizations: Array<Organization> = (organizationsQuery.status === 'success' ? organizationsQuery.data: [] as Array<Organization>)
        .filter(x => {
            if (requireGroup(userGroups, Groups.siteAdmin)) {
                return userInformation?.sites?.includes(x.internshipSite?.id || 0)
            } else return true
        })

    const items = organizations.map(x => internshipSiteFromOrg(x))

    isDebug && console.log("Items", items);

    const handleDelete = (event: any, row: InternshipSite[] | InternshipSite) => {
        if (!Array.isArray(row) && row.id && row.organizationId) {
            setModal && setModal(questionModal(row, "Confirm", `Are you sure you want to archive the site ${row.name}`, DialogType.Confirm, (action: string, row: InternshipSite) => {
                if (action === "confirm") {
                    setSiteUnderChange(value => [...value, (row.id || 0)])
                    api(API.deleteSite(row.organizationId, row.id || 0), userInformation, localClientId).then(e => {
                        return invalidateOrganizationList(queryClient, client.id, viewName).then(x => {
                            setModal && setModal(messageModal("Archive Site", "Site successfully archived"))
                            setSiteUnderChange(value => [...value.filter(x => x !== row.id)])
                        })
                    }).catch(err => apiError(err, setModal))
                }
            }))
        }
    }

    const editSite = (event: any, row: InternshipSite | InternshipSite[]) => {
        !Array.isArray(row) && navigate(`/site/${row.organizationId}/${row.id}`)
    }

    const handleRestoreConfirm = (event: any, row: InternshipSite | InternshipSite[]) => {
        if (!Array.isArray(row) && row.id && row.organizationId) {
            setModal && setModal(questionModal(row, "Confirm", `Are you sure you want to restore the site ${row.name} from it's archived version`, DialogType.Confirm, (action: string, row: InternshipSite) => {
                if (action === "confirm") {
                    setSiteUnderChange(value => [...value, (row.id || 0)])
                    handleRestore(event, row)
                }
            }))
        }
    }

    const handleRestore = (event: any, row: InternshipSite | InternshipSite[]) => {
        !Array.isArray(row) && row.id && api(API.addSiteToCycle(row.organizationId), userInformation, localClientId).then(() => {
            return invalidateOrganizationList(queryClient, client.id, viewName).then(x => {
                setModal && setModal(messageModal("Restore Site", "Site successfully added to cycle"))
                setSiteUnderChange(value => [...value.filter(x => x !== row.id)])
            })
        }).catch(err => apiError(err, setModal))
    }

    const restoreArchiveDeleteActions = (row: InternshipSite): Action<InternshipSite> => {
        if (viewName === "allCycles") {
            if (row.clientCycleId !== client?.settings.cycle.id) {
                return {icon: "unarchive", tooltip: "Restore Site to Current Cycle", onClick: handleRestoreConfirm}
            } else {
                return {icon: "archive", tooltip: "Archive Site", onClick: handleDelete}
            }
        } else {
            return {icon: "archive", tooltip: "Unarchive Site", onClick: handleDelete}
        }
    }

    type TableAction = (Action<InternshipSite> | ((row: InternshipSite) => Action<InternshipSite>))
    const tableActions: TableAction[] =
        ([
            {icon: 'edit', tooltip: 'Edit Site', onClick: editSite}
        ] as TableAction[])
            .concat([(row: InternshipSite) => restoreArchiveDeleteActions(row)])
            .concat(client.settings.directory === "enabled" ? (questionnaires && questionnaires.length > 0 ? [
                {
                    icon: 'list_alt', tooltip: 'Site Directory Profile', onClick: (event, row) => {
                        if (!Array.isArray(row)) {
                            navigate(`/questionnaire/submission/${row.organizationId}/${questionnaires[0].id}/${row.id}`)
                        }
                    }
                }
            ] : []) : [])
            .concat(requireOneOfGroup(userInformation?.userGroups, [Groups.siteAdmin, Groups.globalAdmin]) ? [
                {
                    icon: 'low_priority',
                    tooltip: 'Rank Students',
                    onClick: (event, row) => !Array.isArray(row) && navigate(`/siteStudentRank/${row.organizationId}/${row.id}`)
                }
            ] : [])

    return (organizationsQuery.status !== "success") ? <SpinnerCard/> : (
        <>
            <PageHeader title="Training Site List">
                <>
                    <Box>
                        <Button color="primary" onClick={e => e.preventDefault()} startIcon={<AddCircleIcon/>}><Link to={`/site/0/0`}>Create New Site</Link></Button>
                    </Box>
                    <Box>
                        <DownloadsControl downloadUrl={getApiUrl() + API.listOrganizations("sites", "csv").location + "&clientId=" + localClientId}/>
                    </Box>
                </>
            </PageHeader>
            <PageBody>
                {(requireOneOfGroup(userGroups, [Groups.programAdmin])) ? (<div className="card-body">
                    <InternshipSiteListView organizations={organizations}/>
                </div>) : (
                    <div className="card-body">
                        <Box display="flex" p={1}>
                            {requireOneOfGroup(userGroups, [Groups.globalAdmin, Groups.clientAdmin]) &&
                                <Box><Button startIcon={<FilterListIcon/>} onClick={e => setFilterAnchor(e.currentTarget)}>{orgView}</Button>
                                    <SiteListFilterMenu anchorEl={filterMenuAnchor} id="site-list-filter-menu" closeMenuHandler={(e?: string) => {
                                        isDebug && console.log("HERE", e);
                                        setFilterAnchor(null);
                                        if (e) {
                                            setOrgView(e.trim());
                                            setViewName(mapViewName(e.trim()))
                                        }
                                    }}/>
                                </Box>
                            }
                        </Box>

                        <MaterialTable data={items} columns={internshipSiteColumns} title="Manage Sites"
                                       options={{pageSize: 20}}
                                       actions={tableActions}
                                       components={{
                                           Row: props => siteUnderChange.includes(props.data.id) ? <Spinner/> : <MTableBodyRow {...props}/>
                                       }}
                                       detailPanel={row => <TrackListTable tracks={tracksFromInternshipSite(row.rowData)} internshipSite={row.rowData} paged={false}/>}
                        />

                    </div>
                )}
            </PageBody>
        </>
    )
}

export default InternshipSiteList