import React from "react";
import {Button, Grid} from "@mui/material";
import QuizIcon from "@mui/icons-material/Quiz";
import CheckIcon from "@mui/icons-material/Check";
import {useNavigate} from "react-router-dom";
import {QuestionnaireActionComponent} from "./QuestionnaireActionComponent";
import {useModalService} from "../../../components/modal/ModalService";
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import {usePaymentCallback} from "../../../lib/PaymentCallback";

export const QuestionnaireUnsubmitted: QuestionnaireActionComponent = ({student, setInAction}) => {
    const navigate = useNavigate()

    const modal = useModalService()
    const {setCallback} = usePaymentCallback({callbackPath: "/finalizeStudentQuestionnaire"})

    const finalize = () => {
        modal.confirmChange(true, {
            onConfirm: () => {
                setCallback("/finalizeStudentQuestionnaire")
                if (!student.student.paid && !student.student.school?.schoolPaid) {
                    navigate("/order")
                } else {
                    navigate("/finalizeStudentQuestionnaire")
                    //modal.message("Questionnaire Submitted", "Your questionnaire has been submitted to your program manager for review. You will be notified when it has been reviewed.")
                }
            }
        }, "Are you sure you wish to submit finalized answers to your school administrator ?\n" +
            "Once you submit, you will not be able to change your answers, and you will be taken to the payment page.", AssignmentLateIcon, "#d00000")
    }


    return <Grid container spacing={1}>
        <Grid item xs={12} md={6}>
            <Button variant="contained" onClick={() => navigate("/studentQuestionnaire")} startIcon={<QuizIcon/>} style={{width:"100%"}}>
                Provide Answers
            </Button>
        </Grid>
        <Grid item xs={12} md={6}>
            {student.student.materials.questionnaireComplete &&
                <Button variant="contained" color="secondary" onClick={() => finalize()} startIcon={<CheckIcon/>} style={{width:"100%"}}>
                    Submit Finalized Questionnaire
                </Button>
            }
        </Grid>
    </Grid>

}