
import {Box, Button, Typography} from "@mui/material";
import React from "react";
import {QuestionnaireActionComponent} from "./QuestionnaireActionComponent";
import {useNavigate} from "react-router-dom";
import {useAtomValue} from "jotai/index";
import {clientAtom} from "../../../Atoms";
import {Client} from "../../../client/model/Client";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {useModalService} from "../../../components/modal/ModalService";
import {API, api} from "../../../lib/Api";
import {refreshUser} from "../../../login/lib/UserSessionsService";
import AssignmentLateIcon from "@mui/icons-material/AssignmentLate";
import QuizIcon from "@mui/icons-material/Quiz";
import CheckIcon from "@mui/icons-material/Check";
import Paper from "@mui/material/Paper";

export const QuestionnaireRejected: QuestionnaireActionComponent = ({student, setInAction, action}) => {

    const navigate = useNavigate()
    const client = useAtomValue(clientAtom) as Client

    const queryClient = useQueryClient()
    const modal = useModalService()

    const finalizeMutation = useMutation({
        mutationFn: (guid: string) => api(API.finalizeStudentQuestionnaire(guid), student, client.id)
            .then(x => x.data)
    })

    const finalize = () => {
        modal.confirmChange(true, {
            onConfirm: () => {
                setInAction(true)
                finalizeMutation.mutateAsync(student.guid)
                    .then(x => {
                        refreshUser(queryClient).then(() => {
                            setInAction(false)
                            //modal.message("Questionnaire Submitted", "Your questionnaire has been submitted to your program manager for review. You will be notified when it has been reviewed.")
                        })
                    })
            }
        }, "Are you sure you wish to resubmit updated answers to your school administrator?\n" +
            "Once you submit, you will not be able to change your answers.", AssignmentLateIcon, "#d00000")
    }

    return <Box mr={1}>
        {action.map(x => x.comments ?
            <>
                <Typography variant="inherit">Your questionnaire has been sent back to you with the following comments:</Typography>
                <Paper>
                    <Box p={2} m={1}>
                <Typography variant="inherit"><i><b>{x.comments}</b></i></Typography>
                    </Box>
                </Paper>
            </> :
            <Typography variant="inherit">You questionnaire has been sent back to you to ammend with no
                comments.</Typography>).getOrElse(<></>)
        }
        <Box display="flex" mt={2}>
            <Box mr={1}>
                <Button variant="contained" onClick={() => navigate("/studentQuestionnaire")} startIcon={<QuizIcon/>}>
                    Update Answers
                </Button>
            </Box>
            <Box>
                {student.student.materials.questionnaireComplete &&
                    <Button variant="contained" color="secondary"
                            onClick={() => finalize()} startIcon={<CheckIcon/>}>
                        Resubmit Finalized Questionnaire
                    </Button>
                }
            </Box>
        </Box>
    </Box>

}

