import React, {useEffect, useMemo, useState} from 'react'
import {TrackListRowData} from "../lib/TracksForOrganization";
import {dataValidate} from "../../components/customTable/CustomTable";
import MaterialTable, {MTableAction} from "@material-table/core";

import {API, api, apiError} from "../../lib/Api";
import {InternshipSite, InternshipSiteTrack} from "../model/Organization";
import {useAppContext} from "../../lib/context";
import {
    componentModal,
    errorMessageModal,
} from "../../components/modal/InfoModal";
import {Client, ClientSettings, ClientSettingsOps} from "../../client/model/Client";
import {UserInformation} from "../../login/model/UserInformation";
import {requireOneOfGroup} from "../../lib/util";
import Groups from "../../model/Groups";
import {buildTrackColumns} from "./TrackList";
import {PulsatingCircle} from "../../components/PulsatingCircle";
import {IconButton, Tooltip} from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";
import {clientAtom} from "../../Atoms";
import ContactPageIcon from '@mui/icons-material/ContactPage';
import {useNavigate} from "react-router-dom";

interface TrackListTableProps {
    internshipSite?: InternshipSite
    tracks: TrackListRowData[]
    paged?: boolean
}

export const isTrackEditable = (userInformation: UserInformation, settings: ClientSettings) => (
    ((!ClientSettingsOps.rankListLocked(settings) || ClientSettingsOps.matchPublished(settings) ) &&
      requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin, Groups.clientAdmin, Groups.siteAdmin, Groups.directoryAdmin])
    ) || requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin])
)

export const TrackListTable = ({internshipSite, tracks, paged}: TrackListTableProps) => {
    const {setModal, isDebug} = useAppContext()

    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client

    isDebug && console.log("Rendering track list table", ClientSettingsOps.matchPublished(client?.settings), tracks.length, paged)

    const navigate = useNavigate()

    const localClientId: number = client.id

    const siteId = internshipSite?.id || 0;

    const [state, setState] = useState<TrackListRowData[]>(tracks);

    const trackColumns = useMemo(() => (client?.settings && buildTrackColumns(client.settings, userInformation?.userGroups || [])) || []
        , [client, userInformation])

    useEffect(() => {
        setState(tracks)
    }, [tracks])

    const errorDisplay = (err: Map<string, string>) => (
        <React.Fragment>
            <div>Please complete form:</div>
            <div>
                {Array.from(err.entries()).map(x => x[1]).join(", ")}
            </div>
        </React.Fragment>
    )

    const locked = ClientSettingsOps.rankListLocked(client.settings)

    const saveTrackUpdate = (newData: TrackListRowData, oldData: TrackListRowData) => new Promise((resolve, reject) =>
        api(API.updateTrack(internshipSite?.organizationId || 0, siteId, oldData.track.id || 0), userInformation, localClientId, {
            ...newData.track,
             guessinternshipSiteId: siteId,
            siteTrackType: client?.settings.useTrackType === "no" ? 1 : newData.track.siteTrackType,
        } as InternshipSiteTrack).then(() => {
            setState((prevState) => prevState.map(x => x.track.id === newData.track.id ? newData: x));
            resolve(newData)
        }).catch((x) => {
            apiError(x, setModal)
            reject()
        }))

    const addTrack = (newData: TrackListRowData) => new Promise<TrackListRowData>((resolve, reject) =>
                dataValidate(trackColumns, newData)(err => {
                        setModal && setModal(componentModal("Form Error", errorDisplay(err)))
                        reject(err)
                    }, () => {
                        if (internshipSite) {
                            const apiData = {
                                ...newData.track,
                                internshipSiteId: internshipSite.id,
                                siteTrackType: client?.settings.useTrackType === "no" ? 1 : newData.track.siteTrackType,
                                id: 0,
                                clearingHouseOptIn: false,
                                programs: []
                            } as InternshipSiteTrack

                            api<InternshipSiteTrack, InternshipSiteTrack>(API.addTrack(internshipSite.organizationId, siteId), userInformation, localClientId, apiData).then((resp) => {
                                const newRow: TrackListRowData = {
                                    ...newData,
                                    internshipSiteName: internshipSite.name,
                                    admins: internshipSite.admins,
                                    track: {
                                        ...resp.data,
                                        rankedStudents: [],
                                        studentsApplied: [],
                                        programs: []
                                    }
                                }
                                setState(prevState => [...prevState, newRow])
                                resolve(newRow)
                            }).catch((x) => {
                                apiError(x, setModal)
                                reject()
                            })
                        }
                    }
                ))

    return (
        <MaterialTable title="Manage Tracks" columns={trackColumns} data={state}
                       options={{
                           paging: (paged === undefined ? true : paged),
                           pageSize: 20
                       }}
                       actions={client.settings.clientCycleSettings.applicationSuiteSettings.useApplicationSuite ? [
                           {
                               icon: () => <ContactPageIcon/>,
                               tooltip: 'View Applicants',
                               onClick: (event, rowData: TrackListRowData | TrackListRowData[]) => {
                                   if (rowData && !Array.isArray(rowData) && rowData.track.id) {
                                       navigate(`/applicants/${rowData.organizationId}/${rowData.track.internshipSiteId}/${rowData.track.id}`)
                                   }
                               }
                           }
                       ]: []}
                       components={{
                           Action: props => {
                               console.log("TrackListTable action check", props)
                               if (props.action.tooltip === 'Save') {
                                   console.log("Adjusting okay button")
                                   return (
                                       <PulsatingCircle bg="#008800" fg="#ffffff">
                                         <Tooltip title='Save'>
                                           <IconButton onClick={event => props.action.onClick(event, props.data)} size="large"><CheckIcon style={{color: "#ffffff"}}/></IconButton>
                                         </Tooltip>
                                       </PulsatingCircle>
                                   )
                               }
                               else {
                                   return <MTableAction {...props}/>
                               }
                           }
                       }}
                       editable={{
                           isEditable: x => ((internshipSite?.organizationId||0) > 0) && (isTrackEditable(userInformation, client?.settings)),
                           isDeletable: x => ((internshipSite?.organizationId||0) > 0) && (isTrackEditable(userInformation, client?.settings)),
                           onRowAdd: x => addTrack(x as TrackListRowData),
                           onRowUpdate: (newData: any, oldData: any) =>
                               new Promise((resolve, reject) => {
                                   if (oldData) {
                                       isDebug && console.log("original state", oldData)
                                       // API CALL
                                       isDebug && console.log("Making API call", newData)
                                       dataValidate(trackColumns, newData)(err => {
                                           setModal && setModal(componentModal("Form Error", errorDisplay(err)))
                                           reject(err)
                                       }, () => {
                                           saveTrackUpdate(newData, oldData).then(x => resolve(newData)).catch(err => reject())

                                           /* Removing the opt-in process from the track edit function - it's not really helping
                                           if (matchDatePassed(client?.settings) && oldData.track.clearingHouseOptIn === false) {
                                               setModal && setModal(questionModal({resolve: resolve, reject: reject}, `${client?.settings.clearingHouseLabel} Opt In`, "The match has been published. " +
                                                   `This track is not currently opted into the ${client?.settings.clearingHouseLabel} phase. If you wish to adjust track openings and participate in the ` +
                                                   `${client?.settings.clearingHouseLabel} please Opt-In now.`, DialogType.Confirm, (action, p) => {
                                                       if (action === ModalAction.Confirm) {
                                                           const updatedData = {...newData, track: {...newData.track, clearingHouseOptIn: true}}
                                                           saveTrackUpdate(updatedData, oldData).then(e => p.resolve(updatedData)).catch(err => p.reject())
                                                       }
                                                       else {
                                                           p.reject()
                                                       }
                                                   }, (props: DialogActionProps) => (
                                                       <DialogActions>
                                                           <Button onClick={(e: MouseEvent<HTMLButtonElement>) => props.handleClose(e, ModalAction.Confirm)} color="primary">
                                                               Opt-In
                                                           </Button>
                                                           <Button autoFocus aria-label="close dialog" onClick={(e: MouseEvent<HTMLButtonElement>) => props.handleClose(e, ModalAction.Cancel)} color="primary">
                                                               Cancel
                                                           </Button>
                                                       </DialogActions>
                                                   )
                                               ))
                                           }
                                           else saveTrackUpdate(newData, oldData).then(x => resolve(newData)).catch(err => reject())
                                            */
                                       })
                                   }
                               }),
                           onRowDelete: (oldData: any) =>
                               new Promise<void>((resolve, reject) => {
                                   if (locked) {
                                       setModal && setModal({
                                               ...errorMessageModal("Delete Failed", "You cannot delete a track after rank list lock date"),
                                                   onClose: (s: string) => resolve()
                                           }
                                       )
                                   }
                                   else {
                                       api(API.deleteTrack(internshipSite?.organizationId || 0, siteId, oldData.track.id), userInformation, localClientId)
                                           .then(() => {
                                               isDebug && console.log("Deleted Track!")
                                               setState((prevState) => prevState.filter(x => x.track.name !== oldData.track.name))
                                               resolve()
                                           })
                                           .catch((x) => {
                                               apiError(x, setModal)
                                               reject()
                                           })
                                   }
                               }),
                       }}

        />
    );
}