import React, {Dispatch, SetStateAction, useState} from 'react'
import {QuestionnaireElementProps} from "../../model/QuestionnaireList";
import {
    IconButton,
    List,
    ListItem,
    ListItemText,
    ListItemSecondaryAction,
    TextField
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check"
import {QuestionnaireElement} from "../../model/Questionnaire";
import {KeyboardArrowDown, KeyboardArrowUp} from "@mui/icons-material";
import {isNonEmpty} from "../../../components/customTable/util";
import {useAppContext} from "../../../lib/context";
import {errorMessageModal} from "../../../components/modal/InfoModal";

export const ElementOptions: React.FC<QuestionnaireElementProps> = (props: QuestionnaireElementProps) => {
    const {setModal} = useAppContext()

    const [options, setOptions] = useState<string[]>((props.row.question?.options || []).filter(x => isNonEmpty(x)))
    const [newOption, setNewOption] = useState<string>("")

    const handleRemove = (e: React.MouseEvent<HTMLButtonElement>, option: string) => {
        const toUpdate = options.filter(x => x!==option)
        setOptions(toUpdate)
        setOutput(props.setElement, toUpdate)
    }

    const handleAdd = (e: React.MouseEvent<HTMLButtonElement>) => {
        processAdd()
    }

    const validateOption = (options: string[], newOption: string) => {
        if (options.includes(newOption.trim())) {
            setModal && setModal(errorMessageModal("Duplicate Option", "The option '" + newOption.trim() + "' already exists on this question"))
            return false
        }
        if (newOption.includes(",")) {
            setModal && setModal(errorMessageModal("Invalid Content", "Option values may not contain a comma"))
        }
        return true
    }

    const processAdd = () => {
        if (validateOption(options, newOption)) {
            const toUpdate = newOption.trim() === "" ? [...options] : [...options, newOption]

            setOptions(toUpdate)
            setOutput(props.setElement, toUpdate)

            setNewOption("")
        }
    }

    const moveDown = (e: React.MouseEvent<HTMLButtonElement>, i: number) => {
        let toUpdate = [...options]

        if (i<(options.length - 1)) {
            const elem = options[i]
            const nextElem = options[i + 1]

            toUpdate.splice(i, 2, nextElem, elem)

            setOptions(toUpdate)
            setOutput(props.setElement, toUpdate)
        }
    }

    const moveUp = (e: React.MouseEvent<HTMLButtonElement>, i: number) => {
        var toUpdate = [...options]

        if (i > 0) {
            const elem = options[i]
            const previousElem = options[i - 1]

            toUpdate.splice(i - 1, 2, elem, previousElem)

            setOptions(toUpdate)
            setOutput(props.setElement, toUpdate)
        }
    }

    const setOutput = (f: Dispatch<SetStateAction<QuestionnaireElement>> | undefined, options: string[]) => {
        f && f(elem => { return {
            ...elem,
            question: {
                ...elem.question,
                options: options
            }
        } as QuestionnaireElement })
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        processAdd()
    }

    return props.row.question && (props.row.question.type.questionType === "Select" || props.row.question.type.questionType === "Multi-Select" )? (
        <div className="scrollY optionsEditContainer" key={props.row.id}>
            <form onSubmit={handleSubmit}>
        <List dense={true}>
            {options.map((x, i) => (
                <ListItem key={x}>
                    <ListItemSecondaryAction>
                        <IconButton
                            edge="end"
                            aria-label="Delete question option"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleRemove(e, x)}
                            size="large">
                            <DeleteIcon />
                        </IconButton>
                        <IconButton
                            edge="end"
                            aria-label="Move question option down"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => moveDown(e, i)}
                            size="large">
                            <KeyboardArrowDown/>
                        </IconButton>
                        <IconButton
                            edge="end"
                            aria-label="Move question option down"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => moveUp(e, i)}
                            size="large">
                            <KeyboardArrowUp/>
                        </IconButton>
                    </ListItemSecondaryAction>
                    <ListItemText
                        primary={x}
                    />
                </ListItem>
                ))}

                <ListItem>
                    <ListItemSecondaryAction>
                        <IconButton
                            edge="end"
                            aria-label="delete question option"
                            onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleAdd(e)}
                            size="large">
                            <CheckIcon />
                        </IconButton>
                    </ListItemSecondaryAction>
                    <TextField variant="filled" name="newOption" label="Option" aria-label="New Option Text" value={newOption} onChange={e => setNewOption(e.target.value)}/>
                </ListItem>
        </List>
            </form>
        </div>
    ) : (<span className="notApplicable">N/A</span>);
}