import React, {useEffect, useState} from "react";
import {Program, ProgramId} from "../model/Program";
import {useAppContext} from "../../lib/context";
import useListProgramService from "../lib/ProgramService";

import {ProgramSelect} from "./ProgramSelect";
import {API, api, getApiUrl} from "../../lib/Api";
import {ProgramSiteRelationship} from "../model/ProgramSiteRelationship";
import {AssociationsList} from "./AssociationsList";
import SpinnerCard from "../../components/SpinnerCard";
import {PageBlurb} from "../../components/PageBlurb";
import {Box} from "@mui/material";
import {DownloadsControl} from "../../components/DownloadsControl";
import {useNavigate} from "react-router-dom";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";
import {clientAtom} from "../../Atoms";
import {useQuery} from "@tanstack/react-query";
import {UseQueryResult} from "@tanstack/react-query/src/types";
import {Client} from "../../client/model/Client";

interface ProgramListProps {
    id: ProgramId | undefined
}

export const programRelationshipsQueryKey = (programId: ProgramId) => ["programSiteRelationships", programId]

const ProgramList: React.FC<ProgramListProps> = (props) => {
    const {isDebug} = useAppContext();

    const client = useAtomValue(clientAtom) as Client
    const userInformation = useAtomValue(userInformationAtom)

    const [programId, setProgramId] = useState<ProgramId>(props.id || 0)
    const [inProgress, setInProgress] = useState<ProgramId[]>([])

    const localClientId = client.id

    isDebug && console.log("Program List")

    const programQuery= useListProgramService()

    const navigate = useNavigate()

    const programs: Array<Program> = programQuery.isSuccess ? programQuery.data : [] as Array<Program>

    useEffect(() => {
        if (programQuery.isSuccess && programQuery.data.length > 0 && programQuery.data[0].id) {
            isDebug && console.log("Setting programId", props.id || programQuery.data[0].id)
            setProgramId(props.id || programQuery.data[0].id)
        }
    }, [isDebug, setProgramId, programQuery, props.id])

    const relationshipsQuery: UseQueryResult<ProgramSiteRelationship[]> = useQuery({
        queryFn: () =>
            api<ProgramSiteRelationship[], undefined>(API.programSiteRelationships(programId), userInformation, client.id)
                .then(resp => resp.data
                    .sort((a, b) => a.site.name.localeCompare(b.site.name) || a.siteTrack.name.localeCompare(b.siteTrack.name))
            ),
        queryKey: programRelationshipsQueryKey(programId),
        enabled: programId > 0,
    })

    const setProgram = (requestProgramId: number) => {
        if (programId !== requestProgramId) {
            navigate("/program/" + requestProgramId)
        }
    }

    // Todo: to make this logic more standarized based on how many we want to show without scroll and its diff for each table.
    return (
        <>
                <PageBlurb name="programs_page" roleSpecific={true}/>
                {!relationshipsQuery.isSuccess && programs.length === 0 ?
                    <div>
                    </div>
                    :
                    <div style={{minWidth: "10rem"}}>
                        <Box display="flex" flexWrap="wrap" p={1} m={1}>
                            <Box style={{minWidth: "10rem"}} flexGrow={1}>
                                <ProgramSelect programs={programs} setProgramId={setProgram} programId={programId}/>
                            </Box>

                            <Box p={1} m={1}>
                                <DownloadsControl downloadUrl={getApiUrl() + API.programSiteDirectoryEntries(programId, "csv").location + "&clientId="+ localClientId} />
                            </Box>
                        </Box>
                    </div>
                }

            {programQuery.isSuccess ?
                (relationshipsQuery.isSuccess && programs.length === 0 ?
                <div>
                    You appear to have no programs to administer. Contact your administrator for more information.
                </div> :
                relationshipsQuery.isSuccess ? <AssociationsList setInProgress={setInProgress} inProgress={inProgress} programId={programId} relationships={relationshipsQuery.data} setIncrement={(e) => new Date()}/>
                    : <SpinnerCard message="Loading Relationships"/>
                )
                : <SpinnerCard message="Loading Programs"/>
            }
        </>
    )
}

export default ProgramList