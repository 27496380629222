import React, {FC, useState} from 'react'
import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material";
import {productTypes} from "../../product/model/Product";
import {useStyles} from "../styles/OrderStyles";

export interface OfferTypeSelectorProps {
    onChangeHandler: (e: SelectChangeEvent<string>) => void
}

export const ProductTypeSelector: FC<OfferTypeSelectorProps> = (props) => {
    const [selectedItem, setSelectedItem] = useState<string>()

    const {classes} = useStyles()

    return (
        <FormControl className={classes.formControl}>
            <InputLabel id="productType-label">Product Type</InputLabel>
            <Select name="productType" label="Product Type"
                    value={selectedItem}
                    onChange={e => {
                        setSelectedItem(e.target.value)
                        props.onChangeHandler && props.onChangeHandler(e)
                    }
                    }>
                <MenuItem value=""><em>None</em></MenuItem>
                {productTypes.map(x => <MenuItem key={x} value={x}>{x}</MenuItem>)}
            </Select>
        </FormControl>
    )
}