import {lookup} from "../../lib/util";

export const isPresent: (data: any, key: string) => boolean = (data: any, key: string) => {
    const value = lookup(data, key)
    //console.log("Checking ", data, key, value, !!value, typeof(value))
    return isNonEmpty(value)
}

export const isEmpty = (value: any) => !isNonEmpty(value)

export const isNonEmpty = (value: any) => {
    if (typeof (value) !== "undefined") {
        switch (typeof (value)) {
            case "string":
                return value.trim().length > 0
            case "number":
                return !isNaN(value)
            case "object":
                return value != null && Object.entries(value).length > 0
            case "boolean":
                return true;
            default:
                return !!(value)
        }
    } else {
        return !!value
    }
}