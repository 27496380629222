import {UserId} from "../user/model/User";
import {StudentId} from "../student/model/Student";
import {ProgramId} from "../program/model/Program";
import {SchoolId} from "../school/model/School";
import {
    InternshipSiteId,
    InternshipSiteTrackId,
    OrganizationId, OrganizationViewName
} from "../internship-site/model/Organization";
import {appConfig} from "../conf/conf";
import {UserInformation} from "../login/model/UserInformation";
import {ClientCycleId, ClientId, clientValidator} from "../client/model/Client";
import {OfferId, ProductId} from "../product/model/Product";
import {z, AnyZodObject} from "zod";
import {QuestionnaireId} from "../questionnaire/model/Questionnaire";
import {DialogType, ModalProps} from "../components/modal/ModalProps";
import {refreshToken, userPool} from "../components/CognitoAuth";
import {queryClient, refreshUser} from "../Atoms";
import WarningAmberIcon from '@mui/icons-material/WarningAmber';


var debug = appConfig.isDebug

export type DownloadFormat = "csv" | "json"

export enum BodyMethod { post = "post", put = "put", delete = "delete", get = "get"}

interface QueryParameters {
    [key: string]: string;
}

export interface APIDefinition {
    location: string
    method: BodyMethod
    validator?: APIValidator
}

export type APIResponse<T, U>  = APISuccessResponse<T, U> | APIFailureResponse<U>

export interface APISuccessResponse<T, U> {
    status: "success"
    data: T
    url : string
    args?: U
}

export interface APIFailureResponse<U> {
    status: "failure"
    message?: string
    url: string
    args?: U
}

type APIContentType = "application/json" | "application/x-www-form-urlencoded" | "multipart/form-data"

export interface APIRequestConstruct {
    location: string
    userInformation?: UserInformation
    data?: QueryParameters
    contentType: APIContentType
    validator?: AnyZodObject
    clientId?: number
    assumedEmail?: string
}

// Use Zod for validation
export type APIValidator = AnyZodObject

export const messageResponseValidator = z.object({
    data: z.string()
}).strict()

// TODO At some point get this to return APIDefinition<T> based on the type of the validator
const Get = (location: string, validator?: APIValidator) => ({location: location, method: BodyMethod.get, validator: validator} as APIDefinition)
const Put = (location: string, validator?: APIValidator) => ({location: location, method: BodyMethod.put, validator: validator} as APIDefinition)
const Post = (location: string, validator?: APIValidator) => ({location: location, method: BodyMethod.post, validator: validator} as APIDefinition)
const Delete = (location: string, validator?: APIValidator) => ({location: location, method: BodyMethod.delete, validator: validator} as APIDefinition)

export type DownloadDisposition = "inline" | "attachment"

export class API {

    // School APIs
    static addSchool = () => Post('school/v1/school')
    static listSchool = (format?: DownloadFormat) => Get(`school/v1/listSchools?format=${format || 'json'}`)
    static getSchool = (schoolId: SchoolId) => Get(`school/v1/school/${schoolId}`)
    static updateSchool = (schoolId: SchoolId) => Put(`school/v1/school/${schoolId}`)
    static deleteSchool = (schoolId: SchoolId) => Delete(`school/v1/school/${schoolId}`)

    // Program APIs
    static addProgram = () => Post('program/v1/program')
    static listProgram = () => Get('program/v1/listPrograms')
    static getProgram = (programId: ProgramId) => Get(`program/v1/program/${programId}`)
    static updateProgram = (programId: ProgramId, schoolId: SchoolId) => Put(`program/v1/program/${programId}?schoolId=${schoolId}`)
    static deleteProgram = (programId: ProgramId) => Delete(`program/v1/program/${programId}`)
    static programSiteRelationships = (programId: ProgramId) => Get(`program/v1/program/${programId}/siteTrackRelationships`)

    static programSiteDirectoryEntries = (programId: ProgramId, format?: DownloadFormat) => Get(`program/v1/program/${programId}/siteTrackDirectoryEntries?format=${format || 'json'}`)

    static programSitelistLockDate = (programId: ProgramId) => Put(`program/v1/program/${programId}/siteListLockDate`)

    static programViewerAccount = (programId: ProgramId) => Get(`program/v1/program/${programId}/viewerAccount`)

    // Program Relationship APIs
    static requestSiteRelationship = (programId: ProgramId, siteTrackId: InternshipSiteTrackId) =>
        Post(`program/v1/program/${programId}/requestRelationship/${siteTrackId}`)
    static cancelSiteRequest = (programId: ProgramId, siteTrackId: InternshipSiteTrackId) => Post(`program/v1/program/${programId}/cancelRequest/${siteTrackId}`)
    static rejectSiteRelationship = (programId: ProgramId, siteTrackId: InternshipSiteTrackId) => Post(`program/v1/program/${programId}/rejectRelationship/${siteTrackId}`)
    static acceptSiteRelationship = (programId: ProgramId, siteTrackId: InternshipSiteTrackId) => Post(`program/v1/program/${programId}/acceptRelationship/${siteTrackId}`)
    static withdrawSiteRelationship = (programId: ProgramId, siteTrackId: InternshipSiteTrackId) => Post(`program/v1/program/${programId}/withdrawRelationship/${siteTrackId}`)

    static fetchProgramStudentApprovalQuestionnaireSubmission = (questionnaireId: QuestionnaireId, uuid: string) => Get(`program/v1/application/questionnaire/${questionnaireId}/${uuid}`)
    static fetchProgramStudentApprovalQuestionnaireSubmissionWithTrackId = (questionnaireId: QuestionnaireId, uuid: string, siteId: InternshipSiteId, trackId: InternshipSiteTrackId) => Get(`program/v1/application/questionnaire/${questionnaireId}/${uuid}/${siteId}/${trackId}`)
    static submitProgramStudentApprovalQuestionnaire = (guid: string, questionnaireId: number, isDraft: boolean) => Put(`program/v1/application/questionnaire/${questionnaireId}/${guid}`)
    static submitProgramStudentApprovalQuestionnaireWithTrackId = (guid: string, questionnaireId: number, isDraft: boolean,siteId: InternshipSiteId, trackId: InternshipSiteTrackId | undefined) => Put(`program/v1/application/questionnaire/${questionnaireId}/${guid}/${siteId}/${trackId}`)

    // Student APIs
    static addStudent = () => Post('student/v1/student')
    static resendStudentInvite = (studentId: StudentId) => Put(`student/v1/student/${studentId}/resendInvite`)
    static addStudentBulk = (programId: ProgramId) => Post(`student/v1/program/${programId}/bulkAddStudent`)
    static updateStudentInfo = (studentId: StudentId) => Put(`student/v1/student/${studentId}`)
    static studentListOrganizations = () => Get('organization/v1/student/listOrganizations')
    static getStudent = (studentId: StudentId) => Get(`student/v1/student/${studentId}`)
    static listStudent = (format?: DownloadFormat) => Get(`student/v1/listStudents?format=${format || 'json'}`)
    static listStudentSites = (clientId: ClientId, programId?: ProgramId) => Get(`student/v1/studentSites?clientId=${clientId}&programId=${programId ? programId : ""}`)
    static deleteStudent = (studentId: StudentId) => Delete(`student/v1/student/${studentId}`)
    static addSiteTrackToStudent = (studentId: StudentId, siteTrackId: InternshipSiteTrackId) => Put(`student/v1/student/${studentId}/addSiteTrack/${siteTrackId}`)
    static withdrawSiteTrackToStudent = (studentId: StudentId, siteTrackId: InternshipSiteTrackId) => Delete(`student/v1/student/${studentId}/removeSiteTrack/${siteTrackId}`)
    static withdrawSiteToStudent = (studentId: StudentId, internshipSiteId: InternshipSiteId) => Delete(`student/v1/student/${studentId}/removeSite/${internshipSiteId}`)
    static addSiteToStudent = (studentId: StudentId, siteId: InternshipSiteId) => Put(`student/v1/student/${studentId}/addSite/${siteId}`)
    static saveStudentTrackRanking = (studentId: StudentId) => Put(`student/v1/student/${studentId}/saveRanking`)
    static attestStudent = () => Put(`student/v1/updateAttestation`)
    static updateStudentApplicationLimit = (studentId: StudentId) => Put(`student/v1/student/${studentId}/updateStudentApplicationLimit`)
    static withdraw = (email: string) => Post(`student/v1/student/${email}/withdraw`)
    static listNonEnrolled = (programId: ProgramId| undefined) => Get(`student/v1/nonEnrolledList${programId ? "?programId=" + programId : ""}`)
    static resendNonEnrolledInvites = (programId: ProgramId | undefined) => Put(`student/v1/resendInvitesToAllNonEnrolled${programId ? "?programId=" + programId : ""}`)
    static validateStudentTransfer = (studentId: StudentId, programId: ProgramId) => Put(`student/v1/student/${studentId}/validateTransfer?programId=${programId}`)
    static clearStudentTrackRanks = (studentId: StudentId) => Put(`student/v1/student/${studentId}/clearSiteTracks`)
    static fetchStudentQuestionnaireSubmission = (id: number, uuid: string) => Get(`student/v1/application/questionnaire/${uuid}/${id}`)
    static saveStudentQuestionnaireSubmission = (id: number, uuid: string) => Post(`student/v1/application/questionnaire/${uuid}/${id}`)

    // Application APIs
    static fetchMaterials = (email: string) => Get(`student/v1/student/${email}/materials`)
    static uploadMaterial = (guid: string) => Post(`student/v1/application/createDocumentMaterial?guid=${guid}`)
    static uploadLetterOfReference= (guid: string) => Post(`student/v1/application/uploadLetterOfReference/${guid}`)
    static createLetterOfReference= (guid: string) => Post(`student/v1/application/letterOfReference/${guid}`)
    static saveLetterOfReference= (guid: string) => Put(`student/v1/application/letterOfReference/${guid}`)
    static cancelLetterOfReference = (guid: string, id: string) => Delete(`student/v1/application/letterOfReference/${guid}/${id}`)
    static declineLetterOfReference = (guid: string, id: string) => Put(`student/v1/application/declineLetterOfReference/${guid}/${id}`)
    static resendLetterOfReferenceEmail = (guid: string, id: string) => Put(`student/v1/application/resendLetterOfReferenceEmail/${guid}/${id}`)
    static fetchStudentLetterOfReferenceDoc = (guid: string, docUUID: string) => Get(`student/v1/application/fetchStudentLetterOfReferenceDoc/${guid}/${docUUID}`)
    static uploadPacket = (guid: string) => Post(`student/v1/application/createPacketDocument`)
    static downloadMaterial = (guid: string, id: string, disposition: DownloadDisposition = "attachment") => Get(`student/v1/application/downloadDocument/${guid}/${id}?disposition=${disposition}`)
    static removeDocument = (id: string) => Delete(`student/v1/application/document/${id}`)
    static removePacketDocument = (id: string) => Delete(`student/v1/application/packet/document/${id}`)
    static saveSitePacket = (guid: string, siteId: number) => Put(`student/v1/application/packet/${guid}/site/${siteId}`)
    static saveSiteTrackPacket = (guid: string, siteId: number, trackId: number) => Put(`student/v1/application/packet/${guid}/track/${siteId}/${trackId}`)
    static downloadPacketPDF = (guid: string, siteId: number, trackId: number | undefined, disposition: string = "inline") => {
        if (trackId) {
            return Get(`student/v1/application/packet/downloadPDF/${guid}/${siteId}/${trackId}?disposition=${disposition}`)
        } else {
            return Get(`student/v1/application/packet/downloadPDF/${guid}/${siteId}?disposition=${disposition}`)
        }
    }
    static submitSitePacket = (guid: string, siteId: number) => Post(`student/v1/${guid}/packet/site/submit/${siteId}`)
    static submitTrackPacket = (guid: string, siteId: number, trackId: number) => Post(`student/v1/${guid}/packet/track/submit/${siteId}/${trackId}`)
    static withdrawPacket = (guid: string, siteId: number) => Post(`student/v1/${guid}/packet/withdraw/${siteId}`)
    static renderPDF = (guid: string) => Get(`student/v1/application/renderQuestionnaire/${guid}`)
    static finalizeStudentQuestionnaire = (guid: string) => Post(`student/v1/application/finalizeQuestionnaire/${guid}`)
    static withdrawStudentQuestionnaire = (guid: string) => Post(`student/v1/application/withdrawQuestionnaire/${guid}`)
    static rejectStudentQuestionnaire = (guid: string) => Post(`student/v1/application/questionnaireReject/${guid}`)

    static submitStudentQuestionnaire = (guid: string, questionnaireId: number, isDraft: boolean) => Post(`student/v1/application/questionnaire/${guid}/${questionnaireId}`)
    static fetchStudentQuestionnaire = (guid: string, questionnaireId: number, isDraft: boolean) => Get(`student/v1/application/questionnaire/${guid}/${questionnaireId}`)

    static fetchSiteApplicants = (siteId: number) => Get(`organization/v1/site/${siteId}/applicants`)
    static fetchTrackApplicants = (siteId: number, trackId: number) => Get(`organization/v1/site/${siteId}/track/${trackId}/applicants`)

    static fetchAllApplicants = () => Get(`student/v1/allApplicants`)

    static proposeMatch = (guid: string) => Put(`student/v1/${guid}/proposeMatch`)
    static makeMatch = (guid: string) => Put(`student/v1/${guid}/match`)

    // User APIs
    static listUser = (format?: DownloadFormat) => Get(`user/v1/listUsers?format=${format || 'json'}`)
    static listUserByGroup = (group: string) => Get(`user/v1/group/${group}/listUsers`)
    static currentUser = () => Get('user/v1/currentUser')
    static getUser = (userId: UserId) => Get(`user/v1/user/${userId}`)
    static addUser = () => Post('user/v1/user')
    static updateUser = (userId: UserId) => Put(`user/v1/user/${userId}`)
    static updateProfile = () => Put(`user/v1/profile`)
    static deleteUser = (userId: UserId) => Delete(`user/v1/user/${userId}`)
    static resetUserPassword = (email: string) => Get(`user/v1/user/${email}/resetPassword`)
    static signInUser = (email: string, clientId?: ClientId) => Get(`user/v1/signIn/${email}`)

    static migrateUsers = () => Get(`user/v1/userList/migrateToDynamo`)

    static downloadApplicationCSV = () => Get(`admin/v1/applicationsData`)

    // Organization APIs
    static addOrganization = () => Post('organization/v1/organization')
    static updateOrganization = (organizationId: OrganizationId) => Put(`organization/v1/organization/${organizationId}`)
    static getOrganization = (organizationId: OrganizationId) => Get(`organization/v1/organization/${organizationId}`)
    static getOrganizationBySiteId = (siteId: InternshipSiteId) => Get(`organization/v1/fromSiteId/${siteId}`)
    static deleteOrganization = (organizationId: OrganizationId) => Delete(`organization/v1/organization/${organizationId}`)
    static listOrganizations = (viewName?: OrganizationViewName, format?: DownloadFormat) => Get(`organization/v1/listOrganizations?viewName=${viewName || "tracks"}&format=${format || 'json'}`)

    static listOrganizationsForProgramAdmin = () => Get('organization/v1/programAdmin/listOrganizations')

    static deleteSite = (organizationId: OrganizationId, siteId: InternshipSiteId) => Delete(`organization/v1/organization/${organizationId}/site/${siteId}`)

    static siteTrackProgramRelationships = (siteTrackId: InternshipSiteTrackId, downloadFormat?: DownloadFormat) => Get(`organization/v1/organization/siteTrack/${siteTrackId}/programRelationships?format=${downloadFormat || "json"}`)

    static addSiteToCycle = (organizationId: OrganizationId) => Put(`organization/v1/organization/${organizationId}/addSiteToCycle`)

    // Site APIs
    static siteSchoolRelationships = (siteId: InternshipSiteId) => Get(`organization/v1/organization/site/${siteId}/programRelationships`)
    static listEligibleStudentsForSite = (orgId: OrganizationId, siteId: InternshipSiteId) => Get(`organization/v1/organization/${orgId}/site/${siteId}/eligibleStudents`)
    static listEligibleStudentsForSiteTrack = (orgId: OrganizationId, siteId: InternshipSiteId, trackId: InternshipSiteTrackId) => Get(`organization/v1/organization/${orgId}/site/${siteId}/track/${trackId}/eligibleStudents`)

    // Organization Relationships
    static requestProgramRelationship = (programId: ProgramId, siteTrackId: InternshipSiteTrackId) => Post(`organization/v1/organization/siteTrack/${siteTrackId}/requestRelationship/${programId}`)
    static cancelProgramRequest = (programId: ProgramId, siteTrackId: InternshipSiteTrackId) => Post(`organization/v1/organization/siteTrack/${siteTrackId}/cancelRequest/${programId}`)
    static rejectProgramRelationship = (programId: ProgramId, siteTrackId: InternshipSiteTrackId) => Post(`organization/v1/organization/siteTrack/${siteTrackId}/rejectRelationship/${programId}`)
    static acceptProgramRelationship = (programId: ProgramId, siteTrackId: InternshipSiteTrackId) => Post(`organization/v1/organization/siteTrack/${siteTrackId}/acceptRelationship/${programId}`)
    static withdrawProgramRelationship = (programId: ProgramId, siteTrackId: InternshipSiteTrackId) => Post(`organization/v1/organization/siteTrack/${siteTrackId}/withdrawRelationship/${programId}`)

    // Site Track APIs
    static addTrack = (orgId: number, siteId: number) => Post(`organization/v1/organization/${orgId}/site/${siteId}/track`)
    static updateTrack = (orgId: number, siteId: number, siteTrackId: number) => Put(`organization/v1/organization/${orgId}/site/${siteId}/track/${siteTrackId}`)
    static deleteTrack = (orgId: number, siteId: number, siteTrackId: number) => Delete(`organization/v1/organization/${orgId}/site/${siteId}/track/${siteTrackId}`)
    static updateTrackOpenings = (orgId: number) => Put(`organization/v1/organization/${orgId}/trackOpenings`)
    static getTrack = (orgId: number, siteId: number, siteTrackId: number) => Get(`organization/v1/organization/${orgId}/site/${siteId}/track/${siteTrackId}`)

    static listSiteTrackStudentRank = (orgId: OrganizationId, siteId: InternshipSiteId, siteTrackId: InternshipSiteTrackId) => Get(`organization/v1/organization/${orgId}/site/${siteId}/track/${siteTrackId}/ranks`)
    static updateSiteTrackStudentRank = (orgId: OrganizationId, siteId: InternshipSiteId, siteTrackId: InternshipSiteTrackId) => Put(`organization/v1/organization/${orgId}/site/${siteId}/track/${siteTrackId}/ranks`)

    static updateSiteAttestation = (orgId: OrganizationId, siteId: InternshipSiteId) => Put(`organization/v1/organization/${orgId}/site/${siteId}/attestation`)

    // Client APIs
    static listClient = () => Get('client/v1/listClients') //, z.array(clientValidator))
    static getClient = (id: ClientId) => Get(`client/v1/client/${id}`)
    static currentClient = () => Get('client/v1/currentClient', clientValidator)


    // Client Settings APIs
    static updateClientSettings = (clientId: ClientId) => Put(`clientSettings/v1/clientSettings/${clientId}`)

    // Client Cycle APIs
    static listClientCycles = () => Get('clientCycle/v1/list')
    static listAllClientCycles = () => Get('clientCycle/v1/listAll')
    static updateClientCycle = (id: ClientCycleId) => Put(`clientCycle/v1/clientCycle/${id}`)
    static addClientCycle = () => Post(`clientCycle/v1/clientCycle`)
    static deleteClientCycle = (id: ClientCycleId) => Delete(`clientCycle/v1/clientCycle/${id}`)

    static migrateSites = () => Get('clientCycle/v1/migrateSites')
    static migrateStudents = () => Get('clientCycle/v1/migrateStudents')

    // Questionnaire APIs
    static fetchQuestionnaire = (id: number) => Get(`questionnaire/v1/questionnaire/${id}`)
    static fetchQuestionnaireSubmission = (id: number, siteId: number, includeDraft: boolean) => Get(`questionnaire/v1/questionnaire/${id}/latestSubmission/${siteId}?includeDraft=${includeDraft ? "yes" : "no"}`)
    static listQuestionnaires = () => Get(`questionnaire/v1/list`)
    static updateQuestionnaireElement = (questionnaireId: number, elementId: number, forceNew: boolean) => Put(`questionnaire/v1/questionnaire/${questionnaireId}/updateElement/${elementId}?forceNew=${forceNew ? "yes" : "no"}`)
    static deleteQuestionnaireElement = (questionnaireId: number, elementId: number) => Delete(`questionnaire/v1/questionnaire/${questionnaireId}/deleteElement/${elementId}`)
    static updateQuestionnaire = (questionnaireId: number) => Put(`questionnaire/v1/questionnaire/${questionnaireId}`)

    static submitQuestionnaire = (questionnaireId: number, siteId: number, isDraft: boolean) => Post(`questionnaire/v1/questionnaire/${questionnaireId}/submit/${siteId}?isDraft=${isDraft ? "yes" : "no"}`)
    static isMostRecentADraft = (questionnaireId: number, siteId: number) => Get(`questionnaire/v1/questionnaire/${questionnaireId}/draftPresent/${siteId}`)

    static searchDirectory = (questionnaireId: number, key: string[], keyQuery: string[], openingsOnly: boolean) =>
        Get(`questionnaire/v1/directorySearch?${key.map(x => "k=" + x).join("&")}&${keyQuery.map(x => "kq=" + x).join("&")}&openingsOnly=${openingsOnly ? 'true' : 'false'}`)

    static pivotResult = () => Get('questionnaire/v1/pivotResult')

    static bulkPreview = () => Post(`email/v1/bulkPreview`)
    static bulkSend = (groupName: string) => Post(`email/v1/bulkSend?group=${groupName}`)

    // Audit APIs
    static auditOperations = () => Get(`audit/v1/operationTypes`)
    static auditLog = (operationType: string | undefined = undefined, offset: number | undefined = undefined,
                              limit: number | undefined = undefined) => operationType ?
        Get(`audit/v1/auditLog?opType=${operationType || ''}&offset=${offset || ''}&limit=${limit || ''}`) :
            Get(`audit/v1/auditLog`)

    // Payment APIs
    static preparePayment = (orderId: string) => Get(`payment/v1/prepare/${orderId}`)
    static confirmPayment = (orderId: string) => Put(`payment/v1/confirm/${orderId}`)
    static manualPayment = (orderId: string) => Put(`payment/v1/manual/${orderId}`)

    // Product APIs
    static fetchOffer = (offerId: string) => Get(`product/v1/offer/${offerId}`)
    static listOffers = () => Get(`offer/v1/list`)
    static listOffersForProduct = (productId: ProductId) => Get(`offer/v1/list/${productId}`)
    static deleteOffer = (offerId: OfferId) => Delete(`offer/v1/offer/${offerId}`)
    static updateOffer = (offerId: OfferId) => Put(`offer/v1/offer/${offerId}`)
    static addOffer = () => Post(`offer/v1/offer`)
    static fetchProduct = (productId: ProductId) => Get(`product/v1/product/${productId}`)
    static listProducts = () => Get(`product/v1/list`)
    static addProduct = () => Post(`product/v1/product`)
    static updateProduct = (productId: ProductId) => Put(`product/v1/product/${productId}`)
    static deleteProduct = (productId: ProductId) => Delete(`product/v1/product/${productId}`)
    static listProductByType = (productType: string) => Get(`product/v1/list/type/${productType}`)
    static listOfferByType = (productType: string) => Get(`offer/v1/offer/type/${productType}`)
    static listEligibleOffers = () => Get(`offer/v1/eligibleOffers`)
    static listEligibleOffersForUser = (email: string) => Get(`offer/v1/eligibleOffers/${email}`)
    static listOutstandingOffers = () => Get(`offer/v1/outstanding`)
    static listOutstandingOffersForUser = (email: string) => Get(`offer/v1/outstanding/${email}`)
    static addOrder = () => Post(`order/v1/order`)
    static fetchOrder = (orderId: number) => Get(`order/v1/order/${orderId}`)
    static listOrders = () => Get(`order/v1/list`)
    static listOrdersForUser = () => Get(`order/v1/listForUser`)
    static listOrdersForUserEmail = (email: string) => Get(`order/v1/listForUser/${email}`)

    // Match APIs
    static runExports = () => Get(`export/run`)
    static initiateMatch = () => Get(`match/initiate`)
    static loadResults = () => Get(`match/load`)
    static fetchMatches = (clientCycleId: number) => Get(`match/v1/fetchMatches/${clientCycleId}`)
    static resultsCSV = () => Get(`match/v1/resultsCSV`)
    static sendROLStudentEmails = () => Get(`match/v1/sendRankOrderStudentEmails`)
    static sendROLSiteAdminEmails = () => Get(`match/v1/sendRankOrderSiteAdminEmails`)
    static sendProgramAdminMatchEmails = () => Get(`match/v1/sendProgramAdminMatchEmails`)
    static sendSiteAdminMatchEmails = () => Get(`match/v1/sendSiteAdminMatchEmails`)
    static sendStudentMatchEmails = () => Get(`match/v1/sendStudentMatchEmails`)
    static updateClearingHouse = () => Get(`match/v1/updateClearingHouse`)

    // Admin APIs
    static unsetAdmins = () => Get(`admin/v1/unsetAdmins`)
    static reconcileGroupsAndUsers = () => Get(`admin/v1/reconcileGroupsAndUsers`)
    static republishOrgs = () => Get(`admin/v1/publishOrganizations`)
    static fixStudents = () => Get(`admin/v1/fixStudents`)
}

export function jsonBodyEndpoint<U, T>(data: U, req: APIRequestConstruct, method: BodyMethod): Promise<APIResponse<T, U>> {
    debug && console.log("Making HTTP call to", req.location, "with data", data)
    return jsonEndpoint({
        url: getApiUrl() + req.location,
        userInformation: req.userInformation,
        init: {
            method: method,
            body: JSON.stringify(data),
        },
        validator: req.validator,
        assumedEmail: req.assumedEmail,
    } as APIRequest<U>, req.contentType);
}

export const getPrimaryDomain = (hostname: string) => {
    const domainParts = hostname.split(".")
    appConfig.isDebug && console.log("Domain parts", domainParts)
    if (!domainParts.includes("localhost")) {
        return domainParts[domainParts.length-2]
    }
    else {
        return "practicumfit"
    }
}

export const getApiUrl = () => (appConfig as any)[getPrimaryDomain(window.location.hostname) + "ApiUrl"]

export const api = <T, K>(apiDef: APIDefinition, userInformation: UserInformation | undefined, clientId: number, data?: K, contentType?: APIContentType | undefined): Promise<APISuccessResponse<T, K>>  => {
    const localClientId = clientId || userInformation?.clientId
    appConfig.isDebug && console.log("Fetching API with ", clientId, localClientId)

    const adds = (apiDef.location.includes("?") ? "&" : "?") + (localClientId ? "clientId=" + localClientId : "")

    // TODO would like to use pinnedApiUrl here, but it's http not https, and the browser won't allow it
    const url = (apiDef.location.indexOf("http") === -1 ?
            getApiUrl() : "")
        + encodeURI(apiDef.location) + adds

    debug && console.log("Requesting API", apiDef, url, userInformation)

    const mkInit = (contentType: APIContentType | undefined) => {
        if (contentType === "application/x-www-form-urlencoded" || contentType === "multipart/form-data") {
            return {
                method: apiDef.method,
                ...(apiDef.method !== BodyMethod.get ? {body: data} : {})
            }
        }
        else {
            return {
                method: apiDef.method,
                ...(apiDef.method !== BodyMethod.get ? {body: JSON.stringify(data)} : {})
            }
        }
    }

    return jsonEndpoint<T, K>({
            url: url,
            userInformation: userInformation,
            init: mkInit(contentType),
            validator: apiDef.validator,
            req: data
        } as APIRequest<K>, contentType || "application/json")
}

export interface APIRequest<T> {
    url: string
    userInformation?: UserInformation,
    init: {
        method: string,
        body?: string
    },
    req?: T
    assumedEmail?: string
    contentType?: string,
    headers?: Headers,
    validator?: AnyZodObject | undefined
}

export const makeUrlParams = (data: QueryParameters) => Object.keys(data).reduce((acc: string, e: string) =>
    acc + "&" + encodeURIComponent(e as string) + "=" + encodeURIComponent(data[e] as string)
    , "").substr(1);

export function jsonUrlEndpoint<T, U>(req: APIRequestConstruct): Promise<APIResponse<T, U>> {
    const adds = req.data === undefined ? "" : "?" + makeUrlParams(req.data)

    const url: string = (req.location.indexOf("http") === -1 ? getApiUrl(): "" + encodeURI(req.location) + adds)

    return jsonEndpoint({
        url: url,
        userInformation: req.userInformation,
        init: {method: "get"},
        assumedEmail: req.assumedEmail,
    }, req.contentType)

}

const apiSuccess: <T, U>(url: string, data: T) => APISuccessResponse<T, U> = <T, U>(url: string, data: T): APISuccessResponse<T, U> => ({ status: "success", data, url})

const authHeaders = (userInformation: UserInformation | undefined) => (userInformation ? {
    'X-Auth-JWT': userInformation.jwtToken || "",
    'X-matchfit-email': userInformation.email || "",
    'X-matchfit-groups': userInformation.userGroups ? userInformation.userGroups.join(",") : "",
    ...(userInformation.clientId ? {'X-matchfit-clientId': userInformation.clientId?.toString()} : {})
} :{})


function jsonEndpoint<A, K>(req: APIRequest<K>, contentType: string = "application/json", retry: number = 0): Promise<APISuccessResponse<A & {clientId?: number}, K>> {
    type T = A & {clientId?: number}
    //debug && console.log("jsonEndpoint", req)
    try {
        debug && console.log("Fetching from ", req.url);

        const assumedEmail = req.userInformation?.assumedEmail || localStorage.getItem("assumedUserEmail")

        return fetch(req.url, {
            ...req.init,
            headers: {
                ...authHeaders(req.userInformation),
                ...(contentType === "application/json" ? {'Content-Type': contentType} : {}),
                ...(assumedEmail ? {'X-matchfit-assumed-user-email': assumedEmail} : {}),
            },
            credentials: 'include'
        }).then(resp => {
            debug && console.log(resp)
            /*
            Sentry.setContext("API Call", {
                req: JSON.stringify(req),
                resp: JSON.stringify(resp)
            })

             */
            if (resp.ok) {
                //debug && console.log("Headers", Array.from(resp.headers.entries()))
                return resp.json().then((x: any) => {
                    //appConfig.isDebug && console.log("API Success", x);
                    const result: unknown = apiResultParse(x)
                    if ((result as any).data) {
                        return ({
                            ...(req.validator ?
                                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                                apiSuccess<T, K>(req.url, {...req.validator.parse((result as any).data)} as T)
                                : apiSuccess<T, K>(req.url, (result as any).data as T))
                        })
                    }
                    else {
                        console.log("WARNING RAW DATA for API", req)
                        return (apiSuccess<T, K>(req.url, result as T))
                    }
                }).catch(x => apiFailed<T, K>(x))
            } else {
                switch (resp.status) {
                    case 401:
                        appConfig.isDebug && console.log("Unauthorized for this user")
                        const cognitoUser = userPool.getCurrentUser();
                        if (cognitoUser) {
                            console.log("Attempting a token refresh")
                            refreshToken().then(x => console.log("Refresh token"))
                                .then(x => refreshUser(queryClient))
                                /*
                                .then(x => {
                                    // After the token update, we'll retry here
                                    if (retry < 2) {
                                        return jsonEndpoint(req, contentType, retry + 1)
                                    }
                                })

                                 */
                        }
                        else {
                            // TODO - maybe it's time to make API a hook component
                            appConfig.isDebug && console.log("No current cognito user")
                        }
                        return apiFailed<T, K>(resp)
                    case 403:
                        appConfig.isDebug && console.log("Forbidden for this user")
                        return apiFailed<T, K>(resp)
                    case 500:
                        appConfig.isDebug && console.log("Internal Server error", resp.text())
                        return apiFailed<T, K>(resp)
                    default:
                        appConfig.isDebug && console.log("Failed call with response", resp.status)
                        return apiFailed<T, K>(resp)
                }
            }
        }).catch(err => {
            appConfig.isDebug && console.log("With request", req)
            return apiFailed<T, K>(err)
        })
    } catch (e) {
        /*
        Sentry.captureMessage("API Failed")

         */
        if (appConfig.isDebug) {
            console.log("Whilst calling", req)
            console.log("Api Failed", e)
        }
        return apiFailed<T, K>(e)
    }
}

const apiFailed = <T, U>(err: any): Promise<APISuccessResponse<T, U>> => {
    appConfig.isDebug && console.log("API Result Failed", err);
    /*
    Sentry.captureMessage("API Call non 200")

     */
    //refreshToken().then(x => console.log("Refresh token"))
    return new Promise<APISuccessResponse<T, U>>((resolve, reject) => reject(err))
}

export const apiError = (err: any, setModal: React.Dispatch<React.SetStateAction<ModalProps>> | undefined) => {
    setModal &&
    setModal({
        isOpen: true,
        title: "An Error",
        icon: WarningAmberIcon,
        iconColor: "#aa0000",
        message: "An error has occurred with an API call - make sure you have a stable network connection and retry",
        dialogType: DialogType.Message
    })
    //Sentry.captureMessage("API Failed: " + JSON.stringify(err))
    appConfig.isDebug && console.log("Api Failed", err)
}

export const isDateTimeFieldName = (key: String) =>
    ((key.toLowerCase().includes("timestamp") || key.toLowerCase().includes("date")) && !key.startsWith("use")) || key.toLowerCase().endsWith("start") || key.toLowerCase().endsWith("end")
        || ((key.toLowerCase().includes("validfrom"))) || ((key.toLowerCase().includes("validto")))

export const isBooleanField = (key: String) =>
    (key.toLowerCase().startsWith("use") || key.toLowerCase().endsWith("flag"))

export const apiResultParse: (data: any) => any = (data) => {
    if (Array.isArray(data)) {
        return data.map(x => apiResultParse(x))
    } else if (typeof (data) === "object" && data !== null && data !== undefined) {
        return (Array.from(Object.entries(data)).map(e => {
            const key = e[0]
            const value = e[1]

            if (typeof (value) === "object" && value !== null && value !== undefined) {
                return [key, apiResultParse(value)]
            } else if (isDateTimeFieldName(key) && typeof(value) === "string") {
                const dateValue = Date.parse(value)
                if (!isNaN(dateValue))
                    return [key, new Date(dateValue)]
                else
                    return [key, undefined]
            } else return [key, value]
        }).reduce((a, b) => ({...a, [b[0]]: b[1]}), {}))
    } else {
        return data;
    }
}
