import {
    LoggedInComponent,
    LoggedInComponentWrapper,
} from "../../components/LoggedInPage";
import {Box} from "@mui/material";
import SpinnerCard from "../../components/SpinnerCard";
import React from "react";
import {QuestionnaireId} from "../../questionnaire/model/Questionnaire";
import {useFetchStudentQuestionnaireData} from "../../student/lib/StudentQuestionnaireService";
import {useParams} from "react-router-dom";
import {QuestionnaireAnswers} from "../../questionnaire/component/submission/QuestionnaireAnswers";
import {pageHeadingAtom} from "../../Atoms";
import {useSetAtom} from "jotai";
import {useStudentInformationService} from "../../student/lib/StudentInformationService";


const QuestionnaireReviewWrapper: LoggedInComponentWrapper = (context) => {
    const params = useParams()
    if (context.state === "ready") {
        const user = context.userInformation
        const client = context.client
        const questionnaireId = client.settings.clientCycleSettings.applicationSuiteSettings.materialSlotsDefinition.studentQuestionnaireId
        if (questionnaireId && params.guid) {
            const props = {...context, userInformation: user, client, questionnaireId, guid: params.guid}

            return <QuestionnaireReview {...props}/>
        }
        else {
            return <Box>No application suite student questionnaire id found - this is bad</Box>
        }
    }
    else {
        return <SpinnerCard message="Loading..."/>
    }
}

const QuestionnaireReview: LoggedInComponent<{questionnaireId: QuestionnaireId, guid: string}> = (props) => {
    const setPageHeading = useSetAtom(pageHeadingAtom)

    const studentInfo = useStudentInformationService(props.guid)

    if (studentInfo.isSuccess) {
        setPageHeading(studentInfo.data.firstName + " " + studentInfo.data.lastName + " - Review CUA")
    }

    const questionnaire = useFetchStudentQuestionnaireData(props.userInformation, props.guid, props.client.id, props.questionnaireId)

    return questionnaire.isSuccess && studentInfo.isSuccess ? <>
        <Box sx={{height: "100%", overflowY: "scroll"}}>
            <QuestionnaireAnswers answers={questionnaire.data.elements}/>
        </Box>
    </> : <SpinnerCard message="Loading..."/>
}

export default QuestionnaireReviewWrapper