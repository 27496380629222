import {Box, Link} from "@mui/material";
import React from "react";
import {useAppContext} from "../lib/context";
import {messageModal} from "./modal/InfoModal";
import {appConfig} from "../conf/conf";
import {loginStateAtom} from "../Atoms";
import {useAtomValue} from "jotai";

export interface FooterProps {
    footerPosition?: "static" | "fixed"
}

export const PageFooter = (props: FooterProps) => {
    const {setModal} = useAppContext()

    const loginState = useAtomValue(loginStateAtom)

    const showBuildInfo = () => {
        setModal && setModal({...messageModal("Build Info", "Build Info"),
            component: <>
                Build time {appConfig.buildVersion}<br/>
                env: {appConfig.env}<br/>
                loginState: {loginState}
            </>
        })
    }

    return <Box sx={{height: "60px", margin: "0 auto 10px auto", paddingLeft: "20px",
        bottom: 0, right: 0, position: props.footerPosition || "static"}}
         display="flex"
         flexWrap="wrap"
         justifyContent="flex-end"
         alignItems="center"
         bgcolor="background.paper"
    >
        <Box p={2}>
            <Link href="https://about.practicumfit.com">About</Link>
        </Box>
        <Box p={2}>
            <Link href="/terms">Terms and Conditions</Link>
        </Box>
        <Box p={2}>
            <Link href="/privacy">Privacy Policy</Link>
        </Box>
        <Box p={2}>
            <Link onClick={e => showBuildInfo()}>Info</Link>
        </Box>
        <Box p={2}>
            <Link href="https://practicumfit.zendesk.com">Help</Link>
        </Box>
    </Box>
}