import {InternshipSite, InternshipSiteTrack, Organization} from "../model/Organization";
import internshipSiteFromOrg from "./InternshipSiteFromOrganization";
import {UserInformation} from "../../login/model/UserInformation";

export interface TrackListRowData {
    track: InternshipSiteTrack
    internshipSiteName: string
    organizationId: number
    description: string
    admins?: UserInformation[]
}

const tracksFromOrg = (org: Organization) => tracksFromInternshipSite(internshipSiteFromOrg(org))

export const tracksFromInternshipSite: (site: InternshipSite) => TrackListRowData[] = (site: InternshipSite) =>
    site.siteTracks.flatMap(row => {
        return {
            "track": row,
            "internshipSiteId": site.id,
            "internshipSiteName": site.name,
            "description": site.description,
            "organizationId": site.organizationId,
            "admins": site.admins
        } as TrackListRowData
    }).sort((a, b) => (a.internshipSiteName + a.track.name).localeCompare((b.internshipSiteName + b.track.name)))


export default tracksFromOrg;