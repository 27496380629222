import React from "react";
import {SiteTrackStudentCycleRank} from "../model/Organization";
import {UserInformation} from "../../login/model/UserInformation";
import {EligibleStudent, NotFoundStudent} from "../lib/EligibleStudentService";
import {useAtomValue} from "jotai/index";
import {clientAtom} from "../../Atoms";
import {Client, ClientSettingsOps} from "../../client/model/Client";
import {requireGroup} from "../../lib/util";
import Groups from "../../model/Groups";
import {Box, Button, Hidden, Tooltip} from "@mui/material";
import {CancelOutlined, DeleteOutlined, Lock} from "@mui/icons-material";
import {useAppContext} from "../../lib/context";

export const ItemComponent: React.FC<{x: SiteTrackStudentCycleRank, i: number, userInformation: UserInformation, student: EligibleStudent | NotFoundStudent, deleteClickHandler: (x: SiteTrackStudentCycleRank) => void
}> =
    ({
         userInformation,
         x, i,
         student, deleteClickHandler
     }) => {
        const {isDebug} = useAppContext()

        const client = useAtomValue(clientAtom) as Client
        isDebug && console.log("itemComponent for ", i, x)

        const locked = ClientSettingsOps.rankListLocked(client.settings) && !requireGroup(userInformation.userGroups, Groups.globalAdmin)

        return <>
            <Box display="flex" alignItems="center">
                <PositionMarker existingPosition={x.position} newPosition={i}/>
                <Box flexGrow={1}><StudentLabel student={student}/></Box>

                {!locked && <Hidden smDown={true}><Box><RemoveButton deleteClickHandler={deleteClickHandler}
                                                                     x={x}/></Box></Hidden>}
                {locked &&
                    <Box>
                        <Tooltip title="Locked"><Lock/></Tooltip>
                    </Box>
                }
            </Box>
            {!locked && (
                <Hidden smUp={true}><Box><RemoveButton deleteClickHandler={deleteClickHandler} x={x}/></Box></Hidden>)}
        </>
}

export const StudentLabel: React.FC<{ student: EligibleStudent | NotFoundStudent }> = ({student}) => {
    if (student.student) {
        return <>{student.student.firstName || ""} {student.student.lastName || ""} &lt;{student.student.email}&gt;</>
    }
    else {
        return <Box className="flex flex-row">
            <Box className="stop m-1 mt-[-3px]"><CancelOutlined/></Box><Box className="text-slate-400 m-1">{student.email}</Box><Box className="m-1"><i>[Student Deleted]</i></Box>
        </Box>
    }
}

export const PositionMarker: React.FC<{ existingPosition: number, newPosition: number }> = (
    {
        existingPosition,
        newPosition
    }) => {

    const newPositionSx = {backgroundColor: "rgba(0,0,128,0.4)"}
    const makeSx = (existingPosition === newPosition) ? {
        backgroundColor: "rgba(0,0,0,0.1)",
        color: "rgba(0,0,0,0.3)"
    } : {backgroundColor: "rgba(0,128,0,0.4)"}

    return <>
        <Box className="border-1 p-2 w-10 rounded-full" justifyContent="center" display="flex" m={1} sx={makeSx}>
            <Tooltip title="Current Position">
                <Box>{existingPosition}</Box>
            </Tooltip>
        </Box>
        {existingPosition !== newPosition &&
            <Box className="border-1 p-2 w-10 rounded-full" justifyContent="center" display="flex" m={1}
                 sx={newPositionSx}>
                <Tooltip title="New Position">
                    <Box>{newPosition}</Box>
                </Tooltip>
            </Box>
        }
    </>
}
const RemoveButton = (props: {
    deleteClickHandler: (x: SiteTrackStudentCycleRank) => void,
    x: SiteTrackStudentCycleRank
}) => (
    <Tooltip title="Remove Student">
        <Button className="stop" startIcon={<DeleteOutlined/>} onClick={e => props.deleteClickHandler(props.x)}>
            Remove Student
        </Button>
    </Tooltip>
)

