import React from 'react'
import {Box, Grid, Tooltip, Typography} from "@mui/material";
import {QuestionnaireSubmissionElement} from "../../model/QuestionnaireSubmissionElement";
import {requireOneOfGroup} from "../../../lib/util";
import Groups from "../../../model/Groups";
import {Theme} from "@mui/material/styles";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import {QuestionnaireSummaryDisplay} from "../../QuestionnaireDisplay";
import {RichElement} from "../../../components/customTable/RichElement";
import {isPresent} from "../../../components/customTable/util";
import {makeStyles} from "tss-react/mui";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../../Atoms";
import {UserInformation} from "../../../login/model/UserInformation";
import {QuestionnaireElement, QuestionnaireElementOps, QuestionnaireTableElement} from "../../model/Questionnaire";
import {chompQuestionnaireEntries} from "../../lib/ChompElements";
import {Either} from "../../../lib/Either";
import {QuestionnaireTableAnswers} from "./QuestionnaireTableAnswers";

interface QuestionComponentProps {
    element: QuestionnaireElement
}

interface QuestionnaireAnswersProps {
    answers: QuestionnaireElement[]
}

const useStyles = makeStyles()((theme: Theme) => ({
        questionLabel: {
            padding: theme.spacing(1),
            fontWeight: "bold"
        },
        questionAnswer: {
            padding: theme.spacing(1),
            paddingLeft: theme.spacing(2),
        },
        questionHeading: {
            padding: theme.spacing(1),
            marginTop: theme.spacing(1)
        },
        questionSubheading: {
            padding: theme.spacing(1),
            marginTop: theme.spacing(1)
        },
        sepLine: {
            border: 0,
            height: 1,
            backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 128, 0.25), rgba(0, 0, 0, 0))"
        },
        headingLine: {
            border: 0,
            height: 1,
            backgroundImage: "linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 128, 0.5), rgba(0, 0, 0, 0))"
        }
    }
))

const QuestionComponent = (props: QuestionComponentProps) => {
    const {classes} = useStyles()

    if (!isPresent(props, "element.question.isRequired")) {
        console.log("Inexplicably it's not present", props.element)
        return <React.Fragment/>
    }

    return (isPresent(props.element.answer, "answer") || props.element.question?.isRequired) ? (
        <React.Fragment>
            <Grid item md={4} xs={12}>
                <Grid container spacing={0} ml={1}>
                    <Grid item xs={12} key={props.element.id}>
                        <Box className={classes.questionLabel} id={QuestionnaireElementOps.makeQuestionHTMLId(props.element)}>
                            {props.element.question?.isRequired && !isPresent(props.element.answer, "answer") &&
                                <Tooltip title="This is a required question that has no answer">
                                    <ErrorOutlineIcon className="stop" style={{padding: "2px", "margin": "-3px 3px 0px -25px" }}/>
                                </Tooltip>
                            }
                            {props.element.question?.label}
                        </Box>
                    </Grid>
                    <Grid item xs={12} >
                        <Box className={`${classes.questionAnswer} overflow-x-hidden text-ellipsis`} id={QuestionnaireElementOps.makeQuestionAnswerHTMLId(props.element)}>
                            {props.element.answer && (<RichElement value={props.element.answer?.answer}/>)}
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    ) : (<React.Fragment/>)
}

const HeadingComponent = (props: QuestionComponentProps) => {
    const {classes} = useStyles()

    return (
        <Grid item xs={12} key={props.element.id} mt={2}>
            <hr className={classes.headingLine}/>
            <Typography variant="h4" className={classes.questionHeading}>{props.element.content}</Typography>
        </Grid>
    )
}

const SubheadingComponent = (props: QuestionComponentProps) => {
    const {classes} = useStyles()

    return (
        <Grid item xs={12} key={props.element.id}>
            <Typography variant="h5" className={classes.questionSubheading}>{props.element.content}</Typography>
        </Grid>
    )
}


const HTMLHeadingComponent = (props: QuestionComponentProps) => props.element.content ? (
    <Grid item xs={12} key={props.element.id}>
        <div dangerouslySetInnerHTML={{
            __html: props.element.content
        }}></div>
    </Grid>
) : <Grid item xs={12} key={props.element.id}>Element Undefined</Grid>

export const QuestionnaireAnswer = (props: { element: Either<QuestionnaireElement, QuestionnaireTableElement> }) => {
    const userInformation: UserInformation = useAtomValue(userInformationAtom)

    const {classes} = useStyles()

    const e = props.element

    return e.fold(x => {
        if (requireOneOfGroup(userInformation?.userGroups, [Groups.student, Groups.directoryViewer]) && !x.studentVisible) {
            return <React.Fragment/>
        } else {
            switch (x.elementType?.type) {
                case 'Question':
                    return (<QuestionComponent key={x.id} element={x as QuestionnaireSubmissionElement}/>)
                case 'Short Section Heading':
                    return (<HeadingComponent key={x.id} element={x}/>)
                case 'Subheading':
                    return (<SubheadingComponent key={x.id} element={x}/>)
                case 'HTML Content':
                    return (<HTMLHeadingComponent key={x.id} element={x}/>)
                case 'Formatting Line Break':
                    return (<Grid xs={12}><hr className={classes.sepLine}/></Grid>)
                default:
                    return (<Grid xs={12}>Unknown Type {x.elementType?.type}</Grid>)
            }
        }
    }, table => {
        return <QuestionnaireTableAnswers table={table} columnHeadings={table.parsedParameters.headings} key={table.id.toString()} columnCount={table.parsedParameters.columnCount}/>
    })
}

export const QuestionnaireAnswers = (props: QuestionnaireAnswersProps) => {
    const data = chompQuestionnaireEntries(props.answers)
    return (
        <Grid container spacing={0}>
            <Grid item xs={12}>
                <QuestionnaireSummaryDisplay questionnaire={props.answers}/>
            </Grid>

            {data.map(x => (
                <QuestionnaireAnswer element={x}/>
            ))}
        </Grid>
    )
}