import React, {Dispatch, SetStateAction, useEffect, useState} from "react"
import {Button, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField} from "@mui/material";
import {useAppContext} from "../../lib/context";
import {API, api, apiError} from "../../lib/Api";
import {EmailControlState, EmailTemplate} from "../model/Email";
import Groups from "../../model/Groups";
import {makeStyles} from "tss-react/mui";
import {userInformationAtom} from "../../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom} from "../../Atoms";

interface EmailComposeProps {
    setMode: Dispatch<SetStateAction<EmailControlState>>
}

interface EmailSend {
    group: string
    subject: string
    body: string
}

interface ComposeReturn {
    preview: EmailTemplate | null
    element: JSX.Element
    data: EmailSend
}

export const useStyles = makeStyles()((theme) => ({
    formControl: {
        marginBottom: theme.spacing(1),
        minWidth: "100%",
        maxWidth: "100%",
        position: "relative"
    }
}));

export type EmailPayload = {subject: string, body: string, groupName?: string}

export const useEmailCompose: (props: EmailComposeProps) => ComposeReturn = (props) => {
    const {setModal} = useAppContext()

    const client = useAtomValue(clientAtom)
    const userInformation = useAtomValue(userInformationAtom)

    const [subject, setSubject] = useState<string>("")
    const [body, setBody] = useState<string>("")
    const [submit, setSubmit] = useState<boolean>(false)
    const [preview, setPreview] = useState<EmailTemplate | null>(null)
    const [recipient, setRecipient] = useState<string>("")

    const localClientId = client.id || 0

    const {classes} = useStyles()

    useEffect(() => {
        if (submit) {
            setSubmit(false)
            api<EmailTemplate, EmailPayload>(API.bulkPreview(), userInformation, localClientId, {
                subject: subject,
                body: body
            }).then(response => {
                setPreview({...response.data, groupName: recipient})
                props.setMode("preview")
            }).catch(e => apiError("Preview Failed", setModal))
        }
    }, [submit, setSubmit, userInformation, localClientId, subject, body, setPreview, recipient, props, setModal])

    return {preview: preview, element: (
        <form>
            <FormControl className={classes.formControl}>
                <TextField name="subject" aria-label="Email Subject"
                           label="Subject" variant="outlined" value={subject}
                           onChange={e => setSubject(e.target.value)}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <TextField name="body" aria-label="Email Body"
                           label="Body"
                           rows={10}
                           maxRows={10}
                           variant="outlined" multiline value={body}
                           onChange={e => setBody(e.target.value)}/>
            </FormControl>
            <FormControl className={classes.formControl}>
                <FormLabel component="legend" aria-label="Select the recipient group">Recipient Group</FormLabel>
                <RadioGroup aria-label="gender" name="gender1" value={recipient} onChange={e => setRecipient(e.target.value)}>
                    <FormControlLabel value={Groups.student} control={<Radio />} label="Students"/>
                    <FormControlLabel value={Groups.programAdmin} control={<Radio />} label="Program Admins" />
                    <FormControlLabel value={Groups.siteAdmin} control={<Radio />} label="Site Admins" />
                </RadioGroup>
            </FormControl>
            <Button color="primary" onClick={e => setSubmit(true)}>Preview</Button>
        </form>
    ), data: {subject: subject, body: body, group: recipient }} as ComposeReturn;
}