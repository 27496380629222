import React, {useState} from "react";
import {QuestionnaireInputProps} from "../QuestionnaireEdit";
import {FormControl, TextField, Tooltip} from "@mui/material";
import {answerFor, useStyles} from "../QuestionnaireList";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import {isPresent} from "../../components/customTable/util";

export const filterLabelText = (label: string) => {
    if (label.startsWith("[Table]")) {
        return ""
    }
    else {
        return label.replace(/(^\[[A-Za-z]+\])/gi, "")
    }
}

const QuestionnaireTextInput = (props: QuestionnaireInputProps) => {
    const [state, setState] = useState<string>(answerFor(props) || "")

    const {classes} = useStyles()

    return (
        <FormControl className={classes.formControl}>
            {props.question.isRequired && !isPresent(props.answer, "answer") &&
            <Tooltip title="This is a required question that has no answer">
                <ErrorOutlineIcon className="stop" style={{position: "absolute", left: "-30px", top: "14px"}}/>
            </Tooltip>
            }
            {/* We can't include isUpdated here because we need to debounce save typing in progress, not just onBlur otherwise it overwrites when we setQueryData */}
            <TextField variant="filled" type="text" id={`input-${props.question.id}`}
                       aria-describedby={`question${props.question.id}-help`}
                       aria-label={props.question.helpText}
                       placeholder={props.question.type.questionType === "Link" ? "https://" : ""}
                       InputProps={{
                           readOnly: !props.editable,
                       }}
                       value={state}
                       required={props.requiredBlocking && props.question.isRequired}
                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                           props.onChange && props.onChange(e.target.value)
                           return setState(e.target.value)
                       }}
                       name={`question-${props.question.id}`}
                       label={filterLabelText(props.question.label)}
            />
            <small id={`question-${props.question.id}-help`} className="form-text text-muted">{props.question.isRequired &&
                <span>This is a required field.</span>} {props.question.helpText}
            </small>
        </FormControl>
    )
}

export default QuestionnaireTextInput