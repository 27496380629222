
export const LetterOfReferenceStatuses = {
    requested: "Requested",
    declined: "Declined",
    completed: "Completed",
    canceled: "Canceled"
}

export const letterOfReferenceStatuses = [
    LetterOfReferenceStatuses.requested, LetterOfReferenceStatuses.declined, LetterOfReferenceStatuses.completed, LetterOfReferenceStatuses.canceled
] as const

export type LetterOfReferenceStatus = typeof letterOfReferenceStatuses[number]

export interface LetterOfReference {
    id: string
    slotId: string
    studentEmail: string
    referentEmail: string
    referentName: string
    referentPhone: string
    referentTitle: string | undefined
    emailNote: string | undefined
    documentId: string | undefined
    state: LetterOfReferenceStatus
    createdTimestamp: Date
}