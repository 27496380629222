import React, {useEffect, useState} from 'react'

import {FormControl, InputLabel, MenuItem, Select, SelectChangeEvent} from "@mui/material"
import {InternshipSite, InternshipSiteId} from "../model/Organization";
import {useAppContext} from "../../lib/context";
import {makeStyles} from "tss-react/mui";

interface SiteSelectProps {
    sites: Array<InternshipSite>
    siteId?: InternshipSiteId
    setSiteId: (siteId: InternshipSiteId) => void
    name?: string
}

const useStyles = makeStyles()((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: "100%",
        maxWidth: "100%",
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));

export const SiteSelect = (props: SiteSelectProps) => {
    const { isDebug } = useAppContext()

    const [select, setSelect] = useState<InternshipSiteId>(() => props.siteId || 0)
    isDebug && console.log("SiteSelect", props.siteId)

    useEffect(() => {
        isDebug && console.log("Effecting for siteId", props.siteId)
        props.siteId && setSelect(props.siteId)
    }, [props.siteId, isDebug])

    const {classes} = useStyles();

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        setSelect(event.target.value as InternshipSiteId);
        props.setSiteId(event.target.value as InternshipSiteId)
    };

    return (
        <FormControl variant="filled" className={classes.formControl}>
            <InputLabel id="user-programs-label">Sites</InputLabel>
            <Select name={props.name || "siteId"}
                    value={select}
                    onChange={handleChange}>
                {props.sites.map( e => (
                    <MenuItem key={e.id} value={e.id}>{e.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}