import React, {PropsWithChildren, useEffect, useState} from "react";
import {Link, useLocation, useNavigate} from "react-router-dom";
import MaterialTable, {MTableToolbar} from "@material-table/core";

import {useAppContext} from "../../lib/context";
import SpinnerCard from "../../components/SpinnerCard";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {AppBar, Box, Button, debounce, Fade, Grid, Paper, TextField, Tooltip, Typography} from "@mui/material";
import {api, API, apiError} from "../../lib/Api";
import {Program} from "../../program/model/Program";
import {AssignmentInd, RemoveShoppingCart} from "@mui/icons-material";
import {
    confirmDeleteModal,
    errorMessageModal,
    messageModal,
    questionModal
} from "../../components/modal/InfoModal";
import EmailIcon from '@mui/icons-material/Email';
import {TableColumn} from "../../components/customTable/CustomTable";
import {requireGroup, requireOneOfGroup, setCookie} from "../../lib/util";
import Groups from "../../model/Groups";
import InfoIcon from "@mui/icons-material/Info";
import {RichElement} from "../../components/customTable/RichElement";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {InternshipSiteTrack, Organization} from "../../internship-site/model/Organization";
import {siteTracksFromOrganization} from "../../internship-site/component/SiteTrackSelect";
import {showAdminMatches} from "../../match/MatchResults";
import User, {GUID} from "../../user/model/User";
import {StudentListSchoolProgramComponent} from "./StudentListSchoolProgramComponent";
import {LoggedInComponent} from "../../components/LoggedInPage";
import {TabContext, TabPanel} from "@mui/lab";
import Tab from "@mui/material/Tab";
import {StudentApplicationList} from "./StudentApplicationList";
import {useAtomValue, useSetAtom} from "jotai";
import {assumedUserEmailAtom, clientAtom, userInformationAtom} from "../../Atoms";
import {useMutation, useQueryClient} from "@tanstack/react-query";
import {StudentUserInformation} from "../../login/model/UserInformation";
import useListStudentService, {listStudentsKey} from "../lib/StudentService";
import {ExpandoSiteList} from "./ExpandoStudentSiteList";
import {DialogType, ModalAction} from "../../components/modal/ModalProps";
import {useOrganizationsService} from "../../internship-site/lib/ListOrganizationService";
import {useApiWrapper} from "../../lib/APIWrapper";
import Tabs from "@mui/material/Tabs";
import {refreshUser} from "../../login/lib/UserSessionsService";
import {StudentOps} from "../model/Student";

export const matchedTrackNames: (row:StudentUserInformation, siteTracks: InternshipSiteTrack[]) => string = (row, siteTracks) =>
    siteTracks
        .filter(x => x.id === row.student?.matchedTrackId)
        .map(x => x.siteName + " " + x.name).join("")


const EditableStudentLimit: React.FC<{user: StudentUserInformation}> = ({user}) => {
    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom)

    const [limit, setLimit] = useState<number>(user.student?.applicationLimit || 0)
    const [justSaved, setJustSaved] = useState<boolean>(false)

    useEffect(() => {
        setLimit(user.student?.applicationLimit || 0)
    }, [user])

    const debouncedSave = debounce((s: string) => {
        if (user.guid) {
            api(API.updateStudentApplicationLimit(user.guid), userInformation, client.id, parseInt(s))
                .then(e => {
                    console.log("Saved")
                    setJustSaved(true)
                    setTimeout(() => setJustSaved(false), 1000)
                })
        }
    }, 1000)

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const num = parseInt(e.target.value)
        if (e.target.value === "" || num <= 10) {
            setLimit(parseInt(e.target.value))
            debouncedSave(e.target.value)
        }
    }

    return (
        <Box style={{position: "relative"}} p={0} m={0}>
        <TextField
            label="App Limit"
            type="number"
            InputLabelProps={{
                shrink: true,
            }}
            onWheel={(event: any) => event.target.blur()}
            value={limit}
            onChange={handleChange}
        />
        <Fade in={justSaved}>
            <Grid container spacing={1} style={{position: "absolute"}}>
                <Grid item className="go"><CheckCircleIcon/></Grid>
                <Grid item className="go">Saved</Grid>
            </Grid>
        </Fade>
        </Box>
    )
}

const RollUps = (props: PropsWithChildren<any>) => {
    const {isDebug} = useAppContext();

    const data = props.data as User[]
    const invites = data.length;
    const activated = data.filter(x => (x.student?.paid || x.student?.school?.schoolPaid) && !x.student?.hasWithdrawn && x.student.attestationTimestamp).length;
    const withdrawn = data.filter(x => x.student?.hasWithdrawn).length;
    const inactive: number = data.filter(x => (!x.student?.paid && !x.student?.school?.schoolPaid) || !x.student.attestationTimestamp).length
    const division = withdrawn > 0 ? 3 : 4

    isDebug && console.log("Withdrawn", withdrawn)

    return (
        <Box>
            <MTableToolbar {...props}/>
            <Grid container style={{maxWidth: 620, margin: "0 auto"}} spacing={2}>
                <Grid item sm={division} xs={12}>
                    <Paper elevation={3}><Box p={2}>
                        <Typography style={{fontSize: 32}}>{invites}</Typography>
                        <Typography>Invites Total</Typography>
                    </Box></Paper>
                </Grid>
                <Grid item sm={division} xs={12}>
                    <Paper elevation={3}><Box p={2}>
                        <Typography style={{fontSize: 32}}>{activated}</Typography>
                        <Typography>Activated Accounts</Typography>
                    </Box></Paper>
                </Grid>
                <Grid item sm={division} xs={12}>
                    <Paper elevation={3}><Box p={2}>
                        <Typography style={{fontSize: 32}}>{inactive}</Typography>
                        <Typography>Unaccepted Invites</Typography>
                    </Box></Paper>
                </Grid>
                {withdrawn > 0 &&
                    <Grid item sm={division} xs={12}>
                        <Paper elevation={3}><Box p={2}>
                            <Typography style={{fontSize: 32}}>{withdrawn}</Typography>
                            <Typography>Withdrawn</Typography>
                        </Box></Paper>
                    </Grid>
                }
            </Grid>
        </Box>
    )
}

export const studentStatus = (row: StudentUserInformation) => {
    if (StudentOps.studentApplicationsSubmittedCount(row) > 0 && !StudentOps.hasPaid(row)) {
        return "Submitted without Payment"
    }
    else {
        return (row.student?.hasWithdrawn ? "Withdrawn" : (row.student?.paid || row.student?.school?.schoolPaid) ? "Enrolled" : "Invited")
    }
}

export const StudentList: LoggedInComponent<{programId: number, programs: Program[]}> = (props) => {

    const {client, userInformation} = props
    const {isDebug, setModal, setPageIncrement} = useAppContext();

    //const [siteTracks, setSiteTracks] = useState<InternshipSiteTrack[]>([])

    const location = useLocation()

    const [tabValue, setTabValue] = React.useState(location.hash.substring(1) || '1');

    const setAssumedUserEmail = useSetAtom(assumedUserEmailAtom)
    const queryClient = useQueryClient()
    const navigate = useNavigate()

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
        navigate(location.pathname + "#" + newValue)
    };


    const studentQuery = useListStudentService();
    useApiWrapper(studentQuery, "Student List")

    const orgService = useApiWrapper(useOrganizationsService({clientId: client.id, userInformation, client}), "Organizations Service")

    const siteTracksFromOrgs = (orgList: Organization[] | undefined) => orgList ? orgList.flatMap(x => siteTracksFromOrganization(x)) : []

    /*
    useEffect(() => {
        if (orgService.isSuccess) {
            setSiteTracks(orgService.data.flatMap(x => siteTracksFromOrganization(x)))
        }
    }, [orgService.status])

     */

    const assumeUser = (email: string) => {
        setAssumedUserEmail && setAssumedUserEmail(email)
        setCookie("assumedUserEmail", email, 365)
        localStorage.setItem("assumedUserEmail", email)
        refreshUser(queryClient).then(x => navigate("/home"))
    }

    const withdrawStudent = (email: string) => {
        setModal && setModal(questionModal(email, "Confirm", "Are you sure you wish to withdraw from " + email + " from the match",
            DialogType.Confirm, (action: ModalAction) => {
                if (action === ModalAction.Confirm) {
                    api(API.withdraw(email), userInformation, client.id)
                        .then(e => {
                            setPageIncrement && setPageIncrement(new Date())
                            setModal && setModal(messageModal("Withdrawn", "Student has been withdrawn: " + email))
                        })
                        .catch(e => {
                            apiError(e, setModal)
                        })
                }
            }))
    }

    const columns = ([
        {title: 'Sites', field: 'sites.length', render: row => (<ExpandoSiteList siteList={orgService.isSuccess ? orgService.data.map(x => x.internshipSite) : []} row={row} client={client}/>)},
        {title: 'Tracks', field: 'tracks.length', render: row => row.student?.tracks?.length + " Track" + (row.student?.tracks?.length === 1 ? "" : "s") +" Ranked", cellStyle: {"verticalAlign": "top", "paddingTop": "30px"}, width: 100, },
        ] as TableColumn<StudentUserInformation>[])
        .concat(
            requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin, Groups.clientAdmin]) ?
            [{title: 'School/Program', field: '', render: row => <StudentListSchoolProgramComponent user={row} programs={props.programs}/>, cellStyle: {"verticalAlign": "top", "paddingTop": "30px", "whiteSpace": "nowrap"}}] as TableColumn<StudentUserInformation>[]
                : [] as TableColumn<StudentUserInformation>[])
        .concat([
            {title: 'Name', field: '', customSort: (a, b) => ((a.lastName || "") + (b.firstName || "")).localeCompare((b.lastName || "") + (b.firstName || "")), render: row => <>{row.lastName}{row.lastName ? "," : ""} {row.firstName}</>, cellStyle: {"verticalAlign": "top", "paddingTop": "30px"}},
            {title: 'Email', field: 'email', cellStyle: {"verticalAlign": "top", "paddingTop": "30px"}},
            ])
        .concat(client?.settings.useApplicationLimit === "yes" ?
                [{title: 'Application Limit', field: 'applicationLimit', render: row => <EditableStudentLimit user={row}/>, cellStyle: {"verticalAlign": "top", "paddingTop": "30px"}, editable: "onUpdate"}]
            : [] as TableColumn<StudentUserInformation>[])
        .concat(requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin, Groups.clientAdmin]) ? [
            {title: 'Created Timestamp', field: 'createdTimestamp', render: row => <RichElement value={row.createdTimestamp}/>, cellStyle: {"verticalAlign": "top", "whiteSpace": "nowrap", "paddingTop": "30px"}},
            {title: 'Last Sign In Timestamp', field: 'lastSignInTimestamp', render: row => <RichElement value={row.createdTimestamp}/>, cellStyle: {"verticalAlign": "top", "paddingTop": "30px"}},
            {title: 'Last Activity Timestamp', field: 'lastActivityTimestamp', render: row => <RichElement value={row.lastActivityTimestamp}/>, cellStyle: {"verticalAlign": "top", "paddingTop": "30px"}},
            {title: 'Login Count', field: 'student.signInCount', cellStyle: {"verticalAlign": "top", "paddingTop": "30px"}},
    ] : [] as TableColumn<StudentUserInformation>[])
        .concat([
            {title:'Status', field:'cycle.paid', render: studentStatus , cellStyle:{"verticalAlign":"top", "paddingTop":30},
                customFilterAndSearch: (term, rowData) => studentStatus(rowData).toLowerCase().includes(term.toLowerCase()),
                customSort: (a, b) => studentStatus(a).localeCompare(studentStatus(b))
            },
        {title: 'Matched Track', field: 'cycle.matchedTrackId',
            render: row => <>{showAdminMatches(userInformation, client?.settings) ? matchedTrackNames(row, siteTracksFromOrgs(orgService.data)) : ""}</>,
            customSort: (a, b) => matchedTrackNames(a, siteTracksFromOrgs(orgService.data)).localeCompare(matchedTrackNames(b, siteTracksFromOrgs(orgService.data))),
            cellStyle: {"verticalAlign": "top", "paddingTop": "30px"}}
    ] as TableColumn<StudentUserInformation>[]).map(x => ({...x, editable: x.editable ? x.editable : "never"}))

    const handleDelete = (e: React.MouseEvent, student: User) => {
        e.preventDefault();
        setModal && setModal(confirmDeleteModal(student.guid || "", confirmDelete, "Are you sure you wish to delete " + student.email + "?"))
    }

    const confirmDelete = (action: ModalAction, guid: GUID) => {
        if (action === ModalAction.Delete) {
            api(API.deleteStudent(guid), userInformation, client.id).then(e => {
                if (e.status === "success") {
                    queryClient.invalidateQueries(listStudentsKey(client.id))
                }
            }).catch(err => {
                // TODO this doesn't really do what we think it does, there should be something in data block maybe?
                setModal && setModal(errorMessageModal("Delete Failed", "Student has already paid - please contact support to remove a paid-up student"))
            })
            console.log("Make API Call to Delete")
        }
    }

    // // ref Todo on ProgramList:37
    //const limitWidth = (students.length > 5 && 575) || "100%"

    const resendInviteMutation = useMutation({
        mutationFn: (student: User) => api(API.resendStudentInvite(student.guid || ""), userInformation, client.id).then(x => {return student}),
    })

    const resendInvite = (e: React.MouseEvent, student: User) => {
        resendInviteMutation.mutateAsync(student).then(e => {
            setModal && setModal(messageModal("New Invite", "New Invite sent to " + student.email))
        })
    }

    isDebug && console.log("Student Query", studentQuery.status)

    const localData: StudentUserInformation[] = studentQuery.isSuccess ? studentQuery.data?.filter((x: StudentUserInformation) => props.programId === 0 || x.student.programId === props.programId) : []

    return (!studentQuery.isSuccess || orgService.status === "loading") ? <SpinnerCard message="Loading Student Data..."/> : (<>
            <TabContext value={tabValue}>
                <AppBar position="relative" color="secondary">
                    <Tabs onChange={handleTabChange}
                          value={tabValue}
                          aria-label="Student Display Tabs"
                          indicatorColor="secondary"
                          textColor="inherit"
                    >
                        <Tab label="Invited Student List" value="1" style={{fontWeight: "bold"}}/>
                        {client.settings.clientCycleSettings.applicationSuiteSettings.useApplicationSuite &&
                            <Tab label="Review Student Materials" value="2" style={{fontWeight: "bold"}}/>
                        }
                    </Tabs>
                </AppBar>
                <TabPanel value="1" id="tab-panel-1">
                <MaterialTable
                    title="List of Students"
                    columns={columns}
                    data={localData}
                    actions={[
                        {
                            icon: 'edit',
                            tooltip: 'edit User',
                            onClick: (event: any, rowData: StudentUserInformation | StudentUserInformation[]) => alert("You saved " + rowData)
                        }
                    ]}
                    components={{
                        Action: (props) => (
                            <div style={{width: "125px"}} className="optionsCell">
                                <Link to={`/studentForm/${props.data.guid}`}><InfoIcon className="editIcon"/></Link>
                                <Link to={''} onClick={e => handleDelete(e, props.data)}><DeleteOutlineIcon className="deleteIcon"/></Link>
                                <Tooltip title="Send new invite">
                                    <Link to="#" onClick={e => resendInvite(e, props.data)}><EmailIcon/></Link>
                                </Tooltip>
                                {requireGroup(userInformation?.userGroups, Groups.globalAdmin) &&
                                    <Tooltip title="Assume User">
                                        <Button onClick={e => assumeUser(props.data.email)}><AssignmentInd/></Button>
                                    </Tooltip>
                                }
                                {requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin, Groups.clientAdmin]) &&
                                    <Tooltip title="Withdraw user from match">
                                        <Button onClick={e => withdrawStudent(props.data.email)}><RemoveShoppingCart/></Button>
                                    </Tooltip>
                                }
                            </div>
                        ),
                        Toolbar: (props) => ( <RollUps {...props} data={localData || []}/> )
                    }}
                    options={{
                        actionsColumnIndex: -1,
                        tableLayout: 'auto',
                        paging: true,
                        pageSize: 20,
                        headerStyle: {position: 'sticky', top: 0},
                    }}
                />
                </TabPanel>
                <TabPanel value="2">
                    <StudentApplicationList data={studentQuery.data.filter(x => x.student.programId === props.programId || (requireGroup(userInformation.userGroups, Groups.globalAdmin) && props.programId === 0)) || []} client={client} userInformation={userInformation} state={props.state}/>
                </TabPanel>
            </TabContext>
        </>
    )
}
