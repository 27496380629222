import React, {useEffect, useMemo, useState} from 'react';
import '../../Login.css';
import {api, API, apiError} from '../../lib/Api'
import {useAppContext} from "../../lib/context";
import {useNavigate} from 'react-router-dom';
import {Button, FormControl, InputLabel, ListItemText, MenuItem, Select, TextField} from "@mui/material";
import {ClientAdminUserGroupsSet, ProgramAdminUserGroupsSet, UserGroupsSet} from "../../lib/initialValues";
import useListSchoolService from "../../school/lib/SchoolService";
import {School} from "../../school/model/School";
import {Program} from "../../program/model/Program";
import {ProgramSelect} from "./ProgramSelect";
import Groups from "../../model/Groups";
import {head, isBlank, sum} from "../../lib/util";
import User, {UserId} from "../model/User";
import {errorMessageModal, historyPushModal, messageModal} from "../../components/modal/InfoModal";
import {formDataAsMapFromRef} from "../../lib/Forms";
import SpinnerCard from "../../components/SpinnerCard";
import {SiteSelect} from "./SiteSelect";
import {listOrganizationQueryKey} from "../../internship-site/lib/OrganizationService";
import {InternshipSite, Organization} from "../../internship-site/model/Organization";
import Box from "@mui/material/Box";
import {isEmpty} from "../../components/customTable/util";
import {PageBody, PageHeader} from "../../components/Page";
import Student from "../../student/model/Student";
import {isEmailValid} from "../../lib/emailValidator";
import {makeStyles} from "tss-react/mui";
import useOutstandingOfferService from "../../order/lib/OutstandingOfferService";
import {Offer} from "../../product/model/Product";
import MaterialTable from "@material-table/core";
import {Order} from "../../order/model/Order";
import {TableColumn} from "../../components/customTable/CustomTable";
import {DateFormatted} from "../../lib/DateFormats";
import {userInformationAtom} from "../../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom} from "../../Atoms";
import {UserInformation} from "../../login/model/UserInformation";
import {useQuery} from "@tanstack/react-query";
import {UseQueryResult} from "@tanstack/react-query/src/types";

interface UserFormState {
    id?: string
    guid: string
    email: string
    clientId?: number
    firstName: string
    lastName: string
    groups?: string[]
    programs?: number[]
    sites?: number[]
    lastActivityTimestamp?: Date
    createdTimestamp?: Date
    modifiedTimestamp?: Date

    student?: Student

    directoryViewPassword?: string
    isPending: boolean
}

interface UserFormErrors {
    firstName?: string
    lastName?: string
    email?: string
    groups?: string
    programs?: string
    sites?: string
}

interface UserFormProps {
    email?: string
}

const UserForm = (props: UserFormProps) => {

    const {setModal, isDebug} = useAppContext();

    const userInformation: UserInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom)

    const localClientId: number = client.id
    const localEmail = props.email || "new"

    const [loaded, setLoaded] = useState<boolean>(false)
    const [userGroupsSelect, setUserGroupsSelect] = useState<string>()
    const [user, setUser] = useState<UserFormState | null>()
    const [errors, setErrors] = useState<UserFormErrors>({})
    const [email, setEmail] = useState<string>()
    const [firstName, setFirstName] = useState<string>()
    const [lastName, setLastName] = useState<string>()
    const [offers, setOffers] = useState<Offer[]>([])
    const [orders, setOrders] = useState<Order[]>([])

    const schoolsService = useListSchoolService();
    const schools: Array<School> = useMemo(() => schoolsService.status === 'loaded' ? schoolsService.payload : [] as Array<School>, [schoolsService])
    // Weirdly some programs in Dev has a null name, so adding this filter to prevent that from tripping this up
    const programs: Array<Program> = useMemo(() => schools.flatMap(e => e.programs?.filter(x => x.name).map(x => ({...x, school: e} as Program)) || []).sort((a, b) => a.name.localeCompare(b.name)), [schools])

    const listOrganizationQuery: UseQueryResult<InternshipSite[]> = useQuery({
        queryFn: () => api<Organization[], undefined>(API.listOrganizations(), userInformation, client.id)
                .then(resp => resp.data)
                .then(x => x.map(e => e.internshipSite).sort((a, b) => a.name.localeCompare(b.name))),
        queryKey: listOrganizationQueryKey(client.id),
        staleTime: 86400000
    })


    const outstandingOfferService = useOutstandingOfferService(props.email)

    useEffect(() => {
        if (outstandingOfferService.status === 'loaded') {
            setOffers(outstandingOfferService.payload)
        }
    }, [outstandingOfferService])

    useEffect(() => {
        if (props.email) {
            api<Order[], undefined>(API.listOrdersForUserEmail(props.email), userInformation, localClientId).then(res => {
                console.log("Effecting orders")
                setOrders(res.data.filter(x => sum((x.payments || []).map(y => y.amount)) === x.totalPrice))
            })
        }
    }, [userInformation, localClientId, isDebug, props.email])

    const formRef = React.useRef<HTMLFormElement>(null)

    const navigate = useNavigate()

    const resetPassword = (id: UserId) => {
        api(API.resetUserPassword(id), userInformation, localClientId).then(() =>
            setModal && setModal(messageModal("Password Reset Submitted", "New temporary password generated and email for user " + id))
        ).catch((x) => apiError(x, setModal))
    }

    const useStyles = makeStyles()((theme) => ({
        formControl: {
            margin: theme.spacing(1),
        },
        chips: {
            display: 'flex',
            flexWrap: 'wrap',
        },
        chip: {
            margin: 2,
        },
        noLabel: {
            marginTop: theme.spacing(3),
        },
    }));

    const handleChange = (value: string) => {
        setUserGroupsSelect(value)
    }

    const setPrograms = (value: number[]) => {
        setUser(user => (user && {
            ...user, programs: value
        }))
    }

    const setSites = (value: number[]) => {
        setUser(user => (user && {
            ...user, sites: value
        }))
    }

    const userQuery = useQuery({
        queryFn: () => api<User, undefined>(API.getUser(localEmail), userInformation, localClientId)
            .then(x => {
                const e = x.data
                setUser({
                    ...e,
                    firstName: e.firstName || '',
                    lastName: e.lastName || ''
                })
                setEmail(e.email)
                setFirstName(e.firstName)
                setLastName(e.lastName)
                setUserGroupsSelect(head(e.groups))
                setLoaded(true)
                return e
            })
            .catch(error => console.log(error)),
        queryKey: ["user", localEmail, localClientId],
        enabled: localEmail !== "new",
        staleTime: 86400000
    })

    const validateForm = (data: UserFormState) => {
        const validateEmail = (data: UserFormState) =>
            isBlank(data.email) ? {email: "This field is blank, please enter a value"} :
                isEmailValid(data.email) ? {} : {email: "Please enter a valid email address"}

        const validateFirstName = (data: UserFormState) => isBlank(data.firstName) ? {firstName: "This field is required"} : {}
        const validateLastName = (data: UserFormState) => isBlank(data.lastName) ? {lastName: "This field is required"} : {}
        const validateUserGroups = (data: UserFormState) => isEmpty(data.groups) ? {groups: "This field is required"} :{}
        // We want to be able to deselect all associations
        //const validateSites = (data: UserFormState) => (data.groups && data.groups.includes(Groups.siteAdmin) && (!data.sites || data.sites.length === 0)) ? {sites: "You must pick at least one site to assign to a site-admin."} : {}
        //const validatePrograms = (data: UserFormState) => (data.groups && data.groups.includes(Groups.programAdmin) && (!data.programs || data.programs.length === 0)) ? {programs: "You must pick at least one program to assign to a program-admin."} : {}

        console.log("Email Validate",validateEmail(data))

        return {
            ...validateFirstName(data),
            ...validateLastName(data),
            ...validateEmail(data),
            ...validateUserGroups(data)
        }
    }

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()
        console.log("Submitted")
        if (formRef.current) {
            console.log("Ref has current")
            const formData = formDataAsMapFromRef(formRef)
            if (formData) {
                console.log("form data present")
                const data = {
                    ...(formData as User),
                    groups: [userGroupsSelect],
                    clientId: localClientId,
                    id: localEmail
                } as UserFormState

                const localErrors = validateForm(data)
                setErrors(localErrors)

                if (Object.entries(localErrors).length === 0) {

                    const p = (localEmail === "new" ?
                        api<User, UserFormState>(API.addUser(), userInformation, localClientId, data) :
                        api<User, UserFormState>(API.updateUser(data.email), userInformation, localClientId, data));

                    p.then(r => {
                        console.log("Is Admin",data.groups?.find(x => x.indexOf("-admins")>0))
                        console.log("Data ID", !!data.id)
                        if (data.groups?.find(x => x.indexOf("-admins")>0) && !!data.id) {
                            setModal && setModal(historyPushModal("Administrator Created", "New Administrator created. An invitation has been sent to their email address.", navigate, "/user"))
                        }
                        else {
                            setModal && setModal(historyPushModal("Info", "User Saved Successfully", navigate, "/user"))
                        }
                    }).catch(x => {
                        if (x.status === 400) {
                            x.json().then((json: any) => {
                                const modalParams = errorMessageModal("User Creation Error", json.message)
                                isDebug && console.log("User failed modal", modalParams, json)
                                setModal && setModal(modalParams)
                            })
                        } else {
                            isDebug && console.log("Failed to make call to add user, got ", x)
                            apiError(x, setModal)
                        }
                    })
                } else {
                    isDebug && console.log("Form has errors")
                }
            }
        }
    }

    const {classes} = useStyles();

    const groupsSet = {
        [Groups.globalAdmin]: UserGroupsSet,
        [Groups.clientAdmin]: ClientAdminUserGroupsSet,
        [Groups.programAdmin]: ProgramAdminUserGroupsSet,
        [Groups.directoryAdmin]: ClientAdminUserGroupsSet,
        "default": []
    } as any

    const usersMainGroup: string = head(userInformation?.userGroups) || "default"

    // TODO put in useEffect()
    console.log("UserForm", userInformation?.userGroups, usersMainGroup);
    const userGroupsSet = groupsSet[usersMainGroup] as string[]
    //const userGroupsSet = UserGroupsSet

    if (!loaded && localEmail !== "new") return (<SpinnerCard message="Fetching User"/>)

    const offerColumns: TableColumn<Offer>[] = [{
            title: "ID",
            field: "id"
        },
        {
            title: "Offer",
            field: "name"
        },
        {
            title: "Product Type",
            field: "",
            render: row => <>{row?.product?.productType}</>
        },
        {
            title: "Price",
            field: "price"
        }]

    const orderColumns: TableColumn<Order>[] = [{
        title: "ID",
        field: "id"
    },
        {
            title: "Product Type",
            field: "productType",
            render: row => <>{row?.productType}</>
        },
        {
            title: "Ordered By",
            field: "userEmail"
        },
        {
            title: "OrderActorID",
            field: "orderActorId"
        },
        {
            title: "Order Date",
            field: "createdTimestamp",
            render: row => <DateFormatted date={row.createdTimestamp}/>
        }
    ]

    return ((userQuery.isSuccess || localEmail === "new") && listOrganizationQuery.isSuccess) ? (
        <>
            <PageHeader title="User Info"></PageHeader>
            <PageBody>
                {user?.id && user.id !== "new" &&
                    <Button onClick={() => user?.id && resetPassword(user.email)}>Reset Password for this User</Button>
                }

                <form onSubmit={handleSubmit} ref={formRef}>
                    <FormControl fullWidth className={classes.formControl}>
                        <TextField variant="filled" type="text"
                                   aria-describedby="emailHelp"
                                   placeholder="Enter email"
                                   value={email}
                                   aria-readonly={true}
                                   InputProps={{
                                       readOnly: (localEmail !== "new")
                                   }}
                                   name="email"
                                   label="Email Address"
                                   error={!!errors.email}
                                   onChange={e => setEmail(e.target.value)}
                        />
                        <small id="userEmailHelp" className="form-text text-muted">User's email address</small>
                        <div className="formErrors">{errors.email && <p>{errors.email}</p>}</div>
                    </FormControl>

                    <FormControl fullWidth className={classes.formControl}>
                        <TextField variant="filled" type="text"
                                   aria-describedby="firstNameHelp"
                                   placeholder="First Name"
                                   value={firstName}
                                   name="firstName"
                                   label="First Name"
                                   error={!!errors.firstName}
                                   onChange={e => setFirstName(e.target.value)}
                        />
                        <small id="firstNameHelp" className="form-text text-muted">User's first name</small>
                        <div className="formErrors">{errors.firstName && <p>{errors.firstName}</p>}</div>
                    </FormControl>

                    <FormControl fullWidth className={classes.formControl}>
                        <TextField variant="filled" type="text"
                                   aria-describedby="lastNameHelp"
                                   placeholder="Last Name"
                                   value={lastName}
                                   name="lastName"
                                   label="Last Name"
                                   error={!!errors.lastName}
                                   onChange={e => setLastName(e.target.value)}
                        />
                        <small id="firstNameHelp" className="form-text text-muted">User's last name</small>
                        <div className="formErrors">{errors.lastName && <p>{errors.lastName}</p>}</div>
                    </FormControl>

                    <FormControl fullWidth className={classes.formControl}>
                        <InputLabel id="userGroups-label">User's Groups</InputLabel>
                        <Select
                            labelId={`userGroups-label`}
                            name="groups"
                            variant="filled"
                            value={userGroupsSelect}
                            required={true}
                            onChange={e => {
                                handleChange(e.target.value as string)
                            }}
                            error={!!errors.groups}
                        >
                            {userGroupsSet.map((name) => (
                                <MenuItem key={name} value={name}>
                                    <ListItemText primary={name}/>
                                </MenuItem>
                            ))}
                        </Select>
                        <small id={`userGroup-help`} className="form-text text-muted">Select User's Group</small>
                    </FormControl>
                    {userGroupsSelect === Groups.programAdmin && (
                        <ProgramSelect programs={programs} setPrograms={setPrograms} user={user}
                                       errors={errors.programs}/>)}
                    {userGroupsSelect === Groups.siteAdmin && (
                        <SiteSelect sites={listOrganizationQuery.data} setSites={setSites} user={user} errors={errors.sites}/>)}

                    {outstandingOfferService.status === "loaded" ? <MaterialTable title="Outstanding Offers Available" columns={offerColumns} data={offers}/>
                        : <SpinnerCard message="Loading Offers"/>}

                    <MaterialTable title="Related/Applicable Orders" columns={orderColumns} data={orders}/>

                    <Box component="span" m={1}>
                        <Button type="submit" value="Submit">Submit</Button>
                    </Box>
                    <Box component="span" m={1}>
                        <Button value="Cancel" onClick={() => navigate("/User")}>Cancel</Button>
                    </Box>
                </form>
            </PageBody>
        </>
    ) : <SpinnerCard message="Loading user..."/>
}

export default UserForm;
