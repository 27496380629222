import React, {useEffect, useState} from "react"
import {Client} from "../client/model/Client";
import {StudentUserInformation, UserInformation} from "../login/model/UserInformation";
import {FooterPosition, Page, PageBody, PageHeader} from "./Page";
import Groups from "../model/Groups";
import {useAtomValue} from "jotai";
import {clientAtom, loginStateAtom, userInformationAtom} from "../Atoms";
import {useAppContext} from "../lib/context";
import {Box} from "@mui/material";
import SpinnerCard from "./SpinnerCard";

export type EmptyObject = {}

export type ClientDrivenContext = {
    client: Client
}

export type LoggedInContextProps = ClientDrivenContext & {
    userInformation: UserInformation
    state: "ready"
}

export type StudentLoggedInContextProps = ClientDrivenContext & {
    userInformation: StudentUserInformation
    state: "ready"
}

export type WrappedLoggedInContextProps = (LoggedInContextProps) | {state: "not ready"}
export type StudentWrappedLoggedInContextProps = (StudentLoggedInContextProps) | {state: "not ready"}

export type LoggedInComponentWrapper = React.FC<WrappedLoggedInContextProps>
export type StudentLoggedInComponentWrapper = React.FC<StudentWrappedLoggedInContextProps>

export type LoggedInComponent<T> = React.FC<LoggedInContextProps & T>
export type StudentLoggedInComponent<T> = React.FC<StudentLoggedInContextProps & T>

export interface TitleProps {
    titleString: string
    titleChildren?: React.ReactNode
}

export interface NavPageProps {
    title: (v: LoggedInContextProps) => TitleProps,
    navName: string,
    footerPosition?: FooterPosition,
    requiredGroups: Array<Groups>
}

export interface LoggedInPageProps extends NavPageProps {
    Component: LoggedInComponentWrapper
    variant?: "full" | "half"
    scrollingBody?: boolean
}

export interface StudentLoggedInPageProps extends NavPageProps {
    Component: StudentLoggedInComponentWrapper
    variant?: "full" | "half"
    scrollingBody?: boolean
}

export const LoggedInPage: React.FC<LoggedInPageProps> = (props) => {
    const {isDebug} = useAppContext()
    const {Component, title, navName, footerPosition} = props

    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client
    const loginState = useAtomValue(loginStateAtom)

    const [context, setContext] = useState<WrappedLoggedInContextProps>({state: "not ready"})


    useEffect(() => {
        isDebug && console.log("Are we loaded in?")
        //isDebug && console.log("UserReady", userReady)
        //isDebug && console.log("UseLoginStateCard", useLoginStateCard)
        isDebug && console.log("Client", client)
        isDebug && console.log("ClientCycleSettings", !!(client.settings.clientCycleSettings))
        isDebug && console.log("UserGroups", !!(userInformation?.userGroups))
        isDebug && console.log("User Information", userInformation)
        //isDebug && console.log("User Authentication", userAuthentication)

        if ((client.settings.clientCycleSettings?.clientId || 0) > 0 && userInformation?.userGroups) {
            setContext(context => {
                isDebug && console.log("baking context", userInformation)
                return {client: client as Client, userInformation: userInformation, state: "ready"}
            })
        }
    }, [client, userInformation, loginState, isDebug])

    if (context.state === "ready") {
        const titleObject = title(context as LoggedInContextProps)

        return <Page navName={navName} footerPosition={footerPosition || "static"} variant={props.variant}
                     scrollingBody={props.scrollingBody}>
            <PageHeader title={titleObject.titleString}>
                {titleObject.titleChildren}
            </PageHeader>
            <PageBody scrollingBody={props.scrollingBody}>
                <Component {...context}/>
            </PageBody>
        </Page>
    }
    else {
        return <SpinnerCard message="Authenticating..."/>
    }

}

export const StudentLoggedInPage = (props: StudentLoggedInPageProps) => {
    const context =
        {...props, Component: StudentLoggedInWrapper(props.Component)}
    return <LoggedInPage {...context}/>
}

export const StudentLoggedInWrapper: (Component: StudentLoggedInComponentWrapper) => React.FC<WrappedLoggedInContextProps> = (Component) => (props) => {
    if (props.state === "ready") {
        const student = props.userInformation?.student
        if (student) {
            const context: StudentWrappedLoggedInContextProps = {...props, userInformation: props.userInformation}

            return <Component {...context}/>
        } else {
            return <Box>Not a student user</Box>
        }
    }
    else
        return <SpinnerCard message="Loading..."/>

}