import React, {Dispatch, SetStateAction, useState} from 'react'
import {
    ProgramTrackRelationship,
    ProgramSiteRelationship,
    RelationshipOperation as Op
} from "../model/ProgramSiteRelationship";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {TableSortLabel} from "@mui/material";
import TableBody from "@mui/material/TableBody";
import {AssociationAction, AssociationOptions} from "./AssociationOptions";
import {SortDirection} from "../../components/customTable/Sorting";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import {ProgramId} from "../model/Program";
import {InternshipSiteTrackId} from "../../internship-site/model/Organization";
import {api, API, apiError} from "../../lib/Api";
import {useAppContext} from "../../lib/context";
import {ActionAPIFunction, statusSortMapping} from "../../internship-site/component/AssociationsList";
import {Spinner} from "../../components/SpinnerCard";
import {Link} from "react-router-dom";
import {useDataView} from "../../components/customTable/DataView";
import {messageModal, questionModal} from "../../components/modal/InfoModal";
import {Client, ClientSettingsOps} from "../../client/model/Client";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../Atoms";
import {clientAtom} from "../../Atoms";
import {DialogType, ModalAction} from "../../components/modal/ModalProps";
import {useQueryClient} from "@tanstack/react-query";
import {programRelationshipsQueryKey} from "./ProgramList";

interface AssociationsListProps {
    relationships: ProgramSiteRelationship[]
    setIncrement: Dispatch<SetStateAction<Date>>
    programId: ProgramId
    setInProgress: Dispatch<SetStateAction<InternshipSiteTrackId[]>>
    inProgress: ProgramId[]
}

interface AssociationIconProps {
    relationship: ProgramTrackRelationship | undefined
}

const actionMapping: { [k: number]: ActionAPIFunction } = {
    [AssociationAction.Request.valueOf()]: API.requestSiteRelationship,
    [AssociationAction.Approve.valueOf()]: API.acceptSiteRelationship,
    [AssociationAction.Cancel.valueOf()]: API.cancelSiteRequest,
    [AssociationAction.Reject.valueOf()]: API.rejectSiteRelationship
}

const actionMappingMessage: { [k: number]: string } = {
    [AssociationAction.Request.valueOf()]: "Your request has been submitted. An email has been generated to notify Administrators of the affiliation request.",
    [AssociationAction.Approve.valueOf()]: "Your approval has been submitted.",
    [AssociationAction.Cancel.valueOf()]: "You have cancelled the affiliation.",
    [AssociationAction.Reject.valueOf()]: "Your reject has been submitted. An email has been generated to notify Administrators that their request for affiliation has been rejected."
}

const actionMappingConfirmMessage: { [k: number]: string } = {
    [AssociationAction.Request.valueOf()]: "Do you wish to Request this relationship?",
    [AssociationAction.Approve.valueOf()]: "Do you wish to Approve this relationship?",
    [AssociationAction.Cancel.valueOf()]: "Do you wish to Cancel this relationship?",
    [AssociationAction.Reject.valueOf()]: "Do you wish to Reject this reletionship?"
}

export const AssociationIcon = (props: AssociationIconProps) => {
    switch (props.relationship?.operationId) {
        case Op.ProgramApproved:
        case Op.TrackApproved:
            return <CheckIcon fontSize="small" aria-label="Relationship established" className="go"/>
        case Op.ProgramCancelled:
        case Op.TrackCancelled:
            return <CloseIcon fontSize="small" aria-label="No Relationship" className="stop"/>
        case Op.ProgramRejected:
        case Op.TrackRejected:
            return <CloseIcon fontSize="small" aria-label="No Relationship, and the last request was rejected" className="stop"/>
        case Op.TrackRequested:
        case Op.ProgramRequested:
            return <QueryBuilderIcon fontSize="small" aria-label={"Reqeust relationship"} className="wait"/>
        default:
            return <CloseIcon fontSize="small" aria-label="No Relationship" className="stop"/>
    }
}



export const AssociationsList = (props: AssociationsListProps) => {
    const {isDebug, setModal} = useAppContext()

    const client = useAtomValue(clientAtom) as Client
    const userInformation = useAtomValue(userInformationAtom)

    const localClientId = client?.id

    const queryClient = useQueryClient()

    const associationsColumns = [{
        label: "Site Name",
        name: "siteName",
        comparator: (a: ProgramSiteRelationship, b: ProgramSiteRelationship) => a.site.name.localeCompare(b.site.name)
    }, {
        label: "Track Name",
        name: "trackName",
        comparator: (a: ProgramSiteRelationship, b: ProgramSiteRelationship) => a.siteTrack.name.localeCompare(b.siteTrack.name)
    }, {
        label: "Openings",
        name: "openings",
        comparator: (a: ProgramSiteRelationship, b: ProgramSiteRelationship) => a.siteTrack.openings - b.siteTrack.openings
    },
    {
        label: "Status",
        name: "status",

        comparator: (a: ProgramSiteRelationship, b: ProgramSiteRelationship) => {
            const opSort = (a.relationship ? statusSortMapping[a.relationship.operationId] : 0) - (b.relationship ? statusSortMapping[b.relationship.operationId] : 0)
            return opSort === 0 ? a.site.name.localeCompare(b.site.name) : opSort
        }
    }, {
        label: "Actions",
        name: "actions",
        comparator: undefined
    }];

    const [orderBy, setOrderBy] = useState("Name")

    isDebug && console.log("Assoc list", props.relationships)
    const {items, requestSort, sortConfig} = useDataView(props.relationships)

    const limitWidth = (items && (items.length > 11 && {height: '640px'})) || {}

    const handleUpdate = (siteTrackId: InternshipSiteTrackId) => {
        queryClient.invalidateQueries(programRelationshipsQueryKey(props.programId))
            .then(x =>
                props.setInProgress(e => e.filter(x => x !== siteTrackId))
            )
    }

    const updateRelationship = (action: ModalAction,  x: {action: AssociationAction, programId: ProgramId, siteTrackId: InternshipSiteTrackId}) => {
        if (localClientId && action === ModalAction.Confirm) {
            setModal && setModal(messageModal(
                "Action Performed",
                actionMappingMessage[x.action.valueOf()]
            ))
            props.setInProgress(e => [...e, x.siteTrackId])
            api(actionMapping[x.action.valueOf()](x.programId, x.siteTrackId), userInformation, localClientId)
                .then(() => handleUpdate(x.siteTrackId)).catch((x) => apiError(x, setModal))
        }
    }

    const buttonHandler = (programId: ProgramId, siteTrackId: InternshipSiteTrackId) => (action: AssociationAction) => {
        setModal && setModal(questionModal<{action: AssociationAction, programId: number, siteTrackId: number}>({action: action, programId: programId, siteTrackId: siteTrackId},
            "Confirm", actionMappingConfirmMessage[action.valueOf()], DialogType.Confirm, updateRelationship)
        )
    }

    return (
        <TableContainer component={Paper} style={limitWidth}>
            <Table stickyHeader aria-label="sticky table">
                <TableHead>
                    <TableRow>
                        {associationsColumns.map((column, index) => {
                            return (
                                <TableCell key={index}>
                                    {column.comparator ?
                                        <TableSortLabel
                                            active={orderBy === column.name}
                                            direction={orderBy === column.name ? sortConfig?.direction : SortDirection.ASC}
                                            onClick={() => {
                                                requestSort(column.comparator)
                                                setOrderBy(column.name)
                                            }}>
                                            {column.label}
                                        </TableSortLabel>
                                        : column.label
                                    }
                                </TableCell>

                            )
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {items && items.map((association) => (
                        props.inProgress.includes(association.siteTrack.id || 0) ?
                            (<TableRow key={association.siteTrack.id}><TableCell colSpan={4}>
                                <div style={{width: "100%", margin: "auto"}}><Spinner/></div>
                            </TableCell></TableRow>) :
                            (<TableRow key={association.siteTrack.id}>
                                <TableCell>
                                    {client.settings.directory === "enabled" ?
                                        <Link to={`/directory/entry/${association.site.organizationId}/${association.site.directoryQuestionnaireId}/${association.site.id}`}>{association.site.name}</Link> :
                                        association.site.name
                                    }
                                </TableCell>
                            <TableCell>{association.siteTrack.name}</TableCell>
                            <TableCell>
                                {(!ClientSettingsOps.matchDatePassed(client.settings) || ClientSettingsOps.clearingHousePassed(client.settings)) ?
                                association.siteTrack.openings : 'Unavailable'}
                            </TableCell>
                            <TableCell><AssociationIcon relationship={association.relationship}/></TableCell>
                            <TableCell width="305">
                                <AssociationOptions
                                    buttonHandler={buttonHandler(props.programId, association.siteTrack.id || 0)}
                                    relationship={association.relationship}/>
                            </TableCell>
                        </TableRow>)
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}