import React from 'react';
import './Student.css';

import {useAppContext} from "../lib/context"
import {AppliedSiteList} from "./components/AppliedSiteList";
import {NotAppliedSiteList} from "./components/NotAppliedSiteList";
import {Box, Typography} from "@mui/material";
import {RichElement} from "../components/customTable/RichElement";
import {Client, ClientSettingsOps} from "../client/model/Client";
import {olderDate} from "../lib/util";
import {useAtomValue} from "jotai";
import {clientAtom} from "../Atoms";
import {
    EmptyObject,
    StudentLoggedInComponent,
    StudentLoggedInComponentWrapper,
    StudentLoggedInContextProps,
} from "../components/LoggedInPage";
import SpinnerCard from "../components/SpinnerCard";
import {StudentOps} from "./model/Student";
import {QuestionnaireStatusMessage} from "./QuestionnaireStatusMessage";
import {I18Typography} from "../components/I18Typography";

const SitesWrapper: StudentLoggedInComponentWrapper = (context) => {
        return context.state === "ready" ? <Sites {...context}/> :
            <SpinnerCard message={`Loading...`}/>
}
const Sites: StudentLoggedInComponent<EmptyObject> = (context: StudentLoggedInContextProps) => {
    const { isDebug} = useAppContext();

    const client = useAtomValue(clientAtom) as Client
    const userInformation = context.userInformation

    const hasSites = userInformation?.student?.sites && userInformation?.student?.sites.length > 0

    isDebug && console.log("Site list lock date", userInformation?.student?.program?.sitelistLockDate)

    const locked = client.settings.cycle.matchType !== "manual" && ((userInformation?.student && ClientSettingsOps.siteListLocked(userInformation)) || ClientSettingsOps.rankListLocked(client.settings))

    const lockedDate = olderDate(client?.settings.rankListLockDate, userInformation?.student?.program?.sitelistLockDate)

    return <>
        <Box className="mb-3">
            {locked ?
                <I18Typography className="stop m-2" msgId="sitesAndTracks.blurbs.locked" params={{date: lockedDate, ...client.settings}}/> :
                userInformation?.student?.program?.sitelistLockDate ?
                    <I18Typography className="m-2" msgId="sitesAndTracks.blurbs.hasSiteListLockedDate" params={{date: userInformation?.student?.program?.sitelistLockDate}}/>
                    : <></>
            }

            {client.settings.clientCycleSettings.applicationSuiteSettings.useApplicationSuite && (ClientSettingsOps.isInSubmissionWindow(client.settings) ?
                    <Typography className="m-2">
                        You may submit applications until <RichElement value={client.settings.clientCycleSettings.applicationSuiteSettings.applicationSubmissionClosedTimestamp}/>
                    </Typography> :
                    StudentOps.studentApplicationsSubmittedCount(userInformation) === 0 ?
                            <Typography className="m-2">
                                You did not submit applications for the match. If you wish to participate in
                                Clearinghouse,
                                you may prepare your application materials now. You will be able to create application
                                packets
                                for submission once Clearinghouse begins on <RichElement
                                value={client.settings.clearingHouseDate}/>.
                            </Typography>
                            : <Typography className="m-2">
                            The Application Deadline was <RichElement value={client.settings.clientCycleSettings.applicationSuiteSettings.applicationSubmissionClosedTimestamp}/>
                            Tracks you applied to may be added to your rank list, which you may edit at any time until the Ranking Deadline,
                            <RichElement value={client.settings.cycle.rankListLockDate}/>.
                            If you no longer wish to participate in the match, you MUST withdraw.
                            </Typography>

            )
            }

            {ClientSettingsOps.isInSubmissionWindow(client.settings) ?
                <>
                    <I18Typography className="m-2" msgId="applications.inSubmissionWindow.pageBlurb" params={client.settings}/>

                    {client.settings.clientCycleSettings.applicationSuiteSettings.useApplicationSuite ?
                        <I18Typography className="m-2" msgId="applications.submissionCountMessage" params={{
                            count: StudentOps.studentApplicationsSubmittedCount(userInformation),
                            remaining: (client.settings.clientCycleSettings.applicationSuiteSettings.packetsPerSubmission - StudentOps.studentApplicationsSubmittedCount(userInformation))
                        }}/>
                        : <I18Typography className="m-2" msgId="applications.trackRankCountMessage" params={{
                                count: StudentOps.rankedCount(client, userInformation),
                                remaining: StudentOps.remainingCount(client, userInformation) || 10
                            }}/>
                    }
                </>
                :
                <I18Typography className="m-2" msgId="applications.inRankingWindow.pageBlurb" params={client.settings}/>
            }
        </Box>
        {hasSites &&
            <>
                <Box>
                    {client?.settings.useApplicationLimit === "yes" ?
                        <I18Typography variant="h5" msgId="sitesAndTracks.selectedSitesWithAppLimit" params={{
                            selectedCount: userInformation?.student?.sites.length || 0,
                            available: userInformation?.student?.applicationLimit || 0
                        }}/> : <></>
                    }
                </Box>
                <AppliedSiteList locked={locked} userInformation={userInformation}/>
            </>
        }
        {client.settings.clientCycleSettings.applicationSuiteSettings.useApplicationSuite &&
            <QuestionnaireStatusMessage userInformation={userInformation}/>
        }
        
        <I18Typography variant="h5" msgId="sitesAndTracks.unselectedSites" params={client.settings}/>
        <NotAppliedSiteList userInformation={userInformation}/>
    </>
}

export default SitesWrapper
