import React, {Dispatch, PropsWithChildren, SetStateAction, useRef, useState} from 'react'
import {useAppContext} from "../lib/context";
import TableContainer from "@mui/material/TableContainer";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {QuestionnaireElement, QuestionnaireElementId, QuestionnaireId} from "./model/Questionnaire";
import {Link} from "react-router-dom";
import {TableColumn} from "../components/customTable/CustomTable";
import EditIcon from "@mui/icons-material/Edit";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {KeyboardArrowDown} from "@mui/icons-material";
import {ElementPosition as EditElementPosition} from "./component/edit/ElementPosition";
import {ElementLabel as EditElementLabel} from "./component/edit/ElementLabel";
import {ElementContent as EditElementContent} from "./component/edit/ElementContent";
import {ElementOptions} from "./component/edit/ElementOptions";
import {ElementType as EditElementType} from "./component/edit/ElementType";
import {ElementType as DisplayElementType} from "./component/display/ElementType";
import {ElementLabel as DisplayElementLabel} from "./component/display/ElementLabel";
import {ElementPosition as DisplayElementPosition} from "./component/display/ElementPosition";
import {ElementForStudent} from "./component/edit/ElementForStudent";
import {QuestionnaireElementProps} from "./model/QuestionnaireList";
import {Grid, IconButton, Tooltip, Typography} from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import {API, api, apiError} from "../lib/Api";
import {Spinner} from "../components/SpinnerCard";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import {confirmDeleteModal, questionModal} from "../components/modal/InfoModal";
import {ElementSearchable} from "./component/edit/ElementSearchable";
import {ElementRequired} from "./component/edit/ElementRequired";
import {ElementHelpText} from "./component/edit/ElementHelpText";
import {formDataAsMapFromRef} from "../lib/Forms";
import {isDefined, lookup} from "../lib/util";
import ElementDefaultAnswer from "./component/edit/ElementDefaultAnswer";
import {QuestionnaireInputProps} from "./QuestionnaireEdit";
import {RichElement} from "../components/customTable/RichElement";
import {isEmpty, isPresent} from "../components/customTable/util";
import {ElementExportable} from "./component/edit/ElementExportable";
import {makeStyles} from "tss-react/mui";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../Atoms";
import {clientAtom} from "../Atoms";
import {DialogType, ModalAction} from "../components/modal/ModalProps";
import {ElementParameters} from "./component/edit/ElementParameters";
import {useMutation} from "@tanstack/react-query";
import {Client} from "../client/model/Client";

type QuestionnaireListProps = {
    questionnaireId: QuestionnaireId
    questionnaire: QuestionnaireElement[] | undefined
    moveUp: (id: QuestionnaireElementId) => void
    moveDown: (id: QuestionnaireElementId) => void
    moveToTop: (id: QuestionnaireElementId) => void
    moveToBottom: (id: QuestionnaireElementId) => void
    rowAction: (rowAction: RowAction, oldElement: QuestionnaireElement | null, newElement: QuestionnaireElement | null) => void,
}

export enum RowAction {
    CREATE, UPDATE, DELETE, MOVE
}

type QuestionnaireRowProps = {
    row: QuestionnaireElement
    columns: TableColumn<QuestionnaireElement>[]
    moveUp: (id: QuestionnaireElementId) => void
    moveDown: (id: QuestionnaireElementId) => void
    moveToTop: (id: QuestionnaireElementId) => void
    moveToBottom: (id: QuestionnaireElementId) => void
    rowAction: (action: RowAction, oldElem: QuestionnaireElement | null, newElem: QuestionnaireElement | null) => void
}

export const useStyles = makeStyles()((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: "calc(100% - 16px)",
        maxWidth: "calc(100% - 16px)",
        position: "relative"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

export const answerFor: (props: QuestionnaireInputProps) => string | undefined = (props: QuestionnaireInputProps) => {
    if (isDefined(props.answer?.answer)) {
        return props.answer?.answer
    }
    else if (isPresent(props.question, "defaultAnswer")) {
        return props.question.defaultAnswer
    }
    else {
        return undefined
    }
}

const QuestionnaireList = (props: QuestionnaireListProps) => {
    const {isDebug} = useAppContext()

    const {questionnaire, moveToTop, moveUp, moveDown, moveToBottom} = props

    const questionnaireColumns: TableColumn<QuestionnaireElement>[] = [
        {title: 'ID', field: 'id', width: 30},
        {title: 'Order', field: 'position', width: 50},
        {title: 'Question', field: 'questionnaireText'},
        {title: 'Help Text', field: 'helpText'},
        {title: 'Options', field: 'options'},
        {title: 'For Student', field: 'studentVisible', width: 50},
        {title: 'Searchable', field: 'isSearchable', width: 50},
        {title: 'Required', field: 'isRequired', width: 50},
        {title: 'Exportable', field: 'isExportable', width: 50},
        {title: 'Type', field: 'elementType.elementType', width: 150},
        {title: 'Params', field: 'elementType.parameters', width: 150}
    ]

    //console.log(questionnaireId, questionnaire)

    /*
    const handleAddRow = (e: React.MouseEvent<HTMLAnchorElement>, id: QuestionnaireId) => {
        e.preventDefault()
        addRow(id, 0)
    }

     */

    const rowAction = (action: RowAction, oldElement: QuestionnaireElement | null, newElement: QuestionnaireElement | null) => {
        isDebug && console.log("Questionnaire Edit: rowAction", rowAction, oldElement, newElement)
        switch(action) {
            default:  // By Default, just bubble up
                props.rowAction(action, oldElement, newElement)
                break;
        }
    }

    return (
        <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell>{"Actions"}</TableCell>
                        {questionnaireColumns.map((column, index) => {
                            return (<TableCell key={index} width={column.width ? column.width : ""}>{column.title}</TableCell>)
                        })}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {questionnaire && questionnaire.length === 0  &&
                        <TableCell colSpan={8}>
                            <Tooltip title="Create New Question">
                                <IconButton
                                    onClick={(e) => rowAction(RowAction.CREATE, null, null)}
                                    aria-label="Add New Row"
                                    size="large"><AddCircleIcon className="addIcon"/></IconButton>
                            </Tooltip>
                        </TableCell>
                    }
                    {questionnaire && questionnaire.map(element => {
                       return (
                        <QuestionnaireRow row={element} columns={questionnaireColumns}
                                         moveUp={moveUp}
                                         moveDown={moveDown}
                                         moveToTop={moveToTop}
                                         moveToBottom={moveToBottom}
                                         rowAction={rowAction}
                                         key={`${element.position}-${element.id}`}
                        />
                       )}
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    );

}

const QuestionGridLabel = (props: PropsWithChildren<any>) => (
    <Grid container item alignItems="center" justifyContent="flex-end" xs={2}>
        {props.children}
    </Grid>
)

interface EditRowProps  {
    element: QuestionnaireElement,
    localProps: QuestionnaireElementProps
    submitForm: (forceNew: boolean) => void
    formRef:  React.RefObject<HTMLFormElement>
    handleEdit: (e: React.MouseEvent, x: boolean) => void
}

const EditRow = ({element, localProps, submitForm, formRef, handleEdit}: EditRowProps) => {
    const {setModal, isDebug} = useAppContext()

    isDebug && console.log("EditRow", element)

    // Put this in here because the Edit components have a callback to the parent component to setState on the form content - this might be stupid
    const [elementOriginalState, setElementOriginalState] = useState<QuestionnaireElement>(element)

    const setElement: Dispatch<SetStateAction<QuestionnaireElement>> = (updater: SetStateAction<QuestionnaireElement>) => {
        const execUpdater = (elem: QuestionnaireElement, updater: SetStateAction<QuestionnaireElement>) => {
            if (typeof (updater) === "function") {
                return updater(elem)
            } else {
                return updater
            }
        }

        const r = execUpdater(element, updater)

        // Don't make the mistake of calling setOriginalElement here - we need the original state so we can test if it's label changed, and we need to make a new question.
        localProps.setElement && localProps.setElement(r)
    }


    const passProps: QuestionnaireElementProps = {...localProps, setElement: setElement}

    const localSubmitForm = () => {
        const data = formDataAsMapFromRef(formRef)

        const t = lookup(data, "isMinorEdit")

        isDebug && console.log("Form data",data)

        isDebug && console.log("T", isEmpty(t), elementOriginalState.question?.label, data.label)

        if (isEmpty(t) && elementOriginalState.question?.label && data.label !== elementOriginalState.question?.label && element.id > 0) {
            isDebug && console.log("Opening Modal")
            setModal && setModal(questionModal(null, "Confirm Question Change",
                "By entering new text, you are implicitly creating a new question, which will cause disassociation of all current answers. If this is what you want, click 'Confirm', otherwise go back and check the 'Minor edit' option.", DialogType.Confirm, (action) => {
                if (action === ModalAction.Confirm) {
                    isDebug && console.log("Called close")
                    submitForm(true)
                    setElementOriginalState(element)
                }
            }))
        }
        else {
            isDebug && console.log("Called submit")
            submitForm(false)
            setElementOriginalState(element)
        }
    }

    return (
        <TableRow key={element.id}>
            <TableCell>
                    <span>
                    <IconButton
                        aria-label="Save Changes"
                        component="span"
                        onClick={localSubmitForm}
                        size="large">
                        <CheckCircleIcon className="go"/>
                    </IconButton>
                    <IconButton
                        aria-label="Cancel Changes"
                        component="span"
                        onClick={(e: any) => handleEdit(e, false)}
                        size="large">
                        <CancelIcon className="stop"/>
                    </IconButton>
                </span>
            </TableCell>
            <TableCell>{element.id}</TableCell>
            <TableCell colSpan={8}>
                <form ref={formRef}>
                <Grid container spacing={1} alignItems="center" justifyContent="center">
                    <QuestionGridLabel>
                        <Typography>Position</Typography>
                    </QuestionGridLabel>
                    <Grid item xs={10}><EditElementPosition {...passProps}/></Grid>

                    <QuestionGridLabel>Element Type</QuestionGridLabel>
                    <Grid item xs={10}><EditElementType {...passProps}/></Grid>

                    <React.Fragment>
                        <QuestionGridLabel>Question</QuestionGridLabel>
                        <Grid item xs={10}>
                            {element.elementType.type === "Question" ?
                            (<EditElementLabel {...passProps}/>) :
                            (<EditElementContent {...passProps}/>)
                            }
                        </Grid>
                    </React.Fragment>

                    <QuestionGridLabel>Default Answer</QuestionGridLabel>
                    <Grid item xs={10}><ElementDefaultAnswer {...passProps}/></Grid>

                    {element.elementType.type === "Question" && (<React.Fragment>
                            <QuestionGridLabel>Help Text</QuestionGridLabel>
                            <Grid item xs={10}><ElementHelpText {...passProps}/></Grid>
                        </React.Fragment>
                    )}

                    <QuestionGridLabel>Options</QuestionGridLabel>
                    <Grid item xs={10}><ElementOptions {...passProps}/></Grid>

                    <QuestionGridLabel>Student Visible</QuestionGridLabel>
                    <Grid item xs={10}><ElementForStudent {...passProps}/></Grid>

                    {element.elementType.type === "Question" && (
                        <React.Fragment>
                            <QuestionGridLabel>Searchable</QuestionGridLabel>
                            <Grid item xs={10}><ElementSearchable {...passProps}/></Grid>

                            <QuestionGridLabel>Required</QuestionGridLabel>
                            <Grid item xs={10}><ElementRequired {...passProps}/></Grid>

                            <QuestionGridLabel>Exportable</QuestionGridLabel>
                            <Grid item xs={10}><ElementExportable {...passProps}/></Grid>
                        </React.Fragment>
                    )}

                    <QuestionGridLabel>Parameters</QuestionGridLabel>
                    <Grid item xs={10}>
                        <ElementParameters {...passProps}/>
                    </Grid>
                </Grid>
                </form>
            </TableCell>
        </TableRow>
    );
}

const QuestionnaireRow = ({row, moveUp, moveDown, moveToTop, moveToBottom, rowAction}: QuestionnaireRowProps) => {
    const {isDebug, setModal} = useAppContext()

    isDebug && console.log("Questionnaire row", row)

    const userInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client

    const localClientId = client.id

    const [edit, setEdit] = useState<boolean>(false)
    const [saving, setSaving] = useState<boolean>(false)
    const [element, setElement] = useState<QuestionnaireElement>(row)
    const [oldElement, setOldElement] = useState<QuestionnaireElement>(row)

    const formRef = useRef<HTMLFormElement>(null)

    const elementMutate = useMutation({
        mutationFn: (props: {element: QuestionnaireElement, forceNew: boolean}) =>
            api(API.updateQuestionnaireElement(element.questionnaireId, element.id, props.forceNew), userInformation, localClientId || 0, props.element)
    })

    const submitForm = (forceNew: boolean) => {
        const data = formDataAsMapFromRef(formRef)
        setSaving(true)
        isDebug && console.log("Saving to ", localClientId)
        isDebug && console.log("Data is", data)
        isDebug && console.log("Element is", element)
        elementMutate.mutateAsync({element, forceNew}).then(() => {
            setEdit(false);
            setSaving(false);
            setElement(elem => {return {
                ...elem, isNew: false
            }})
            rowAction(RowAction.UPDATE, oldElement, element)
        }).catch((x) => {
            apiError(x, setModal)
        })
    }

    const handleEdit = (e: React.MouseEvent, x: boolean) => {
        isDebug && console.log("editing", row)
        setOldElement(row)
        e.preventDefault()
        setEdit(x)
        //updateRow(element)
    }

    const handleAddRow = (e: React.MouseEvent, element: QuestionnaireElement) => {
        e.preventDefault()
        rowAction(RowAction.CREATE, row, null)
    }

    const confirmDeleteRow = (e: React.MouseEvent, element: QuestionnaireElement) => {
        e.preventDefault()
        localClientId && api(API.deleteQuestionnaireElement(element.questionnaireId, element.id), userInformation, localClientId, element).then(() => {
            // Force reload
            rowAction(RowAction.DELETE, element, null)
        })
    }

    const handleDeleteRow = (e: React.MouseEvent, element: QuestionnaireElement) => {
        e.preventDefault()
        setModal && setModal(confirmDeleteModal(element, (actionType: string) => {
                    if (actionType === "delete") confirmDeleteRow(e, element)
                }, "Are you sure you want to this element"))
    }

    const localProps: QuestionnaireElementProps = {
        row: element,
        setElement: setElement
    }

    if (saving) return (
        <TableRow key={element.id}>
            <TableCell colSpan={6}>
                <Spinner/>
            </TableCell>
        </TableRow>
    )

    return (edit || element.isNew) ? (<EditRow element={element} localProps={localProps} formRef={formRef} handleEdit={handleEdit} submitForm={submitForm}/>): (
        <TableRow key={element.id}>
            <TableCell style={{"width": "100px", "whiteSpace": "nowrap"}}>
                <Tooltip title="Move Up">
                    <KeyboardArrowUpIcon onClick={() => moveUp(element.id)} aria-label="Move Up"/>
                </Tooltip>
                <Tooltip title="Move to the top of the list">
                    <DoubleArrowIcon onClick={() => moveToTop(element.id)} style={{transform: "rotate(-90deg)"}} aria-label="Move To Top"/>
                </Tooltip>
                <Tooltip title="Move to the bottom of the list">
                    <DoubleArrowIcon onClick={() => moveToBottom(element.id)} style={{transform: "rotate(90deg)"}} aria-label="Move To Bottom"/>
                </Tooltip>
                <Tooltip title="Move down">
                    <KeyboardArrowDown onClick={() => moveDown(element.id)} aria-label="Move Down"/>
                </Tooltip>
                <Tooltip title="Edit this questionnaire element">
                    <Link to="" onClick={(e) => handleEdit(e, true)} aria-label="Edit"><EditIcon className="editIcon"/></Link>
                </Tooltip>
                <Tooltip title="Add a new question after this one">
                    <Link to="" onClick={(e) => handleAddRow(e, element)} aria-label="Add"><AddCircleIcon className="addIcon"/></Link>
                </Tooltip>
                <Tooltip title="Delete this questionnaire element">
                    <Link to="" onClick={(e) => handleDeleteRow(e, element)} aria-label="Delete"><DeleteOutlineIcon className="deleteIcon"/></Link>
                </Tooltip>
            </TableCell>
            <TableCell aria-label="element ID">{element.id}</TableCell>
            <TableCell aria-label="element position"><DisplayElementPosition row={element}/></TableCell>
            <TableCell aria-label="element label"><DisplayElementLabel row={element}/></TableCell>
            <TableCell aria-label="question help text">{element.question && element.question?.helpText}</TableCell>
            <TableCell>
                <div style={{maxWidth: "200px"}}>
                {(element.question?.type?.questionType === "Select" || element.question?.type?.questionType === "Multi-Select") && element.question?.options?.join(", ")}
                </div>
            </TableCell>
            <TableCell><RichElement value={element.studentVisible}/></TableCell>
            <TableCell>{element.question && <RichElement value={element.question?.isSearchable}/>}</TableCell>
            <TableCell>{element.question && <RichElement value={element.question?.isRequired}/>}</TableCell>
            <TableCell>{element.question && <RichElement value={element.question?.isExportable}/>}</TableCell>
            <TableCell><DisplayElementType row={element}/></TableCell>
            <TableCell><RichElement value={element.parameters}/></TableCell>
        </TableRow>
    )
}

export default QuestionnaireList