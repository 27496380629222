import {Client, ClientSettings} from "../client/model/Client";
import {AdminUserInformation} from "../login/model/UserInformation";
import {DialogType, ModalProps} from "../components/modal/ModalProps";
import {LoginState} from "../login/lib/UserSessionsService";

export const UserGroupsSet = [
    "client-admins",
    "directory-admins",
    //"directory-viewers",
    "global-admins",
    "program-admins",
    "site-admins",
    //"students",
]

export const ClientAdminUserGroupsSet = [
    "program-admins",
    "site-admins",
    //"students"
]

export const ProgramAdminUserGroupsSet = [
    //"directory-viewers",
    //"students"
]

export const initialModal: ModalProps = {
    isOpen: false,
    title: "Title",
    message: "A Message",
    dialogType: DialogType.Message
}

export const initialClientSettings: ClientSettings = {
    siteName: "",
    clientName: "",
    clientUrl: "https://about.practicumfit.com",
    clientSupportEmail: "None",
    fromEmail: "None",
    inviteSubject: "Invite",
    directory: "enabled",
    showAttestationFiledFlag: "yes",
    showDuesPaidFlag: "yes",
    useTrackType: "yes",
    studentCost: "0",
    paywallLocation: "",
    useSiteListLockDate: "no",
    useApplicationLimit: "yes",
    attestationAtRanking: "no",
    defaultApplicationLimit: "10",
    blurbs: {},
    clearingHouseLabel: "Clearing House",
    clientAdminCanPreviewMatch: "yes",
    cycle: {
        id: 0,
        clientId: 0,
        cycleStart: new Date(),
        cycleEnd: new Date(),
        cycleName: "<Unknown>",
        matchType: "auto",
    },
    isLoaded: false,
    clientCycleSettings: {
        clientId: 0,
        clientCycleId: 0,
        cycleName: "",
        applicationSuiteSettings: {
            useApplicationSuite: false,
            useProgramApprovalProcess: false,
            applyTo: "Site",
            applicationSubmissionOpensTimestamp: new Date(),
            applicationSubmissionClosedTimestamp: new Date(),
            packetsPerSubmission: 1,
            materialSlotsDefinition: {
                clientId: 0,
                clientCycleId: 0,
                materialsApprovalQuestionnaireId: 0,
                studentQuestionnaireId: 0,
                slotDefinitions: []
            },
            packetSlotsDefinition: {
                clientId: 0,
                clientCycleId: 0,
                slotDefinitions: []
            }
        },
        dateSettings: {
            cycleStart: new Date(),
            cycleEnd: new Date(),
        },
    },
    paymentDescription: ""
}

export const initialClient: () => Client = () => ({
    id: 0,
    name: "",
    settings: initialClientSettings,
    cycles: []
})

export const initialLoginState = () => LoginState.InitialState


export const emptyUserInformation = new AdminUserInformation({email: ""})

export const initialUserInformation = () => emptyUserInformation
