import React, {useState} from "react"
import {QuestionnaireElementProps} from "../../model/QuestionnaireList";
import {Checkbox} from "@mui/material";

export const ElementRequired = (props: QuestionnaireElementProps) => {

    const [checked, setChecked] = useState<boolean>(props.row.question?.isRequired || false)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const x = event.target.checked

        setChecked(x);
        props.setElement && props.setElement(elem => elem.question ? {
                ...elem,
                question: {
                    ...elem.question,
                    isRequired: x
                },
        } : elem)
    };

    return props.row.question ? (
        <Checkbox
            name="isRequired"
            checked={checked}
            onChange={handleChange}
            inputProps={{ 'aria-label': 'is question required' }}
        />
    ) : (<React.Fragment/>)
}