import React, {Dispatch, SetStateAction, useEffect, useMemo, useRef, useState} from 'react'
import {
    Question,
    QuestionId,
    QuestionnaireElement,
    QuestionnaireElementQuestion,
    QuestionTypeId
} from "../../model/Questionnaire";
import {
    Box,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select, Typography, Theme, Paper, FormControlLabel, Checkbox, SelectChangeEvent
} from "@mui/material";
import {Questionnaire} from "../../../model/Questionnaire";
import {RemoveCircle} from "@mui/icons-material";
import {useAppContext} from "../../../lib/context";
import {AddSelect} from "./create/AddSelect";
import {SearchInput, SearchInputProps} from "./criteria/SearchInput";
import Groups from "../../../model/Groups";
import {requireOneOfGroup} from "../../../lib/util";
import {Client, ClientSettingsOps} from "../../../client/model/Client";
import {makeStyles} from "tss-react/mui";
import useUserReadyService from "../../../user/lib/UserReadyService";
import {useAtomValue} from "jotai";
import {userInformationAtom} from "../../../Atoms";
import {clientAtom} from "../../../Atoms";

export interface SearchInputDataElement {
    key: string
    value: string
}

export interface SearchInputData {
    search: SearchInputDataElement[]
    query?: string
    openingsOnly: boolean
}

export interface SearchComponentProps {
    questionnaire: Questionnaire
    setSearchInputData: Dispatch<SetStateAction<SearchInputData>>
}

export interface InputSearchProps {
    searchKey: SearchKey
    value?: string
    setSearchInputData: Dispatch<SetStateAction<SearchInputData>>
}

export const useStyles = makeStyles()((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: "250px"
    },
    root: {
        minWidth: 275,
    },
    form: {
        padding: 0,
        margin: 0
    },
    bullet: {
        display: 'inline-block',
        margin: '0 2px',
        transform: 'scale(0.8)',
    },
    title: {
        fontSize: 14,
    },
    pos: {
        marginBottom: 12,
    },
    searchInput: {
        border: "1px solid rgba(0,0,0,0.5)"
    }
}));

const useGridStyles = makeStyles()((theme: Theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(1),
        color: theme.palette.text.secondary,
    }
}))

export interface SearchKey { // TODO should this just be a quesitonnaireElement?
    name: string
    question: Question
    typeId: QuestionTypeId
    studentVisible: boolean
}

export const keysFromQuestionnaire = (questionnaire: QuestionnaireElement[]) => {
    const k: QuestionnaireElementQuestion[] = questionnaire
        .filter(x => x.question && x.question.label) as QuestionnaireElementQuestion[]

    return k.filter(x => x as QuestionnaireElementQuestion)
        .filter(x => x.question.isSearchable)
        .map(x => ({name: x.question.label, question: x.question, typeId: x.question.type.id, studentVisible: x.studentVisible} as SearchKey))
}

interface KeySelectProps {
    keys: SearchKey[]
    // TODO figure out how to mock a dispatcher
    setKey: Dispatch<SetStateAction<SearchKey | undefined>>
    key?: number | undefined
}

export const KeySelect = (props: KeySelectProps) => {
    const userInformation = useAtomValue(userInformationAtom)

    const userReady = useUserReadyService()

    const [key, setKey] = useState<number | undefined>();

    useEffect(() => {
        setKey(props.key)
    }, [setKey, props.key])

    const {classes} = useStyles()

    const handleChange = (event: SelectChangeEvent<unknown>) => {
        const value: number = event.target.value as number
        setKey(value)

        props.setKey && props.setKey(props.keys.find(x => x.question.id === value))
    }

    if (userReady && userInformation && userInformation.primaryGroup()) {
        const searchKeys = requireOneOfGroup(userInformation.userGroups, [Groups.directoryViewer, Groups.student]) ?
            props.keys.filter(x => x.studentVisible) : props.keys

        return (
            <FormControl variant="filled" className={classes.formControl} size="small">
                <InputLabel id="user-programs-label">Search Key</InputLabel>
                <Select name="newKey"
                        value={key}
                        aria-label="Search Key"
                        onChange={handleChange}>
                    {searchKeys.map(e => (
                        <MenuItem key={e.question.id} value={e.question.id}>{e.name}</MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    } else {
        return (<React.Fragment/>)
    } // TODO Maybe something more here than nothing?
}

export interface SearchKeyForm {
    newKey: string
    value: string
}

interface SearchComponentRef {
    questionId: QuestionId
    element: JSX.Element
}

export const SearchComponent = (props: SearchComponentProps) => {
    const {isDebug} = useAppContext()

    const client = useAtomValue(clientAtom) as Client

    const [components, setComponents] = useState<SearchComponentRef[]>([])
    const [openingsOnly, setOpeningsOnly] = useState<boolean>(false)

    const formRef = useRef<HTMLFormElement>(null)

    /*
    useEffect(() => {
        const formData = formRef && formListDataAsMapFromRef(formRef)
        console.log("Effecting Search Component", formData, formData["value"] && formData["value"].length)
        formData && formData.value && formData.value.length > 0 && props.runSearch(formData)
    }, [components, formRef])

     */

    isDebug && console.log("Search component", props.questionnaire, components)

    const searchKeys: SearchKey[] = useMemo(() => keysFromQuestionnaire(props.questionnaire.elements), [props.questionnaire])

    const handleRemove = (e: React.MouseEvent, searchKey: SearchKey) => {
        setComponents(prev => {
            isDebug && console.log("Removing", prev.findIndex(x => x.questionId === searchKey.question.id))
            const n = [...(prev.filter(x => x.questionId !== searchKey.question.id))]
            isDebug && console.log(n)
            return n;
        })
        props.setSearchInputData(prev => ({
            search: [...(prev.search.filter(x => x.key !== searchKey.name))],
            query: prev.query,
            openingsOnly: openingsOnly
        }))
    }

    const createKey = (props: SearchInputProps) => {
        isDebug && console.log("Creating key", props)

        const newElem: JSX.Element = (<Box>
            <SearchInput {...props}/>
            <IconButton
                onClick={(e: React.MouseEvent) => handleRemove(e, props.searchKey)}
                style={{paddingTop: "20px", paddingLeft: "5px"}}
                size="large">
                <RemoveCircle/>
            </IconButton>
        </Box>)

        setComponents(prev => [...(prev.filter(x => x.questionId !== props.searchKey.question.id)), {
            questionId: props.searchKey.question.id,
            element: newElem
        }])
    }

    const handleOpeningsOnly = () => {
        setOpeningsOnly(!openingsOnly)
        props.setSearchInputData(prev => ({...prev, openingsOnly: !openingsOnly}))
    }

    const {classes} = useStyles()
    const gridClasses = useGridStyles().classes

    isDebug && console.log(components)

    return (
        <Box display="flex" flexWrap="wrap" p={1} m={1} bgcolor="background.paper">
            <Box p={1} bgcolor="grey.300">
                <Paper className={gridClasses.paper}>
                    <Typography className={classes.title}>Add Search Parameters</Typography>
                    <AddSelect searchKeys={searchKeys} createKey={createKey}
                               setSearchInputData={props.setSearchInputData}/>
                    {(!ClientSettingsOps.matchDatePassed(client.settings) || ClientSettingsOps.clearingHousePassed(client?.settings)) && <>
                        <FormControlLabel control={<Checkbox checked={openingsOnly} onChange={handleOpeningsOnly}
                                                             name="checkbox-openingsonly"/>} label="Include Only Tracks with Openings"/>
                        <input type="hidden" value={openingsOnly ? "yes" : "no"} name="checkbox-openingsonly"/>
                    </>}
                </Paper>
            </Box>
            <Box p={1} bgcolor="grey.300" flexGrow={1}>
                <Paper className={gridClasses.paper}>
                    <Typography className={classes.title}>Current Search Parameters</Typography>
                    <form ref={formRef} className={classes.form}>
                        <Box p={0} display="flex" flexWrap="wrap">
                            {components.map(x =>
                                <Box p={0}>
                                    {x.element}
                                </Box>
                            )}
                        </Box>
                    </form>
                </Paper>
            </Box>
        </Box>
    )
}