import React, {CSSProperties, useCallback, useState} from "react";
import {Link} from "react-router-dom";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import {Card} from "../Card";
import {lookup} from "../../lib/util";
import {Column} from "@material-table/core";
import {IconButton} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import {useDebounce} from "../../lib/useDebounce";
import {RichElement} from "./RichElement";
import {SearchInput} from "../SearchInput";
import {isPresent} from "./util";
import {useDataView} from "./DataView";

export interface EditableProps<T> {
    onRowAdd: (newData: T) => Promise<T>
    onRowUpdate: (oldData: T, newData: T) => Promise<T>
    onRowDelete: (oldData: T) => Promise<void>
}

export interface CustomTableProps<T extends Object> {
    items: Array<T>,
    itemToRowMapping: (t: T) => any,
    title: string,
    columns: Array<TableColumn<T>>
    editable: EditableProps<T>
}

export interface TableColumn<T extends Object> extends Column<T> {
    title: string
    field: string
    colSpan?: number
    comparator?: (a: T, b: T) => number
    required?: boolean
    validator?: (v: any) => string | undefined
    render?: (row: T) => React.ReactNode
    width?: number
    cellStyle?: CSSProperties
}

export function applyColumn<T extends Object>(t: TableColumn<T>, row: T, datum: string): React.ReactNode {
    return (t.render && t.render(row)) || <RichElement value={datum}/>
}

interface ColumnReprProps<T extends Object> {
    column: TableColumn<T>
    row: T,
    className?: string
}

export function ColumnRepr<T extends Object>(props: ColumnReprProps<T>) {
    const {column, row, className} = props
    return (column.render && <>{column.render(row)}</>) || <RichElement value={lookup(row, column.field)} className={className}/>
}

/*
export function ColumnRepr<T extends Object>({column,row,className}: ColumnReprProps<T>): React.FC<ColumnReprProps<T>> {
    const rendered: React.ReactNode | undefined =

    return (<>{rendered ||
}

 */

export function dataValidate<T extends Object>(columns: Array<TableColumn<T>>, data: any) {
    //console.log("Asked to validate", data, columns);
    return (failure: (err: Map<string, string>) => void, success: () => void) => {
        const m = new Map<string, string>(
            columns
                .filter((x: TableColumn<T>) => (x.required && !isPresent(data, x.field)))
                .map(x => [x.field, x.title + " is required"])
        )
        columns.filter((x: TableColumn<T>) => (x.validator && x.validator(lookup(data,x.field))))
            .map(x => [x.field, x.validator && x.validator(lookup(data,x.field))] as [string, string | undefined])
            .forEach(x => x[1] && m.set(x[0], x[1]))
        // TODO apply custom validator
        // filter((x: TableColumn<T>) => (!x.validator || !x.validator(data[x.field])))
        if (m.size === 0) success()
        else failure(m)
    }
}

function CustomTable<T extends Object>(props:CustomTableProps<T>) {
    const [searchFilter, setSearchFilter] = useState<SearchFilter>(() => noSearch)

    const rowDelete = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>, elem: T) => {
        props.editable.onRowDelete && props.editable.onRowDelete(elem)
    }

    const debouncedSearch = useDebounce(useCallback((s: string) => {
        setSearchFilter(() => stringSearcher(s))
    }, [setSearchFilter]), 500)

    const { items } = useDataView(props.items, null, searchFilter);

    const localItems = items.map(row => ({
        row: row,
        columns: props.columns.map(col => {
            const datum = lookup(row, col.field)
            return {
                column: col,
                datum: datum,
                element: applyColumn(col, row, datum)
            }
        })
    }))

    return (
        <Card title={`${props.title}`}>
            <div style={{marginBottom:"20px"}}><Link to={`/program/0`} >Create New {props.title}</Link></div>
            <SearchInput searchFunction={debouncedSearch}></SearchInput>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            {props.columns.map((column) => {
                                return (<TableCell>{column.title}</TableCell>)
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {localItems.map((elem) => (
                            <TableRow>
                                <TableCell>
                                    <IconButton onClick={e => rowDelete(e, elem.row)} size="large">
                                        <DeleteIcon/>
                                    </IconButton>
                                </TableCell>
                                {elem.columns.map((column) => (
                                    <TableCell>
                                        {/* Consider using ColumnRepr */}
                                        {applyColumn(column.column, elem.row, column.datum)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
}

export type SearchFilter = (str: string) => boolean

export function applySearchFilter<T extends Object>(data: Array<T>, searchFilter: SearchFilter | undefined) {
    const filtered = searchFilter ? [
    ...(data.filter(x => rowPredicate(x, searchFilter)))
    ] : data

    //console.log("Applied", data, filtered, searchFilter)
    return filtered
}

export function rowPredicate<T extends Object>(row: T, searchFilter: SearchFilter | undefined): boolean {
    return searchFilter ? Object.entries(row).map(z => z[1]).find(v => searchFilter(String(v))) !== undefined : true
}

export const stringSearcher: (str: string) => SearchFilter = (str) => (x: string) => {
    if (typeof(x) === "string") {
        return x.toLowerCase().includes(str.toLowerCase())
    } else {
        console.log("Got a weird x", x)
        return false
    }
}
export const noSearch: SearchFilter = (str: string) => true


export default CustomTable