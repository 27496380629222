import React from "react";
import {QuestionnaireElement, QuestionnaireTableElement} from "../../model/Questionnaire";
import {QuestionnaireSubmissionElement} from "../../model/QuestionnaireSubmissionElement";
import {filterTableRowLabel, questionnaireTableColumns} from "../QuestionnaireTableComponent";
import {RichElement} from "../../../components/customTable/RichElement";
import {mapByCount} from "../../../lib/util";
import {Box} from "@mui/material";
import MaterialTable from "@material-table/core";
import {makeStyles} from "tss-react/mui";

export const useStyles = makeStyles()((theme) => ({
    questionnaireTableContainer: {
        margin: "0.4rem",
        position: "relative",
        width: "100%"
    },
}));

export const QuestionnaireTableAnswers: React.FC<{table: QuestionnaireTableElement, columnHeadings: string[], columnCount: number}> = (props) => {
    const {classes} = useStyles()

    const columns = questionnaireTableColumns(props.columnHeadings)

    const makeRowQuestionnaireElements: any = (elems: QuestionnaireSubmissionElement[]) =>
        Object.fromEntries(
            elems.map((e, i) =>
                [("q" + i), (<RichElement value={e.answer?.answer}/>)]
            )
        )

    const makeRowObject = (elems: QuestionnaireElement[]) => {
        return ({
            title: filterTableRowLabel(elems[0].question?.label || ''), ...makeRowQuestionnaireElements(elems)
        })
    }

    const rowSize = props.columnCount
    const items = mapByCount(props.table.tableQuestions, rowSize, (elems) => makeRowObject(elems))

    return <Box className={`${classes.questionnaireTableContainer}`}>
        <MaterialTable data={items} columns={columns} title={props.table.content} options={{
            search: false,
            toolbar: false,
            paging: false,
        }}/>
    </Box>
}