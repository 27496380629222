import React, {ChangeEvent, useState} from "react";
import {QuestionnaireElementProps} from "../../model/QuestionnaireList";
import TextField from "@mui/material/TextField";
import {makeStyles} from "tss-react/mui";
import {Theme} from "@mui/material/styles";
import {questionnaireElementTableParametersValidator} from "../../model/Questionnaire";
import {useAppContext} from "../../../lib/context";

const useStyles = makeStyles()((theme: Theme) => ({
    formControl: {
        width: "100%",
        minWidth: "200px",
    },
}));

export const ElementParameters: React.FC<QuestionnaireElementProps> = (props: QuestionnaireElementProps) => {
    const {isDebug} = useAppContext()
    //const [parameters, setParameters] = React.useState(props.row.parameters)
    const [errorText, setErrorText] = useState('');

    const {classes} = useStyles()

    const onChangeHandler = (e: ChangeEvent<HTMLInputElement>) => {
        const x = e.target.value
        isDebug && console.log("Setting parameters to ", x)

        props.setElement && props.setElement(elem => {
            return {
                ...elem,
                parameters: x
            }
        })
    }

    const validate = (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const x = e.target.value
        isDebug && console.log("Validating parameters ", x)
        try {
            setErrorText('')
            questionnaireElementTableParametersValidator.parse(
                JSON.parse(x)
            )
            props.setElement && props.setElement(elem => {
                return {
                    ...elem,
                    parameters: x,
                    parsedParameters: JSON.parse(x)
                }
            })
        }
        catch (e) {
            isDebug && console.log("Error validating parameters ", e)
            setErrorText("Parameters must be a valid json object")
        }
    }

    return (
            <TextField className={classes.formControl}
                       id={`elementParameters${props.row.id}`}
                       variant="filled"
                       label="Parameters"
                       defaultValue={props.row.parameters}
                       name="parameters"
                       size="small"
                       multiline={true}
                       error={!!errorText}
                       helperText={errorText}
                       onChange={onChangeHandler}
                       onBlur={e => validate(e)}
            />
    )
}
