import React from "react";
import Drawer from "@mui/material/Drawer";
import {
    Box,
    ListItem,
    ListItemText, Typography,
} from "@mui/material";
import {Theme} from "@mui/material/styles";
import {head, requireOneOfGroup} from "../../lib/util";
import Groups from "../../model/Groups";
import useListQuestionnaireService from "../../lib/ListQuestionnaireService";
import {Questionnaire} from "../../model/Questionnaire";
import {useNavigate} from "react-router-dom";
import { makeStyles } from 'tss-react/mui';
import {userInformationAtom} from "../../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom} from "../../Atoms";
import {UserInformation} from "../../login/model/UserInformation";
import {useNavigationElements} from "../NavigationElements";
import {Client} from "../../client/model/Client";

interface NavDrawerProps {
    toggleDrawerHandler: (e: React.SyntheticEvent<HTMLElement>) => void
    open: boolean,
    activeName: string
}

const styles = makeStyles()((theme: Theme) => ({
    list: {
        width: 250
    },
    fullList: {
        width: "auto"
    }
}));

export const NavDrawer = (props: NavDrawerProps) => {
    const client = useAtomValue(clientAtom) as Client
    const userInformation: UserInformation = useAtomValue(userInformationAtom)

    const localClientId = client.id || 0

    //const activeName = props.activeName || ""

    const navigate = useNavigate()

    const {classes }= styles()

    const questionnaireService = useListQuestionnaireService()
    const questionnaires: Array<Questionnaire> = questionnaireService.status === "loaded" ? questionnaireService.payload : [] as Array<Questionnaire>
    const questionnaire = questionnaireService.status === "loaded" && head(questionnaires)

    const questionnaireOnClickHandler = (questionnaire: Questionnaire) => () => {
        if (questionnaire.id && questionnaire.id > 0) {
            navigate("/questionnaire/" + questionnaire.id)
        }
    }

    const navigationElements = useNavigationElements(client.settings, userInformation)

    const sideList = (side: string) => (
        <div
            className={classes.list}
            role="presentation"
            onClick={props.toggleDrawerHandler}
            onKeyDown={props.toggleDrawerHandler}>
            <Box m={2}>
                <Typography variant="h5">{client.settings.siteName}</Typography>
            </Box>

            {navigationElements
                .filter(e => e.requiredRoles.length === 0 || requireOneOfGroup(userInformation?.userGroups, e.requiredRoles))
                .map(e => (
                    <ListItem button key={e.label} component="a" href={e.to}>
                        <ListItemText primary={e.label}/>
                    </ListItem>
                ))}
            {requireOneOfGroup(userInformation?.userGroups, [Groups.globalAdmin, Groups.clientAdmin]) && client?.settings.directory==="enabled" &&
            <React.Fragment>
                <ListItem key="site-relationships" button={true} component="a" href="/trackPrograms"><ListItemText primary="Track Relationships"/></ListItem>
                <ListItem key="program-relationships" button={true} component="a" href="/program"><ListItemText primary="Program Relationships"/></ListItem>
                <ListItem component="a" key="bulk-email" button href="/bulkEmail">
                    <ListItemText primary="Bulk Email"/>
                </ListItem>
                {questionnaireService.status === "loaded" &&
                (questionnaire && localClientId!==1 && (<ListItem button key="Questionnaire" id="questionnaire" onClick={questionnaireOnClickHandler(questionnaire)}><ListItemText primary={questionnaire.name}/></ListItem>))
                }
            </React.Fragment>
            }
        </div>
    );

    return (
        <Drawer open={props.open} onClose={props.toggleDrawerHandler}>
            {sideList("left")}
        </Drawer>
    );
}

/*
const renderMobileDrawer = (
    <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
    >
    </Menu>
)

 */

