import React, {useEffect, useState} from "react"
import {SearchComponent, SearchInputData} from "./component/search/SearchComponent";
import {API, api} from "../lib/Api";
import {useAppContext} from "../lib/context";
import {TableColumn} from "../components/customTable/CustomTable";
import {InternshipSite} from "../internship-site/model/Organization";
import {Box, Card, Table, TableBody, TableCell, TableHead, TableRow, Typography} from "@mui/material";
import {PageBlurb} from "../components/PageBlurb";
import {useDebounce} from "../lib/useDebounce";
import {Link} from "react-router-dom";
import {QuestionnaireId} from "./model/Questionnaire";
import SpinnerCard from "../components/SpinnerCard";
import {Page, PageBody, PageHeader} from "../components/Page";
import Groups from "../model/Groups";
import {requireGroup} from "../lib/util";
import {LoginStateCard} from "../components/LoginStateCard";
import {Client, ClientSettingsOps} from "../client/model/Client";
import {RichElement} from "../components/customTable/RichElement";
import {siteTrackFilter} from "./component/search/component/DirectoryEntry";
import {userInformationAtom} from "../Atoms";
import {useAtomValue} from "jotai";
import {clientAtom, loginStateAtom} from "../Atoms";
import {useQuestionnaireByNameService} from "./QuestionnaireByNameService";
import {useQuery} from "@tanstack/react-query";
import {UserInformation} from "../login/model/UserInformation";

interface SearchResultsProps {
    questionnaireId: QuestionnaireId
    searchResultsColumns: TableColumn<InternshipSite>[]
    results: InternshipSite[]
}

const SearchResultsTable = (props: SearchResultsProps) => {
    const client = useAtomValue(clientAtom) as Client
    return (
        <Table>
            <TableHead>
                <TableCell>Name</TableCell>
            </TableHead>
            <TableBody>
                {props.results.map(site => (
                    <TableRow>
                        <TableCell>
                            <Link
                                  to={`/directory/entry/${site.organizationId}/${props.questionnaireId}/${site.id}`}>
                                {site.name} {(!ClientSettingsOps.matchDatePassed(client?.settings) || ClientSettingsOps.clearingHousePassed(client?.settings)) && <span className="siteOpenings">[Openings: {site.totalOpenings}]</span>}
                            </Link>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

export const DirectorySearch = () => {
    const {isDebug} = useAppContext()

    const userInformation: UserInformation = useAtomValue(userInformationAtom)
    const client = useAtomValue(clientAtom) as Client
    const loginState = useAtomValue(loginStateAtom)

    const [searching, setSearching] = useState<boolean>(true)
    const [searchData, setSearchData] = useState<SearchInputData>({search: [], openingsOnly: false})

    const questionnaireQuery = useQuestionnaireByNameService(userInformation, "Agency Profile", client)

    const siteMapping = (site: InternshipSite) => ({...site, siteTracks: {...site.siteTracks?.filter(x => siteTrackFilter(x, userInformation, client))}})

    const searchQuery= useQuery({
        queryFn: () => {
            setSearching(true)
            return api(API.searchDirectory(questionnaireQuery.data?.id || 0, searchData.search.map(x => x.key), searchData.search.map(x => x.value), searchData.openingsOnly), userInformation, client.id)
                .then((res) => {
                    isDebug && console.log("Search results", res.data)
                    setSearching(false)
                    return res.data
                })
                .then(res =>
                    (res as InternshipSite[]).map(x => siteMapping(x)).sort((a, b) => a.name.localeCompare(b.name))
                )
        },
        queryKey: ["searchDirectory", searchData.search.map(x => x.key + x.value).join(",")],
        enabled: questionnaireQuery.isSuccess && client.id > 0,
        staleTime: 600
    })

    const debouncedSearch = useDebounce((searchData) => {
        isDebug && console.log("Search Data", searchData)
        return searchData
    }, 1000)

    useEffect(() => {
        debouncedSearch(searchData)
    }, [setSearching, userInformation, searchData, isDebug, debouncedSearch])

    const searchResultColumns: TableColumn<InternshipSite>[] = [{
        title: "Name",
        field: "name"
    }]

    isDebug && console.log(searchQuery.data)

    const {useLoginStateCard, component} = LoginStateCard(loginState, client)

    /*
    const isDirectoryEnabled: boolean = false && matchPublished(client?.settings) && requireGroup(userInformation?.userGroups, Groups.student) && (!!userInformation?.student?.matchedTrackId)

    if (!isDirectoryEnabled) {
        return (
            <Page navName="Directory" footerPosition="fixed">
                <PageHeader title="Search Directory"/>
                <PageBody>
                    <Typography variant="h6">Thank you for participation in this years match.
                        Your match has now been posted and is available at the <Link to="/studentResults">Match Results Page</Link>.
                    </Typography>
                </PageBody>
            </Page>
        )
    }


    if (isDirectoryEnabled && !clearingHousePassed(client?.settings)) {
        return (
            <Page navName="Directory" footerPosition="fixed">
                <PageHeader title="Search Directory"/>
                <PageBody>
                    <Box>
                        <Typography variant="h6">Thank you for participation in this years match.
                            Your match result has now been posted and is available at the <Link to="/studentResults">Match Results Page</Link>.
                        </Typography>
                    </Box>
                    <p>
                        The {client?.settings.clearingHouseLabel} phase opens on <RichElement value={client?.settings.clearingHouseDate}/>, at which time you may browse the directory.
                    </p>
                </PageBody>
            </Page>
        )
    }

     */

    return useLoginStateCard ? component : (
        <Page navName="Directory" footerPosition="fixed">
            <PageHeader title="Search Directory"/>
            <PageBody>
                {ClientSettingsOps.matchPublished(client?.settings) &&
                    <Box>
                        <Typography variant="h6">The match has been published.
                             {requireGroup(userInformation?.userGroups, Groups.student) && <>You can view your result at <Link to="/studentResults">Match Results Page</Link>.</>}
                        </Typography>
                        {ClientSettingsOps.clearingHousePassed(client?.settings) ?
                            <p>The {client?.settings.clearingHouseLabel} phase is now open.</p>
                            : <p>Track opening information will be available again once the {client?.settings.clearingHouseLabel} window opens on <RichElement value={client?.settings.clearingHouseDate}/>.</p>
                        }
                    </Box>
                }

                <PageBlurb name="directory_search"/>
                {requireGroup(userInformation?.userGroups, Groups.student) &&
                    <p>You are viewing the directory as a member of {userInformation?.student?.program?.name} at {userInformation?.student?.school?.name}.</p>
                }
                {questionnaireQuery.isSuccess ?
                    <SearchComponent questionnaire={questionnaireQuery.data} setSearchInputData={setSearchData}/>
                    : <SpinnerCard message="Loading search terms..."/>
                }
                <Card>
                    <Box m={2}>
                    <Typography variant="h5" color="textSecondary" gutterBottom>
                        Search Results
                    </Typography>
                    </Box>
                    {searching ? <SpinnerCard message="Searching"/> :
                        searchQuery.isSuccess && questionnaireQuery.isSuccess ?
                            <SearchResultsTable questionnaireId={questionnaireQuery.data.id} data-testid="search-results-table"
                                                searchResultsColumns={searchResultColumns} results={searchQuery.data}/>
                            : "No Search Results"}
                </Card>
            </PageBody>
        </Page>
    )
}