import React, {Suspense} from 'react';
import {BrowserRouter} from 'react-router-dom';

import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import reportWebVitals from './reportWebVitals';
import SpinnerCard from "./components/SpinnerCard";
import {ErrorFallback} from "./components/ErrorFallback";
import {ErrorBoundary} from "react-error-boundary";
import { createRoot } from 'react-dom/client';
import {appConfig} from "./conf/conf";
import {buildVersion} from "./lib/buildVersion";
import './i18n';


import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: 'e63bf572-6c58-49fc-94fc-a7bec719175f',
    clientToken: 'pubdda9d0be07a8ce6fc912a0d42167bfb7',
    site: 'us5.datadoghq.com',
    service: 'matchfit-ui',
    env: appConfig.env,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: buildVersion,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
});

const container = document.getElementById('root');
const root = createRoot(container!); // createRoot(container!) if you use TypeScript
root.render(
    <React.StrictMode>
        <ErrorBoundary fallbackRender={() => <ErrorFallback/>}>
            <Suspense fallback={<SpinnerCard message="Please Wait ..."></SpinnerCard>}>
                <BrowserRouter>
                    <App/>
                </BrowserRouter>
            </Suspense>
        </ErrorBoundary>
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

reportWebVitals();

