import {RefObject} from "react";

import {setFromStringPath, setListFromStringPath} from "./util";
import {appConfig} from "../conf/conf";

export const formDataAsMapFromRef = (formRef: RefObject<HTMLFormElement>) => {
    if (formRef.current) {
        return formDataAsMap<any>(formRef.current)
    } else return undefined
}

export const formListDataAsMapFromRef = (formRef: RefObject<HTMLFormElement>) => {
    if (formRef.current) {
        return formDataAsMapList(formRef.current)
    } else return undefined
}

export function formDataAsMap<T>(form: HTMLFormElement): T {
    const formData = new FormData(form)
    const ar = Array.from(formData.entries())
    appConfig.isDebug && console.log("Form Data Entries", ar)
    const k = ar.reduce((p, cur, index) => {
        const [key, value] = cur
        if (key.endsWith("-date")) {
            const truncKey: string = key.substring(0, key.length-5)
            const timeKey: string = truncKey + "-time"
            ar.filter(x => x[0] === timeKey).forEach(e => {
                // TODO this is HARD CODED to California!
                const dateStr = value + "T" + e[1] + ":00-0700"
                setFromStringPath(p, truncKey, new Date(dateStr))
            })
            return p
        }
        else if (key.endsWith("-numeric")) {
            const truncKey: string = key.substring(0, key.length-8)
            setFromStringPath(p, truncKey, parseFloat(value.toString()))
            return p
        }
        else if (key.endsWith("-bool")) {
            const truncKey: string = key.substring(0, key.length-5)
            setFromStringPath(p, truncKey, value === "true" || value === "yes" || value === "on")
            return p
        }
        else if (key.endsWith("-array")) {
            const truncKey: string = key.substring(0, key.length-6)
            setFromStringPath(p, truncKey, [])
            return p
        }
        else {
            return setFromStringPath(p, key, value)
        }
    }, {})
    return k as T
}

export const formDataAsMapList: (f: HTMLFormElement) => any = (form: HTMLFormElement) => {
    const formData = new FormData(form)
    //console.log("Form Data", Array.from(formData.entries()))
    const k = Array.from(formData.entries()).reduce((p, cur, index) => {
        const [key, value] = cur
        return setListFromStringPath(p, key, value)
    }, {})
    return k
}

export class StandardValidators {
    static emailRegex = /[a-z]+/

    static validateEmail(email: string | undefined) {
        if (email) {
            //console.log("Validating: ", email)
            return email.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/) !== null;
        }
        else {
            return false
        }
    }
}
