import React from "react";
import {makeStyles} from "tss-react/mui";


const useStyles = (bg: string, fg: string) => makeStyles()((theme) => ({
    pulsingButtonContainer: {
        position: "relative"
    },
    pulsingButton: {
        background: bg,
        color: fg,
        borderRadius: "100%",
        animation: "pulse-shadow 2s infinite ease",
    },

}));

interface PulsatingCircleProps {
    children?: React.ReactNode
    bg: string
    fg: string
}

export const PulsatingCircle = (props: PulsatingCircleProps) => {
    const {classes} = useStyles(props.bg, props.fg)();

    console.log("Pulsating Circle props", props.children)

    return (
        <div className={classes.pulsingButton}>{props.children}</div>
    );
}