import z from "zod";
import {StudentUserInformation} from "../../login/model/UserInformation";
import {setFromArrayPath} from "../../lib/util";
import i18next from "i18next";
export type ClientId = number
export type ClientCycleId = number
export type SlotId = string

export const clientCycleValidator = z.object({
    id: z.number().int().gte(0).optional(),
    clientId: z.number().int().gte(0),
    cycleStart: z.date().or(z.string().transform(x => new Date(x))),
    cycleEnd: z.date().or(z.string().transform(x => new Date(x))),
    rankListLockDate: z.date().optional().or(z.string().transform(x => new Date(x))),
    matchPublishDate: z.date().nullable().optional().or(z.string().transform(x => new Date(x))),
    clearingHouseDate: z.date().nullable().optional().or(z.string().transform(x => new Date(x))),
    cycleName: z.string(),
    matchType: z.enum(["auto", "manual"])
})

export type ClientCycle = z.infer<typeof clientCycleValidator>

export const slotSourceValidator = z.enum(["materials", "direct", "letterOfReference"])
export type SlotSource = z.infer<typeof slotSourceValidator>

export const SlotGroups = {
    specificRequiredDocument: "Specific Required Document",
    letterOfReference: "Letter of Reference",
    electiveDocument: "Elective Document"
} as const

export const slotGroups = [
    SlotGroups.specificRequiredDocument,
    SlotGroups.letterOfReference,
    SlotGroups.electiveDocument
] as const

export const slotGroupValidator = z.enum(slotGroups)

export type SlotGroup = z.infer<typeof slotGroupValidator>

export const slotDefinitionValidator = z.object({
    id: z.string(),
    position: z.number().int().gte(0),
    name: z.string(),
    required: z.boolean(),
    slotGroup: slotGroupValidator
}).strict()

export const materialSlotDefinitionValidator = slotDefinitionValidator
export const packetSlotDefinitionValidator = slotDefinitionValidator.extend({
    source: slotSourceValidator
})

export type MaterialSlotDefinition = z.infer<typeof materialSlotDefinitionValidator>
export type PacketSlotDefinition = z.infer<typeof packetSlotDefinitionValidator>

export const applicationSuiteSettingsValidator = z.object({
    useApplicationSuite: z.boolean(),
    useProgramApprovalProcess: z.boolean(),
    applyTo: z.enum(["Site", "Track"]),
    applicationSubmissionOpensTimestamp: z.date().or(z.string().transform(x => new Date(x))),
    applicationSubmissionClosedTimestamp: z.date().nullable().or(z.string().transform(x => new Date(x))),
    packetsPerSubmission: z.number(),
    materialSlotsDefinition: z.object({
        clientId: z.number().int().gte(0),
        clientCycleId: z.number().int().gte(0),
        studentQuestionnaireId: z.number().nullable(),
        materialsApprovalQuestionnaireId: z.number().nullable(),
        slotDefinitions: materialSlotDefinitionValidator.array()
    }),
    packetSlotsDefinition: z.object({
        clientId: z.number().int().gte(0),
        clientCycleId: z.number().int().gte(0),
        slotDefinitions: packetSlotDefinitionValidator.array()
    })
})

export type ApplicationSuiteSettings = z.infer<typeof applicationSuiteSettingsValidator>

export const clientCycleSettingsValidator = z.object({
    clientId: z.number().int().gte(0),
    clientCycleId: z.number().int().gte(0),
    cycleName: z.string(),
    applicationSuiteSettings: applicationSuiteSettingsValidator,
    dateSettings: z.object({
        rankListLockDate: z.date().optional().nullable().or(z.string().transform(x => new Date(x))),
        cycleStart: z.date().or(z.string().transform(x => new Date(x))),
        cycleEnd: z.date().or(z.string().transform(x => new Date(x))),
        matchPublishDate: z.date().nullable().optional().or(z.string().transform(x => new Date(x))),
        clearinghouseDate: z.date().nullable().optional().or(z.string().transform(x => new Date(x))),
        studentInviteStartDate: z.date().optional().or(z.string().transform(x => new Date(x)))
    })
})

export const clientPaymentSettingsValidator = z.object({
    showDuesPaidFlag: z.string(),
    paywallLocation: z.string(),
    studentCost: z.string(),
    paymentDescription: z.string(),
})

export type ClientPaymentSettings = z.infer<typeof clientPaymentSettingsValidator>

export type ClientCycleSettings = z.infer<typeof clientCycleSettingsValidator>

export const clientSettingsValidator = clientPaymentSettingsValidator.extend({
    siteName: z.string(),
    clientName: z.string(),
    clientUrl: z.string().url(),
    clientSupportEmail: z.string(),
    fromEmail: z.string(),
    inviteSubject: z.string(),
    directory: z.union([z.literal("enabled"), z.literal("disabled")]),
    useTrackType: z.union([z.literal("yes"), z.literal("no")]),
    showDuesPaidFlag: z.union([z.literal("yes"), z.literal("no")]),
    showAttestationFiledFlag: z.union([z.literal("yes"), z.literal("no")]),
    paywallLocation: z.string(),
    studentCost: z.string(), // yes - this is weird, all settings are stored in dynamo as Strings because they are totally generic.
    useSiteListLockDate: z.union([z.literal("yes"), z.literal("no")]),
    useApplicationLimit: z.union([z.literal("yes"), z.literal("no")]),
    attestationAtRanking: z.union([z.literal("yes"), z.literal("no")]),
    defaultApplicationLimit: z.string(),
    blurbs: z.record(z.string()),
    rankListLockDate: z.date().optional().or(z.string().transform(x => new Date(x))),
    matchPublishDate: z.date().nullable().optional().or(z.string().transform(x => new Date(x))),
    clearingHouseDate: z.date().nullable().optional().or(z.string().transform(x => new Date(x))),
    clearingHouseLabel: z.string(),
    clientAdminCanPreviewMatch: z.union([z.literal("yes"), z.literal("no")]),
    cycle: clientCycleValidator,
    isLoaded: z.boolean().optional(),
    clientCycleSettings: clientCycleSettingsValidator
})

export type ClientSettings = z.infer<typeof clientSettingsValidator>

export const clientValidator = z.object({
    id: z.number(),
    name: z.string(),
    settings: clientSettingsValidator,
    cycles: z.array(clientCycleValidator).optional()
})

export type Client = z.infer<typeof clientValidator>

export class Clients {
    static bapic = 2;
    static scaptp = 3;
    static test = 36;
}


export class ClientSettingsOps {
    static siteListLocked: (userInformation: StudentUserInformation) => boolean = (userInformation) =>
        (userInformation.student.program?.sitelistLockDate?.getTime() || Number.MAX_SAFE_INTEGER) < new Date().getTime()

    static rankListLocked: (clientSettings: ClientSettings) => boolean = (clientSettings) =>
        (clientSettings.rankListLockDate?.getTime() || Number.MAX_SAFE_INTEGER) < new Date().getTime()

    static matchPublished: (clientSettings?: ClientSettings) => boolean = (settings) => ClientSettingsOps.matchDatePassed(settings)

    static matchDatePassed: (clientSettings?: ClientSettings) => boolean = (clientSettings?) =>
        (clientSettings?.matchPublishDate?.getTime() || Number.MAX_SAFE_INTEGER) < new Date().getTime()

    static clearingHousePassed: (clientSettings?: ClientSettings) => boolean = (clientSettings?) =>
        (clientSettings?.clearingHouseDate?.getTime() || Number.MAX_SAFE_INTEGER) < new Date().getTime()

    static isDirectoryEnabled = (clientSettings?: ClientSettings) => clientSettings?.directory === "enabled"

    static packetSlotsByType = (clientSettings: ClientSettings, slotGroup: SlotGroup): PacketSlotDefinition[] => {
        const applicationSuiteSettings: ApplicationSuiteSettings = clientSettings.clientCycleSettings.applicationSuiteSettings
        return applicationSuiteSettings.packetSlotsDefinition.slotDefinitions
            .filter(x=> x.slotGroup === slotGroup)
    }

    static materialSlotsByType = (clientSettings: ClientSettings, slotGroup: SlotGroup) => {
        const applicationSuiteSettings: ApplicationSuiteSettings = clientSettings.clientCycleSettings.applicationSuiteSettings
        return applicationSuiteSettings.materialSlotsDefinition.slotDefinitions
            .filter(x=> x.slotGroup === slotGroup)
    }

    static isPreClearingHouse = (clientSettings: ClientSettings): boolean => {
        return (clientSettings.clientCycleSettings.dateSettings.clearinghouseDate?.getTime() || Number.MAX_SAFE_INTEGER) > new Date().getTime()
    }

    static isInSubmissionWindow = (clientSettings: ClientSettings): boolean => {
        return (clientSettings.clientCycleSettings.applicationSuiteSettings?.applicationSubmissionOpensTimestamp.getTime() < new Date().getTime() &&
            (clientSettings.clientCycleSettings.applicationSuiteSettings?.applicationSubmissionClosedTimestamp?.getTime() || 0) > new Date().getTime())
    }

    static isInRankingWindow = (clientSettings: ClientSettings): boolean => {
        return (clientSettings.clientCycleSettings.applicationSuiteSettings?.applicationSubmissionClosedTimestamp?.getTime() || 0) > new Date().getTime()
    }

    static loadSiteBlurbsToI18n = (clientSettings: ClientSettings): void => {
        let blurbs = {}

        Object.keys(clientSettings.blurbs).forEach(k => {
            setFromArrayPath(blurbs, k.split("/"), clientSettings.blurbs[k])
        })

        i18next.addResourceBundle("en", "translation", {blurbs: blurbs}, true, true)
    }
}