import React, {FormEvent, useEffect, useState} from 'react';
import '../../Login.css';
import {api, API, apiError} from '../../lib/Api'
import {useAppContext} from "../../lib/context";
import {Address} from "../../components/model/Address";
import {errorMessageModal, messageModal} from "../../components/modal/InfoModal";
import {Box, Button, Collapse, FormControl, TextField, Typography} from "@mui/material";
import {formDataAsMap} from "../../lib/Forms";
import {Theme} from "@mui/material/styles";
import Groups from "../../model/Groups";
import {useNavigate} from "react-router-dom";
import SpinnerCard from "../../components/SpinnerCard";
import {changePassword} from "../../components/CognitoAuth";
import {makeStyles} from "tss-react/mui";
import {userInformationAtom} from "../../Atoms";
import {useAtomValue} from "jotai";
import {useQueryClient} from "@tanstack/react-query";
import {clientAtom, refreshUser} from "../../Atoms";
import {Client} from "../../client/model/Client";

interface ProfileFormState {
    firstName: string
    lastName: string
    email: string
    currentPassword: string
    newPassword: string
    verifyPassword: string
    address: Address
}

interface ProfileFormErrors {
    id?: string
    clientId?: string
    firstName?: string
    lastName?: string
    email?: string
    programId?: string
    currentPassword?: string
    newPassword?: string
    verifyPassword?: string
    address?: {
        address1?: string
        address2?: string
        city?: string
        region?: string
        postalCode?: string
    }
}

const useStyles = makeStyles()((theme: Theme) => ({
        formControl: {
            minWidth: 120,
            width: "100%",
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        },
        hFlowBox: {
            marginTop: theme.spacing(1),
            marginRight: theme.spacing(1)
        }
    }));

export const ProfileForm = () => {

    const {setModal, isDebug} = useAppContext();

    const client = useAtomValue(clientAtom) as Client
    const userInformation = useAtomValue(userInformationAtom)

    const localClientId: number = client.id

    const [errors, setErrors] = useState<ProfileFormErrors>({});
    const [loading, setLoading] = useState<boolean>(true)
    const [arrive, setArrive] = useState<boolean>(false)

    const [user, setUser] = useState<ProfileFormState | null>()

    const navigate = useNavigate()

    const queryClient = useQueryClient()

    useEffect(() => {
        if (userInformation) {
            setUser({
                firstName: "",
                lastName: "",
                currentPassword: "",
                newPassword: "",
                verifyPassword: "",
                address: {
                    id: 0, address1: "", address2: "", city: "", region: "", postalCode: "",
                    ...userInformation?.student?.address
                },
                ...userInformation
            })
            setLoading(false)
            setTimeout(() => setArrive(true), 2000)
        }
    }, [userInformation]);


    const validate = (data: ProfileFormState) => {
        if (false) {
            setErrors({})
        }
    }

    const handleSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        const data = formDataAsMap<ProfileFormState>(event.target as HTMLFormElement)
        validate(data)

        const localData = {...data, clientId: localClientId, id: userInformation.guid};

        api(API.updateProfile(), userInformation, localClientId, localData)
            .then(r => {
                isDebug && console.log("LocalData in updateProfile", localData, r);
                setUser({...data});
                setModal && setModal({
                    ...messageModal("Info", "Profile Saved Successfully"),
                    onClose: e => {
                        if (localData.currentPassword.trim()) {
                            changePassword(localData.currentPassword, localData.newPassword)
                                .then(x => {
                                    setModal && setModal(messageModal("Info", "Password Change Completed"))
                                }).catch(x => {
                                setModal && setModal(errorMessageModal("Error", x.message))
                            })
                        }

                        /*
                        userInformation && setUserInformation && userEnhanceFromBackEnd(userInformation).then(x => setUserInformation(x))
                         */

                        refreshUser(queryClient).then(x => {
                            navigate("/home")
                        })
                    }
                })
            }).catch(x => {
            x.json().then((apiData: any) => {
                if (apiData.message === "User already exists") {
                    setModal && setModal(errorMessageModal("Error", "A user already exists on the system with the email address " + localData.email))
                } else {
                    isDebug && console.log("Failed to make call to add Student, got error JSON", data)
                    apiError(x, setModal)
                }
            }).catch((y: any) => {
                console.log("Failed to make call to add Student, got ", y)
                apiError(y, setModal)
            })
        })

    }

    const {classes} = useStyles()

    return loading ? <SpinnerCard/> :<>
                    <Typography variant="h6" style={{paddingBottom: 20}}>Email: {userInformation?.email}</Typography>
                    <Collapse in={arrive && !user?.firstName}>
                        <Typography variant="h6" style={{color: "#a00000"}}>
                            {`Thank you for logging in to ${client?.settings.siteName}! Please update your profile information to continue.`}
                        </Typography>
                    </Collapse>
                    <form onSubmit={e => handleSubmit(e)}>
                        <FormControl className={classes.formControl}>
                            <TextField type="text" id="firstName"
                                       variant="outlined"
                                       aria-label="First name"
                                       label="First Name"
                                       defaultValue={user?.firstName}
                                       placeholder="Enter First Name"
                                       helperText="First Name"
                                       name="firstName"
                                       required={true}
                            />
                            <div className="formErrors">{errors.firstName}</div>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField type="text" id="lastName"
                                       variant="outlined"
                                       aria-label="Last name"
                                       label="Last Name"
                                       helperText="Last Name"
                                       defaultValue={user?.lastName}
                                       name="lastName"
                                       required={true}
                                       placeholder="Enter Last Name"
                            />
                        </FormControl>
                        {userInformation?.primaryGroup() === Groups.student &&
                        <React.Fragment>
                            <FormControl className={classes.formControl}>
                                <TextField type="text" id="address1"
                                           variant="outlined"
                                           aria-label="Address"
                                           label="Street Address"
                                           placeholder="Address"
                                           helperText="Street Address"
                                           defaultValue={userInformation?.student?.address?.address1}
                                           name="address.address1"
                                />
                                <div className="formErrors">{errors.address?.address1}</div>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField type="text" id="address2"
                                           variant="outlined"
                                           aria-label="Unit, apartment, or suite."
                                           label="Suite / Apartment"
                                           placeholder="Suite / Apt"
                                           defaultValue={userInformation?.student?.address?.address2}
                                           name="address.address2"
                                           helperText="Unit, apartment, or suite number"
                                />
                                <div className="formErrors">{errors.address?.address2}</div>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField type="text" id="city"
                                           variant="outlined"
                                           aria-label="City"
                                           label="City"
                                           placeholder="City"
                                           defaultValue={userInformation?.student?.address?.city}
                                           name="address.city"
                                           helperText="City"
                                />
                                <div className="formErrors">{errors.address?.city}</div>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField type="text" id="state"
                                           variant="outlined"
                                           label="State"
                                           aria-label="State or region"
                                           placeholder="State"
                                           defaultValue={userInformation?.student?.address?.region}
                                           name="address.region"
                                           helperText="State or Region"
                                />
                                <div className="formErrors">{errors.address?.region}</div>
                            </FormControl>
                            <FormControl className={classes.formControl}>
                                <TextField type="text" id="postalCode"
                                           variant="outlined"
                                           label="Zip"
                                           aria-label="Zipcode or Postal Code"
                                           placeholder="Zipcode"
                                           defaultValue={userInformation?.student?.address?.postalCode}
                                           name="address.postalCode"
                                           helperText="Zipcode or Postal Code"
                                />
                                <div className="formErrors">{errors.address?.postalCode}</div>
                            </FormControl>
                        </React.Fragment>
                        }

                        <Typography variant="h6" style={{paddingTop: 20}}>
                            If you would like to update your password, enter your current password to verify,
                            and then enter a new password below:
                        </Typography>

                        <FormControl className={classes.formControl}>
                            <TextField type="password" id="currentPassword"
                                       variant="outlined"
                                       aria-label="Current Password"
                                       label="Current Password"
                                       placeholder="Current Password"
                                       defaultValue={user?.newPassword}
                                       name="currentPassword"
                            />
                            <div className="formErrors">{errors.newPassword}</div>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField type="password" id="password"
                                       variant="outlined"
                                       aria-label="New Password"
                                       label="New Password"
                                       placeholder="New Password"
                                       defaultValue={user?.newPassword}
                                       name="password"
                            />
                            <div className="formErrors">{errors.newPassword}</div>
                        </FormControl>
                        <FormControl className={classes.formControl}>
                            <TextField type="password" id="passwordVerify"
                                       variant="outlined"
                                       aria-label="Confirm New Password"
                                       label="Confirm New Password"
                                       placeholder="Confirm New Password"
                                       defaultValue={user?.verifyPassword}
                                       name="verifyPassword"
                            />
                            <div className="formErrors">{errors.verifyPassword}</div>
                        </FormControl>
                        <Box display="flex" flexWrap="wrap">
                            <Box className={classes.hFlowBox}>
                                <Button type="submit" variant="outlined">Submit Form</Button>
                            </Box>
                            <Box className={classes.hFlowBox}>
                                <Button href="/student" variant="outlined">Cancel</Button>
                            </Box>
                        </Box>
                    </form>
        </>
}
