import {Accordion, AccordionDetails, AccordionSummary, Box, Typography} from "@mui/material";
import React from "react";
import {useStyles} from "./StudentApplicationList";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import {Client} from "../../client/model/Client";
import {StudentUserInformation, UserInformation} from "../../login/model/UserInformation";
import {MaterialElementChip} from "./MaterialElementChip";

interface StudentMaterialsElementProps {
    userInformation: UserInformation
    client: Client
    user: StudentUserInformation
}

export const StudentMaterialsElement: React.FC<StudentMaterialsElementProps> = (props) => {
    const {userInformation, user, client} = props

    const slotCount = client.settings.clientCycleSettings.applicationSuiteSettings.materialSlotsDefinition.slotDefinitions.length
    const userCount = user.student.materials?.materials.length || 0

    const {classes} = useStyles()

    return <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
            <Typography>{userCount} / {slotCount} Materials Provided</Typography>
        </AccordionSummary>
        <AccordionDetails className={classes.materialChipsGroup}>
            <Box display="flex" flexWrap="wrap" p={1} alignItems="center">
                {client.settings.clientCycleSettings.applicationSuiteSettings.materialSlotsDefinition.slotDefinitions.map(x => MaterialElementChip(userInformation, client, user, x))}
            </Box>
        </AccordionDetails>
    </Accordion>
}