import React from 'react';
import {Page, PageBody, PageHeader} from "./components/Page";
import {Box} from "@mui/material";

export const CapicEnd: React.FC = () => {

    return <Page navName="Home" footerPosition="fixed">
            <PageHeader title="CAPIC InternFit"/>
            <PageBody>
                <Box>
                    CAPIC's Match and post-match Clearinghouse period have ended.
                    The InternFit Match portal is now closed for the 22-23 year.
                    Please visit <a href="http://capic.net">CAPIC.net</a> for information about the upcoming 2023-24 match year schedule.
                </Box>
            </PageBody>
        </Page>
}
