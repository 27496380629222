import React from 'react';
import '../student/Student.css';
import TrackForm from "./component/TrackForm";
import TrackList from "./component/TrackList";
import {useParams} from "react-router-dom";

import {useAppContext} from "../lib/context"
import {LoginStateCard} from "../components/LoginStateCard";
import {Page} from "../components/Page";
import Groups from "../model/Groups";
import {useAtomValue} from "jotai";
import {clientAtom, loginStateAtom} from "../Atoms";

type TrackParams = {
    id: string
    siteId?: string
    organizationId?: string
}

const Tracks = () => {
    const params = useParams<TrackParams>()
    const { isDebug} = useAppContext();

    const client = useAtomValue(clientAtom)
    const loginState = useAtomValue(loginStateAtom)

    const {useLoginStateCard, component}  = LoginStateCard(loginState, client, [Groups.globalAdmin, Groups.clientAdmin, Groups.siteAdmin, Groups.directoryAdmin])

    if (isDebug) {
        console.log("Params Id", params.id)
    }

    return useLoginStateCard ? component : (
        <Page navName="Tracks">
                {params.id && params.siteId && params.organizationId ? (
                    <div className="justify-content-center h-100">
                        <TrackForm id={params.id} siteId={params.siteId} organizationId={params.organizationId}/>
                    </div>
                ) : (<TrackList orgId={params.id}/>)}
        </Page>
    )
}

export default Tracks
