import React, {useState} from 'react';
import '../Login.css';
import {useNavigate} from "react-router-dom";
import {useAppContext} from "../lib/context";
import {
    changePassword,
    completeNewPassword,
    niceifyCognitoError,
} from "../components/CognitoAuth";
import {appConfig} from "../conf/conf";
import {Spinner} from "../components/SpinnerCard";
import {useStyles} from "./Login";
import {Box, Button, FormGroup, Grid, TextField, Typography} from "@mui/material";
import clsx from "clsx";
import {useAtom, useAtomValue} from "jotai";
import {userInformationAtom} from "../Atoms";
import {flashingErrorMessageAtom, loginStateAtom} from "../Atoms";
import {DialogType} from "../components/modal/ModalProps";
import {LoginState} from "./lib/UserSessionsService";
import {useSignInService} from "./lib/SignInService";

const NewPassword: React.FC<{email: string}> = (props) => {
    const {setModal} = useAppContext();

    const [flashingErrorMessage, setFlashingErrorMessage] = useAtom(flashingErrorMessageAtom)
    const userInformation = useAtomValue(userInformationAtom)
    const [loginState, setLoginState] = useAtom(loginStateAtom)

    const [oldPassword, setOldPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [verifyPassword, setVerifyPassword] = useState("");

    const [submitted, setSubmitted] = useState(false)

    appConfig.isDebug && console.log(flashingErrorMessage)

    const navigate = useNavigate();

    const signInService = useSignInService()

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault()

        if (verifyPassword !== newPassword) {
            setFlashingErrorMessage && setFlashingErrorMessage("Your entered password and verify do not match")
        } else {
            const p = loginState === LoginState.NewPasswordRequired ?
                completeNewPassword(props.email, userInformation.toAttrs(), oldPassword, newPassword) : changePassword(oldPassword, newPassword)

            setSubmitted(true)
            p.then(x => {
                setLoginState(LoginState.Authenticating)
                setFlashingErrorMessage("Logging you in with new password...")

                signInService.signIn(props.email, newPassword).then(data => {
                    appConfig.isDebug && console.log("Cognito sign in ", data)
                    // Once we're signed in, we can retrieve session and initialize the userInformation object
                    navigate("/home")
                }).catch(err => {
                    appConfig.isDebug && console.log(err.message)
                    setLoginState && setLoginState(LoginState.AuthenticationFailed)
                    setFlashingErrorMessage && setFlashingErrorMessage(err.message)
                    setSubmitted(false)
                })
            }).catch(err => {
                appConfig.isDebug && console.log(err)

                const msg = niceifyCognitoError(err)

                setModal && setModal({
                    title: "Error",
                    message: "Failed to Set New Password: " + msg,
                    isOpen: true,
                    onClose: () => {
                        setSubmitted(false)
                    },
                    dialogType: DialogType.Message
                })
                setFlashingErrorMessage && setFlashingErrorMessage(err.message)
            });
        }
    }

    const {classes} = useStyles()

    return (
        <Box className={clsx(classes.Login, classes.container)}>
            <Box className={classes.card}>
                <Box className={classes.cardHeaderBox}>
                    <Typography variant="h5" className={classes.cardHeader}>Update Password</Typography>
                </Box>
                <Box>
                    {submitted ?
                        (<div style={{"width": "100%", "marginTop": "50px"}}>
                            <div style={{"margin": "0 auto", "width": "32px"}}>
                                <Spinner/>
                            </div>
                        </div>)
                        :
                        <form onSubmit={handleSubmit}>
                            {flashingErrorMessage ? (
                                <div className={classes.formControl}>
                                    <span id="formError" className="text-danger">
                                       {flashingErrorMessage}
                                    </span>
                                </div>
                            ) : ""}
                            <FormGroup className={classes.formControl}>
                                <Grid container alignItems="flex-end" wrap="nowrap">
                                    <Grid item className={classes.prependIcon}>
                                        <Box p={1}><i className="fas fa-key"></i></Box>
                                    </Grid>
                                    <Grid item className={classes.inputContainer} xs={12}>
                                        <TextField id="temporary-password" name="oldPassword" placeholder={loginState === LoginState.NewPasswordRequired ? "Temporary Password" : "Old Password"} className={classes.inputField}
                                                   type="password"
                                                   value={oldPassword} onChange={e => setOldPassword(e.target.value)}/>
                                    </Grid>
                                </Grid>
                            </FormGroup>
                            <FormGroup className={classes.formControl}>
                                <Grid container alignItems="flex-end" wrap="nowrap">
                                    <Grid item className={classes.prependIcon}>
                                        <Box p={1}><i className="fas fa-key"></i></Box>
                                    </Grid>
                                    <Grid item className={classes.inputContainer} xs={12}>
                                        <TextField id="login-password" name="newPassword" placeholder="New Password" className={classes.inputField}
                                                   type="password"
                                                   value={newPassword} onChange={e => setNewPassword(e.target.value)}/>
                                    </Grid>
                                </Grid>
                            </FormGroup>
                            <FormGroup className={classes.formControl}>
                                <Grid container alignItems="flex-end" wrap="nowrap">
                                    <Grid item className={classes.prependIcon}>
                                        <Box p={1}><i className="fas fa-key"></i></Box>
                                    </Grid>
                                    <Grid item className={classes.inputContainer} xs={12}>
                                        <TextField id="verify-password" name="verifyPassword" placeholder="Confirm New Password" className={classes.inputField}
                                                   type="password"
                                                   value={verifyPassword} onChange={e => setVerifyPassword(e.target.value)}/>
                                    </Grid>
                                </Grid>
                            </FormGroup>
                            <Box className={classes.formControl}>
                                <Grid container alignItems="flex-end" justifyContent="flex-end">
                                    <Grid item><Button type="submit" value="Update" id="update-button" className={clsx(classes.submitBtn, classes.loginBtn)}>Update</Button></Grid>
                                </Grid>
                            </Box>
                        </form>
                    }
                </Box>
            </Box>
        </Box>
    );
}

export default NewPassword;
