enum Groups {
    globalAdmin = "global-admins",
    siteAdmin = "site-admins",
    programAdmin = "program-admins",
    clientAdmin = "client-admins",
    student = "students",
    directoryViewer = "directory-viewers",
    directoryAdmin = "directory-admins"
}

export const allGroups = [Groups.globalAdmin, Groups.siteAdmin, Groups.programAdmin, Groups.clientAdmin, Groups.student, Groups.directoryViewer, Groups.directoryAdmin]

export default Groups