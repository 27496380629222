import {UseQueryResult} from "@tanstack/react-query/src/types";
import {useQuery} from "@tanstack/react-query";
import {API, api} from "../lib/Api";
import {Client} from "../client/model/Client";
import {UserInformation} from "../login/model/UserInformation";
import {Questionnaire} from "../model/Questionnaire";


export const useQuestionnaireByNameService = (userInformation: UserInformation, name: String, client: Client): UseQueryResult<Questionnaire> => {
    return useQuery({
        queryFn: () => api<Questionnaire[], undefined>(API.listQuestionnaires(), userInformation, client.id).then(list => {
            const questionnaireId = list.data.find(q => q.name === name)?.id

            if (questionnaireId) {
                return api<Questionnaire, undefined>(API.fetchQuestionnaire(questionnaireId), userInformation, client.id)
                    .then(x => x.data)
            } else {
                return new Promise<Questionnaire>((resolve) => resolve({id: 0, name: "", elements: []}))
            }
        }),
        queryKey: ["questionnaireByName", name],
        staleTime: (86400000),
        enabled: client.id > 0
        //enabled: userReady
    })
}