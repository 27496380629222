import {emptyInternshipSite, InternshipSite, Organization} from "../model/Organization";

const internshipSiteFromOrg: (org: Organization) => InternshipSite = (org) => {
    return org.internshipSite ? {
        ...org.internshipSite,
        organizationId: org.id || 0,
        address: org.address
    }: {
        ...emptyInternshipSite,
        ...org,
        organizationId: org.id || 0,
        address: org.address,
        identityCode: (org.id || 0).toString(),
        continuousId: (org.id || 0).toString()
    }
}



export default internshipSiteFromOrg
